import React, { useState, useEffect } from "react";

import { Box, Button, Skeleton, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { getDashboardCounts } from "../../../api/Transporter/dashboardCounts";
import { LOADING_STATUS } from "../../../constants/constant";

const StyledTypography = styled(Typography)(() => ({
  display: "flex",
  color: "#333",
  fontSize: "14px",
  fontWeight: "400",
}));

const BoxWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "6px",
  marginBottom: "6px",
}));

export default function CountsCard() {
  const [counts, setCounts] = useState([]);
  const [isDashboardCountsFetched, setDashboardCountsFetched] = useState("");

  const dashboardCounts = async () => {
    try {
      setDashboardCountsFetched(LOADING_STATUS.LOADING);
      const response = await getDashboardCounts();
      if (response.status === 200) {
        setCounts(response.data.data);
        setDashboardCountsFetched(LOADING_STATUS.LOADED);
      }
    } catch (error) {
      setDashboardCountsFetched(LOADING_STATUS.FAILED);
    }
  };

  useEffect(() => {
    dashboardCounts();
  }, []);

  return (
    <Box
      border="2px solid #ececec"
      borderRadius="8px"
      width="320px"
      height="180px"
      padding="12px 20px 12px 20px"
      justifyContent="space-evenly"
      display="flex"
      flexDirection="column"
    >
      {isDashboardCountsFetched === LOADING_STATUS.LOADING && (
        <Box p={2}>
          <Box width={250} pt={1}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        </Box>
      )}

      {isDashboardCountsFetched === LOADING_STATUS.LOADED && (
        <Box>
          <BoxWrapper>
            <StyledTypography>Total Trips</StyledTypography>
            <StyledTypography>
              {counts?.totalTrips ? counts?.totalTrips : "0"}
            </StyledTypography>
          </BoxWrapper>
          <BoxWrapper>
            <StyledTypography>Total Enquiries</StyledTypography>
            <StyledTypography>
              {counts?.totalEnquiries ? counts?.totalEnquiries : "0"}
            </StyledTypography>
          </BoxWrapper>
          <BoxWrapper>
            <StyledTypography>Total Offers Made</StyledTypography>
            <StyledTypography>
              {counts?.totalOffersMade ? counts?.totalOffersMade : "0"}
            </StyledTypography>
          </BoxWrapper>
          <BoxWrapper>
            <StyledTypography>Total Trucks</StyledTypography>
            <StyledTypography>
              {counts?.totalTrucks ? counts?.totalTrucks : "0"}
            </StyledTypography>
          </BoxWrapper>
          <BoxWrapper>
            <StyledTypography>Total Drivers</StyledTypography>
            <StyledTypography>
              {counts?.totalDrivers ? counts?.totalDrivers : "0"}
            </StyledTypography>
          </BoxWrapper>{" "}
        </Box>
      )}

      {isDashboardCountsFetched === LOADING_STATUS.FAILED && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={4}
        >
          <Typography variant="body1" paragraph>
            Failed to load counts...
          </Typography>
          <Button variant="contained" color="primary" onClick={dashboardCounts}>
            Retry
          </Button>
        </Box>
      )}
    </Box>
  );
}
