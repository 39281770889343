import { client } from "../config";


export const createOrder = async (orderData) => {
  try
  {
    const response = await client.post(`/payment/orders`, orderData);
    return response;
  } catch (error)
  {
    return error;
  }
};

export const createRemainingPaymentOrder = async (tripId, orderData) => {
  try
  {
    const response = await client.post(`/payment/orders/${tripId}`, orderData);
    return response;
  } catch (error)
  {
    return error;
  }
};