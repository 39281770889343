// need to have enquiry id to get to this page
import { AppBar, Box, Stack, Toolbar, Typography } from "@mui/material";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useEffect, useState } from "react";
import GetInTouch from "../component/Common/GetInTouch";

import EnquiryScreens from "../pages/Enquiry/EnquiryScreens";
import { useSelector } from "react-redux";

const RouteOverview = ({
  source,
  destination,
  distance,
  switchComponent,
  setSwitchComp,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Stack
      direction="row"
      alignItems="center"
      // justifyContent="space-between"
      maxWidth="lg"
      m="auto"
      py={2}
    >
      <Box>
        <ArrowBackIosIcon
          onClick={() => {
            if (switchComponent) {
              setSwitchComp(false);
            } else {
              if (location.state.detail?.isAuthenticated) {
                navigate(-1, { replace: true });
              } else navigate("/", { replace: true });
            }
          }}
          sx={{ cursor: "pointer" }}
        />
      </Box>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        gap={5}
        px={3}
      >
        <Typography color="#333" fontWeight={700} maxWidth="40%">
          {source}
        </Typography>
        <Stack direction="column" alignItems="center" maxWidth="20%">
          <EastIcon />
          <Typography color="#333" fontWeight={700}>
            {distance} Km
          </Typography>
        </Stack>
        <Typography color="#333" fontWeight={700} maxWidth="40%">
          {destination}
        </Typography>
      </Stack>
      <p></p>
    </Stack>
  );
};

const EnquiryLayout = () => {
  const location = useLocation();
  const getInTouchNumber = useSelector(
    (state) => state.supportNumbers.landingPage
  );
  const [source, setSource] = useState(null);
  const [destination, setDestination] = useState(null);
  const [distance, setDistance] = useState(null);
  const [enquiryId, setEnquiryId] = useState(null);

  const [switchComponent, setSwitchComp] = useState(false);

  useEffect(() => {
    if (location.state) {
      const { source, destn, distance, enquiryId } = location?.state?.detail;
      setSource(source);
      setDestination(destn);
      setDistance(distance);
      setEnquiryId(enquiryId);
    }
  }, []);

  // if enquiryId is not generated navigate customer to landing page
  if (!location.state) return <Navigate to="/" replace={true} />;
  return (
    <Box>
      <AppBar
        position="relative"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          boxShadow: "none",
          background: "#fff",
        }}
      >
        <Toolbar sx={{ height: "6rem" }}>
          <Stack
            direction="row"
            m="auto"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            maxWidth="lg"
          >
            <Stack direction="row" alignItems="center" spacing="1.25rem">
              <img
                src="/assets/icons/LZ-logo-face.svg"
                style={{ marginBottom: ".35rem" }}
                alt="logoface"
              />
              <img src="/assets/icons/LZ-logo.svg" alt="logo" />
            </Stack>

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <GetInTouch />
              <Typography color="#0C7F44" fontWeight={700} align="right">
                {getInTouchNumber}
              </Typography>
            </Box>
          </Stack>
        </Toolbar>
      </AppBar>

      <Box sx={{ background: "#EAEAEA" }}>
        <RouteOverview
          source={source}
          destination={destination}
          distance={distance}
          switchComponent={switchComponent}
          setSwitchComp={setSwitchComp}
        />
      </Box>

      <Box maxWidth="lg" m="auto" pt={3}>
        <EnquiryScreens
          switchComponent={switchComponent}
          setSwitchComp={setSwitchComp}
          enquiryId={enquiryId}
        />
      </Box>
    </Box>
  );
};

export default EnquiryLayout;
