import {
  Avatar,
  Box,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  styled,
} from "@mui/material";
import ToggleButton from "../../../../../component/Common/ToggleButton";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getSelectedDriver,
  updateDriverAccessLevel,
  updateDriverAvailabilityStatus,
} from "../../../../../api/Transporter/driver";

const DriverCardDetails = ({
  openEditDriverModal,
  selectedDriver,
  updateLocalDriverStatus,
  handleOpenToast,
}) => {
  // Function to handle selected driver data
  const [driverDetails, setDriverDetails] = useState([]);

  // Accessing transporter id from application state.
  const transporterId = useSelector((state) => state.transporterId.id);

  const [isDriverActive, setIsDriverActive] = useState(
    selectedDriver?.status === "AVAILABLE" ? true : false
  );

  // State to store the selected value
  const [selectedValue, setSelectedValue] = useState(
    driverDetails?.driver?.isSuperDriver ? "super driver" : "driver"
  );

  // Function to update driver level
  const updateDriverLevel = async (selectedValue) => {
    try {
      const response = await updateDriverAccessLevel(
        transporterId,
        selectedDriver.driverId,
        selectedValue
      );
      if (response.status === 200) {
        handleOpenToast("Driver level updated successfully", "success");
      }
    } catch (error) {
      handleOpenToast("Error updating driver level", "error");
    }
  };

  // Function to handle changes in the selected value
  const handleChange = (event) => {
    setSelectedValue(
      event.target.value === "super driver" ? "super driver" : "driver"
    );
    updateDriverLevel(event.target.value === "super driver" ? true : false);
  };

  const updateDriverAvailability = async () => {
    const newStatus = isDriverActive ? "UNAVAILABLE" : "AVAILABLE";
    try {
      const response = await updateDriverAvailabilityStatus(
        transporterId,
        selectedDriver.driverId,
        !isDriverActive
      );
      if (response.status === 200) {
        updateLocalDriverStatus(selectedDriver.driverId, newStatus);
        setIsDriverActive(!isDriverActive);
        handleOpenToast("Driver availability updated successfully", "success");
      }
    } catch (error) {
      handleOpenToast("Error updating driver availability", "error");
    }
  };

  const onToggle = () => {
    setIsDriverActive(!isDriverActive);
    updateDriverAvailability();
  };

  // Function to handle driver details data.
  const fetchDriverDetails = async () => {
    const response = await getSelectedDriver(
      transporterId,
      selectedDriver.driverId
    );

    if (response.status === 200) {
      setSelectedValue(
        response.data?.driver?.isSuperDriver ? "super driver" : "driver"
      );
      setDriverDetails(response.data);
    }
  };

  useEffect(() => {
    fetchDriverDetails();
    setIsDriverActive(selectedDriver?.status === "AVAILABLE" ? true : false);
  }, [selectedDriver]);

  return (
    <StyledDriverCard>
      <TopBar>
        <EditIcon
          src="/assets/icons/editIcon.svg"
          alt="driver edit icon"
          onClick={openEditDriverModal}
        />
        <StatusAndToggle>
          <StatusTypography
            sx={{ color: isDriverActive ? "#00B87C" : "#FF0000" }}
          >
            {isDriverActive ? "Available" : "Not Available"}
          </StatusTypography>
          <ToggleButton toggledState={isDriverActive} onToggle={onToggle} />
        </StatusAndToggle>
      </TopBar>

      <DriverInfoBox>
        <DriverAvatarBox>
          <StyledAvatar
            alt="User Avatar"
            src={driverDetails?.driver?.profilePhoto}
          />
        </DriverAvatarBox>

        <DriverDetailsBox>
          <h2>{selectedDriver?.driverName}</h2>
          <Box>
            <InputLabel
              sx={{
                color: "#333",
                fontFamily: "Helvetica",
                fontSize: "14px",
                fontWeight: 700,
              }}
              htmlFor="driverType"
            >
              Select driver type
            </InputLabel>
            <Select
              value={selectedValue}
              onChange={handleChange}
              sx={{
                width: "150px",
                height: "40px",
              }}
              name="driverType"
            >
              <MenuItem value="super driver">Super Driver</MenuItem>
              <MenuItem value="driver">Driver</MenuItem>
            </Select>
          </Box>
          <Box
            sx={{
              display: "flex",
              margin: "10px 0px",
              fontSize: "14px",
            }}
          >
            Assigned to: {selectedDriver?.truckNumber}
          </Box>

          <Box sx={{ display: "flex", gap: "10px" }}>
            <p>Total trips :</p>
            <span>
              <b>-</b>
            </span>
          </Box>
        </DriverDetailsBox>
      </DriverInfoBox>

      <KYCInfoBox>
        <KYCDetail>
          <Typography
            variant="body1"
            sx={{
              color: "#333",
              fontFamily: "Helvetica",
              fontSize: "14px",
              fontWeight: 700,
            }}
          >
            Driving <br /> License
          </Typography>
          <img
            src={
              driverDetails?.kycDetails?.drivingLicenceDetails
                ?.approvalStatus === "APPROVED"
                ? "/assets/icons/approvalGreenTick.svg"
                : "/assets/icons/red-cross.svg"
            }
            alt={
              driverDetails?.kycDetails?.drivingLicenceDetails
                ?.approvalStatus === "APPROVED"
                ? "green checkmark"
                : "red cross"
            }
            style={{ alignSelf: "center", cursor: "pointer" }}
          />
        </KYCDetail>

        <KYCDetail>
          <Typography
            variant="body1"
            sx={{
              color: "#333",
              fontFamily: "Helvetica",
              fontSize: "14px",
              fontWeight: 700,
            }}
          >
            Aadhar <br /> Card
          </Typography>
          <img
            src={
              driverDetails?.kycDetails?.aadharDetails?.approvalStatus ===
              "APPROVED"
                ? "/assets/icons/approvalGreenTick.svg"
                : "/assets/icons/red-cross.svg"
            }
            alt={
              driverDetails?.kycDetails?.aadharDetails?.approvalStatus ===
              "APPROVED"
                ? "green checkmark"
                : "red cross"
            }
            style={{ alignSelf: "center", cursor: "pointer" }}
          />
        </KYCDetail>

        <KYCDetail>
          <Typography
            variant="body1"
            sx={{
              color: "#333",
              fontFamily: "Helvetica",
              fontSize: "14px",
              fontWeight: 700,
            }}
          >
            Pan <br /> Card
          </Typography>
          <img
            src={
              driverDetails?.kycDetails?.panDetails?.approvalStatus ===
              "APPROVED"
                ? "/assets/icons/approvalGreenTick.svg"
                : "/assets/icons/red-cross.svg"
            }
            alt={
              driverDetails?.kycDetails?.panDetails?.approvalStatus ===
              "APPROVED"
                ? "green checkmark"
                : "red cross"
            }
            style={{ alignSelf: "center", cursor: "pointer" }}
          />
        </KYCDetail>
      </KYCInfoBox>
    </StyledDriverCard>
  );
};
export default DriverCardDetails;

const StyledDriverCard = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  borderBottom: "1px solid #ADADAD",
  padding: "16px",
});

const TopBar = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const EditIcon = styled("img")({
  cursor: "pointer",
});

const StatusAndToggle = styled(Box)({
  display: "flex",
  gap: "10px",
});

const StatusTypography = styled(Typography)({
  fontSize: "14px",
  fontWeight: 700,
});

const DriverInfoBox = styled(Box)({
  border: "1px solid #ADADAD",
  display: "flex",
  gap: "10px",
  padding: "16px",
});

const DriverAvatarBox = styled(Box)({
  width: "150px",
  height: "170px",
});

const StyledAvatar = styled(Avatar)({
  width: 120,
  height: 120,
  marginTop: "1rem",
});

const DriverDetailsBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const KYCInfoBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const KYCDetail = styled(Box)({
  display: "flex",
  padding: "8px 16px",
  alignItems: "center",
  borderRadius: 1,
  background: "#EAEAEA",
  gap: "30px",
});
