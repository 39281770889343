import React from "react";
import { Box, Typography, Grid } from "@mui/material";

import { truncateString } from "../../utils/textFormat";
import WhatsAppAcceptOfferPayment from "./WhatsAppAcceptOfferPayment";

function WhatsAppOfferDetailsAndPayment({
  enquiryDetails,
  pickupAddress,
  dropoffAddress,
  handleOpenToast,
  setStep,
  contactPersonDetails,
}) {
  return (
    <Box>
      {/* Locations Section */}
      <Box
        sx={{
          background: "#ffffff",
          borderRadius: 3,
          boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
          mb: 3,
          overflow: "hidden",
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "16px",
              }}
            >
              <Box sx={{ textAlign: "left" }}>
                <Typography variant="body1" fontWeight={600} color="#4a4a4a">
                  Pickup:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {`${pickupAddress?.addressLine1}, ${pickupAddress?.city}, ${pickupAddress?.state}`}
                </Typography>
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Typography variant="body1" fontWeight={600} color="#4a4a4a">
                  Drop-off:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {`${dropoffAddress?.addressLine1}, ${dropoffAddress?.city}, ${dropoffAddress?.state}`}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                py: 1,
                backgroundColor: "#eaeaea",
              }}
            >
              <Box>
              <img
                src="/assets/icons/distanceArrow.svg"
                alt="Distance"
                style={{ width: "80px", height: "auto" }}
              />
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  ml: 1,
                  alignSelf: "center",
                }}
              >
                {`${enquiryDetails?.distance} km`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          background: "#ffffff",
          padding: "12px",
          borderRadius: 3,
          mb: 3,
          boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
              {truncateString(enquiryDetails?.material?.name, 40)}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Material
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
              {`${Number(enquiryDetails?.weight) / 1000} ton`}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Weight
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
              {enquiryDetails?.quantity || "-"}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              No of Pkgs
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
              {`${enquiryDetails?.customer?.preferredVehicle?.vehicleCategory} Wheeler`}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Vehicle
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* Payment Section */}
      <Box
        sx={{
          background: "#e0f7ef",
          padding: "16px",
          borderRadius: 3,
          boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
        }}
      >
        <Typography
          sx={{ fontSize: 16, fontWeight: 700, mb: 2, color: "#2c662d" }}
        >
          Special Offer Payment Plan
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="bold" color="#4a4a4a">
              Offered Cost:
            </Typography>
            <Typography variant="body2">
              ₹{enquiryDetails?.offers?.bestOffer?.totalCost}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="bold" color="#4a4a4a">
              Platform Charges:
            </Typography>
            <Typography variant="body2">₹0 (0%)</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="bold" color="#4a4a4a">
              Total Cost:
            </Typography>
            <Typography variant="body2">
              ₹{enquiryDetails?.offers?.bestOffer?.totalCost}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {/* razorpay pament */}
      <WhatsAppAcceptOfferPayment
        enquiryDetails={enquiryDetails}
        pickupAddress={pickupAddress}
        dropoffAddress={dropoffAddress}
        handleOpenToast={handleOpenToast}
        setStep={setStep}
        contactPersonDetails={contactPersonDetails}
      />
    </Box>
  );
}

export default WhatsAppOfferDetailsAndPayment;
