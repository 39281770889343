import React from "react";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const SupportedCityContainer = styled(Box)({
  marginTop: "1rem",
  display: "flex",
  gap: "1.5rem",
});

const SupportCityDetails = styled(Box)({
  display: "flex",
  gap: "1rem",
  borderRadius: "0.125rem",
  border: "1px solid #ADADAD",
  background: "#EAEAEA",
  height: "2rem",
  paddingInline: "1rem",
  alignItems: "center",
  justifyContent: "center",
});

const YourComponent = () => {
  return (
    <SupportedCityContainer>
      <SupportCityDetails>
        <Typography variant="body1">Bangalore</Typography>
        <Typography variant="body1">9856 452 123</Typography>
      </SupportCityDetails>
      <SupportCityDetails>
        <Typography variant="body1">Bangalore Rural</Typography>
        <Typography variant="body1">9856 452 123</Typography>
      </SupportCityDetails>
    </SupportedCityContainer>
  );
};

export default YourComponent;
