import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import useToast from "../../../hooks/useToast";

import {
  getAllTransporterTrips,
  getTransporterTripById,
} from "../../../api/Transporter/transporterTrips";
import CountsCard from "./CountsCard";
import DashboardEnquiries from "./DashboardEnquiries";
import DashboardDetailedView from "./DashBoardDetailedView";
import { getTransporterEnquiries } from "../../../api/Transporter/enquiries";
import TripCarousel from "../../Common/TripCarousel";
import {
  setEnquiryStatusTracking,
  setSelectedRow,
} from "../../../redux/slices/transporterSlice";
import { LOADING_STATUS } from "../../../constants/constant";

const EntityTypes = {
  enquiry: "enquiry",
  trip: "trip",
};

const TripTypes = {
  inTransit: "inTransit",
  upComing: "upComing",
};

const TripViews = {
  details: "details",
  map: "map",
  support: "support",
};

export default function TransporterDashboard() {
  const transporterId = useSelector((state) => state.transporterId.id);
  const enquiryStatusTracking = useSelector(
    (state) => state.transporter.enquiryStatusTracking
  );

  const dispatch = useDispatch();

  const { handleOpenToast } = useToast();

  const [inTransitTrips, setInTransitTrips] = useState([]);
  const [upComingTrips, setUpComingTrips] = useState([]);
  const [enquiries, setEnquiries] = useState([]);

  const [entityType, setEntityType] = useState(EntityTypes.enquiry);
  const [entityId, setEntityId] = useState(null);
  const [selectedTripType, setSelectedTripType] = useState(TripTypes.inTransit);
  const [selectedTripView, setSelectedTripView] = useState(TripViews.details);

  const [isInTransitTripsFetched, setIsInTransitTripsFetched] = useState("");
  const [isUpcomingTripsFetched, setIsUpcomingTripsFetched] = useState("");
  const [isEnquiriesFetched, setIsEnquiriesFetched] = useState("");

  const transporterTripsByInTransitStatus = async () => {
    try {
      setIsInTransitTripsFetched(LOADING_STATUS.LOADING);
      const tripStatus = ["TRUCK_IN_TRANSIT"];
      const response = await getAllTransporterTrips(
        transporterId,
        0,
        tripStatus
      );

      if (response?.status === 200) {
        const trips = response.data.data.trips;
        setIsInTransitTripsFetched(LOADING_STATUS.LOADED);

        const tripsData = await Promise.all(
          trips.map(async (trip) => {
            const tripRes = await getTransporterTripById(
              transporterId,
              trip.id
            );
            const tripDetails = tripRes.data.data;
            return {
              id: trip.id,
              route: trip.route,
              pickUp: tripDetails?.enquiryDetails?.pickup?.location?.address,
              dropOff: tripDetails?.enquiryDetails?.dropoff?.location?.address,
              tripId: trip.tripId,
              tripDetails: tripDetails,
            };
          })
        );

        setInTransitTrips(tripsData);
      } else {
        setInTransitTrips([]);
        handleOpenToast("Error while getting inTransit trips", "error");
      }
    } catch (error) {
      setIsInTransitTripsFetched(LOADING_STATUS.FAILED);
    }
  };

  const transporterTripsByUpcomingStatus = async () => {
    try {
      setIsUpcomingTripsFetched(LOADING_STATUS.LOADING);
      const tripStatus = [
        "BOOKING_ACCEPTED",
        "DRIVER_AT_PICKUP_LOCATION",
        "MATERIAL_LOADED",
      ];
      const response = await getAllTransporterTrips(
        transporterId,
        0,
        tripStatus
      );

      if (response?.status === 200) {
        const trips = response.data.data.trips;
        setIsUpcomingTripsFetched(LOADING_STATUS.LOADED);

        const tripsData = await Promise.all(
          trips.map(async (trip) => {
            const tripRes = await getTransporterTripById(
              transporterId,
              trip.id
            );
            const tripDetails = tripRes.data.data;
            return {
              id: trip.id,
              route: trip.route,
              pickUp: tripDetails?.enquiryDetails?.pickup?.location?.address,
              dropOff: tripDetails?.enquiryDetails?.dropoff?.location?.address,
              tripId: trip.tripId,
              tripDetails: tripDetails,
            };
          })
        );
        setUpComingTrips(tripsData);
      } else {
        setUpComingTrips([]);
        handleOpenToast("Error while getting upcoming trips", "error");
      }
    } catch (error) {
      setIsUpcomingTripsFetched(LOADING_STATUS.FAILED);
    }
  };

  const transporterEnquiries = async () => {
    try {
      setIsEnquiriesFetched(LOADING_STATUS.LOADING);
      const status = ["QUOTED", "UNQUOTED", "YOU_QUOTED"];
      const response = await getTransporterEnquiries(transporterId, 0, status);

      if (response.status === 200) {
        const enquiries = response?.data.data.enquiries;
        setEnquiries(enquiries);
        setIsEnquiriesFetched(LOADING_STATUS.LOADED);

        setEntityType(EntityTypes.enquiry);
        if (enquiries[0]) {
          setEntityId(enquiries[0].transporterEnquiryId);
          dispatch(setSelectedRow(enquiries[0]));
          dispatch(setEnquiryStatusTracking(enquiries[0].transporterEnquiryId));
        }
      } else {
        setEnquiries([]);
        handleOpenToast("Error while getting enquiries", "error");
      }
    } catch (error) {
      setIsEnquiriesFetched(LOADING_STATUS.FAILED);
    }
  };

  useEffect(() => {
    if (transporterId) {
      transporterTripsByInTransitStatus();
      transporterTripsByUpcomingStatus();
      transporterEnquiries();
    }
  }, [transporterId, enquiryStatusTracking]);

  const enquiryStatus = enquiries.find(
    (enquiry) => enquiry.transporterEnquiryId === entityId
  )?.enquiryStatus;

  return (
    <Box display="flex" flexDirection="column" gap="10px" height="100%">
      <Box display="flex" flexDirection="row" gap="10px">
        <CountsCard />
        <DashboardEnquiries
          onSelectEnquiry={(id) => {
            setEntityId(id);
            setEntityType(EntityTypes.enquiry);
          }}
          entityId={entityId}
          entityType={entityType}
          enquiries={enquiries}
          getEnquiries={transporterEnquiries}
          fetchStatus={isEnquiriesFetched}
        />
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        gap="10px"
        height="calc(100% - 190px)"
        width="100%"
      >
        <Box display="flex" flexDirection="column" gap="20px">
          <Box height="220px">
            <TripCarousel
              trips={inTransitTrips}
              heading="In Transit"
              emptyMessage="No live trips are available for display at the moment."
              onSelectTrip={(id, view) => {
                setEntityId(id);
                setEntityType(EntityTypes.trip);
                setSelectedTripType(TripTypes.inTransit);
                setSelectedTripView(view);
              }}
              entityId={entityId}
              entityType={entityType}
              selectedTripType={selectedTripType}
              selectedTripView={selectedTripView}
              type={TripTypes.inTransit}
              fetchStatus={isInTransitTripsFetched}
              retry={transporterTripsByInTransitStatus}
            />
          </Box>

          <Box height="220px">
            <TripCarousel
              trips={upComingTrips}
              heading="Upcoming"
              emptyMessage="No upcoming trips are available for display at the moment."
              onSelectTrip={(id, view) => {
                setEntityId(id);
                setEntityType(EntityTypes.trip);
                setSelectedTripType(TripTypes.upComing);
                setSelectedTripView(view);
              }}
              entityId={entityId}
              entityType={entityType}
              selectedTripType={selectedTripType}
              selectedTripView={selectedTripView}
              type={TripTypes.upComing}
              fetchStatus={isUpcomingTripsFetched}
              retry={transporterTripsByUpcomingStatus}
            />
          </Box>
        </Box>

        <Box width="calc(100% - 320px)" height="100%" overflow="auto">
          <DashboardDetailedView
            entityId={entityId}
            transporterId={transporterId}
            enquiryStatus={enquiryStatus}
            enquiries={enquiries}
            entityType={entityType}
            selectedTripType={selectedTripType}
            selectedTripView={selectedTripView}
            trips={
              selectedTripType === TripTypes.inTransit
                ? inTransitTrips
                : upComingTrips
            }
            getTripsByInTransitStatus={transporterTripsByInTransitStatus}
            onSelectTrip={(id, view) => {
              setEntityId(id);
              setEntityType(EntityTypes.trip);
              setSelectedTripType(
                selectedTripType === TripTypes.inTransit
                  ? TripTypes.inTransit
                  : TripTypes.upComing
              );
              setSelectedTripView(view);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
