// Transporter Layout after user has logged in .
import { Box } from "@mui/system";
import Sidebar from "../component/Sidebar/Sidebar";
import { Outlet } from "react-router";
import { AppBar, CssBaseline, Stack, Toolbar, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { transporterRoutes } from "../routes/transporterRoutes";
import { useDispatch, useSelector } from "react-redux";
import { setTransporterName } from "../redux/slices/transporterNameSlice";
import { setTransporterId } from "../redux/slices/transporterIdSlice";
import { getTransporterProfile } from "../api/Transporter/profile";
import { setTransporterProfile } from "../redux/slices/transporterProfileSlice";
import GetInTouch from "../component/Common/GetInTouch";
import { getToken } from "@firebase/messaging";
import NotificationPopup from "../component/NotificationPopup";
import { messaging, onMessageListener } from "../firebase";
import { updateTransporterFCMToken } from "../api/fcmToken";
import useToast from "../hooks/useToast";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { setTransporterNotificationPreference } from "../redux/slices/transporterSlice";

const TransporterLayout = () => {
  const [isNotificationPanel, setIsNotificationPanel] = useState(false);
  const [ftueStatus, setftueStatus] = useState("");
  const dispatch = useDispatch();
  const { handleOpenToast, ToastMessage } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const profileName = useSelector((state) => state.transporterName?.name);
  const number = useSelector(
    (state) => state.supportNumbers?.transporterPortal
  );
  const transporterfcmToken = sessionStorage.getItem("transporterfcmToken");

  const [isTokenFound, setTokenFound] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isSnackbarOpen, setisSnackbarOpen] = useState(false);

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      setisSnackbarOpen(true);
    })
    .catch((err) => {
      console.log("failed: ", err);
    });

  const generateFCMToken = async () => {
    try {
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      });
      return token;
    } catch (err) {
      console.log(err);
      return;
    }
  };

  const updateFCMToken = async () => {
    const fcmtoken = await generateFCMToken();
    if (fcmtoken) {
      // add fcm token to user document
      await updateTransporterFCMToken(fcmtoken);
      sessionStorage.setItem("transporterfcmToken", JSON.stringify(fcmtoken));
      setTokenFound(true);
    }
  };

  async function requestUserPermission() {
    if (transporterfcmToken) {
      return;
    }
    if (Notification.permission === "denied") {
      alert(
        "Notifications are blocked. Please enable them in your browser settings."
      );
    } else if (Notification.permission === "default") {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        console.log("Notification permission granted.");
        // You can now send notifications
        updateFCMToken();
      }
    } else if (Notification.permission === "granted") {
      updateFCMToken();
    }
  }

  useEffect(() => {
    if (localStorage.getItem("ftueStatus") !== "PENDING") {
      getTransporterProfile()
        .then((response) => {
          const currentftueStatus = response.data.transporter?.ftueStatus;
          setftueStatus(currentftueStatus);
          dispatch(setTransporterId(response.data.transporter._id));
          if (currentftueStatus === "DONE") requestUserPermission();
          dispatch(setTransporterName(response.data.transporter.fullName));
          dispatch(setTransporterProfile(response.data?.transporter));
          dispatch(
            setTransporterNotificationPreference(
              response.data?.transporter?.notificationPreferences
            )
          );
        })
        .catch((err) => {
          handleOpenToast("Something went wrong. Please refresh page");
        });
    }
  }, []);

  const token = Cookies.get("transportertoken");
  // if token is present means user is authorized and can access app
  if (!token || token === undefined) {
    return <Navigate to="/" replace />;
  }
  return (
    <Box sx={{ height: "100vh" }}>
      <ToastMessage />
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          boxShadow: "none",
          background: "#fff",
          borderBottom: "1px solid #adadad",
        }}
      >
        <Toolbar sx={{ height: "6rem" }}>
          <Stack direction="row" alignItems="center" spacing="1.25rem">
            <img
              src="/assets/icons/LZ-logo-face.svg"
              style={{ marginBottom: ".35rem" }}
              alt="logoface"
            />
            <img src="/assets/icons/LZ-logo.svg" alt="logo" />
          </Stack>

          <Box
            sx={{
              marginLeft: "auto",
              display: "flex",
              gap: "2.5rem",
              alignItems: "center",
            }}
          >
            {ftueStatus === "DONE" && (
              <NotificationsActiveOutlinedIcon
                sx={{
                  color: "#333",
                  background: location.pathname.includes(
                    "transporter/notification"
                  )
                    ? "#DDE7EF"
                    : "#fff",
                  cursor: "pointer",
                  borderRadius: 1,
                  fontSize: 26,
                }}
                onClick={() => {
                  navigate("notifications");
                }}
              />
            )}
            <Box>
              <Typography color="#333" fontWeight={700}>
                {profileName ? profileName : "Guest"}
              </Typography>
              <Typography color="#0C7F44">LoadEazy Partner</Typography>
            </Box>
            <Box>
              <GetInTouch />
              <Typography color="#0C7F44" fontWeight={700} align="right">
                {number}
              </Typography>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100vw",
        }}
      >
        {!location.pathname.endsWith("/transporter/notifications") && (
          <Sidebar routes={transporterRoutes} />
        )}
        <Box mt="6rem" sx={{ flexGrow: 1 }}>
          {/* <Toolbar sx={{ height: "6rem" }} /> */}
          <Outlet />
        </Box>
      </Box>
      <NotificationPopup
        isOpen={isSnackbarOpen}
        header={notification.title}
        message={notification.body}
        setisSnackbarOpen={setisSnackbarOpen}
      />
    </Box>
  );
};
export default TransporterLayout;
