import React, { useState, useEffect } from "react";
import { Box, Typography, Button, TextField, MenuItem } from "@mui/material";
import styled from "@emotion/styled";

import SimpleCard from "../../Common/SimpleCard";
import SupportNumberModal from "./SupportNumberModal";
import AmountReceived from "./AmountReceived";
import CustomStepperProgressBar from "../../Common/CustomStepperProgressBar";

import {
  CANCELLED_TRIP_STEPPER_STATES,
  TRIP_STEPPER_STATES,
} from "../../../constants/constant";
import moment from "moment";
import {
  getLinkedDriversForDropdown,
  updateDriverReAssignment,
} from "../../../api/Transporter/truck";
import useToast from "../../../hooks/useToast";
import { useSelector } from "react-redux";

const StyledBox = styled(Box)(({ unAssigned, cancel }) => ({
  display: "flex",
  flexDirection: "column",
  height: "auto",
  width: "100%",
  backgroundColor: cancel ? "#F6EDED" : "#ddefdd",
  border: `${unAssigned ? "2px" : "1px"} solid ${
    unAssigned ? "red" : "#333333"
  }`,
  padding: "20px",
}));

const StyledText = styled(Typography)(
  ({ fontSize, textTransform, fontWeight }) => ({
    color: "#4F4848",
    fontSize: fontSize || "12px",
    fontWeight: fontWeight || "400",
    textTransform: textTransform || "capitalize",
  })
);

const StyledTypography = styled(Typography)(() => ({
  display: "flex",
  marginRight: "40px",
  alignItems: "end",
  justifyContent: "end",
  color: "#FF3F3F",
  fontSize: "14px",
  fonWeight: "400",
  textDecoration: "underline",
  marginBottom: "30px",
}));

const StyledSubHeading = styled(Typography)(({ color, textAlign }) => ({
  color: color || "#333333",
  textAlign: textAlign || "center",
  fontSize: "16px",
  fontWeight: "700",
}));

const StyledLine = styled(Typography)(() => ({
  border: "1px solid #6A6A6A",
}));

const BoxContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "30px",
  justifyContent: "center",
  width: "100%",
}));

const StyledBoxContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));

export default function TripDetails(props) {
  const transporterId = useSelector((state) => state.transporterId.id);

  const { tripDetails, fromDashboard, trips, entityId } = props;
  const { handleOpenToast, ToastMessage } = useToast();

  const tripStatus = tripDetails?.tripStatus;
  const currentTripId = trips?.map(
    (trip) => trip.id === entityId && trip.tripId
  );
  const [activeStep, setActiveStep] = useState(1);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState("");
  const [driversList, setDriversList] = useState([]);

  const getDriversForDropdown = async () => {
    try {
      const response = await getLinkedDriversForDropdown(transporterId);
      if (response.status === 200) {
        setDriversList(response.data.data);
      }
    } catch (error) {
      handleOpenToast("Error while fetching drivers list", "error");
    }
  };

  const driverReAssignment = async (driverId) => {
    try {
      const response = await updateDriverReAssignment(
        transporterId,
        tripDetails.id,
        driverId
      );

      if (response.status === 200) {
        props.updateDriver(response.data.data);
        handleOpenToast("Driver reassigned successfully", "success");
      }
    } catch (error) {
      handleOpenToast("Error while assigning driver", "error");
    }
  };

  const handleSelectDriver = async (event) => {
    const driverId = event.target.value;
    driverReAssignment(driverId);
    setSelectedDriver(event.target.value);
  };

  useEffect(() => {
    if (tripDetails?.driver) {
      setSelectedDriver(tripDetails?.driver?.driverId);
    }
  }, [tripDetails]);

  useEffect(() => {
    getDriversForDropdown();
  }, []);

  useEffect(() => {
    setActiveStep(
      tripStatus?.includes("CANCELLED")
        ? CANCELLED_TRIP_STEPPER_STATES[tripStatus]
        : TRIP_STEPPER_STATES[tripStatus]
    );
  }, [tripStatus]);

  const deliveredDate = () => {
    if (tripDetails?.tripCompletedTimeStamp) {
      return moment(tripDetails?.tripCompletedTimeStamp).format(
        "Do MMMM YYYY hh:mm A"
      );
    }
    if (
      tripDetails?.truckInTransitTimeStamp >
      tripDetails?.deliveryExpectedTimeStamp
    ) {
      return "";
    }
    if (
      tripDetails?.materialLoadedTimeStamp >
      tripDetails?.deliveryExpectedTimeStamp
    ) {
      return "";
    }
    if (
      tripDetails?.driverAtPickUpTimeStamp >
      tripDetails?.deliveryExpectedTimeStamp
    ) {
      return "";
    }

    return tripDetails?.deliveryExpectedTimeStamp
      ? `Expected By ${moment(tripDetails?.deliveryExpectedTimeStamp).format(
          "Do MMMM YYYY"
        )}`
      : "";
  };

  const TRANSPORTER_TRIP_STEPPER_LABELS = [
    {
      label: "Booking Accepted",
      description:
        tripDetails?.bookingAcceptedTimestamp &&
        moment(tripDetails?.bookingAcceptedTimestamp).format(
          "Do MMMM YYYY hh:mm A"
        ),
    },
    {
      label: "Driver At Pickup Location",
      description:
        tripDetails?.driverAtPickUpTimeStamp &&
        moment(tripDetails?.driverAtPickUpTimeStamp).format(
          "Do MMMM YYYY hh:mm A"
        ),
    },
    {
      label: "Material Loaded",
      description:
        tripDetails?.materialLoadedTimeStamp &&
        moment(tripDetails?.materialLoadedTimeStamp).format(
          "Do MMMM YYYY hh:mm A"
        ),
    },
    {
      label: "In Transit",
      description:
        tripDetails?.truckInTransitTimeStamp &&
        moment(tripDetails?.truckInTransitTimeStamp).format(
          "Do MMMM YYYY hh:mm A"
        ),
    },
    {
      label: "Delivered",
      description: deliveredDate(),
    },
  ];

  const CANCELLED_TRIP_STEPPER_LABELS = [
    {
      label: "Booking Accepted",
      description:
        tripDetails?.bookingAcceptedTimestamp &&
        moment(tripDetails?.bookingAcceptedTimestamp).format(
          "Do MMMM YYYY hh:mm A"
        ),
    },
    {
      label: `Cancelled By ${
        tripDetails?.cancellation?.requestedBy === "CUSTOMER"
          ? "Customer"
          : tripDetails?.cancellation?.requestedBy === "TRANSPORTER"
          ? "You"
          : "Admin"
      }`,
      description:
        tripDetails?.cancellation?.cancelledAt &&
        moment(tripDetails?.cancellation?.cancelledAt).format(
          "Do MMMM YYYY hh:mm A"
        ),
    },
  ];

  return (
    <>
      <ToastMessage />
      <StyledBox
        unAssigned={!tripDetails?.driver?.driverName}
        cancel={tripDetails?.tripStatus?.includes("CANCELLED")}
      >
        {fromDashboard && (
          <Box fontWeight={700} fontSize={14}>
            #{currentTripId}{" "}
          </Box>
        )}

        <BoxContainer>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="60%"
            gap="10px"
          >
            <Box
              width="40%"
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <StyledText>
                <Box
                  textOverflow="ellipsis"
                  width={fromDashboard ? "130px" : "200px"}
                  overflow="hidden"
                  whiteSpace="nowrap"
                >
                  {tripDetails?.enquiryDetails?.pickup.exactLocation.address},
                </Box>
                {tripDetails?.enquiryDetails?.pickup.exactLocation.city},
                {tripDetails?.enquiryDetails?.pickup.exactLocation.state},
                {tripDetails?.pickupContactPerson?.name && (
                  <Box fontWeight="700">
                    {tripDetails?.pickupContactPerson?.name},
                    {tripDetails?.pickupContactPerson?.phoneNumber}
                  </Box>
                )}
              </StyledText>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="start"
              width="20%"
            >
              <Typography
                textAlign="center"
                fontSize="12px"
                pl={fromDashboard ? "" : "20px"}
              >
                {tripDetails?.enquiryDetails?.distance} Km
              </Typography>
              <img
                src="/assets/icons/distanceArrow.svg"
                alt="addImage"
                height="20px"
                width="60%"
              />
            </Box>

            <Box
              width="40%"
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <StyledText>
                <Box
                  textOverflow="ellipsis"
                  width={fromDashboard ? "130px" : "200px"}
                  overflow="hidden"
                  whiteSpace="nowrap"
                >
                  {tripDetails?.enquiryDetails?.dropoff.exactLocation.address},
                </Box>
                {tripDetails?.enquiryDetails?.dropoff.exactLocation.city},
                {tripDetails?.enquiryDetails?.dropoff.exactLocation.state},
                {tripDetails?.dropOffContactPerson?.name && (
                  <Box fontWeight="700">
                    {tripDetails?.dropOffContactPerson?.name},
                    {tripDetails?.dropOffContactPerson?.phoneNumber}
                  </Box>
                )}
              </StyledText>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" width="40%">
            <Box
              display="flex"
              flexDirection="row"
              paddingBottom="15px"
              marginTop="15px"
              gap="10px"
              width="100%"
            >
              <SimpleCard
                heading={tripDetails?.enquiryDetails?.material?.name}
                subHeading={"Material"}
                width="50%"
                fromDashboard={fromDashboard}
              />
              <SimpleCard
                heading={`${tripDetails?.enquiryDetails?.weight / 1000} ${
                  tripDetails?.enquiryDetails?.weight / 1000 <= 1
                    ? "Ton"
                    : "Tons"
                }`}
                subHeading={"Weight"}
                width="50%"
                fromDashboard={fromDashboard}
              />
            </Box>
          </Box>
        </BoxContainer>

        <StyledLine />

        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          p="20px"
        >
          <Box display="flex">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between"
              borderRight="1px dotted #333"
              pr="20px"
            >
              <StyledText fontSize="14px">Total amount</StyledText>
              <StyledSubHeading textAlign="left">
                ₹{" "}
                {tripDetails?.transporterAmounts?.bidAmount.toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </StyledSubHeading>
            </Box>

            <Box display="flex" flexDirection="column" paddingLeft="20px">
              <AmountReceived tripDetails={tripDetails} />

              {!tripDetails?.tripStatus?.includes("CANCELLED") && (
                <Box display="flex" gap="20px" alignItems="center">
                  <StyledText width="140px" fontSize="14px">
                    Balance Receivable
                  </StyledText>
                  <StyledSubHeading
                    textTransform="none"
                    textAlign="left"
                    sx={{ width: "100px" }}
                  >
                    ₹{" "}
                    {tripDetails?.transporterAmounts?.balancePayable.toLocaleString(
                      "en-US",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </StyledSubHeading>
                  <StyledText textTransform="none">
                    to be received by{" "}
                    {tripDetails?.transportPaymentSettlementDeadline
                      ? moment(
                          tripDetails?.transportPaymentSettlementDeadline
                        ).format("DD-MM-YYYY")
                      : "-"}
                  </StyledText>
                </Box>
              )}
              {tripDetails?.tripStatus === "CANCELLED_BY_TRANSPORTER" && (
                <Box display="flex" gap="20px" alignItems="center">
                  <StyledText width="140px" fontSize="14px">
                    Penalty Charge
                  </StyledText>
                  <StyledSubHeading
                    textTransform="none"
                    textAlign="left"
                    color="red"
                    sx={{ width: "100px" }}
                  >
                    ₹{" "}
                    {tripDetails?.cancellation?.penaltyCharge?.toLocaleString(
                      "en-US",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </StyledSubHeading>
                </Box>
              )}
            </Box>
          </Box>

          {!tripDetails?.driver?.driverName && (
            <StyledTypography
              sx={{ cursor: "pointer" }}
              onClick={() => setIsCancelModalOpen(true)}
            >
              Cancel Trip
            </StyledTypography>
          )}
        </Box>

        <StyledLine />

        <CustomStepperProgressBar
          steps={
            tripDetails?.tripStatus?.includes("CANCELLED")
              ? CANCELLED_TRIP_STEPPER_LABELS
              : TRANSPORTER_TRIP_STEPPER_LABELS
          }
          activeStep={activeStep}
          fromDashboard={fromDashboard}
        />

        <StyledLine />

        <Box paddingBottom="10px" width="100%">
          <Box display="flex" flexDirection="row">
            <StyledText
              fontSize={fromDashboard ? "14px" : "18px"}
              fontWeight="700"
              marginTop="10px"
              width="120px"
            >
              Driver Details
            </StyledText>
          </Box>

          <StyledBoxContainer>
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              pt="20px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                flexDirection="row"
                gap="20px"
                alignItems="center"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  ml={tripDetails?.driver?.driverName ? "20px" : "0px"}
                >
                  <StyledText mr="3px">Vehicle Assigned:</StyledText>
                  <StyledText fontWeight="700">
                    {tripDetails?.truck?.VehicleNumber}
                  </StyledText>
                </Box>
                {tripDetails?.driver?.driverId ? (
                  <>
                    <Box display="flex" flexDirection="row">
                      <StyledText mr="3px">Name:</StyledText>
                      {tripDetails.tripStatus === "BOOKING_ACCEPTED" && (
                        <TextField
                          variant="outlined"
                          placeholder="Select"
                          fullWidth
                          InputProps={{
                            style: { height: "22px", width: "150px" },
                          }}
                          select
                          value={selectedDriver || null}
                          onChange={handleSelectDriver}
                        >
                          {driversList?.length > 0 &&
                            driversList.map((driver) => (
                              <MenuItem key={driver?._id} value={driver?._id}>
                                {driver.driverName}
                              </MenuItem>
                            ))}
                        </TextField>
                      )}
                      {tripDetails.tripStatus === "TRUCK_IN_TRANSIT" && (
                        <StyledText fontWeight="700">
                          {tripDetails?.driver?.driverName}
                        </StyledText>
                      )}
                    </Box>
                    <Box display="flex" flexDirection="row">
                      <StyledText mr="3px">Phone Number:</StyledText>
                      <StyledText fontWeight="700">
                        {tripDetails?.driver?.driverContact}
                      </StyledText>
                    </Box>
                  </>
                ) : (
                  <Box>
                    <Box fontWeight="700" fontSize="14px">
                      Assign Driver
                    </Box>

                    <Box color="red" fontWeight="700" fontSize="14px">
                      Driver Unassigned
                    </Box>
                    <Box color="red" fontWeight="700" fontSize="12px">
                      Please assign driver to vehicle{" "}
                      {tripDetails?.truck?.VehicleNumber} in vehicle list
                      section.
                    </Box>
                  </Box>
                )}
              </Box>
              <Box display="flex" justifyContent="end">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button
                    variant="supportButton"
                    onClick={() => setIsSupportModalOpen(true)}
                    sx={{
                      color: "#333",
                      fontWeight: "700",
                      textTransform: "none",
                      cursor: "pointer",
                    }}
                  >
                    Support
                  </Button>
                </Box>
              </Box>
            </Box>
          </StyledBoxContainer>
        </Box>
      </StyledBox>

      <SupportNumberModal
        open={isCancelModalOpen}
        close={setIsCancelModalOpen}
        cancelText="To cancel this trip please call our support team."
      />

      <SupportNumberModal
        open={isSupportModalOpen}
        close={setIsSupportModalOpen}
      />
    </>
  );
}
