import { createSlice } from '@reduxjs/toolkit';

const userAuthenticationSlice = createSlice({
  name: 'user',
  initialState: {
    phone: '',
    hash:'',
    portal:'',
    isEnquiryFlow:false
  },
  reducers: {
    setUserCredentials: (state, action) => {
      state.phone = action.payload.phone;
      state.hash = action.payload.hash;
      state.portal = action.payload.portal;
      state.isEnquiryFlow = action.payload?.isEnquiryFlow ? action.payload?.isEnquiryFlow : false
    },
  },
});

export const { setUserCredentials } = userAuthenticationSlice.actions;
export default userAuthenticationSlice.reducer;
