import { Outlet } from "react-router-dom";
import TransporterResources from "../pages/Admin/Resources/TransporterResources";
import Approval from "../pages/Admin/Approval/Approval";
import ApprovalDetails from "../pages/Admin/Approval/ApprovalDetails";
import SupportNumber from "../pages/Admin/Configuration/SupportNumber";
import Inventory from "../pages/Admin/Configuration/Inventory";
import TransporterResourcesDetailedView from "../pages/Admin/Resources/TransporterResourcesDetailedView";
import Support from "../pages/Admin/Suppport/Support";
import CreateSupportTicketPage from "../pages/Admin/Suppport/CreateSupportTicketPage";
import EditSupportTicket from "../pages/Admin/Suppport/EditSupportTicket";
import DriverResources from "../pages/Admin/Resources/DriverResources";
import TrucksResources from "../pages/Admin/Resources/TrucksResources";
import TruckResourcesDetailedView from "../pages/Admin/Resources/TrucksResourcesDetailedView";
import DriverResourcesDetailedView from "../pages/Admin/Resources/DriverResourcesDetailedView";
import TimeRelated from "../pages/Admin/Configuration/TimeRelated";
import Enquiries from "../pages/Admin/Enquiries/Enquiries";
import AdminEnquiryDetailedView from "../component/Admin/Configurations/Enquiries/AdminEnquiryDetailedView";
import Trips from "../pages/Admin/Trips";
import TripDetailedView from "../component/Admin/Trips/TripDetailedView";
import CustomerResources from "../pages/Admin/Resources/CustomerResources";
import CustomerDetailsView from "../component/AdminResources/CustomerDetailsView";
import Dashboard from "../pages/Admin/Dashboard/Dashboard";
import VehicleTracker from "../pages/Admin/Resources/VehicleLocationTracker";

const iconStyle = { width: "24px", height: "24px" };

export const adminRoutes = [
  {
    path: "dashboard",
    element: <Dashboard />,
    state: "dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: (
        <img
          src="/assets/sidebarIcons/dashboard.svg"
          style={iconStyle}
          alt="dashboard"
        />
      ),
    },
  },
  {
    path: "enquiries",
    element: <Enquiries />,
    state: "enquiries",
    sidebarProps: {
      displayText: "Enquiries",
      icon: (
        <img
          src="/assets/sidebarIcons/enquiry.svg"
          style={iconStyle}
          alt="enquiry"
        />
      ),
    },
  },
  {
    path: "enquiries/:id",
    element: <AdminEnquiryDetailedView />,
    state: "enquiries",
  },

  {
    path: "trips",
    element: <Trips />,
    state: "trips",
    sidebarProps: {
      displayText: "Trips",
      icon: (
        <img
          src="/assets/sidebarIcons/trips.svg"
          style={iconStyle}
          alt="trips"
        />
      ),
    },
  },
  {
    path: "trips/:id",
    element: <TripDetailedView />,
    state: "trips",
  },
  {
    path: "",
    element: <Outlet />,
    state: "resources",
    sidebarProps: {
      displayText: "Resources",
      icon: (
        <img
          src="/assets/sidebarIcons/resources.svg"
          style={iconStyle}
          alt="resources"
        />
      ),
    },
    child: [
      {
        path: "resources/customers",
        element: <CustomerResources />,
        state: "resources.customers",
        sidebarProps: {
          displayText: "Customers",
        },
      },
      {
        path: "resources/customers/:id",
        element: <CustomerDetailsView />,
        state: "resources.customers",
      },
      {
        path: "resources/transporters",
        element: <TransporterResources />,
        state: "resources.transporters",
        sidebarProps: {
          displayText: "Transporters",
        },
      },
      {
        path: "resources/transporters/:id",
        element: <TransporterResourcesDetailedView />,
        state: "resources.transporters",
      },
      {
        path: "resources/trucks",
        element: <TrucksResources />,
        state: "resources.trucks",
        sidebarProps: {
          displayText: "Trucks",
        },
      },
      {
        path: "resources/trucks/:id",
        element: <TruckResourcesDetailedView />,
        state: "resources.trucks",
      },

      {
        path: "resources/drivers",
        element: <DriverResources />,
        state: "resources.drivers",
        sidebarProps: {
          displayText: "Drivers",
        },
      },
      {
        path: "resources/drivers/:id",
        element: <DriverResourcesDetailedView />,
        state: "resources.drivers",
      },
      {
        path: "resources/VehicleLocations",
        element: <VehicleTracker />,
        state: "resources.VehicleLocations",
        sidebarProps: {
          displayText: "Vehicle Locations",
        },
      },
    ],
  },
  {
    path: "approval",
    element: <Approval />,
    state: "approval",
    sidebarProps: {
      displayText: "Approval",
      icon: (
        <img
          src="/assets/sidebarIcons/approval.svg"
          style={iconStyle}
          alt="trips"
        />
      ),
    },
  },
  {
    path: "approval/:id",
    element: <ApprovalDetails />,
    state: "approval",
  },
  {
    path: "",
    state: "configs",
    element: (
      <>
        <Outlet />
      </>
    ),
    sidebarProps: {
      displayText: "Configuration",
      icon: (
        <img
          src="/assets/sidebarIcons/config.svg"
          style={iconStyle}
          alt="trips"
        />
      ),
    },
    child: [
      {
        path: "config/inventory",
        element: <Inventory />,
        state: "configs.customers",
        sidebarProps: {
          displayText: "Inventory",
        },
      },
      {
        path: "config/TimeRelated",
        element: <TimeRelated />,
        state: "configs.TimeRelated",
        sidebarProps: {
          displayText: "Time Related",
        },
      },
      {
        path: "config/supportNumber",
        element: <SupportNumber />,
        state: "configs.supportNumber",
        sidebarProps: {
          displayText: "Support Number",
        },
      },
    ],
  },
  {
    path: "support",
    element: <Support />,
    state: "support",
    sidebarProps: {
      displayText: "Support",
      icon: (
        <img
          src="/assets/sidebarIcons/support.svg"
          style={iconStyle}
          alt="trips"
        />
      ),
    },
  },
  {
    path: "support/createTicket",
    element: <CreateSupportTicketPage />,
    state: "support",
  },
  {
    path: "support/editTicket/:id",
    element: <EditSupportTicket />,
    state: "support",
  },
];
