import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import KYCDocumentsImageUploader from "../../../component/Common/KYCDocumentImageUploader";
import { useState } from "react";
import { StyledSubmitButton } from "./StyledSubmitButton";
import axios from "axios";
import { BASE_URL } from "../../../api/config";
import Cookies from "js-cookie";
import KycUpdatesAlert from "../../../component/Common/KycUpdatesMessages";
import { LOADING_STATUS } from "../../../constants/constant";
import { submitAadhaarDocuments } from "../../../api/Transporter/kycDetails";

const Aadhaar = (props) => {
  const { expanded, handleChange, userAdhaarDetails, setProfileStatus } = props;
  const [aadhaarStatus, setAadhaarStatus] = useState(
    userAdhaarDetails?.approvalStatus
  );

  const [aadhaarNumber, setAadhaarNumber] = useState(
    userAdhaarDetails?.documentNumber
  );
  const [aadhaarFrontImage, setAadhaarFrontImage] = useState(
    userAdhaarDetails?.frontUrl
  );
  const [aadhaarRearImage, setAadhaarRearImage] = useState(
    userAdhaarDetails?.backUrl
  );
  const [reasonOfRejection, setReasonOfRejection] = useState(
    userAdhaarDetails?.reasonOfRejection
  );
  const [isAadhaarSubmitted, setIsAadhaarSubmitted] = useState(
    LOADING_STATUS.INITIAL
  );
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  // error handling states for aadhaar Number field and image field.
  const [isAadhaarValid, setIsAadhaarValid] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const [aadhaarFrontImageSizeError, setAadhaarFrontImageSizeError] = useState("");
  const [aadhaarBackImageSizeError, setAadhaarBackImageSizeError] = useState("");

  // Funtion handles aadhaar number input field values on change
  const handleAadhaarNumberInputChange = (event) => {
    if (isNaN(event.target.value)) {
      return;
    } else setAadhaarNumber(event.target.value);
    if (event.target.value.length === 12) setIsAadhaarValid(true);
  };

  // Function helps in closing snackbar . this function is passed in common snackbar component to handle visibility of snackbar.
  const handleOnClose = () => {
    setShowSnackbar(false);
  };

  // Function does validation on entered inputs and submits documents to server.
  const handleAadhaarSubmit = async () => {
    if (!aadhaarNumber) {
      setIsAadhaarValid(false);
      return;
    }
    if (aadhaarNumber?.length <= 11 || aadhaarNumber?.length > 12) {
      setIsAadhaarValid(false);
      return;
    }
    if (!aadhaarFrontImage && !aadhaarRearImage) {
      setSnackbarMessage({
        msg: "Please Select an image",
        severity: "error",
      });
      setShowSnackbar(true);
      return;
    }
    if (!aadhaarFrontImage) {
      setSnackbarMessage({
        msg: "Please Select Front image",
        severity: "error",
      });
      setShowSnackbar(true);
      return;
    }
    if (!aadhaarRearImage) {
      setSnackbarMessage({
        msg: "Please Select Rear image",
        severity: "error",
      });
      setShowSnackbar(true);
      return;
    }
    if (aadhaarFrontImage === aadhaarRearImage) {
      setSnackbarMessage({
        msg: "Cannot upload same image twice",
        severity: "error",
      });
      setShowSnackbar(true);
      return;
    }
    // collecting user input for aadhaar details
    const aadhaarDocs = {
      documentNumber: aadhaarNumber,
      frontUrl: aadhaarFrontImage,
      backUrl: aadhaarRearImage,
    };
    try {
      setIsAadhaarSubmitted(LOADING_STATUS.LOADING);
      const response = await submitAadhaarDocuments(aadhaarDocs);
      setReasonOfRejection(
        response.data.kycDetails.kycDetails.aadharDetails?.reasonOfRejection
      );
      setIsAadhaarSubmitted(LOADING_STATUS.LOADED);
      setAadhaarStatus(
        response.data.kycDetails.kycDetails.aadharDetails?.approvalStatus
      );
      setSnackbarMessage({
        msg: "Submitted Successfully.",
        severity: "success",
      });
      setProfileStatus(response.data.kycDetails?.kycDetails?.profileStatus);
      setAadhaarFrontImageSizeError('')
      setAadhaarBackImageSizeError('')
    } catch (err) {
      setIsAadhaarSubmitted(LOADING_STATUS.FAILED);
      setSnackbarMessage({ msg: "Something went wrong.", severity: "error" });
    }
    setShowSnackbar(true);
  };

  const approvalStatusMarker = () => {
    if (userAdhaarDetails?.approvalStatus === "REJECTED" && reasonOfRejection) {
      return <img src="/assets/icons/red-cross.svg" alt="rejected" />;
    }
    if (userAdhaarDetails?.approvalStatus === "APPROVED") {
      return <img src="/assets/icons/green-tick.svg" alt="approved" />;
    }
  };
  const getApprovalTextMessage = () => {
    switch (aadhaarStatus) {
      case "REJECTED":
        return (
          <Typography color="red" fontSize="14px" fontWeight="700">
            {" "}
            Rejection Reason : {reasonOfRejection}
          </Typography>
        );
      case "APPROVED":
        return (
          <Typography color="green" fontSize="14px" fontWeight="700">
            Your Aadhaar Details are approved
          </Typography>
        );
      case "PENDING":
        return (
          <Typography color="#333" fontSize="14px" fontWeight="700">
            This document will be reviewed by LoadEazy Team
          </Typography>
        );
      default:
        return (
          <Typography color="#333" fontSize="14px" fontWeight="700">
            Please submit documents to complete KYC.
          </Typography>
        );
    }
  };

  return (
    <>
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        sx={{ boxShadow: "none", borderRadius: ".35rem" }}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={
            <img src="/assets/icons/expand_less.svg" alt="expand_less" />
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            background: "#EAEAEA",
            paddingLeft: "2rem",
            fontWeight: 700,
            height: "3rem",
            "&.Mui-expanded": {
              minHeight: "3rem",
            },
          }}
        >
          <Typography sx={{ flexShrink: 0, fontWeight: 700 }}>
        Aadhaar Details* &nbsp;
          </Typography>
          {approvalStatusMarker()}
        </AccordionSummary>

        <AccordionDetails
          sx={{
            background: "#FFF",
            paddingLeft: "2rem",
            paddingTop: "2rem",
          }}
        >
          <Box maxWidth="sm">
            <InputLabel sx={{ color: "#333", fontSize: "14px" }}>
              {"Aadhaar Number"}
            </InputLabel>

            <TextField
              placeholder="000000000000"
              required
              value={aadhaarNumber}
              fullWidth
              inputProps={{
                maxLength: 12,
                style: { color: "#333", fontWeight: 700 },
              }}
              onChange={handleAadhaarNumberInputChange}
              error={
                !isAadhaarValid ||
                (!isAadhaarValid && aadhaarNumber?.length !== 12)
              }
              helperText={
                !isAadhaarValid ||
                (!isAadhaarValid && aadhaarNumber?.length !== 12)
                  ? "Invalid Aadhaar Number*"
                  : ""
              }
            />
            <Stack direction="row" justifyContent="space-between" mt={3}>
              <KYCDocumentsImageUploader
                documentType="aadhaar"
                side="front"
                setDocumentImage={setAadhaarFrontImage}
                displayImage={aadhaarFrontImage}
                imageSizeError={aadhaarFrontImageSizeError}
                setImageSizeError={setAadhaarFrontImageSizeError}
              />
              <KYCDocumentsImageUploader
                documentType="aadhaar"
                side="back"
                setDocumentImage={setAadhaarRearImage}
                displayImage={aadhaarRearImage}
                imageSizeError={aadhaarBackImageSizeError}
                setImageSizeError={setAadhaarBackImageSizeError}
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mt={3}
            >
              {getApprovalTextMessage()}

              {userAdhaarDetails?.approvalStatus !== "APPROVED" && (
                <StyledSubmitButton
                  variant="contained"
                  color="primary"
                  onClick={handleAadhaarSubmit}
                  disabled={isAadhaarSubmitted === "loading"}
                >
                  {"Submit"}
                </StyledSubmitButton>
              )}
            </Stack>
          </Box>
        </AccordionDetails>
      </Accordion>

      {showSnackbar && (
        <KycUpdatesAlert
          handleOnClose={handleOnClose}
          open={showSnackbar}
          message={snackbarMessage.msg}
          severity={snackbarMessage.severity}
        />
      )}
    </>
  );
};
export default Aadhaar;
