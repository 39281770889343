import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import React from "react";
import Aadhaar from "./Aadhaar";
import PAN from "./PAN";
import GST from "./GST";
import BusinessLicense from "./BusinessLicense";
import { LOADING_STATUS } from "../../../constants/constant";
import KYCLoadingScreen from "../../../component/Common/KYCLoadingScreen";
import ErrorScreen from "../../../component/Common/ErrorScreen";
import { useNavigate } from "react-router-dom";
import TransporterOnboardingModal from "../../../component/Common/TransporterOnboardingModal";
import { getKycDetails } from "../../../api/Transporter/kycDetails";

const KYC = () => {
  const navigate = useNavigate();
  const [profileStatus, setProfileStatus] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [userAdhaarDetails, setUserAadharDetails] = useState(null);
  const [userPanDetails, setUserPanDetails] = useState(null);
  const [userBusinessLicenseNumber, setUserBusinessLicenseNumber] =
    useState(null);
  const [userGstNumber, setUserGstNumber] = useState(null);

  const [isKycDetailsFetched, setIsKycDetailsFetched] = useState(
    LOADING_STATUS.INITIAL
  );
  const [
    isTransporterOnboardingModalOpen,
    setIsTransporterOnboardingModalOpen,
  ] = useState(false);

  useEffect(() => {
    fetchKycDetails();
  }, []);

  const fetchKycDetails = async () => {
    try {
      setIsKycDetailsFetched(LOADING_STATUS.LOADING);
      const response = await getKycDetails();
      const { kycDetails } = await response.data.data;
      const aadhar = kycDetails?.aadharDetails;
      const pan = kycDetails?.panDetails;
      const gst = kycDetails?.gstDetails;
      const businessLicense = kycDetails?.businessLicenceDetails;
      setProfileStatus(kycDetails?.profileStatus);
      setUserAadharDetails(aadhar);
      setUserPanDetails(pan);
      setUserGstNumber(gst);
      setUserBusinessLicenseNumber(businessLicense);
      setIsKycDetailsFetched(LOADING_STATUS.LOADED);
    } catch (err) {
      setIsKycDetailsFetched(LOADING_STATUS.FAILED);
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // render part
  if (isKycDetailsFetched === "loading") return <KYCLoadingScreen />;
  else if (isKycDetailsFetched === "failed") {
    return <ErrorScreen onRetry={fetchKycDetails} />;
  }

  const handleSkipNowButtonClick = () => {
    setIsTransporterOnboardingModalOpen(true);
  };

  const closeTransporterOnboardingModal = () => {
    setIsTransporterOnboardingModalOpen(false);
  };

  const handleNextButtonClick = () => {
    setIsTransporterOnboardingModalOpen(true);
  };

  const handleSaveButtonclick = () => {
    closeTransporterOnboardingModal();
    navigate("/transporter/dashboard");
    localStorage.setItem("ftueStatus", "DONE");
    window.location.reload();
  };

  return (
    <Box p={2} sx={{ display: "flex", flexDirection: "column", gap: ".75rem" }}>
      <TransporterOnboardingModal
        open={isTransporterOnboardingModalOpen}
        onClose={closeTransporterOnboardingModal}
        handleSaveButtonclick={handleSaveButtonclick}
      />

      <Aadhaar
        expanded={expanded}
        handleChange={handleChange}
        userAdhaarDetails={userAdhaarDetails}
        setProfileStatus={setProfileStatus}
      />
      <PAN
        expanded={expanded}
        handleChange={handleChange}
        userPanDetails={userPanDetails}
        setProfileStatus={setProfileStatus}
      />
      <GST
        expanded={expanded}
        handleChange={handleChange}
        userGstNumber={userGstNumber}
      />
      <BusinessLicense
        expanded={expanded}
        handleChange={handleChange}
        userBusinessLicenseNumber={userBusinessLicenseNumber}
      />

      {localStorage.getItem("ftueStatus") === "DONE" ? null : profileStatus ===
        "INCOMPLETE" ? (
        <Button
          sx={{
            textTransform: "none",
            alignSelf: "flex-start",
            marginLeft: "1rem",
            textDecoration: "underline",
          }}
          onClick={() => handleSkipNowButtonClick()}
        >
          {"Skip for now >>"}
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          sx={{
            alignSelf: "flex-end",
            padding: ".25rem 2rem",
            marginTop: "1rem",
          }}
          onClick={() => handleNextButtonClick()}
        >
          Next
        </Button>
      )}
    </Box>
  );
};
export default KYC;
