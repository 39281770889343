import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CustomMultipleCircleProgressBar from "./CustomMultipleCircleProgressBar";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const steps = [
  "Register",
  "Complete KYC",
  "Add resources",
  "Receive Enquiries",
  "Send Bid",
  "Receive Bookings",
];
const TransporterOnboardingModal = ({
  handleSaveButtonclick,
  open,
  onClose,
}) => {
  return (
    <Dialog open={open}  maxWidth="500px">
      <DialogTitle align="center" mt={2}>
        <Typography variant="h5" color="#0C7F44" fontWeight={700} gutterBottom>
          Transporter Onboarding Journey
        </Typography>
        <Typography fontSize={14} fontWeight={700} color="#6D6D6D" gutterBottom>
          From Registration to Booking Success: A Step-by-Step Guide for
          Transporters
        </Typography>
      </DialogTitle>

      <DialogContent>
        <CustomMultipleCircleProgressBar steps={steps}/>
      </DialogContent>
      <DialogActions sx={{ alignSelf: "center", marginBottom: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveButtonclick}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransporterOnboardingModal;
