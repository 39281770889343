import { useEffect, useState } from "react";
import EnquiryDetailsForm from "./EnquiryDetailsForm";
import CustomerVerificationAndDetailPage from "./CustomerVerificationAndDetailPage";
import {
  getMaterialTypes,
  getTimeSlots,
  getVehicleModels,
  getVehicles,
} from "../../api/Customer/enquiry";
import { LOADING_STATUS } from "../../constants/constant";
import { CircularProgress, Typography } from "@mui/material";
import { Box, Button } from "@mui/material";
import moment from "moment";

const initialFormState = {
  goodsType: "",
  weight: "",
  vehicleCategory: "",
  vehicleName: "",
  date: null,
  slot: "",
  quantity: "",
  weightUnit: "KG",
};

const EnquiryScreens = ({
  enquiryId,
  isVehicleCategoryOpen,
  switchComponent,
  setSwitchComp,
}) => {
  // form - values state
  const [formData, setFormData] = useState(initialFormState);
  // const [vehicleList, setVehicleList] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [allTimeSlots, setAllTimeSlots] = useState([]);
  const [isEnquiryDataReady, setIsEnquiryDataAvailable] = useState(
    LOADING_STATUS.INITIAL
  );
  const [materialOpted, setMaterialOpted] = useState({
    name: '',
    category: '',
    categoryId: ''
  })

  const readableTruckCategories = (vehicle, idx) => {
    return {
      value: `${vehicle.category} wheelers (${vehicle.minCapacity / 1000}T - ${
        vehicle.maxCapacity / 1000
      }T)`,
      id: idx,
    };
  };
  const retriveDataForEnquiry = async () => {
    try {
      setIsEnquiryDataAvailable(LOADING_STATUS.LOADING);
      const [
        materialTypeResponse,
        // vehicleCategoryTypeResponse,
        timeSlotResponse,
      ] = await Promise.all([
        getMaterialTypes(),
        // getVehicles(),
        getTimeSlots(),
      ]);
      const materialTypeForDropdown = materialTypeResponse.data.data.map(
        (type) => ({
          id: type.materialCategory.id, // new
          value: type?.materialName,
          // productCategory: type.productCategory
          material: {
            name: type.materialName,
            category: type.productCategory,
            categoryId: type.materialCategory.id
          },
        })
      );
      // const vehicleCategoriesForDropdown =
      //   vehicleCategoryTypeResponse.data.data.map((vehicle, idx) =>
      //     readableTruckCategories(vehicle, idx)
      //   );

      const timeSlotForDropdown = timeSlotResponse.data.data.map(
        (slot, idx) => ({
          id: idx,
          value: slot.timeSlot,
          startTime: slot.startTime,
        })
      );
      setAllTimeSlots(timeSlotForDropdown);
      
      setMaterialList(materialTypeForDropdown);
      // setVehicleList([
      //   { value: "No preference (any)", id: "-1" },
      //   ...vehicleCategoriesForDropdown,
      // ]);
      setTimeSlots(timeSlotForDropdown);
      setIsEnquiryDataAvailable(LOADING_STATUS.LOADED);
    } catch (err) {
      setIsEnquiryDataAvailable(LOADING_STATUS.FAILED);
    }
  };
  // this useEffect retrives all vehicle categories
  useEffect(() => {
    retriveDataForEnquiry();
  }, []);

  // useEffect(() => {
  //   // emptying vehicle name value whenever vehicle category is changed
  //   setFormData((p) => ({ ...p, vehicleName: "" }));
  //   if (formData.vehicleCategory === "No preference (any)") {
  //     // emptying vehicleType array so nothing comes in dropdown
  //     setVehicleModels([]);
  //   } else if (
  //     formData.vehicleCategory !== "No preference (any)" &&
  //     formData.vehicleCategory
  //   ) {
  //     getVehicleModels(formData.vehicleCategory.split(" ")[0])
  //       .then((data) => {
  //         const models = data.data.data.map((model) => ({
  //           value: model.modelName,
  //           id: model.vehicleTypeId,
  //         }));
  //         setVehicleModels(models);
  //       })
  //       .catch((err) => {
  //         setVehicleModels([]);
  //       });
  //   }
  // }, [formData.vehicleCategory]);

  useEffect(() => {
    if (formData.date) {
      const selectedDate = moment(formData.date).startOf("day");
      const currentDate = moment().startOf("day");

      if (selectedDate.isSame(currentDate)) {
        const currentTime = moment();

        const newTimeSlots = allTimeSlots.filter((slot) => {
          const slotStartTime = moment(slot.startTime, "hh:mm A");
          return slotStartTime.isAfter(currentTime);
        });

        setTimeSlots(newTimeSlots);
      } else {
        setTimeSlots(allTimeSlots);
      }
    }
  }, [formData.date, allTimeSlots]);

  if (isEnquiryDataReady === LOADING_STATUS.FAILED) {
    return (
      <Box textAlign="center" pt={10}>
        <Typography fontSize={24} color="#333" gutterBottom>
          Oops! we ran into some problem
        </Typography>
        <Button variant="contained" onClick={retriveDataForEnquiry}>
          Retry
        </Button>
      </Box>
    );
  } else if (isEnquiryDataReady === LOADING_STATUS.LOADING) {
    return (
      <Box textAlign="center" pt={16}>
        <CircularProgress sx={{ color: "green" }} size={36} />
      </Box>
    );
  }

  return !switchComponent ? (
    <EnquiryDetailsForm
      enquiryId={enquiryId}
      setSwitchComp={setSwitchComp}
      formData={formData}
      setFormData={setFormData}
      isVehicleCategoryOpen={isVehicleCategoryOpen}
      // vehicleList={vehicleList}
      vehicleModels={vehicleModels}
      materialList={materialList}
      timeSlots={timeSlots}
      materialOpted={materialOpted}
      setMaterialOpted={setMaterialOpted}
    />
  ) : (
    <CustomerVerificationAndDetailPage
      enquiryId={enquiryId}
      vehicleCategory={formData?.vehicleCategory}
    />
  );
};
export default EnquiryScreens;
