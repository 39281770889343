import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../api/config";
import Cookies from "js-cookie";
import AadhaarDocuments from "./AadhaarDocument";
import PANDocuments from "./PanDocument";
import LicenseDocuments from "./LicenseDocument";
import OtherDocuments from "./OtherDocuments";
import { useEffect, useState } from "react";
import { getDocumentDetailsOfUser } from "../../../api/Admin/approval";

const ApprovalDetails = () => {
  // kyc_id from query params
  const { id } = useParams();
  const kycId = id;
  const [panDocuments, setPanDocuments] = useState(null);
  const [aadhaarDocuments, setAadhaarDocuments] = useState(null);
  const [driversDocument, setDriversDocument] = useState(null);
  const [gstNumber, setGstNumber] = useState(null);
  const [businessLicense, setBusinessLicense] = useState(null);
  const [overallProfileStatus, setOverallProfileStatus] = useState(null);
  const [profileType, setProfileType] = useState("");
  const [profileName, setProfileName] = useState("");

  const fetchDocumentDetails = async () => {
    const response = await getDocumentDetailsOfUser(id);
    setProfileType(response?.data?.data.profileType);
    setAadhaarDocuments(response?.data?.data?.aadharDetails);
    setPanDocuments(response?.data?.data?.panDetails);
    setDriversDocument(response?.data?.data?.drivingLicenceDetails);
    setGstNumber(response?.data?.data?.gstDetails);
    setBusinessLicense(response?.data?.data?.businessLicenceDetails);
    setOverallProfileStatus(response?.data?.data?.profileStatus);
    setProfileName(response?.data?.data?.profileName);
  };

  useEffect(() => {
    fetchDocumentDetails();
  }, []);

  const decoratedOverallProfileStatus = (status) => {
    // capitalizing the value
    const formattedStatusText = status[0] + status.slice(1).toLowerCase();

    if (status === "APPROVED") {
      return (
        <Typography
          sx={{
            border: "1px solid #1CA82E",
            color: "#1CA82E",
            fontSize: "14px",
            borderRadius: "1rem",
            fontWeight: 700,
          }}
          pl={1}
          pr={1}
        >
          {formattedStatusText}
        </Typography>
      );
    } else if (status === "REJECTED") {
      return (
        <Typography
          component="div"
          sx={{
            border: "1px solid #FF0707",
            color: "#FF0707",
            fontSize: "14px",
            borderRadius: "1rem",
            fontWeight: 700,
          }}
          pl={1}
          pr={1}
        >
          {formattedStatusText}
        </Typography>
      );
    }
    return <span />;
  };

  const navigate = useNavigate();
  if (!profileType) return null;
  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ background: "#EAEAEA", padding: ".5rem 1rem" }}
      >
        <img
          style={{ cursor: "pointer", width: "24px", height: "20px" }}
          src="/assets/icons/backArrow.svg"
          alt="back"
          onClick={() => navigate(-1)}
          height="24px"
        />

        <Typography color="#333" fontWeight={700} fontSize="1.25rem">
          {profileName || "-"}
        </Typography>
        {decoratedOverallProfileStatus(overallProfileStatus)}
      </Stack>
      <DocumentGroup
        kycId={kycId}
        overallProfileStatus={overallProfileStatus}
        profileType={profileType}
        aadhaarDocuments={aadhaarDocuments}
        panDocuments={panDocuments}
        driversDocument={driversDocument}
        gstNumber={gstNumber}
        businessLicense={businessLicense}
      />
    </Box>
  );
};

export default ApprovalDetails;

function DocumentGroup({
  kycId,
  overallProfileStatus,
  profileType,
  aadhaarDocuments,
  panDocuments,
  driversDocument,
  gstNumber,
  businessLicense,
}) {
  const activeStyle = {
    color: "#FFF",
    textTransform: "none",
    background: "#3975ED",
    paddingInline: "1rem",
    fontWeight: 700,
    border: "1px solid #3975ED",
    "&:hover": {
      background: "#3975ED",
    },
  };
  const normalStyle = {
    color: "#8C8C8C",
    textTransform: "none",
    background: "#FFF",
    paddingInline: "1rem",
    fontWeight: 700,
    border: "1px solid #8C8C8C",
    "&:hover": {
      background: "#FFF",
    },
  };
  const [activeSection, setActiveSection] = useState(
    profileType !== "DRIVER" ? "aadhaar" : "license"
  );
  const updateTab = (tab) => {
    setActiveSection(tab);
  };

  const buttonIconBasedOnKycStatus = (document) => {
    if (document === "REJECTED") {
      return <img src="/assets/icons/red-cross.svg" alt="reject" />;
    } else if (document === "APPROVED") {
      return <img src="/assets/icons/green-tick.svg" alt="approve" />;
    }
    return null;
  };

  return (
    <>
      <Box sx={{ width: "100%" }} p={3}>
        <Stack direction="row" spacing={2}>
          {profileType === "DRIVER" && (
            <Button
              onClick={() => updateTab("license")}
              sx={activeSection === "license" ? activeStyle : normalStyle}
              endIcon={buttonIconBasedOnKycStatus(
                driversDocument?.approvalStatus
              )}
            >
              License Details
            </Button>
          )}
          <Button
            onClick={() => updateTab("aadhaar")}
            sx={activeSection === "aadhaar" ? activeStyle : normalStyle}
            endIcon={buttonIconBasedOnKycStatus(
              aadhaarDocuments?.approvalStatus
            )}
          >
            Aadhaar Details
          </Button>
          <Button
            onClick={() => updateTab("pan")}
            sx={activeSection === "pan" ? activeStyle : normalStyle}
            endIcon={buttonIconBasedOnKycStatus(panDocuments?.approvalStatus)}
          >
            Pan Details
          </Button>
          {profileType !== "DRIVER" && (
            <Button
              onClick={() => updateTab("others")}
              sx={activeSection === "others" ? activeStyle : normalStyle}
              endIcon={buttonIconBasedOnKycStatus(
                businessLicense?.approvalStatus
              )}
            >
              Other Details
            </Button>
          )}
        </Stack>
        <DocumentDetails
          kycId={kycId}
          activeSection={activeSection}
          aadhaarDocuments={aadhaarDocuments}
          panDocuments={panDocuments}
          driversDocument={driversDocument}
          gstNumber={gstNumber}
          businessLicense={businessLicense}
        />
      </Box>
    </>
  );
}

const DocumentDetails = ({
  kycId,
  activeSection,
  businessLicense,
  gstNumber,
  driversDocument,
  aadhaarDocuments,
  panDocuments,
}) => {
  if (activeSection === "aadhaar")
    return (
      <AadhaarDocuments aadhaarDocuments={aadhaarDocuments} kycId={kycId} />
    );
  else if (activeSection === "pan")
    return <PANDocuments panDocuments={panDocuments} kycId={kycId} />;
  else if (activeSection === "others")
    return (
      <OtherDocuments
        businessLicense={businessLicense}
        gstNumber={gstNumber}
        kycId={kycId}
      />
    );
  else
    return <LicenseDocuments driversDocument={driversDocument} kycId={kycId} />;
};
