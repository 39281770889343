import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Box, Radio, Typography, Grid, Select, MenuItem } from "@mui/material";
import { DialogBtn } from "../Common/DialogBtn";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { StyledDetailsSectionBox } from "./StyledDetailsSectionBox";
import { StyledDetailsSectionTextPrimary } from "./StyledDetailsSectionTextPrimary";
import { StyledDetailsSectionTextSecondary } from "./StyledDetailsSectionTextSecondary";
import { truncateString } from "../../utils/textFormat";
import { StyledDeliverySectionBox } from "./StyledDeliverySectionBox";
import { State, City } from "country-state-city";
import { CustomTextInput } from "../Common/CustomTextInput";
import { createOrder } from "../../api/Customer/payment";
import { useSelector } from "react-redux";
import { createTrip, getCustomerProfile } from "../../api/Customer/enquiry";
import {
  CONFIRM_BOOKING_DIALOG_STEPS,
  OFFER_TYPE,
  PAYMENT_METHOD_CONSTANTS,
} from "../../constants/constant";
import { setEnquiryStatusTracking } from "../../redux/slices/customerSlice";
import { useDispatch } from "react-redux";
import { StyledInputLabel } from "./StyledInputLabel";

const parseAddress = (addressString) => {
  const addressParts = addressString.split(",");
  const numParts = addressParts.length;
  const plotAndBuilding = addressParts.slice(0, -3).join(",").trim() || "";
  const city = addressParts[numParts - 3]?.trim() || "";
  const state = addressParts[numParts - 2]?.trim() || "";

  return { plotAndBuilding, city, state };
};

export default function ConfirmBookingDialog({
  open,
  handleClose,
  enquiry,
  enquiryDetails,
  offerType,
  offers,
  setIsPaymentSuccessDialogOpen,
  setPaymentAmount,
  setTripId,
  handleOpenToast,
}) {
  const dispatch = useDispatch();

  const [advancePaymentMethod, setAdvancePaymentMethod] = useState(
    PAYMENT_METHOD_CONSTANTS.FULL
  );
  const [step, setStep] = useState(
    CONFIRM_BOOKING_DIALOG_STEPS.CONTACT_PERSON_ADDRESS
  );
  const [pickupAddress, setPickupAddress] = useState({
    addressLine1: "",
    addressLine1Error: "",
    addressLine2: "",
    addressLine2Error: "",
    state: "",
    stateError: "",
    city: "",
    cityError: "",
  });

  useEffect(() => {
    const states = State.getStatesOfCountry("IN");
    setPickUpstateOptions(
      states.map((state) => ({ value: state.isoCode, label: state.name }))
    );
    setDropOffStateOptions(
      states.map((state) => ({ value: state.isoCode, label: state.name }))
    );

    if (enquiryDetails) {
      if (enquiryDetails.pickup) {
        const { address, city, loc } = enquiryDetails.pickup.location;
        const parsedAddress = parseAddress(address);
        const selectedState = states.find(
          (state) =>
            state.name === parsedAddress.state ||
            state.isoCode === loc?.stateCode
        );

        if (selectedState) {
          handleStateChange(selectedState.isoCode, setPickUpCityOptions);

          setPickupAddress((prevState) => ({
            ...prevState,
            addressLine1: parsedAddress.plotAndBuilding,
            state: selectedState.isoCode,
            city: city || parsedAddress.city,
          }));
        }
      }

      if (enquiryDetails.dropoff) {
        const { address, city, loc } = enquiryDetails.dropoff.location;
        const parsedAddress = parseAddress(address);
        const selectedState = states.find(
          (state) =>
            state.name === parsedAddress.state ||
            state.isoCode === loc?.stateCode
        );

        if (selectedState) {
          handleStateChange(selectedState.isoCode, setDropOffCityOptions);

          setDropoffAddress((prevState) => ({
            ...prevState,
            addressLine1: parsedAddress.plotAndBuilding,
            state: selectedState.isoCode,
            city: city || parsedAddress.city,
          }));
        }
      }
    }
  }, [enquiryDetails]);

  const [dropoffAddress, setDropoffAddress] = useState({
    addressLine1: "",
    addressLine1Error: "",
    addressLine2: "",
    addressLine2Error: "",
    state: "",
    stateError: "",
    city: "",
    cityError: "",
  });

  const [pickUpStateOptions, setPickUpstateOptions] = useState([]);
  const [pickUpCityOptions, setPickUpCityOptions] = useState([]);
  const [dropOffStateOptions, setDropOffStateOptions] = useState([]);
  const [dropOffCityOptions, setDropOffCityOptions] = useState([]);
  const [customerDetails, setCustomerDetails] = useState({});

  const customerName = useSelector((state) => state.customer.name);
  const customerId = useSelector((state) => state.customer.id);
  const customerEmail = useSelector((state) => state.customer.email);
  const customerPhone = useSelector((state) => state.customer.phone);

  const handleAdvancePaymentChange = (event) => {
    setAdvancePaymentMethod(event.target.value);
  };

  const handleNext = () => {
    //pickup dropoff address step
    if (step === CONFIRM_BOOKING_DIALOG_STEPS.CONTACT_PERSON_ADDRESS) {
      const pickupAddressErrors = validateAddress(pickupAddress);
      const dropoffAddressErrors = validateAddress(dropoffAddress);
      if (
        Object.values(pickupAddressErrors).some((error) => error !== "") ||
        Object.values(dropoffAddressErrors).some((error) => error !== "")
      ) {
        setPickupAddress({ ...pickupAddress, ...pickupAddressErrors });
        setDropoffAddress({ ...dropoffAddress, ...dropoffAddressErrors });
        return; // Exit early if there are errors
      }
      //confirm and pay step
      setStep(CONFIRM_BOOKING_DIALOG_STEPS.CONFIRM_AND_PAY);
    }
  };

  const handleChange = (name, value, setAddress) => {
    if (value?.length <= 50) {
      setAddress((prevState) => ({
        ...prevState,
        [name]: value,
        ...(value.trim() !== "" && { [`${name}Error`]: "" }),
      }));
    }
  };

  const getStateName = (isocode) => {
    const stateName = State.getStateByCodeAndCountry(isocode, "IN").name;
    return stateName ? stateName : "";
  };

  const validateAddress = (address) => {
    const errors = {};

    if (address.addressLine1.trim() === "") {
      errors.addressLine1Error = "This field is required";
    }
    if (address.state.trim() === "") {
      errors.stateError = "This field is required";
    }
    if (address.city.trim() === "") {
      errors.cityError = "This field is required";
    }

    return errors;
  };

  const getPreferredVehicleCategory = () => {
    if (offerType === OFFER_TYPE.BEST_MATCH) {
      return Object.keys(offers?.bestMatch)?.length !== 0
        ? `${offers?.bestMatch?.vehicleCategory} Wheeler`
        : "-";
    } else if (offerType === OFFER_TYPE.BEST_OFFER) {
      return Object.keys(offers?.bestOffer)?.length !== 0
        ? `${offers?.bestOffer?.vehicleCategory} Wheeler`
        : "-";
    } else {
      return "-";
    }
  };
  const getBidId = () => {
    if (offerType === OFFER_TYPE.BEST_MATCH) {
      return Object.keys(offers?.bestMatch)?.length !== 0
        ? `${offers?.bestMatch?.bidId}`
        : null;
    } else if (offerType === OFFER_TYPE.BEST_OFFER) {
      return Object.keys(offers?.bestOffer)?.length !== 0
        ? `${offers?.bestOffer?.bidId}`
        : null;
    }
  };

  const getVehicleName = () => {
    if (offerType === OFFER_TYPE.BEST_MATCH) {
      return Object.keys(offers?.bestMatch)?.length !== 0
        ? `${offers?.bestMatch?.vehicleModelName}`
        : "-";
    } else if (offerType === OFFER_TYPE.BEST_OFFER) {
      return Object.keys(offers?.bestOffer)?.length !== 0
        ? `${offers?.bestOffer?.vehicleModelName}`
        : "-";
    } else {
      return "-";
    }
  };

  const getOfferedCost = () => {
    if (offerType === OFFER_TYPE.BEST_MATCH) {
      return Object.keys(offers?.bestMatch)?.length !== 0
        ? Math.ceil(offers?.bestMatch?.totalCost)
        : 0;
    } else if (offerType === OFFER_TYPE.BEST_OFFER) {
      return Object.keys(offers?.bestOffer)?.length !== 0
        ? Math.ceil(offers?.bestOffer?.totalCost)
        : 0;
    } else {
      return 0;
    }
  };

  const getRazorPayAmount = () => {
    if (advancePaymentMethod === PAYMENT_METHOD_CONSTANTS.FULL) {
      return Math.ceil(getOfferedCost() * 0.75);
    } else {
      return Math.ceil(getOfferedCost() * 0.4);
    }
  };

  const handlePaymentSuceess = () => {
    handleClose();
    setIsPaymentSuccessDialogOpen(true);
  };

  const handleCreateTrip = async (paymentId) => {
    if (
      !enquiryDetails ||
      !pickupAddress ||
      !dropoffAddress ||
      !getBidId() ||
      !paymentId
    ) {
      handleClose();
      handleOpenToast(`Error creating trip. Please apply for refund`, "error");

      return;
    }
    var bidId = getBidId();
    const paymentAmount = getRazorPayAmount();
    const pickupState = getStateName(pickupAddress?.state);
    const dropoffState = getStateName(dropoffAddress?.state);
    // Note : currently we have not added pincode in step1
    //TODO : make pincode field dynamic if we added it in step 1
    let tripData = {
      customerEnquiryId: enquiryDetails?.enquiryId,
      customerName: customerName ? customerName : "",
      pickup: {
        address: pickupAddress?.addressLine1 + pickupAddress?.addressLine2,
        city: pickupAddress?.city,
        state: pickupState,
      },
      dropoff: {
        address:
          dropoffAddress?.addressLine1 + "," + dropoffAddress?.addressLine2,
        city: dropoffAddress?.city,
        state: dropoffState,
      },
      bidId: bidId,
      amountReceived: paymentAmount,
      paymentStatus: "PAYMENT_SUCCESSFULL",
      paymentId: paymentId,
    };

    const response = await createTrip(customerId, tripData);
    if (response.status === 201) {
      setPaymentAmount(paymentAmount);
      setTripId(response?.data?.data[0]?._id);
      handlePaymentSuceess();
    } else {
      //TODO: Need to create refund api
      // payment done but trip not created
      // refund code
      handleClose();
      handleOpenToast(`Error creating trip. Please apply for refund`, "error");

      new Promise((resolve) => setTimeout(resolve, 5000)).then(() => {
        dispatch(
          setEnquiryStatusTracking(`contact-person-details-save-cancel`)
        );
      });
    }
  };

  const handleCreateTripForZeroPayment = async () => {
    if (!enquiryDetails || !pickupAddress || !dropoffAddress || !getBidId()) {
      handleClose();
      handleOpenToast(`Error creating trip. Please try again`, "error");
      return;
    }
    var bidId = getBidId();
    const pickupState = getStateName(pickupAddress?.state);
    const dropoffState = getStateName(dropoffAddress?.state);

    let tripData = {
      customerEnquiryId: enquiryDetails?.enquiryId,
      customerName: customerName ? customerName : "",
      pickup: {
        address: pickupAddress?.addressLine1 + pickupAddress?.addressLine2,
        city: pickupAddress?.city,
        state: pickupState,
      },
      dropoff: {
        address:
          dropoffAddress?.addressLine1 + "," + dropoffAddress?.addressLine2,
        city: dropoffAddress?.city,
        state: dropoffState,
      },
      bidId: bidId,
      amountReceived: 0,
      paymentStatus: "PAYMENT_SUCCESSFULL",
    };

    const response = await createTrip(customerId, tripData);
    if (response.status === 201) {
      setPaymentAmount(0);
      setTripId(response?.data?.data[0]?._id);
      handlePaymentSuceess();
    } else {
      handleOpenToast(`Error creating trip. Please try again`, "error");
    }
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  async function displayRazorpay() {
    try {
      const res = await loadScript(
        "https://checkout.razorpay.com/v2/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      const orderData = {
        totalCost: getRazorPayAmount(),
        enquiryId: enquiryDetails?.enquiryId,
      };

      const result = await createOrder(orderData);

      if (!result) {
        alert("Server error. Are you online?");
        return;
      }

      const { amount, id: order_id, currency } = result.data;
      // Note : 1)unable to add upi option due to test sandbox , it will be available in live payment
      //        2)host loadeasy logo and add image as url
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
        amount: amount.toString(),
        currency: currency,
        name: "LoadEazy",
        description: "Test Transaction",
        image:
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAcBAMAAABi/9neAAAAG1BMVEUAAAAOgEc5OTk6OjoMgEQzMzMMf0QzMzP////9dtj5AAAABnRSTlMAEhIj7u4z0rXDAAAAAWJLR0QIht6VegAAAEVJREFUGNNjYEAFoaEBLC4wwMBQXl7AlgYDpHMZBWEAzEW2CMhlgVuDopccLiPcGnwW0dpHpaFwAOIiARK5xsZGSnDAAAB8VWucFCeHkwAAAABJRU5ErkJggg==",
        order_id: order_id,
        handler: async function (response) {
          const data = {
            orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };
          await handleCreateTrip(response.razorpay_payment_id);
        },
        prefill: {
          name: customerName ? customerName : "",
          email: customerEmail ? customerEmail : "",
          contact: customerPhone ? customerPhone : "",
        },
        notes: {
          address: "LoadEazy office address",
        },
        theme: {
          color: "#0C7F44",
        },
      };

      const rzp = new window.Razorpay(options);

      rzp.open();
    } catch (error) {
      alert("Failed to load Razorpay SDK. Are you online?");
    }
  }

  useEffect(() => {
    const states = State.getStatesOfCountry("IN");
    setPickUpstateOptions(
      states.map((state) => ({ value: state.isoCode, label: state.name }))
    );
    setDropOffStateOptions(
      states.map((state) => ({ value: state.isoCode, label: state.name }))
    );
  }, []);

  const handleStateChange = (selectedStateIsoCode, setCityOptions) => {
    const cities = City.getCitiesOfState("IN", selectedStateIsoCode);
    setCityOptions(
      cities.map((city) => ({ value: city.name, label: city.name }))
    );
  };

  const getCustomerProfileDetails = async () => {
    try {
      const response = await getCustomerProfile();
      if (response.status === 200) {
        setCustomerDetails(response.data.customer);
      }
    } catch (error) {
      console.error("Error fetching customer profile details", error);
    }
  };

  useEffect(() => {
    getCustomerProfileDetails();
  }, []);

  return (
    <React.Fragment>
      <Dialog
        maxWidth={1024}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box p={4} sx={{ width: "1024px" }}>
          <Box display="flex" style={{ justifyContent: "space-between" }}>
            <Box>
              <Typography
                sx={{ fontSize: 24, fontWeight: 700, color: "#333333" }}
              >
                Confirm Your Booking Details
              </Typography>
              <Typography
                sx={{ fontSize: 14, fontWeight: 700, color: "#808080" }}
              >
                {step === CONFIRM_BOOKING_DIALOG_STEPS.CONTACT_PERSON_ADDRESS
                  ? `This address will be considered as the local pickup and drop-off location for your booking. Please fill in the details carefully!`
                  : `Review your booking information and complete your payment.`}
              </Typography>
            </Box>
            <Box onClick={handleClose} style={{ cursor: "pointer" }}>
              <img src="/assets/icons/cross-icon.svg" alt="close" />
            </Box>
          </Box>

          {step === CONFIRM_BOOKING_DIALOG_STEPS.CONTACT_PERSON_ADDRESS && (
            <Box>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Box>
                    <Box pt={3}>
                      <Typography
                        sx={{ fontSize: 24, fontWeight: 700, color: "#333333" }}
                      >
                        Pickup Address Details
                      </Typography>
                    </Box>
                    <CustomTextInput
                      label="Plot No., Building/Warehouse Name"
                      value={pickupAddress.addressLine1}
                      error={pickupAddress.addressLine1Error}
                      handleInputChange={(e) =>
                        handleChange(
                          "addressLine1",
                          e.target.value,
                          setPickupAddress
                        )
                      }
                      containerStyle={{ marginTop: 2 }}
                    />
                    <CustomTextInput
                      label="Street, Area, Locality"
                      value={pickupAddress.addressLine2}
                      error={pickupAddress.addressLine2Error}
                      handleInputChange={(e) =>
                        handleChange(
                          "addressLine2",
                          e.target.value,
                          setPickupAddress
                        )
                      }
                      containerStyle={{ marginTop: 2 }}
                    />
                    <Box mt={2}>
                      <StyledInputLabel>State</StyledInputLabel>

                      <Select
                        fullWidth
                        id="state-select"
                        value={pickupAddress.state}
                        onChange={(e) => {
                          const selectedState = e.target.value;
                          handleChange(
                            "state",
                            selectedState,
                            setPickupAddress
                          );
                          handleStateChange(
                            selectedState,
                            setPickUpCityOptions
                          );
                        }}
                      >
                        {pickUpStateOptions.map((state) => (
                          <MenuItem key={state.value} value={state.value}>
                            {state.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <small style={{ color: "red", fontSize: 12 }}>
                        {pickupAddress.stateError}
                      </small>
                    </Box>

                    <Box mt={2}>
                      <StyledInputLabel>City</StyledInputLabel>
                      <Select
                        disabled={!pickupAddress.state}
                        fullWidth
                        labelId="city-label"
                        id="city-select"
                        value={pickupAddress.city}
                        onChange={(e) =>
                          handleChange("city", e.target.value, setPickupAddress)
                        }
                      >
                        {pickUpCityOptions.map((city) => (
                          <MenuItem key={city.value} value={city.value}>
                            {city.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <small style={{ color: "red", fontSize: 12 }}>
                        {pickupAddress.cityError}
                      </small>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <Box pt={3}>
                      <Typography
                        sx={{ fontSize: 24, fontWeight: 700, color: "#333333" }}
                      >
                        Dropoff Address Details
                      </Typography>
                    </Box>
                    <CustomTextInput
                      label="Plot No., Building/Warehouse Name"
                      value={dropoffAddress.addressLine1}
                      error={dropoffAddress.addressLine1Error}
                      handleInputChange={(e) =>
                        handleChange(
                          "addressLine1",
                          e.target.value,
                          setDropoffAddress
                        )
                      }
                      containerStyle={{ marginTop: 2 }}
                    />
                    <CustomTextInput
                      label="Street, Area, Locality"
                      value={dropoffAddress.addressLine2}
                      error={dropoffAddress.addressLine2Error}
                      handleInputChange={(e) =>
                        handleChange(
                          "addressLine2",
                          e.target.value,
                          setDropoffAddress
                        )
                      }
                      containerStyle={{ marginTop: 2 }}
                    />
                    <Box mt={2}>
                      <StyledInputLabel>State</StyledInputLabel>

                      <Select
                        fullWidth
                        id="state-select"
                        value={dropoffAddress.state}
                        onChange={(e) => {
                          const selectedState = e.target.value;

                          handleChange(
                            "state",
                            selectedState,
                            setDropoffAddress
                          );
                          handleStateChange(
                            selectedState,
                            setDropOffCityOptions
                          );
                        }}
                      >
                        {dropOffStateOptions.map((state) => (
                          <MenuItem key={state.value} value={state.value}>
                            {state.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <small style={{ color: "red", fontSize: 12 }}>
                        {dropoffAddress.stateError}
                      </small>
                    </Box>

                    <Box mt={2}>
                      <StyledInputLabel>City</StyledInputLabel>
                      <Select
                        fullWidth
                        disabled={!dropoffAddress.state}
                        labelId="city-label"
                        id="city-select"
                        value={dropoffAddress.city}
                        onChange={(e) =>
                          handleChange(
                            "city",
                            e.target.value,
                            setDropoffAddress
                          )
                        }
                      >
                        {dropOffCityOptions.map((city) => (
                          <MenuItem key={city.value} value={city.value}>
                            {city.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <small style={{ color: "red", fontSize: 12 }}>
                        {dropoffAddress.cityError}
                      </small>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="center">
                <DialogBtn
                  title="Next"
                  onClick={handleNext}
                  buttonStyle={{
                    color: "#FFFFFF",
                    background: "#0C7F44",
                    "&:hover": {
                      backgroundColor: "#0C7F44",
                      opacity: 0.8,
                    },
                    borderRadius: 1,
                    marginTop: 5,
                  }}
                />
              </Box>
            </Box>
          )}
          {/* step 2 */}
          {step === CONFIRM_BOOKING_DIALOG_STEPS.CONFIRM_AND_PAY && (
            <Box>
              {/* delivery section */}
              <Grid
                container
                mt={3}
                sx={{
                  background: "#EAEAEA",
                  borderRadius: 2,
                  padding: "10px 16px 10px 16px",
                }}
              >
                <Grid item xs={4}>
                  <StyledDeliverySectionBox>
                    <StyledDeliverySectionTextPrimary>
                      Pickup Location
                    </StyledDeliverySectionTextPrimary>
                    <StyledDeliverySectionTextSecondary
                      sx={{
                        wordBreak: "break-word",
                      }}
                    >
                      {`${pickupAddress.addressLine1},${
                        pickupAddress.addressLine2
                      },${pickupAddress.city},${getStateName(
                        pickupAddress.state
                      )}`}
                    </StyledDeliverySectionTextSecondary>
                  </StyledDeliverySectionBox>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    px={2}
                  >
                    <Typography
                      style={{ fontSize: 18, color: "#000", fontWeight: 700 }}
                    >
                      {`${enquiryDetails?.distance}km`}
                    </Typography>
                    <img
                      src="/assets/icons/distanceArrowLong.svg"
                      alt="distance"
                      width={200}
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <StyledDeliverySectionBox sx={{ alignItems: "end" }}>
                    <StyledDeliverySectionTextPrimary>
                      Drop-off Location
                    </StyledDeliverySectionTextPrimary>
                    <StyledDeliverySectionTextSecondary
                      sx={{ wordWrap: "break-word" }}
                    >
                      {`${dropoffAddress.addressLine1},${
                        dropoffAddress.addressLine2
                      },${dropoffAddress.city},${getStateName(
                        dropoffAddress.state
                      )}`}
                    </StyledDeliverySectionTextSecondary>
                  </StyledDeliverySectionBox>
                </Grid>
              </Grid>
              {/* Details section */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={2}
                sx={{ background: "#EAEAEA" }}
              >
                <StyledDetailsSectionBox sx={{ padding: "8px 14px" }}>
                  <StyledDetailsSectionTextPrimary>
                    {truncateString(enquiry?.material?.name, 40)}
                  </StyledDetailsSectionTextPrimary>
                  <StyledDetailsSectionTextSecondary>
                    Material
                  </StyledDetailsSectionTextSecondary>
                </StyledDetailsSectionBox>
                <StyledDetailsSectionBox sx={{ padding: "8px 24px" }}>
                  <StyledDetailsSectionTextPrimary>
                    {Number(enquiry?.weight) / 1000} ton
                  </StyledDetailsSectionTextPrimary>
                  <StyledDetailsSectionTextSecondary>
                    Weight
                  </StyledDetailsSectionTextSecondary>
                </StyledDetailsSectionBox>
                <StyledDetailsSectionBox sx={{ padding: "8px 24px" }}>
                  <StyledDetailsSectionTextPrimary>
                    {`${enquiryDetails?.quantity ?? "--"}`}
                  </StyledDetailsSectionTextPrimary>
                  <StyledDetailsSectionTextSecondary>
                    No of Pkgs
                  </StyledDetailsSectionTextSecondary>
                </StyledDetailsSectionBox>
                <StyledDetailsSectionBox sx={{ padding: "8px 12px" }}>
                  <StyledDetailsSectionTextPrimary>
                    {getPreferredVehicleCategory()}
                  </StyledDetailsSectionTextPrimary>
                  <StyledDetailsSectionTextSecondary>
                    Vehicle Category
                  </StyledDetailsSectionTextSecondary>
                </StyledDetailsSectionBox>
                <StyledDetailsSectionBox sx={{ padding: "8px 12px" }}>
                  <StyledDetailsSectionTextPrimary>
                    {truncateString(getVehicleName(), 40)}
                  </StyledDetailsSectionTextPrimary>
                  <StyledDetailsSectionTextSecondary>
                    Vehicle Model
                  </StyledDetailsSectionTextSecondary>
                </StyledDetailsSectionBox>
              </Box>
              {/* payment section */}
              <Box sx={{ background: "#DDEFDD", padding: "16px 24px" }} mt={2}>
                <Typography
                  sx={{ fontSize: 18, color: "#333333", fontWeight: 700 }}
                >
                  Congratulations! As a valued LoadEazy customer, you have been
                  offered a reduced advance payment option
                </Typography>
                <Box display="flex" mt={1.5}>
                  <StyledPaymentSectionTextPrimary>
                    Offered Cost
                  </StyledPaymentSectionTextPrimary>
                  <StyledPaymentSectionTextSecondary>
                    ₹{getOfferedCost()}
                  </StyledPaymentSectionTextSecondary>
                </Box>
                <Box display="flex" mt={0.5}>
                  <StyledPaymentSectionTextPrimary>
                    Platform Charges
                  </StyledPaymentSectionTextPrimary>
                  <StyledPaymentSectionTextSecondary>
                    ₹0 (0%)
                  </StyledPaymentSectionTextSecondary>
                </Box>
                <Box>
                  <img
                    src="/assets/icons/horizontalVector.svg"
                    alt="distance arrow"
                  />
                </Box>
                <Box display="flex" mt={0.5}>
                  <StyledPaymentSectionTextPrimary>
                    Total Cost
                  </StyledPaymentSectionTextPrimary>
                  <StyledPaymentSectionTextSecondary>
                    ₹{getOfferedCost()}
                  </StyledPaymentSectionTextSecondary>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mx: 5,
                  mt: 5,
                }}
              >
                <Box display="flex" alignItems="center">
                  <Radio
                    checked={
                      advancePaymentMethod === PAYMENT_METHOD_CONSTANTS.FULL
                    }
                    onChange={handleAdvancePaymentChange}
                    value={PAYMENT_METHOD_CONSTANTS.FULL}
                    name="radio-button"
                    sx={{
                      "&, &.Mui-checked": {
                        color: "#0C7F44",
                      },
                    }}
                  />
                  <StyledPaymentMethodTextPrimary>
                    ₹{Math.ceil(getOfferedCost() * 0.75)}
                  </StyledPaymentMethodTextPrimary>
                  <StyledPaymentMethodTextSecondary>
                    75% Advance
                  </StyledPaymentMethodTextSecondary>
                </Box>
                <Box display="flex" alignItems="center" ml={3}>
                  <Radio
                    checked={
                      advancePaymentMethod === PAYMENT_METHOD_CONSTANTS.HALF
                    }
                    onChange={handleAdvancePaymentChange}
                    value={PAYMENT_METHOD_CONSTANTS.HALF}
                    name="radio-button"
                    sx={{
                      "&, &.Mui-checked": {
                        color: "#0C7F44",
                      },
                    }}
                  />
                  <StyledPaymentMethodTextPrimary>
                    ₹{Math.ceil(getOfferedCost() * 0.4)}
                  </StyledPaymentMethodTextPrimary>
                  <StyledPaymentMethodTextSecondary>
                    40% Advance
                  </StyledPaymentMethodTextSecondary>
                </Box>
                {customerDetails?.isPremium && (
                  <Box display="flex" alignItems="center" ml={3}>
                    <Radio
                      checked={
                        advancePaymentMethod === PAYMENT_METHOD_CONSTANTS.ZERO
                      }
                      onChange={handleAdvancePaymentChange}
                      value={PAYMENT_METHOD_CONSTANTS.ZERO}
                      name="radio-button"
                      sx={{
                        "&, &.Mui-checked": {
                          color: "#0C7F44",
                        },
                      }}
                    />
                    <StyledPaymentMethodTextPrimary>
                      ₹ 0
                    </StyledPaymentMethodTextPrimary>
                    <StyledPaymentMethodTextSecondary>
                      Pay later
                    </StyledPaymentMethodTextSecondary>
                  </Box>
                )}
                <Box flexGrow={2} textAlign="right">
                  <DialogBtn
                    title={
                      advancePaymentMethod === PAYMENT_METHOD_CONSTANTS.ZERO
                        ? "Confirm"
                        : "Pay"
                    }
                    onClick={
                      advancePaymentMethod === PAYMENT_METHOD_CONSTANTS.ZERO
                        ? handleCreateTripForZeroPayment
                        : displayRazorpay
                    }
                    disabled={!advancePaymentMethod}
                    buttonStyle={{
                      color: "#FFFFFF",
                      background: "#0C7F44",
                      "&:hover": {
                        backgroundColor: "#0C7F44",
                        opacity: 0.8,
                      },
                      borderRadius: 0,
                    }}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
const StyledDeliverySectionTextPrimary = styled(Typography)`
  font-size: 18px;
  color: #333333;
  font-weight: 700;
`;
const StyledDeliverySectionTextSecondary = styled(Typography)`
  font-size: 14px;
  color: #808080;
  text-align: left;
  margin-top: 8px;
  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}
`;

const StyledPaymentSectionTextPrimary = styled(Typography)`
  font-size: 18px;
  color: #333333;
  width: 200px;
  padding-left: 4px;
`;

const StyledPaymentSectionTextSecondary = styled(Typography)`
  font-size: 18px;
  color: #333333;
  font-weight: 700;
  flex-grow: 2;
  text-align: left;
`;
const StyledPaymentMethodTextPrimary = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  color: #0c7f44;
`;

const StyledPaymentMethodTextSecondary = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  padding-left: 24px;
`;
