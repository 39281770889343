import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoginDialog from "../../component/Common/LoginDialog";
import { sendOTP } from "../../api/common";
import { setUserCredentials } from "../../redux/slices/userAuthenticationSlice";
import EnquiryEstimatePage from "./EnquiryEstimatePage";
import StyledSelectInput from "../../component/Common/StyledSelectInput";
import { updateCustomerDetails } from "../../api/Customer/enquiry";
import { LOADING_STATUS } from "../../constants/constant";
import { useLocation } from "react-router-dom";

const labelStyle = { fontWeight: 700, fontSize: "1.125rem", color: "#333" };

const CustomerVerificationAndDetailPage = ({ enquiryId, vehicleCategory }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const customerId = useSelector((state) => state.customer.id);
  // this will pick number from customer slice when user is logged in as state is updated at layout
  // And it will read from user slice when user is not loggedin, which is set right after number verification
  const phoneNumber = useSelector((state) =>
    location.state?.detail?.isAuthenticated
      ? state.customer?.phone
      : state.user?.phone
  );
  const customerName = useSelector(state=>state.customer.name ?? "");
  const [customerIdValue, setCustomerIdValue] = useState("");
  const [customerDetails, setCustomerDetails] = useState({
    phoneNumber: "",
    name: "",
  });
  const [switchComp, setSwitchComp] = useState(true);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [otpStatus, setOtpStatus] = useState(LOADING_STATUS.INITIAL);
  const [isCustomerDetailsPosted, setIsCustomerDetailsPosted] = useState(
    LOADING_STATUS.INITIAL
  );
  useEffect(() => {
    // Backup code if current logic breaks
    // if coming from dashboard
    // if (location.state?.detail?.isAuthenticated) {
    //   setCustomerDetails((prev) => ({
    //     ...prev,
    //     phoneNumber: location.state?.detail?.phone,
    //   }));
    //   setCustomerIdValue(location.state?.detail?.customerId);
    // } else {
    if (customerId) setCustomerIdValue(customerId);
    if (phoneNumber)
      setCustomerDetails((p) => ({ ...p, phoneNumber: phoneNumber }));
    if(customerName) setCustomerDetails(p=>({...p, name:customerName}));
    // }
  }, [customerId, phoneNumber]);

  const handleFormDataChange = (event) => {
    const { value, name } = event.target;
    if (name === "name") {
      if (/^[A-Za-z\s]*$/.test(value)) {
        setCustomerDetails((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      }
      return;
    }
    setCustomerDetails((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleGetOtpBtnClick = async () => {
    setOtpStatus(LOADING_STATUS.LOADING);
    try {
      const data = await sendOTP("send-otp", customerDetails.phoneNumber);
      const { phone, hash } = data?.data;
      dispatch(
        setUserCredentials({
          phone,
          hash,
          portal: "customer",
          isEnquiryFlow: true,
        })
      );
      setOtpStatus(LOADING_STATUS.LOADED);
      setIsLoginModalOpen(true);
    } catch (err) {
      setOtpStatus(LOADING_STATUS.FAILED);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsCustomerDetailsPosted(LOADING_STATUS.LOADING);
    try {
      await updateCustomerDetails(customerIdValue, enquiryId, customerDetails);
      setIsCustomerDetailsPosted(LOADING_STATUS.LOADED);
      setSwitchComp(false);
    } catch (err) {
      setIsCustomerDetailsPosted(LOADING_STATUS.FAILED);
    }
  };
  if (!switchComp) {
    return (
      <EnquiryEstimatePage
        enquiryId={enquiryId}
        setSwitchComp={setSwitchComp}
        vehicleCategory={vehicleCategory}
      />
    );
  }
  return (
    <Box maxWidth="lg">
      {isLoginModalOpen && (
        <LoginDialog
          isLoginModalOpen={isLoginModalOpen}
          handleCloseLoginModal={() => setIsLoginModalOpen(false)}
          stakeholder="customer"
          isVerified={true}
        />
      )}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Stack>
              <InputLabel shrink sx={labelStyle}>
                Phone Number
              </InputLabel>
              <Stack direction="row" spacing={2}>
                <TextField
                  name="phoneNumber"
                  autoComplete="off"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "2.75rem",
                      paddingRight: ".25rem",
                    },
                  }}
                  fullWidth
                  value={customerDetails.phoneNumber}
                  type="tel"
                  disabled={Boolean(customerIdValue)}
                  onChange={handleFormDataChange}
                  inputProps={{
                    maxLength: 10,
                  }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        disabled={
                          customerDetails.phoneNumber.length !== 10 ||
                          customerIdValue !== ""
                        }
                        color="primary"
                        variant="contained"
                        sx={{ minWidth: "116px" }}
                        onClick={handleGetOtpBtnClick}
                        endIcon={
                          otpStatus === LOADING_STATUS.LOADING ? (
                            <CircularProgress
                              size={16}
                              sx={{ color: "#FFF" }}
                            />
                          ) : null
                        }
                      >
                        Get OTP
                      </Button>
                    ),
                  }}
                />
              </Stack>
            </Stack>
          </Grid>
          {customerIdValue && customerDetails.phoneNumber && (
            <Grid item xs={6}>
              <Stack>
                <InputLabel shrink sx={labelStyle}>
                  Name
                </InputLabel>
                <Stack direction="row" spacing={2}>
                  <TextField
                    name="name"
                    autoComplete="off"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height: "2.75rem",
                        paddingRight: 0,
                      },
                    }}
                    fullWidth
                    value={customerDetails.name}
                    onChange={handleFormDataChange}
                  />
                </Stack>
              </Stack>
            </Grid>
          )}
        </Grid>
        <Stack direction="row" justifyContent="center" mt={6}>
          {customerIdValue &&
            customerDetails.phoneNumber &&
            customerDetails.name &&
             (
              <Button
                color="primary"
                variant="contained"
                type="submit"
                endIcon={
                  isCustomerDetailsPosted === LOADING_STATUS.LOADING ? (
                    <CircularProgress size={16} sx={{ color: "#FFF" }} />
                  ) : null
                }
              >
                Get Estimation
              </Button>
            )}
        </Stack>
      </form>
    </Box>
  );
};

export default CustomerVerificationAndDetailPage;
