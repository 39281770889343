import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import React from "react";

import { LOADING_STATUS } from "../../../constants/constant";
import KYCLoadingScreen from "../../../component/Common/KYCLoadingScreen";
import ErrorScreen from "../../../component/Common/ErrorScreen";
import { getCustomerKycDetails } from "../../../api/Customer/kyc";
import Aadhaar from "./Aadhaar";
import PAN from "./PAN"
import GST from "./GST";
import BusinessLicense from "./BusinessLicense";

const CustomerKYC = () => {
  const [profileStatus, setProfileStatus] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [userAdhaarDetails, setUserAadharDetails] = useState(null);
  const [userPanDetails, setUserPanDetails] = useState(null);
  const [userBusinessLicenseNumber, setUserBusinessLicenseNumber] =
    useState(null);
  const [userGstNumber, setUserGstNumber] = useState(null);
  const [isKycDetailsFetched, setIsKycDetailsFetched] = useState(
    LOADING_STATUS.INITIAL
  );

  useEffect(() => {
    fetchKycDetails();
  }, []);

  const fetchKycDetails = async () => {
    try {
      setIsKycDetailsFetched(LOADING_STATUS.LOADING);
      const response = await getCustomerKycDetails();
      const { kycDetails } = await response.data.data;
      const aadhar = kycDetails?.aadharDetails;
      const pan = kycDetails?.panDetails;
      const gst = kycDetails?.gstDetails;
      const businessLicense = kycDetails?.businessLicenceDetails;
      setProfileStatus(kycDetails?.profileStatus);
      setUserAadharDetails(aadhar);
      setUserPanDetails(pan);
      setUserGstNumber(gst);
      setUserBusinessLicenseNumber(businessLicense);
      setIsKycDetailsFetched(LOADING_STATUS.LOADED);
    } catch (err) {
      setIsKycDetailsFetched(LOADING_STATUS.FAILED);
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // render part
  if (isKycDetailsFetched === "loading") return <KYCLoadingScreen />;
  else if (isKycDetailsFetched === "failed") {
    return <ErrorScreen onRetry={fetchKycDetails} />;
  }

  return (
    <Box p={2} sx={{ display: "flex", flexDirection: "column", gap: ".75rem" }}>
      <Aadhaar
        expanded={expanded}
        handleChange={handleChange}
        userAdhaarDetails={userAdhaarDetails}
        setProfileStatus={setProfileStatus}
      />
      <PAN
        expanded={expanded}
        handleChange={handleChange}
        userPanDetails={userPanDetails}
        setProfileStatus={setProfileStatus}
      />
      <GST
        expanded={expanded}
        handleChange={handleChange}
        userGstNumber={userGstNumber}
      />
      <BusinessLicense
        expanded={expanded}
        handleChange={handleChange}
        userBusinessLicenseNumber={userBusinessLicenseNumber}
      />
    </Box>
  );
};
export default CustomerKYC;
