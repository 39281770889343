import { TextField } from "@mui/material";

export const TableSearchInputField = (props) => {
  const { value, onChange, placeholder } = props;
  return (
    <TextField
      variant="outlined"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      InputProps={{
        sx: { height: "36.31px" },
        endAdornment: <img src="/assets/icons/search-icon.svg" alt="search" />,
      }}
    />
  );
};
