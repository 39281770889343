import { Box } from "@mui/system";
import AddButton from "../../Common/AddButton";
import { useEffect, useState } from "react";
import {
  CircularProgress,
  Modal,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import AddEditMaterialModel from "./MaterialType/AddEditMaterialModel";
import { TableCell } from "@mui/material";
import {
  deleteSelectedMaterial,
  getAllMaterial,
  getMaterialById,
} from "../../../api/Admin/materialType";
import useToast from "../../../hooks/useToast";
import DeleteMaterialTypeModal from "./MaterialType/DeleteMaterialTypeModal";
import { StyledTableCell } from "../../../pages/Admin/Approval/StyledTableCell";
import { StyledTableRow } from "../../Common/StyledTableRow";

const MaterialType = () => {
  const [paginationData, setPaginationData] = useState({
    totalItems: 0,
    pageSize: 10,
  });

  const [isMaterialModelOpen, setIsMaterialModelOpen] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [editMaterialData, setEditMaterialData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(0);

  const [isDeleteMaterialModalOpen, setIsDeleteMaterialModalOpen] =
    useState(false);

  // headers for material table
  const materialHeaders = [
    "Name",
    "Material Category",
    "Industry",
    "No of Pieces",
    "Minimum Weight",
    "Maximum Weight",
    "Width",
    "Length",
    "Thickness",
  ];

  const { handleOpenToast } = useToast();

  const [materialData, setMaterialData] = useState([]);

  // function for fetching all material data
  const fetchAllMaterial = async () => {
    try {
      setIsLoading(true);
      const response = await getAllMaterial(page + 1, "10");
      if (response.status === 200) {
        setMaterialData(response?.data?.data?.materialTypes);
        setPaginationData(response?.data?.data?.pagination);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      handleOpenToast(`Vehicle data fetching failed.`, "error");
    }
  };

  // function for close modal
  const handleCloseModal = () => {
    setIsMaterialModelOpen(false);
    setEditMaterialData([]);
  };

  // function for edit material data
  const handleEditMaterialData = async (index) => {
    const response = await getMaterialById(materialData[index]?.materialTypeId);

    if (response.status === 200) {
      setIsMaterialModelOpen(true);
      setEditMaterialData(response?.data?.data);
    } else {
      handleOpenToast(`Material data fetching failed.`, "error");
    }
  };

  const handleDeleteMaterialTypeButtonClick = () => {
    setIsMaterialModelOpen(false);
    setIsDeleteMaterialModalOpen(true);
  };

  const handleDeleteMaterial = async () => {
    const response = await deleteSelectedMaterial(
      editMaterialData.materialTypeId
    );
    if (response.status === 200) {
      await fetchAllMaterial();
      handleOpenToast("Material deleted successfully", "success");
    } else {
      handleOpenToast("Material deleted failed", "error");
    }
    handleCloseModal();
    setIsDeleteMaterialModalOpen(false);
  };

  useEffect(() => {
    fetchAllMaterial();
  }, [page]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "35vh",
          width: "100%",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box>
      <AddButton
        name="Add Material"
        onClick={() => setIsMaterialModelOpen(true)}
      />
      <Modal open={isMaterialModelOpen} onClose={() => handleCloseModal()}>
        <Box>
          <AddEditMaterialModel
            handleDeleteMaterialTypeButtonClick={
              handleDeleteMaterialTypeButtonClick
            }
            handleCloseModal={handleCloseModal}
            fetchAllMaterial={fetchAllMaterial}
            handleOpenToast={handleOpenToast}
            editMaterialData={editMaterialData}
          />
        </Box>
      </Modal>
      <Modal
        open={isDeleteMaterialModalOpen}
        onClose={() => setIsDeleteMaterialModalOpen(false)}
      >
        <Box>
          <DeleteMaterialTypeModal
            closeDeleteModal={() => setIsDeleteMaterialModalOpen(false)}
            handleDeleteMaterialTypeData={handleDeleteMaterial}
            materialData={editMaterialData}
          />
        </Box>
      </Modal>

      <TableContainer
        sx={{
          width: "100%",
          height: "100%",
          boxShadow: "none",
          border: "none",
          marginTop: "5px",
        }}
      >
        <Table size="small" aria-label="Truck data">
          <TableHead>
            <TableRow>
              {materialHeaders.map((header, index) => (
                <StyledTableCell key={index}>{header}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {!materialData ? (
              <div style={{ marginTop: "2rem" }}>No material data found</div>
            ) : (
              materialData?.length > 0 &&
              materialData?.map((material, index) => (
                <StyledTableRow
                  key={index}
                  style={{
                    textDecoration: "none",
                    marginTop: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => handleEditMaterialData(index)}
                >
                  <TableCell
                    scope="row"
                    sx={{ color: "#6d6d6d", textAlign: "center" }}
                  >
                    {material.materialName?.length > 20
                      ? material.materialName.substring(0, 0 + 17) + "..."
                      : material.materialName?.length <= 20
                      ? material.materialName
                      : "-"}
                  </TableCell>
                  <TableCell
                    scope="row"
                    sx={{ color: "#6d6d6d", textAlign: "center" }}
                  >
                    {material.materialCategory ? material.materialCategory : "-"}
                  </TableCell>
                  <TableCell sx={{ color: "#6d6d6d", textAlign: "center" }}>
                    {material.industry ? material.industry : "-"}
                  </TableCell>
                  <TableCell sx={{ color: "#6d6d6d", textAlign: "center" }}>
                    {material.noOfPieces ? material.noOfPieces : "-"}
                  </TableCell>
                  <TableCell sx={{ color: "#6d6d6d", textAlign: "center" }}>
                    {material.minWeight ? material.minWeight : "-"}
                  </TableCell>
                  <TableCell sx={{ color: "#6d6d6d", textAlign: "center" }}>
                    {material.maxWeight ? material.maxWeight : "-"}
                  </TableCell>
                  <TableCell sx={{ color: "#6d6d6d", textAlign: "center" }}>
                    {material.width ? material.width : "-"}
                  </TableCell>
                  <TableCell sx={{ color: "#6d6d6d", textAlign: "center" }}>
                    {material.length ? material.length : "-"}
                  </TableCell>
                  <TableCell sx={{ color: "#6d6d6d", textAlign: "center" }}>
                    {material.thickness ? material.thickness : "-"}
                  </TableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={paginationData?.totalItems}
        rowsPerPage={paginationData?.pageSize}
        page={page}
        onPageChange={handleChangePage}
        labelDisplayedRows={({ count }) => (
          <span>
            Page{" "}
            <span style={{ color: "green", fontWeight: 700 }}>{page + 1} </span>{" "}
            of {paginationData?.totalPages}
          </span>
        )}
      />
    </Box>
  );
};

export default MaterialType;
