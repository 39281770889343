import { InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
const labelStyle = { fontWeight: 700, fontSize: "1.125rem", color: "#333" };
const StyledSelectInput = ({
  label,
  name,
  value,
  onChange,
  items = [],
  id,
  error,
  helperText,
  disabled = false,
}) => {
  return (
    <Stack>
      <InputLabel shrink sx={labelStyle}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        id={id ? id : null}
        error={Boolean(error)}
        fullWidth
        name={name}
        sx={{
          color: value ? "#333" : "#ADADAD",
          height: "2.75rem",
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ADADAD",
          },
        }}
        displayEmpty
        value={value}
        onChange={onChange}
      >
        <MenuItem disabled value="" sx={{ display: "none" }}>
          Select
        </MenuItem>
        {items?.length > 0 &&
          items.map((e, idx) => (
            <MenuItem key={e?.id || idx} value={e?.value}>
              {e?.value}
            </MenuItem>
          ))}
      </Select>
      {error && (
        <Typography fontSize={12} pt={0.5} pl={1} color="#d32f2f">
          {error}
        </Typography>
      )}
    </Stack>
  );
};
export default StyledSelectInput;
