import React from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";

import {
  TRANSPORTER_PAYMENT_STATUS,
  TRANSPORTER_PAYMENT_STATUS_COLOR,
} from "../../../constants/constant";

const StatusText = styled(Typography)(({ color }) => ({
  color: color || "#333",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "12px",
  fontWeight: "700",
  padding: "0px",
}));

export default function TripPaymentStatusCell({ status }) {
  return (
    <StatusText color={TRANSPORTER_PAYMENT_STATUS_COLOR[status]}>
      {TRANSPORTER_PAYMENT_STATUS[status]
        ? TRANSPORTER_PAYMENT_STATUS[status]
        : "-"}
    </StatusText>
  );
}
