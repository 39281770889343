import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import GenerateStats from "../../../component/Common/GenerateStats";
import { StyledTableCell } from "../Approval/StyledTableCell";
import { StyledTableRow } from "../../../component/Common/StyledTableRow";
import LoadingTable from "../../../component/Common/LoadingTable";
import { LOADING_STATUS } from "../../../constants/constant";
import ErrorScreen from "../../../component/Common/ErrorScreen";
import { getAllCustomers } from "../../../api/Admin/customerResources";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const CustomerResources = () => {
  const [customers, setCustomers] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [kycStatus, setKycStatus] = useState([]);
  const [count, setCount] = useState({});
  const [isCustomerListFetched, setIsCustomerListFetched] = useState(
    LOADING_STATUS.INITIAL
  );
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
  };

  const fetchData = async () => {
    setIsCustomerListFetched(LOADING_STATUS.LOADING);
    const response = await getAllCustomers(page, pageSize, search, kycStatus);

    if (response.status === 200) {
      setCustomers(response.data.data.customers);
      setIsCustomerListFetched(LOADING_STATUS.LOADED);
      setPaginationData(response.data.data.pagination);
      setCount(response.data.data.counts);
    } else {
      setIsCustomerListFetched(LOADING_STATUS.FAILED);
    }
  };

  const [debouncedSearchText, setDebouncedSearchText] = useState("");

  //   debouncing for search input
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchText(search);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  useEffect(() => {
    fetchData();
  }, [page, pageSize, debouncedSearchText, kycStatus]);

  const statsArray = [
    { name: "Total", value: count?.total || 0 },
    { name: "Active", value: count?.active || 0 },
    { name: "Inactive", value: count?.inactive || 0 },
    {
      name: "Blocked",
      value: count?.blocked || 0,
    },
  ];
  const tableHeaders = ["Name", "Mobile", "Joined", "Status"];

  const renderViews = () => {
    switch (isCustomerListFetched) {
      case "loading":
        return <LoadingTable columns={tableHeaders.length} />;

      case "loaded":
        if (customers.length > 0)
          return <CustomersResourcesTable customers={customers} />;
        return (
          <TableRow sx={{ background: "#EAEAEA" }}>
            <TableCell
              colSpan={tableHeaders.length}
              align="center"
              sx={{ fontWeight: 600, color: "#333" }}
            >
              No Results Found.
            </TableCell>
          </TableRow>
        );

      case "failed":
        return (
          <TableRow>
            <TableCell
              colSpan={tableHeaders.length}
              sx={{ borderBottom: "none" }}
            >
              <ErrorScreen />
            </TableCell>
          </TableRow>
        );

      default:
        return null;
    }
  };

  return (
    <Box>
      <Stack
        p=".75rem 1.625rem"
        borderBottom="1px solid #ADADAD"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <GenerateStats data={statsArray} />
        <TextField
          variant="outlined"
          placeholder="Search"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          InputProps={{
            sx: { height: "36.31px" },
            endAdornment: (
              <img src="/assets/icons/search-icon.svg" alt="search" />
            ),
          }}
        />
      </Stack>
      <Box p={3}>
        <TableContainer aria-label="customized table">
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ borderLeft: "1px solid #c6c6c6" }}>
                  Name
                </StyledTableCell>
                <StyledTableCell>Mobile No.</StyledTableCell>
                <StyledTableCell>Joined On</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderViews()}</TableBody>
          </Table>
        </TableContainer>
        <div style={{ padding: "10px" }}>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={paginationData.totalItems}
            rowsPerPage={paginationData.pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ count }) => (
              <div>
                Page{" "}
                <span style={{ color: "green", fontWeight: 700 }}>
                  {page + 1}
                </span>{" "}
                of {Math.ceil(count / paginationData.pageSize)}
              </div>
            )}
          />
        </div>
      </Box>
    </Box>
  );
};
export default CustomerResources;

const CustomersResourcesTable = ({ customers }) => {
  const generateStatus = (status) => {
    return status === "ACTIVE" ? (
      <TableCell sx={{ color: "green", textAlign: "center" }}>
        {status}
      </TableCell>
    ) : (
      <TableCell sx={{ color: "red", textAlign: "center" }}>{status}</TableCell>
    );
  };

  function createCustomerRowData(customer) {
    return {
      Name: customer?.customerName || "-",
      Mobile: customer?.customerPhone || "-",
      Joined: customer?.joinedOn || "-",
      Status: customer?.customerStatus,
      customerId: customer?.customerId,
      kycId: customer?.kycId,
    };
  }

  const customerTableData = customers.map((customer) =>
    createCustomerRowData(customer)
  );

  const navigate = useNavigate();

  return customerTableData.map((row) => {
    return (
      <StyledTableRow
        key={row.customerId}
        style={{
          textDecoration: "none",
          cursor: "pointer",
        }}
        onClick={() => {
          navigate(`/admin/app/resources/customers/${row.customerId}`);
        }}
      >
        <TableCell
          sx={{
            color: "#6D6D6D",
            textAlign: "center",
          }}
        >
          {row.Name}
        </TableCell>
        <TableCell sx={{ color: "#6D6D6D", textAlign: "center" }}>
          {row.Mobile}
        </TableCell>
        <TableCell sx={{ color: "#6D6D6D", textAlign: "center" }}>
          {moment(row.Joined).format("DD-MM-YYYY")}
        </TableCell>
        {generateStatus(row.Status)}
      </StyledTableRow>
    );
  });
};
