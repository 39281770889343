import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Stack,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";

import { TableSearchInputField } from "../../../component/Common/TableSearchInput";
import { StyledTableRow } from "../../../component/Common/StyledTableRow";
import { StyledTableCell } from "./StyledTableCell";
import GenerateStats from "../../../component/Common/GenerateStats";
import { getAllKycRequests } from "../../../api/Admin/approval";
import { LOADING_STATUS } from "../../../constants/constant";
import LoadingTable from "../../../component/Common/LoadingTable";
import ErrorScreen from "../../../component/Common/ErrorScreen";
import Filters from "../../../component/Common/Filters";

const Approval = () => {
  const navigate = useNavigate();
  const [kycApprovals, setKycApprovals] = useState([]);
  const [stakeholderFilter, setStakeholderFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [receivedOnFilter, setReceivedOnFilter] = useState("asc");
  const [approvalStats, setApprovalStats] = useState(null);
  const [isKycFetched, setIskycFetched] = useState(LOADING_STATUS.INITIAL);
  const [inputValue, setInputValue] = useState("");

  // pagination states
  const [paginationData, setPaginationData] = useState(null);
  const [page, setPage] = useState(0);

  // states for maintaining where popover filter menu should appear
  const [anchorForStakeholder, setAnchorForStakeholder] = useState(null);
  const [anchorForStatus, setAnchorForStatus] = useState(null);

  const statsArray = [
    { name: "Total Approvals", value: approvalStats?.total },
    { name: "Approved", value: approvalStats?.approved },
    { name: "Pending", value: approvalStats?.pending },
    { name: "Rejected", value: approvalStats?.rejected },
  ];

  const handleSearchInputChange = (event) => {
    if (isNaN(event.target.value)) return;
    setInputValue(event.target.value);
  };

  const fetchKycApprovalsRequests = async () => {
    setIskycFetched(LOADING_STATUS.LOADING);
    const response = await getAllKycRequests(
      stakeholderFilter,
      statusFilter,
      page + 1,
      inputValue
    );
    // if response is a success
    if (response.status === 200) {
      const approvalsArray = response.data.data.kycRequests;
      setApprovalStats(response.data.data.counts);
      setKycApprovals(approvalsArray);
      setPaginationData(response.data.data.pagination);
      setIskycFetched(LOADING_STATUS.LOADED);
    } else {
      setIskycFetched(LOADING_STATUS.FAILED);
    }
  };

  // onclick handler for stakeholder filter
  const updateStakeholderFilter = (event) => {
    setStakeholderFilter(event.target.textContent.toUpperCase());
    setPage(0);
  };

  // onclick handler for status filter
  const updateApprovalStatusFilter = (event) => {
    setStatusFilter(event.target.textContent.toUpperCase());
    setPage(0);
  };

  // filter popup handlers for stakeholder type
  const handleOpenStakeholderFilter = (event) => {
    setAnchorForStakeholder(event.currentTarget);
  };
  const handleCloseStakeholderFilter = () => {
    setAnchorForStakeholder(null);
  };

  // filter popup handlers for status type
  const handleOpenStatusFilter = (event) => {
    setAnchorForStatus(event.currentTarget);
  };
  const handleCloseStatusFilter = () => {
    setAnchorForStatus(null);
  };
  // pagination handlers functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
  };

  useEffect(() => {
    fetchKycApprovalsRequests();
  }, [stakeholderFilter, statusFilter, page, inputValue]);

  // render part
  return (
    <Box>
      <Stack
        p=".75rem 1.625rem"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid #c6c6c6"
      >
        <GenerateStats data={statsArray} />
        <TableSearchInputField
          placeholder="Mobile No."
          value={inputValue}
          onChange={handleSearchInputChange}
        />
      </Stack>
      <Box p={3}>
        <TableContainer>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {"Stakeholder"}

                    <img
                      src="/assets/icons/expand_less.svg"
                      alt="expand less"
                      onClick={handleOpenStakeholderFilter}
                      style={{ cursor: "pointer" }}
                    />
                    <Filters
                      open={anchorForStakeholder}
                      onClose={handleCloseStakeholderFilter}
                      onClick={updateStakeholderFilter}
                      filters={["Customer", "Transporter", "Driver", "All"]}
                      selectedFilter={stakeholderFilter}
                    />
                  </Stack>
                </StyledTableCell>
                <StyledTableCell>Phone Number</StyledTableCell>
                <StyledTableCell>Received On</StyledTableCell>
                <StyledTableCell>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {"Status"}

                    <img
                      src="/assets/icons/expand_less.svg"
                      alt="expand less"
                      onClick={handleOpenStatusFilter}
                      style={{ cursor: "pointer" }}
                    />
                    <Filters
                      open={anchorForStatus}
                      onClose={handleCloseStatusFilter}
                      onClick={updateApprovalStatusFilter}
                      filters={["Pending", "Approved", "Rejected", "All"]}
                      selectedFilter={statusFilter}
                    />
                  </Stack>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <ApprovalTable
              kycApprovals={kycApprovals}
              setStakeholderFilter={setStakeholderFilter}
              setStatusFilter={setStatusFilter}
              isKycFetched={isKycFetched}
              fetchKycApprovalsRequests={fetchKycApprovalsRequests}
              navigate={navigate}
            />
          </Table>
          {paginationData && (
            <div style={{ padding: "10px" }}>
              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={paginationData.totalItems}
                rowsPerPage={paginationData.pageSize}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ count }) => (
                  <>
                    Page{" "}
                    <span style={{ color: "green", fontWeight: 700 }}>
                      {page + 1}
                    </span>{" "}
                    of {Math.ceil(count / paginationData.pageSize)}
                  </>
                )}
              />
            </div>
          )}
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Approval;

function createData(approval) {
  return {
    id: approval._id,
    stakeholder: approval.profileType,
    phone: approval?.user?.phone ?? "",
    receivedOn: approval.updatedAt,
    status: approval.profileStatus,
  };
}

function ApprovalTable({
  kycApprovals,
  isKycFetched,
  fetchKycApprovalsRequests,
  navigate,
}) {
  const rows = kycApprovals.map((approval) => createData(approval));
  if (isKycFetched === LOADING_STATUS.LOADED) {
    return kycApprovals.length ? (
      <TableBody>{createApprovalTable(rows, navigate)}</TableBody>
    ) : (
      <TableRow sx={{ background: "#EAEAEA" }}>
        <TableCell
          colSpan={12}
          align="center"
          sx={{ fontWeight: 600, color: "#333" }}
        >
          No Results Found.
        </TableCell>
      </TableRow>
    );
  } else if (isKycFetched === LOADING_STATUS.FAILED)
    return <ErrorScreen onRetry={fetchKycApprovalsRequests} />;
  return <LoadingTable columns={4} />;
}

function createApprovalTable(rows, navigate) {
  const approvalStatus = (status) => {
    if (status === "PENDING") return "red";
    else if (status === "REJECTED") return "gray";
    return "green";
  };
  return rows.map((row) => {
    return (
      <StyledTableRow
        key={row.id}
        onClick={() => navigate(`${row.id}`)}
        style={{ textDecoration: "none", cursor: "pointer" }}
      >
        <TableCell scope="row" sx={{ color: "#6D6D6D" }}>
          {row.stakeholder[0] + row.stakeholder.slice(1).toLowerCase()}
        </TableCell>
        <TableCell sx={{ color: "#6D6D6D" }}>{row.phone}</TableCell>
        <TableCell sx={{ color: "#6D6D6D" }}>{row.receivedOn}</TableCell>
        <TableCell sx={{ color: approvalStatus(row.status) }}>
          {row.status[0] + row.status.slice(1).toLowerCase()}
        </TableCell>
      </StyledTableRow>
    );
  });
}
