import styled from "@emotion/styled";
import { TableRow } from "@mui/material";

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    border: "6px solid #FFF",
    background: "#F6F6F6",
    "&:hover": {
      background: "#DDE7EF",
    },
  
    td: {
      paddingInline: "1rem",
      paddingBlock: ".35rem",
      fontWeight: 700,
      fontSize: "12px",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));