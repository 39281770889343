import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import moment from "moment";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import LoadingTable from "../../Common/LoadingTable";
import {
  LOADING_STATUS,
  PAYMENT_STATUS_ARRAY,
  TRIP_STATUS_ARRAY,
} from "../../../constants/constant";
import ErrorScreen from "../../Common/ErrorScreen";

export default function TripTable({
  trips,
  isTripsListFetched,
  fetchTrips,
  setTripStatus,
  setCustomerPaymentStatus,
  setTransporterPaymentStatus,
  tripStatus,
  customerPaymentStatus,
  transporterPaymentStatus,
}) {
  const [filterPopoverAnchorEl, setFilterPopoverAnchorEl] = useState(null);

  const [
    filterPopoverAnchorElCustomerReceipts,
    setFilterPopoverAnchorElCustomerReceipts,
  ] = useState(null);

  const [filterPopoverAnchorElTripStatus, setFilterPopoverAnchorElTripStatus] =
    useState(null);

  // Handle opening the filter popover
  const handleFilterPopoverOpen = (event) => {
    setFilterPopoverAnchorEl(event.currentTarget);
  };

  const handleFilterPopoverOpenCustomerReceipts = (event) => {
    setFilterPopoverAnchorElCustomerReceipts(event.currentTarget);
  };

  const handleFilterPopoverOpenTripStatus = (event) => {
    setFilterPopoverAnchorElTripStatus(event.currentTarget);
  };

  // Handle closing the filter popover
  const handleFilterPopoverClose = () => {
    setFilterPopoverAnchorEl(null);
  };

  const handleFilterPopoverCloseCustomerReceipts = () => {
    setFilterPopoverAnchorElCustomerReceipts(null);
  };

  const handleFilterPopoverCloseTripStatus = () => {
    setFilterPopoverAnchorElTripStatus(null);
  };

  // Check if the filter popover is open
  const isFilterPopoverOpen = Boolean(filterPopoverAnchorEl);

  const isFilterPopoverOpenCustomerReceipts = Boolean(
    filterPopoverAnchorElCustomerReceipts
  );

  const isFilterPopoverOpenTripStatus = Boolean(
    filterPopoverAnchorElTripStatus
  );

  // Check if all statuses are selected
  const areAllStatusesSelected = (selectedStatuses, statusList) =>
    statusList.every((status) => selectedStatuses.includes(status));

  const handleStatusChange = (
    currentStatuses,
    setStatus,
    event,
    statusValues
  ) => {
    const isChecked = event.target.checked;
    setStatus((prevSelected) => {
      let newStatuses = new Set(prevSelected);

      statusValues.forEach((status) => {
        isChecked ? newStatuses.add(status) : newStatuses.delete(status);
      });

      return Array.from(newStatuses);
    });
  };

  // Handle checkbox change for customer payment status
  const handleCustomerPaymentStatusChange = (event) => {
    const { value } = event.target;
    setCustomerPaymentStatus((prevSelected) => {
      if (prevSelected.includes(value)) {
        return prevSelected.filter((status) => status !== value);
      } else {
        return [...prevSelected, value];
      }
    });
  };

  // Handle checkbox change for transporter payment status
  const handleTransporterPaymentStatusChange = (event) => {
    const { value } = event.target;
    setTransporterPaymentStatus((prevSelected) => {
      if (prevSelected.includes(value)) {
        return prevSelected.filter((status) => status !== value);
      } else {
        return [...prevSelected, value];
      }
    });
  };

  const navigate = useNavigate();

  function renderViews() {
    switch (isTripsListFetched) {
      case LOADING_STATUS.LOADING:
        return <LoadingTable columns={7} />;
      case LOADING_STATUS.LOADED:
        if (trips.length) {
          return (
            <>
              {trips.map((enquiry) => (
                <StyledTableRow
                  key={enquiry?.customerEnquiryId}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/admin/app/trips/" + enquiry?.id);
                  }}
                >
                  <StyledTableBodyCell>
                    {enquiry?.tripId || "-"}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {moment(enquiry?.tripDate).format("DD-MM-YYYY") || "-"}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {enquiry?.route || "-"}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell
                    color={
                      TRIP_STATUS_ARRAY.find(
                        (status) => status.label === enquiry.tripStatus
                      )?.color
                    }
                  >
                    {
                      TRIP_STATUS_ARRAY.find(
                        (status) => status.label === enquiry.tripStatus
                      )?.value
                    }
                  </StyledTableBodyCell>

                  <StyledTableBodyCell>
                    {enquiry?.customerName || "-"}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell
                    color={
                      PAYMENT_STATUS_ARRAY.find(
                        (status) =>
                          status.label === enquiry.customerPaymentStatus
                      )?.color
                    }
                  >
                    {
                      PAYMENT_STATUS_ARRAY.find(
                        (status) =>
                          status.label === enquiry.customerPaymentStatus
                      )?.value
                    }
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {enquiry?.transporterName || "-"}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell
                    color={
                      PAYMENT_STATUS_ARRAY.find(
                        (status) =>
                          status.label === enquiry.transporterPaymentStatus
                      )?.color
                    }
                  >
                    {PAYMENT_STATUS_ARRAY.map((status) => {
                      if (status.label === enquiry?.transporterPaymentStatus) {
                        return status.value;
                      }
                    })}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {enquiry?.driverNumber || "-"}
                  </StyledTableBodyCell>
                </StyledTableRow>
              ))}
            </>
          );
        } else {
          return (
            <TableRow sx={{ background: "#EAEAEA" }}>
              <TableCell
                colSpan={12}
                align="center"
                sx={{ fontWeight: 600, color: "#333" }}
              >
                No Results Found.
              </TableCell>
            </TableRow>
          );
        }
      case LOADING_STATUS.FAILED:
        return (
          <TableRow>
            <TableCell colSpan={7} sx={{ borderBottom: "none" }}>
              <ErrorScreen onRetry={fetchTrips} />
            </TableCell>
          </TableRow>
        );
      default:
        return null;
    }
  }

  return (
    <Box style={{ height: "100%" }}>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          height: "100%",
          boxShadow: "none",
          paddingBottom: "10px",
        }}
      >
        <Table size="small" aria-label="Enquiry data" stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell
                sx={{
                  width: "140px",
                }}
              >
                Trip ID
              </StyledTableCell>
              <StyledTableCell>Trip Date</StyledTableCell>
              <StyledTableCell> Route</StyledTableCell>
              <StyledTableCell>
                <StatusBox>
                  Trip Status
                  <img
                    src="/assets/icons/expand_less.svg"
                    alt="expand less transporter status"
                    style={{ cursor: "pointer" }}
                    onClick={handleFilterPopoverOpenTripStatus}
                  />
                </StatusBox>

                <Popover
                  open={isFilterPopoverOpenTripStatus}
                  anchorEl={filterPopoverAnchorElTripStatus}
                  onClose={handleFilterPopoverCloseTripStatus}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <PopOverBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={areAllStatusesSelected(tripStatus, [
                            "BOOKING_ACCEPTED",
                            "DRIVER_AT_PICKUP_LOCATION",
                            "MATERIAL_LOADED",
                          ])}
                          onChange={(e) =>
                            handleStatusChange(tripStatus, setTripStatus, e, [
                              "BOOKING_ACCEPTED",
                              "DRIVER_AT_PICKUP_LOCATION",
                              "MATERIAL_LOADED",
                            ])
                          }
                          value="BOOKING_ACCEPTED,DRIVER_AT_PICKUP_LOCATION,MATERIAL_LOADED"
                        />
                      }
                      label="Upcoming"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tripStatus.includes("TRUCK_IN_TRANSIT")}
                          onChange={(e) =>
                            handleStatusChange(tripStatus, setTripStatus, e, [
                              "TRUCK_IN_TRANSIT",
                            ])
                          }
                          value="TRUCK_IN_TRANSIT"
                        />
                      }
                      label="In Transit"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tripStatus.includes("TRIP_COMPLETED")}
                          onChange={(e) =>
                            handleStatusChange(tripStatus, setTripStatus, e, [
                              "TRIP_COMPLETED",
                            ])
                          }
                          value="TRIP_COMPLETED"
                        />
                      }
                      label="Completed"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tripStatus.includes(
                            "CANCELLED_BY_CUSTOMER",
                            "CANCELLED_BY_TRANSPORTER",
                            "CANCELLED_BY_ADMIN"
                          )}
                          onChange={(e) =>
                            handleStatusChange(tripStatus, setTripStatus, e, [
                              "CANCELLED_BY_CUSTOMER",
                              "CANCELLED_BY_TRANSPORTER",
                              "CANCELLED_BY_ADMIN",
                            ])
                          }
                          value="CANCELLED_BY_CUSTOMER,CANCELLED_BY_TRANSPORTER,CANCELLED_BY_ADMIN"
                        />
                      }
                      label="Cancelled"
                    />
                    {tripStatus.length > 0 && (
                      <Button
                        onClick={() => {
                          setTripStatus([]);
                        }}
                      >
                        Clear All
                      </Button>
                    )}
                  </PopOverBox>
                </Popover>
              </StyledTableCell>
              <StyledTableCell>Customer Name</StyledTableCell>
              <StyledTableCell>
                <StatusBox>
                  Customer Receipts
                  <img
                    src="/assets/icons/expand_less.svg"
                    alt="expand less transporter status"
                    style={{ cursor: "pointer" }}
                    onClick={handleFilterPopoverOpenCustomerReceipts}
                  />
                </StatusBox>

                <Popover
                  open={isFilterPopoverOpenCustomerReceipts}
                  anchorEl={filterPopoverAnchorElCustomerReceipts}
                  onClose={handleFilterPopoverCloseCustomerReceipts}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <PopOverBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={customerPaymentStatus.includes("PAID")}
                          onChange={handleCustomerPaymentStatusChange}
                          value="PAID"
                        />
                      }
                      label="Paid"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={customerPaymentStatus.includes(
                            "PARTIALLY_PAID"
                          )}
                          onChange={handleCustomerPaymentStatusChange}
                          value="PARTIALLY_PAID"
                        />
                      }
                      label="Partial"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={customerPaymentStatus.includes("UNPAID")}
                          onChange={handleCustomerPaymentStatusChange}
                          value="UNPAID"
                        />
                      }
                      label="Unpaid"
                    />
                    {customerPaymentStatus.length > 0 && (
                      <Button
                        onClick={() => {
                          setCustomerPaymentStatus([]);
                        }}
                      >
                        Clear All
                      </Button>
                    )}
                  </PopOverBox>
                </Popover>
              </StyledTableCell>
              <StyledTableCell>Transporter Name</StyledTableCell>
              <StyledTableCell>
                <StatusBox>
                  Transporter Payments
                  <img
                    src="/assets/icons/expand_less.svg"
                    alt="expand less transporter status"
                    style={{ cursor: "pointer" }}
                    onClick={handleFilterPopoverOpen}
                  />
                </StatusBox>

                <Popover
                  open={isFilterPopoverOpen}
                  anchorEl={filterPopoverAnchorEl}
                  onClose={handleFilterPopoverClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <PopOverBox>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={transporterPaymentStatus.includes("PAID")}
                          onChange={handleTransporterPaymentStatusChange}
                          value="PAID"
                        />
                      }
                      label="Paid"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={transporterPaymentStatus.includes(
                            "PARTIALLY_PAID"
                          )}
                          onChange={handleTransporterPaymentStatusChange}
                          value="PARTIALLY_PAID"
                        />
                      }
                      label="Partial"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={transporterPaymentStatus.includes("UNPAID")}
                          onChange={handleTransporterPaymentStatusChange}
                          value="UNPAID"
                        />
                      }
                      label="Unpaid"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={transporterPaymentStatus.includes(
                            "REFUNDED"
                          )}
                          onChange={handleTransporterPaymentStatusChange}
                          value="REFUNDED"
                        />
                      }
                      label="Refunded"
                    />
                    {transporterPaymentStatus.length > 0 && (
                      <Button
                        onClick={() => {
                          setTransporterPaymentStatus([]);
                        }}
                      >
                        Clear All
                      </Button>
                    )}
                  </PopOverBox>
                </Popover>
              </StyledTableCell>
              <StyledTableCell> Driver No.</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderViews()}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const PopOverBox = styled(Box)({
  padding: "10px",
  display: "flex",
  flexDirection: "column",
});

const StatusBox = styled(Box)({
  fontSize: "14px",
  fontWeight: 700,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
});

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFFFFF",
    color: "#333333",
    borderRight: "1px solid #c6c6c6",
    fontWeight: 700,
    borderBottom: "none",
    width: "10%",
    textAlign: "center",
  },
}));

const StyledTableBodyCell = styled(TableCell)(({ color }) => ({
  color: color || "#6D6D6D",
}));

const StyledTableRow = styled(TableRow)(() => ({
  border: "2px solid #FFF",
  background: "#F6F6F6",
  "&:hover": {
    background: "#DDE7EF",
  },
  td: {
    fontWeight: 700,
    fontSize: "12px",
    paddingBottom: "2px",
    paddingTop: "2px",
    borderBottom: "6px solid #fff",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
