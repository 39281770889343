import { Button } from "@mui/material";
import React from "react";

function AddButton({ onClick, name }) {
  return (
    <Button
      variant="outlined"
      onClick={onClick}
      sx={{
        display: "flex",
        height: "30px",
        alignItems: "center",
        justifyContent: "space-around",
        borderColor: "#333333",
        borderRadius: "2px",
        padding: "8px 8px 8px 16px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        fontFamily: "Helvetica",
        fontWeight: "700",
        fontSize: "16px",
        color: "#333",
        gap: "8px",
      }}
    >
      {name}
      <img
        src="/assets/icons/add.svg"
        alt="addImage"
        style={{
          height: "20px",
          width: "20px",
        }}
      />
    </Button>
  );
}

export default AddButton;
