import { guestApi } from "./config"

const sendOTP = async(endpoint, phoneNumber) =>{
    return await guestApi.post(`/${endpoint}`,{
        phone:phoneNumber
    })
}

const userprofileById = async(id)=>{
    return await guestApi.get(`/wati/profile/${id}`)
}

export {sendOTP, userprofileById}