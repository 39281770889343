import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { css } from "@emotion/react";

export const StyledDetailsSectionBox = styled(Box)`
  background: #eaeaea;
  border-radius: 5px;
  text-align: center;
  padding: 8px 42px 8px 42px;
  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}
`;
