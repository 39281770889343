import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { getCustomerTrips } from "../../api/Admin/customerResources";
import moment from "moment";
import { StyledTableCell } from "../../pages/Admin/Approval/StyledTableCell";
import { StyledTableRow } from "../Common/StyledTableRow";
import { useNavigate } from "react-router-dom";
import { TRIP_STATUS_ARRAY } from "../../constants/constant";
import useToast from "../../hooks/useToast";

const CustomerTripsTable = ({ panelExpanded, updateExpandedPanel, id }) => {
  const [customerTrips, setCustomerTrips] = useState([]);
  const [paginationData, setPaginationData] = useState({});

  const navigate = useNavigate();

  const createTable = (customerTrips) => {
    return (
      <StyledTableRow
        key={customerTrips.id}
        onClick={() => {
          navigate(`/admin/app/trips/${customerTrips.id}`);
        }}
        sx={{ cursor: "pointer" }}
      >
        <StyledTableCell sx={{ color: "#6D6D6D" }}>
          {customerTrips.tripId || ""}
        </StyledTableCell>
        <StyledTableCell sx={{ color: "#6D6D6D" }}>
          {moment(customerTrips.tripDate).format("DD-MM-YYYY hh:mm A") || ""}
        </StyledTableCell>
        <StyledTableCell sx={{ color: "#6D6D6D" }}>
          {customerTrips.route || ""}
        </StyledTableCell>
        <StyledTableBodyCell
          sx={{ color: "#6D6D6D" }}
          color={
            TRIP_STATUS_ARRAY.find(
              (status) => status.label === customerTrips.tripStatus
            )?.color
          }
        >
          {
            TRIP_STATUS_ARRAY.find(
              (status) => status.label === customerTrips.tripStatus
            )?.value
          }
        </StyledTableBodyCell>
        <StyledTableCell sx={{ color: "#6D6D6D" }}>
          {`₹ ${customerTrips.customerAmounts.totalAmount}` || ""}
        </StyledTableCell>
        <StyledTableCell sx={{ color: "#6D6D6D" }}>
          {`₹ ${customerTrips.customerAmounts.balanceReceivable}` || ""}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const [tripStatus, setTripStatus] = useState([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [search, setSearch] = useState("");

  const { handleOpenToast } = useToast();
  const [filterPopoverAnchorElTripStatus, setFilterPopoverAnchorElTripStatus] =
    useState(null);

  const handleFilterPopoverOpenTripStatus = (event) => {
    setFilterPopoverAnchorElTripStatus(event.currentTarget);
  };

  const handleFilterPopoverCloseTripStatus = () => {
    setFilterPopoverAnchorElTripStatus(null);
  };

  const isFilterPopoverOpenTripStatus = Boolean(
    filterPopoverAnchorElTripStatus
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
  };

  const handleStatusChange = (
    currentStatuses,
    setStatus,
    event,
    statusValues
  ) => {
    const isChecked = event.target.checked;
    setStatus((prevSelected) => {
      let newStatuses = new Set(prevSelected);

      statusValues.forEach((status) => {
        isChecked ? newStatuses.add(status) : newStatuses.delete(status);
      });

      return Array.from(newStatuses);
    });
  };

  const fetchCustomerTrips = async () => {
    try {
      const response = await getCustomerTrips(
        id,
        page,
        pageSize,
        tripStatus,
        search
      );
      if (response.status === 200) {
        setCustomerTrips(response.data.data.trips);
        setPaginationData(response.data.data.pagination);
      }
    } catch (error) {
      handleOpenToast("Error while getting customer trips", "error");
    }
  };

  useEffect(() => {
    fetchCustomerTrips();
  }, [page, pageSize, tripStatus, search]);

  return (
    <Accordion
      disableGutters
      TransitionProps={{ unmountOnExit: true }}
      sx={{ borderRadius: ".35rem" }}
      expanded={panelExpanded === "Trips"}
      onChange={updateExpandedPanel("Trips")}
    >
      <AccordionSummary
        expandIcon={
          <img src="/assets/icons/expand_less.svg" alt="expand_less" />
        }
        aria-controls="panel1bh-content"
        sx={{
          background: "#EAEAEA",
          paddingLeft: "2rem",
          fontWeight: 700,

          "&.Mui-expanded": {
            paddingTop: 0,
          },
          "&.MuiAccordionSummary-root": {
            height: "36px !important",
            minHeight: "36px !important",
          },
        }}
      >
        {"Trips"}
      </AccordionSummary>

      <AccordionDetails
        sx={{
          background: "#FFF",
          paddingLeft: "2rem",
          paddingTop: "1rem",
        }}
      >
        <TextField
          variant="outlined"
          placeholder="Search by trip id"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          InputProps={{
            sx: { height: "36.31px" },
            endAdornment: (
              <img src="/assets/icons/search-icon.svg" alt="search" />
            ),
          }}
        />
        {
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 700 }}>Trip ID</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Trip Date</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Route</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>
                    <StatusBox>
                      Trip Status
                      <img
                        src="/assets/icons/expand_less.svg"
                        alt="expand less transporter status"
                        style={{ cursor: "pointer" }}
                        onClick={handleFilterPopoverOpenTripStatus}
                      />
                    </StatusBox>
                    <Popover
                      open={isFilterPopoverOpenTripStatus}
                      anchorEl={filterPopoverAnchorElTripStatus}
                      onClose={handleFilterPopoverCloseTripStatus}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <PopOverBox>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={tripStatus.includes(
                                "BOOKING_ACCEPTED",
                                "DRIVER_AT_PICKUP_LOCATION",
                                "MATERIAL_LOADED"
                              )}
                              onChange={(e) =>
                                handleStatusChange(
                                  tripStatus,
                                  setTripStatus,
                                  e,
                                  [
                                    "BOOKING_ACCEPTED",
                                    "DRIVER_AT_PICKUP_LOCATION",
                                    "MATERIAL_LOADED",
                                  ]
                                )
                              }
                              value="BOOKING_ACCEPTED,DRIVER_AT_PICKUP_LOCATION,MATERIAL_LOADED"
                            />
                          }
                          label="Upcoming"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={tripStatus.includes("TRUCK_IN_TRANSIT")}
                              onChange={(e) =>
                                handleStatusChange(
                                  tripStatus,
                                  setTripStatus,
                                  e,
                                  ["TRUCK_IN_TRANSIT"]
                                )
                              }
                              value="TRUCK_IN_TRANSIT"
                            />
                          }
                          label="In Transit"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={tripStatus.includes("TRIP_COMPLETED")}
                              onChange={(e) =>
                                handleStatusChange(
                                  tripStatus,
                                  setTripStatus,
                                  e,
                                  ["TRIP_COMPLETED"]
                                )
                              }
                              value="TRIP_COMPLETED"
                            />
                          }
                          label="Completed"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={tripStatus.includes(
                                "CANCELLED_BY_CUSTOMER",
                                "CANCELLED_BY_TRANSPORTER",
                                "CANCELLED_BY_ADMIN"
                              )}
                              onChange={(e) =>
                                handleStatusChange(
                                  tripStatus,
                                  setTripStatus,
                                  e,
                                  [
                                    "CANCELLED_BY_CUSTOMER",
                                    "CANCELLED_BY_TRANSPORTER",
                                    "CANCELLED_BY_ADMIN",
                                  ]
                                )
                              }
                              value="CANCELLED_BY_CUSTOMER,CANCELLED_BY_TRANSPORTER,CANCELLED_BY_ADMIN"
                            />
                          }
                          label="Cancelled"
                        />
                        {tripStatus.length > 0 && (
                          <Button
                            onClick={() => {
                              setTripStatus([]);
                            }}
                          >
                            Clear All
                          </Button>
                        )}
                      </PopOverBox>
                    </Popover>
                  </TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Total Amount</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Pending Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customerTrips?.length > 0 ? (
                  customerTrips?.map((customerTrip) =>
                    createTable(customerTrip)
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={12} align="center">
                      Trips not found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </AccordionDetails>
      {customerTrips?.length > 1 && (
        <div
          style={{
            borderTop: "1px solid #adadad",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={paginationData.totalItems}
            rowsPerPage={paginationData.pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ count }) => (
              <div>
                Page{" "}
                <span style={{ color: "green", fontWeight: 700 }}>
                  {page + 1}
                </span>{" "}
                of {Math.ceil(count / paginationData.pageSize)}
              </div>
            )}
          />
        </div>
      )}
    </Accordion>
  );
};

export default CustomerTripsTable;

const StyledTableBodyCell = styled(TableCell)(({ color }) => ({
  color: color || "#6D6D6D",
}));

const PopOverBox = styled(Box)({
  padding: "10px",
  display: "flex",
  flexDirection: "column",
});

const StatusBox = styled(Box)({
  fontSize: "14px",
  fontWeight: 700,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
});
