import { customerApi } from "../config";

export const getCustomerTrips = async (customerId, page, search) => {
  try {
    const response = await customerApi.get(
      `customer/trip/${customerId}?page=${page + 1}&search=${search}`
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const getCustomerTripsByStatus = async (
  customerId,
  page,
  tripStatus
) => {
  try {
    const response = await customerApi.get(
      `customer/trip/${customerId}?page=${
        page + 1
      }&tripStatus=${tripStatus.toString()}`
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const getrTripDetails = async (customerId, tripId) => {
  try {
    const response = await customerApi.get(
      `customer/trip/${customerId}/${tripId}`
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const createTransaction = async (transactionData) => {
  try {
    const response = customerApi.post(`transaction`, transactionData);
    return response;
  } catch (error) {
    return error;
  }
};

export const patchToggleFavourite = async (customerId, trip_id) => {
  try {
    const response = customerApi.patch(
      `customer/trip/${customerId}/${trip_id}/toggle-favourite`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getFavorites = async (customerId, page, pageSize) => {
  try {
    const response = customerApi.get(
      `customer/${customerId}/favourite-trips?page=${
        page + 1
      }&pageSize=${pageSize}`
    );
    return response;
  } catch (error) {
    return error;
  }
};
