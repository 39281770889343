import React, { useState, useEffect, Fragment } from "react";
import {
  Box,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import styled from "@emotion/styled";
import moment from "moment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import TripDetails from "./TripDetails";
import TripStatusCell from "./TripStatusCell";
import TripPaymentStatusCell from "./TripPaymentStatusCell";
import TripStatusFilter from "./TripStatusFilter";
import {
  TRANSPORTER_PAYMENT_STATUS_ARRAY,
  TRANSPORTER_TRIP_STATUS_ARRAY,
} from "../../../constants/constant";
import TripPaymentFilter from "./TripPaymentFilter";
import { LOADING_STATUS } from "../../../constants/constant";
import LoadingTable from "../../Common/LoadingTable";
import ErrorScreen from "../../Common/ErrorScreen";
import { getTransporterTripById } from "../../../api/Transporter/transporterTrips";
import useToast from "../../../hooks/useToast";

const StyledBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  color: "#333333",
  fontWeight: 700,
}));

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFFFFF",
    color: "#333333",
    borderRight: "1px solid #c6c6c6",
    fontWeight: 700,
    borderBottom: "none",
    textAlign: "center",
  },
}));

const StyledTableBodyCell = styled(TableCell)(() => ({
  color: "#6D6D6D",
  textAlign: "center",
}));

const StyledTableRow = styled(TableRow)(({ selected }) => {
  return {
    cursor: "pointer",
    borderBottom: "none",
    "&:hover": {
      background: "#E9F2F4",
      td: {
        color: "#333333",
      },
    },

    td: {
      fontWeight: 700,
      fontSize: "12px",
      paddingBottom: "18px",
      paddingTop: "18px",
      color: selected && "#333333",
      borderBottom: "none",
    },

    "&:last-child td, &:last-child th": {
      border: 0,
    },
  };
});

export default function TripsTable(props) {
  const {
    trips,
    setTripStatus,
    tripStatus,
    paymentStatus,
    setPaymentStatus,
    isTripsListFetched,
    transporterTrips,
    transporterId,
  } = props;
  const { handleOpenToast } = useToast();

  const [selectedRow, setSelectedRow] = useState(null);
  const [tripDetails, setTripDetails] = useState([]);

  const [openStatusFilter, setOpenStatusFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState([]);

  const [openPaymentStatusFilter, setPaymentStatusFilter] = useState(null);
  const [paymentStatusFilter, setPaymentStatusStatusFilter] = useState([]);

  const tripId = selectedRow?.id;

  const handleOpenStatusFilter = (event) => {
    setOpenStatusFilter(event.target);
  };

  const handleCloseStatusFilter = () => {
    setOpenStatusFilter(null);
  };

  const handleOpenPaymentStatusFilter = (event) => {
    setPaymentStatusFilter(event.target);
  };

  const handleClosePaymentStatusFilter = () => {
    setPaymentStatusFilter(null);
  };

  const tripByTransporterId = async () => {
    try {
      const response = await getTransporterTripById(transporterId, tripId);
      if (response?.status === 200) {
        setTripDetails(response.data.data);
      }
    } catch (error) {
      handleOpenToast("Error while getting trip details", "error");
    }
  };

  useEffect(() => {
    if ((transporterId, tripId)) {
      tripByTransporterId();
    }
  }, [transporterId, tripId]);

  function renderViews() {
    switch (isTripsListFetched) {
      case LOADING_STATUS.LOADING:
        return <LoadingTable columns={8} />;
      case LOADING_STATUS.LOADED:
        if (trips?.length) {
          return trips.map((trip, index) => (
            <Fragment key={index}>
              <StyledTableRow
                selected={selectedRow?.id === trip.id}
                onClick={() => {
                  if (selectedRow?.id === trip.id) {
                    setSelectedRow(null);
                  } else setSelectedRow(trip);
                }}
              >
                <StyledTableBodyCell>{trip.tripId}</StyledTableBodyCell>
                <StyledTableBodyCell>{trip.route}</StyledTableBodyCell>
                <StyledTableBodyCell>
                  {moment(trip.tripDate).format("DD-MM-YYYY")}
                </StyledTableBodyCell>
                <StyledTableBodyCell>
                  {trip.tripTimeSlot ? trip.tripTimeSlot : "-"}
                </StyledTableBodyCell>
                <StyledTableBodyCell
                  sx={{
                    color: Boolean(trip.driverName)
                      ? "#6D6D6D"
                      : "red !important",
                  }}
                >
                  {trip.driverName ? trip.driverName : "Unassigned"}
                </StyledTableBodyCell>
                <StyledTableBodyCell>{trip.VehicleNumber}</StyledTableBodyCell>
                <StyledTableBodyCell>
                  <TripStatusCell status={trip.tripStatus} />
                </StyledTableBodyCell>
                <StyledTableBodyCell>
                  <TripPaymentStatusCell
                    status={trip.transporterPaymentStatus}
                  />
                </StyledTableBodyCell>
              </StyledTableRow>

              {selectedRow?.id === trip.id && (
                <TableRow sx={{ width: "100%", borderColor: "red" }}>
                  <TableCell
                    colSpan={8}
                    sx={{
                      padding: "0px",
                    }}
                  >
                    <Collapse in={selectedRow?.id === trip.id}>
                      <TripDetails
                        selectedRow={selectedRow}
                        setSelectedRow={setSelectedRow}
                        tripDetails={tripDetails}
                        transporterId={transporterId}
                        updateDriver={(data) => {
                          setTripDetails({
                            ...tripDetails,
                            driver: data.driver,
                          });

                          const updatedTrips = trips.map((trip) => {
                            if (trip.id === selectedRow?.id) {
                              return {
                                ...trip,
                                driverName: data.driver.driverName,
                              };
                            } else {
                              return trip;
                            }
                          });

                          props.setTrips(updatedTrips);
                        }}
                      />
                    </Collapse>
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          ));
        } else {
          return (
            <TableRow sx={{ background: "#EAEAEA" }}>
              <TableCell
                colSpan={8}
                align="center"
                sx={{ fontWeight: 600, color: "#333" }}
              >
                No Results Found.
              </TableCell>
            </TableRow>
          );
        }
      case LOADING_STATUS.FAILED:
        return (
          <TableRow>
            <TableCell colSpan={8} sx={{ borderBottom: "none" }}>
              <ErrorScreen onRetry={transporterTrips} />
            </TableCell>
          </TableRow>
        );
      default:
        return null;
    }
  }

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          boxShadow: "none",
        }}
      >
        <Table size="small" aria-label="Truck data" stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>Trip Id</StyledTableCell>
              <StyledTableCell>Route</StyledTableCell>
              <StyledTableCell>Pickup Date</StyledTableCell>
              <StyledTableCell>Pickup Time</StyledTableCell>
              <StyledTableCell>Driver Name</StyledTableCell>
              <StyledTableCell>Truck No</StyledTableCell>

              <TableCell
                sx={{
                  borderRight: "1px solid #c6c6c6",
                  cursor: "pointer",
                  borderBottom: "none",
                }}
              >
                <StyledBox>
                  Trip Status
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer" }}
                    onClick={handleOpenStatusFilter}
                  />
                </StyledBox>
                <TripStatusFilter
                  open={openStatusFilter}
                  onClose={handleCloseStatusFilter}
                  filters={TRANSPORTER_TRIP_STATUS_ARRAY}
                  setFilters={setStatusFilter} //
                  selectedFilters={statusFilter} //
                  tripStatus={tripStatus}
                  setTripStatus={setTripStatus}
                />
              </TableCell>

              <TableCell
                sx={{
                  borderRight: "1px solid #c6c6c6",
                  cursor: "pointer",
                  borderBottom: "none",
                }}
              >
                <StyledBox>
                  Payment Status
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer" }}
                    onClick={handleOpenPaymentStatusFilter}
                  />
                </StyledBox>
                <TripPaymentFilter
                  open={openPaymentStatusFilter}
                  onClose={handleClosePaymentStatusFilter}
                  filters={TRANSPORTER_PAYMENT_STATUS_ARRAY}
                  setFilters={setPaymentStatusStatusFilter} //
                  selectedFilters={paymentStatusFilter} //
                  paymentStatus={paymentStatus}
                  setPaymentStatus={setPaymentStatus}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderViews()}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
