import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDocumentDetailsForKycId,
  getDriverById,
  updateDriverStatus,
} from "../../../api/Admin/driverResources";
import useToast from "../../../hooks/useToast";
import DriverTripsTable from "../../../component/AdminResources/DriverTripsTable";
import styled from "@emotion/styled";
import ToggleButton from "../../../component/Common/ToggleButton";
import moment from "moment";

const DocumentImage = (props) => {
  return (
    <img
      src={props?.url}
      alt="doc"
      width="93px"
      height="59px"
      style={{ border: "1px solid #c6c6c6", borderRadius: ".25rem" }}
    />
  );
};

const DriverResourcesDetailedView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [aadhaarImages, setAadhaarImages] = useState(null);
  const [licenseImages, setLicenseImages] = useState(null);
  const [panImages, setPanImages] = useState(null);
  const [overallProfileStatus, setOverallProfileStatus] = useState(null);
  const [driverDetails, setDriverDetails] = useState(null);
  const [panelExpanded, setPanelExpanded] = useState(null);
  const updateExpandedPanel = (panel) => (event, isExpanded) => {
    setPanelExpanded(isExpanded ? panel : false);
  };

  const [isDriverAvailable, setIsDriverAvailable] = useState(
    driverDetails?.status === "AVAILABLE" ? true : false
  );

  const { handleOpenToast, ToastMessage } = useToast();

  const fetchKycDocuments = async (id) => {
    try {
      const response = await getDocumentDetailsForKycId(id);
      if (response.status === 200) {
        setPanImages(response?.data?.data?.panDetails);
        setLicenseImages(response?.data?.data?.drivingLicenceDetails);
        setAadhaarImages(response?.data?.data?.aadharDetails);
        setOverallProfileStatus(response?.data?.data?.profileStatus);
      }
    } catch (error) {
      handleOpenToast("Error while getting driver kyc details", "error");
    }
  };

  const handleUpdateDriverStatus = async () => {
    try {
      const newStatus = isDriverAvailable ? "UNAVAILABLE" : "AVAILABLE";
      const response = await updateDriverStatus(id, newStatus);
      if (response.status === 200) {
        handleOpenToast("Driver status updated successfully", "success");
        setIsDriverAvailable(!isDriverAvailable);
      }
    } catch (error) {
      handleOpenToast("Error while updating driver status", "error");
    }
  };

  const onToggle = async () => {
    setIsDriverAvailable(!isDriverAvailable);
    handleUpdateDriverStatus();
  };

  const getProfileStatus = () => {
    let formattedText =
      overallProfileStatus[0] + overallProfileStatus.slice(1).toLowerCase();
    if (overallProfileStatus === "APPROVED")
      return <Typography color="#1CA82E">{formattedText}</Typography>;
    else if (
      overallProfileStatus === "REJECTED" ||
      overallProfileStatus === "INCOMPLETE"
    )
      return <Typography color="gray">{formattedText}</Typography>;
    else return <Typography color="red">{formattedText}</Typography>;
  };

  const getDetails = async () => {
    try {
      const response = await getDriverById(id);
      if (response.status === 200) {
        setDriverDetails(response.data.data);
      }
    } catch (error) {
      handleOpenToast("Error while getting driver details", "error");
    }
  };

  useEffect(() => {
    getDetails();
    setIsDriverAvailable(driverDetails?.status === "AVAILABLE");
  }, [id, driverDetails?.status]);

  useEffect(() => {
    if (driverDetails) {
      fetchKycDocuments(driverDetails?.kycId);
    }
  }, [driverDetails]);

  return (
    <Box>
      <ToastMessage />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ background: "#EAEAEA" }}
        p=".5rem 1.5rem"
      >
        <img
          alt="back"
          src="/assets/icons/backArrow.svg"
          style={{ cursor: "pointer", width: "24px", height: "20px" }}
          onClick={() => navigate(-1)}
        />
        <Typography color="#333" fontWeight={700} fontSize="1.25rem">
          {driverDetails?.driverName || ""}
        </Typography>
        <StatusAndToggle>
          <ToggleButton toggledState={isDriverAvailable} onToggle={onToggle} />
          <StatusTypography
            sx={{ color: isDriverAvailable ? "#00B87C" : "#FF0000" }}
          >
            {isDriverAvailable ? "Available" : "Not Available"}
          </StatusTypography>
        </StatusAndToggle>
      </Stack>
      <Box p="1rem 2rem">
        <Stack direction="row">
          <Typography sx={{ fontWeight: 700, color: "#333", fontSize: "1rem" }}>
            Driver Details
          </Typography>
          <div style={{ borderBottom: "1px solid #6D6D6D", flexGrow: 1 }}></div>
        </Stack>
        <Grid container gap={5} alignItems="center" mt={2} pl={2}>
          <Grid item xs="auto">
            <Avatar
              sx={{ width: "120px", height: "120px" }}
              src={driverDetails?.profilePhoto}
              alt="Driver"
            />
          </Grid>
          <Grid item xs="auto">
            <Typography>
              Name : <b>{driverDetails?.driverName || "-"}</b>
            </Typography>
            <Typography>
              Phone No. : <b>{driverDetails?.phone || "-"}</b>
            </Typography>
            <Typography>
              Email : <b>{driverDetails?.email || "-"}</b>
            </Typography>
            <Typography>
              Transporter Name :{" "}
              <b>{driverDetails?.linkedTransporters[0]?.fullName || "-"}</b>
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Typography>
              Onboarding Date & time : <b>{driverDetails?.createdAt}</b>
            </Typography>
            <Typography>
              Date of Birth :{" "}
              {moment(driverDetails?.dateOfBirth).format("DD-MM-YYYY")}
            </Typography>
            <Typography>
              Age :{" "}
              {driverDetails?.dateOfBirth &&
                new Date().getFullYear() -
                  new Date(driverDetails?.dateOfBirth).getFullYear()}
            </Typography>
            <Typography>Address : {driverDetails?.address || "-"}</Typography>
          </Grid>
        </Grid>
        <Stack direction="row" mt={3}>
          <Typography sx={{ fontWeight: 700, color: "#333", fontSize: "1rem" }}>
            Documents Details
          </Typography>
          <div style={{ borderBottom: "1px solid #6D6D6D", flexGrow: 1 }}></div>
        </Stack>
        <Stack mt={2} direction="row" gap={2}>
          <Stack>
            <Typography>Driving License Photo</Typography>
            {licenseImages?.frontUrl && licenseImages?.backUrl ? (
              <Stack direction="row" gap={1}>
                <DocumentImage url={licenseImages?.frontUrl} />
                <DocumentImage url={licenseImages?.backUrl} />
              </Stack>
            ) : (
              <Stack direction="row" gap={1}>
                <DocumentImage url="/assets/images/Image-placeholder.png" />
                <DocumentImage url="/assets/images/Image-placeholder.png" />
              </Stack>
            )}
          </Stack>
          <Stack>
            <Typography>Aadhaar Card Photo</Typography>
            {aadhaarImages?.frontUrl && aadhaarImages?.backUrl ? (
              <Stack direction="row" gap={1}>
                <DocumentImage url={aadhaarImages?.frontUrl} />
                <DocumentImage url={aadhaarImages?.backUrl} />
              </Stack>
            ) : (
              <Stack direction="row" gap={1}>
                <DocumentImage url="/assets/images/Image-placeholder.png" />
                <DocumentImage url="/assets/images/Image-placeholder.png" />
              </Stack>
            )}
          </Stack>
          <Stack>
            <Typography>Pan Card Photo</Typography>
            {panImages?.frontUrl ? (
              <Stack direction="row" gap={1}>
                <DocumentImage url={panImages?.frontUrl} />
              </Stack>
            ) : (
              <Stack direction="row" gap={1}>
                <DocumentImage url="/assets/images/Image-placeholder.png" />
              </Stack>
            )}
          </Stack>
          <Stack>
            <Typography>Verification Status</Typography>
            <Typography>
              {overallProfileStatus && getProfileStatus()}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="column" gap={2} mt={2}>
          <DriverTripsTable
            panelExpanded={panelExpanded}
            updateExpandedPanel={updateExpandedPanel}
            id={id}
          />
        </Stack>
      </Box>
    </Box>
  );
};
export default DriverResourcesDetailedView;

const StatusAndToggle = styled(Box)({
  display: "flex",
  gap: "10px",
});

const StatusTypography = styled(Typography)({
  fontSize: "14px",
  fontWeight: 700,
  width: "90px",
});
