import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { setTransporterAppState } from "../redux/slices/transporterAppStateSlice";

const Wrapper = (props)=>{
    
    const dispatch = useDispatch();
    useEffect(()=>{
        if(props.state){
            dispatch(setTransporterAppState(props.state));
        }
    },[dispatch,props])
    return <>{props.children}</>
}
export default Wrapper