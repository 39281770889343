import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import Timer from "./Timer";
import {
  getDriverRelatedTime,
  updateDriverRelatedTime,
} from "../../../../api/Admin/TimeRelated";
import useToast from "../../../../hooks/useToast";

const DriverRelated = () => {
  const [showDriverInfoPreTripTime, setShowDriverInfoPreTripTime] = useState({
    d: 0,
    hr: 0,
    min: 0,
    sec: 0,
  });

  const [activateGoToPickupButtonTime, setActivateGoToPickupButtonTime] =
    useState({
      hr: 0,
      min: 0,
    });

  const [isLoading, setIsLoading] = useState(false);

  const { handleOpenToast, ToastMessage } = useToast();

  // function to fetch driver related time configurations
  const fetchDriverRelatedConfigs = async () => {
    try {
      setIsLoading(true);
      const response = await getDriverRelatedTime();
      if (response.status === 200) {
        setShowDriverInfoPreTripTime(
          response?.data?.data?.preTripReminderNotificationTimeDriver
        );
        setActivateGoToPickupButtonTime(
          response?.data?.data?.goToPickupButtonActivationTimeDriver
        );
      }
      setIsLoading(false);
    } catch (error) {
      handleOpenToast(
        "Error while fetching driver related configurations",
        "error"
      );
      setIsLoading(false);
    }
  };

  // function for update Pre Trip Notification Time
  const updatePreTripReminderNotificationTime = async (timeData) => {
    try {
      const response = await updateDriverRelatedTime(
        {
          preTripReminderNotificationTimeDriver: timeData,
        },
        true,
        false
      );
      if (response.status === 200) {
        fetchDriverRelatedConfigs();
        handleOpenToast(
          "Pre trip reminder time updated successfully",
          "success"
        );
      }
    } catch (error) {
      handleOpenToast("Error while updating pre trip reminder time", "error");
    }
  };

  // function for update Go To Pickup Button Activation Time
  const updateGoToPickupButtonActivationTime = async (timeData) => {
    try {
      const response = await updateDriverRelatedTime(
        {
          goToPickupButtonActivationTimeDriver: timeData,
        },
        false,
        true
      );
      if (response.status === 200) {
        fetchDriverRelatedConfigs();
        handleOpenToast(
          "Go to pickup button activation time updated successfully",
          "success"
        );
      }
    } catch (error) {
      handleOpenToast(
        "Error while updating go to pickup button activation time",
        "error"
      );
    }
  };

  useEffect(() => {
    fetchDriverRelatedConfigs();
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "35vh",
          width: "100%",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box>
      <Timer
        title="Pre-trip Reminder Notification Time"
        day={showDriverInfoPreTripTime?.d}
        hour={showDriverInfoPreTripTime?.hr}
        minute={showDriverInfoPreTripTime?.min}
        second={showDriverInfoPreTripTime?.sec}
        onSave={updatePreTripReminderNotificationTime}
      />
      <Timer
        title="Activate 'Go to Pickup' Button Time"
        hour={activateGoToPickupButtonTime?.hr}
        minute={activateGoToPickupButtonTime?.min}
        onSave={updateGoToPickupButtonActivationTime}
      />
    </Box>
  );
};

export default DriverRelated;
