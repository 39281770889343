import { Route } from 'react-router';
import { transporterRoutes } from './transporterRoutes';
import { adminRoutes } from './adminRoutes';
import { customerRoutes } from './customerRoutes';
import Wrapper from '../layout/Wrapper';

/**
 * Recieves array of Routes and maps them to respective Route
 * @param {array} routes
 * @returns <Route to ="path" element={element}/>
 */
const generateRoute = (routes) => {
  return routes.map((route, index) => (
    <Route
      path={`${route.path}`}
      element={
        <Wrapper state={route.child ? undefined : route.state}>
          {route.element}
        </Wrapper>
      }
      key={index}
    >
      {route.child && generateRoute(route.child)}
    </Route>
  ));
};

export const transporter_Routes = generateRoute(transporterRoutes);
export const admin_Routes = generateRoute(adminRoutes);
export const customer_Routes = generateRoute(customerRoutes);
// same approach to be followed for Customer and Admin Routes later
// export const customer_Routes = generateRoute(customerRoutes);
