import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import axios from "axios";
import { BASE_URL } from "../../api/config";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { getTransporterProfile } from "../../api/Transporter/profile";
import { Typography } from "@mui/material";
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 14,
    left: "calc(-50% + 15px)",
    right: "calc(50% + 15px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "green",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "green",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

function QontoStepIcon(props) {
  const { completed } = props;

  return completed ? (
    <div
      style={{
        border: "3px solid green",
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src="/assets/icons/green-tick.svg" width="70%" alt="completed" />
    </div>
  ) : (
    <div
      style={{
        border: "3px solid #ADADAD",
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    ></div>
  );
}

export default function CustomMultipleCircleProgressBar({
  stakeholder = "transporter",
  steps,
}) {
  const [step, setStep] = useState(0);
  const getStepValue = () => {
    if (step === "REGISTERED") return 1;
    else if (step === "COMPLETED_KYC") return 2;
    else return 1;
  };
  useEffect(() => {
    if (stakeholder === "transporter") {
      getTransporterProfile().then((res) => {
        setStep(getStepValue(res.data.transporter.status));
      });
    } else setStep(1);
  }, []);
  return (
    <Box
      sx={{
        border: "1px solid #ADADAD",
        height: "12rem",
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: ".25rem",
      }}
    >
      {/* {step && ( */}
      <Stepper
        activeStep={step}
        alternativeLabel
        connector={<QontoConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={QontoStepIcon}
              sx={{ width: "110px" }}
            >
              <Typography
                textAlign="center"
                sx={{
                  margin: "auto",
                  fontWeight: 700,
                  fontSize: "1rem",
                  width: "90px",
                }}
              >
                {label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {/* )} */}
    </Box>
  );
}
