import { createSlice } from "@reduxjs/toolkit";

const customerProfileSlice = createSlice({
  name: "customerProfile",
  initialState: {
    id : '',
    profile : {}
  },
  reducers: {
    setCustomerId : (state, action)=>{
        state.id = action.payload;
    },
    setCustomerProfile: (state, action) => {
        state.profile = action.payload;
    },
  },
});

// export const { setCustomerProfile } = customerProfileSlice.actions;
export const { setCustomerId, setCustomerProfile } = customerProfileSlice.actions;
export default customerProfileSlice.reducer;
