import { customerApi } from "../config";

export const updateCustomerNotificationPreferences = async (
  customerProfileData
) => {
  return await customerApi.put(
    "/customer/profile/notificationStatus",
    customerProfileData
  );
};
