import { CheckFareAndBook } from "./CheckFareAndBook";
import { Footer } from "./Footer";
import Navbar from "../../component/Navbar";
import React, { useState } from "react";
import AboutUsSection from "./AboutUsSection";
import HowItWorks from "./HowItWorksSection";
import WhyChooseUs from "./WhyChooseUsSection";
import MaterialWeHandle from "./MaterialWeHandle";
import ContactUsSection from "./ContactUs";
import LoginDialog from "../../component/Common/LoginDialog";

const LandingPage = () => {
  // State to manage the login modal for customer and transporter portal
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  // State to manage the stakeholder
  const [stakeholder, setStakeholder] = useState(null);

  // this function closes login modal upon clicking cross mark
  const handleCloseLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  // Function to handle navigation based on sectionId
  const handleSectionNavigation = (sectionId) => {
    if (sectionId === "customer" || sectionId === "transporter") {
      sectionId === "customer"
        ? setStakeholder("customer")
        : setStakeholder("transporter");
      setIsLoginModalOpen(true);
      return;
    }
    const targetSection = document.getElementById(sectionId);
    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <div>
      <LoginDialog
        isLoginModalOpen={isLoginModalOpen}
        handleCloseLoginModal={handleCloseLoginModal}
        stakeholder={stakeholder}
      />
      <div id="home">
        <Navbar />
        <CheckFareAndBook />
      </div>
      <AboutUsSection />
      <HowItWorks />
      <WhyChooseUs />
      <MaterialWeHandle />
      <ContactUsSection handleSectionNavigation={handleSectionNavigation}/>
      <Footer handleSectionNavigation={handleSectionNavigation} />
    </div>
  );
};

export default LandingPage;
