import { client } from "../config";

export const getTransporterEnquiries = async (
  transporterId,
  page,
  enquiryStatus
) => {
  try {
    const response = await client.get(
      `transporter/enquiry/${transporterId}?page=${
        page + 1
      }&enquiryStatus=${enquiryStatus.toString()}`
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const getTransporterEnquiryDetailsById = async (
  transporterId,
  transporterEnquiryId
) => {
  try {
    const response = await client.get(
      `transporter/enquiry/${transporterId}/${transporterEnquiryId}`
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const updateEnquiryDetailsById = async (
  transporterId,
  transporterEnquiryId,
  options = {}
) => {
  try {
    const queryParams = new URLSearchParams();

    for (const key in options) {
      if (options.hasOwnProperty(key)) {
        queryParams.append(key, options[key]);
      }
    }
    const response = await client.patch(
      `transporter/enquiry/${transporterId}/${transporterEnquiryId}?${queryParams.toString()}`
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getBidDetailsByBidId = async (bidId) => {
  try {
    const response = await client.get(`transporter/bid/${bidId}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getVehicalDropdownInfo = async (transporterId) => {
  try {
    const response = await client.get(
      `transporter/enquiry/${transporterId}/transporter-vehicles`
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const getEstimate = async (
  transporterId,
  transporterEnquiryId,
  vehicalCategory
) => {
  try {
    const response = await client.get(
      `transporter/enquiry/${transporterId}/${transporterEnquiryId}/getEstimates?vehicleCategory=${vehicalCategory}`
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const createBid = async (bidData) => {
  try {
    const response = await client.post(`/transporter/bid`, bidData);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateBid = async (bidId, bidData) => {
  try {
    const response = await client.put(`/transporter/bid/${bidId}`, bidData);
    return response;
  } catch (error) {
    return error;
  }
};

export const getEnquiryDetailsById = async (transporterId, enquiryId) => {
  return await client.get(`/transporter/enquiry/${transporterId}/${enquiryId}`);
};
