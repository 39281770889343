import React from "react";
import styled from "@emotion/styled";

import { Box, Typography } from "@mui/material";

const StatusText = styled(Typography)(({ color }) => ({
  color: color || "#ffffff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "12px",
  fontWeight: "700",
  padding: "0px",
}));

const StyledBox = styled(Box)(({ color }) => ({
  width: "20px",
  height: "16px",
  backgroundColor: "#FF3F3F",
  borderRadius: "41px",
}));

export default function StatusCell({ status }) {
  const isQuote = status === "QUOTE";
  const isUnquoted = status === "UNQUOTED";

  const getQuoteStatus = () => {
    switch (status) {
      case "UNQUOTED":
        return "Unquoted";
      case "QUOTED":
        return "Quoted";
      case "YOU_QUOTED":
        return "You Quoted";
      case "BID_WON":
        return "Bid won";
      case "BID_LOST":
        return "Bid lost";
      case "ENQUIRY_REJECTED":
        return "Enquiry Rejected";
      case "ENQUIRY_IGNORED":
        return "Enquiry Ignored";
      case "ENQUIRY_CLOSED":
        return "Enquiry Closed";
      case "ENQUIRY_EXPIRED":
        return "Enquiry Expired";
      default:
        return "default";
    }
  };
  const getStatusColor = () => {
    switch (status) {
      case "UNQUOTED":
        return "#6D6D6D";
      case "QUOTED":
        return "#6D6D6D";
      case "YOU_QUOTED":
      case "BID_WON":
        return "#0C7F44";
      case "BID_LOST":
      case "ENQUIRY_REJECTED":
      case "ENQUIRY_IGNORED":
      case "ENQUIRY_CLOSED":
      case "ENQUIRY_EXPIRED":
        return "#B8B8B8";
      default:
        return "B8B8B8";
    }
  };

  return (
    <>
      <StatusText color={getStatusColor()}>{getQuoteStatus()}</StatusText>
      {isQuote && (
        <StyledBox>
          <StatusText>{1}</StatusText>
        </StyledBox>
      )}
    </>
  );
}
