import { useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import ZoomInRoundedIcon from '@mui/icons-material/ZoomInRounded';

const KycDocumentImageViewer = ({ imageUrl = "",imageSide="" }) => {

    const [isFullViewOpen, setIsFullViewOpen] = useState(false);
    
    const handleOpenModal = () => {
      setIsFullViewOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsFullViewOpen(false);
    };
  
    return (
      <Box>
        <Typography sx={{ color: "#333", fontSize: "1rem" }}>
          Front image
        </Typography>
        <Box
          sx={{
            position: "relative",
            border: "1px solid #eaeaea",
            height: "180px",
            width: "290px",
            overflow: "hidden", 
            "&:hover > #viewButton": {
              display: "flex", 
            },
            "&:hover img": {
              transform: "scale(1.1)",
            },
          }}
        >
          <img
            style={{
              transition: "transform 0.5s ease", 
            }}
            src={imageUrl}
            alt={imageSide}
            width="100%"
            height="100%"
          />
          <Button
            id="viewButton"
            sx={{
              position: "absolute",
              textTransform: "none",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "none",
              border: "1px solid #c6c6c6",
              background:"#EAEAEA",
              color: "#333",
              fontSize:'12px',
              fontWeight:700,
              "&:hover": {
                border: "1px solid #EAEAEA",
                background:"#c6c6c6"
              },
            }}
            onClick={handleOpenModal}
          >
           <ZoomInRoundedIcon sx={{fontSize:"30px",color:'#333'}}/>
          </Button>
        </Box>
        <Modal
          open={isFullViewOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <img
              src={imageUrl}
              alt={imageSide}
              style={{ maxWidth: "100%", maxHeight: "98vh" }}
            />
          </Box>
        </Modal>
      </Box>
    );
  };

export default KycDocumentImageViewer