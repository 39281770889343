import { customerApi } from "../config";

export const getCustomerKycDetails = async() =>{
    return await customerApi.get('customer/kyc')
}
export const submitCustomerAadhaarDocuments = async(document)=>{
    return await customerApi.post('customer/kyc/aadhar',document)
}
export const submitCustomerPANDocuments = async(document)=>{
    return await customerApi.post('customer/kyc/pan',document)
}
export const submitCustomerGST = async(gstNumber) =>{
    return await customerApi.post('customer/kyc/gst',{gstNumber})
}
export const submitCustomerBusinessLicense = async(licenceNumber) =>{
    return await customerApi.post('customer/kyc/business-license',{licenceNumber})
}