import React from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";

import TransporterTrips from "../../../component/Transporter/Trips";

const StyledBox = styled(Box)(() => ({
  height: "100%",
  width: "calc(100vw - 276px)",
}));

export default function Trips() {
  return (
    <StyledBox>
      <TransporterTrips />
    </StyledBox>
  );
}
