import React, { useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Fade,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import ErrorScreen from "../Common/ErrorScreen";
import LoadingTable from "../Common/LoadingTable";
import { StyledEnquiryTableCell } from "./StyledEnquiryTableCell";
import { formatDate, truncateString } from "../../utils/textFormat";
import {
  CUSTOMER_ENQUIRY_STATUS,
  LOADING_STATUS,
} from "../../constants/constant";
import { EnquiryStatus } from "./EnquiryStatus";
import { OfferReceivedCollapse } from "./OfferReceivedCollapse";
import { FindingPartnerCollapse } from "./FindingPartnerCollapse";
import { RejectCollapse } from "./RejectCollapse";

function CreateEnquiryTable({
  row,
  openRow,
  setOpenRow,
  index,
  handleOpenToast,
}) {
  const isOpen =
    openRow === index ||
    row?.enquiryStatus === CUSTOMER_ENQUIRY_STATUS.OFFER_RECEIVED;
  const handleRowClick = () => {
    setOpenRow((prevOpenRow) => {
      // If the row is already open, close it; otherwise, open it.
      return prevOpenRow === index ? null : index;
    });
  };
  const rowStyles = {
    backgroundColor:
      index === openRow ||
      row?.enquiryStatus === CUSTOMER_ENQUIRY_STATUS.OFFER_RECEIVED
        ? "#E9F2F4"
        : "#FFFFFF",
    borderBottom: 0,
    fontWeight:
      index === openRow ||
      row?.enquiryStatus === CUSTOMER_ENQUIRY_STATUS.OFFER_RECEIVED
        ? 700
        : "none",
  };

  return (
    <>
      <TableRow
        key={index + 1}
        onClick={(e) => {
          e.stopPropagation();
          handleRowClick();
        }}
        sx={{
          border:
            index === openRow ||
            row?.enquiryStatus === CUSTOMER_ENQUIRY_STATUS.OFFER_RECEIVED
              ? "1px solid #6D6D6D"
              : "none",
          cursor: "pointer",
        }}
      >
        <StyledEnquiryTableCell sx={rowStyles}>
          <Typography sx={{ paddingLeft: 2, fontSize: 14, fontWeight: 700 }}>
            {row?.route ? truncateString(row?.route, 30) : "--"}
          </Typography>
        </StyledEnquiryTableCell>
        <StyledEnquiryTableCell sx={{ ...rowStyles, textAlign: "center" }}>
          {formatDate(row?.createdAt, "DD-MM-YYYY")}
        </StyledEnquiryTableCell>
        <StyledEnquiryTableCell sx={{ ...rowStyles, textAlign: "center" }}>
          {row?.material?.name ? row?.material?.name : "--"}
        </StyledEnquiryTableCell>

        <StyledEnquiryTableCell sx={rowStyles}>
          {Number(row?.weight) ? `${Number(row?.weight) / 1000} ton` : `--`}
        </StyledEnquiryTableCell>
        <StyledEnquiryTableCell sx={{ ...rowStyles, textAlign: "center" }}>
          {row?.pickupDate ? formatDate(row?.pickupDate, "DD-MM-YYYY") : "--"}
        </StyledEnquiryTableCell>
        <StyledEnquiryTableCell sx={{ ...rowStyles, textAlign: "center" }}>
          {row?.pickupSlot ? row?.pickupSlot : "--"}
        </StyledEnquiryTableCell>

        <StyledEnquiryTableCell sx={rowStyles}>
          <EnquiryStatus
            enquiry={row}
            status={row?.enquiryStatus}
            style={{ textAlign: "center" }}
          />
        </StyledEnquiryTableCell>
      </TableRow>
      <TableRow sx={{ width: "100%" }}>
        <StyledEnquiryTableCell
          style={{
            padding: 0,
            border:
              index === openRow ||
              row?.enquiryStatus === CUSTOMER_ENQUIRY_STATUS.OFFER_RECEIVED
                ? "1px solid #6D6D6D"
                : "none",
          }}
          colSpan={7}
        >
          <Fade in={isOpen} timeout={1300}>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              {getCollapseComponent(row?.enquiryStatus, row, handleOpenToast)}
            </Collapse>
          </Fade>
        </StyledEnquiryTableCell>
      </TableRow>
    </>
  );
}
const getStatus = (status) => {
  switch (status) {
    case CUSTOMER_ENQUIRY_STATUS.OFFER_RECEIVED:
      return "Offer Received";
    case CUSTOMER_ENQUIRY_STATUS.FINDING_PARTNER:
      return "Finding Partner";
    case CUSTOMER_ENQUIRY_STATUS.CONVERTED_TO_TRIP:
      return "Converted To Trip";
    case CUSTOMER_ENQUIRY_STATUS.ENQUIRY_CLOSED:
      return "Enquiry Closed";
    case CUSTOMER_ENQUIRY_STATUS.ENQUIRY_EXPIRED:
      return "Enquiry Expired";
    case CUSTOMER_ENQUIRY_STATUS.ENQUIRY_REJECTED:
      return "Enquiry Rejected";
    case CUSTOMER_ENQUIRY_STATUS.OFFER_EXPIRED:
      return "Offer Expired";
    default:
      return "";
  }
};

const getCollapseComponent = (status, row, handleOpenToast) => {
  const rejectCollapseArray = [
    CUSTOMER_ENQUIRY_STATUS.ENQUIRY_EXPIRED,
    CUSTOMER_ENQUIRY_STATUS.OFFER_EXPIRED,
    CUSTOMER_ENQUIRY_STATUS.ENQUIRY_CLOSED,
    CUSTOMER_ENQUIRY_STATUS.ENQUIRY_REJECTED,
    CUSTOMER_ENQUIRY_STATUS.CONVERTED_TO_TRIP,
  ];
  if (status === CUSTOMER_ENQUIRY_STATUS.OFFER_RECEIVED)
    return (
      <OfferReceivedCollapse enquiry={row} handleOpenToast={handleOpenToast} />
    );
  else if (status === CUSTOMER_ENQUIRY_STATUS.FINDING_PARTNER)
    return <FindingPartnerCollapse enquiry={row} />;
  else if (rejectCollapseArray.includes(status))
    return <RejectCollapse status={status} enquiry={row} />;
};

export const EnquiryTable = ({
  enquiries,
  isEnquiryFetched,
  fetchEnquiriesRequests,
  navigate,
  statusFilter,
  handleOpenToast,
}) => {
  const [openRow, setOpenRow] = useState(null);

  const filteredRows =
    statusFilter.length > 0
      ? enquiries.filter((enquiry) =>
          statusFilter.includes(getStatus(enquiry?.enquiryStatus).toLowerCase())
        )
      : enquiries;

  if (isEnquiryFetched === LOADING_STATUS.LOADED) {
    return enquiries.length ? (
      <TableBody>
        {filteredRows.map((row, index) => (
          <CreateEnquiryTable
            key={index}
            row={row}
            openRow={openRow}
            setOpenRow={setOpenRow}
            index={index}
            handleOpenToast={handleOpenToast}
          />
        ))}
      </TableBody>
    ) : (
      <TableRow sx={{ background: "#EAEAEA" }}>
        <TableCell
          colSpan={12}
          align="center"
          sx={{ fontWeight: 600, color: "#333" }}
        >
          No Results Found.
        </TableCell>
      </TableRow>
    );
  } else if (isEnquiryFetched === LOADING_STATUS.FAILED)
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <ErrorScreen onRetry={fetchEnquiriesRequests} />;
      </Box>
    );
  return <LoadingTable columns={7} />;
};
