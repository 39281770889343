import React, { useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import moment from "moment";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { LOADING_STATUS } from "../../../../constants/constant";
import LoadingTable from "../../../Common/LoadingTable";
import ErrorScreen from "../../../Common/ErrorScreen";
import AdminStatusCell from "./AdminStatusCell";
import AdminStatusFilter from "./AdminStatusFilter";
import AdminExpiryTimerCell from "./AdminExpiryTimerCell";
import { formatNumber } from "../../../../utils/formatNumber";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFFFFF",
    color: "#333333",
    borderRight: "1px solid #c6c6c6",
    fontWeight: 700,
    borderBottom: "none",
    textAlign: "center",
  },
}));

const StyledTableBodyCell = styled(TableCell)(() => ({
  color: "#6D6D6D",
}));

const StyledBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  color: "#333333",
  fontWeight: 700,
}));

const StyledStatusCell = styled(TableCell)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "12px",
  borderBottom: "0px",
  color: "#333333",
}));

const StyledTableRow = styled(TableRow)(() => ({
  border: "2px solid #FFF",
  background: "#F6F6F6",
  "&:hover": {
    background: "#DDE7EF",
    td: {
      color: "#333333",
    },
  },
  td: {
    fontWeight: 700,
    fontSize: "12px",
    paddingBottom: "2px",
    paddingTop: "2px",
    borderBottom: "6px solid #fff",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function AdminTable(props) {
  const { setEnquiryStatus, enquiryStatus, enquiries, isEnquiriesListFetched } =
    props;
  const [anchorForStatus, setAnchorForStatus] = useState(null);
  const [statusFilter, setStatusFilter] = useState([]);

  const navigate = useNavigate();

  function renderViews() {
    switch (isEnquiriesListFetched) {
      case LOADING_STATUS.LOADING:
        return <LoadingTable columns={7} />;
      case LOADING_STATUS.LOADED:
        if (enquiries.length) {
          return (
            <>
              {enquiries.map((enquiry) => (
                <StyledTableRow
                  key={enquiry?.customerEnquiryId}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(
                      "/admin/app/enquiries/" + enquiry?.customerEnquiryId
                    );
                  }}
                >
                  <StyledTableBodyCell>
                    {moment(enquiry?.enquiredOn).format("DD-MM-YYYY hh:mm A")}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {enquiry?.customerName ? enquiry?.customerName : " - "}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {enquiry?.customerPhone ? enquiry?.customerPhone : " - "}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>{enquiry?.route}</StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {enquiry?.estimationDetails
                      ? `₹ ${formatNumber(
                          enquiry?.estimationDetails?.minFare
                        )} -
                    ${formatNumber(enquiry?.estimationDetails?.maxFare)}`
                      : "-"}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    <AdminExpiryTimerCell
                      status={enquiry?.status}
                      enquiryExpirationTimestamp={
                        (enquiry?.status === "UNQUOTED" &&
                          enquiry?.timers?.enquiryExpirationTimestamp) ||
                        (enquiry?.status === "QUOTED" &&
                          enquiry?.timers?.offerExpiryTimestamp)
                      }
                    />
                  </StyledTableBodyCell>
                  <StyledStatusCell>
                    <AdminStatusCell status={enquiry?.status} />
                  </StyledStatusCell>
                </StyledTableRow>
              ))}
            </>
          );
        } else {
          return (
            <TableRow sx={{ background: "#EAEAEA" }}>
              <TableCell
                colSpan={7}
                align="center"
                sx={{ fontWeight: 600, color: "#333" }}
              >
                No Results Found.
              </TableCell>
            </TableRow>
          );
        }
      case LOADING_STATUS.FAILED:
        return (
          <TableRow>
            <TableCell colSpan={7} sx={{ borderBottom: "none" }}>
              <ErrorScreen onRetry={props.adminEnquiries} />
            </TableCell>
          </TableRow>
        );
      default:
        return null;
    }
  }

  const statusFilters = [
    { label: "Un Quoted", value: "UNQUOTED" },
    { label: "Quoted", value: "QUOTED" },
    { label: "No Service", value: "NO_SERVICE" },
    { label: "Offer Received", value: "QUOTED" },
    { label: "Converted To-trip", value: "CONVERTED_TO_TRIP" },
    { label: "Enquiry Expired", value: "ENQUIRY_EXPIRED" },
    { label: "Offer Expired", value: "OFFER_EXPIRED" },
    { label: "Enquiry Cancelled", value: "ENQUIRY_CANCELLED" },
    { label: "Enquiry Rejected", value: "ENQUIRY_REJECTED" },
  ];

  const handleOpenStatusFilter = (event) => {
    setAnchorForStatus(event.target);
  };

  const handleCloseStatusFilter = () => {
    setAnchorForStatus(null);
  };

  return (
    <Box style={{ height: "100%" }}>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          height: "100%",
          boxShadow: "none",
          paddingBottom: "10px",
        }}
      >
        <Table size="small" aria-label="Enquiry data" stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell
                sx={{
                  width: "150px",
                  borderLeft: "1px solid #c6c6c6",
                }}
              >
                <Box>Enquired</Box>
                <Box>Date & Time</Box>
              </StyledTableCell>
              <StyledTableCell>Customer Name</StyledTableCell>
              <StyledTableCell>Mobile No.</StyledTableCell>
              <StyledTableCell>Route</StyledTableCell>
              <StyledTableCell>Estimate</StyledTableCell>
              <StyledTableCell>Expiry</StyledTableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #c6c6c6",
                  cursor: "pointer",
                  borderBottom: "none",
                }}
              >
                <StyledBox>
                  Status
                  <img
                    src="/assets/icons/expand_less.svg"
                    alt="expand less transporter status"
                    style={{ cursor: "pointer" }}
                    onClick={handleOpenStatusFilter}
                  />
                </StyledBox>
                <AdminStatusFilter
                  open={anchorForStatus}
                  onClose={handleCloseStatusFilter}
                  filters={statusFilters}
                  setFilters={setStatusFilter}
                  selectedFilters={statusFilter}
                  setEnquiryStatus={setEnquiryStatus}
                  enquiryStatus={enquiryStatus}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderViews()}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
