import { Outlet } from "react-router-dom";
import ComingSoon from "../component/ComingSoon";
import { Enquiries } from "../pages/Customer/Enquiries/Enquiries";
import { Trips } from "../pages/Customer/Bookings/Trips";
import CustomerDashboard from "../pages/Customer/Dashboard";
import CustomerProfile from "../pages/Customer/Profile";
import CustomerKYC from "../pages/Customer/KYC";
import CustomerBilling from "../pages/Customer/Billing";
import CustomerNotificationPreference from "../pages/Customer/Notification";
import Favorites from "../pages/Customer/Favorites/Favorites";
const iconStyle = { width: "24px", height: "24px" };

export const customerRoutes = [
  {
    path: "dashboard",
    element: <CustomerDashboard />,
    state: "dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: (
        <img
          src="/assets/sidebarIcons/dashboard.svg"
          style={iconStyle}
          alt="dashboard"
        />
      ),
    },
  },
  {
    path: "enquiries",
    element: <Enquiries />,
    state: "instalenquirieslation",
    sidebarProps: {
      displayText: "Enquiries",
      icon: (
        <img
          src="/assets/sidebarIcons/enquiry.svg"
          style={iconStyle}
          alt="enquiry"
        />
      ),
    },
  },
  {
    path: "trips",
    element: <Trips />,
    state: "trips",
    sidebarProps: {
      displayText: "Trips",
      icon: (
        <img
          src="/assets/sidebarIcons/trips.svg"
          style={iconStyle}
          alt="booking"
        />
      ),
    },
  },
  // {
  //   path: "reports",
  //   element: <ComingSoon />,
  //   state: "reports",
  //   sidebarProps: {
  //     displayText: "Reports",
  //     icon: (
  //       <img
  //         src="/assets/sidebarIcons/trips.svg"
  //         style={iconStyle}
  //         alt="reports"
  //       />
  //     ),
  //   },
  // },
  {
    path: "",
    element: (
      <>
        <Outlet />
      </>
    ),
    state: "accounts",
    sidebarProps: {
      displayText: "Account",
      icon: (
        <img
          src="/assets/sidebarIcons/account.svg"
          style={iconStyle}
          alt="accounts"
        />
      ),
    },
    child: [
      {
        path: "accounts/profile",
        element: <CustomerProfile />,
        state: "accounts.profile",
        sidebarProps: {
          displayText: "Profile",
        },
      },

      {
        path: "accounts/kyc",
        element: <CustomerKYC />,
        state: "accounts.kyc",
        sidebarProps: {
          displayText: "KYC",
        },
      },
      {
        path: "accounts/notifications",
        element: <CustomerNotificationPreference />,
        state: "accounts.notifications",
        sidebarProps: {
          displayText: "Notifications",
        },
      },

      {
        path: "accounts/billings",
        element: <CustomerBilling />,
        state: "accounts.billings",
        sidebarProps: {
          displayText: "Billing",
        },
      },
    ],
  },
  {
    path: "favorites",
    element: <Favorites />,
    state: "favorites",
  },
];
