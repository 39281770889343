import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import { refundPayment } from "../../../api/Admin/trips";

const RefundModal = ({
  isModalOpen,
  handleCloseModal,
  handleOpenToast,
  amountReceived,
  penaltyCharge,
  paymentId,
  customerId,
  tripId,
  customerEnquiryId,
  getTripById,
}) => {
  const [error, setError] = useState("");
  const [refundAmount, setRefundAmount] = useState(
    amountReceived - penaltyCharge
  );
  const [isRefunding, setIsRefunding] = useState(false);

  const calculatePenaltyCharge = () => {
    if (amountReceived - refundAmount < 0) {
      return 0;
    }
    return (amountReceived - refundAmount).toFixed(2);
  };

  const handleRefund = async () => {
    setIsRefunding(true);
    if (refundAmount <= 0) {
      setError("Refund amount should be greater than 0");
      return;
    }
    if (amountReceived < refundAmount) {
      setError("Refund amount should be less than amount received");
      return;
    }

    try {
      const response = await refundPayment(
        paymentId,
        refundAmount,
        amountReceived - penaltyCharge,
        customerId,
        penaltyCharge,
        calculatePenaltyCharge(),
        tripId,
        customerEnquiryId
      );
      if (response.status === 200) {
        handleOpenToast("Refund Successful", "success");
        getTripById();
        handleCloseModal();
      } else {
        handleOpenToast("Failed to refund", "error");
      }
    } catch (error) {
      handleOpenToast("Failed to refund", "error");
    }
    setIsRefunding(false);
  };

  return (
    <Dialog open={isModalOpen} onClose={handleCloseModal} Width="625px">
      <DialogTitle sx={{ color: "#333" }}>
        <Typography fontWeight={700} fontSize={24}>
          Refund to Customer
        </Typography>
      </DialogTitle>
      <CloseIcon
        fontSize="medium"
        onClick={handleCloseModal}
        sx={{
          position: "absolute",
          right: "10px",
          top: "14px",
          cursor: "pointer",
        }}
      />
      <DialogContent>
        <Box
          sx={{
            gap: "40px",
            width: "500px",
          }}
        >
          <Box sx={{ width: "100%", marginBottom: "20px" }}>
            <Typography
              sx={{
                color: "#333",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <Box sx={{ background: "#DDEFDD", padding: "16px 24px" }}>
                <Box display="flex" mt={1.5}>
                  <StyledPaymentSectionTextPrimary>
                    Amount Received
                  </StyledPaymentSectionTextPrimary>
                  <StyledPaymentSectionTextSecondary>
                    ₹{amountReceived}
                  </StyledPaymentSectionTextSecondary>
                </Box>
                <Box display="flex" mt={1.5}>
                  <StyledPaymentSectionTextPrimary>
                    Penalty Charge
                  </StyledPaymentSectionTextPrimary>
                  <StyledPaymentSectionTextSecondary>
                    {calculatePenaltyCharge()}
                  </StyledPaymentSectionTextSecondary>
                </Box>
                <Box sx={{ width: "50%" }}>
                  <img
                    src="/assets/icons/horizontalVector.svg"
                    alt="distance arrow"
                  />
                </Box>
                <Box display="flex" mt={1.5}>
                  <StyledPaymentSectionTextPrimary>
                    Refund Amount
                  </StyledPaymentSectionTextPrimary>
                  <StyledPaymentSectionTextSecondary>
                    ₹{refundAmount}
                  </StyledPaymentSectionTextSecondary>
                </Box>
              </Box>
            </Typography>
          </Box>

          <Box sx={{ width: "100%" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <InputLabel
                sx={{
                  color: "#333",
                  fontSize: "1.125rem",
                  fontWeight: 700,
                }}
                htmlFor="weight.min"
              >
                Refund Amount
              </InputLabel>
              <Typography
                sx={{
                  color: "#333",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                (You can edit the refund amount)
              </Typography>
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              width="100%"
              value={refundAmount}
              error={!!error}
              helperText={error}
              onChange={(e) => setRefundAmount(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <StyledButton
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleRefund}
            disabled={isRefunding}
          >
            Refund
          </StyledButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RefundModal;

const StyledButton = styled(Button)(() => ({
  float: "right",
  padding: "10px 30px",
  fontSize: "1.2rem",
  margin: "20px 0",
}));

const StyledPaymentSectionTextPrimary = styled(Typography)`
  font-size: 18px;
  color: #333333;
  width: 200px;
  padding-left: 4px;
`;

const StyledPaymentSectionTextSecondary = styled(Typography)`
  font-size: 18px;
  color: #333333;
  font-weight: 700;
  flex-grow: 2;
  text-align: left;
`;
