import { Box, Divider, List, ListItem, Typography } from "@mui/material";
const colors = {
  main: "#333333",
};

const TermsAndConditions = () => {
  return (
    <Box p={5} textAlign="center" maxWidth="lg" m="auto">
      <Typography
        component="h1"
        sx={{
          fontSize: "24px",
          textDecoration: "underline",
          fontWeight: 700,
          color: colors.main,
        }}
      >
        Terms and Conditions of LoadEazy
      </Typography>
      <Typography component="p" align="left" mt={3} color={colors.main}>
        Greetings from LoadEazy. The following terms and conditions of use,
        along with our privacy policy, govern LoadEazy's (Owned by Juggernaut
        Ventures) relationship with you regarding this app/website and the
        Services (as detailed below). If you continue to browse and use our
        app/website, you agree to comply with and be bound by them.
      </Typography>
      <Typography component="p" align="left" mt={3} color={colors.main}>
        If any terms and conditions contained herein conflict with any other
        document executed by the parties hereto, the terms and conditions
        contained herein shall take precedence.
      </Typography>
      <Typography component="p" align="left" mt={3} color={colors.main}>
        LoadEazy reserves the right to modify these terms and conditions at any
        time by updating this page. The current version of these terms and
        conditions, as modified, will take effect on December 1, 2023. Users are
        encouraged to review this page periodically for any changes.
      </Typography>
      {/* Definitions */}
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>DEFINITIONS</Typography>
        <Typography mt={3} color={colors.main}>
          The terms and conditions listed below, which control this contract of
          carriage between you and us, are defined as follows:
        </Typography>
        <Typography mt={3} color={colors.main}>
          The terms "we", "us", "our", "Carrier", "LoadEazy", and "Company"
          refer to Juggernaut Ventures, the company that owns LoadEazy, as well
          as its staff and service providers who carry or agree to carry the
          consignment under this agreement or handle any other related services
          on its behalf.
        </Typography>
        <Typography mt={3} color={colors.main}>
          "You," "your," and "consignor" refer to the sender, consignor, or
          consignee of the shipment, the holder of this consignment note, the
          recipient and owner of the shipment's contents, or any other party
          with a relevant legal interest, as applicable.
        </Typography>
        <Typography mt={3} color={colors.main}>
          "Services" refers to the services that LoadEazy offers and that make
          possible the following:
        </Typography>
        <Typography component="div" mt={1} pl={5}>
          <ul
            style={{
              listStyle: "circle",
              paddingTop: "10px",
              color: colors.main,
            }}
          >
            <li>
              the services of vehicle transportation of goods by customers,
              either on a point-to-point basis or on a time-and-usage basis both
              inside and outside of cities, including inter-city transportation
              throughout India; the services of customer labor hiring for the
              purpose of loading and unloading the shipment; and any additional
              services that LoadEazy may occasionally add.
            </li>
            <li>
              "Carriage" refers to and encompasses all of the tasks and services
              that we perform in relation to the Consignment.
            </li>
            <li>
              "Consignment" refers to any package, parcel, sachet, or freight
              that is provided to us and authorized for transportation in
              accordance with our Consignment Note.
            </li>
            <li>
              "Dangerous Goods" refers to products that are deemed dangerous by
              the ICAO T.I., IATA DGR, IMDG-Code, ADR, or other national
              transportation laws.
            </li>
            <li>
              "Delivery" refers to either handing over the package to the
              consignee or notifying them when it will arrive.
            </li>
            <li>
              "Prohibited Items" are any products or materials that the
              applicable law forbids the carriage of.
            </li>
            <li>
              "Receiver" or "Consignee" refers to the consignment's recipient,
              addressee, or consignee.
            </li>
            <li>
              The term "Applicable Law" refers to all laws, statutes,
              ordinances, rules, guidelines, policies, bye-laws, notifications,
              directions, directives, and orders, as well as other governmental
              restrictions. It also includes any decision made by, or
              interpretation of, or determination by, any state, municipality,
              court, tribunal, government, ministry, department, commission,
              arbitrator or board, or any other body which may have the
              authority to make laws in the Republic of India.
            </li>
          </ul>
        </Typography>
      </Box>
      {/* Users Eligibility */}
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>USER(S) ELIGIBILITY</Typography>
        <Typography mt={3} color={colors.main}>
          Any person or legal business entity operating in India or another
          nation that utilizes and is authorized to use LoadEazy's services is
          referred to as the "User(s)." It is hereby made clear that only people
          or businesses that are able to enter into legally binding contracts
          under the applicable laws may use our services. An individual must be
          at least eighteen (18) years old in order to be a User(s) and be
          eligible to use our Services. LoadEazy warns user(s) that they must
          adhere to and obey all applicable laws when using the website. When
          using this website, LoadEazy assumes no responsibility for any actions
          or behavior you take. In its sole discretion, LoadEazy has the right
          to reject service to anyone at any time.
        </Typography>
      </Box>
      {/* Users Agreement */}
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>USER(S) AGREEMENT</Typography>
        <Typography mt={3} color={colors.main}>
          This agreement is applicable to any visitor, registered user, paid
          user, or anyone else who uses the website for any reason. It also
          extends to any legal body that you might represent with real or
          apparent authority. This website may only be used by the user(s) for
          internal or personal use.
        </Typography>
        <Typography mt={3} color={colors.main}>
          All LoadEazy Services are covered by this agreement, along with any
          additional terms and conditions that might be relevant to the
          particular service that the user(s) uses or accesses. The terms and
          conditions that apply to the specific services will take precedence
          over any other provisions in the event of a conflict or inconsistency
          between the terms and conditions mentioned here and the specific
          service.
        </Typography>
      </Box>
      {/* Amendments */}
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>AMENDMENT TO USER(S) AGREEMENT</Typography>
        <Typography mt={3} color={colors.main}>
          This agreement may be updated, modified, amended, or changed at any
          time by LoadEazy with or without prior notice to the user(s). The
          updated terms and conditions of use will take effect immediately upon
          posting. You have to stop using the service if you don't follow the
          changes. You will be deemed to have accepted the amended Agreement and
          the updated terms by continuing to use the Services. Moreover, User(s)
          agree to be bound by any modifications made from time to time to any
          policy or agreement mentioned in these Terms of Service.
        </Typography>
      </Box>
      {/* INTELLECTUAL PROPERTY RIGHTS */}
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>INTELLECTUAL PROPERTY RIGHTS</Typography>
        <Typography mt={3} color={colors.main}>
          All rights to the website and its contents belong to LoadEazy, or to a
          legitimate licensee. The term "website content" refers to the layout,
          text, graphics, sound, and other elements of a website. Trade secrets
          and intellectual property rights covered by international copyright
          and other laws are included in the content of this website. Whatever
          the situation, LoadEazy, its affiliates, or the licensors of LoadEazy
          content will retain all title, ownership, and intellectual property
          rights in the website and its content.
        </Typography>
        <Typography mt={3} color={colors.main}>
          All rights reserved, whether or not otherwise claimed by LoadEazy.com
          or under this agreement. The reader assumes all responsibility for the
          use of the information on this website, which is only meant to offer
          general information for their own use. The information and
          advertisements (collectively, the "content") on, distributed through,
          linked, downloaded, or accessed from any of the Services on this
          website are not represented or endorsed by LoadEazy, nor is the
          quality of any goods, data, or other materials displayed or acquired
          by you as a result of an advertisement or any other information or
          offer in or in connection with the Service. Regarding any mistakes or
          omissions, as well as the outcomes of using this information, we take
          no liability. All content on this website is provided "AS IS," meaning
          that there is no guarantee regarding its timeliness, completeness,
          accuracy, or usefulness, nor any implied or express warranties of any
          kind, including but not limited to merchantability, fitness for a
          particular purpose, and performance. Nothing in this article is
          intended to replace the user(s)' independent research and sound
          technical and business judgment in any way. With regard to the
          Service, LoadEazy shall never be liable for any kind of damages,
          whether direct, indirect, incidental, punitive, or consequential. The
          user(s) of this site agree to assume all liability for any reliance on
          any content.
        </Typography>
        <Typography mt={3} color={colors.main}>
          Any part of the Service or the app/website may be improved, or any
          error or omission may be corrected, at LoadEazy's sole discretion and
          without any obligation.
        </Typography>
      </Box>
      {/* TRADEMARKS AND COPYRIGHTS */}
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>TRADEMARKS AND COPYRIGHTS</Typography>
        <Typography mt={3} color={colors.main}>
          All associated icons and logos are copyright, trademark, and other
          proprietary rights laws protected, and are registered trademarks,
          trademarks, or service marks of LoadEazy in different jurisdictions.
          It is strictly forbidden to use, modify, copy, or publish these marks
          without authorization.
        </Typography>
        <Typography mt={3} color={colors.main}>
          With the exception of third-party content and links to third-party
          websites on our app or website, all content on this website is
          copyrighted by LoadEazy.
        </Typography>
        <Typography mt={3} color={colors.main}>
          Any part of the Service or the app/website may be improved, or any
          error or omission may be corrected, at LoadEazy's sole discretion and
          without any obligation.
        </Typography>
        <Typography mt={3} color={colors.main}>
          All associated icons and logos are copyright, trademark, and other
          proprietary rights laws protected, and are registered trademarks,
          trademarks, or service marks of LoadEazy in different jurisdictions.
          It is strictly forbidden to use, modify, copy, or publish these marks
          without authorization.
        </Typography>
        <Typography mt={3} color={colors.main}>
          With the exception of third-party content and links to third-party
          websites on our app or website, all content on this website is
          copyrighted by LoadEazy.
        </Typography>
        <Typography mt={3} color={colors.main}>
          It is not permitted to use robots, spiders, automated equipment, or
          manual processes to gather or assemble, directly or indirectly, any
          collection, compilation, database, or directory by means of the
          systematic retrieval of content from LoadEazy without written
          permission from LoadEazy.
        </Typography>
        <Typography mt={3} color={colors.main}>
          If a user violates any third party's rights while using the LoadEazy
          service, or if LoadEazy feels that the user's behavior is detrimental
          to the interests of LoadEazy, its affiliates, or other user(s), or for
          any other reason at LoadEazy's sole discretion, with or without cause,
          LoadEazy reserves the right to terminate the user's account. Without
          impairing LoadEazy's rights, you will be responsible for compensating
          LoadEazy for any damages or costs incurred by LoadEazy as a result of
          any infringement of LoadEazy's intellectual property.
        </Typography>
      </Box>
      {/* LINKS TO THIRD PARTY SITES */}
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>LINKS TO THIRD PARTY SITES</Typography>
        <Typography mt={3} color={colors.main}>
          The website offers links to other websites as a convenience to the
          user(s); LoadEazy has no control over the resources and content
          available on these websites.
        </Typography>
        <Typography mt={3} color={colors.main}>
          Through hyperlinks (such as word links, banners, channels, or other
          symbols) to the websites of those Third Parties, LoadEazy may provide
          user(s) with access to content, goods, or services provided by Third
          Parties. It is advised that you familiarize yourself with the terms
          and conditions governing your use of these websites by reading their
          privacy policies and/or terms and conditions before using them.
        </Typography>
        <Typography mt={3} color={colors.main}>
          The website offers links to other websites as a convenience to the
          user(s); LoadEazy has no control over the resources and content
          available on these websites.
        </Typography>
        <Typography mt={3} color={colors.main}>
          According to LoadEazy, the user(s) understand that LoadEazy has no
          control over and does not monitor third-party websites. As a result,
          LoadEazy disclaims all liability and responsibility for any
          third-party websites, as well as any content, goods, or services that
          may be made available on them. It is the responsibility of the User(s)
          to read and follow LoadEazy's Privacy Policy whenever they interact
          with LoadEazy regarding any information or data.
        </Typography>
      </Box>
      {/* TERMINATION */}
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>TERMINATION</Typography>
        <Typography mt={3} color={colors.main}>
          The majority of the content and a few features on the website are
          provided without charge to the user(s). Nevertheless, LoadEazy retains
          the right, with or without prior notice, to restrict access to any
          portion of the website or to specific features. In order to safeguard
          the interests of LoadEazy and/or other website user(s), LoadEazy
          additionally maintains the absolute right to refuse access to any or
          all of its Services to any user at any time without prior notification
          or explanation. LoadEazy maintains the right, without prior notice, to
          modify any feature or add new features, as well as to restrict, deny,
          or grant access to the website and its features differently to
          different user(s).
        </Typography>
      </Box>
      {/* TERMS & CONDITIONS FOR USE OF OUR SERVICE */}
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>
          TERMS & CONDITIONS FOR USE OF OUR SERVICE
        </Typography>
        <Typography mt={3} color={colors.main}>
          Customers who use the company's vehicle rental services are subject to
          the following terms and conditions: The fare (as agreed), parking
          fees, any additional night surcharge (if applicable), and any other
          fees or levies now or in the future imposed by the applicable law or
          necessary to be paid for using LoadEazy's services are all payable by
          the customer. The user acknowledges and agrees that using the
          company's services is entirely at their risk. The user also agrees
          that the company makes no express or implied warranties or
          representations of any kind. When using the service, the client agrees
          to refrain from engaging in any of the following activities:
        </Typography>
        <Typography component="div" mt={1} pl={5}>
          <ul
            style={{
              listStyle: "circle",
              paddingTop: "10px",
              color: colors.main,
            }}
          >
            <li>
              Containing dirt or harm to the vehicle's body and/or interiors.
            </li>
            <li>
              Abusing, contaminating, or causing harm to any of the technical or
              non-technical devices in the vehicle.
            </li>
            <li>
              Requesting that the driver disobey any traffic, RTO, city police,
              or government regulations for any reason. The driver is free to
              turn down a customer's request of this kind. Additionally, the
              driver is free to decline such a pick-up.
            </li>
            <li>
              Pressuring the driver to load more cargo onto the truck than is
              permitted.
            </li>
          </ul>
        </Typography>
        <Typography mt={3} color={colors.main}>
          In connection with the use or proposed use of the Services by the
          Customer, the Customer shall hold Company harmless from and against
          any and all liabilities, losses, charges, and expenses (including
          legal fees and costs on a full indemnity basis), as well as any and
          all claims, demands, actions, and proceedings that the Company may
          sustain or incur directly or indirectly. The Customer shall also pay
          any such amounts upon demand from the Company.
        </Typography>
        <Typography mt={3} color={colors.main}>
          By using the mobile device to book a vehicle, the customer gives the
          company permission to use the location-based data that any of the
          telecommunications companies have provided. The location-based data
          will only be utilized to increase the likelihood that a vehicle will
          be found for the Customer.
        </Typography>
        <Typography mt={3} color={colors.main}>
          The information that the company has about a customer may be disclosed
          in any manner that the company, in its sole discretion, thinks
          appropriate or if it believes it is in its best interests to do so to
          any government body, other companies in its group, or any government
          body as required by applicable law or by directive or request from any
          government body.
        </Typography>
        <Typography mt={3} color={colors.main}>
          The Company reserves the right to cancel the customer's vehicle
          reservation at any time and for any reason. The user(s) agrees to hold
          LoadEazy harmless for any costs incurred in connection with the
          booking.
        </Typography>
        <Typography mt={3} color={colors.main}>
          If something is lost in transit, the company will make a "best-effort"
          to find it, but it will not be held accountable for loss, damage,
          theft, or misappropriation of the item(s). In order to provide
          Services, LoadEazy aggregates the vehicles. User(s) shall not be
          entitled to withhold payment from LoadEazy in the event that any item
          is lost. Furthermore, according to the Applicable Law, LoadEazy
          retains the right to exercise a specific lien over the consignment
          until full payment for the Services is made to LoadEazy. This is
          especially true if any payments from the User to LoadEazy are not made
          within the time frame specified by LoadEazy in its corresponding
          invoice or statement. Furthermore, User(s) shall indemnify LoadEazy
          for any loss, damage, or costs that it may incur as a result of having
          custody of the consignment during this time.
        </Typography>
        <Typography mt={3} color={colors.main}>
          Within twenty-four hours of utilizing the company's vehicles or
          services, the customer must notify the company in writing of any
          complaints regarding the services or the use of the vehicles.
        </Typography>
        <Typography mt={3} color={colors.main}>
          Regarding the behavior of the drivers of the vehicles, the Company
          shall not be held responsible. Nonetheless, the Company requests that
          you inform it of any grievances you may have regarding the driver you
          may have employed through the Company's services.
        </Typography>
        <Typography mt={3} color={colors.main}>
          The terms and conditions on this website, www.LoadEazy.com, are
          subject to modification at any time by the Company, which reserves the
          right to add, vary, or amend any or all of them. The Customer agrees
          to be bound by any such additions, variations, or amendments once they
          are incorporated into the terms and conditions on this website.
        </Typography>
        <Typography mt={3} color={colors.main}>
          For quality and training purposes, the company records every call made
          to its call center. If you post a question on our app or website about
          our services, fees, or terms of service, you hereby specifically
          consent to receiving our answers to your question and any related
          information about our services, whether they come in the form of phone
          calls or emails. Any marketing and/or commercial information is,
          without limitation, considered related information for the purpose of
          dispelling any doubts. You understand, agree, and acknowledge that, in
          accordance with the Telecom Unsolicited Commercial Communications
          Regulations, 2007 or any other Applicable Law, our telephone numbers
          shall not be eligible for registration under the "National Do Not Call
          Register" or the "Private Do Not Call Register" due to the disclosure
          of such information. Additionally, you agree that such information
          shall in no circumstances qualify as unsolicited commercial
          communication.
        </Typography>
        <Typography mt={3} color={colors.main}>
          Cancellation Policy: There will be a cancellation fee for any
          cancellations made more than Twenty minutes after the driver is
          assigned. If you cancel four reservations within a day of the driver
          being assigned, your account will be temporarily suspended for a full
          day. The amounts and duration could change from time to time.
        </Typography>
        <Typography mt={3} color={colors.main}>
          Should there be a toll on your journey, you will be responsible for
          paying the return toll fare.
        </Typography>
      </Box>
      {/* CONFIDENTIALITY */}
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>CONFIDENTIALITY</Typography>
        <Typography mt={3} color={colors.main}>
          User(s) shall not disclose any information received under the contract
          of service with LoadEazy to any third party. Access to any information
          pertaining to LoadEazy's business will be kept private to the extent
          that it could have a negative effect on the company. User(s) are
          responsible for indemnifying LoadEazy for any damages to its
          reputation or business that result from their actions.
        </Typography>
      </Box>
      {/* DISCLAIMER */}
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>DISCLAIMER</Typography>
        <Typography mt={3} color={colors.main}>
          For security purposes only, mobile technology is used to continuously
          track all vehicles registered with the company. It is explicitly
          stated here that neither the Company nor any of its employees directly
          or indirectly own all of the vehicles or the drivers who operate them.
          The Company disclaims all liability regarding the drivers and the
          vehicles that are provided by third parties. The company is permitted
          to use the contact details provided by customers for its own marketing
          initiatives. The mobile numbers registered with the Company may
          receive updates via SMS on a regular basis.
        </Typography>
      </Box>
      {/* INSURANCE INFO */}
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>INSURANCE INFO</Typography>
        <Typography mt={3} color={colors.main}>
          Appropriate insurance if applicable shall be bound by the Policy
          Document
        </Typography>
      </Box>
      {/* LOADEAZY WALLET INFO */}
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>INSURANCE INFO</Typography>
        <Typography mt={3} color={colors.main}>
          Wallet info shall be updated as soon as the feature is activated.
        </Typography>
      </Box>
      {/* YOUR ACCEPTANCE OF OUR TERMS AND CONDITIONS */}
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>
          YOUR ACCEPTANCE OF OUR TERMS AND CONDITIONS
        </Typography>
        <Typography mt={3} color={colors.main}>
          Regardless of whether you have signed the front of our consignment
          note or not, by giving us your consignment, you accept our terms and
          conditions as stated in the consignment note, the contract of
          carriage, and/or the contract for the performance of other services on
          behalf of yourself and/or anyone else who has an interest in the
          consignment or the performance of other Services. In addition to our
          employees, directors, and agents, our terms and conditions also apply
          to anyone we use or subcontract to pick up, transport, deliver, or
          carry out other services. Any modification to these terms and
          conditions must be approved in writing by one of our authorized
          officers only. We will not be obligated to follow any written or
          verbal instructions that you give us with the shipment that go against
          our terms and conditions.
        </Typography>
      </Box>
      {/* YOUR OBLIGATIONS */}
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>
          YOUR ACCEPTANCE OF OUR TERMS AND CONDITIONS
        </Typography>
        <Typography mt={3} color={colors.main}>
          You promise, warrant, and ensure the following to us:
        </Typography>
        <Typography component="div" mt={1} pl={5}>
          <ul
            style={{
              listStyle: "circle",
              paddingTop: "10px",
              color: colors.main,
            }}
          >
            <li>
              The contents of the shipment (weight and quantity of items
              included, for example) have been accurately described on our
              consignment note; the consignment note is complete in every way;
              and the documents necessary for the shipment's transportation,
              such as the invoice and permits, are enclosed with the consignment
              note.
            </li>
            <li>
              That neither you nor the consignee are a person or organization
              with whom we or you may not lawfully trade under Applicable Law,
              and that the contents of the shipment are not Prohibited Items
              and/or are not restricted by the applicable regulations. o That
              you will provide to us any necessary declaration of dangerous
              goods in accordance with Applicable Law, correctly and accurately.
            </li>
            <li>
              That all of the information and documentation you provide about
              the consignment will be true and accurate. You also understand
              that if you provide false or fraudulent information about the
              consignment or any of its contents, you run the risk of facing
              civil lawsuits and/or criminal prosecutions, with possible
              consequences like forfeiture and sale. You undertake to defend and
              hold us harmless against any claims based on the information you
              provided, made against us or our agents.
            </li>
            <li>
              It is expressly agreed that we will be conclusively presumed to
              have delivered the goods in accordance with this contract, and we
              are authorized to deliver the goods at the address specified on
              the Consignment Note. We will use emails and SMS to confirm
              delivery; if we don't hear back from you the same day, it will be
              assumed that the delivery was successful.
            </li>
            <li>
              You have accurately stated the weight of the shipment, and you
              will supply any specialized tools we might require to load or
              unload the shipment into or out of our vehicles.
            </li>
            <li>
              When you have asked us to charge the receiver or a third party and
              the receiver or third party does not pay us you will promptly
              settle our invoice together with an administration fee in full
              within 7 days of us sending you the invoice
            </li>
            <li>Applicable Law has been complied with by you.</li>
          </ul>
        </Typography>
        <Typography component="div" mt={3}>
          <ul
            style={{
              listStyle: "disc",
              paddingTop: "10px",
              color: colors.main,
            }}
          >
            <li>
              You recognize, accept, and agree that the Services are not
              appropriate for the transportation of valuables, such as cash,
              precious stones, jewelry, gold, silver, diamonds, expensive luxury
              items, or other valuables. When you hand over or load a vehicle
              containing valuables for transportation, you assume all risk of
              loss, damage, theft, or misappropriation of the consignment. For
              the previously stated reasons, and without affecting the other
              provisions, we reserve the right to explicitly and specifically
              disclaim any liability and/or responsibility arising from any
              loss, damage, theft, misappropriation, or any of the consignment's
              contents, as well as the right to seek indemnification from you in
              cases where your actions have caused us to lose goodwill or
              reputation.
            </li>
            <li>
              You comprehend, concur, and acknowledge that before delivering the
              consignment for transportation, you must obtain an insurance
              policy for it from a general insurance company that operates in
              India if the consignment or any of its contents are valued at more
              than INR 100,000/- (Indian Rupees One Lakh only). The consignment
              will be transported at your risk and not ours if there is
              insufficient insurance to cover loss of goods in transit, if the
              consignment details or value are incorrectly or intentionally
              omitted, and whether the consignment is insured or not. We hereby
              expressly and specifically disclaim any liability and/or
              responsibility arising from any damage, loss, theft,
              misappropriation, or any insurable loss to / of the consignment or
              any contents of the consignment. You additionally consent to
              reimburse us for any damages we may have incurred as a result of
              your actions violating our terms of service, including loss of
              goodwill and reputation.
            </li>
            <li>
              Even if we unintentionally accept a consignment that violates any
              of your obligations, you agree to indemnify and hold us harmless
              from any liabilities we may incur or any costs, damages, or
              expenses, including legal costs, we incur either to you or to
              anyone else arising out of your breach of any of these warranties,
              representations, and guarantees.
            </li>
            <li>
              You attest that all of the statements and details you supply
              regarding the consignment's transportation will be accurate and
              truthful. You understand that you run the risk of facing civil
              lawsuits and/or criminal charges, with the forfeiture and sale of
              your consignment as punishment, if you make false or fraudulent
              statements about the consignment or any of its contents. If we
              choose to help you with the necessary paperwork for customs and
              other formalities, that help will be provided at your own risk.
              You also agree to pay any administration fees we may impose for
              providing the Services detailed in this condition, and to
              indemnify and hold us harmless from any claims made against us
              resulting from the information you provide to us and any expenses
              we will incur regarding this.
            </li>
          </ul>
        </Typography>
        <Typography component="div" ml={6}>
          <ul
            style={{
              listStyle: "circle",
              paddingTop: "10px",
              color: colors.main,
            }}
          >
            <li>
              To the fullest extent permitted by applicable law, the customer
              agrees and acknowledges that using the services provided by the
              company is at their own risk and that the company disclaims all
              representations and warranties of any kind, whether express or
              implied, regarding condition, suitability, quality,
              merchantability, and fitness for any particular purpose.
              Notwithstanding the foregoing, the Company disclaims all liability
              and makes no representations regarding: o The Services fulfilling
              the needs of the customer.
            </li>
            <li>
              The Services will be timely, secure, error-free, and
              uninterrupted.
            </li>
            <li>
              Any responsibility or liability for any loss or damage, whether
              direct or indirect, that the customer may incur from using the
              services provided by the company or from the company's failure to
              provide the services to the customer for any reason at all,
              including but not limited to the customer's failure to follow the
              recorded voice instructions for the services, malfunction, partial
              or complete failure of any network terminal, data processing
              system, computer tele-transmission, or telecommunications system,
              or other circumstances that may arise that are outside the
              company's or any individual or organization involved in the
              aforementioned systems. Any responsibility for any damages,
              including but not limited to direct, indirect, incidental,
              punitive, and consequential damages, resulting from the use of the
              company's service.
            </li>
            <li>
              Any additional or extra charges for far off locations & toll
              charges as well.
            </li>
            <li>You have complied with applicable law.</li>
            <li>
              Situations outside of our control, including but not restricted
              to:  Natural disasters or calamities, such as earthquakes,
              cyclones, storms, flooding, fire, illness, fog, snow, or frost.
            </li>
            <li>
              Force majeure encompasses various events such as war, epidemics,
              pandemics, accidents, public enemy acts, strikes, embargoes,
              airborne hazards, local disputes, or civil disturbances.
            </li>
            <li>
              Disruptions to air or ground transportation networks on a national
              or local level, as well as mechanical issues with vehicles or
              equipment.
            </li>
            <li>
              Potential flaws or innate immorality present in the shipment's
              contents.
            </li>
            <li>Third-party criminal activities, including theft and arson.</li>
            <li>
              Your actions or inactions, or those of third parties, including: 
              Your breaching your obligations under these terms and conditions,
              or any other party claiming an interest in the consignment causing
              you to breach these terms and conditions.
            </li>
            <li>
              The contents of the shipment, including any articles that are
              forbidden, hazardous goods, or valuables, even if you voluntarily
              gave the shipment to us without alerting, informing, or declaring
              it to us, or even if we may have accepted it in error.
            </li>
          </ul>
        </Typography>
      </Box>
      {/* DANGEROUS GOODS / SECURITY */}
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>DANGEROUS GOODS / SECURITY</Typography>
        <Typography mt={3} color={colors.main} component="div" pl={5}>
          <ul
            style={{
              listStyle: "circle",
              paddingTop: "10px",
              color: colors.main,
            }}
          >
            <li>
              We neither carry nor provide any other services in relation to
              goods that, in our sole opinion, are dangerous goods. These goods
              may include, but are not limited to, those listed in any laws,
              rules, technical instructions, or codes that apply to our company,
              the transportation of dangerous goods, or the provision of other
              services pertaining to them.
            </li>
            <li>
              If you have been given the status of an approved customer, we may,
              at our discretion, accept some dangerous goods for carriage or for
              the performance of other services in some locations. However, this
              must be confirmed in writing by us before your consignment is
              accepted. Only if your dangerous goods meet our requirements and
              the relevant regulations will they be accepted. Our nearest office
              has information on our requirements and how to apply for approved
              customer status. Upon acceptance of your consignment, you will
              receive an invoice with a dangerous goods surcharge.
            </li>
            <li>
              Prohibited Items: Consignments containing prohibited items will
              not be accepted. Consignments will only be accepted if you declare
              the type and value of the consignment (the basis for the term
              "said to contain"). Regarding the accuracy of the description,
              kind, or value of the declaration you provide, we bear no
              liability whatsoever. You also agree to defend, indemnify, and
              hold us harmless against any claims arising from or connected to
              the declaration you provide and any associated expenses.
            </li>
          </ul>
        </Typography>
      </Box>
      {/* REJECTED CONSIGNMENTS */}
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>REJECTED CONSIGNMENTS</Typography>
        <Typography mt={3} color={colors.main}>
          We will make an effort to get in touch with you and, if necessary,
          agree to the next course of action if the recipient declines to accept
          delivery. You consent to reimburse us for any expenses we incur for
          shipping, getting rid of, or returning the package, as well as any
          fees we assess for the decided-upon proper course of action.
        </Typography>
        <Typography mt={3} color={colors.main}>
          In the event that the user(s) cancels their agreement with LoadEazy
          regarding any consignment, they will be responsible for paying all
          fees and other costs incurred in connection with that consignment.
        </Typography>
      </Box>
      {/* CLAIMS BROUGHT BY THIRD PARTIES */}
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>
          CLAIMS BROUGHT BY THIRD PARTIES
        </Typography>
        <Typography mt={3} color={colors.main}>
          You promise us that, even in the event that we were careless or in
          default, you will not allow anyone else with an interest in the
          consignment to file a claim or action against us arising out of the
          carriage, and that, in the event that such a claim or action is filed,
          you will indemnify us against the results of the claim or action as
          well as the costs and expenses we incur in defending it.
        </Typography>
        <Typography mt={3} color={colors.main}>
          The User(s) and LoadEazy agree to the terms and conditions of use
          and/or agreement mentioned above, as well as the privacy policy,
          regarding access to and use of the website and services provided by
          LoadEazy. These agreements supersede any prior written or oral
          agreements regarding the same subject matter.
        </Typography>
      </Box>
      {/* GST */}
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>
          CLAIMS BROUGHT BY THIRD PARTIES
        </Typography>
        <Typography component="div" mt={1} pl={5}>
          <ul
            style={{
              listStyle: "disc",
              paddingTop: "10px",
              color: colors.main,
            }}
          >
            <li>GST, as applicable, will be levied on the Invoice.</li>
            <li>
              Any information shall be considered only prospectively. Under no
              circumstances, will invoices be revised retrospectively for delay
              in providing GST registration information.
            </li>
            <li>
              Taxes on reverse charge mechanism, wherever applicable, shall have
              to be paid by the recipient of services.
            </li>
          </ul>
        </Typography>
      </Box>
      {/* GOVERNING LAW AND JURISDICTION */}
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>GOVERNING LAW AND JURISDICTION</Typography>
        <Typography mt={3} color={colors.main}>
          These Terms of Service shall be governed by and construed in
          accordance with the laws of the India, without regard to the
          principles of conflict of laws. The courts of [Bengaluru] shall have
          exclusive jurisdiction over any disputes, differences or claims
          arising out of or in connection with these Terms of Service or any
          Services provided by us pursuant to these Terms of Service
        </Typography>
      </Box>
      {/* GRIEVANCE OFFICER*/}
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>GRIEVANCE OFFICER</Typography>
        <Typography mt={3} color={colors.main}>
          In accordance the Consumer Protection (E-Commerce) Rules, 2020, the
          name and contact details of the Nodal/Grievance Officer are provided,
          any grievances under the said Rules, may have relevance with respect
          to the said Rules, its treatment or any grievance related to Services
          being availed at LOADEAZY, the request may be directed by the
          Authority under the said Rules, to such grievance office:
        </Typography>

        <Box mt={4}>
          <Typography color={colors.main}>Juggernaut Ventures</Typography>
          <Typography mt={2} color={colors.main}>
            283, 5th Main, 5th Cross,{" "}
          </Typography>
          <Typography mt={2} color={colors.main}>
            Koramangala 1st Block,
          </Typography>
          <Typography mt={2} color={colors.main}>
            Bengaluru, Karnataka 560034{" "}
          </Typography>
          <Typography mt={2} color={colors.main}>
            Email: <span style={{ color: "blue" }}>jvgroup22@gmail.com</span>{" "}
          </Typography>
          <Typography mt={2} color={colors.main}>
            Time: Monday to Friday (10 a.m. to 6 p.m.)
          </Typography>
        </Box>
      </Box>
      {/* DISCLAIMER
       */}
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>DISCLAIMER</Typography>
        <Typography mt={3} color={colors.main}>
          The content on this website and the LoadEazy app is solely intended
          for general informational purposes. While we try to keep the
          information current and accurate, LoadEazy provides it. As such, we
          make no express or implied representations or warranties of any kind
          regarding the availability, suitability, accuracy, completeness, or
          reliability of the information, products, services, or related
          graphics on the website or app for any purpose. You therefore strictly
          assume all risk associated with any reliance you may have on such
          information.
        </Typography>
        <Typography mt={3} color={colors.main}>
          We disclaim all liability for any loss or damage, including but not
          limited to indirect or consequential loss or damage, or any loss or
          damage due to lost data or profits.
        </Typography>
        <Typography mt={3} color={colors.main}>
          You can link to other websites that are not managed by LoadEazy using
          this website or app. The availability, nature, and content of those
          websites are beyond our control. Any links provided are not meant to
          be recommendations or endorsements of the content found within.
        </Typography>
        <Typography mt={3} color={colors.main}>
          We make every effort to maintain the seamless operation of the portal.
          Nevertheless, if technical problems outside of our control cause the
          portal to become momentarily unavailable, LoadEazy disclaims all
          liability and accepts no responsibility for such events.
        </Typography>
      </Box>
    </Box>
  );
};
export default TermsAndConditions;