import React from "react";
import { InputLabel } from "@mui/material";

export const StyledInputLabel = ({ children }) => {
  return (
    <InputLabel
      htmlFor="name-input"
      sx={{
        color: "#333",
        fontFamily: "Helvetica",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        textTransform: "capitalize",
      }}
    >
      {children}
    </InputLabel>
  );
};
