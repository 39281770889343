import { configureStore } from "@reduxjs/toolkit";
import userAuthenticationSlice from "./slices/userAuthenticationSlice";
import transporterProfileSlice from "./slices/transporterProfileSlice";
import transporterAppStateSlice from "./slices/transporterAppStateSlice";
import transporterNameSlice from "./slices/transporterNameSlice";
import transporterIdSlice from "./slices/transporterIdSlice";
import supportNumbersSlice from "./slices/supportNumbersSlice";
import transporterSlice from "./slices/transporterSlice";
import customerProfileSlice from "./slices/customerProfileSlice";
import customerSlice from "./slices/customerSlice";

const store = configureStore({
  reducer: {
    user: userAuthenticationSlice,
    transporter: transporterSlice,
    transporterProfile: transporterProfileSlice,
    transporterAppState: transporterAppStateSlice,
    transporterName: transporterNameSlice,
    transporterId: transporterIdSlice,
    supportNumbers: supportNumbersSlice,
    customerProfile: customerProfileSlice,
    customer: customerSlice,
  },
});

export default store;
