import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import SidebarItem from "./SidebarItem";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

const SidebarItemCollapse = ({ item }) => {
  const location = useLocation();
  const isAdminPortal = (location.pathname.includes('admin'))
  const { transporterAppState } = useSelector(
    (state) => state.transporterAppState
  );
  useEffect(() => {
    if (transporterAppState.includes(item.state)) {
      setOpen(true);
    }
  }, [transporterAppState, item]);
  const [open, setOpen] = useState(false);

  return item.sidebarProps ? (
    <>
      <ListItemButton
        onClick={() => setOpen(!open)}
        sx={{
          "&: hover": {
            backgroundColor: "#f6f6f6",
          },
          paddingY: "8px",
          paddingX: "24px",
          mr: "1rem",
          color: "#6d6d6d",
          backgroundColor: open ? "#f6f6f6" : "#fff",
          borderRadius: "0.25rem",
        }}
      >
        <ListItemIcon
          sx={{
            filter: transporterAppState.includes(item.state)
              ? "invert(90%)"
              : "invert(50%)",
          }}
        >
          {item.sidebarProps.icon && item.sidebarProps.icon}
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <Typography
              sx={{
                fontWeight: isAdminPortal ? 400 :700,
                fontSize: isAdminPortal?'1rem':'18px',
                color: transporterAppState.includes(item.state)
                  ? "#333333"
                  : "#6d6d6d",
              }}
            >
              {item.sidebarProps.displayText}
            </Typography>
          }
        />
        {open ? (
          <img src="/assets/icons/expand_more.svg" alt="expand more" />
        ) : (
          <img src="/assets/icons/expand_less.svg" alt="expand less" />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <List>
          {item.child?.map((route, index) =>
            route.sidebarProps ? (
              <SidebarItem item={route} key={index} hasChild={true} />
            ) : null
          )}
        </List>
      </Collapse>
    </>
  ) : null;
};

export default SidebarItemCollapse;
