import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import {
  Box,
  Button,
  Divider,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { guestApi } from "../../api/config";
import {
  ref,
  onValue,
  off,
  limitToFirst,
  startAt,
  query,
  orderByKey,
  limitToLast,
  orderByChild,
  endAt,
} from "firebase/database";
import { getDatabase } from "firebase/database";
import { firebaseApp } from "../../firebase";
import { useSelector } from "react-redux";
import moment from "moment";

const TransporterNotificationPage = () => {
  const pageSize = 7;
  const navigate = useNavigate();
  const [notificationsList, setNotificationsList] = useState([]);
  const transporterId = useSelector((state) => state.transporterId.id);
  const [lastKey, setLastKey] = useState("");
  const [hasMore, setHasMore] = useState(true);

  const fetchNotifications = (lastKey = "") => {
    const db = getDatabase(firebaseApp);
    let itemsRef = ref(db, `history/generic/${transporterId}/`);

    // Initial query constraints
    let queryConstraints = [
      orderByChild("timestamp"),
      limitToLast(pageSize + 1),
    ]; // +1 to check for more items

    if (lastKey) {
      // If we have a lastKey, fetch the previous set of items, using endAt to specify the starting point
      queryConstraints.push(endAt(lastKey));
    }

    itemsRef = query(itemsRef,...queryConstraints);

    // Listener to fetch data
    const unsubscribe = onValue(itemsRef, (snapshot) => {
      const data = snapshot.val();
      let fetchedItems = data ? Object.values(data) : [];

      // Since we are using limitToLast, we need to reverse the fetched items to display them correctly
      fetchedItems.reverse();

      if (lastKey) {
        // Remove the first item which is a duplicate of the last item from the previous fetch
        fetchedItems.shift();
      }

      const newItems = [...notificationsList, ...fetchedItems];
      setNotificationsList(newItems);

      if (fetchedItems.length > 0) {
        // Save the timestamp of the last item for pagination
        // Note: Ensure that the fetchedItems are not empty before accessing its properties
        setLastKey(fetchedItems[fetchedItems.length - 1].timestamp);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    });

    // Return the unsubscribe function to clean up the listener
    return () => unsubscribe();
  };

  useEffect(() => {
    const unsubscribe = fetchNotifications();

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [transporterId]);
  return (
    <Box pb={3}>
      <Stack
        position="fixed"
        width="100%"
        sx={{ background: "#EAEAEA", zIndex: 1000 }}
        py={2}
      >
        <Stack
          width="100%"
          m="auto"
          direction="row"
          maxWidth="lg"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            sx={{ color: "#333", fontWeight: 700 }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosRoundedIcon />
          </Button>

          <Typography
            fontWeight={700}
            color="#333"
            textAlign="center"
            flexGrow={1}
            variant="h5"
          >
            Notification
          </Typography>
        </Stack>
      </Stack>
      <Toolbar />
      <Stack m="auto" maxWidth="lg">
        {notificationsList.length ? notificationsList.map((each, idx) => (
          <Notification data={each} key={idx} />
        )):<Typography color="#333" mt={20} fontSize={18} textAlign='center'>You have no notifications at the moment !</Typography>}
      </Stack>
      {hasMore && (
        <Stack direction="row" justifyContent="center" mt={5}>
          <Button
            sx={{ textTransform: "none" }}
            onClick={() => fetchNotifications(lastKey)}
          >
            Load more...
          </Button>
        </Stack>
      )}
      {!hasMore && notificationsList.length > 0 && (
        <Typography textAlign="center" fontSize={16} color="#ADADAD" my={5}>
          You're all caught up !
        </Typography>
      )}
    </Box>
  );
};
export default TransporterNotificationPage;

const Notification = ({ data }) => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        gap={5}
        maxWidth="lg"
        px={5}
        py={2}
      >
        <Box>
          <Typography fontWeight={700}>{data.title}</Typography>
          <Typography color="#ADADAD">{data.body}</Typography>
        </Box>
        <Typography fontWeight={700}>
          {moment(data.timestamp).format("DD MMMM hh:mm A")}
        </Typography>
      </Stack>
      <Divider />
    </>
  );
};
