import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import moment from "moment";
import { StyledTableCell } from "../../pages/Admin/Approval/StyledTableCell";
import { StyledTableRow } from "../Common/StyledTableRow";
import useToast from "../../hooks/useToast";
import { getTransporterTransactions } from "../../api/Admin/transporterResources";
import shortenString from "../../utils/shortenString";
import { formatNumber } from "../../utils/formatNumber";

const TransporterTransaction = ({
  panelExpanded,
  updateExpandedPanel,
  id,
  transporterName,
  amount,
}) => {
  const [truckTransactions, setTruckTransactions] = useState([]); // Renamed state
  const [paginationData, setPaginationData] = useState({});

  const createTable = (transaction) => {
    return (
      <StyledTableRow key={transaction.transactionId}>
        <StyledTableCell sx={{ color: "#6D6D6D" }}>
          {transaction.tripId}
        </StyledTableCell>
        {
          <Tooltip title={transaction.transactionId} placement="top">
            <StyledTableCell sx={{ color: "#6D6D6D" }}>
              {shortenString(transaction.transactionId, 10)}
            </StyledTableCell>
          </Tooltip>
        }
        <StyledTableCell sx={{ color: "#6D6D6D" }}>LE Platform</StyledTableCell>
        <StyledTableCell sx={{ color: "#6D6D6D" }}>
          {transporterName}
        </StyledTableCell>
        <StyledTableCell
          sx={{ color: "#6D6D6D" }}
        >{`₹ ${transaction.amount}`}</StyledTableCell>
        <StyledTableCell sx={{ color: "#6D6D6D" }}>
          {transaction.modeOfPayment}
        </StyledTableCell>
        <StyledTableCell sx={{ color: "#6D6D6D" }}>
          {transaction.isPaymentDoneThroughLEPlatform
            ? "LE Platform"
            : "Offline"}
        </StyledTableCell>
        <StyledTableCell sx={{ color: "#6D6D6D" }}>
          {moment(transaction.createdAt).format("DD-MM-YYYY")}
        </StyledTableCell>
        <StyledTableCell sx={{ color: "#6D6D6D" }}>
          {moment(transaction.createdAt).format("hh:mm A")}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [search, setSearch] = useState("");
  const [isPaymentDoneThroughLEPlatform, setIsPaymentDoneThroughLEPlatform] =
    useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setPageSize(parseInt(event.target.value, 10));
  };

  const { handleOpenToast } = useToast();

  const fetchTruckTransactions = async () => {
    try {
      const response = await getTransporterTransactions(
        id,
        page,
        pageSize,
        search,
        isPaymentDoneThroughLEPlatform === "Yes"
          ? true
          : isPaymentDoneThroughLEPlatform === "No"
          ? false
          : ""
      );
      if (response.status === 200) {
        setTruckTransactions(response.data.data.transactions);
        setPaginationData(response.data.data.pagination);
      }
    } catch (error) {
      handleOpenToast("Error while getting truck transactions", "error");
    }
  };

  const [filterPopoverAnchorEl, setFilterPopoverAnchorEl] = useState(null);

  const handleFilterPopoverOpen = (event) => {
    setFilterPopoverAnchorEl(event.currentTarget);
  };

  const handleFilterPopoverClose = () => {
    setFilterPopoverAnchorEl(null);
  };

  const handleStatusChange = (event) => {
    const value = event.target.value;
    setIsPaymentDoneThroughLEPlatform((prev) => (prev === value ? "" : value));
  };

  const isFilterPopoverOpen = Boolean(filterPopoverAnchorEl);

  const [debounceSearch, setDebounceSearch] = useState(search);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceSearch(search);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    fetchTruckTransactions();
  }, [page, pageSize, debounceSearch, isPaymentDoneThroughLEPlatform]);

  return (
    <Accordion
      disableGutters
      TransitionProps={{ unmountOnExit: true }}
      sx={{ borderRadius: ".35rem" }}
      expanded={panelExpanded === "Transactions"}
      onChange={updateExpandedPanel("Transactions")}
    >
      <AccordionSummary
        expandIcon={
          <img src="/assets/icons/expand_less.svg" alt="expand_less" />
        }
        aria-controls="panel1bh-content"
        sx={{
          background: "#EAEAEA",
          paddingLeft: "2rem",
          fontWeight: 700,
          "&.Mui-expanded": {
            paddingTop: 0,
          },
          "&.MuiAccordionSummary-root": {
            height: "36px !important",
            minHeight: "36px !important",
          },
        }}
      >
        {"Transactions"}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          background: "#FFF",
          paddingLeft: "2rem",
          paddingTop: "1rem",
        }}
      >
        <TextField
          variant="outlined"
          placeholder="Search by trip id"
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            sx: { height: "36.31px" },
            endAdornment: (
              <img src="/assets/icons/search-icon.svg" alt="search" />
            ),
          }}
        />
        <Box
          sx={{
            display: "flex",
            padding: "10px",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <Box sx={{ display: "flex", gap: "10px" }}>
            <AmountBox>
              Total Amount : {formatNumber(amount?.total || 0)}
            </AmountBox>

            <AmountBox>
              Amount Paid : {formatNumber(amount?.paid || 0)}
            </AmountBox>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <AmountBox>
              Balance Payable : {formatNumber(amount?.payable || 0)}
            </AmountBox>
            <AmountBox>
              Settled Amount : {formatNumber(amount?.settle)}
            </AmountBox>
            <AmountBox>Penalty : {formatNumber(amount?.penalty)}</AmountBox>
          </Box>
        </Box>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>Trip ID</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Transaction ID</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>From</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>To</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Amount Paid</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Payment Mode</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>
                  <StatusBox>
                    Payment Through
                    <img
                      src="/assets/icons/expand_less.svg"
                      alt="expand less transporter status"
                      style={{ cursor: "pointer" }}
                      onClick={handleFilterPopoverOpen}
                    />
                  </StatusBox>
                  <Popover
                    open={isFilterPopoverOpen}
                    anchorEl={filterPopoverAnchorEl}
                    onClose={handleFilterPopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <PopOverBox>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isPaymentDoneThroughLEPlatform === "Yes"}
                            onChange={handleStatusChange}
                            value="Yes"
                          />
                        }
                        label="LE Platform"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isPaymentDoneThroughLEPlatform === "No"}
                            onChange={handleStatusChange}
                            value="No"
                          />
                        }
                        label="Offline"
                      />
                    </PopOverBox>
                  </Popover>
                </TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Date</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {truckTransactions?.length > 0 ? (
                truckTransactions.map((transaction) => createTable(transaction))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No transactions found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
      {truckTransactions?.length > 0 && (
        <Box
          sx={{
            borderTop: "1px solid #adadad",
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={paginationData.totalItems}
            rowsPerPage={paginationData.pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ count }) => (
              <div>
                Page{" "}
                <span style={{ color: "green", fontWeight: 700 }}>
                  {page + 1}
                </span>{" "}
                of {Math.ceil(count / paginationData.pageSize)}
              </div>
            )}
          />
        </Box>
      )}
    </Accordion>
  );
};

export default TransporterTransaction;

const StatusBox = styled(Box)({
  fontSize: "14px",
  fontWeight: 700,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
});

const PopOverBox = styled(Box)({
  padding: "10px",
  display: "flex",
  flexDirection: "column",
});

const AmountBox = styled(Box)(() => ({
  fontWeight: 700,
  display: "flex",
  fontSize: "12px",
  alignItems: "center",
  backgroundColor: "#EAEAEA",
  padding: "8px",
}));

const StyledButton = styled(Button)(() => ({
  padding: "5px 12px",
  fontSize: "12px",
}));
