import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  PARSE_BODY_MATERIAL,
  PARSE_BODY_TYPE,
  PARSE_FORKLIFT_COMPATIBILITY,
} from "../../../../constants/util";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import {
  addNewTruck,
  editTruckData,
  getVehicleCategories,
  getVehicleDetailsById,
  getVehicleModels,
} from "../../../../api/Transporter/truck";
import { useSelector } from "react-redux";
import {
  BHARAT_VEHICLE_NUMBER_REGEX,
  BODY_MATERIAL,
  BODY_TYPE,
  VEHICLE_NUMBER_REGEX,
} from "../../../../constants/constant";
import StyledSelectInput from "../../../Common/StyledSelectInput";
import {
  CheckBoxLabel,
  MultiSelectDropdown,
  StyledInputLabel,
  StyledTextField,
  TruckImageUploader,
} from "../formComponents";
const initialVehicleData = {
  vehicleNumber: "",
  vehicleCategory: "",
  vehicleModelName: "",
  vehicleOwnerName: "",
  lengthOfBody: "",
  widthOfBody: "",
  heightOfBody: "",
  maxCapacity: "",
  bodyType: "",
  bodyCategory: [],
  bodyMaterial: "",
  forkliftCompatibility: "",
  accessories: {
    toolsAvailable: [],
    others: "",
  },
};
const accessoryList = [
  "Standard Tarpaulin for rain protection",
  "Heavy-duty chains for securing material",
  "Ropes",
  "Belt & Tightener",
  "Base support wood pieces",
];
const bodyTypeOptions = ["Open", "Low Deck", "High Deck", "Closed Container"];
const bodyMaterialOptions = ["Steel", "Wood"];

const AddEditTruckModal = ({
  mode,
  closeAddEditTruckModal,
  selectedVehicle,
  handleOpenToast,
  fetchAllTrucksList,
  transporterId,
  open,
  onClose,
}) => {
  // Accessing transporter id from application state.
  const transporterName = useSelector((state) => state.transporterName.name);
  // State variable for vehicle data initialized with initialVehicleData
  const [vehicleData, setVehicleData] = useState(initialVehicleData);

  const [vehicleCategory, setVehicleCategory] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);

  const [bodyCategory, setBodyCategory] = useState([]);
  // for holding states from api on edit for transformation to Frontend values
  const [tempBodyType, setTempBodyType] = useState("");
  const [tempBodyMaterial, setTempBodyMaterial] = useState("");
  const [tempForkliftCompatibility, setTempForkliftCompatibilty] = useState("");

  const [otherAccessories, setOtherAccessories] = useState("");

  //state to store different error state of vehicle.
  const [fieldErrors, setFieldErrors] = useState({
    vehicleCategory: "",
    vehicleOwnerName: "",
    truckNumber: "",
    maxCapacity: "",
    vehicleModelName: "",
  });

  // State variables for left, right, front, and rear images initialized as null
  const [leftImage, setLeftImage] = useState("");
  const [rightImage, setRightImage] = useState("");
  const [frontImage, setFrontImage] = useState("");
  const [rearImage, setRearImage] = useState("");

  // References to input elements for left, right, front, and rear images
  // const leftInputRef = useRef(null);
  // const rightInputRef = useRef(null);
  // const frontInputRef = useRef(null);
  // const rearInputRef = useRef(null);

  const handleFormDataChange = async (event) => {
    const { name, value } = event.target;
    if (name === "vehicleCategory") {
      setVehicleModels([]);
      setVehicleData((prevData) => ({ ...prevData, vehicleModelName: ""}));
      await fetchVehicleModels(event.target.value);
    }
    if (name === "bodyType" && mode === "edit") {
      setTempBodyType(value);
    }
    if (name === "bodyMaterial" && mode === "edit") {
      setTempBodyMaterial(value);
    }
    if (name === "forkliftCompatibility" && mode === "edit") {
      setTempForkliftCompatibilty(value);
    }
    if (
      ["maxCapacity", "lengthOfBody", "widthOfBody", "heightOfBody"].includes(
        name
      )
    ) {
      if (isNaN(value)) return;
    }
    setVehicleData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handles changes in the checkboxes for accessories
  const handleCheckboxChange = (accessoryItem) => {
    let updatedAccessories = [];
    if (vehicleData.accessories.toolsAvailable.includes(accessoryItem)) {
      updatedAccessories = vehicleData.accessories.toolsAvailable.filter(
        (accessory) => accessory !== accessoryItem
      );
    } else {
      updatedAccessories = [
        ...vehicleData.accessories.toolsAvailable,
        accessoryItem,
      ];
    }
    setVehicleData({
      ...vehicleData,
      accessories: {
        ...vehicleData.accessories,
        toolsAvailable: updatedAccessories,
      },
    });
  };

  // Handle i am owner checkbox click event.
  const handleIamOwnerCheckBoxClick = (event) => {
    const { checked } = event.target;
    setVehicleData({
      ...vehicleData,
      vehicleOwnerName: checked ? transporterName : "",
    });
  };

  // Fetches vehicle categories.
  const fetchVehicleCategories = async () => {
    try {
      const response = await getVehicleCategories();
      if (response.status === 200) {
        const allVehicleCategory = response?.data?.data;
        const result = allVehicleCategory.map((vehicle) => {
          const category = vehicle.category;
          const minCapacityMT = (vehicle.minCapacity / 1000).toFixed(3);
          const maxCapacityMT = (vehicle.maxCapacity / 1000).toFixed(3);
          return {
            id: category,
            value: `${category} wheeler: ${minCapacityMT} - ${maxCapacityMT} MT`,
            vehicleCategoryId: vehicle.vehicleCategoryId,
          };
        });
        setVehicleCategory(result);
      }
    } catch (error) {
      setVehicleCategory([]);
      handleOpenToast("Error fetching vehicle categories:", "error");
    }
  };

  //function to fetch vehicle model data.
  const fetchVehicleModels = async (value) => {
    try {
      const response = await getVehicleModels(value.split(" ")[0]);
      setVehicleModels(response?.data?.data);
    } catch (err) {
      setVehicleModels([]);
      handleOpenToast("Unable to fetch model data.", "error");
    }
  };

  //function to validation vehicle.
  const truckValidation = () => {
    const errors = {};
    let hasError = false;
    if (!vehicleData.vehicleCategory) {
      errors.vehicleCategory = "Vehicle Category is required";
      hasError = true;
    }

    if (!vehicleData.vehicleModelName) {
      errors.vehicleModelName = "Vehicle Model Name is required";
      hasError = true;
    }

    if (!vehicleData.vehicleOwnerName) {
      errors.vehicleOwnerName = "Vehicle Owner Name is required";
      hasError = true;
    }

    if (!vehicleData.vehicleNumber) {
      errors.truckNumber = "Truck Number is required";
      hasError = true;
    }
    if (!vehicleData.maxCapacity) {
      errors.maxCapacity = "This is required field";
      hasError = true;
    }
    if (
      vehicleData.vehicleNumber &&
      !VEHICLE_NUMBER_REGEX.test(
        vehicleData.vehicleNumber.replace(/\s/g, "")
      ) &&
      !BHARAT_VEHICLE_NUMBER_REGEX.test(
        vehicleData.vehicleNumber.replace(/\s/g, "")
      )
    ) {
      errors.truckNumber = "Truck Number is invalid";
      hasError = true;
    }

    setFieldErrors(errors);
    return hasError;
  };

  // bodyCategory array handler
  const updateBodyCategory = (category) => {
    setBodyCategory((prevCategory) => {
      if (!prevCategory.includes(category)) {
        return category;
      } else {
        return [...prevCategory.filter((option) => option !== category)];
      }
    });
  };

  // Functions for adding truck.
  const addNewTruckList = async () => {
    const requestBody = {
      vehicleTypeId: vehicleModels.filter(
        (e) => e.modelName === vehicleData?.vehicleModelName
      )[0]?.vehicleTypeId,
      vehicleModelName: vehicleData.vehicleModelName,
      vehicleManufacturerName: vehicleModels.filter(
        (e) => e.modelName === vehicleData?.vehicleModelName
      )[0]?.manufacturerName,
      vehicleNumber: vehicleData?.vehicleNumber
        .toUpperCase()
        .replace(/\s/g, ""),
      vehicleCategory: parseInt(vehicleData.vehicleCategory.split(" ")[0]),
      vehicleCategoryId: vehicleCategory.filter(
        (e) => e.value === vehicleData.vehicleCategory
      )[0]?.vehicleCategoryId,
      vehicleOwnerName: vehicleData?.vehicleOwnerName,
      maxCapacity: vehicleData.maxCapacity,
      lengthOfBody: vehicleData.lengthOfBody
        ? parseInt(vehicleData.lengthOfBody)
        : "",
      widthOfBody: vehicleData.widthOfBody
        ? parseInt(vehicleData.widthOfBody)
        : "",
      heightOfBody: vehicleData.heightOfBody
        ? parseInt(vehicleData.heightOfBody)
        : "",
      forkliftCompatibility: vehicleData.forkliftCompatibility === "Yes",
      bodyMaterial: BODY_MATERIAL[vehicleData.bodyMaterial] ?? "",
      bodyType: BODY_TYPE[vehicleData.bodyType] ?? "",
      bodyCategory: bodyCategory,
      vehicleImages: {
        frontUrl: frontImage,
        rearUrl: rearImage,
        leftUrl: leftImage,
        rightUrl: rightImage,
      },
      accessories: {
        toolsAvailable: vehicleData.accessories.toolsAvailable,
        other: otherAccessories,
      },
    };
    const checkValidationError = truckValidation();
    if (checkValidationError) {
      handleOpenToast(
        "Some fields in form are not filled correctly!",
        "warning"
      );
      return;
    }

    try {
      const response = await addNewTruck(transporterId, requestBody);
      if (response.status === 201) {
        closeAddEditTruckModal();
        await fetchAllTrucksList();
        handleOpenToast("Truck added successfully!", "success");
      } else if (response.response.status === 409) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          truckNumber: "Vehicle with this number already exists",
        }));
        handleOpenToast("Truck with same number already exists.", "error");
      } else {
        handleOpenToast("Truck addition failed.", "error");
      }
    } catch (err) {
      handleOpenToast("Cannot perform this action.", "error");
    }
  };

  // Functions for editing truck.
  const editTruckList = async () => {
    const editRequestBody = {
      vehicleOwnerName: vehicleData?.vehicleOwnerName,
      vehicleImages: {
        frontUrl: frontImage,
        rearUrl: rearImage,
        leftUrl: leftImage,
        rightUrl: rightImage,
      },
      accessories: {
        toolsAvailable: vehicleData.accessories.toolsAvailable,
        other: otherAccessories,
      },
      bodyType: BODY_TYPE[tempBodyType] ?? "",
      bodyMaterial: BODY_MATERIAL[tempBodyMaterial] ?? "",
      bodyCategory: bodyCategory.length ? bodyCategory : [],
      maxCapacity: vehicleData.maxCapacity,
      forkliftCompatibility: vehicleData.forkliftCompatibility === "Yes",
      lengthOfBody: vehicleData?.lengthOfBody ?? "",
      widthOfBody: vehicleData?.widthOfBody ?? "",
      heightOfBody: vehicleData?.heightOfBody ?? "",
    };
    if (truckValidation(editRequestBody)) {
      handleOpenToast(
        "Some fields in form are not filled correctly!",
        "warning"
      );
      return;
    }
    try {
      let response = await editTruckData(
        transporterId,
        selectedVehicle.transporterVehicleId,
        editRequestBody
      );
      if (response.status === 200) {
        closeAddEditTruckModal();
        await fetchAllTrucksList();
        handleOpenToast("Truck details edited successfully.", "success");
      } else {
        handleOpenToast("Error updating truck details.", "error");
      }
    } catch (err) {
      handleOpenToast(
        "Couldn't perform this action. Please try again !",
        "error"
      );
    }
  };

  useEffect(() => {
    if (mode === "edit") {
      getVehicleDetailsById(transporterId, selectedVehicle.transporterVehicleId)
        .then((vehicle) => {
          setVehicleData(vehicle.data.data);
          vehicle.data.data?.bodyType &&
            setTempBodyType(PARSE_BODY_TYPE[vehicle.data.data.bodyType]);
          vehicle.data.data?.bodyMaterial &&
            setTempBodyMaterial(
              PARSE_BODY_MATERIAL[vehicle.data.data.bodyMaterial]
            );
          setTempForkliftCompatibilty(
            PARSE_FORKLIFT_COMPATIBILITY[
              vehicle.data.data.forkliftCompatibility
            ]
          );
          setFrontImage(vehicle.data.data?.vehicleImages?.frontUrl ?? "");
          setRearImage(vehicle.data.data?.vehicleImages?.rearUrl ?? "");
          setLeftImage(vehicle.data.data?.vehicleImages?.leftUrl ?? "");
          setRightImage(vehicle.data.data?.vehicleImages?.rightUrl ?? "");
          setBodyCategory(vehicle.data.data?.bodyCategory ?? []);

          setOtherAccessories(vehicle.data.data.accessories?.other ?? "");
        })
        .catch((err) => {
          setVehicleData(initialVehicleData);
        });
    } else {
      fetchVehicleCategories();
      setVehicleData(initialVehicleData);
    }
  }, []);

  // Handles the click events for selecting an image input based on the side
  // const handleEditImageClick = (side) => {
  //   if (side === "left") {
  //     leftInputRef.current.click();
  //   } else if (side === "right") {
  //     rightInputRef.current.click();
  //   } else if (side === "front") {
  //     frontInputRef.current.click();
  //   } else if (side === "rear") {
  //     rearInputRef.current.click();
  //   }
  // };

  // Function to handle image change
  // const handleImageChange = async (event, side) => {
  //   const file = event.target.files[0];
  //   const docImage = await uploadimage(`public/Transporter/Vehicle`, file);
  //   handleSetImage(side, docImage);

  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = () => {};
  //     reader.readAsDataURL(file);
  //   }
  // };

  // // Function to set image based on side
  const handleSetImage = (side, imageResult) => {
    switch (side) {
      case "left":
        setLeftImage(imageResult);
        break;
      case "right":
        setRightImage(imageResult);
        break;
      case "front":
        setFrontImage(imageResult);
        break;
      case "rear":
        setRearImage(imageResult);
        break;
      default:
        break;
    }
  };

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle sx={{ color: "#333" }}>
        <Typography fontWeight={700} fontSize={24}>
          {mode === "edit" ? "Edit Truck" : "Add Truck"}
        </Typography>
        <Typography>Please fill in the details of the truck:</Typography>
      </DialogTitle>
      <CloseIcon
        fontSize="medium"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: "10px",
          top: "14px",
          cursor: "pointer",
        }}
      />

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <StyledInputLabel
              isRequired
              disabled={mode === "edit"}
              htmlFor="category"
            >
              Vehicle Category
            </StyledInputLabel>
            {mode === "edit" ? (
              <TextField
                fullWidth
                id="category"
                value={vehicleData?.vehicleCategory + " Wheelers"}
                InputProps={{
                  readOnly: true,
                  sx: {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C6C6C6 !important",
                    },
                  },
                  style: { height: "2.75rem", color: "#6d6d6d" },
                }}
              />
            ) : (
              <StyledSelectInput
                id="category"
                name="vehicleCategory"
                error={fieldErrors.vehicleCategory}
                helperText={fieldErrors.vehicleCategory}
                value={vehicleData?.vehicleCategory}
                items={vehicleCategory}
                onChange={handleFormDataChange}
              />
            )}
          </Grid>

          <Grid item xs={6}>
            <StyledInputLabel
              isRequired
              disabled={mode === "edit"}
              htmlFor="model"
            >
              Vehicle Model Name
            </StyledInputLabel>

            {mode === "edit" ? (
              <TextField
                fullWidth
                id="model"
                value={vehicleData?.vehicleModelName}
                // disabled
                InputProps={{
                  readOnly: true,
                  sx: {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C6C6C6 !important",
                    },
                  },
                  style: { height: "2.75rem", color: "#6d6d6d" },
                }}
              />
            ) : (
              <StyledSelectInput
                id="model"
                name="vehicleModelName"
                error={fieldErrors.vehicleModelName}
                value={vehicleData?.vehicleModelName}
                disabled={!vehicleData.vehicleCategory}
                items={vehicleModels.map((e, i) => ({
                  id: i,
                  value: e.modelName,
                }))}
                onChange={handleFormDataChange}
              />
            )}
          </Grid>

          <Grid item xs={6}>
            <StyledInputLabel
              isRequired
              disabled={mode === "edit"}
              htmlFor="number"
            >
              Truck Number
            </StyledInputLabel>
            {mode === "edit" ? (
              <TextField
                fullWidth
                id="number"
                value={vehicleData?.vehicleNumber}
                aria-readonly
                InputProps={{
                  readOnly: true,
                  sx: {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C6C6C6 !important",
                    },
                  },
                  style: { height: "2.75rem", color: "#6d6d6d" },
                }}
              />
            ) : (
              <StyledTextField
                id="number"
                name="vehicleNumber"
                placeholder="XX 00 XX 0000"
                onChange={handleFormDataChange}
                value={vehicleData.vehicleNumber.toUpperCase()}
                error={Boolean(!!fieldErrors.truckNumber)}
                helperText={fieldErrors.truckNumber}
              />
            )}
          </Grid>

          <Grid item xs={6}>
            <Stack
              direction="row"
              // alignItems="center"
              justifyContent="space-between"
            >
              <StyledInputLabel isRequired htmlFor="name">
                Owner Name
              </StyledInputLabel>
            </Stack>
            <StyledTextField
              id="name"
              name="vehicleOwnerName"
              placeholder="Enter the owner's name"
              onChange={handleFormDataChange}
              value={vehicleData?.vehicleOwnerName}
              error={Boolean(!!fieldErrors.vehicleOwnerName)}
              helperText={fieldErrors.vehicleOwnerName}
            />
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  checked={vehicleData.vehicleOwnerName === transporterName}
                  color="success"
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 20 },
                    padding: "0 .25rem 0 1rem",
                  }}
                  onChange={(e) => handleIamOwnerCheckBoxClick(e)}
                />
              }
              label="I am the truck owner"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInputLabel isRequired htmlFor="capacity">
              Max Capacity in kg.
            </StyledInputLabel>
            <StyledTextField
              id="capacity"
              name="maxCapacity"
              value={vehicleData?.maxCapacity}
              error={Boolean(!!fieldErrors.maxCapacity)}
              helperText={fieldErrors.maxCapacity}
              onChange={handleFormDataChange}
              placeholder="Max capacity"
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInputLabel htmlFor="length">
              Length of body in ft.
            </StyledInputLabel>
            <StyledTextField
              id="length"
              value={vehicleData?.lengthOfBody ?? ""}
              name="lengthOfBody"
              onChange={handleFormDataChange}
              placeholder="Enter length in feet"
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInputLabel htmlFor="width">
              Width of body in ft.
            </StyledInputLabel>
            <StyledTextField
              id="width"
              value={vehicleData?.widthOfBody ?? ""}
              name="widthOfBody"
              onChange={handleFormDataChange}
              placeholder="Enter width in feet"
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInputLabel htmlFor="height">
              Height of body in ft.
            </StyledInputLabel>
            <StyledTextField
              id="height"
              value={vehicleData?.heightOfBody ?? ""}
              name="heightOfBody"
              onChange={handleFormDataChange}
              placeholder="Enter height in feet"
            />
          </Grid>

          <Grid item xs={6}>
            <StyledInputLabel htmlFor="forklift">
              Forklift Compatible
            </StyledInputLabel>
            <StyledSelectInput
              id="forklift"
              value={
                mode === "edit"
                  ? tempForkliftCompatibility
                  : vehicleData.forkliftCompatibility
              }
              onChange={handleFormDataChange}
              name="forkliftCompatibility"
              items={["Yes", "No"].map((e) => ({ value: e, id: e }))}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInputLabel htmlFor="material">
              Body Material
            </StyledInputLabel>
            <StyledSelectInput
              id="material"
              value={
                mode === "edit" ? tempBodyMaterial : vehicleData?.bodyMaterial
              }
              onChange={handleFormDataChange}
              name="bodyMaterial"
              items={bodyMaterialOptions.map((material) => ({
                value: material,
                id: material,
              }))}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInputLabel htmlFor="bodytype">Body Type</StyledInputLabel>
            <StyledSelectInput
              id="bodytype"
              value={mode === "edit" ? tempBodyType : vehicleData?.bodyType}
              onChange={handleFormDataChange}
              name="bodyType"
              items={bodyTypeOptions.map((type) => ({ value: type, id: type }))}
            />
          </Grid>
          {/* TODO */}
          <Grid item xs={6}>
            <StyledInputLabel htmlFor="bodycategory">
              Body Category
            </StyledInputLabel>
            <MultiSelectDropdown
              id="bodycategory"
              name="bodyCategory"
              updateBodyCategory={updateBodyCategory}
              value={vehicleData?.bodyCategory}
            />
          </Grid>
          {/* ACCESSORIES */}
          <Grid item xs={12}>
            <Stack direction="row" gap={4} mt={1}>
              <Typography
                sx={{
                  color: "#333",
                  fontSize: "1rem",
                  fontWeight: 700,
                  pt: 0.75,
                }}
              >
                Accessories
              </Typography>
              <Stack
                direction="column"
                flexGrow={1}
                flexWrap="wrap"
                maxHeight={130}
              >
                {accessoryList.map((accessory, index) => (
                  <Stack direction="row" alignItems="center" key={index}>
                    <Checkbox
                      id={accessory}
                      checked={vehicleData?.accessories?.toolsAvailable.includes(
                        accessory
                      )}
                      color="success"
                      onChange={() => handleCheckboxChange(accessory)}
                      sx={{
                        padding: 1,
                      }}
                    />
                    <CheckBoxLabel option={accessory} />
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack pl={16}>
              <TextField
                value={otherAccessories}
                onChange={(e) => setOtherAccessories(e.target.value)}
                placeholder="Others"
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            sx={{
              color: "#333",
              fontSize: "1rem",
              fontWeight: 700,
              mb: 1,
            }}
          >
            Images
          </Typography>
        </Grid>
        <Grid item container>
          <Grid item xs={3}>
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "1px solid #C6C6C6",
                gap: "10px",
                height: "140px",
                width: "197px",
                p: frontImage ? 0 : 2,
                cursor: "pointer",
              }}
              onClick={() => handleEditImageClick("front")}
            >
              {frontImage ? (
                <img
                  src={frontImage}
                  alt="front side"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <>
                  <img
                    src="/assets/icons/truckFrontSide.svg"
                    alt="truck front side"
                    style={{ width: "35px", height: "45px" }}
                  />

                  <img
                    src="/assets/icons/uploadIcon.svg"
                    alt="upload"
                    style={{ width: "16px", height: "16px" }}
                  />
                  <Typography>Front side</Typography>
                </>
              )}
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(event) => handleImageChange(event, "front")}
                ref={frontInputRef}
              />
            </Box> */}
            <TruckImageUploader
              side="Front"
              updateImage={setFrontImage}
              image={frontImage}
            />
          </Grid>
          <Grid item xs={3}>
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "1px solid #C6C6C6",
                gap: "10px",
                height: "140px",
                width: "197px",
                p: rearImage ? 0 : 2,
              }}
              p={2}
              onClick={() => handleEditImageClick("rear")}
            >
              {rearImage ? (
                <img
                  src={rearImage}
                  alt="back side"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <>
                  <img
                    src="/assets/icons/truckBackSide.svg"
                    alt="truck back side"
                    style={{ width: "35px", height: "45px" }}
                  />

                  <img
                    src="/assets/icons/uploadIcon.svg"
                    alt="upload"
                    style={{ width: "16px", height: "16px" }}
                  />
                  <Typography>Rear side</Typography>
                </>
              )}
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(event) => handleImageChange(event, "rear")}
                ref={rearInputRef}
              />
            </Box> */}
            <TruckImageUploader
              side="Back"
              updateImage={setRearImage}
              image={rearImage}
            />
          </Grid>
          <Grid item xs={3}>
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "1px solid #C6C6C6",
                gap: "10px",
                height: "140px",
                width: "197px",
                p: leftImage ? 0 : 2,
              }}
              onClick={() => handleEditImageClick("left")}
            >
              {leftImage ? (
                <img
                  src={leftImage}
                  alt="right side"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <>
                  <img
                    src="/assets/icons/truckLeftSide.svg"
                    alt="truck left side"
                    style={{ width: "130px", height: "42px" }}
                  />

                  <img
                    src="/assets/icons/uploadIcon.svg"
                    alt="upload"
                    style={{ width: "16px", height: "16px" }}
                  />
                  <Typography>Left side</Typography>
                </>
              )}
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(event) => handleImageChange(event, "left")}
                ref={leftInputRef}
              />
            </Box> */}
            <TruckImageUploader
              side="Left"
              updateImage={setLeftImage}
              image={leftImage}
            />
          </Grid>
          <Grid item xs={3}>
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "1px solid #C6C6C6",
                gap: "10px",
                height: "140px",
                width: "197px",
                p: rightImage ? 0 : 2,
              }}
              onClick={() => handleEditImageClick("right")}
            >
              {rightImage ? (
                <img
                  src={rightImage}
                  alt="right side"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <>
                  <img
                    src="/assets/icons/truckRightSide.svg"
                    alt="truck right side "
                    style={{ width: "130px", height: "42px" }}
                  />
                  <img
                    src="/assets/icons/uploadIcon.svg"
                    alt="upload"
                    style={{ width: "16px", height: "16px" }}
                  />
                  <Typography>Right side</Typography>
                </>
              )}
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(event) => handleImageChange(event, "right")}
                ref={rightInputRef}
              />
            </Box> */}
            <TruckImageUploader
              side="Right"
              updateImage={setRightImage}
              image={rightImage}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} textAlign="right" mt={3}>
          <Button
            variant="contained"
            onClick={() =>
              mode === "edit" ? editTruckList() : addNewTruckList()
            }
          >
            {mode === "edit" ? "Save" : "Add truck"}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditTruckModal;

// <Grid item xs={3}>
// <Box
//   sx={{
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     border: "1px solid #C6C6C6",
//     gap: "10px",
//     height: "140px",
//     width: "197px",
//     p: frontImage ? 0 : 2,
//     cursor: "pointer",
//   }}
//   onClick={() => handleEditImageClick("front")}
// >
//   {frontImage ? (
//     <img
//       src={frontImage}
//       alt="front side"
//       style={{ width: "100%", height: "100%", objectFit: "cover" }}
//     />
//   ) : (
//     <>
//       <img
//         src="/assets/icons/truckFrontSide.svg"
//         alt="truck front side"
//         style={{ width: "35px", height: "45px" }}
//       />

//       <img
//         src="/assets/icons/uploadIcon.svg"
//         alt="upload"
//         style={{ width: "16px", height: "16px" }}
//       />
//       <Typography>Front side</Typography>
//     </>
//   )}
//   <input
//     type="file"
//     accept="image/*"
//     style={{ display: "none" }}
//     onChange={(event) => handleImageChange(event, "front")}
//     ref={frontInputRef}
//   />
// </Box>
// </Grid>
