import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { BASE_URL } from "../../../api/config";
import Cookies from "js-cookie";
import { LOADING_STATUS } from "../../../constants/constant";
import KycUpdatesAlert from "../../../component/Common/KycUpdatesMessages";

const GST = (props) => {
  const { expanded, handleChange, userGstNumber } = props;
  const [gstStatus, setGstStatus] = useState(userGstNumber?.approvalStatus);
  const [gstNumber, setGstNumber] = useState(userGstNumber?.licenceNumber);
  const [isGstSubmitted, setIsGstSubmitted] = useState(LOADING_STATUS.INITIAL);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const approvalStatusMarker = () => {
    if (gstStatus === "REJECTED") {
      return <img src="/assets/icons/red-cross.svg" alt="rejected" />;
    }
    if (gstStatus === "APPROVED") {
      return <img src="/assets/icons/green-tick.svg" alt="approved" />;
    }
    return null;
  };
  const handleOnClose = () => {
    setShowSnackbar(false);
  };
  const handleGSTNumberInputChange = (event) => {
    setGstNumber(event.target.value);
  };
  const handleSubmitGstNumber = async () => {
    if (!gstNumber) return;
    // if(gstNumber?.length !== 15) return

    setIsGstSubmitted(LOADING_STATUS.LOADING);
    try {
      const response = await axios.post(
        BASE_URL.concat("transporter/kyc/gst"),
        {
          gstNumber: gstNumber,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${Cookies.get("transportertoken")}`,
          },
        }
      );
      setIsGstSubmitted(LOADING_STATUS.LOADED);
      setGstStatus(response.data.kycDetails.gstDetails.approvalStatus);
      setSnackbarMessage({
        msg: "Submitted Successfully",
        severity: "success",
      });
      setShowSnackbar(true);
    } catch (err) {
      setIsGstSubmitted(LOADING_STATUS.FAILED);
      setSnackbarMessage({ msg: "Something went wrong", severity: "error" });
      setShowSnackbar(true);
    }
  };
  return (
    <>
      <Accordion
        sx={{ boxShadow: "none", borderRadius: ".35rem" }}
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<img src="/assets/icons/expand_less.svg" />}
          aria-controls="panel3bh-content"
          id="panel1bh-header"
          sx={{
            background: "#EAEAEA",
            paddingLeft: "2rem",
            height: "3rem",
            borderRadius: ".25rem",
            "&.Mui-expanded": {
              minHeight: "3rem",
            },
          }}
        >
          <Typography sx={{ width: "33%", flexShrink: 0, fontWeight: 700 }}>
            GST Details &nbsp; {approvalStatusMarker()}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            background: "#FFF",
            width: "60%",
            paddingTop: "2rem",
            paddingLeft: "2rem",
          }}
        >
          <InputLabel sx={{ color: "#333", fontSize: "14px" }}>
            GSTIN/UIN Number
          </InputLabel>
          <TextField
            placeholder="000000000000"
            // type="text"
            required
            maxLength={12}
            value={gstNumber ? gstNumber : ""}
            // defaultValue={aadhaarNumber}
            fullWidth
            InputProps={{
              style: { height: "52px", fontWeight: 700 },
            }}
            onChange={handleGSTNumberInputChange}
            // error={gstNumber}
            // helperText="15 digit"
          />

          <Stack direction="row" justifyContent="flex-end" mt={5}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                marginLeft: "auto",
                paddingInline: "24px",
                paddingBlock: "4px",
              }}
              onClick={handleSubmitGstNumber}
            >
              Submit
            </Button>
          </Stack>
        </AccordionDetails>
      </Accordion>
      {showSnackbar && (
        <KycUpdatesAlert
          handleOnClose={handleOnClose}
          open={showSnackbar}
          message={snackbarMessage.msg}
          severity={snackbarMessage.severity}
        />
      )}
    </>
  );
};

export default GST;
