import { adminApi } from "../config";

// Function to fetch all support numbers.
export const getSupportNumbers = async () => {
  try {
    const response = await adminApi.get(`support/support-numbers`);
    return response;
  } catch (error) {
    return error;
  }
};

// Function to update support numbers.
export const editSupportNumbers = async (updatedSupportNumbers) => {
  try {
    const response = await adminApi.put(
      `support/support-numbers`,
      updatedSupportNumbers
    );
    return response;
  } catch (error) {
    return error;
  }
};
