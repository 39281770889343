import React, { useEffect, useState } from "react";
import Timer from "./Timer";
import { Box, CircularProgress } from "@mui/material";
import {
  getCustomerRelatedTime,
  updateCustomerRelatedTime,
} from "../../../../api/Admin/TimeRelated";
import useToast from "../../../../hooks/useToast";

const CustomerRelated = () => {
  const [showDriverInfoPreTripTime, setShowDriverInfoPreTripTime] = useState({
    hr: 0,
    min: 0,
    sec: 0,
  });

  const [
    customerTripCancellationDeadline,
    setCustomerTripCancellationDeadline,
  ] = useState({
    d: 0,
    hr: 0,
    min: 0,
    sec: 0,
  });

  const [
    tripCancellationNotificationDeadline,
    setTripCancellationNotificationDeadline,
  ] = useState({
    d: 0,
    hr: 0,
    min: 0,
    sec: 0,
  });

  const [
    customerPaymentSettlementDeadline,
    setCustomerPaymentSettlementDeadline,
  ] = useState({
    d: 0,
    hr: 0,
    min: 0,
    sec: 0,
  });

  const [offersRefreshTime, setOffersRefreshTime] = useState({
    min: 0,
  });

  const [isLoading, setIsLoading] = useState(false);

  const { handleOpenToast, ToastMessage } = useToast();

  // function to fetch customer related time configurations
  const fetchCustomerRelatedConfigs = async () => {
    try {
      setIsLoading(true);
      const response = await getCustomerRelatedTime();
      if (response.status === 200) {
        setShowDriverInfoPreTripTime(
          response?.data?.data?.showDriverInfoPreTripTime
        );
        setCustomerTripCancellationDeadline(
          response?.data?.data?.customerTripCancellationDeadline
        );
        setTripCancellationNotificationDeadline(
          response?.data?.data?.tripCancellationNotificationDeadline
        );
        setCustomerPaymentSettlementDeadline(
          response?.data?.data?.customerPaymentSettlementDeadline
        );
        setOffersRefreshTime(response?.data?.data?.offersRefreshTime);
      }
      setIsLoading(false);
    } catch (error) {
      handleOpenToast(
        "Error while fetching customer related configurations",
        "error"
      );
      setIsLoading(false);
    }
  };

  // function to update show driver info pre-trip time
  const updateShowDriverInfoPreTripTime = async (timeData) => {
    try {
      const response = await updateCustomerRelatedTime(
        {
          showDriverInfoPreTripTime: timeData,
        },
        true,
        false,
        false,
        false,
        false
      );
      if (response.status === 200) {
        fetchCustomerRelatedConfigs();
        handleOpenToast(
          "Show Driver Info Pre-trip Time updated successfully",
          "success"
        );
      }
    } catch (error) {
      handleOpenToast(
        "Error while updating Show Driver Info Pre-trip Time",
        "error"
      );
    }
  };

  // function to update customer trip cancellation deadline
  const updateCustomerTripCancellationDeadline = async (timeData) => {
    try {
      const response = await updateCustomerRelatedTime(
        {
          customerTripCancellationDeadline: timeData,
        },
        false,
        true,
        false,
        false,
        false
      );

      if (response.status === 200) {
        fetchCustomerRelatedConfigs();
        handleOpenToast(
          "Customer Trip Cancellation Deadline updated successfully",
          "success"
        );
      }
    } catch (error) {
      handleOpenToast(
        "Error while updating Customer Trip Cancellation Deadline",
        "error"
      );
    }
  };

  // function to update trip cancellation notification deadline
  const updateTripCancellationNotificationDeadline = async (timeData) => {
    try {
      const response = await updateCustomerRelatedTime(
        {
          tripCancellationNotificationDeadline: timeData,
        },
        false,
        false,
        true,
        false,
        false
      );
      if (response.status === 200) {
        fetchCustomerRelatedConfigs();
        handleOpenToast(
          "Trip Cancellation Notification Deadline updated successfully",
          "success"
        );
      }
    } catch (error) {
      handleOpenToast(
        "Error while updating Trip Cancellation Notification Deadline",
        "error"
      );
    }
  };

  // function to update customer payment settlement deadline
  const updateCustomerPaymentSettlementDeadline = async (timeData) => {
    try {
      const response = await updateCustomerRelatedTime(
        {
          customerPaymentSettlementDeadline: timeData,
        },
        false,
        false,
        false,
        true,
        false
      );
      if (response.status === 200) {
        fetchCustomerRelatedConfigs();
        handleOpenToast(
          "Customer Payment Settlement Deadline updated successfully",
          "success"
        );
      }
    } catch (error) {
      handleOpenToast(
        "Error while updating Customer Payment Settlement Deadline",
        "error"
      );
    }
  };

  // function to update best offer refresh time
  const updateBestOfferRefreshTime = async (timeData) => {
    try {
      const response = await updateCustomerRelatedTime(
        {
          offersRefreshTime: timeData,
        },
        false,
        false,
        false,
        false,
        true
      );
      if (response.status === 200) {
        fetchCustomerRelatedConfigs();
        handleOpenToast(
          "Best Offer Refresh Time updated successfully",
          "success"
        );
      }
    } catch (error) {
      handleOpenToast("Error while updating Best Offer Refresh Time", "error");
    }
  };

  useEffect(() => {
    fetchCustomerRelatedConfigs();
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "35vh",
          width: "100%",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box>
      <ToastMessage />
      <Timer
        title="Show Driver Info Pre-trip Time"
        hour={showDriverInfoPreTripTime.hr}
        minute={showDriverInfoPreTripTime.min}
        second={showDriverInfoPreTripTime.sec}
        onSave={updateShowDriverInfoPreTripTime}
      />
      <Timer
        title="Customer Trip Cancellation Deadline"
        day={customerTripCancellationDeadline.d}
        hour={customerTripCancellationDeadline.hr}
        minute={customerTripCancellationDeadline.min}
        second={customerTripCancellationDeadline.sec}
        onSave={updateCustomerTripCancellationDeadline}
      />
      <Timer
        title="Trip Cancellation Notification Deadline"
        day={tripCancellationNotificationDeadline.d}
        hour={tripCancellationNotificationDeadline.hr}
        minute={tripCancellationNotificationDeadline.min}
        second={tripCancellationNotificationDeadline.sec}
        onSave={updateTripCancellationNotificationDeadline}
      />
      <Timer
        title="Customer Payment Settlement Deadline"
        day={customerPaymentSettlementDeadline.d}
        hour={customerPaymentSettlementDeadline.hr}
        minute={customerPaymentSettlementDeadline.min}
        second={customerPaymentSettlementDeadline.sec}
        onSave={updateCustomerPaymentSettlementDeadline}
      />

      <Timer
        title="Best Offer Refresh Time"
        minute={offersRefreshTime.min}
        onSave={updateBestOfferRefreshTime}
      />
    </Box>
  );
};

export default CustomerRelated;
