import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import styled from "@emotion/styled";

import HeaderWithUnderline from "./HeaderWithUnderline";
import { LOADING_STATUS } from "../../../../constants/constant";
import LoadingTable from "../../../Common/LoadingTable";
import { formatNumber } from "../../../../utils/formatNumber";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFFFFF",
    color: "#333333",
    borderRight: "1px solid #c6c6c6",
    fontWeight: 700,
    borderBottom: "none",
    fontSize: "12px",
  },
}));

const StyledTableBodyCell = styled(TableCell)(() => ({
  color: "#6D6D6D",
}));

const StyledTableRow = styled(TableRow)(() => ({
  border: "2px solid #FFF",

  td: {
    fontWeight: 700,
    fontSize: "12px",
    paddingBottom: "8px",
    paddingTop: "8px",
    borderBottom: "6px solid #fff",
    "&:hover": {
      color: "#333333",
    },
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function LiveQuotesSharedDetails(props) {
  const { enquiry } = props;

  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageData, setPageData] = useState([]);
  const [isEnquiriesListFetched, setIsEnquiriesListFetched] = useState(
    LOADING_STATUS.INITIAL
  );

  const perPage = 10;
  const totalItems = tableData?.length;

  useEffect(() => {
    const startIndex = page * perPage;

    setPageData(tableData?.slice(startIndex, startIndex + 10));
  }, [tableData, page]);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (enquiry?.bids?.length > 0) {
      let result = enquiry?.bids.map((item) => {
        const matchedItem = enquiry?.matchedTransporters.find(
          (matched) => matched.transporterId === item.transporterId
        );

        return {
          transporterName: matchedItem.fullName || "-",
          phoneNumber: matchedItem.phone || "-",
          companyName: matchedItem.businessName || "-",
          vehicleCategory:
            `${enquiry?.customer?.preferredVehicle?.vehicleCategory} Wheeler` ||
            "-",
          modalName: item.vehicleModelName || "-",
          transportationCost: item.amountBided || "-",
          platformCharge: item.platformMargin || "-",
          totalAmount: item.totalCost || "-",
          transporterId: item.transporterId || "-",
        };
      });

      setTableData(result);
      setIsEnquiriesListFetched(LOADING_STATUS.LOADED);
    }
  }, [enquiry]);

  function renderViews() {
    switch (isEnquiriesListFetched) {
      case LOADING_STATUS.LOADING:
        return <LoadingTable columns={9} />;
      case LOADING_STATUS.LOADED:
        if (pageData?.length) {
          return (
            <>
              {pageData.map((enquiry, i) => (
                <StyledTableRow
                  key={i}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <StyledTableBodyCell>
                    {enquiry?.transporterName}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {enquiry.phoneNumber}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {enquiry.companyName}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {enquiry.vehicleCategory}
                  </StyledTableBodyCell>

                  <StyledTableBodyCell>{enquiry.modalName}</StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {`₹ ${formatNumber(enquiry.transportationCost)}`}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {`₹ ${formatNumber(enquiry.platformCharge)}`}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {`₹ ${formatNumber(enquiry.totalAmount)}`}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>-</StyledTableBodyCell>
                </StyledTableRow>
              ))}
            </>
          );
        } else {
          return (
            <TableRow sx={{ background: "#EAEAEA" }}>
              <TableCell
                colSpan={9}
                align="center"
                sx={{ fontWeight: 600, color: "#333" }}
              >
                No Results Found.
              </TableCell>
            </TableRow>
          );
        }
      default:
        return null;
    }
  }
  return (
    <Box>
      <HeaderWithUnderline header="Live Quotes shared" />

      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          boxShadow: "none",
        }}
      >
        <Table size="small" aria-label="Enquiry data" stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>Transporter Name</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
              <StyledTableCell>Company Name</StyledTableCell>
              <StyledTableCell>Vehicle Category</StyledTableCell>
              <StyledTableCell>Modal</StyledTableCell>
              <StyledTableCell>Transportation Cost</StyledTableCell>
              <StyledTableCell>Platform Charge</StyledTableCell>
              <StyledTableCell>Total Amount</StyledTableCell>
              <StyledTableCell>Amount / Ton</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderViews()}</TableBody>
        </Table>
      </TableContainer>

      <div
        style={{
          borderTop: "1px solid #adadad",
          marginTop: "3px",
        }}
      >
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={totalItems}
          rowsPerPage={perPage}
          page={page}
          onPageChange={handleChangePage}
          labelDisplayedRows={({ count }) => (
            <div>
              Page{" "}
              <span style={{ color: "green", fontWeight: 700 }}>
                {page + 1}
              </span>{" "}
              of {Math.ceil(count / perPage)}
            </div>
          )}
        />
      </div>
    </Box>
  );
}
