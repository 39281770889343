import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";
import { OTPInput } from "../component/OTPInput";
import { formatCounterTime } from "../utils/formatCounterTime";
import { useDispatch, useSelector } from "react-redux";
// import { config } from "../api/config";
import { client } from "../api/config";

import { OTP_VERIFICATION_STATUS } from "../constants/constant";
import { setTransporterProfile } from "../redux/slices/transporterProfileSlice";
import { setUserCredentials } from "../redux/slices/userAuthenticationSlice";
import Cookies from "js-cookie";
import { setCustomerId } from "../redux/slices/customerSlice";

const OTPVerificationScreen = ({ handleCloseLoginModal, stakeholder }) => {
  const SEND_OTP_END_POINT =
    stakeholder === "admin" ? "send-otp-admin" : "send-otp";
  const VERIFY_OTP_END_POINT =
    stakeholder === "admin" ? "verify-otp-admin" : "verify-otp";

  const dispatch = useDispatch();
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [validityTime, setValidityTime] = useState(120);
  const [errMessage, setErrMessage] = useState("");
  const [ftueStatus, setFtueStatus] = useState("");
  const [isOTPVerified, setIsOTPVerified] = useState(
    OTP_VERIFICATION_STATUS.INITIAL
  );

  // This state is responsible for actions on Resend btn
  const [resendTime, setResendTime] = useState(30);
  const { phone, hash, portal, isEnquiryFlow } = useSelector((state) => state.user);

  const [routeTo, setRouteTo] = useState("");

  useEffect(() => {
    const countdown = setInterval(() => {
      setValidityTime((prev) => {
        if (prev > 90) {
          // here im preventing the usage of another counter and only keeping a state for applying actions on Resend btn.
          // once the 30s has crossed this state won't be set anymore and Resenf btn will be enabled
          setResendTime((p) => p - 1);
        }
        return prev - 1;
      });
    }, 1000);

    if (validityTime === 0) {
      clearInterval(countdown);
    }

    // Cleanup the interval on component unmount
    return () => clearInterval(countdown);
  }, [validityTime]);

  const resendOTP = () => {
    client
      .post(`${SEND_OTP_END_POINT}`, {
        phone: phone,
      })
      .then((user) => {
        const { phone, hash } = user.data;
        // updating userAuthentication slice upon resending OTP
        dispatch(setUserCredentials({ phone, hash, portal: stakeholder,isEnquiryFlow}));
        setOtp(["", "", "", ""]);
        setValidityTime(120);
        setResendTime(30);
        setErrMessage("");
      })
      .catch((err) => {
        setErrMessage("Couldn't sent OTP. Please try again.");
      });
  };

  const handleOTPVerification = async () => {
    setIsOTPVerified(OTP_VERIFICATION_STATUS.VERIFYING);
    let response;
    try {
      response = await client.post(VERIFY_OTP_END_POINT, {
        hash,
        phone,
        otp: otp.join(""),
        portal,
      });
      const currentUser = await response.data;
      const userProfile = currentUser.profile;
      const requestedPortal = currentUser.portal;
      const accessToken = currentUser.accessToken;
      const userId = currentUser.profile._id

      Cookies.set(`${requestedPortal}token`, accessToken);

      if (requestedPortal === "transporter") {
        // seeting ftueStatus in local storage for conditional rendering on app only if its transporter portal
        const { ftueStatus } = userProfile;
        localStorage.setItem("ftueStatus", ftueStatus);

        // setting transporter profile details in redux state.
        dispatch(setTransporterProfile(userProfile));

        setFtueStatus(ftueStatus);
        // deciding routes on arrival of user based on ftue status
        if (ftueStatus === "PENDING") {
          setRouteTo(`/${requestedPortal}/accounts/profile`);
        } else if (ftueStatus === "IN_PROGRESS") {
          setRouteTo(`/${requestedPortal}/accounts/cities&routes`);
        } else {
          setRouteTo(`/${requestedPortal}/dashboard`);
        }
      } else if (requestedPortal === "customer") {
        dispatch(setCustomerId(userId));
        if (isEnquiryFlow) {
          handleCloseLoginModal();
          return userId;
        } else setRouteTo(`/${requestedPortal}/dashboard`);
      } else {
        setRouteTo(`/admin/app/dashboard`);
        Cookies.set('loggedInAdminId', userId)
      }

      setIsOTPVerified(OTP_VERIFICATION_STATUS.VERIFIED);
    } catch (err) {
      setIsOTPVerified(OTP_VERIFICATION_STATUS.NOT_VERIFIED);
      setErrMessage(err.response.data.message);
    }
  };

  if (isOTPVerified === OTP_VERIFICATION_STATUS.VERIFIED) {
    return <Navigate to={routeTo} replace={true} state={{ ftueStatus }} />;
  }

  return (
    <Box>
      <Box borderBottom="1px solid #C6C6C6">
        <Box
          p={2.5}
          width="64%"
          ml="auto"
          display="flex"
          justifyContent="space-between"
        >
          <Typography
            sx={{ color: "#333333", fontWeight: "bold", fontSize: "1.025rem" }}
          >
            Verify OTP
          </Typography>
          { stakeholder !== "admin" && <img
            src="/assets/icons/cross-icon.svg"
            alt="close icon"
            onClick={handleCloseLoginModal}
            style={{ cursor: "pointer" }}
          />}
        </Box>
      </Box>
      <Stack direction="column" alignItems="center" textAlign="center" pt={2}>
        <Typography
          align="center"
          mb={1}
          mt={1.75}
          sx={{ color: "#333333", fontSize: "0.875rem", fontWeight: 700 }}
        >
          Enter the 4-digit code sent to {phone}
        </Typography>
        <Typography mb={1} sx={{ color: "#808080", fontSize: "0.875rem" }}>
          Valid for {formatCounterTime(validityTime)}
        </Typography>

        {/* This is the component for 4 digit OTP field  */}
        <OTPInput
          otp={otp}
          setOtp={setOtp}
          handleOTPVerification ={handleOTPVerification}
          onChange={() => setErrMessage("")}
        />
        <Box sx={{ height: "1.5rem" }}>
          {validityTime === 0 && (
            <Typography mt={1} sx={{ fontSize: "0.875rem", color: "#FF3F3F" }}>
              OTP has expired
            </Typography>
          )}
          {validityTime !== 0 && errMessage && (
            <Typography mt={1} sx={{ fontSize: "0.875rem", color: "#FF3F3F" }}>
              {errMessage}
            </Typography>
          )}
        </Box>

        <Stack mt={4} gap={2} direction="row">
          {resendTime > 0 ? (
            <Button
              sx={{ fontSize: "1.025rem" }}
              variant="outlined"
              disabled
              color="primary"
            >
              Resend {`in ${resendTime}`}
            </Button>
          ) : (
            <Button
              sx={{ fontSize: "1.025rem" }}
              variant="outlined"
              color="primary"
              onClick={() => {
                resendOTP();
              }}
            >
              Resend
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            sx={{
              fontSize: "1.025rem",
            }}
            disabled={otp.includes("") || validityTime === 0} // OTP length is 4 or timer has not expired, then only verify btn is enabled
            onClick={handleOTPVerification}
          >
            Verify
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default OTPVerificationScreen;
