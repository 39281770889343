import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Grid,
  MenuItem,
  InputLabel,
  IconButton,
  Divider,
  Autocomplete,
} from "@mui/material";
import styled from "@emotion/styled";
import { INDUSTRY_ITEMS } from "../../../../constants/constant";
import AddIcon from "@mui/icons-material/Add";
import {
  createMaterialCategory,
  updateMaterialCategory,
} from "../../../../api/Admin/matericalCategory";
import ClearIcon from "@mui/icons-material/Clear";
import { getVehicles } from "../../../../api/Customer/enquiry";

const AddEditMaterialCategoryModel = ({
  handleDeleteMaterialTypeButtonClick,
  addEditMaterialModalData,
  editMaterialData,
  handleCloseModal,
  fetchAllMaterial,
  handleOpenToast,
}) => {
  const [vehicleList, setVehicleList] = useState([]);
  const [materialInfo, setMaterialInfo] = useState({
    materialCategory: "",
    industry: "",
    vehicles: [
      {
        vehicleCategoryId: "",
        numberOfWheels: "",
        minWeight: "",
        maxWeight: "",
      },
    ],
  });

  const [validationErrors, setValidationErrors] = useState({});

  const validateMaterialInfo = (data) => {
    const errors = {};
    if (!data.materialCategory) {
      errors.materialCategory = "Category Name is required";
    }
    if (!data.industry) {
      errors.industry = "Industry is required";
    }
    if (data.vehicles.length === 0) {
      errors.vehicles = "At least one vehicle is required";
    }
    data.vehicles.forEach((vehicle, index) => {
      if (!vehicle.numberOfWheels || !vehicle.vehicleCategoryId) {
        errors[`numberOfWheels${index}`] = "Choose Vehicle Type from dropdown";
      }
      if (!vehicle.minWeight || vehicle.minWeight <= 0) {
        errors[`minWeight${index}`] = "Min Weight is required";
      }
      if (!vehicle.maxWeight || vehicle.maxWeight <= 0) {
        errors[`maxWeight${index}`] = "Max Weight is required";
      }
    });
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMaterialInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddVehicle = () => {
    const newVehicle = {
      vehicleCategoryId: "",
      numberOfWheels: "",
      minWeight: "",
      maxWeight: "",
    };
    setMaterialInfo((prevState) => ({
      ...prevState,
      vehicles: [...prevState.vehicles, newVehicle],
    }));
  };

  const handleRemoveVehicle = (index) => {
    if (materialInfo.vehicles.length === 1) {
      handleOpenToast("At least one vehicle is required", "error");
      return;
    }
    setMaterialInfo((prevState) => ({
      ...prevState,
      vehicles: prevState.vehicles.filter((_, i) => i !== index),
    }));
  };

  const handleVehicleChange = (index, field, value) => {
    const updatedVehicles = materialInfo.vehicles.map((vehicle, i) => {
      if (i === index) {
        if (field === "numberOfWheels") {
          const vehicleCategory = vehicleList.find(
            (vehicle) => vehicle.category === value?.value
          );

          if (vehicleCategory) {
            return {
              ...vehicle,
              vehicleCategoryId: vehicleCategory.vehicleCategoryId,
              [field]: value?.value,
            };
          }
          return {
            ...vehicle,
            [field]: value?.value,
          };
        }
        return {
          ...vehicle,
          [field]: value,
        };
      }
      return vehicle;
    });

    setMaterialInfo((prevState) => ({
      ...prevState,
      vehicles: updatedVehicles,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateMaterialInfo(materialInfo);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    } else {
      setValidationErrors({});
    }
    let materialData = { ...materialInfo };

    materialData.vehicles = materialData.vehicles.map((vehicle) => {
      return {
        ...vehicle,
        minWeight: vehicle.minWeight * 1000,
        maxWeight: vehicle.maxWeight * 1000,
      };
    });

    if (editMaterialData && editMaterialData?.materialCategoryId) {
      const response = await updateMaterialCategory(
        editMaterialData.materialCategoryId,
        materialData.industry,
        materialData.materialCategory,
        materialData.vehicles
      );
      if (response.status === 200) {
        await fetchAllMaterial();
        handleOpenToast("Material category updated successfully", "success");
      } else {
        handleOpenToast("Failed to update material category", "error");
      }
    } else {
      const response = await createMaterialCategory(
        materialData.industry,
        materialData.materialCategory,
        materialData.vehicles
      );
      if (response.status === 201) {
        await fetchAllMaterial();
        handleOpenToast("Material Category added successfully", "success");
      } else {
        handleOpenToast("Failed to add material category", "error");
      }
    }
    handleCloseModal();
  };

  const getVehiclesList = async () => {
    try {
      const response = await getVehicles();
      if (response.status === 200) {
        setVehicleList(
          response.data.data.map((vehicle, idx) => ({
            category: vehicle.category,
            vehicleCategoryId: vehicle.vehicleCategoryId,
          }))
        );
      }
    } catch (error) {
      handleOpenToast("Failed to fetch vehicle data", "error");
    }
  };

  const vehicleCategoriesForDropdown = vehicleList.map((vehicle, idx) => ({
    value: vehicle.category,
    label: `${vehicle.category} Wheelers`,
  }));

  useEffect(() => {
    getVehiclesList();
  }, []);

  useEffect(() => {
    if (addEditMaterialModalData) {
      setMaterialInfo(addEditMaterialModalData);
    }
  }, [addEditMaterialModalData]);

  useEffect(() => {
    if (editMaterialData && editMaterialData.materialCategoryId) {
      setMaterialInfo({
        materialCategory: editMaterialData.materialCategory,
        industry: editMaterialData.industry,
        vehicles: editMaterialData.vehicle.map((vehicle) => {
          return {
            vehicleCategoryId: vehicle.vehicleCategoryId,
            numberOfWheels: vehicle.numberOfWheels,
            minWeight: vehicle.minWeight / 1000,
            maxWeight: vehicle.maxWeight / 1000,
          };
        }),
      });
    }

    setMaterialInfo((prevState) => ({
      ...prevState,
    }));
  }, [editMaterialData]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Box
        sx={{
          bgcolor: "white",
          p: 5,
          borderRadius: 4,
          width: "70%",
          height: "550px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              fontSize: "2rem",
              fontWeight: 700,
              marginBottom: "20px",
            }}
          >
            {editMaterialData && editMaterialData.materialCategoryId
              ? "Edit Material Category"
              : "Add Material Category"}
          </Box>
          <img
            src="/assets/icons/cross-icon.svg"
            alt="cross"
            style={{
              cursor: "pointer",
            }}
            onClick={handleCloseModal}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "92%",
            overflowY: "scroll",
            padding: "20px",
          }}
        >
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputLabel
                  sx={{ color: "#333", fontSize: "1.125rem", fontWeight: 700 }}
                  htmlFor="industry"
                >
                  Industry <span style={{ color: "red" }}>*</span>
                </InputLabel>

                <TextField
                  name="industry"
                  select
                  label={materialInfo.industry ? "" : "Select Industry"}
                  value={materialInfo.industry}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: false }}
                  error={!!validationErrors && !!validationErrors.industry}
                  helperText={validationErrors && validationErrors.industry}
                >
                  {INDUSTRY_ITEMS.map((accessory, index) => (
                    <MenuItem key={index} value={accessory}>
                      {accessory}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  sx={{
                    color: "#333",
                    fontSize: "1.125rem",
                    fontWeight: 700,
                  }}
                  htmlFor="materialCategory"
                >
                  Category Name <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <TextField
                  variant="outlined"
                  placeholder="Category Name"
                  name="materialCategory"
                  value={materialInfo.materialCategory}
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  error={
                    !!validationErrors && !!validationErrors.materialCategory
                  }
                  helperText={
                    validationErrors && validationErrors.materialCategory
                  }
                />
              </Grid>
              <Box sx={{ width: "100%" }}>
                <Divider
                  light
                  style={{ marginTop: "30px", marginBottom: "10px" }}
                />
              </Box>
              <StyledVehicleBox>
                Vehicle Preference
                <Button
                  onClick={handleAddVehicle}
                  variant="outlined"
                  sx={{ margin: "10px", paddingX: "20px" }}
                  startIcon={<AddIcon />}
                >
                  Add Vehicle
                </Button>
              </StyledVehicleBox>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginTop: "20px",
                  paddingX: "15px",
                }}
              >
                {materialInfo.vehicles.map((vehicle, index) => {
                  return (
                    <Grid
                      container
                      spacing={2}
                      key={index}
                      sx={{
                        width: "100%",
                        marginBottom: "20px",
                        alignItems: "flex-start",
                        justifyContent: "center",
                      }}
                    >
                      <Grid item xs={4}>
                        <InputLabel
                          sx={{
                            color: "#333",
                            fontSize: "1.125rem",
                            fontWeight: 700,
                          }}
                          htmlFor="numberOfWheels"
                        >
                          Vehicle Category{" "}
                          <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <Autocomplete
                          options={vehicleCategoriesForDropdown}
                          getOptionLabel={(option) => option.label}
                          value={
                            vehicleCategoriesForDropdown.find(
                              (option) => option.value == vehicle.numberOfWheels
                            ) || null
                          }
                          onChange={(e, value) =>
                            handleVehicleChange(index, "numberOfWheels", value)
                          }
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px white inset",
                            },
                            "& .MuiInputLabel-outlined.Mui-focused": {
                              color: "gray",
                            },
                            "& .MuiInputLabel-outlined": {
                              color: "gray",
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                vehicle.numberOfWheels ||
                                params.inputProps.value
                                  ? ""
                                  : "Vehicle Category"
                              }
                              variant="outlined"
                              fullWidth
                              value={vehicle.numberOfWheels}
                              InputLabelProps={{ shrink: false }}
                              InputProps={{
                                ...params.InputProps,
                                sx: {
                                  "&.MuiOutlinedInput-root .MuiAutocomplete-input":
                                    {
                                      padding: "1rem",
                                      paddingRight: "4rem",
                                      fontWeight: 700,
                                      color: "#333",
                                    },
                                },
                              }}
                            />
                          )}
                        />
                        <span style={{ color: "red", fontSize: "0.8rem" }}>
                          {validationErrors &&
                            validationErrors[`numberOfWheels${index}`]}
                        </span>
                      </Grid>
                      <Grid item xs={4}>
                        <InputLabel
                          sx={{
                            color: "#333",
                            fontSize: "1.125rem",
                            fontWeight: 700,
                          }}
                          htmlFor="minWeight"
                        >
                          Min Capacity (TONS)
                          <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <TextField
                          label={vehicle.minWeight ? "" : "Min Capacity (TONS)"}
                          type="number"
                          variant="outlined"
                          name="minWeight"
                          fullWidth
                          value={vehicle.minWeight}
                          InputLabelProps={{ shrink: false }}
                          onChange={(e) =>
                            handleVehicleChange(
                              index,
                              "minWeight",
                              e.target.value
                            )
                          }
                          sx={{
                            "& .MuiOutlinedInput-input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px white inset",
                            },
                            "& .MuiInputLabel-outlined.Mui-focused": {
                              color: "gray",
                            },
                            "& .MuiInputLabel-outlined": {
                              color: "gray",
                            },
                          }}
                          error={
                            !!validationErrors &&
                            !!validationErrors[`minWeight${index}`]
                          }
                          helperText={
                            validationErrors &&
                            validationErrors[`minWeight${index}`]
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <InputLabel
                          sx={{
                            color: "#333",
                            fontSize: "1.125rem",
                            fontWeight: 700,
                          }}
                          htmlFor="maxWeight"
                        >
                          Max Capacity (TONS)
                          <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <TextField
                          label={vehicle.maxWeight ? "" : "Max Capacity (TONS)"}
                          type="number"
                          variant="outlined"
                          fullWidth
                          value={vehicle.maxWeight}
                          InputLabelProps={{ shrink: false }}
                          onChange={(e) =>
                            handleVehicleChange(
                              index,
                              "maxWeight",
                              e.target.value
                            )
                          }
                          sx={{
                            "& .MuiOutlinedInput-input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px white inset",
                            },
                            "& .MuiInputLabel-outlined.Mui-focused": {
                              color: "gray",
                            },
                            "& .MuiInputLabel-outlined": {
                              color: "gray",
                            },
                          }}
                          error={
                            !!validationErrors &&
                            !!validationErrors[`maxWeight${index}`]
                          }
                          helperText={
                            validationErrors &&
                            validationErrors[`maxWeight${index}`]
                          }
                        />
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => handleRemoveVehicle(index)}
                          style={{ marginTop: "30px" }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  );
                })}
              </Box>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: `${
                  editMaterialData && editMaterialData.materialCategoryId
                    ? "space-between"
                    : "flex-end"
                }`,
                width: "100%",
              }}
            >
              {editMaterialData && editMaterialData.materialCategoryId ? (
                <span
                  onClick={handleDeleteMaterialTypeButtonClick}
                  style={{
                    color: "red",
                    marginTop: "10px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Delete
                </span>
              ) : null}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{
                  float: "right",
                  padding: "10px 30px",
                  fontSize: "1.2rem",
                  margin: "20px 0",
                }}
              >
                Save
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default AddEditMaterialCategoryModel;

const StyledVehicleBox = styled(Box)({
  fontSize: "1.3rem",
  fontWeight: 600,
  display: "flex",
  width: "100%",
  marginLeft: "17px",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "space-between",
});
