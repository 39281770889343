import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  TextField,
} from "@mui/material";
import useToast from "../../../../hooks/useToast";
import {
  editSupportNumbers,
  getSupportNumbers,
} from "../../../../api/Admin/supportNumber";

const SupportNumber = () => {
  //show Toast messages
  const { toastData, handleOpenToast, handleCloseToast, ToastMessage } =
    useToast();

  // Declarations for different portals with keys and labels
  const portals = [
    { key: "customer", label: "Customer Portal" },
    { key: "transporter", label: "Transporter Portal" },
    { key: "driver", label: "driver Portal" },
    { key: "landingPage", label: "Landing Page" },
  ];

  // State to handle the loading status of support numbers
  const [isSupportNumbersFetched, setIsSupportNumbersFetched] =
    useState("LOADING");

  // State to manage support numbers for different portals
  const [supportNumbers, setSupportNumbers] = useState({
    customer: "0000000000",
    transporter: "0000000000",
    driver: "0000000000",
    landingPage: "0000000000",
  });

  // Function to fetch support number details from the server
  const fetchSupportNumberDetails = async () => {
    try {
      const response = await getSupportNumbers();
      if (response.status === 200) {
        const supportNumbersData = response.data;
        setSupportNumbers({
          customer: supportNumbersData?.data?.customerSupportNumber,
          transporter: supportNumbersData?.data?.transporterSupportNumber,
          driver: supportNumbersData?.data?.driverSupportNumber,
          landingPage: supportNumbersData?.data?.landingPageSupportNumber,
        });
        setIsSupportNumbersFetched("LOADED");
      } else {
        handleOpenToast("Failed to fetch support numbers.", "error");
        setIsSupportNumbersFetched("FAILED");
      }
    } catch {
      setIsSupportNumbersFetched("FAILED");
    }
  };

  // Function to handle changes in support number fields
  const handleSupportNumberChange = (prop) => (event) => {
    setSupportNumbers({ ...supportNumbers, [prop]: event.target.value });
  };

  // Function to update support numbers.
  const handleUpdatSupportNumbers = async (updatedSupportNumbers) => {
    const body = {
      customerSupportNumber: updatedSupportNumbers.customer,
      transporterSupportNumber: updatedSupportNumbers.transporter,
      driverSupportNumber: updatedSupportNumbers.driver,
      landingPageSupportNumber: updatedSupportNumbers.landingPage,
    };

    try {
      const response = await editSupportNumbers(body);
      if (response.status === 200) {
        const supportNumbersData = response.data;
        setSupportNumbers({
          customer: supportNumbersData?.data?.customerSupportNumber,
          transporter: supportNumbersData?.data?.transporterSupportNumber,
          driver: supportNumbersData?.data?.driverSupportNumber,
          landingPage: supportNumbersData?.data?.landingPageSupportNumber,
        });
        setIsSupportNumbersFetched("LOADED");
        handleOpenToast("Support numbers updated.", "success");
      } else {
        handleOpenToast("Support number updation failed.", "error");
        setIsSupportNumbersFetched("FAILED");
      }
    } catch (error) {
      setIsSupportNumbersFetched("FAILED");
    }
  };

  // State to manage input errors for support numbers
  const [inputErrors, setInputErrors] = useState({
    customer: false,
    transporter: false,
    driver: false,
    landingPage: false,
  });

  // Function to handle changes in support number input fields
  const handleInputChange = (event, inputId) => {
    const value = event.target.value;
    const numberRegex = /^[0-9]*$/;
    const isValid = numberRegex.test(value);

    if (value.length > 10 || !isValid) {
      return;
    } else {
      setInputErrors({ ...inputErrors, [inputId]: false });
      handleSupportNumberChange(inputId)(event);
    }
  };

  // Function to handle the save button click for updating support numbers
  const handleSaveButtonClick = async () => {
    const updatedSupportNumbers = {
      customer: supportNumbers.customer,
      transporter: supportNumbers.transporter,
      driver: supportNumbers.driver,
      landingPage: supportNumbers.landingPage,
    };

    const errors = {
      customer: updatedSupportNumbers.customer.length !== 10,
      transporter: updatedSupportNumbers.transporter.length !== 10,
      driver: updatedSupportNumbers.driver.length !== 10,
      landingPage: updatedSupportNumbers.landingPage.length !== 10,
    };
    setInputErrors(errors);
    if (!Object.values(errors).some((error) => error)) {
      await handleUpdatSupportNumbers(updatedSupportNumbers);
    }
  };

  useEffect(() => {
    fetchSupportNumberDetails();
  }, []);

  return (
    <>
      {isSupportNumbersFetched === "LOADING" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            width: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : isSupportNumbersFetched === "LOADED" ? (
        <>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <ToastMessage
              open={toastData.open}
              message={toastData.message}
              severity={toastData.severity}
              handleClose={handleCloseToast}
            />
            {portals.map(({ key, label }) => (
              <Box key={key} sx={{ marginTop: "20px", marginLeft: "40px" }}>
                <StyledInputLabel>{label}</StyledInputLabel>
                <TextField
                  variant="outlined"
                  value={supportNumbers[key]}
                  placeholder="0000 000 000"
                  fullWidth
                  InputProps={{
                    style: { height: "34px", width: "284px", marginTop: "4px" },
                  }}
                  onChange={(event) => handleInputChange(event, key)}
                  error={inputErrors[key]}
                  helperText={
                    inputErrors[key] ? "Please enter a valid mobile number" : ""
                  }
                />
              </Box>
            ))}
          </Box>
          <Button
            sx={{ marginTop: "20px", marginLeft: "40px" }}
            variant="contained"
            color="primary"
            onClick={() => handleSaveButtonClick()}
          >
            Save
          </Button>
        </>
      ) : (
        <React.Fragment>
          <p>Failed</p>
        </React.Fragment>
      )}
    </>
  );
};

const StyledInputLabel = ({ children }) => {
  return (
    <InputLabel
      htmlFor="name-input"
      sx={{
        color: "#333",
        fontFamily: "Helvetica",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        textTransform: "capitalize",
      }}
    >
      {children}
    </InputLabel>
  );
};

export default SupportNumber;
