import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createOrder, createTransaction } from "../../../api/Admin/trips";
import styled from "@emotion/styled";

const AmountPayModal = ({
  isModalOpen,
  handleCloseModal,
  tripData,
  handleOpenToast,
}) => {
  const [amountPayable, setAmountPayable] = useState(
    tripData?.transporterAmounts?.balancePayable
  );
  const [error, setError] = useState("");

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const razorPay = async () => {
    try {
      setError("");

      // Check if the entered amount is greater than the payable amount
      if (
        Math.ceil(amountPayable) >
        Math.ceil(tripData?.transporterAmounts?.balancePayable)
      ) {
        setError("Please enter less than or equal to payable amount");
        return;
      }

      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        handleOpenToast(
          "Razorpay SDK failed to load. Are you online?",
          "error"
        );
        return;
      }

      const result = await createOrder(tripData?.id, amountPayable);

      if (!result) {
        handleOpenToast("Server error. Are you online?", "error");
        return;
      }

      const { amount, id: order_id, currency } = result.data.data;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: amount.toString(),
        currency: currency,
        name: "LoadEazy",
        description: "Test Transaction",
        image:
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAcBAMAAABi/9neAAAAG1BMVEUAAAAOgEc5OTk6OjoMgEQzMzMMf0QzMzP////9dtj5AAAABnRSTlMAEhIj7u4z0rXDAAAAAWJLR0QIht6VegAAAEVJREFUGNNjYEAFoaEBLC4wwMBQXl7AlgYDpHMZBWEAzEW2CMhlgVuDopccLiPcGnwW0dpHpaFwAOIiARK5xsZGSnDAAAB8VWucFCeHkwAAAABJRU5ErkJggg==",
        order_id: order_id,
        handler: async function (response) {
          const data = {
            paymentId: response.razorpay_payment_id,
            tripId: tripData?.tripId,
            customerEnquiryId: tripData?.enquiryDetails?.enquiryId,
            senderId: "LE_PLATFORM",
            receiverId: tripData?.transporterDetails?.transporterId,
            isPaymentDoneThroughLEPlatform: true,
            transactionStatus: "SUCCESS",
            paymentType: "RECEIVED",
            userType: "transporter",
          };
          await createTransaction(data);
          handleCloseModal();
        },

        prefill: {
          name: tripData?.customerDetails?.name,
          email: tripData?.customerDetails?.email,
          contact: tripData?.customerDetails?.phone,
        },
        notes: {
          address: "LoadEazy office address",
        },
        theme: {
          color: "#0C7F44",
        },
      };

      const rzp = new window.Razorpay(options);

      rzp.open();
    } catch (error) {
      handleOpenToast("Failed to load Razorpay SDK. Are you online?", "error");
    }
  };

  useEffect(() => {
    setAmountPayable(tripData?.transporterAmounts?.balancePayable);
  }, [tripData]);

  return (
    <Dialog open={isModalOpen} onClose={handleCloseModal} Width="625px">
      <DialogTitle sx={{ color: "#333" }}>
        <Typography fontWeight={700} fontSize={24}>
          Pay Transporter
        </Typography>
      </DialogTitle>
      <StyledCloseIcon fontSize="medium" onClick={handleCloseModal} />
      <DialogContent>
        <DialogBox>
          <Box sx={{ width: "100%" }}>
            <InputLabel
              sx={{
                color: "#333",
                fontSize: "1.125rem",
                fontWeight: 700,
              }}
              htmlFor="amount"
            >
              Enter Amount to Pay
            </InputLabel>
            <TextField
              name="amount"
              fullWidth
              variant="outlined"
              type="number"
              width="100%"
              error={!!error}
              helperText={error}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
              value={amountPayable}
              onChange={(e) => setAmountPayable(e.target.value)}
            />
          </Box>
        </DialogBox>
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <StyledButton
            variant="contained"
            color="primary"
            type="submit"
            onClick={razorPay}
          >
            Confirm & Pay
          </StyledButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AmountPayModal;

const DialogBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  mb: 2,
  gap: "20px",
  width: "500px",
}));

const StyledCloseIcon = styled(CloseIcon)(() => ({
  position: "absolute",
  right: "10px",
  top: "14px",
  cursor: "pointer",
}));

const StyledButton = styled(Button)(() => ({
  float: "right",
  padding: "10px 30px",
  fontSize: "1.2rem",
  margin: "20px 0",
}));
