import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Box, Typography } from "@mui/material";
import { DialogBtn } from "../Common/DialogBtn";
import { splitAndTruncateString } from "../../utils/textFormat";

export default function CloseEnquiryDialog({
  open,
  handleClose,
  enquiryDetails,
  handleCloseEnquiry,
}) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <Box p={7} sx={{ width: "600px" }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              style={{ fontSize: 24, fontWeight: 700, color: "#333333" }}
            >
              Do you want to close this enquiry?
            </Typography>
            <Typography
              style={{
                fontSize: 18,
                fontWeight: 700,
                color: "#333333",
                paddingTop: 8,
              }}
            >
              {`Enquiry ID #${enquiryDetails?.enquiryId}`}
            </Typography>
            <Box
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: "#4F4848",
                textAlign: "center",
                paddingTop: 12,
              }}
            >
              <Typography>
                Closing this enquiry will remove all the data, local
                <br />
                transporter won't get your enquiry
              </Typography>
            </Box>
            <Box
              display="flex"
              style={{ paddingTop: 14 }}
              justifyContent="center"
            >
              <Typography
                style={{ fontSize: 14, color: "#4F4848" }}
                dangerouslySetInnerHTML={{
                  __html: splitAndTruncateString(
                    enquiryDetails?.pickup?.location?.address,
                    24,
                    50
                  ),
                }}
              />
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                px={2}
              >
                <Typography style={{ fontSize: 12, color: "#4F4848" }}>
                  {`${enquiryDetails?.distance}km`}
                </Typography>
                <img src="/assets/icons/distanceArrow.svg" alt="distance" />
              </Box>
              <Typography
                style={{
                  fontSize: 14,
                  color: "#4F4848",
                  textAlign: "right",
                }}
                dangerouslySetInnerHTML={{
                  __html: splitAndTruncateString(
                    enquiryDetails?.dropoff?.location?.address,
                    27,
                    50
                  ),
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "center",
              paddingTop: 4,
            }}
          >
            <DialogBtn
              title="Close Enquiry"
              onClick={handleCloseEnquiry}
              buttonStyle={{
                color: "#FFFFFF",
                background: "#FF3F3F",
                "&:hover": {
                  backgroundColor: "#FF3F3F",
                  opacity: 0.8,
                },
              }}
            />
            <DialogBtn
              title="Cancel"
              onClick={handleClose}
              buttonStyle={{
                color: "#333333",
                border: "1px solid #333333",
              }}
            />
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
