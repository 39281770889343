import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { css } from '@emotion/react';

export const StyledDeliverySectionTextPrimary = styled(Typography)`
  font-size: 14px;
  color: #808080;
  text-align: left;
  margin-top: 8px;
  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}
`;
