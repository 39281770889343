import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  FormControl,
  Typography,
} from "@mui/material";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { useEffect, useState } from "react";
import EditCalendarRoundedIcon from "@mui/icons-material/EditCalendarRounded";
import {
  getVehicleRecommendation,
  updateEnquiry,
} from "../../api/Customer/enquiry";
import StyledSelectInput from "../../component/Common/StyledSelectInput";
import useToast from "../../hooks/useToast";
import { guestApi } from "../../api/config";
const labelStyle = { fontWeight: 700, fontSize: "1.125rem", color: "#333" };
const tempData = ["OPTION 1", "OPTION 2", "OPTION 3"];

const EnquiryDetailsForm = ({
  setSwitchComp,
  enquiryId,
  formData,
  setFormData,
  // vehicleList,
  // vehicleModels,
  materialList,
  timeSlots,
  materialOpted,
  setMaterialOpted,
}) => {
  // form - values state
  const [unit, setUnit] = useState(
    formData.weightUnit ? formData.weightUnit : "KG"
  );
  const { handleOpenToast, ToastMessage } = useToast();
  const [vehicleCategories, setVehicleCategories] = useState(null);
  const [vehicleType, setVehicleType] = useState(null);
  // state for enabling truck type input even when weight is emptied after entering once
  const [isTruckTypeEnabled, setIsTruckTypeEnabled] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(true);
  const [isVehicleCategoryOpen, setIsVehicleCategoryOpen] = useState(false);
  const [startTime, setStartTime] = useState(
    formData.slot ? formData.slot.split("-")[0] : null
  );

  const [newVehicleList, setNewVehicleList] = useState([]);

  const isNextBtnEnabled =
    formData.slot &&
    formData.weight &&
    (formData.vehicleCategory === "No Preference" || formData.vehicleCategory);

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;
    // logic for enabling next input once weight field value has been entered by user
    if (name === "weight") {
      if (!isTruckTypeEnabled) {
        setIsTruckTypeEnabled(true);
      }
      if (isNaN(value)) return;
    }
    if (name === "slot") {
      const selectedSlot = timeSlots.filter((slot) => slot.value === value);
      setStartTime(selectedSlot[0].startTime);
    }
    if (name === "quantity") {
      if (isNaN(value)) return;
    }
    if (name === "goodsType") {
      const selectedMaterial = materialList.find((e) => e.value === value);
      setMaterialOpted(selectedMaterial.material);
    }
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleForm = async (e) => {
    e.preventDefault();
    if (formData.quantity && parseInt(formData.quantity) > 1000000) {
      handleOpenToast(
        "For 'No of Pkgs' input field max. allowed number should not be more than 1000000",
        "error"
      );
      return;
    }
    if (parseInt(formData.quantity) === 0) {
      handleOpenToast(
        "For 'No of Pkgs' input field min. allowed number should not be less than 1",
        "error"
      );
      return;
    }
    if (formData.weight) {
      const weightValue =
        formData.weightUnit === "KG"
          ? parseInt(formData.weight)
          : 1000 * parseInt(formData.weight);
      // if (weightValue > 60000) {
      //   handleOpenToast(
      //     "Maximun allowed weight should not be more than 60 Tons or 60000 Kgs",
      //     "error"
      //   );
      //   return;
      // }
      if (weightValue < 300) {
        handleOpenToast(
          "Minimum allowed weight should not be less than 300 Kgs",
          "error"
        );
        return;
      }
    }

    let datePart = formData.date;
    let timePart = formData.slot.split("-")[0];
    let time24 = timePart.includes("AM")
      ? timePart.substring(0, 5)
      : (parseInt(timePart.substring(0, 2)) % 12) +
        12 +
        timePart.substring(2, 5);
    let dateTimeString = `${datePart}T${time24}+05:30`;
    let requestBody = {
      // goodsType: formData.goodsType,
      material: materialOpted,
      weight:
        formData.weightUnit === "KG" ? formData.weight : 1000 * formData.weight,
      quantity: formData.quantity,
      vehicleCategory: 1,
      pickupDate: new Date(dateTimeString).toISOString(),
      pickupSlot: formData.slot,
    };
    if (formData.vehicleCategory !== "No Preference") {
      requestBody = {
        ...requestBody,
        vehicleCategory: formData.vehicleCategory.split(" ")[0],
        vehicleCategoryId: newVehicleList.find(
          (type) => type.value === formData.vehicleCategory
        ).id,
      };
    }
    try {
      await updateEnquiry(requestBody, enquiryId);
      setSwitchComp(true);
    } catch (err) {
      handleOpenToast("Something went wrong.", "error");
    }
  };

  useEffect(() => {
    if (formData.goodsType && formData.weight) {
      const weightInput =
        formData.weightUnit === "KG" ? formData.weight : 1000 * formData.weight;
      getVehicleRecommendation(materialOpted.categoryId, weightInput)
        .then((data) => {
          const res = data.data.data.map((vehicle) => {
            return {
              value: `${vehicle.numberOfWheels} wheelers (${
                vehicle.minCapacity / 1000
              }T - ${vehicle.maxCapacity / 1000}T)`,
              id: vehicle.vehicleCategoryId,
            };
          });
          setNewVehicleList(res);
        })
        .catch((err) => {
          handleOpenToast("Something went wrong!", "error");
        });
    }
  }, [formData.weightUnit, formData.weight, formData.goodsType]);

  return (
    <>
      <ToastMessage />
      <WarningBoxModal
        vehicleCategory={formData.vehicleCategory}
        isWarningModalOpen={isWarningModalOpen}
        setIsWarningModalOpen={setIsWarningModalOpen}
        setIsVehicleCategoryOpen={setIsVehicleCategoryOpen}
      />
      <form onSubmit={handleForm}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledSelectInput
              label="Material Name"
              name="goodsType"
              value={formData.goodsType}
              id={"goods"}
              onChange={handleFormDataChange}
              items={materialList}
            />
          </Grid>

          {formData.goodsType && (
            <Grid item xs={6}>
              <Stack>
                <InputLabel shrink sx={labelStyle}>
                  Total Weight
                </InputLabel>
                <Stack direction="row" spacing={2}>
                  <TextField
                    name="weight"
                    placeholder="Enter Weight"
                    autoComplete="off"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height: "2.75rem",
                        paddingRight: 0,
                      },
                    }}
                    fullWidth
                    value={formData.weight}
                    onChange={handleFormDataChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Select
                            value={formData.weightUnit}
                            onChange={(e) => {
                              setFormData((p) => ({
                                ...p,
                                weightUnit: e.target.value,
                              }));
                            }}
                            sx={{
                              width: "100px",
                              borderColor: "red",
                              "& .MuiOutlinedInput-notchedOutline , &.Mui-focused .MuiOutlinedInput-notchedOutline ,& .MuiOutlinedInput-root":
                                {
                                  borderColor: "#EAEAEA !important",
                                },
                            }}
                          >
                            <MenuItem value="KG">KG</MenuItem>
                            <MenuItem value="TONS">TONS</MenuItem>
                          </Select>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </Stack>
            </Grid>
          )}

          {formData.goodsType && (
            <Grid item xs={6}>
              <Stack>
                <InputLabel shrink sx={labelStyle}>
                  No of Pkgs
                </InputLabel>
                <TextField
                  name="quantity"
                  value={formData.quantity}
                  id={"quantity"}
                  onChange={handleFormDataChange}
                  fullWidth
                  placeholder="Enter No of Pkgs."
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "2.75rem",
                      paddingRight: 0,
                    },
                  }}
                />
              </Stack>
            </Grid>
          )}

          {(formData.weight || isTruckTypeEnabled) && (
            <Grid item xs={6}>
              {/* <VehicleCategorySelect
                value={formData.vehicleCategory}
                onChange={handleFormDataChange}
                items={newVehicleList}
                isVehicleCategoryOpen={isVehicleCategoryOpen}
              /> */}
              <PreSelectedCategory
                data={newVehicleList}
                formData={formData}
                setFormData={setFormData}
                selectedValue={formData.vehicleCategory}
                // onChange={handleFormDataChange}
              />
            </Grid>
          )}

          {/* {formData.vehicleCategory && (
            <Grid item xs={6}>
              <StyledSelectInput
                label="Vehicle Name"
                name="vehicleName"
                value={formData.vehicleName}
                onChange={handleFormDataChange}
                items={vehicleModels}
              />
            </Grid>
          )} */}

          {
            // formData.vehicleName ||
            (formData.vehicleCategory ||
              // &&
              // formData.vehicleName === "" &&
              // formData.date
              formData.vehicleCategory === "No Preference") && (
              // &&!formData.vehicleName
              <Grid item xs={6}>
                <Stack>
                  <InputLabel shrink sx={labelStyle}>
                    Pickup Date
                  </InputLabel>
                  <DateInput formData={formData} setFormData={setFormData} />
                </Stack>
              </Grid>
            )
          }

          {(formData.vehicleCategory ||
            formData.vehicleCategory === "No Preference") && (
            <Grid item xs={6}>
              <StyledSelectInput
                label="Pickup Time Slot"
                disabled={!formData.date}
                name="slot"
                value={formData.slot}
                onChange={handleFormDataChange}
                items={timeSlots}
              />
            </Grid>
          )}
        </Grid>
        <Stack mt={4} direction="row" justifyContent="center">
          {isNextBtnEnabled && (
            <Button
              color="primary"
              variant="contained"
              type="submit"
              sx={{ padding: ".5rem 1.5rem" }}
            >
              NEXT
            </Button>
          )}
        </Stack>
      </form>
    </>
  );
};

export default EnquiryDetailsForm;

const VehicleCategorySelect = ({
  items,
  onChange,
  value = "",
  isVehicleCategoryOpen,
}) => {
  const [open, setOpen] = useState(isVehicleCategoryOpen);

  const handleSelectClick = () => {
    setOpen(!open);
  };

  const handleSelectClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <InputLabel shrink sx={labelStyle}>
        Vehicle Category
      </InputLabel>
      <Select
        open={open}
        onClose={handleSelectClose}
        onOpen={handleSelectClick}
        onClick={handleSelectClick}
        value={value}
        onChange={onChange}
        name="vehicleCategory"
        id="open"
        fullWidth
        sx={{
          color: value ? "#333" : "#ADADAD",
          height: "2.75rem",
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ADADAD",
          },
        }}
        displayEmpty
      >
        <MenuItem disabled value="" sx={{ display: "none" }}>
          Select
        </MenuItem>
        {items
          ? items.map((e) => (
              <MenuItem key={e.id} value={e.value}>
                {e.value}
              </MenuItem>
            ))
          : tempData.map((e) => {
              return <MenuItem value={e}>{e}</MenuItem>;
            })}
      </Select>
    </div>
  );
};

const WarningBoxModal = ({
  vehicleCategory,
  isWarningModalOpen,
  setIsWarningModalOpen,
  setIsVehicleCategoryOpen,
}) => {
  return (
    <Dialog
      open={vehicleCategory === "No preference (any)" && isWarningModalOpen}
    >
      <Box width={500} height={350} textAlign="center" p="1rem 2rem">
        <WarningAmberRoundedIcon
          sx={{ color: "#F28929", fontSize: "3.75rem" }}
        />
        <DialogTitle>Truck Selection Required</DialogTitle>
        <DialogContent>
          <DialogContentText>
            For an accurate fare estimate, select a truck type. If not, the fare
            will be shown once offers are received.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setIsWarningModalOpen(false);
              // logic to open dropdown
              setIsVehicleCategoryOpen(true);
              document.getElementById("open").click();
            }}
          >
            Select Preferred Vehicle
          </Button>
          <Button
            onClick={() => setIsWarningModalOpen(false)}
            sx={{ textTransform: "none" }}
          >
            Proceed without vehicle
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
// customized date input with date picker
const DateInput = ({ formData, setFormData }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        slots={{
          openPickerIcon: EditCalendarRoundedIcon,
        }}
        value={moment(formData.date)}
        minDate={moment(new Date())}
        maxDate={moment().add(3, "months")}
        disablePast
        format="DD/MM/YYYY"
        defaultValue={new Date()}
        sx={{
          "& .MuiOutlinedInput-root": {
            height: "2.75rem",
            "&.Mui-error": {
              "& fieldset": {
                borderColor: "#c6c6c6",
              },
            },
          },
        }}
        name="date"
        onChange={(newValue) => {
          const dateStr = newValue.format("YYYY-MM-DD");
          setFormData((p) => ({ ...p, date: dateStr }));
        }}
        s
      />
    </LocalizationProvider>
  );
};

const PreSelectedCategory = ({
  data,
  formData,
  setFormData,
  selectedValue,
}) => {
  const [isRecommended, setIsRecommended] = useState(false);
  // const [value, setValue] = useState('');

  // Effect hook to update the value based on the data input
  useEffect(() => {
    // if(value) setValue(value)
    // if(!selectedValue){
    if (data.length > 0) {
      setFormData((p) => ({ ...p, vehicleCategory: data[0].value }));
      setIsRecommended(true);
    } else {
      setFormData((p) => ({ ...p, vehicleCategory: "No Preference" }));
      setIsRecommended(false);
    }
    // }
  }, [data]); // Re-run the effect when data changes

  const handleChange = (event) => {
    // setValue(event.target.value);
    setFormData((p) => ({ ...p, vehicleCategory: event.target.value }));
    setIsRecommended(data.length > 0 && event.target.value === data[0].value);
  };

  return (
    <div>
      {/* <InputLabel id="demo-simple-select-label">Select</InputLabel> */}
      <InputLabel shrink sx={labelStyle}>
        Vehicle Category
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        value={formData.vehicleCategory}
        onChange={handleChange}
        fullWidth
        name="vehicleCategory"
        sx={{
          color: "#333",
          height: "2.75rem",
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ADADAD",
          },
        }}
      >
        {data.length > 0 ? (
          data.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.value}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="No Preference">No Preference</MenuItem>
        )}
      </Select>
      {isRecommended && (
        <Typography
          color="green"
          style={{ fontSize: 12, marginTop: 0, marginLeft: 8, fontWeight: 700 }}
        >
          Recommended
        </Typography>
      )}
    </div>
  );
};
