import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Box, Stack, TablePagination, TextField } from "@mui/material";
import styled from "@emotion/styled";
import TripsTable from "./TripsTable";
import { getAllTransporterTrips } from "../../../api/Transporter/transporterTrips";
import { LOADING_STATUS } from "../../../constants/constant";
import useToast from "../../../hooks/useToast";
import SearchIcon from "@mui/icons-material/Search";
import ErrorScreen from "../../Common/ErrorScreen";

const StyledBox = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 125px)",
  padding: "0px 15px 0px 15px",
  overflow: "auto",
}));

export default function TransporterTrips() {
  const [trips, setTrips] = useState([]);
  const [page, setPage] = useState(0);
  const [paginationData, setPaginationData] = useState({});
  const [tripStatus, setTripStatus] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isTripsListFetched, setIsTripsListFetched] = useState(
    LOADING_STATUS.INITIAL
  );
  const [debouncedSearchText, setDebouncedSearchText] = useState("");

  const transporterId = useSelector((state) => state.transporterId.id);

  const { handleOpenToast } = useToast();

  const transporterTrips = async () => {
    try {
      setIsTripsListFetched(LOADING_STATUS.LOADING);
      const response = await getAllTransporterTrips(
        transporterId,
        page,
        tripStatus,
        paymentStatus,
        debouncedSearchText
      );

      if (response?.status === 200) {
        setTrips(response.data.data.trips);
        setPaginationData(response.data.data.pagination);
        setIsTripsListFetched(LOADING_STATUS.LOADED);
      } else {
        setIsTripsListFetched(LOADING_STATUS.FAILED);
      }
    } catch (error) {
      setIsTripsListFetched(LOADING_STATUS.FAILED);
    }
  };

  useEffect(() => {
    if (transporterId) {
      transporterTrips();
    }
  }, [transporterId, page, tripStatus, paymentStatus, debouncedSearchText]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = () => {
    setPage(0);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);

  return (
    <Box height="100%">
      <Stack p="1rem 1.625rem" borderBottom="1px solid #ADADAD">
        <TextField
          variant="outlined"
          placeholder="Search"
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          InputProps={{
            sx: { height: "36.31px", width: "100%" },
            endAdornment: <SearchIcon />,
          }}
        />
      </Stack>

      <StyledBox>
        <TripsTable
          trips={trips}
          tripStatus={tripStatus}
          setTripStatus={setTripStatus}
          paymentStatus={paymentStatus}
          setPaymentStatus={setPaymentStatus}
          isTripsListFetched={isTripsListFetched}
          transporterTrips={transporterTrips}
          transporterId={transporterId}
          setTrips={setTrips}
        />
      </StyledBox>

      {trips?.length >= 1 && (
        <Box borderTop="1px solid #adadad">
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={paginationData.totalItems}
            rowsPerPage={paginationData.pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ count }) => (
              <Box>
                Page{" "}
                <span style={{ color: "green", fontWeight: 700 }}>
                  {page + 1}{" "}
                </span>
                of {Math.ceil(count / paginationData.pageSize)}
              </Box>
            )}
          />
        </Box>
      )}
    </Box>
  );
}
