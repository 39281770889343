import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import Sidebar from "../component/Sidebar/Sidebar";
import { Outlet, useNavigate } from "react-router";
import { Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Cookies from "js-cookie";
import {
  AppBar,
  CircularProgress,
  CssBaseline,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { customerRoutes } from "../routes/customerRoutes";
import useToast from "../hooks/useToast";
import { setCustomerProfile } from "../redux/slices/customerSlice";
import { getCustomerProfile } from "../api/Customer/enquiry";
import { LOADING_STATUS } from "../constants/constant";
import GetInTouch from "../component/Common/GetInTouch";
import ErrorScreen from "../component/Common/ErrorScreen";

import { getToken } from "firebase/messaging";
import { messaging, onMessageListener } from "../firebase";
import NotificationPopup from "../component/NotificationPopup";
import { updateCustomerFCMToken } from "../api/fcmToken";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
const CustomerLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isFavoritesPage = location.pathname.includes("favorites");
  const { handleOpenToast, ToastMessage } = useToast();

  const [isProfileFetched, setIsProfileFetched] = useState("");
  const [isTokenFound, setTokenFound] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isSnackbarOpen, setisSnackbarOpen] = useState(false);

  const number = useSelector((state) => state.supportNumbers?.customerPortal);
  const name = useSelector((state) => state.customer.name);
  const token = Cookies.get("customertoken");
  const fcmToken = sessionStorage.getItem("customerfcmToken");

  // background notification listener
  onMessageListener()
    .then((payload) => {
      // whenever new notification arrives ,store it in state.
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      // turn on Notification Popup
      setisSnackbarOpen(true);
    })
    .catch((err) => {
      handleOpenToast("Something went wrong. Please refresh page !", "error");
      console.log("failed: ", err);
    });

  const generateFCMToken = async () => {
    try {
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      });
      return token;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const updateFCMToken = async () => {
    const fcmtoken = await generateFCMToken();
    if (fcmtoken) {
      // add fcm token to user document
      try {
        await updateCustomerFCMToken(fcmtoken);
        sessionStorage.setItem("customerfcmToken", JSON.stringify(fcmtoken));
        setTokenFound(true);
      } catch (err) {
        console.error(err);
      }
    }
  };

  async function requestUserPermission() {
    if (fcmToken) {
      return;
    }
    if (Notification.permission === "denied") {
      alert(
        "Notifications are blocked. Please enable them in your browser settings."
      );
    } else if (Notification.permission === "default") {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        console.log("Notification permission granted.");
        // You can now send notifications
        updateFCMToken();
      }
    } else if (Notification.permission === "granted") {
      updateFCMToken();
    }
  }

  const fetchCustomerProfile = async () => {
    setIsProfileFetched(LOADING_STATUS.LOADING);
    try {
      const response = await getCustomerProfile();
      if (response.status === 200) {
        dispatch(setCustomerProfile(response.data.customer));
        setIsProfileFetched(LOADING_STATUS.LOADED);
        return true;
      } else {
        setIsProfileFetched(LOADING_STATUS.FAILED);
        handleOpenToast("Something went wrong. Please refresh page !", "error");
        return false;
      }
    } catch (err) {
      setIsProfileFetched(LOADING_STATUS.FAILED);
      handleOpenToast("Something went wrong. Please refresh page !", "error");
      return false;
    }
  };

  const renderOutlet = () => {
    if (isProfileFetched === LOADING_STATUS.LOADING) {
      <Box display="flex" justifyContent="center" alignItems="center" p={5}>
        <CircularProgress />
      </Box>;
    } else if (isProfileFetched === LOADING_STATUS.LOADED) {
      return <Outlet />;
    } else if (isProfileFetched === LOADING_STATUS.FAILED) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          <ErrorScreen onRetry={fetchCustomerProfile} />;
        </Box>
      );
    }
  };

  useEffect(() => {
    if (fetchCustomerProfile()) {
      requestUserPermission();
    }
  }, []);

  // if token is present means user is authorized and can access app
  if (!token || token === undefined) {
    return <Navigate to="/" replace />;
  }

  return (
    <Box>
      <NotificationPopup
        isOpen={isSnackbarOpen}
        header={notification.title}
        message={notification.body}
        setisSnackbarOpen={setisSnackbarOpen}
      />
      <ToastMessage />
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          boxShadow: "none",
          background: "#fff",
          borderBottom: "1px solid #adadad",
        }}
      >
        <Toolbar sx={{ height: "6rem" }}>
          <Stack direction="row" alignItems="center" spacing="1.25rem">
            <img
              src="/assets/icons/LZ-logo-face.svg"
              style={{ marginBottom: ".35rem" }}
              alt="logoface"
            />
            <img src="/assets/icons/LZ-logo.svg" alt="logo" />
          </Stack>

          <Box
            sx={{
              marginLeft: "auto",
              display: "flex",
              gap: "2rem",
              alignItems: "center",
            }}
          >
            <Box
              bgcolor={isFavoritesPage && "#DDE7EF"}
              display="flex"
              alignItems="center"
              borderRadius="5px"
              p="2px"
              sx={{ cursor: "pointer" }}
            >
              <StarOutlineIcon
                onClick={() => {
                  navigate("favorites");
                }}
                sx={{
                  fill: isFavoritesPage ? "#333" : "#6D6D6D",
                  width: "26px",
                  height: "26px",
                }}
              />
            </Box>
            <NotificationsActiveOutlinedIcon
              sx={{
                color: "#333",
                background: location.pathname.includes("customer/notification")
                  ? "#DDE7EF"
                  : "#fff",
                cursor: "pointer",
                borderRadius: 1,
                fontSize: 26,
              }}
              onClick={() => {
                navigate("notifications");
              }}
            />
            <Box>
              <Typography color="#333" fontWeight={700}>
                {name ? name : "Guest"}
              </Typography>
              <Typography color="#0C7F44">Customer</Typography>
            </Box>
            <Box>
              <GetInTouch />
              <Typography color="#0C7F44" fontWeight={700} align="right">
                {number}
              </Typography>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Box sx={{ display: "flex" }}>
        {!location.pathname.endsWith("customer/notifications") && (
          <Sidebar routes={customerRoutes} />
        )}
        <Box mt="6rem" sx={{ flexGrow: 1 }}>
          {/* <Toolbar sx={{ height: "6rem" }} /> */}
          {renderOutlet()}
        </Box>
      </Box>
    </Box>
  );
};
export default CustomerLayout;
