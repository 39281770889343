import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

const confirmationModalParagraphText = {
  fontWeight: 400,
  fontSize: 18,
  color: "#333",
};

const confirmationModalTextHeader = {
  color: "#333",
  fontWeight: 700,
  fontSize: 24,
  paddingBottom: "8px",
};

const TruckChangeConfirmationModal = ({
  closeTruckChangeConfirmationModal,
}) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "white",
        p: 4,
        borderRadius: 4,
        width: "600px",
        height: "400px",
      }}
    >
      <Typography sx={confirmationModalTextHeader}>
        Confirmation Required
      </Typography>
      <Typography sx={confirmationModalParagraphText}>
        Are you sure you want to unlink the driver from the currently assigned
        truck and reassign the driver to the newly selected truck?
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "40px",
          gap: "30px",
        }}
      >
        <Typography sx={confirmationModalParagraphText}>
          Current Asssigned Vehicle : <b>KA 2505 AL</b>
        </Typography>
        <Typography
          sx={{
            ...confirmationModalParagraphText,
            background: "#DDEFDD",
            padding: "8px",
          }}
        >
          New Assigned Vehicle : <b>KA 2505 AL</b>
        </Typography>
        <Box sx={{ gap: "16px" }}>
          <Button
            variant="contained"
            onClick={closeTruckChangeConfirmationModal}
          >
            Cancel
          </Button>
          <Button variant="contained">Confirm</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default TruckChangeConfirmationModal;
