import axios from "axios";
import Cookies from "js-cookie";

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const local_url = "http://localhost:8000/api/v1/";
// initialized axios instance
const client = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const adminApi = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const customerApi = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
const guestApi = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

adminApi.interceptors.request.use(
  (config) => {
    const token = Cookies.get("admintoken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
client.interceptors.request.use(
  (config) => {
    const token = Cookies.get("transportertoken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
customerApi.interceptors.request.use(
  (config) => {
    const token = Cookies.get("customertoken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { adminApi, client, customerApi, guestApi };

// const prod = {
//   BASE_URL: "https://api.prod.loadeazy.com/api/v1/",
// };

// const dev = {
//   BASE_URL: "http://api.dev.loadeazy.com/api/v1/",
// };

// export const config = process.env.NODE_ENV === "development" ? dev : prod;
