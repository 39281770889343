import React, { useState } from "react";
import {
  Box,
  TextField,
  MenuItem,
  IconButton,
  Grid,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const Timer = ({ title, day, hour, minute, second, onSave }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [timeValues, setTimeValues] = useState({
    days: day || "0",
    hours: hour || "0",
    minutes: minute || "0",
    seconds: second || "0",
  });

  const handleTimeChange = (type) => (event) => {
    setTimeValues({ ...timeValues, [type]: event.target.value });
  };

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  const handleSave = () => {
    // save values that are not undefined
    let values = {};
    if (day !== undefined) {
      values = { ...values, d: +timeValues.days };
    }
    if (hour !== undefined) {
      values = { ...values, hr: +timeValues.hours };
    }
    if (minute !== undefined) {
      values = { ...values, min: +timeValues.minutes };
    }
    if (second !== undefined) {
      values = { ...values, sec: +timeValues.seconds };
    }

    // send the values to the parent component
    onSave(values);
  };

  const renderTimeInput = (label, value, type) => {
    const timeOptions = {
      days: 31,
      hours: 24,
      minutes: 60,
      seconds: 60,
    };

    // Check if the prop (day, hour, minute, second) is true
    if (type === "days" && day === undefined) return null;
    if (type === "hours" && hour === undefined) return null;
    if (type === "minutes" && minute === undefined) return null;
    if (type === "seconds" && second === undefined) return null;

    return (
      <TextField
        select
        label={label}
        value={value}
        onChange={handleTimeChange(type)}
        variant="outlined"
        size="small"
        fullWidth
        disabled={!isEditable}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          },
        }}
      >
        {[...Array(timeOptions[type]).keys()].map((option) => (
          <MenuItem key={option} value={option.toString()}>
            {option.toString()}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  return (
    <>
      <Box
        sx={{
          p: 2,
          width: "100%",
          borderRadius: "4px",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Typography
          sx={{
            color: "#333",
            fontSize: "14px",
            fontWeight: 700,
            width: "content",
            marginBottom: "10px",
          }}
        >
          {title}
        </Typography>
        <Grid container spacing={2} alignItems="center">
          {day !== undefined && (
            <Grid item xs={2}>
              {renderTimeInput("Days", timeValues.days, "days")}
            </Grid>
          )}
          {hour !== undefined && (
            <Grid item xs={2}>
              {renderTimeInput("Hours", timeValues.hours, "hours")}
            </Grid>
          )}
          {minute !== undefined && (
            <Grid item xs={2}>
              {renderTimeInput("Minutes", timeValues.minutes, "minutes")}
            </Grid>
          )}
          {second !== undefined && (
            <Grid item xs={2}>
              {renderTimeInput("Seconds", timeValues.seconds, "seconds")}
            </Grid>
          )}
          {isEditable ? (
            <Grid item xs={2}>
              <Button onClick={handleSave} variant="contained" color="primary">
                Save
              </Button>
            </Grid>
          ) : (
            <Grid item xs={2}>
              <IconButton onClick={toggleEdit}>
                <EditIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Box>
      <Divider />
    </>
  );
};

export default Timer;
