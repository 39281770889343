import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import NumberVerificationScreen from "../NumberVerificationScreen";
import OTPVerificationScreen from "../OTPVerificationScreen";
import { Box } from "@mui/material";

export default function LoginDialog({
  isLoginModalOpen,
  handleCloseLoginModal,
  stakeholder,
  isVerified=false
}) {

  const [isOTPReceived, setIsOTPReceived] = useState(null);

  useEffect(() => {
    setIsOTPReceived(isVerified)
    return () => {
      setIsOTPReceived(false);
    };
  }, [isLoginModalOpen]);

  return (
    <Dialog open={isLoginModalOpen}>
      <Box sx={{ width: "31rem", height: "23rem" }}>
        {!isOTPReceived ? (
          <NumberVerificationScreen
            setIsOTPReceived={setIsOTPReceived}
            handleCloseLoginModal={handleCloseLoginModal}
            stakeholder={stakeholder}
          />
        ) : (
          <OTPVerificationScreen
            handleCloseLoginModal={handleCloseLoginModal}
            stakeholder={stakeholder}
          />
        )}
      </Box>
    </Dialog>
  );
}
