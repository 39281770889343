import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateTransporterNotificationPreferences } from "../../../../api/Transporter/notification";
import { setTransporterNotificationPreference } from "../../../../redux/slices/transporterSlice";
import { transporterPreferenceOrderTexts } from "../../../../constants/util";

export default function TransporterNotificationPreference() {
  const transporterNotificationValues = useSelector(
    (state) => state.transporter?.transporterNotificationPreferences
  );

  const dispatch = useDispatch();
  const [transporterNotificationDetails, setTransporterNotificationDetails] =
    useState([]);

  useEffect(() => {
    if (transporterNotificationValues) {
      const transformedPreferences = transporterPreferenceOrderTexts.map(
        (key) => ({
          text: getTextRepresentation(key),
          status: transporterNotificationValues[key],
        })
      );
      setTransporterNotificationDetails(transformedPreferences);
    }
  }, [transporterNotificationValues]);

  const getTextRepresentation = (key) => {
    const textMappings = {
      enquiry: "Enquiry received notification",
      bid: "Bid creation notification",
      trip: "Trip notification",
      payment: "Payment received notification",
      kyc: "KYC verification notification",
    };
    return textMappings[key] || "";
  };

  const updateTransporterNotificationPreference = async (text, checked) => {
    const textMappings = {
      "Enquiry received notification": "enquiry",
      "Bid creation notification": "bid",
      "Trip notification": "trip",
      "Payment received notification": "payment",
      "KYC verification notification": "kyc",
    };
    const updatedPreferences = {
      ...transporterNotificationValues,
      [textMappings[text]]: checked,
    };
    try {
      const requestBody = { notifications: updatedPreferences };
      const response = await updateTransporterNotificationPreferences(
        requestBody
      );
      dispatch(
        setTransporterNotificationPreference(
          response?.data?.transporter?.notificationPreferences
        )
      );
    } catch (error) {
      console.error(
        "Error updating transporter notification preference:",
        error
      );
    }
  };

  return (
    <Box p={3}>
      <FormControl
        component="fieldset"
        variant="standard"
        sx={{ width: "90%" }}
      >
        <FormGroup sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {transporterNotificationDetails.map((preference, index) => (
            <>
              <FormControlLabel
                labelPlacement="start"
                key={index}
                control={
                  <Switch
                    sx={{ marginLeft: "auto" }}
                    color="success"
                    checked={preference.status}
                    onChange={() =>
                      updateTransporterNotificationPreference(
                        preference.text,
                        !preference.status
                      )
                    }
                    name={preference.text}
                  />
                }
                label={preference.text}
              />
              <Divider />
            </>
          ))}
        </FormGroup>
      </FormControl>
    </Box>
  );
}
