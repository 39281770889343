import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { Box, Stack } from "@mui/system";
import { Avatar, Grid, Typography } from "@mui/material";
import { getKycDetailById } from "../../api/Admin/transporterResources";

import moment from "moment";
import {
  getCustomerById,
  updateCustomerPremium,
  updateCustomerStatus,
} from "../../api/Admin/customerResources";
import CustomerTransaction from "./CustomerTransaction";
import useToast from "../../hooks/useToast";
import CustomerTripsTable from "./CustomerTripsTable";
import styled from "@emotion/styled";
import ToggleButton from "../Common/ToggleButton";

const CustomerDetailsView = () => {
  const navigate = useNavigate();

  const [panelExpanded, setPanelExpanded] = useState(null);
  const updateExpandedPanel = (panel) => (event, isExpanded) => {
    setPanelExpanded(isExpanded ? panel : false);
  };

  const { id } = useParams();

  const [customerDetails, setCustomerDetails] = useState({});

  const [customerKycDetails, setCustomerKycDetails] = useState({});

  const { handleOpenToast, ToastMessage } = useToast();

  const [isCustomerActive, setIsCustomerActive] = useState(
    customerDetails?.customerStatus === "ACTIVE" ? true : false
  );

  const [isCustomerPremium, setIsCustomerPremium] = useState(
    customerDetails?.isPremium ? true : false
  );

  const handleCustomerStatus = async () => {
    try {
      const newStatus = isCustomerActive ? "BLOCKED" : "ACTIVE";
      const response = await updateCustomerStatus(id, newStatus);
      if (response.status === 200) {
        handleOpenToast(
          `Customer ${isCustomerActive ? "Blocked" : "Activated"} successfully`,
          "success"
        );
      }
    } catch (error) {
      handleOpenToast("Error while updating customer status", "error");
    }
  };

  const handleCustomerPremium = async () => {
    try {
      const response = await updateCustomerPremium(id, !isCustomerPremium);
      if (response.status === 200) {
        handleOpenToast(
          `Customer ${
            isCustomerPremium ? "Premium" : "Not Premium"
          } status updated successfully`,
          "success"
        );
      }
    } catch (error) {
      handleOpenToast("Error while updating customer premium status", "error");
    }
  };

  const onToggle = async () => {
    setIsCustomerActive(!isCustomerActive);
    handleCustomerStatus();
  };
  const onTogglePremium = async () => {
    setIsCustomerPremium(!isCustomerPremium);
    handleCustomerPremium();
  };

  const getCustomerDetails = async () => {
    try {
      const response = await getCustomerById(id);
      if (response.status === 200) {
        setCustomerDetails(response.data.data);
      }
    } catch (error) {
      handleOpenToast("Error while getting customer details", "error");
    }
  };

  const getCustomerKycDetail = async (kycId) => {
    try {
      const response = await getKycDetailById(kycId);
      if (response.status === 200) {
        setCustomerKycDetails(response.data.data);
      }
    } catch (error) {
      handleOpenToast("Error while getting customer kyc details", "error");
    }
  };

  useEffect(() => {
    getCustomerDetails();
    setIsCustomerActive(
      customerDetails?.customerStatus === "ACTIVE" ? true : false
    );

    setIsCustomerPremium(customerDetails?.isPremium ? true : false);
  }, [id, customerDetails?.customerStatus]);

  useEffect(() => {
    if (customerDetails.kycId) {
      getCustomerKycDetail(customerDetails.kycId);
    }
  }, [customerDetails.kycId]);

  const formattedBusinessAddress = (businessAddress) => {
    const { addressLine1, addressLine2, city, state } = businessAddress || {};
    return [addressLine1, addressLine2, city, state].filter(Boolean).join(", ");
  };
  return (
    <Box>
      <ToastMessage />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ background: "#EAEAEA" }}
        p=".5rem 1.5rem"
      >
        <img
          alt="back"
          src="/assets/icons/backArrow.svg"
          style={{ cursor: "pointer", width: "24px", height: "20px" }}
          onClick={() => navigate(-1)}
        />
        <Typography color="#333" fontWeight={700} fontSize="1.25rem">
          {customerDetails?.fullName || ""}
        </Typography>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <StatusAndToggle>
            <ToggleButton toggledState={isCustomerActive} onToggle={onToggle} />
            <StatusTypography
              sx={{ color: isCustomerActive ? "#00B87C" : "#FF0000" }}
            >
              {isCustomerActive ? "Active" : "Blocked"}
            </StatusTypography>
          </StatusAndToggle>
          <StatusAndToggle>
            <ToggleButton
              toggledState={isCustomerPremium}
              onToggle={onTogglePremium}
            />
            <StatusTypography
              sx={{ color: isCustomerPremium ? "#00B87C" : "#FF0000" }}
            >
              {isCustomerPremium ? "Premium" : "Not Premium"}
            </StatusTypography>
          </StatusAndToggle>
        </Box>
      </Stack>

      <Box p="1rem 2rem">
        {/* Customer Details */}
        <Stack direction="row">
          <Typography sx={{ fontWeight: 700, color: "#333", fontSize: "1rem" }}>
            Customer Details
          </Typography>
          <div style={{ borderBottom: "1px solid #6D6D6D", flexGrow: 1 }}></div>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={4} mt={2} pl={2}>
          {customerDetails?.profilePhoto ? (
            <img
              src={customerDetails.profilePhoto}
              alt="Profile"
              style={{ width: "112px", height: "112px", borderRadius: "50%" }}
            />
          ) : (
            <Avatar sx={{ width: "112px", height: "112px" }} />
          )}
          <Grid container spacing={2}>
            <Grid item sm={12} md={5}>
              <Stack direction="column" gap={0.75}>
                <Typography fontSize="1rem">
                  Name: <b>{customerDetails?.fullName || "-"}</b>
                </Typography>

                <Typography>
                  Mobile No.: <b>{customerDetails?.phone || "-"}</b>
                </Typography>

                <Typography>
                  Email: <b>{customerDetails?.email || "-"}</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item sm={12} md={7} spacing={2}>
              <Stack direction="column" gap={0.75}>
                <Typography fontSize="1rem">
                  Onboarding Date & Time:{" "}
                  <b>
                    {moment(customerDetails?.createdAt).format(
                      "DD-MM-YYYY hh:mm A"
                    ) || "-"}
                  </b>
                </Typography>
                <Typography fontSize="1rem">
                  Company Name: <b>{customerDetails?.businessName || "-"}</b>
                </Typography>
                <Typography fontSize="1rem">
                  Address:{" "}
                  {
                    <b>
                      {formattedBusinessAddress(
                        customerDetails?.businessAddress
                      ) || "-"}
                    </b>
                  }
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>

        {/* Documents Details */}
        <Stack direction="row" mt={3}>
          <Typography sx={{ fontWeight: 700, color: "#333", fontSize: "1rem" }}>
            Documents Details
          </Typography>
          <div style={{ borderBottom: "1px solid #6D6D6D", flexGrow: 1 }}></div>
        </Stack>
        <Stack direction="row" spacing={16} mt={2} pl={2}>
          <Grid item xs={6} sm={3}>
            <Typography>
              Aadhaar Number:{" "}
              <b>{customerKycDetails?.aadharDetails?.documentNumber || "-"}</b>
            </Typography>
            <Typography sx={{ mt: 2 }}>
              PAN Number:{" "}
              <b>{customerKycDetails?.panDetails?.documentNumber || "-"}</b>
            </Typography>
          </Grid>
          <Grid Grid item sm={12} md={7}>
            <Typography>
              GSTIN/UIN Number:{" "}
              <b>{customerKycDetails?.gstDetails?.licenceNumber || "-"}</b>
            </Typography>

            <Typography sx={{ mt: 2 }}>
              Shop Act License Number:{" "}
              <b>
                {customerKycDetails?.businessLicenceDetails?.licenceNumber ||
                  "-"}
              </b>
            </Typography>
          </Grid>
          <Grid item sm={12} md={7}>
            <Typography>
              Verification Status:{" "}
              <b
                style={{
                  color:
                    customerKycDetails?.profileStatus === "APPROVED"
                      ? "green"
                      : "red",
                }}
              >
                {customerKycDetails?.profileStatus === "APPROVED"
                  ? "Approved"
                  : "Pending"}
              </b>
            </Typography>
          </Grid>
        </Stack>

        <Stack direction="column" gap={2} mt={2}>
          <CustomerTripsTable
            panelExpanded={panelExpanded}
            updateExpandedPanel={updateExpandedPanel}
            id={id}
          />
          <CustomerTransaction
            panelExpanded={panelExpanded}
            updateExpandedPanel={updateExpandedPanel}
            id={id}
            customerName={customerDetails?.fullName}
            customerPhone={customerDetails?.phone}
            handleOpenToast={handleOpenToast}
            amount={customerDetails?.amount}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default CustomerDetailsView;

const StatusAndToggle = styled(Box)({
  display: "flex",
  gap: "10px",
});

const StatusTypography = styled(Typography)({
  fontSize: "14px",
  fontWeight: 700,
});
