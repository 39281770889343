import React from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";

const BoxContainer = styled(Box)(() => ({
  display: "flex",
  gap: "10px",
  padding: "14px 0px",
}));
const HeaderBox = styled(Box)(() => ({
  fontSize: "16px",
  fontWeight: 700,
  textTransform: "capitalize",
}));

const UnderlinedBox = styled(Box)(() => ({
  borderBottom: "2px solid #6D6D6D",
  display: "flex",
  flex: "auto",
}));

export default function HeaderWithUnderline(props) {
  return (
    <BoxContainer>
      <HeaderBox>{props.header}</HeaderBox>
      <UnderlinedBox />
    </BoxContainer>
  );
}
