import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Popover,
  Stack,
  Typography,
  checkboxClasses,
} from "@mui/material";

const popOverButtonsStyle = {
  color: "#333",
  textTransform: "capitalize",
  fontSize: "12px",
  textAlign: "left",
  pl: 0,
  pr: 2,
};

const FilterCollapse = ({
  open,
  onClose,
  filters = [],
  setEnquiryStatus,
  enquiryStatus,
}) => {
  const updateEnquiryStatusFilter = (e, filter) => {
    let statusArray = [...enquiryStatus];

    if (e.target.checked) {
      statusArray.push(filter);
      setEnquiryStatus(statusArray);
    } else {
      const filteredArr = statusArray.filter((item) => {
        if (item !== filter) {
          return item;
        }
      });
      setEnquiryStatus(filteredArr);
    }
  };

  const clearEnquiryStatus = () => {
    setEnquiryStatus([]);
  };

  return (
    <Popover
      open={Boolean(open)}
      anchorEl={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Stack direction="column">
        <Typography
          fontSize="14px"
          fontWeight="700"
          display="flex"
          justifyContent="end"
          pr="14px"
          sx={{ cursor: "pointer" }}
          onClick={() => clearEnquiryStatus()}
          color="#808080"
        >
          {" "}
          Clear All
        </Typography>
        {filters.map((filter, i) => (
          <Box display="flex" key={i}>
            <Checkbox
              checked={enquiryStatus?.includes(filter.value)}
              onChange={(e) => updateEnquiryStatusFilter(e, filter.value)}
              sx={{
                [`&, &.${checkboxClasses.checked}`]: {
                  color: "#0C7F44",
                },
                padding: "0px 6px",
              }}
            />
            <Button key={i} sx={popOverButtonsStyle}>
              {filter.label}
            </Button>
          </Box>
        ))}
      </Stack>
    </Popover>
  );
};
export default FilterCollapse;
