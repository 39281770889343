import { adminApi, client, customerApi } from "./config"

export const logoutTransporter= async()=>{
    return await client.post('/logout')
}
export const logoutCustomer = async() =>{
    return await customerApi.post("/logout")
}
export const logoutAdmin = async() =>{
    return await adminApi.post("/logout")
}