import { client } from "../config"

export const getTransporterProfile = async() =>{
    return await client.get('transporter/profile')
}
export const updateTransporterProfileOnFTUE = async(transporterProfileData) =>{
    return await client.post('transporter/ftue/profile',transporterProfileData)
}
export const updateTransporterProfile = async(transporterProfileData) =>{
    return await client.post('transporter/profile',transporterProfileData)
}