import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import styled from "@emotion/styled";

export default function IgnoreEnquiryModal({
  transporterEnquiryDetails,
  setIsIgnoreEnquiryModalOpen,
  handleIgnoreInquiry,
}) {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "white",
        borderRadius: 4,
        width: "600px",
        height: "400px",
        padding: "70px",
      }}
    >
      <StyledBox width="440px" flexDirection="column">
        <StyledText color="#333" fontSize="24px" fontWeight="700" width="430px">
          Do you want to ignore this enquiry?
        </StyledText>
        <StyledText
          color="#333"
          fontSize="18px"
          fontWeight="700"
          width="430px"
          sx={{
            marginTop: "16px",
            marginBottom: "16px",
            textAlign: "center",
          }}
        >
          Enquiry ID #
          {transporterEnquiryDetails?.customerEnquiry?.customerEnquiryId}
        </StyledText>
        <StyledTypography>
          Ignoring this enquiry will remove the enquiry from your list.
        </StyledTypography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            marginBottom: "40px",
          }}
        >
          <StyledText>
            {
              transporterEnquiryDetails?.customerEnquiry?.pickup?.location
                ?.address
            }{" "}
          </StyledText>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "10px",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                color: "#4F4848",
                fontSize: "12px",
                fontWeight: 400,
              }}
            >
              {transporterEnquiryDetails?.customerEnquiry?.distance + " km"}
            </Typography>
            <img
              src="/assets/icons/distanceShortArrow.svg"
              alt="addImage"
              style={{
                height: "20px",
                width: "70px",
              }}
            />
          </Box>

          <StyledText
            sx={{
              textAlign: "right",
            }}
          >
            {
              transporterEnquiryDetails?.customerEnquiry?.dropoff?.location
                ?.address
            }
          </StyledText>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <Button
            sx={{
              background: "#FF3F3F",
              color: "white",
              "&:hover": {
                background: "#FF3F3F",
              },
              fontWeight: 700,
            }}
            onClick={handleIgnoreInquiry}
          >
            Confirm
          </Button>
          <Button
            sx={{
              background: "#FFF",
              border: "1px solid #333",
              color: "#333",
              fontWeight: 700,
            }}
            onClick={() => setIsIgnoreEnquiryModalOpen(false)}
          >
            Cancel
          </Button>
        </Box>
      </StyledBox>
    </Box>
  );
}

const StyledText = styled(Typography)(
  ({ fontSize, lineHeight, color, fontWeight, width }) => ({
    color: color || "#4F4848",
    fontFamily: "Helvetica",
    fontSize: fontSize || "14px",
    fontStyle: "normal",
    fontWeight: fontWeight || "400",
    lineHeight: lineHeight || "18px",
    textTransform: "capitalize",
    width: width || "160px",
  })
);
const StyledBox = styled(Box)(
  ({ width, flexDirection, gap, marginBottom }) => ({
    width: width || "0px",
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    flexDirection: flexDirection || "row",
    gap: gap || "0px",
    marginBottom: marginBottom || "0px",
  })
);

const StyledTypography = styled(Typography)(() => ({
  color: "#333",
  fontSize: "14px",
  fontWeight: 400,
  marginBottom: "24px",
}));
