import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import { getCustomerProfile } from "../../../api/Customer/enquiry";
import { getCustomerKycDetails } from "../../../api/Customer/kyc";

const Consignment = () => {
  const customerName = useSelector((state) => state.customer.name);
  const [customerProfile, setCustomerProfile] = useState(null);
  const [customerKycDetails, setCustomerKycDetails] = useState(null);

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const profileResponse = await getCustomerProfile();
        const kycResponse = await getCustomerKycDetails();
        setCustomerProfile(profileResponse.data);
        setCustomerKycDetails(kycResponse?.data?.data);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    };

    fetchCustomerDetails();
  }, []);

  const getFullAddress = (customerProfile) => {
    const businessAddress = customerProfile?.customer?.businessAddress;
    const fullAddress = businessAddress
      ? Object.values({
          addressLine1: businessAddress.addressLine1,
          addressLine2: businessAddress.addressLine2,
          city: businessAddress.city,
          state: businessAddress.state,
        })
          .filter(Boolean)
          .join(", ")
      : "-";
    return fullAddress;
  };

  return (
    <Box
      sx={{
        mt: 1,
        p: 2,
        borderRadius: 2,
        backgroundColor: "#F0F0F0",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={4.5}>
              <Typography
                variant="body1"
                sx={{ color: "#4a4a4a", fontWeight: "bold", fontSize: "12px" }}
              >
                CUSTOMER NAME
              </Typography>
            </Grid>
            <Grid item xs={0.5}>
              <Typography
                variant="body1"
                sx={{ color: "#4a4a4a", fontSize: "12px" }}
              >
                :
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                variant="body1"
                sx={{ color: "#4a4a4a", fontSize: "12px" }}
              >
                {customerName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={4.5}>
              <Typography
                variant="body1"
                sx={{ color: "#4a4a4a", fontWeight: "bold", fontSize: "12px" }}
              >
                COMPANY NAME
              </Typography>
            </Grid>
            <Grid item xs={0.5}>
              <Typography
                variant="body1"
                sx={{ color: "#4a4a4a", fontSize: "12px" }}
              >
                :
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                variant="body1"
                sx={{ color: "#4a4a4a", fontSize: "12px" }}
              >
                {customerProfile
                  ? customerProfile?.customer?.businessName
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={4.5}>
              <Typography
                variant="body1"
                sx={{ color: "#4a4a4a", fontWeight: "bold", fontSize: "12px" }}
              >
                GSTIN
              </Typography>
            </Grid>
            <Grid item xs={0.5}>
              <Typography
                variant="body1"
                sx={{ color: "#4a4a4a", fontSize: "12px" }}
              >
                :
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                variant="body1"
                sx={{ color: "#4a4a4a", fontSize: "12px" }}
              >
                {customerKycDetails
                  ? customerKycDetails?.kycDetails?.gstDetails?.licenceNumber
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={4.5}>
              <Typography
                variant="body1"
                sx={{ color: "#4a4a4a", fontWeight: "bold", fontSize: "12px" }}
              >
                GSTIN ADDRESS
              </Typography>
            </Grid>
            <Grid item xs={0.5}>
              <Typography
                variant="body1"
                sx={{ color: "#4a4a4a", fontSize: "12px" }}
              >
                :
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                variant="body1"
                sx={{ color: "#4a4a4a", fontSize: "12px" }}
              >
                {getFullAddress(customerProfile)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Consignment;
