export const accessoriesOptions = [
  {
    name: "tarpaulin",
    checked: false,
    label: "Standard Tarpaulin for rain protection",
  },
  {
    name: "chains",
    checked: false,
    label: "Heavy-duty chains for securing material",
  },
  { name: "ropes", checked: false, label: "Ropes" },
  { name: "belt", checked: false, label: "Belt & Tightener" },
  { name: "wood", checked: false, label: "Base support wood pieces" },
  // { name: "others", checked: false, label: "Others" },
];

export const PARSE_BODY_MATERIAL = {
  WOOD: "Wood",
  STEEL: "Steel",
};
export const PARSE_BODY_TYPE = {
  OPEN: "Open",
  LOW_DECK: "Low Deck",
  HIGH_DECK: "High Deck",
  CLOSED_CONTAINER: "Closed Container",
};
export const PARSE_FORKLIFT_COMPATIBILITY = {
  true: "Yes",
  false: "No",
};

export const bankList = [
  "Bank of Baroda",
  "Bank of India",
  "Bank of Maharashtra",
  "Canara Bank",
  "Central Bank of India",
  "Indian Bank",
  "Indian Overseas Bank",
  "Punjab and Sind Bank",
  "Punjab National Bank",
  "State Bank of India",
  "UCO Bank",
  "Union Bank of India",
  "Axis Bank",
  "Bandhan Bank",
  "CSB Bank",
  "City Union Bank",
  "DCB Bank",
  "Dhanlaxmi Bank",
  "Federal Bank",
  "HDFC Bank",
  "ICICI Bank",
  "IDBI Bank",
  "IDFC First Bank",
  "IndusInd Bank",
  "Jammu & Kashmir Bank",
  "Karnataka Bank",
  "Karur Vysya Bank",
  "Kotak Mahindra Bank",
  "Nainital Bank",
  "RBL Bank",
  "South Indian Bank",
  "Tamilnad Mercantile Bank",
  "Yes Bank",
];

export const customerPreferenceOrderTexts = [
  "enquiry",
  "trip",
  "payment",
  "kyc",
];

export const transporterPreferenceOrderTexts = [
  "enquiry",
  "bid",
  "trip",
  "payment",
  "kyc",
];
