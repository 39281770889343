import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from "@mui/material";
import styled from "@emotion/styled";
import NameDescription from "./NameDescription";

export default function RejectAdminEnquiryModal({
  setIsRejectEnquiryModalOpen,
  isRejectEnquiryModalOpen,
  enquiry,
  handleRejectEnquiry,
}) {
  return (
    <Dialog open={isRejectEnquiryModalOpen} width="600px" height="400px">
      <DialogTitle
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mt={2}
      >
        <StyledText color="#333" fontSize="24px" fontWeight="700" width="500px">
          Reject Enquiry
        </StyledText>
        <img
          src="/assets/icons/cross-icon.svg"
          alt="cross"
          style={{
            cursor: "pointer",
          }}
          onClick={() => setIsRejectEnquiryModalOpen(false)}
        />
      </DialogTitle>

      <DialogContent>
        <BoxContainer marginTop="30px" gap="40px">
          <NameDescription
            name="Pick Up Location"
            description={enquiry?.pickup?.location?.address}
            width="175px"
            marginLeft="0px"
          />
          <NameDescription
            name="Distance"
            description={enquiry?.distance}
            marginLeft="0px"
          />

          <NameDescription
            name="Drop Off Location"
            description={enquiry?.dropoff?.location?.address}
            width="175px"
            marginLeft="0px"
          />
        </BoxContainer>

        <StyledBox flexDirection="column" marginTop="50px" gap="25px">
          <StyledText color="#F10505" textAlign="center">
            You really want to reject this Enquiry?
          </StyledText>

          <StyledBox gap="25px">
            <Button
              onClick={handleRejectEnquiry}
              variant="rejectButton"
              sx={{ padding: "7px 24px", fontWeight: 700 }}
            >
              Reject
            </Button>
            <Button
              sx={{
                fontWeight: 700,
                padding: "6px 24px",
              }}
              onClick={() => setIsRejectEnquiryModalOpen(false)}
              variant="outlined"
            >
              Cancel
            </Button>
          </StyledBox>
        </StyledBox>
      </DialogContent>
    </Dialog>
  );
}

const StyledText = styled(Typography)(
  ({ fontSize, color, fontWeight, width }) => ({
    color: color || "#4F4848",
    fontSize: fontSize || "14px",
    fontWeight: fontWeight || "400",
    width: width || "400px",
  })
);
const StyledBox = styled(Box)(
  ({
    width,
    flexDirection,
    gap,
    marginBottom,
    alignItems,
    justifyContent,
    marginTop,
  }) => ({
    width: width || "100%",
    display: "flex",
    alignItems: alignItems || "center",
    justifyContent: justifyContent || "center",
    flexDirection: flexDirection || "row",
    gap: gap || "0px",
    marginBottom: marginBottom || "0px",
    marginTop: marginTop || "0px",
  })
);

const BoxContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "baseline",
}));
