import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import styled from "@emotion/styled";

import HeaderWithUnderline from "./HeaderWithUnderline";
import { LOADING_STATUS } from "../../../../constants/constant";
import LoadingTable from "../../../Common/LoadingTable";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFFFFF",
    color: "#333333",
    borderRight: "1px solid #c6c6c6",
    fontWeight: 700,
    borderBottom: "none",
  },
}));

const StyledTableBodyCell = styled(TableCell)(() => ({
  color: "#6D6D6D",
}));

const StyledTableRow = styled(TableRow)(() => ({
  border: "2px solid #FFF",

  td: {
    fontWeight: 700,
    fontSize: "12px",
    paddingBottom: "8px",
    paddingTop: "8px",
    borderBottom: "6px solid #fff",
    "&:hover": {
      color: "#333333",
    },
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function NearByTransporterDetails(props) {
  const { matchedTransporters } = props;

  const [page, setPage] = useState(0);
  const [pageData, setPageData] = useState([]);
  const [isEnquiriesListFetched, setIsEnquiriesListFetched] = useState(
    LOADING_STATUS.INITIAL
  );

  const perPage = 10;
  const totalItems = matchedTransporters?.length;

  useEffect(() => {
    const startIndex = page * perPage;

    setPageData(matchedTransporters?.slice(startIndex, startIndex + 10));
    setIsEnquiriesListFetched(LOADING_STATUS.LOADED);
  }, [matchedTransporters, page]);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  function renderViews() {
    switch (isEnquiriesListFetched) {
      case LOADING_STATUS.LOADING:
        return <LoadingTable columns={4} />;
      case LOADING_STATUS.LOADED:
        if (pageData?.length) {
          return (
            <>
              {pageData.map((transporter) => (
                <StyledTableRow
                  key={transporter.transporterId}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <StyledTableBodyCell>
                    {transporter.fullName}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {transporter.businessName}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>{transporter.email}</StyledTableBodyCell>
                  <StyledTableBodyCell>{transporter.phone}</StyledTableBodyCell>
                </StyledTableRow>
              ))}
            </>
          );
        } else {
          return (
            <TableRow sx={{ background: "#EAEAEA" }}>
              <TableCell
                colSpan={4}
                align="center"
                sx={{ fontWeight: 600, color: "#333" }}
              >
                No Results Found.
              </TableCell>
            </TableRow>
          );
        }
      default:
        return null;
    }
  }

  return (
    <Box>
      <HeaderWithUnderline header="Near by Transporter" />

      <TableContainer
        component={Paper}
        sx={{
          width: "70%",
          boxShadow: "none",
        }}
      >
        <Table size="small" aria-label="Enquiry data" stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>Transporter Name</StyledTableCell>
              <StyledTableCell>Business Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderViews()}</TableBody>
        </Table>
      </TableContainer>

      {matchedTransporters?.length > 1 && (
        <div
          style={{
            borderTop: "1px solid #adadad",
            marginTop: "3px",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={totalItems}
            rowsPerPage={perPage}
            page={page}
            onPageChange={handleChangePage}
            labelDisplayedRows={({ count }) => (
              <div>
                Page{" "}
                <span style={{ color: "green", fontWeight: 700 }}>
                  {page + 1}
                </span>{" "}
                of {Math.ceil(count / perPage)}
              </div>
            )}
          />
        </div>
      )}
    </Box>
  );
}
