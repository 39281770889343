import { Box, Grid, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  getTruckDetails,
  updateTruckStatus,
} from "../../../api/Admin/trucksResources";
import { useEffect, useState } from "react";
import TruckTripsTable from "../../../component/AdminResources/TruckTripsTable";
import useToast from "../../../hooks/useToast";
import styled from "@emotion/styled";
import ToggleButton from "../../../component/Common/ToggleButton";

// Common component for generating Accessory with styles
const Accessories = ({ accessory }) => {
  return (
    <Typography
      component="div"
      border="2px solid #c6c6c6"
      borderRadius="1rem"
      p=".125rem  .5rem"
      color="#333"
    >
      {accessory}
    </Typography>
  );
};

const TruckImageDisplayer = ({ image, alt }) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        height: "110px",
        width: "180px",
        border: "1px solid #c6c6c6",
        borderRadius: ".25rem",
        overflow: "hidden",
      }}
    >
      <img
        src={image}
        alt={alt}
        width="100%"
        height="100%"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "scale-down",
          objectPosition: "center",
        }}
      />
    </Stack>
  );
};

const TruckResourcesDetailedView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [truckDetails, setTruckDetails] = useState([]);
  const [panelExpanded, setPanelExpanded] = useState(null);
  const updateExpandedPanel = (panel) => (event, isExpanded) => {
    setPanelExpanded(isExpanded ? panel : false);
  };
  const [isTransporterAvailable, setIsTransporterAvailable] = useState(
    truckDetails?.vehicleStatus === "AVAILABLE" ? true : false
  );
  const { handleOpenToast, ToastMessage } = useToast();

  const getDetails = async (id) => {
    try {
      const response = await getTruckDetails(id);
      if (response.status === 200) {
        setTruckDetails(response.data.data);
      }
    } catch (error) {
      handleOpenToast("Error while getting truck details", "error");
    }
  };
  const handleUpdateTruckStatus = async () => {
    try {
      const newStatus = isTransporterAvailable ? "UNAVAILABLE" : "AVAILABLE";
      const response = await updateTruckStatus(id, newStatus);
      if (response.status === 200) {
        handleOpenToast("Truck status updated successfully", "success");
        setIsTransporterAvailable(!isTransporterAvailable);
      }
    } catch (error) {
      handleOpenToast("Error while updating truck status", "error");
    }
  };

  const onToggle = async () => {
    setIsTransporterAvailable(!isTransporterAvailable);
    handleUpdateTruckStatus();
  };

  useEffect(() => {
    getDetails(id);
    setIsTransporterAvailable(truckDetails?.vehicleStatus === "AVAILABLE");
  }, [id, truckDetails?.vehicleStatus]);

  return (
    <Box>
      <ToastMessage />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ background: "#EAEAEA" }}
        p=".5rem 1.5rem"
      >
        <img
          alt="back"
          src="/assets/icons/backArrow.svg"
          style={{ cursor: "pointer", width: "24px", height: "20px" }}
          onClick={() => navigate(-1)}
        />
        <Typography color="#333" fontWeight={700} fontSize="1.25rem">
          {truckDetails.vehicleManufacturerName +
            " " +
            truckDetails.vehicleModelName}
        </Typography>
        <StatusAndToggle>
          <ToggleButton
            toggledState={isTransporterAvailable}
            onToggle={onToggle}
          />
          <StatusTypography
            sx={{ color: isTransporterAvailable ? "#00B87C" : "#FF0000" }}
          >
            {isTransporterAvailable ? "Available" : "Not Available"}
          </StatusTypography>
        </StatusAndToggle>
      </Stack>
      <Box p="1rem 2rem">
        <Stack direction="row">
          <Typography sx={{ fontWeight: 700, color: "#333", fontSize: "1rem" }}>
            Truck Details
          </Typography>
          <div style={{ borderBottom: "1px solid #6D6D6D", flexGrow: 1 }}></div>
        </Stack>

        <Stack direction="row" gap={2} p="1rem 0">
          <Stack flexGrow={1} maxWidth="md">
            <Grid container gap={4} width="100%">
              <Grid item xs="auto">
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <Typography>
                    Vehicle Category:{" "}
                    <b>{truckDetails.vehicleCategory || "-"} Wheelers</b>
                  </Typography>
                  <Typography>
                    Vehicle Model: <b>{truckDetails.vehicleModelName || "-"}</b>
                  </Typography>
                  <Typography>
                    Vehicle Number: <b>{truckDetails.vehicleNumber || "-"}</b>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <Typography>
                    Owner Name: <b>{truckDetails.vehicleOwnerName || "-"}</b>
                  </Typography>
                  <Typography>
                    Wheel Count: <b>{truckDetails.vehicleCategory || "-"}</b>
                  </Typography>
                  <Typography>
                    Added On: <b>{truckDetails.createdAt || "-"} </b>
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Stack direction="row" mt={4} gap={2} alignItems="center">
              <Typography
                sx={{ fontWeight: 700, color: "#333", fontSize: "1rem" }}
              >
                Features
              </Typography>
              <div
                style={{ borderBottom: "1px solid #6D6D6D", flexGrow: 1 }}
              ></div>
            </Stack>
            <Stack
              direction="row"
              flexWrap="wrap"
              alignItems="center"
              mt={2}
              gap={1.5}
            >
              {truckDetails?.accessories?.toolsAvailable?.map(
                (accessory, index) => (
                  <Accessories key={index} accessory={accessory} />
                )
              )}
              {truckDetails?.accessories?.other && (
                <Accessories
                  key={truckDetails?.accessories?.other}
                  accessory={truckDetails?.accessories?.other}
                />
              )}
            </Stack>
          </Stack>
          <Stack
            maxWidth="400px"
            flexWrap="wrap"
            direction="row"
            gap={2}
            justifyContent={{ sm: "center", lg: "flex-start" }}
            alignItems="center"
          >
            {truckDetails?.vehicleImages?.frontUrl ? (
              <TruckImageDisplayer
                image={truckDetails.vehicleImages.frontUrl}
                alt="front"
              />
            ) : (
              <TruckImageDisplayer
                image={"/assets/images/front.png"}
                alt="front"
              />
            )}
            {truckDetails?.vehicleImages?.rearUrl ? (
              <TruckImageDisplayer
                image={truckDetails.vehicleImages.rearUrl}
                alt="rear"
              />
            ) : (
              <TruckImageDisplayer
                image={"/assets/images/back.png"}
                alt="rear"
              />
            )}
            {truckDetails?.vehicleImages?.leftUrl ? (
              <TruckImageDisplayer
                image={truckDetails.vehicleImages.leftUrl}
                alt="left"
              />
            ) : (
              <TruckImageDisplayer
                image={"/assets/images/left.png"}
                alt="left"
              />
            )}
            {truckDetails?.vehicleImages?.rightUrl ? (
              <TruckImageDisplayer
                image={truckDetails.vehicleImages.rightUrl}
                alt="right"
              />
            ) : (
              <TruckImageDisplayer
                image={"/assets/images/right.png"}
                alt="right"
              />
            )}
          </Stack>
        </Stack>

        {/* Other Details Section */}
        <Stack direction="row">
          <Typography sx={{ fontWeight: 700, color: "#333", fontSize: "1rem" }}>
            {"Other Details"}
          </Typography>
          <div style={{ borderBottom: "1px solid #6D6D6D", flexGrow: 1 }}></div>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          gap={2}
          mt={2}
          mx={5}
        >
          <Typography>
            Transporter Name: <b>{truckDetails?.transporterName || "-"}</b>
          </Typography>
          <Typography>
            Current Status: <b>{truckDetails?.vehicleStatus || "-"}</b>
          </Typography>
        </Stack>
        <Stack direction="column" gap={2} mt={2}>
          <TruckTripsTable
            panelExpanded={panelExpanded}
            updateExpandedPanel={updateExpandedPanel}
            id={id}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default TruckResourcesDetailedView;

const StatusAndToggle = styled(Box)({
  display: "flex",
  gap: "10px",
});

const StatusTypography = styled(Typography)({
  fontSize: "14px",
  fontWeight: 700,
  width: "90px",
});
