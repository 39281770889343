import React, { useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";

const KycUpdatesAlert = (props) => {
  const [snackbarOpen, setSnackbarOpen] = useState(true);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
    props.handleOnClose();
  };
  useEffect(() => {
    handleShowSnackbar();
  }, []);

  const handleShowSnackbar = () => {
    // You can set a timeout to automatically close the Snackbar after a certain duration
    setTimeout(() => {
      setSnackbarOpen(false);
      props.handleOnClose();
    }, 5000); // Close the Snackbar after 5 seconds
  };

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
    >
      <Alert
        variant="filled"
        onClose={handleSnackbarClose}
        severity={props.severity}
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
};

export default KycUpdatesAlert;
