import { adminApi } from "../config";

const getAllMaterial = async (currentPage = "1", pageSize = "10") => {
  try {
    const response = await adminApi.get(
      `inventory/material-type/?page=${currentPage}&pageSize=${pageSize}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

const createMaterial = async (materialData) => {
  try {
    const response = await adminApi.post(
      `inventory/material-type`,
      materialData
    );
    return response;
  } catch (error) {
    return error;
  }
};

const editMaterial = async (materialId, updatedMaterialData) => {
  try {
    const response = await adminApi.put(
      `inventory/material-type/${materialId}`,
      updatedMaterialData
    );
    return response;
  } catch (error) {
    return error;
  }
};

const deleteSelectedMaterial = async (materialId) => {
  try {
    const response = await adminApi.delete(
      `inventory/material-type/${materialId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

const getMaterialById = async (materialId) => {
  try {
    const response = await adminApi.get(
      `inventory/material-type/${materialId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

const getMaterialCategoryByIndustry = async (industry) => {
  try {
    const response = await adminApi.get(
      `/inventory/${industry}/material-category/`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export {
  getAllMaterial,
  createMaterial,
  editMaterial,
  deleteSelectedMaterial,
  getMaterialById,
  getMaterialCategoryByIndustry,
};
