import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from "@mui/material";
import styled from "@emotion/styled";

const TransporterRemoveCityModal = ({
  confirmRemoveCity,
  closeRemoveCityModal,
  cityName,
  isDeleteCityModalOpen,
}) => {
  const StyledBox = styled(Box)(({ flexDirection, gap, marginTop }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: flexDirection || "row",
    gap: gap || "0px",
    marginTop: marginTop || "0px",
  }));

  const StyledText = styled(Typography)(() => ({
    color: "#4F4848",
    fontSize: "14px",
    fontWeight: "400",
    width: "400px",
  }));

  return (
    <Dialog open={isDeleteCityModalOpen} width="300px" height="300px">
      <DialogTitle
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mt={2}
      >
        <Typography color="#333" fontSize="24px" fontWeight="700">
          Remove City
        </Typography>
        <img
          src="/assets/icons/cross-icon.svg"
          alt="cross"
          style={{
            cursor: "pointer",
          }}
          onClick={closeRemoveCityModal}
        />
      </DialogTitle>

      <DialogContent>
        <StyledBox flexDirection="column" marginTop="20px" gap="25px">
          <StyledText textAlign="center">
            Do you want to remove {cityName} city?
          </StyledText>

          <StyledBox gap="25px">
            <Button
              onClick={confirmRemoveCity}
              variant="rejectButton"
              sx={{ padding: "7px 24px", fontWeight: 700 }}
            >
              Yes
            </Button>
            <Button
              sx={{
                fontWeight: 700,
                padding: "6px 24px",
              }}
              onClick={closeRemoveCityModal}
              variant="outlined"
            >
              No
            </Button>
          </StyledBox>
        </StyledBox>
      </DialogContent>
    </Dialog>
  );
};

export default TransporterRemoveCityModal;
