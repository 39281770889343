import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import {
  Box,
  Button,
  Divider,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { guestApi } from "../../api/config";
import {
  ref,
  onValue,
  off,
  limitToFirst,
  startAt,
  query,
  orderByKey,
  limitToLast,
  orderByChild,
  endAt,
} from "firebase/database";
import { getDatabase } from "firebase/database";
import { firebaseApp } from "../../firebase";
import { useSelector } from "react-redux";
import moment from "moment";
import Cookies from "js-cookie";

const topics = [
  {
    name: "All",
    filter: "",
  },
  {
    name: "Kyc's",
    filter: "KYC",
  },
  {
    name: "Enquiries",
    filter: "ENQUIRY",
  },
  {
    name: "Trips",
    filter: "TRIP",
  },
  {
    name: "Payments",
    filter: "PAYMENT",
  },
];

const AdminNotificationPage = () => {
  const navigate = useNavigate();
  
  //hardcoded adminId needs to be replaced with current admin id
  const adminId = Cookies.get('loggedInAdminId');

  const pageSize = 7;
  const [notificationList, setNotificationsList] = useState([]);
  const [lastKey, setLastKey] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [filter, setFilter] = useState("");

  const fetchNotifications = (filterValue, nextPage = false, lastKey = "") => {
    const db = getDatabase(firebaseApp);
    const getDbPath = () => {
      return !filterValue
        ? `history/generic/${adminId}`
        : `history/categorized/${adminId}/${filterValue}`;
    };

    let itemsRef = ref(db, getDbPath());
    let queryConstraints = [orderByChild("timestamp")];

    if (lastKey) {
      queryConstraints.push(endAt(lastKey));
    }

    queryConstraints.push(limitToLast(pageSize + 1)); // +1 to determine if there's a next page

    itemsRef = query(itemsRef, ...queryConstraints);

    const unsubscribe = onValue(itemsRef, (snapshot) => {
      const data = snapshot.val();
      let fetchedItems = data ? Object.values(data) : [];
      fetchedItems.reverse();
      if (lastKey) {
        fetchedItems.shift(); // Remove the first item (duplicate from previous fetch)
      }

      const newItems = nextPage
        ? [...notificationList, ...fetchedItems]
        : fetchedItems;
      setNotificationsList(newItems);

      const hasMoreItems = fetchedItems.length > 0;
      if (hasMoreItems) setHasMore(hasMoreItems);
      else setHasMore(false);

      if (hasMoreItems) {
        // Set the lastKey to the last item's timestamp to use for the next query
        setLastKey(fetchedItems[fetchedItems.length - 1].timestamp);
      }
    });

    return () => unsubscribe();
  };

  const handleFilterChange = (clickedFilter) =>{
    setFilter(clickedFilter); // Update the filter state
    setNotificationsList([]); // Clear existing notifications
    setLastKey(""); // Reset the lastKey for pagination
    setHasMore(true); // Reset hasMore in case it was set to false
    
    // Fetch notifications with the new filter after delay
    setTimeout(() => {
      fetchNotifications(clickedFilter, false, "");
    }, 300);
  }

  useEffect(() => {
    const unsubscribe = fetchNotifications("", true, "");
    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  return (
    <Box pb={3}>
      <Stack
        position="fixed"
        width="100%"
        sx={{ background: "#EAEAEA", zIndex: 1000 }}
        py={2}
      >
        <Stack
          width="100%"
          m="auto"
          direction="row"
          maxWidth="lg"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            sx={{ color: "#333", fontWeight: 700 }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosRoundedIcon />
          </Button>

          <Typography
            fontWeight={700}
            color="#333"
            textAlign="center"
            flexGrow={1}
            variant="h5"
          >
            Notification
          </Typography>
        </Stack>
      </Stack>
      <Toolbar />
      <Stack
        direction="row"
        gap={2}
        justifyContent="flex-end"
        p="1rem 6rem"
        position="fixed"
        width="100%"
        sx={{background:'white',zIndex:500}}
      >
        {topics.map((topic) => (
          <Button
            key={topic.filter}
            sx={{
              textTransform: "none",
              color: topic.filter === filter ? "#333" : "#ADADAD",
              fontWeight: topic.filter === filter ? "700" : "700",
              borderBottom: topic.filter === filter ? "1px solid #333" : "none",
            }}
            onClick={()=>handleFilterChange(topic.filter)}
          >
            {topic.name}
          </Button>
        ))}
      </Stack>
      <Toolbar />
      <Stack m="auto" maxWidth="lg">
        {notificationList.length ? (
          notificationList.map((each, idx) => (
            <Notification data={each} key={idx} />
          ))
        ) : (
          <Typography color="#333" mt={20} fontSize={18} textAlign="center">
            You have no notifications at the moment !
          </Typography>
        )}
      </Stack>
      {hasMore && (
        <Stack direction="row" justifyContent="center" mt={5}>
          <Button
            sx={{ textTransform: "none" }}
            onClick={() => fetchNotifications(filter, hasMore, lastKey)}
          >
            Load more...
          </Button>
        </Stack>
      )}
      {!hasMore && notificationList.length > 0 && (
        <Typography textAlign="center" fontSize={16} color="#ADADAD" mt={5}>
          You're all caught up !
        </Typography>
      )}
    </Box>
  );
};
export default AdminNotificationPage;

const Notification = ({ data }) => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        gap={5}
        maxWidth="lg"
        px={5}
        py={2}
      >
        <Box >
          <Typography fontWeight={700}>{data.title}</Typography>
          <Typography color="#ADADAD">{data.body}</Typography>
        </Box>
        <Typography fontWeight={700} textAlign='left' minWidth={200}>
          {moment(data.timestamp).format("DD MMMM YYYY hh:mm A")}
        </Typography>
      </Stack>
      <Divider />
    </>
  );
};

