import { Button, Stack, Typography } from "@mui/material";
import Appbar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import LoginDialog from "./Common/LoginDialog";
import { useSelector } from "react-redux";
import GetInTouch from "./Common/GetInTouch";

const LogoSection = () => {
  return (
    <Stack direction="row" spacing={1.5}>
      <img
        src="/assets/icons/LZ-logo-face.svg"
        alt="logo face"
        style={{ marginBottom: "2px" }}
      />

      <img
        src="/assets/icons/LZ-logo.svg"
        alt="logo"
        style={{ marginTop: "4px" }}
      />
    </Stack>
  );
};
const PortalButtons = ({ handlePortalButtonClick }) => {
  return (
    <>
      <Button
        variant="text"
        sx={{
          "&:hover": { backgroundColor: "transparent" },
          textTransform: "none",
        }}
        disableRipple
        onClick={() => handlePortalButtonClick("transporter")}
      >
        <Typography
          color="#333333"
          sx={{ textDecoration: "underline", fontWeight: "bold" }}
        >
          LoadEazy Partner
        </Typography>
      </Button>
      <Button
        variant="contained"
        sx={{
          fontWeight: "bold",
        }}
        style={{ background: "#0C7F44", textTransform: "none" }}
        startIcon={
          <img
            src="/assets/icons/login-button-icon.svg"
            alt="login"
            style={{ width: "1.125rem" }}
          />
        }
        onClick={() => handlePortalButtonClick("customer")}
      >
        Login
      </Button>
    </>
  );
};
const GetInTouchSection = () => {
  const number = useSelector((state) => state.supportNumbers?.landingPage);
  return (
    <Stack spacing={0}>
      <GetInTouch />
      <Typography variant="p" ml="auto" color="#0C7F44" fontWeight={700}>
        {number}
      </Typography>
    </Stack>
  );
};

const Navbar = () => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [stakeholder, setStakeholder] = useState(null);
  /**
   * helps in picking which portal user wants to enter
   * @param {customer,partner} clickedPortal
   */
  const handlePortalButtonClick = (clickedPortal) => {
    setStakeholder(clickedPortal);
    setIsLoginModalOpen(true);
  };
  // this function closes login modal upon clicking cross mark
  const handleCloseLoginModal = () => {
    setIsLoginModalOpen(false);
  };
  return (
    <Box maxWidth="xl" sx={{ margin: "auto" }}>
      <Appbar
        position="static"
        sx={{ background: "#FFF", margin: "auto", boxShadow: "none" }}
        id="landing-page-navbar"
      >
        <Box
          px={{
            sm: 6,
            md: 8,
            lg: 12,
            xl: 16,
          }}
          py={{
            sm: 1,
            md: 1.75,
            lg: 2.5,
            xl: 4,
          }}
          sx={{
            direction: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <LogoSection />

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              sm: 4,
              md: 6,
              lg: 8,
              xl: 8,
            }}
          >
            <PortalButtons handlePortalButtonClick={handlePortalButtonClick} />
            <GetInTouchSection />
          </Stack>
        </Box>
      </Appbar>
      <LoginDialog
        isLoginModalOpen={isLoginModalOpen}
        handleCloseLoginModal={handleCloseLoginModal}
        stakeholder={stakeholder}
      />
    </Box>
  );
};

export default Navbar;
