import { Box, Button, Stack, Typography } from "@mui/material";
import RejectDocumentModal from "./RejectDocumentModal";
import { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../api/config";
import Cookies from "js-cookie";
import KycDocumentImageViewer from "../../../component/Common/KycDocumentsImageViewer";

const LicenseDocuments = ({ driversDocument,kycId }) => {

    const [driverLicenseStatus, setDriversLicenseStatus] = useState(
        driversDocument?.approvalStatus
      );
    const [openRejectModal, setOpenRejectModal] = useState(false);
    //   states for open reject document modal 
      const handleOpen = () => setOpenRejectModal(true);
      const handleClose = () => setOpenRejectModal(false);

      const approveDriversLicenseRequest = async () => {
        try {
          await axios
            .post(
              BASE_URL.concat("admin/kyc/approve"),
              {
                kycId,
                documentType: "drivingLicence",
              },
              {
                headers: {
                  Authorization: `Bearer ${Cookies.get("admintoken")}`,
                },
              }
            )
            .then((data) => {
              setDriversLicenseStatus(data.data.data.kyc.drivingLicenceDetails.approvalStatus);
              window.location.reload();
            });
        } catch (err) {}
      };
    
      const rejectDriversLicenseRequest = async (reason) => {
        axios
          .post(
            BASE_URL.concat("admin/kyc/reject"),
            {
              kycId,
              documentType: "drivingLicence",
              resonOfRejection: reason,
            },
            {
              headers: {
                Authorization: `Bearer ${Cookies.get("admintoken")}`,
              },
            }
          )
          .then((data) => {
            setDriversLicenseStatus(data.data.data.kyc.drivingLicenceDetails.approvalStatus);
            window.location.reload();
          });
      };
    if (!driversDocument)
      return (
        <Typography
          sx={{
            color: "#333",
            fontSize: "1rem",
            marginTop: "4rem",
            marginLeft: "1rem",
          }}
        >
          No documents uploaded yet
        </Typography>
      );
    return (
      <Box p={2} mt={5}>
        <Stack direction="row" alignItems="center" spacing={2} mt={4}>
        <KycDocumentImageViewer imageUrl={driversDocument?.frontUrl} imageSide="license_front"/>
        <KycDocumentImageViewer imageUrl={driversDocument?.backUrl} imageSide="license_front"/>
        </Stack>
        <Stack
          width={600}
          direction="row"
          justifyContent="space-between"
          pr={0.5}
          mt={5}
        >
          {driverLicenseStatus &&
          driverLicenseStatus === "APPROVED" ? (
            <>
            <span />
            <Button
              sx={{ color: "#0C7F44", textTransform: "none" }}
              endIcon={
                <img src="/assets/icons/green-tick.svg" alt="approved" />
              }
            >
              Approved
            </Button>
          </>
          ) : driverLicenseStatus === 'REJECTED' ? <>
          <span />
          <Button
            sx={{ color: "#FF0707", textTransform: "none" }}
            endIcon={<img src="/assets/icons/red-cross.svg" alt="approved" />}
          >
            Rejected
          </Button>
        </>:(
            <>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleOpen}
              >
                Reject
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={approveDriversLicenseRequest}
              >
                Approve
              </Button>
            </>
          )}
        </Stack>
        <RejectDocumentModal
      documentType='License'
        openRejectModal={openRejectModal}
        handleOpen={handleOpen}
        handleClose={handleClose}
        handleRejectDocument={rejectDriversLicenseRequest}
      />
      </Box>
    );
  };
  
  export default LicenseDocuments

  