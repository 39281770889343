import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import RemoveTripModal from "./RemoveTripModal";

export default function FavoriteTripCard(props) {
  const navigate = useNavigate();

  const { trip, customerId, fetchTrips } = props;
  const [isRemoveTripModalOpen, setIsRemoveTripCityModalOpen] = useState(false);

  const handleRemoveTrip = () => {
    setIsRemoveTripCityModalOpen(true);
  };

  const closeRemoveTripModal = () => {
    setIsRemoveTripCityModalOpen(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        display="flex"
        gap="10px"
        flexDirection="column"
        width="60%"
        border="2px solid #ececec"
        px="20px"
        py="10px"
      >
        <Box display="flex" fontWeight="700" fontSize="16px">
          {trip.enquiryDetails.route}
        </Box>

        <Box display="flex" flexDirection="row">
          <img src="/assets/icons/distanceArrowSmall.svg" alt="DistanceArrow" />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box color="#6D6D6D">
              {trip.enquiryDetails.pickup.exactLocation.address},{" "}
              {trip.enquiryDetails.pickup.exactLocation.city},{" "}
              {trip.enquiryDetails.pickup.exactLocation.state}.
            </Box>
            <Box color="#6D6D6D">
              {trip.enquiryDetails.dropoff.exactLocation.address},{" "}
              {trip.enquiryDetails.dropoff.exactLocation.city},{" "}
              {trip.enquiryDetails.dropoff.exactLocation.state}.
            </Box>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Button
            variant="outlined"
            sx={{ height: "25px" }}
            onClick={handleRemoveTrip}
          >
            Remove
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ height: "25px" }}
            onClick={() =>
              navigate("/rebook", {
                state: {
                  tripId: trip.tripId,
                  customerEnquiryId: trip.enquiryDetails.customerEnquiryId,
                  customerId: customerId,
                },
              })
            }
          >
            Rebook
          </Button>
        </Box>
        <RemoveTripModal
          isRemoveTripModalOpen={isRemoveTripModalOpen}
          customerId={customerId}
          trip={trip}
          closeRemoveTripModal={closeRemoveTripModal}
          fetchTrips={fetchTrips}
        />
      </Box>
    </Box>
  );
}
