import React, { useEffect, useState } from "react";
import { client } from "../api/config";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { useDispatch } from "react-redux";
import { setUserCredentials } from "../redux/slices/userAuthenticationSlice";
import { OTP_STATUS } from "../constants/constant";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { sendOTP } from "../api/common";
import { Link } from "react-router-dom";

const NumberVerificationScreen = ({
  setIsOTPReceived,
  handleCloseLoginModal,
  stakeholder,
}) => {
  const SEND_OTP_END_POINT =
    stakeholder === "admin" ? "send-otp-admin" : "send-otp";

  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] =
    useState(false);
  const [isOTPSent, setIsOTPSent] = useState(OTP_STATUS.INITIAL);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }
  }, [showAlert]);

  // this functions sets the phone number in state `phoneNumber` only if entered value is digit.
  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;
    if (isNaN(value)) return;
    setPhoneNumber(value);
  };
  // function is triggered when Get OTP button is clicked on after user is done inputting phone no.
  const onClickGetOTP = async () => {
    // handleClose();
    setIsOTPSent(OTP_STATUS.SENDING);
    if (phoneNumber.length === 10 && isTermsAndConditionsChecked) {
      const response = await sendOTP(SEND_OTP_END_POINT, phoneNumber)
        .then((user) => {
          const { phone, hash } = user?.data;
          dispatch(setUserCredentials({ phone, hash, portal: stakeholder }));
          setTimeout(() => {
            setIsOTPSent(OTP_STATUS.SENT);
            setIsOTPReceived(true);
          }, 1000);
        })
        .catch((err) => {
          setShowAlert(true);
          setAlertMessage({
            message: err.response.data.message,
            severity: "error",
          });
          setIsOTPSent(OTP_STATUS.NOT_SENT);
        });
    } else {
      setShowAlert(true);
      setAlertMessage({
        message: "Please agree to terms and conditions",
        severity: "error",
      });
      setIsOTPSent(OTP_STATUS.NOT_SENT);
    }
  };
  return (
    <Box p={3}>
      <Stack direction="row" justifyContent="space-between">
        <img
          src="/assets/icons/login-button-icon.svg"
          alt="login icon"
          style={{ filter: "invert(81%)", width: "50px", height: "55px" }}
        />
        {stakeholder !== "admin" && (
          <img
            src="/assets/icons/cross-icon.svg"
            alt="cross"
            style={{ alignSelf: "flex-start", cursor: "pointer" }}
            onClick={handleCloseLoginModal}
          />
        )}
      </Stack>
      <Typography
        mt={2}
        sx={{ fontWeight: "bold", fontSize: "1.5rem", color: "#333333" }}
      >
        Login
      </Typography>
      <Typography sx={{ color: "#6D6D6D", fontWeight: 700 }} mb={4}>
        Welcome to LoadEazy{stakeholder === "admin" ? " Admin" : "..."}
      </Typography>

      <Typography
        mb={1}
        sx={{ fontWeight: "bold", fontSize: ".875rem", color: "#333333" }}
      >
        Mobile Number
      </Typography>

      <TextField
        onChange={handlePhoneNumberChange}
        value={phoneNumber}
        placeholder="Enter Mobile Number"
        variant="outlined"
        fullWidth
        autoFocus
        sx={{
          "& .MuiInputBase-input": {
            fontSize: "1rem",
            fontWeight: 700,
          },
        }}
        inputProps={{
          maxLength: 10,
        }}
      />
      <Stack
        mt={3}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <FormControlLabel
          label={
            <Typography fontSize=".875rem" color="#ADADAD">
              I accept the{" "}
              <Link target="_blank" to="/terms&conditions">
                T&C
              </Link>{" "}
              |{" "}
              <Link target="_blank" to="/privacypolicy">
                Privacy Policy
              </Link>
              .
            </Typography>
          }
          control={
            <Checkbox
              color="success"
              checked={isTermsAndConditionsChecked}
              onChange={() => {
                setIsTermsAndConditionsChecked(!isTermsAndConditionsChecked);
                setShowAlert(false);
              }}
            />
          }
        />

        <Button
          disabled={phoneNumber.length !== 10 || isOTPSent === "sending"}
          variant="contained"
          color="primary"
          startIcon={
            isOTPSent === "sending" && (
              <CircularProgress size={20} sx={{ color: "#FFF" }} />
            )
          }
          sx={{
            fontSize: "1.025rem",
          }}
          onClick={onClickGetOTP}
        >
          Get OTP
        </Button>
      </Stack>
      {/* {showAlert && ( */}
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={showAlert}
        autoHideDuration={4000}
      >
        <Alert severity={alertMessage.severity}>{alertMessage.message}.</Alert>
      </Snackbar>
      {/* )} */}
    </Box>
  );
};

export default NumberVerificationScreen;
