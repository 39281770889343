import React, { useState, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router";
import CustomerDetails from "./CustomerDetails";
import EnquiryDetails from "./EnquiryDetails";
import EstimationDetails from "./EstimationDetails";
import NearByTransporterDetails from "./NearByTransporterDetails";
import LiveQuotesSharedDetails from "./LiveQuotesSharedDetails";
import { getAdminEnquiryById } from "../../../../api/Admin/adminEnquiries";
import AdminStatusCell from "./AdminStatusCell";
import AdminExpiryTimerCell from "./AdminExpiryTimerCell";
import useToast from "../../../../hooks/useToast";

const HeaderBox = styled(Box)(({ gap }) => ({
  display: "flex",
  flexDirection: "col",
  alignItems: "center",
  gap: gap || "12px",
}));

const StyledBox = styled(Box)(() => ({
  padding: "24px",
}));

const StyledTypography = styled(Typography)(
  ({ fontWeight, fontSize, textTransform }) => ({
    fontWeight: fontWeight || "",
    fontSize: fontSize || "",
    textTransform: textTransform || "",
  })
);

export default function AdminEnquiryDetailedView() {
  const [enquiry, setEnquiry] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const adminEnquiryStatusArray = ["UNQUOTED", "QUOTED"];
  const estimationDetailsStatusArray = ["NO_SERVICE", "UNQUOTED", "QUOTED"];
  const { handleOpenToast } = useToast();

  const adminEnquiryById = async () => {
    try {
      const response = await getAdminEnquiryById(id);
      if (response?.status === 200) {
        setEnquiry(response.data.data);
      }
    } catch (error) {
      handleOpenToast("Error while getting enquiry.", "error");
    }
  };

  useEffect(() => {
    adminEnquiryById();
  }, [id]);

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ background: "#EAEAEA", padding: "12px 24px" }}
      >
        <HeaderBox>
          <img
            style={{
              cursor: "pointer",
              width: "17px",
              height: "24px",
            }}
            src="/assets/icons/backArrow.svg"
            alt="back"
            onClick={() => navigate(-1)}
            height="24px"
          />
          <StyledTypography
            fontWeight="700"
            fontSize="16px"
            textTransform="capitalize"
          >
            #{enquiry?.customerEnquiryId}
          </StyledTypography>
        </HeaderBox>
        <StyledTypography
          fontWeight="700"
          fontSize="24px"
          textTransform="capitalize"
        >
          {enquiry?.route}
        </StyledTypography>
        <HeaderBox gap="18px">
          <StyledTypography fontWeight="700" fontSize="12px">
            {adminEnquiryStatusArray?.includes(enquiry?.enquiryStatus) && (
              <AdminExpiryTimerCell
                status={enquiry?.enquiryStatus}
                enquiryExpirationTimestamp={
                  (enquiry?.enquiryStatus === "UNQUOTED" &&
                    enquiry?.timers?.enquiryExpirationTimestamp) ||
                  (enquiry?.enquiryStatus === "QUOTED" &&
                    enquiry?.timers?.offerExpiryTimestamp)
                }
              />
            )}
          </StyledTypography>
          <StyledTypography fontWeight="700" fontSize="12px">
            <AdminStatusCell status={enquiry?.enquiryStatus} preview />
          </StyledTypography>
        </HeaderBox>
      </Stack>

      <StyledBox>
        {enquiry?.matchedTransporters &&
          enquiry?.enquiryStatus === "UNQUOTED" && (
            <NearByTransporterDetails
              matchedTransporters={enquiry?.matchedTransporters}
            />
          )}

        {enquiry?.matchedTransporters &&
          enquiry?.enquiryStatus === "QUOTED" && (
            <LiveQuotesSharedDetails enquiry={enquiry} />
          )}

        <CustomerDetails enquiry={enquiry} />

        <EnquiryDetails
          enquiry={enquiry}
          refreshEnquiryDetails={adminEnquiryById}
        />

        {estimationDetailsStatusArray.includes(enquiry?.enquiryStatus) && (
          <EstimationDetails enquiry={enquiry} />
        )}
      </StyledBox>
    </Box>
  );
}
