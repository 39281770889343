import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { getAllLinkedDriverLocationDetails } from "../../../../api/Transporter/mapTracking";

const truckIcon = new L.Icon({
  iconUrl: "/assets/icons/truck.svg",
  iconSize: [16, 10],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

const VehicleInspectionRecords = () => {
  const [vehiclePositions, setVehiclePositions] = useState([]);
  const transporterId = useSelector((state) => state.transporterId.id);

  useEffect(() => {
    const fetchDriversAndLocations = async () => {
      try {
        const driversDetails = await getAllLinkedDriverLocationDetails(
          transporterId
        );
        const validPositions = driversDetails.data.locations.filter(
          (vehicle) =>
            vehicle &&
            typeof vehicle.latitude === "number" &&
            typeof vehicle.longitude === "number" &&
            !isNaN(vehicle.latitude) &&
            !isNaN(vehicle.longitude)
        );

        const promises = validPositions.map(async (vehicle) => {
          const { latitude, longitude } = vehicle;
          const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
          );
          const address = response.data.results[0].formatted_address;
          return { ...vehicle, address };
        });

        const vehiclesWithAddress = await Promise.all(promises);
        setVehiclePositions(vehiclesWithAddress);
      } catch (error) {
        console.error("Error fetching driver locations:", error);
      }
    };

    fetchDriversAndLocations();
  }, [transporterId]);

  const indiaBounds = [
    [6.5546079, 68.1113787],
    [35.6745457, 97.395561],
  ];

  return (
    <MapContainer
      center={[20.5937, 78.9629]}
      zoom={5}
      style={{ height: "100vh", width: "100%" }}
      maxBounds={indiaBounds}
      maxBoundsViscosity={1.0}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {vehiclePositions.map((vehicle) => (
        <Marker
          key={vehicle.id}
          position={[vehicle.latitude, vehicle.longitude]}
          icon={truckIcon}
        >
          <Tooltip direction="top" offset={[0, -32]} opacity={1}>
            <div>
              <strong>Driver Name: {vehicle.driverName}</strong>
              <br />
              Mobile Number: {vehicle.phone}
              <br />
              Address: {vehicle.address}
            </div>
          </Tooltip>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default VehicleInspectionRecords;
