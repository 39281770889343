import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  landingPage: "",
  transporterPortal:'',
  customerPortal:'',
};

export const supportNumberSlice = createSlice({
  name: "supportNumbers",
  initialState,
  reducers: {
    setSupportnumbers: (state, action) => {
      state.landingPage = action.payload?.landingPageSupportNumber;
      state.transporterPortal = action.payload?.transporterSupportNumber;
      state.customerPortal = action.payload?.customerSupportNumber;
    },
  },
});

export const { setSupportnumbers } = supportNumberSlice.actions;

export default supportNumberSlice.reducer;
