import React, { useState, useEffect } from "react";
import { Box, Stack, TablePagination, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import styled from "@emotion/styled";

import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { StyledTableCell } from "../../Admin/Approval/StyledTableCell";
import { LOADING_STATUS } from "../../../constants/constant";
import { useNavigate } from "react-router-dom";
import FilterCollapse from "../../../component/Customer/FilterCollapse";
import { getCustomerTrips } from "../../../api/Customer/trips";
import { useSelector } from "react-redux";
import { TripsTable } from "../../../component/Customer/TripsTable";
import LoadingTable from "../../../component/Common/LoadingTable";
import ErrorScreen from "../../../component/Common/ErrorScreen";
import useToast from "../../../hooks/useToast";
import SearchIcon from "@mui/icons-material/Search";

export const Trips = () => {
  const { ToastMessage, handleOpenToast } = useToast();
  const customerId = useSelector((state) => state.customer.id);
  const bookingStatusTracking = useSelector(
    (state) => state.customer.bookingStatusTracking
  );

  const navigate = useNavigate();
  const [trips, setTrips] = useState([]);
  const [isTripsFetched, setIsTripsFetched] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [page, setPage] = useState(0);
  const [openRow, setOpenRow] = useState(null);
  const [actionFilter, setActionFilter] = useState([]);
  const [anchorForAction, setAnchorForAction] = useState(null);
  const [paymentStatusFilter, setPaymentStatusFilter] = useState([]);
  const [anchorForStatus, setAnchorForStatus] = useState(null);
  const [tripStatusFilter, setTripStatusFilter] = useState([]);
  const [anchorForTripStatus, setAnchorForTripStatus] = useState(null);
  const [search, setSearch] = useState("");
  const actionfilters = ["pay now", "rebook"];
  const paymentStatusfilters = ["partially paid", "paid", "refunded"];
  const tripStatusfilters = ["upcoming", "cancelled", "intransit", "completed"];

  const handleOpenFilter = (event, setAnchor) => {
    setAnchor(event.currentTarget);
  };

  const handleCloseFilter = (setAnchor) => {
    setAnchor(null);
  };

  const fetchTripsRequests = async () => {
    setIsTripsFetched(LOADING_STATUS.LOADING);

    const response = await getCustomerTrips(customerId, page, search);
    if (response.status === 200) {
      const tripsArray = response?.data.data.trips;
      setTrips(tripsArray);
      setPaginationData(response?.data.data.pagination);
      setIsTripsFetched(LOADING_STATUS.LOADED);
    } else {
      setIsTripsFetched(LOADING_STATUS.FAILED);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
  };

  const tableCellStyles = {
    borderBottom: 0,
    padding: "2px 12px",
    textAlign: "center",
    lineHight: "16px",
  };

  useEffect(() => {
    fetchTripsRequests();
  }, [customerId, bookingStatusTracking]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchTripsRequests();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  if (isTripsFetched === LOADING_STATUS.LOADING) {
    return (
      <TableContainer sx={{ marginBottom: 8 }}>
        <Table aria-label="customized table">
          <TableHead></TableHead>
          <LoadingTable columns={8} />
        </Table>
      </TableContainer>
    );
  } else if (isTripsFetched === LOADING_STATUS.LOADED) {
    return (
      <Box>
        <Stack p="1rem 1.625rem" borderBottom="1px solid #ADADAD">
          <TextField
            variant="outlined"
            placeholder="Search by trip id"
            value={search}
            type="number"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            InputProps={{
              sx: { height: "36.31px", width: "100%" },
              endAdornment: <SearchIcon />,
            }}
          />
        </Stack>
        <Box>
          <TableContainer sx={{ paddingBottom: 12 }}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={tableCellStyles}>
                    Trip Id
                  </StyledTableCell>
                  <StyledTableCell sx={tableCellStyles}>
                    Trip Date
                  </StyledTableCell>
                  <StyledTableCell sx={tableCellStyles}>Route</StyledTableCell>
                  <StyledTableCell sx={tableCellStyles}>
                    <StyledStack>
                      {"Trip Status"}
                      <img
                        src="/assets/icons/expand_less.svg"
                        alt="expand less"
                        onClick={(e) =>
                          handleOpenFilter(e, setAnchorForTripStatus)
                        }
                        style={{ cursor: "pointer" }}
                      />
                      <FilterCollapse
                        open={anchorForTripStatus}
                        onClose={() =>
                          handleCloseFilter(setAnchorForTripStatus)
                        }
                        filters={tripStatusfilters}
                        setFilters={setTripStatusFilter}
                        selectedFilters={tripStatusFilter}
                      />
                    </StyledStack>
                  </StyledTableCell>
                  <StyledTableCell sx={tableCellStyles}>
                    Total <br />
                    Amount
                  </StyledTableCell>
                  <StyledTableCell sx={tableCellStyles}>
                    Amount <br />
                    Paid
                  </StyledTableCell>
                  <StyledTableCell sx={tableCellStyles}>
                    Pending <br />
                    Amount
                  </StyledTableCell>

                  <StyledTableCell sx={tableCellStyles}>
                    <StyledStack>
                      Payment <br />
                      Status
                      <img
                        src="/assets/icons/expand_less.svg"
                        alt="expand less"
                        onClick={(e) => handleOpenFilter(e, setAnchorForStatus)}
                        style={{ cursor: "pointer" }}
                      />
                      <FilterCollapse
                        open={anchorForStatus}
                        onClose={() => handleCloseFilter(setAnchorForStatus)}
                        filters={paymentStatusfilters}
                        setFilters={setPaymentStatusFilter}
                        selectedFilters={paymentStatusFilter}
                      />
                    </StyledStack>
                  </StyledTableCell>
                  <StyledTableCell sx={tableCellStyles}>
                    <StyledStack>
                      {"Action"}
                      <img
                        src="/assets/icons/expand_less.svg"
                        alt="expand less"
                        onClick={(e) => handleOpenFilter(e, setAnchorForAction)}
                        style={{ cursor: "pointer" }}
                      />
                      <FilterCollapse
                        open={anchorForAction}
                        onClose={() => handleCloseFilter(setAnchorForAction)}
                        filters={actionfilters}
                        setFilters={setActionFilter}
                        selectedFilters={actionFilter}
                      />
                    </StyledStack>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TripsTable
                trips={trips}
                actionFilter={actionFilter}
                paymentStatusFilter={paymentStatusFilter}
                tripStatusFilter={tripStatusFilter}
                navigate={navigate}
                openRow={openRow}
                setOpenRow={setOpenRow}
                handleOpenToast={handleOpenToast}
              />
            </Table>
          </TableContainer>
          <StyledDiv>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={paginationData.totalItems}
              rowsPerPage={paginationData.pageSize}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={({ count }) => (
                <span>
                  Page{" "}
                  <span style={{ color: "green", fontWeight: 700 }}>
                    {page + 1}
                  </span>{" "}
                  of {Math.ceil(count / paginationData.pageSize)}
                </span>
              )}
            />
          </StyledDiv>
        </Box>
        <ToastMessage />
      </Box>
    );
  } else {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <ErrorScreen onRetry={fetchTripsRequests} />;
      </Box>
    );
  }
};

const StyledDiv = styled.div`
  border-top: 1px solid #adadad;
  height: 60px;
  margin-top: 3px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: white;
`;

const StyledStack = styled(Stack)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
