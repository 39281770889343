import React from "react";
import { Typography, Box } from "@mui/material";

const MaterialWeHandle = () => {
  const materials = [
    {
      title: "Steel Coils",
      image: "/assets/landingPageIcons/MWH-coils.svg",
    },
    {
      title: "Chequered Plates",
      image: "/assets/landingPageIcons/MWH-chequered-plates.svg",
    },
    {
      title: "All Sheets & Plates",
      image: "/assets/landingPageIcons/MWH-all-sheets-and-plates.svg",
    },
    {
      title: "MS Channels",
      image: "/assets/landingPageIcons/MWH-MS-channels.svg",
    },
    {
      title: "MS Angles",
      image: "/assets/landingPageIcons/MWH-MS-angles.svg",
    },
    {
      title: "MS Beams",
      image: "/assets/landingPageIcons/MWH-MS-beams.svg",
    },
    {
      title: "TMT Bars",
      image: "/assets/landingPageIcons/MWH-solid-bars.svg",
    },
    {
      title: "Purlins and other accessories",
      image: "/assets/landingPageIcons/MWH-purlins.svg",
    },
    {
      title: "Pipes and Tubes",
      image: "/assets/landingPageIcons/MWH-pipes-and-tubes.svg",
    },
    {
      title: "Other Structural Steel",
      image: "/assets/landingPageIcons/MWH-other-structural-steels.svg",
    },
  ];
  return (
    <Box
      id="thingsWeDeliver"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#F8FAF8",
        gap: "24px",
        pt: "90px",
        pb: "90px",
      }}
    >
      <Typography
        variant="h1"
        mb={4}
        sx={{
          color: "#333",
          textAlign: "center",
          fontFamily: "Helvetica",
          fontSize: "36px",
          fontWeight: 700,
        }}
      >
        Materials We Handle
      </Typography>

      <Typography
        // variant="body1"
        maxWidth="lg"
        m="1rem auto"
        sx={{
          color: "#4E4E4E",
          textAlign: "center",
          fontFamily: "Helvetica",
          fontSize: "24px",
          fontWeight: 400,
        }}
      >
        Our logistics services are tailored to meet your unique cargo needs,
        providing customized solutions.
        <br /> We support the delivery of a wide range of goods, as listed
        below.
      </Typography>

      <Box
        maxWidth="xl"
        sx={{
          margin: "auto",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "56px",
        }}
      >
        {materials.map((e) => (
          <Box
            key={e.title}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "290px",
            }}
          >
            <img
              src={e.image}
              alt={e.title}
              style={{
                height: "180px",
                width: "180px",
              }}
            />
            <Typography
              gutterBottom
              component="div"
              sx={{
                color: "#333",
                textAlign: "center",
                fontFamily: "Helvetica",
                fontSize: "24px",
                fontWeight: 700,
              }}
            >
              {e.title}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default MaterialWeHandle;