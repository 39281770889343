import React from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import TransporterDashboard from "../../../component/Transporter/Dashboard";

export default function Dashboard() {
  const StyledBox = styled(Box)(() => ({
    width: "calc(100vw - 280px)",
    height: "calc(100vh - 96px)",
  }));
  return (
    <StyledBox p={2}>
      <TransporterDashboard />
    </StyledBox>
  );
}
