import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  TextField,
  TextareaAutosize as BaseTextareaAutosize,
  Stack,
} from "@mui/material";
import styled from "@emotion/styled";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  p: 2,
};

export default function RejectDocumentModal({
  handleRejectDocument,
  handleClose,
  openRejectModal,
  documentType
}) {
  const [reasonOfRejection, setReasonOfRejection] = React.useState("");
  const [isReasonEmpty, setIsReasonEmpty] = React.useState(false)
  const handleReasonOfRejectionSubmit =()=>{
    if(!reasonOfRejection){
      setIsReasonEmpty(true)
      return
    }
    handleRejectDocument(reasonOfRejection)
  }
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openRejectModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openRejectModal}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              sx={{ fontWeight: 700, fontSize: "1.5rem" }}
            >
              Reject {documentType}
            </Typography>
            <Box id="transition-modal-description" sx={{ mt: 2 }}>
              <Typography>Reason of Rejection</Typography>
              <TextareaAutosize required
                minRows={4}
                onChange={(e) => setReasonOfRejection(e.target.value)}
              />
              {isReasonEmpty && <Typography color='red' fontSize={14} ml={1}>This is required field*</Typography>}
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mt={1}
              >
                <Button
                  sx={{ color: "red", textTransform: "none" }}
                  onClick={ handleReasonOfRejectionSubmit}
                >
                  Reject
                </Button>
                <Button
                  sx={{ color: "green", textTransform: "none" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

const TextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    width:366px;
    max-width: 366px;
    font-family: Helvetica;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: #333;
    background: #fff;
    border: 1px solid #eaeaea;
    box-shadow: 0px 2px 2px #eaeaea;
    &:hover {
      border-color: #c6c6c6;
    }
  
    
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);
