import { client } from "../config";

export const getTrackingDetailsById = async (TripId) => {
  try {
    const response = await client.get(`tracking/location/${TripId}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getAllLinkedDriverLocationDetails = async (transporterId) => {
  try {
    const response = await client.get(
      `transporter/${transporterId}/drivers-location`
    );
    return response;
  } catch (error) {
    return error;
  }
};
