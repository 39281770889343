import { Box, CircularProgress, Typography, styled } from "@mui/material";

const TripDetails = ({ trips, isTripListFetching }) => {
  if (isTripListFetching) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    );
  }

  return (
    <TripHistoryContainer>
      <Box>
        <TripHistoryTitle>Trips History</TripHistoryTitle>
        {trips && trips.length > 0 ? (
          trips.map((trip) => (
            <TripItemContainer key={trip.tripId}>
              <TripItemHeader>
                <Box>
                  <TripInfo>Trip Id: {trip.tripId}</TripInfo>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <TripInfo>{trip.route}</TripInfo>
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <TripDateSlot>
                        {`( ${new Date(trip.tripDate).toLocaleDateString()} ${
                          trip.tripTimeSlot
                        }  )`}
                      </TripDateSlot>
                    </Box>
                  </Box>
                </Box>
              </TripItemHeader>
            </TripItemContainer>
          ))
        ) : (
          <NoTripsMessage>No trip assigned yet!</NoTripsMessage>
        )}
      </Box>
    </TripHistoryContainer>
  );
};

export default TripDetails;

const LoadingContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "35vh",
  width: "100%",
});

const TripHistoryContainer = styled(Box)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  overflowY: "scroll",
  border: "1px solid #ADADAD",
  margin: "8px",
  borderRadius: "5px",
  background: "#EAEAEA",
});

const TripHistoryTitle = styled(Typography)({
  fontSize: "16px",
  fontWeight: 700,
  color: "#333",
  padding: "8px",
  marginLeft: "8px",
});

const TripItemContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  margin: "8px",
  padding: "8px",
  borderBottom: "1px solid black",
});

const TripItemHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "8px",
});

const TripInfo = styled(Box)({
  fontSize: "16px",
  color: "#333",
});

const TripDateSlot = styled(Box)({
  fontSize: "14px",
  color: "#333",
});

const NoTripsMessage = styled(Box)({
  background: "#EAEAEA",
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  minHeight: "100px",
  margin: "8px",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "16px",
  fontWeight: "700",
});
