import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Popover,
  Stack,
  checkboxClasses,
} from "@mui/material";

const popOverButtonsStyle = {
  color: "#333",
  textTransform: "capitalize",
  fontSize: "12px",
  textAlign: "left",
  pl: 0,
  pr: 2,
};

const TripPaymentFilter = ({
  open,
  onClose,
  filters = [],
  paymentStatus,
  setPaymentStatus,
}) => {
  const updatePaymentStatusFilter = (e, filter) => {
    let statusArray = [...paymentStatus];

    if (e.target.checked) {
      statusArray.push(filter);
      setPaymentStatus(statusArray);
    } else {
      const filteredArr = statusArray.filter((item) => {
        if (item !== filter) {
          return item;
        }
      });
      setPaymentStatus(filteredArr);
    }
  };

  return (
    <Popover
      open={Boolean(open)}
      anchorEl={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Stack direction="column">
        {filters.map((filter, i) => (
          <Box display="flex" key={i}>
            <Checkbox
              checked={paymentStatus?.includes(filter.value)}
              onChange={(e) => updatePaymentStatusFilter(e, filter.value)}
              sx={{
                [`&, &.${checkboxClasses.checked}`]: {
                  color: "#0C7F44",
                },
                padding: "0px 6px",
              }}
            />
            <Button key={i} sx={popOverButtonsStyle}>
              {filter.label}
            </Button>
          </Box>
        ))}
      </Stack>
    </Popover>
  );
};
export default TripPaymentFilter;
