import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { PlacesAutocomplete } from "../component/Common/PlacesAutoComplete";
import { useEffect, useState } from "react";
import { useMapsService } from "../hooks/useMapsService";
import EastIcon from "@mui/icons-material/East";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import EditCalendarRoundedIcon from "@mui/icons-material/EditCalendarRounded";
import moment from "moment";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  createEnquiryFromWhatsapp,
  getMaterialTypes,
  getTimeSlots,
  getVehicles,
  getVehicleModels,
  getVehicleRecommendation,
} from "../api/Customer/enquiry";
import useToast from "../hooks/useToast";
import { userprofileById } from "../api/common";
const labelStyle = { fontSize: ".835rem", color: "#333", fontWeight: 700 };
const initialFormState = {
  pickup: "",
  dropoff: "",
  weight: "",
  quantity: "",
  material: "",
  vehicleCategory: "",
  vehicleType: "",
  date: null,
  // moment(new Date()).format("YYYY-MM-DD"),
  slot: "",
  username:''
};

const WhatsAppEnquiryForm = () => {
  const { userId } = useParams();
  // if(!userId) return <Navigate to={-1}/>
  const navigate = useNavigate();
  const { ToastMessage, handleOpenToast } = useToast({
    vertical: "top",
    horizontal: "center",
  });
  const { calculateDistance, getLatLng } = useMapsService();

  // additional states
  const [materialOpted, setMaterialOpted] = useState({
    name: "",
    category: "",
    categoryId: "",
  });
  const [recommendedVehicles, setRecommendedVehicles] = useState([]);
  const [recommendedTimeSlots, setRecommendedTimeSlots] = useState([]);

  // formData keeps track of inputs in enquiry form
  const [formData, setFormData] = useState(initialFormState);
  const [vehicleCategoryList, setVehicleCategoryList] = useState([]);
  const [vehicleModelList, setVehicleModelList] = useState([]);
  const [materialNameList, setMaterialNameList] = useState([]);
  const [timeSlotList, setTimeSlotList] = useState([]);

  // state to switch b/w two pages of form
  const [switchPage, setSwitchPage] = useState(false);
  const [distance, setDistance] = useState("");
  const [hasDistanceAndLatLng, setHasDistanceAndLatLng] = useState(false);
  const [pickUpPlaceId, setPickupPlaceId] = useState("");
  const [dropOffPlaceId, setDropoffPlaceId] = useState("");
  const [pickupPlaceLocation, setPickupPlaceLocation] = useState({});
  const [dropoffPlaceLocation, setDropoffPlaceLocation] = useState({});
  const [unit, setUnit] = useState("KG");
  const [firstPageErrors, setFirstPageErrors] = useState({});

  //   second page states
  const [secondPageErrors, setSecondPageErrors] = useState({});
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [isVehicleOpted, setIsVehicleOpted] = useState(true);

  const readableTruckCategories = (vehicle, idx) => {
    return {
      value: `${vehicle.category} wheelers (${vehicle.minCapacity / 1000}T - ${
        vehicle.maxCapacity / 1000
      }T)`,
      id: idx,
    };
  };

  const retriveDataForEnquiry = async () => {
    try {
      const [
        materialTypeResponse,
        // vehicleCategoryTypeResponse,
        userDetails,
        timeSlotResponse,
      ] = await Promise.all([
        getMaterialTypes(),
        // getVehicles(),
        userprofileById(userId),
        getTimeSlots(),
      ]);
      const materialTypeForDropdown = materialTypeResponse.data.data.map(
        (type) => ({
          id: type.materialTypeId,
          value: type?.materialName,
          material: {
            name: type.materialName,
            category: type.productCategory,
            categoryId: type.materialCategory.id,
          },
        })
      );
      // const vehicleCategoriesForDropdown =
      //   vehicleCategoryTypeResponse.data.data.map((vehicle, idx) =>
      //     readableTruckCategories(vehicle, idx)
      //   );
      const timeSlotForDropdown = timeSlotResponse.data.data.map(
        (slot, idx) => ({
          id: idx,
          value: slot.timeSlot,
          startTime: slot.startTime,
        })
      );
      const name = userDetails.data.data.customer?.fullName;
      setMaterialNameList(materialTypeForDropdown);
      // setVehicleCategoryList(vehicleCategoriesForDropdown);
      setTimeSlotList(timeSlotForDropdown);
      setFormData(prev=>({...prev, username:name}))
    } catch (err) {
      handleOpenToast("Something went wrong. Please refresh page.", "error");
    }
  };

  // fetch truck category when component mounts
  useEffect(() => {
    retriveDataForEnquiry();
  }, []);

  // whenever category changes this useEffect is triggered.
  // useEffect(() => {
  //   if (formData.vehicleCategory && formData.vehicleCategory !== 1) {
  //     setFormData((prev) => ({ ...prev, vehicleType: "" }));
  //     getVehicleModels(formData.vehicleCategory.split(" ")[0])
  //       .then((data) => {
  //         setVehicleModelList(data.data.data);
  //       })
  //       .catch((err) => {
  //         handleOpenToast("Something went wrong! Please refresh page", "error");
  //       });
  //   }
  // }, [formData.vehicleCategory]);
  useEffect(() => {
    if (formData.date) {
      const selectedDate = moment(formData.date).startOf("day");
      const currentDate = moment().startOf("day");

      if (selectedDate.isSame(currentDate)) {
        const currentTime = moment();

        const newTimeSlots = timeSlotList.filter((slot) => {
          const slotStartTime = moment(slot.startTime, "hh:mm A");
          return slotStartTime.isAfter(currentTime);
        });

        setRecommendedTimeSlots(newTimeSlots);
        
      } else {
      
        setRecommendedTimeSlots(timeSlotList);
      }
    }
  }, [formData.date]);

  useEffect(() => {
    if (formData.material && formData.weight) {
      const weightInput =
        unit === "KG" ? formData.weight : 1000 * formData.weight;
      getVehicleRecommendation(materialOpted.categoryId, weightInput)
        .then((data) => {
          const res = data.data.data.map((vehicle) => {
            return {
              value: `${vehicle.numberOfWheels} wheelers (${
                vehicle.minCapacity / 1000
              }T - ${vehicle.maxCapacity / 1000}T)`,
              id: vehicle.vehicleCategoryId,
            };
          });
          setRecommendedVehicles(res);
        })
        .catch((err) => {
          handleOpenToast("Something went wrong!", "error");
        });
    }
  }, [formData.material, formData.weight, unit]);

  const handlePickupSelect = (place, placeId) => {
    setFormData((prev) => ({ ...prev, pickup: place }));
    setPickupPlaceId(placeId);
  };
  const handleDropoffSelect = (place, placeId) => {
    setFormData((prev) => ({ ...prev, dropoff: place }));
    setDropoffPlaceId(placeId);
  };
  const handleInputChange = (event) => {
    const { value, name } = event.target;
    if (name === "vehicleCategory" && value === 1) {
      if (!isVehicleOpted) {
        setIsVehicleOpted(true);
      }
      setFormData((prev) => ({ ...prev, vehicleType: "" }));
    }
    if (name === "vehicleType") {
      const chosenModel = vehicleModelList.find(
        (model) => model.modelName === value
      );
      setSelectedVehicleType(chosenModel);
    }
    if (name === "weight") {
      if (isNaN(value)) return;
    }
    if (name === "material") {
      const selectedMaterial = materialNameList.find((e) => e.value === value);
      setMaterialOpted(selectedMaterial.material);
    }
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const validateFirstPageInputs = () => {
    let errors = {};
    if (!formData.pickup) {
      errors.pickup = "Select pickup location";
    }
    if (!formData.dropoff) {
      errors.dropoff = "Select dropoff location";
    }
    if (!formData.material) {
      errors.material = "Select material type";
    }
    if (!formData.weight) {
      errors.weight = "Please input weight";
    }
    if (!formData.vehicleCategory)
      errors.vehicleCategory = "Select vehicle category";
    setFirstPageErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const validateSecondPageInputs = () => {
    let errors = {};

    // if (!formData.vehicleType && formData.vehicleCategory !== 1)
    //   errors.vehicleType = "Select vehicle model";
    if(!formData.username) errors.username = "Please enter user name"
    if (!formData.date) errors.date = "Please select pickup date";
    if (!formData.slot) errors.slot = "Please select slot";
    setSecondPageErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const generatePayload = (pickupLocationDetails, dropoffLocationDetails) => {
    let datePart = formData.date;
    let timePart = formData.slot.split("-")[0];
    let time24 = timePart.includes("AM")
      ? timePart.substring(0, 5)
      : (parseInt(timePart.substring(0, 2)) % 12) +
        12 +
        timePart.substring(2, 5);
    let dateTimeString = `${datePart}T${time24}+05:30`;
    let requestBody = {
      pickup: { location: pickupLocationDetails },
      dropoff: { location: dropoffLocationDetails },
      distance,
      // goodsType: formData.material,
      material:materialOpted,
      weight:
        unit === "KG"
          ? parseInt(formData.weight)
          : parseInt(1000 * formData.weight),
      quantity: formData.quantity ? parseInt(formData.quantity) : "",
      vehicleCategory: 1,
      //
      pickupDate: new Date(dateTimeString).toISOString(),
      pickupSlot: formData.slot,
      // passing customerId coming from link
      customerId: userId,
      customerName : formData.username
    };
    if (formData.vehicleCategory !== 'No Preference') {
      requestBody = {
        ...requestBody,
        vehicleCategory: parseInt(formData.vehicleCategory.split(" ")[0]),
        vehicleCategoryId: recommendedVehicles.find(
          (type) => type.value === formData.vehicleCategory
        ).id,
        // vehicleModel: formData.vehicleType,
        // vehicleTypeId: selectedVehicleType.vehicleTypeId,
      };
    }
    return requestBody;
  };
  const handleNextBtnClick = async () => {
    if (validateFirstPageInputs()) {
      setHasDistanceAndLatLng(true);
      try {
        const [pickupLocationDetails, dropoffLocationDetails] =
          await Promise.all([
            getLatLng(pickUpPlaceId), // returns pickup locations lat, long, city and address
            getLatLng(dropOffPlaceId), // returns dropoff locations lat, long, city and address
          ]);

        setPickupPlaceLocation({...pickupLocationDetails,address:formData.pickup});
        setDropoffPlaceLocation({...dropoffLocationDetails,address:formData.dropoff});
        const result = await calculateDistance(
          formData.pickup,
          formData.dropoff
        );
        setDistance(result);
        setSwitchPage(true);
      } catch (err) {
        if (err.message === `no cityError ${pickUpPlaceId}`) {
          setFirstPageErrors((prev) => ({ ...prev, pickup: "error" }));
        }
        if (err.message === `no cityError ${dropOffPlaceId}`) {
          setFirstPageErrors((prev) => ({ ...prev, dropoff: "error" }));
        } else {
          handleOpenToast("Cannot perform this action. Try again", "error");
        }
      }
      setHasDistanceAndLatLng(false);
    }
  };
  const handleCreateEnquiry = async () => {
    
    if (validateSecondPageInputs()) {
      const details = generatePayload(
        pickupPlaceLocation,
        dropoffPlaceLocation
      );
      // return
      try {
        const response = await createEnquiryFromWhatsapp(details);
        // handleOpenToast("Enquiry created successfully")
        navigate(`/enquiry-form/${userId}/success`, {
          state: {
            details: {
              enqId: response.data.data._id,
              pickup: formData.pickup,
              dropoff: formData.dropoff,
              distance,
              userId,
            },
          },
        });
      } catch (err) {
        handleOpenToast(err?.message ?? "Couldn't create enquiry !", "error");
      }
    }
  };
  const handleBackBtnClickOnSecondPage = () => {
    setSwitchPage(false);
    setHasDistanceAndLatLng(false);
    // resetting errors of second page
    setSecondPageErrors({});
    // resetting inputs on second page
    setFormData((prev) => {
      return { ...prev, vehicleCategory: "", vehicleType: "", slot: "" };
    });
  };
  return (
    <Box maxWidth="sm" m="auto" py={3}>
      <Box px={3}>
        <img
          src="/assets/icons/LZ-logo-face.svg"
          style={{ marginBottom: ".35rem", marginRight: ".25rem" }}
          alt="logoface"
        />
        <img src="/assets/icons/LZ-logo.svg" alt="logo" />
      </Box>

      {!switchPage ? (
        <Box mt={4} px={3}>
          <Typography gutterBottom fontWeight={700} color="#333">
            Welcome!
          </Typography>
          <Typography fontSize={14}>
            Start your enquiry by filling out these details
          </Typography>
          <Stack direction="column" gap={1} mt={2}>
            <Box>
              <InputLabel sx={labelStyle}>Pickup Location</InputLabel>
              <PlacesAutocomplete
                onPlaceSelect={handlePickupSelect}
                error={Boolean(firstPageErrors.pickup)}
                value={formData.pickup}
              />
            </Box>
            <Box>
              <InputLabel sx={labelStyle}>Dropoff Location</InputLabel>
              <PlacesAutocomplete
                onPlaceSelect={handleDropoffSelect}
                error={Boolean(firstPageErrors.dropoff)}
                value={formData.dropoff}
              />
            </Box>
            <Stack>
              <InputLabel sx={labelStyle}>Material Name</InputLabel>
              <Select
                displayEmpty
                value={formData.material}
                error={Boolean(firstPageErrors.material)}
                onChange={handleInputChange}
                name="material"
                sx={{ color: formData.material ? "#333" : "#ADADAD" }}
              >
                <MenuItem value="" sx={{ display: "none" }}>
                  Select
                </MenuItem>
                {materialNameList.map((material) => (
                  <MenuItem value={material.value} key={material.id}>
                    {material.value}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Box>
              <InputLabel sx={labelStyle}>Weight</InputLabel>
              <Stack direction="row" spacing={2}>
                <TextField
                  name="weight"
                  placeholder="Enter Weight"
                  autoComplete="off"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      paddingRight: 0,
                    },
                  }}
                  fullWidth
                  value={formData.weight}
                  onChange={handleInputChange}
                  error={Boolean(firstPageErrors.weight)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Select
                          value={unit}
                          onChange={(e) => setUnit(e.target.value)}
                          sx={{
                            width: "100px",
                            borderColor: "red",
                            "& .MuiOutlinedInput-notchedOutline , &.Mui-focused .MuiOutlinedInput-notchedOutline ,& .MuiOutlinedInput-root":
                              {
                                borderColor: "#EAEAEA !important",
                              },
                          }}
                        >
                          <MenuItem value="KG">KG</MenuItem>
                          <MenuItem value="TONS">TONS</MenuItem>
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Box>
            <Box>
              <InputLabel sx={labelStyle}>Vehicle Category</InputLabel>
              {/* <Select
                value={formData.vehicleCategory}
                fullWidth
                name="vehicleCategory"
                onChange={handleInputChange}
                displayEmpty
                sx={{
                  color: formData.vehicleCategory ? "#333" : "#ADADAD",
                }}
                error={Boolean(firstPageErrors.vehicleCategory)}
              >
                <MenuItem value="" sx={{ display: "none" }}>
                  Select
                </MenuItem>
                <MenuItem value={1}>No preference (any)</MenuItem>
                {vehicleCategoryList &&
                  vehicleCategoryList.map((category, idx) => (
                    <MenuItem key={idx} value={category.value}>
                      {category.value}
                    </MenuItem>
                  ))}
              </Select> */}
              <PreSelectedCategory
                data={recommendedVehicles}
                formData={formData}
                setFormData={setFormData}
                selectedValue={formData.vehicleCategory}
                // onChange={handleFormDataChange}
              />
            </Box>
          </Stack>
          {JSON.stringify(firstPageErrors) !== "{}" && (
            <FormHelperText
              sx={{ color: "#d32f2f", position: "relative", top: 10, left: 8 }}
            >
              {firstPageErrors.pickup === "error" ||
              firstPageErrors.dropoff === "error"
                ? "Pick more accurate location*"
                : "Please fill mandatory fields*"}
            </FormHelperText>
          )}
          <Stack mt={4}>
            <Button
              variant="contained"
              color="primary"
              sx={{ paddingBlock: ".75rem" }}
              onClick={handleNextBtnClick}
              endIcon={
                hasDistanceAndLatLng && (
                  <CircularProgress size={20} sx={{ color: "#fff" }} />
                )
              }
              disabled={hasDistanceAndLatLng}
            >
              Next
            </Button>
          </Stack>
        </Box>
      ) : (
        <Box mt={2}>
          <Stack
            direction="column"
            textAlign="center"
            sx={{ background: "#EAEAEA" }}
            p={2}
            mb={2}
            borderRadius={0.5}
          >
            <Typography fontSize=".9rem">{formData.pickup}</Typography>
            <Stack direction="column" alignItems="center">
              <EastIcon />
              <Typography fontWeight={700} fontSize=".9rem" color="#333">
                {distance} Km
              </Typography>
            </Stack>
            <Typography fontSize=".9rem">{formData.dropoff}</Typography>
          </Stack>

          <Stack direction="column" gap={1} px={3}>
            <Box>
              <InputLabel sx={labelStyle}>No of Pkgs</InputLabel>
              <TextField
                placeholder="No of Pkgs (optional)"
                fullWidth
                value={formData.quantity}
                name="quantity"
                onChange={handleInputChange}
              />
            </Box>
            {/* <Box>
              <InputLabel sx={labelStyle}>Vehicle Type</InputLabel>
              <Select
                fullWidth
                name="vehicleType"
                displayEmpty
                value={formData.vehicleType}
                onChange={handleInputChange}
                disabled={
                  formData.vehicleCategory === 1 || !formData.vehicleCategory
                }
                sx={{
                  color: formData.vehicleCategory ? "#333" : "#ADADAD",
                }}
                error={Boolean(secondPageErrors.vehicleType)}
              >
                <MenuItem value="" sx={{ display: "none" }}>
                  Select
                </MenuItem>
                {vehicleModelList &&
                  vehicleModelList.map((model) => (
                    <MenuItem key={model.vehicleTypeId} value={model.modelName}>
                      {model.modelName}
                    </MenuItem>
                  ))}
              </Select>
            </Box> */}
            <Box>
              <InputLabel sx={labelStyle}>Pickup Date</InputLabel>
              <Stack>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    slots={{
                      openPickerIcon: EditCalendarRoundedIcon,
                    }}
                    format="DD/MM/YYYY"
                    value={moment(formData.date)}
                    minDate={moment(new Date())}
                    defaultValue={moment(new Date())}
                    name="date"
                    onChange={(newValue) => {
                      const dateStr = newValue.format("YYYY-MM-DD");
                      setFormData((prev) => ({ ...prev, date: dateStr }));
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        // height: "2.75rem",
                        "&.Mui-error": {
                          "& fieldset": {
                            borderColor: "#c6c6c6",
                          },
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Box>
            <Box>
              <InputLabel sx={labelStyle}>Pickup Slot</InputLabel>
              <Select
                name="slot"
                onChange={handleInputChange}
                fullWidth
                displayEmpty
                value={formData.slot}
                sx={{
                  color: formData.vehicleCategory ? "#333" : "#ADADAD",
                }}
                error={Boolean(secondPageErrors.slot)}
              >
                <MenuItem value="" sx={{ display: "none" }}>
                  Select
                </MenuItem>
                {recommendedTimeSlots.map((slot) => (
                  <MenuItem key={slot.id} value={slot.value}>
                    {slot.value}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box>
            <InputLabel sx={labelStyle}>Name</InputLabel>
              <TextField
                placeholder="Enter name"
                fullWidth
                value={formData.username}
                name="username"
                onChange={handleInputChange}
                error={Boolean(secondPageErrors.username)}
              />
            </Box>
            {JSON.stringify(secondPageErrors) !== "{}" && (
              <FormHelperText
                sx={{ color: "#d32f2f", position: "relative", left: 8 }}
              >
                Please fill mandatory fields*
              </FormHelperText>
            )}
          </Stack>

          <Stack mt={3} px={3}>
            <Button
              variant="contained"
              onClick={handleCreateEnquiry}
              sx={{ paddingBlock: ".75rem" }}
            >
              Create Enquiry
            </Button>
          </Stack>
          <Stack px={3} mt={2}>
            <Button
              sx={{
                textTransform: "none",
                textDecoration: "underline",
                alignSelf: "center",
              }}
              onClick={handleBackBtnClickOnSecondPage}
            >
              Back
            </Button>
          </Stack>
        </Box>
      )}
      <ToastMessage />
    </Box>
  );
};
export default WhatsAppEnquiryForm;

const PreSelectedCategory = ({
  data,
  formData,
  setFormData,
  selectedValue,
}) => {
  const [isRecommended, setIsRecommended] = useState(false);
  // const [value, setValue] = useState('');

  // Effect hook to update the value based on the data input
  useEffect(() => {
    // if(value) setValue(value)
    // if(!selectedValue){
    if (data.length > 0) {
      setFormData((p) => ({ ...p, vehicleCategory: data[0].value }));
      setIsRecommended(true);
    } else {
      setFormData((p) => ({ ...p, vehicleCategory: "No Preference" }));
      setIsRecommended(false);
    }
    // }
  }, [data]); // Re-run the effect when data changes

  const handleChange = (event) => {
    // setValue(event.target.value);
    setFormData((p) => ({ ...p, vehicleCategory: event.target.value }));
    setIsRecommended(data.length > 0 && event.target.value === data[0].value);
  };

  return (
    <div>
      {/* <InputLabel id="demo-simple-select-label">Select</InputLabel> */}
      {/* <InputLabel shrink sx={labelStyle}>
        Vehicle Category
      </InputLabel> */}
      <Select
        labelId="demo-simple-select-label"
        value={formData.vehicleCategory}
        onChange={handleChange}
        fullWidth
        name="vehicleCategory"
        sx={{
          color: "#333",
          // height: "2.75rem",
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ADADAD",
          },
        }}
      >
        {data.length > 0 ? (
          data.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.value}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="No Preference">No Preference</MenuItem>
        )}
      </Select>
      {isRecommended && (
        <Stack direction="row" alignItems="center" gap={0.25} ml={.5}>
          <TaskAltIcon style={{ color: "green", fontSize: 16,fontWeight:700 }} />
          <Typography
            color="green"
            style={{ fontSize: 12, marginTop: 0, fontWeight: 700 }}
          >
            Recommended
          </Typography>
        </Stack>
      )}
    </div>
  );
};
