import { client } from "../config";

export const createBid = async (bidData) => {
  try {
    const response = await client.post(`/transporter/bid`, bidData);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateBid = async (bidId, bidData) => {
  try {
    const response = await client.put(`/transporter/bid/${bidId}`, bidData);
    return response;
  } catch (error) {
    return error;
  }
};

export const getBidDetailsByBidId = async (bidId) => {
  try {
    const response = await client.get(`transporter/bid/${bidId}`);
    return response;
  } catch (err) {
    return err;
  }
};
