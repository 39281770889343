import React from "react";
import { useSelector } from "react-redux";

import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";

import SimpleCard from "../../Common/SimpleCard";

const StyledBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  backgroundColor: "#DDEFDD",
  padding: "20px",
}));

const StyledText = styled(Typography)(
  ({ fontSize, textTransform, fontWeight }) => ({
    color: "#4F4848",
    fontSize: fontSize || "12px",
    fontWeight: fontWeight || "400",
    textTransform: textTransform || "capitalize",
  })
);

const StyledLine = styled(Typography)(() => ({
  border: "1px solid #6A6A6A",
}));

const BoxContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "30px",
  justifyContent: "center",
  width: "100%",
}));

export default function SupportNumber(props) {
  const { tripDetails, customerSupportNumber } = props;
  const supportNumber = useSelector(
    (state) => state.supportNumbers?.transporterPortal
  );
  const customerPortalSupportNumber = useSelector(
    (state) => state.supportNumbers?.customerPortal
  );

  return (
    <StyledBox>
      <Box fontWeight={700} fontSize={14}>
        #{tripDetails?.tripId}
      </Box>

      <BoxContainer>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="60%"
          gap="10px"
        >
          <Box width="40%">
            <StyledText sx={{ wordWrap: "break-word" }}>
              {tripDetails?.enquiryDetails?.pickup.exactLocation.address},
              {tripDetails?.enquiryDetails?.pickup.exactLocation.city},
              {tripDetails?.enquiryDetails?.pickup.exactLocation.state},
            </StyledText>
            {tripDetails?.pickupContactPerson?.name && (
              <Box fontWeight="700" fontSize={12}>
                {tripDetails?.pickupContactPerson?.name},{" "}
                {tripDetails?.pickupContactPerson?.phoneNumber}
              </Box>
            )}
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="20%"
          >
            <Typography textAlign="center" fontSize="12px">
              {tripDetails?.enquiryDetails?.distance} Km
            </Typography>
            <img
              src="/assets/icons/distanceArrow.svg"
              alt="addImage"
              height="20px"
              width="100%"
            />
          </Box>

          <Box width="40%">
            <StyledText sx={{ wordWrap: "break-word" }}>
              {tripDetails?.enquiryDetails?.dropoff.exactLocation.address},
              {tripDetails?.enquiryDetails?.dropoff.exactLocation.city},
              {tripDetails?.enquiryDetails?.dropoff.exactLocation.state},
            </StyledText>
            {tripDetails?.dropOffContactPerson?.name && (
              <Box fontWeight="700" fontSize={12}>
                {tripDetails?.dropOffContactPerson?.name},{" "}
                {tripDetails?.dropOffContactPerson?.phoneNumber}
              </Box>
            )}
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" width="40%">
          <Box
            display="flex"
            flexDirection="row"
            paddingBottom="15px"
            marginTop="15px"
            gap="10px"
            width="100%"
          >
            <SimpleCard
              heading={tripDetails?.enquiryDetails?.material?.name}
              subHeading={"Material"}
              width="50%"
              fromDashboard={true}
            />
            <SimpleCard
              heading={`${tripDetails?.enquiryDetails?.weight / 1000} T`}
              subHeading={"Weight"}
              width="50%"
              fromDashboard={true}
            />
          </Box>
        </Box>
      </BoxContainer>

      <StyledLine />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        padding={6}
        gap="10px"
      >
        <AddIcCallIcon
          sx={{
            width: "50px",
            height: "50px",
          }}
        />
        <Box fontWeight="700" fontSize="18px">
          For any queries related to this trip, please call our support team.
        </Box>
        <Box fontSize="24px">
          Support Number{" "}
          <span style={{ fontWeight: "700", fontSize: "24px" }}>
            {(customerSupportNumber && customerPortalSupportNumber) ||
              supportNumber}
          </span>
        </Box>
      </Box>
    </StyledBox>
  );
}
