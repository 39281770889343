import React, { useEffect, useState } from "react";
import { Box, Skeleton, Typography, Grid } from "@mui/material";
import CloseEnquiryDialog from "./CloseEnquiryDialog";
import { StyledDeliverySectionBox } from "./StyledDeliverySectionBox";
import { StyledDeliverySectionTextPrimary } from "./StyledDeliverySectionTextPrimary";
import { LOADING_STATUS } from "../../constants/constant";
import {
  closeEnquiry,
  getCustomerEnquiryDetailsById,
} from "../../api/Customer/enquiry";
import { useSelector } from "react-redux";
import { truncateString } from "../../utils/textFormat";
import { setEnquiryStatusTracking } from "../../redux/slices/customerSlice";
import { useDispatch } from "react-redux";
import ErrorScreen from "../Common/ErrorScreen";
import useToast from "../../hooks/useToast";

export const FindingPartnerCollapse = ({ enquiry }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [enquiryDetails, setEnquiryDetails] = useState({});
  const [enquiryDetailsFetched, setIsEnquiryDetailsFetched] = useState("");
  const dispatch = useDispatch();
  const { handleOpenToast, ToastMessage } = useToast();
  const customerId = useSelector((state) => state.customer.id);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleCloseEnquiry = async () => {
    var enquiryId = enquiryDetails?.enquiryId;
    try {
      const response = await closeEnquiry(customerId, enquiryId);
      if (response.status === 200) {
        handleCloseDialog();
        handleOpenToast("Enquiry closed successfully!", "success");
        await new Promise((resolve) => setTimeout(resolve, 3000));
        await dispatch(setEnquiryStatusTracking(`${enquiryId}-enquiry-closed`));
      }
    } catch (error) {
      handleOpenToast(`Error while closing enquiry!`, "error");
    } finally {
      setIsDialogOpen(false);
    }
  };

  const fetchCustomerEnquiryDetails = async () => {
    setIsEnquiryDetailsFetched(LOADING_STATUS.LOADING);
    const response = await getCustomerEnquiryDetailsById(
      customerId,
      enquiry?.enquiryId
    );
    if (response.status === 200) {
      const details = response?.data.data.enquiry;
      setEnquiryDetails(details);
      setIsEnquiryDetailsFetched(LOADING_STATUS.LOADED);
    } else {
      setIsEnquiryDetailsFetched(LOADING_STATUS.FAILED);
    }
  };

  useEffect(() => {
    fetchCustomerEnquiryDetails();
  }, []);

  if (enquiryDetailsFetched === LOADING_STATUS.LOADED) {
    return (
      <>
        <ToastMessage />
        <Box
          sx={{
            background: "#DDEFDD",
            padding: "16px 24px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4} sx={{ whiteSpace: "normal" }}>
              <StyledDeliverySectionBox>
                <StyledDeliverySectionTextPrimary>
                  {truncateString(
                    enquiryDetails?.pickup?.location?.address,
                    100
                  )}
                </StyledDeliverySectionTextPrimary>
              </StyledDeliverySectionBox>
            </Grid>
            <Grid item xs={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                px={2}
              >
                <Typography sx={{ fontSize: 12, fontWeight: 400 }}>
                  {`${enquiryDetails?.distance}km`}
                </Typography>
                <img src="/assets/icons/distanceArrowLong.svg" alt="distance" />
              </Box>
            </Grid>
            <Grid item xs={4} sx={{ whiteSpace: "normal" }}>
              <StyledDeliverySectionBox>
                <StyledDeliverySectionTextPrimary>
                  {truncateString(
                    enquiryDetails?.dropoff?.location?.address,
                    100
                  )}
                </StyledDeliverySectionTextPrimary>
              </StyledDeliverySectionBox>
            </Grid>
          </Grid>
          <Box onClick={handleOpenDialog}>
            <Typography
              sx={{
                color: "#FF3F3F",
                fontSize: 14,
                fontWeight: 400,
                textDecoration: "underline",
                cursor: "pointer",
                p: 1,
              }}
            >
              Close Enquiry
            </Typography>
          </Box>
          <Box sx={{ p: 1 }}>
            <Typography
              sx={{ fontSize: 18, fontWeight: 700, color: "#333333" }}
            >
              LoadEazy is searching the best match for you...
            </Typography>
          </Box>
        </Box>
        <CloseEnquiryDialog
          open={isDialogOpen}
          handleClose={handleCloseDialog}
          enquiryDetails={enquiryDetails}
          enquiryDetailsFetched={enquiryDetailsFetched}
          handleCloseEnquiry={handleCloseEnquiry}
        />
      </>
    );
  } else if (enquiryDetailsFetched === LOADING_STATUS.FAILED) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <ErrorScreen onRetry={fetchCustomerEnquiryDetails} />;
      </Box>
    );
  } else if (enquiryDetailsFetched === LOADING_STATUS.LOADING) {
    return (
      <Box sx={{ width: "100%" }} p={3}>
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
      </Box>
    );
  }
};
