import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Grid,
  List,
  ListItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  getEnquiryStats,
  getTripStats,
  getKycStats,
  getSupportTicketStats,
  getCustomerActivityStats,
  getTransporterActivityStats,
  getDriverAvailabilityStats,
  getTransporterVehicleAvailabilityStats,
} from "../../../api/Admin/dashboard";
import useToast from "../../../hooks/useToast";
import styled from "@emotion/styled";

const StatusCard = ({ title, data, navigation }) => {
  const navigate = useNavigate();
  return (
    <Grid item xs={12} md={6} lg={4}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontWeight: "bold", mb: 2, fontSize: 16 }}>
            {title}
          </Typography>
          <StyledCardButton
            variant="contained"
            onClick={() => navigate(navigation)}
          >
            View All
          </StyledCardButton>
        </Box>
        <List>
          {Object.keys(data).map((key) => (
            <ListItem
              key={key}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                bgcolor: "#f8f8f8",
                mb: 1,
              }}
            >
              <Typography fontSize="12px">{key}</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: "bold", mr: 1, fontSize: 12 }}>
                  {data[key]}
                </Typography>
              </Box>
            </ListItem>
          ))}
        </List>
      </Paper>
    </Grid>
  );
};

const Dashboard = () => {
  const [enquiryStats, setEnquiryStats] = useState({});
  const [tripStats, setTripStats] = useState({});
  const [kycStats, setKycStats] = useState({});
  const [supportStats, setSupportStats] = useState({});
  const [customerActivityStats, setCustomerActivityStats] = useState({});
  const [transporterActivityStats, setTransporterActivityStats] = useState({});
  const [driverAvailabilityStats, setDriverAvailabilityStats] = useState({});
  const [
    transporterVehicleAvailabilityStats,
    setTransporterVehicleAvailabilityStats,
  ] = useState({});

  const { handleOpenToast, ToastMessage } = useToast();

  const getEnquiryStat = async () => {
    try {
      const response = await getEnquiryStats();
      setEnquiryStats(response.data.data);
    } catch (error) {
      handleOpenToast("Error while fetching enquiry count", "error");
    }
  };

  const getTripStat = async () => {
    try {
      const response = await getTripStats();
      setTripStats(response.data.data);
    } catch (error) {
      handleOpenToast("Error while fetching trip count", "error");
    }
  };

  const getKycStat = async () => {
    try {
      const response = await getKycStats();
      setKycStats(response.data.data);
    } catch (error) {
      handleOpenToast("Error while fetching kyc count", "error");
    }
  };

  const getSupportStat = async () => {
    try {
      const response = await getSupportTicketStats();
      setSupportStats(response.data.data);
    } catch (error) {
      handleOpenToast("Error while fetching support count", "error");
    }
  };

  const getCustomerActivityStat = async () => {
    try {
      const response = await getCustomerActivityStats();
      setCustomerActivityStats(response.data.data);
    } catch (error) {
      handleOpenToast("Error while fetching customer count", "error");
    }
  };

  const getTransporterActivityStat = async () => {
    try {
      const response = await getTransporterActivityStats();
      setTransporterActivityStats(response.data.data);
    } catch (error) {
      handleOpenToast("Error while fetching transporter count", "error");
    }
  };

  const getDriverAvailabilityStat = async () => {
    try {
      const response = await getDriverAvailabilityStats();
      setDriverAvailabilityStats(response.data.data);
    } catch (error) {
      handleOpenToast("Error while fetching driver count", "error");
    }
  };

  const getTransporterVehicleAvailabilityStat = async () => {
    try {
      const response = await getTransporterVehicleAvailabilityStats();
      setTransporterVehicleAvailabilityStats(response.data.data);
    } catch (error) {
      handleOpenToast("Error while fetching truck count", "error");
    }
  };

  const statistics = {
    enquiry: {
      "Total Enquiries": enquiryStats?.total || 0,
      "No Service": enquiryStats?.noService || 0,
      Quoted: enquiryStats?.quoted || 0,
      "Not Quoted": enquiryStats?.notQuoted || 0,
      "Converted to Trip": enquiryStats?.convertedToTrip || 0,
      "Expired Enquiry": enquiryStats?.expired || 0,
      "Closed Enquiry": enquiryStats?.closed || 0,
      Rejected: enquiryStats?.rejected || 0,
    },
    trips: {
      "Total Trips": tripStats?.total || 0,
      Upcoming: tripStats?.upcoming || 0,
      "In Transit": tripStats?.inTransit || 0,
      Completed: tripStats?.completed || 0,
      Canceled: tripStats?.cancelled || 0,
    },
    kyc: {
      "Total KYC Approvals": kycStats?.total || 0,
      Approved: kycStats?.kycApproved || 0,
      Pending: kycStats?.kycPending || 0,
      Incomplete: kycStats?.kycIncomplete || 0,
      Rejected: kycStats?.kycRejected || 0,
    },
    support: {
      "Total Support Tickets": supportStats?.total || 0,
      "High Priority": supportStats?.high || 0,
      "Medium Priority": supportStats?.medium || 0,
      "Low Priority": supportStats?.low || 0,
    },
    customer: {
      "Total Customers": customerActivityStats?.total || 0,
      Active: customerActivityStats?.active || 0,
      Inactive: customerActivityStats?.inactive || 0,
      Blocked: customerActivityStats?.blocked || 0,
    },
    transporter: {
      "Total Transporters": transporterActivityStats?.total || 0,
      Active: transporterActivityStats?.active || 0,
      Inactive: transporterActivityStats?.inactive || 0,
      Blocked: transporterActivityStats?.blocked || 0,
    },
    driver: {
      "Total Drivers": driverAvailabilityStats?.total || 0,
      Available: driverAvailabilityStats?.available || 0,
      Unavailable: driverAvailabilityStats?.unavailable || 0,
      "In Transit": driverAvailabilityStats?.inTransit || 0,
      Invited: driverAvailabilityStats?.invited || 0,
    },
    truck: {
      "Total Trucks": transporterVehicleAvailabilityStats?.total || 0,
      Available: transporterVehicleAvailabilityStats?.available || 0,
      Unavailable: transporterVehicleAvailabilityStats?.unavailable || 0,
      "In Transit": transporterVehicleAvailabilityStats?.inTransit || 0,
    },
  };

  const paymentData = {
    receivable: {
      "Total Amount Receivable": 313,
      "Amount Received": 131,
      "Balance Receivable": 123,
    },
    payable: {
      "Total Amount Payable": 45,
      "Amount Paid": 40,
      "Balance Payable": 5,
    },
  };

  useEffect(() => {
    getEnquiryStat();
    getTripStat();
    getKycStat();
    getSupportStat();
    getCustomerActivityStat();
    getTransporterActivityStat();
    getDriverAvailabilityStat();
    getTransporterVehicleAvailabilityStat();
  }, []);

  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex", p: 2, flexDirection: "column" }}>
      <ToastMessage />
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <StatusCard
          title="Enquiry Summary"
          data={statistics.enquiry}
          navigation="/admin/app/enquiries"
        />
        <StatusCard
          title="Trip Overview"
          data={statistics.trips}
          navigation="/admin/app/trips"
        />
        <StatusCard
          title="KYC Approval"
          data={statistics.kyc}
          navigation="/admin/app/approval"
        />
        <StatusCard
          title="Customer"
          data={statistics.customer}
          navigation="/admin/app/resources/customers"
        />
        <StatusCard
          title="Transporter"
          data={statistics.transporter}
          navigation="/admin/app/resources/transporters"
        />
        <StatusCard
          title="Driver"
          data={statistics.driver}
          navigation="/admin/app/resources/drivers"
        />
        <StatusCard
          title="Truck"
          data={statistics.truck}
          navigation="/admin/app/resources/trucks"
        />
        <Grid item xs={12} md={6} lg={4}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ fontWeight: "bold", mb: 2, fontSize: 16 }}>
                Support Summary
              </Typography>
              <StyledCardButton
                variant="contained"
                color="success"
                onClick={() => navigate("/admin/app/support")}
              >
                View All
              </StyledCardButton>
            </Box>
            <Box>
              <Button
                variant="outlined"
                color="success"
                sx={{
                  height: 25,
                  fontSize: 12,
                  width: "100%",
                  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                  border: "1.3px solid #0C7F44",
                  textTransform: "none",
                  borderRadius: "2px",
                  fontWeight: "normal",
                }}
                startIcon={
                  <img src="/assets/icons/plusButton.svg" alt="create ticket" />
                }
                onClick={() => navigate("/admin/app/support/createTicket")}
              >
                Create Ticket
              </Button>
            </Box>
            <List>
              {Object.keys(statistics.support).map((key) => (
                <ListItem
                  key={key}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    bgcolor: "#f8f8f8",
                    mb: 1,
                    fontSize: 9,
                  }}
                >
                  <Typography fontSize="12px">{key}</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{ fontWeight: "bold", mr: 1, fontSize: 12 }}
                    >
                      {statistics.support[key]}
                    </Typography>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Typography sx={{ fontWeight: "bold", mb: 2, fontSize: 16 }}>
            Payment Summary
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}
          >
            <List sx={{ width: "100%" }}>
              {Object.keys(paymentData.receivable).map((key) => (
                <ListItem
                  key={key}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    bgcolor: "#f8f8f8",
                    mb: 1,
                    fontSize: 9,
                  }}
                >
                  <Typography fontSize="12px">{key}</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{ fontWeight: "bold", mr: 1, fontSize: 12 }}
                    >
                      {`₹ ${paymentData.receivable[key]}`}
                    </Typography>
                  </Box>
                </ListItem>
              ))}
            </List>
            <List sx={{ width: "100%" }}>
              {Object.keys(paymentData.payable).map((key) => (
                <ListItem
                  key={key}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    bgcolor: "#f8f8f8",
                    mb: 1,
                    fontSize: 9,
                  }}
                >
                  <Typography fontSize="12px">{key}</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{ fontWeight: "bold", mr: 1, fontSize: 12 }}
                    >
                      {`₹ ${paymentData.payable[key]}`}
                    </Typography>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        </Paper>
      </Grid>
    </Box>
  );
};

export default Dashboard;

const StyledCardButton = styled(Button)(() => ({
  height: 25,
  fontSize: 12,
  fontWeight: "bold",
  borderRadius: "2px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
}));
