import { client } from "../config";

export const getDashboardCounts = async () => {
  try {
    const response = await client.get("/transporter/dashboard-counts");
    return response;
  } catch (error) {
    return error;
  }
};
