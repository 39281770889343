import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import GenerateStats from "../../../component/Common/GenerateStats";
import { StyledTableCell } from "../Approval/StyledTableCell";
import { StyledTableRow } from "../../../component/Common/StyledTableRow";
import LoadingTable from "../../../component/Common/LoadingTable";
import { getAllTransporters } from "../../../api/Admin/transporterResources";
import { LOADING_STATUS } from "../../../constants/constant";
import ErrorScreen from "../../../component/Common/ErrorScreen";
import shortenString from "../../../utils/shortenString";
import moment from "moment";

const TransporterResources = () => {
  const [transporters, setTransporters] = useState([]);
  const [statusFilterValue, setStatusFilterValue] = useState("");
  const [anchorForStatus, setAnchorForStatus] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [paginationData, setPaginationData] = useState({});
  const [countData, setCountData] = useState({});
  const [isTransportersListFetched, setIsTransporterListFetched] = useState(
    LOADING_STATUS.INITIAL
  );
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
  };

  const fetchData = async () => {
    setIsTransporterListFetched(LOADING_STATUS.LOADING);
    const response = await getAllTransporters(
      page,
      inputValue,
      statusFilterValue
    );

    if (response.status === 200) {
      setTransporters(response.data.data.transporters);
      setIsTransporterListFetched(LOADING_STATUS.LOADED);
      setPaginationData(response.data.data.pagination);
      setCountData(response.data.data.counts);
    } else {
      setIsTransporterListFetched(LOADING_STATUS.FAILED);
    }
  };

  // filter popup handlers for status type
  const handleOpenStatusFilter = (event) => {
    setAnchorForStatus(event.currentTarget);
  };
  const handleCloseStatusFilter = () => {
    setAnchorForStatus(null);
  };
  const updateStatusFilterValue = (event) => {
    setStatusFilterValue("KYC_" + event.target.textContent.toUpperCase());
    setPage(0);
    handleCloseStatusFilter();
  };

  const [debouncedSearchText, setDebouncedSearchText] = useState(inputValue);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchText(inputValue);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [inputValue]);

  useEffect(() => {
    fetchData();
  }, [page, debouncedSearchText, statusFilterValue]);

  const statsArray = [
    { name: "Total", value: countData.total || 0 },
    { name: "Active", value: countData.active || 0 },
    {
      name: "Inactive",
      value: countData.inactive || 0,
    },
    {
      name: "Blocked",
      value: countData.blocked || 0,
    },
  ];

  const tableHeaders = [
    "Name",
    "Mobile No.",
    "Joined On",
    "Operational Cities",
    "Company Name",
    "Status",
  ];

  const renderViews = () => {
    switch (isTransportersListFetched) {
      case "loading":
        return <LoadingTable columns={tableHeaders.length} />;

      case "loaded":
        if (transporters.length)
          return <TransporterResourcesTable transporters={transporters} />;
        return (
          <TableRow sx={{ background: "#EAEAEA" }}>
            <TableCell
              colSpan={tableHeaders.length}
              align="center"
              sx={{ fontWeight: 600, color: "#333" }}
            >
              No Results Found.
            </TableCell>
          </TableRow>
        );

      case "failed":
        return (
          <TableRow>
            <TableCell
              colSpan={tableHeaders.length}
              sx={{ borderBottom: "none" }}
            >
              <ErrorScreen />
            </TableCell>
          </TableRow>
        );

      default:
        return null;
    }
  };

  return (
    <Box>
      <Stack
        p=".75rem 1.625rem"
        borderBottom="1px solid #ADADAD"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <GenerateStats data={statsArray} />
        <TextField
          variant="outlined"
          placeholder="Search"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          InputProps={{
            sx: { height: "36.31px" },
            endAdornment: (
              <img src="/assets/icons/search-icon.svg" alt="search" />
            ),
          }}
        />
      </Stack>
      <Box p={3}>
        <TableContainer aria-label="customized table">
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ borderLeft: "1px solid #c6c6c6" }}>
                  Name
                </StyledTableCell>
                <StyledTableCell>Mobile No.</StyledTableCell>
                <StyledTableCell>Operational Cities</StyledTableCell>
                <StyledTableCell>Company Name</StyledTableCell>
                <StyledTableCell>Joined On</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
              </TableRow>
            </TableHead>

            {/* based on loading status views will switch */}
            <TableBody>{renderViews()}</TableBody>
          </Table>
        </TableContainer>
        <div style={{ padding: "10px" }}>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={paginationData.totalItems}
            rowsPerPage={paginationData.pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ count }) => (
              <div>
                Page{" "}
                <span style={{ color: "green", fontWeight: 700 }}>
                  {page + 1}
                </span>{" "}
                of {Math.ceil(count / paginationData.pageSize)}
              </div>
            )}
          />
        </div>
      </Box>
    </Box>
  );
};
export default TransporterResources;

const TransporterResourcesTable = ({ transporters }) => {
  const navigate = useNavigate();

  const generateStatus = (status) => {
    if (status.includes("ACTIVE")) {
      return (
        <TableCell sx={{ color: "green", textAlign: "center" }}>
          {status}
        </TableCell>
      );
    } else {
      return (
        <TableCell sx={{ color: "red", textAlign: "center" }}>
          {status}
        </TableCell>
      );
    }
  };

  function createTransporterRowData(transporter) {
    return {
      id: transporter?.transporterId,
      name: transporter?.transporterName || "-",
      number: transporter?.transporterPhone || "-",
      operationalCities: transporter?.operationalCities.join(", ") || "-",
      companyName: transporter?.businessName || "-",
      joinedOn: moment(transporter.joinedOn).format("DD-MM-YYYY") || "-",
      status: transporter?.transporterStatus || "-",
    };
  }

  const transporterTableData = transporters?.map((transporter) =>
    createTransporterRowData(transporter)
  );

  return transporterTableData?.map((row, index) => {
    return (
      <StyledTableRow
        key={row.id}
        onClick={() => navigate(`/admin/app/resources/transporters/${row.id}`)}
        style={{ textDecoration: "none", cursor: "pointer" }}
      >
        <TableCell scope="row" sx={{ color: "#6D6D6D", textAlign: "center" }}>
          {shortenString(row.name, 18)}
        </TableCell>
        <TableCell sx={{ color: "#6D6D6D", textAlign: "center" }}>
          {row.number}
        </TableCell>
        <TableCell sx={{ color: "#6D6D6D", textAlign: "center" }}>
          {shortenString(row.operationalCities, 16)}
        </TableCell>
        <TableCell sx={{ color: "#6d6d6d", textAlign: "center" }}>
          {shortenString(row.companyName, 16)}
        </TableCell>
        <TableCell sx={{ color: "#6D6D6D", textAlign: "center" }}>
          {row.joinedOn}
        </TableCell>
        {generateStatus(row.status)}
      </StyledTableRow>
    );
  });
};
