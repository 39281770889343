import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { css } from '@emotion/react';

export const StyledTextPrimary = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: #808080;
  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}
`;
