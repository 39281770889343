import { Box, InputLabel, TextField, Typography } from "@mui/material";
import React from "react";
import { StyledInputLabel } from "../Customer/StyledInputLabel";

export const CustomTextInput = ({
  label,
  handleInputChange,
  value,
  error,
  placeholder,
  helperText,
  containerStyle,
}) => {
  return (
    <Box sx={{ width: "100%", ...containerStyle }}>
      <StyledInputLabel>{label}</StyledInputLabel>
      <TextField
        variant="outlined"
        value={value}
        placeholder={placeholder}
        fullWidth
        InputProps={{
          style: {
            height: "50px",
            marginTop: "4px",
            width: "100%",
          },
        }}
        onChange={handleInputChange}
        helperText={helperText}
      />
      <small style={{ color: "red", fontSize: 12 }}>{error}</small>
    </Box>
  );
};
