import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import styled from "@emotion/styled";
import useToast from "../../../../hooks/useToast";
import InviteEditDriverModal from "./Drivers/InviteEditDriverModal";
import { useSelector } from "react-redux";
import DriverCardDetails from "./Drivers/DriverCardDetails";
import DeleteDriverModal from "./Drivers/DeleteDriverModal";
import { getAllDriverData } from "../../../../api/Transporter/driver";
import ErrorScreen from "../../../../component/Common/ErrorScreen";
import { LOADING_STATUS } from "../../../../constants/constant";
import LoadingTable from "../../../../component/Common/LoadingTable";
import SearchBox from "../../../../component/Common/SearchBox";
import TripDetails from "../../../../component/Transporter/Resources/Trucks/TripDetails";
import { getDriverTrips } from "../../../../api/Transporter/trip";

export default function Drivers() {
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    border: "6px solid #FFF",
    background: "#F6F6F6",
    "&:hover": {
      background: "#E9F2F4",
    },
    textDecoration: "none",
    cursor: "pointer",
    td: {
      paddingInline: "1rem",
      paddingBlock: ".35rem",
      fontWeight: 700,
      fontSize: "12px",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#FFFFFF",
      color: "#333333",
      borderRight: "1px solid #c6c6c6",
      fontWeight: 700,
      width: "25%",
    },
  }));

  const transporterTruckStatusCountContainer = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "6px 10px",
    border: "1px solid #C6C6C6",
    background: "#EAEAEA",
    gap: "1rem",
    borderRadius: 1,
  };

  const headerText = {
    color: "#333",
    fontWeight: 700,
    fontSize: "18px",
  };

  // Varibale to show toast messages.
  const [isDriverDataFetched, setIsDriverDataFetched] = useState(
    LOADING_STATUS.INITIAL
  );

  // Varibale to show toast messages.
  const { toastData, handleCloseToast, handleOpenToast, ToastMessage } =
    useToast();

  // This state variable controls the visibility of the 'Invite Driver' modal.
  const [isInviteDriverModalOpen, setIsInviteDriverModalOpen] = useState(false);

  // This state variable controls the visibility of the 'Edit Driver' modal.
  const [isEditModeOn, setIsEditModeOn] = useState(false);

  // State to store data related to drivers
  const [driverData, setDriverData] = useState([]);

  // State to manage the visibility of the delete driver modal
  const [isDeleteDriverModalOpen, setIsDeleteDriverModalOpen] = useState(false);

  // State to keep track of the selected driver's data or identifier
  const [selectedDriver, setSelectedDriver] = useState([]);

  // State to keep track of the current selected driver
  const [currentSelectedDriver, setCurrentSelectedDriver] = useState([]);

  const [tripData, setTripData] = useState([]);

  const [isTripDataLoading, setIsTripDataLoading] = useState(false);

  // State to keep track of kyc status of transporter.
  const [transporterKycStatus, setTransporterKycStatus] = useState();

  // Function to open the 'Invite Driver' modal by setting isInviteDriverModalOpen to true.
  const openAddDriverModal = () => {
    if (transporterKycStatus === "KYC_PENDING") {
      handleOpenToast("Please wait for kyc approval from admin.", "warning");
      return;
    } else if (transporterKycStatus === "KYC_INCOMPLETE") {
      handleOpenToast("Please upload the kyc documents.", "error");
      return;
    } else if (transporterKycStatus === "KYC_REJECTED") {
      handleOpenToast(
        "Your KYC documents were rejected. Please reupload KYC documents.",
        "error"
      );
      return;
    }
    setIsInviteDriverModalOpen(true);
    setIsEditModeOn(false);
  };

  // Function to edit the driver modal.
  const openEditDriverModal = () => {
    setIsInviteDriverModalOpen(true);
    setIsEditModeOn(true);
  };

  // Function to close the 'Invite Driver' modal by setting isInviteDriverModalOpen to false.
  const closeInviteDriverModal = () => {
    setIsInviteDriverModalOpen(false);
  };

  // Function to open the 'Delete Driver' modal by setting isDeleteDriverModalOpen to true.
  const openDeleteDriverModal = () => {
    closeInviteDriverModal();
    setIsDeleteDriverModalOpen(true);
  };

  // Function to close the 'Delete Driver' modal by setting isDeleteDriverModalOpen to false.
  const closeDeleteDriverModal = () => {
    setIsDeleteDriverModalOpen(false);
  };

  // Function to select the driver.
  const handleSelectedDriver = (index) => {
    setSelectedDriver(driverData[index]);
  };

  // State for pagination data
  const [paginationData, setPaginationData] = useState({});

  // State for managing the current page number in pagination
  const [page, setPage] = useState(0);

  // Function to handle a change in page number in pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // State to manage the search value
  const [searchValue, setSearchValue] = useState(null);

  // State to store the total available drivers
  const [totalAvailableDrivers, setTotalAvailableDrivers] = useState(0);

  // State to store the total unavailable drivers
  const [totalUnavailableDrivers, setTotalUnavailableDrivers] = useState(0);

  // State to store the total invited drivers
  const [totalInvitedDrivers, setTotalInvitedDrivers] = useState(0);

  // State to manage the anchor element for the filter popover
  const [filterPopoverAnchorEl, setFilterPopoverAnchorEl] = useState(null);

  // Accessing transporter id from application state.
  const transporterId = useSelector((state) => state.transporterId.id);

  // state to store debounced search
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchValue);

  // Handle opening the filter popover
  const handleFilterPopoverOpen = (event) => {
    setFilterPopoverAnchorEl(event.currentTarget);
  };

  const [filterValue, setFilterValue] = useState([]);

  // Handle closing the filter popover
  const handleFilterPopoverClose = () => {
    setFilterPopoverAnchorEl(null);
  };

  // Check if the filter popover is open
  const isFilterPopoverOpen = Boolean(filterPopoverAnchorEl);

  // Function to update filter value
  const handleFilterValueChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setFilterValue([...filterValue, value]);
    } else {
      setFilterValue(filterValue.filter((item) => item !== value));
    }
  };

  const handleSearchBoxChange = (value) => {
    setSearchValue(value);
  };

  const fetchAllDriversDetails = async () => {
    try {
      setIsDriverDataFetched(LOADING_STATUS.LOADING);
      const pageSize = 15;
      const response = await getAllDriverData(
        transporterId,
        page + 1,
        pageSize,
        filterValue.join(","),
        searchValue || ""
      );
      if (response.status === 200) {
        setTransporterKycStatus(response?.data?.data?.kycStatus);
        setTotalAvailableDrivers(response?.data?.data?.counts?.AVAILABLE);
        setTotalUnavailableDrivers(response?.data?.data?.counts?.UNAVAILABLE);
        setTotalInvitedDrivers(response?.data?.data?.counts?.INVITED);
        const driverData = response?.data?.data?.drivers;
        setDriverData(driverData);
        if (driverData.length) {
          setSelectedDriver(driverData[0]);
        }
        setIsDriverDataFetched(LOADING_STATUS.LOADED);
        setPaginationData(response?.data?.data?.pagination);
      }
    } catch (error) {
      setIsDriverDataFetched(LOADING_STATUS.FAILED);
    }
  };

  const getTrips = async () => {
    try {
      setIsTripDataLoading(true);
      const response = await getDriverTrips(
        transporterId,
        selectedDriver.driverId
      );
      if (response.status === 200) {
        setTripData(response.data.data.trips);
      }
      setIsTripDataLoading(false);
    } catch (error) {
      setIsTripDataLoading(false);
      handleOpenToast("Failed to fetch trip data", "error");
    }
  };

  const updateLocalDriverStatus = (driverId, newStatus) => {
    const updatedDrivers = driverData.map((driver) => {
      if (driver.driverId === driverId) {
        return { ...driver, status: newStatus };
      }
      return driver;
    });
    setDriverData(updatedDrivers);
  };

  useEffect(() => {
    if (selectedDriver.driverId) {
      getTrips();
    }
  }, [selectedDriver]);

  // Debounce search value for 500ms
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  useEffect(() => {
    if (transporterId) {
      fetchAllDriversDetails();
    }
  }, [page, transporterId, filterValue, debouncedSearchValue]);

  function renderViews() {
    switch (isDriverDataFetched) {
      case LOADING_STATUS.LOADING:
        return <LoadingTable columns={5} />;
      case LOADING_STATUS.LOADED:
        if (driverData.length) {
          return driverData.map((driver, index) => (
            <StyledTableRow
              key={index}
              style={{
                background:
                  selectedDriver?.driverId === driver.driverId ? "#E9F2F4" : "",
              }}
            >
              <TableCell
                scope="row"
                sx={{ color: "#6D6D6D" }}
                onClick={() => handleSelectedDriver(index)}
              >
                {driver?.driverName}
                {driver?.isSuperDriver && (
                  <Box
                    sx={{
                      color: "#0C7F44",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                  >
                    Super Driver
                  </Box>
                )}
              </TableCell>
              <TableCell
                sx={{ color: "#6D6D6D" }}
                onClick={() => handleSelectedDriver(index)}
              >
                {driver?.phone}
              </TableCell>
              <TableCell
                sx={{ color: "#6D6D6D" }}
                onClick={() => handleSelectedDriver(index)}
              >
                {driver?.truckNumber ? driver?.truckNumber : "-"}
              </TableCell>
              <TableCell
                sx={{ color: "#6D6D6D" }}
                onClick={() => handleSelectedDriver(index)}
              >
                {driver?.status}
              </TableCell>
            </StyledTableRow>
          ));
        } else {
          return (
            <TableRow sx={{ background: "#EAEAEA" }}>
              <TableCell
                colSpan={5}
                align="center"
                sx={{ fontWeight: 600, color: "#333" }}
              >
                No Results Found.
              </TableCell>
            </TableRow>
          );
        }
      case LOADING_STATUS.FAILED:
        return (
          <TableCell colSpan={5}>
            <ErrorScreen onRetry={fetchAllDriversDetails} />
          </TableCell>
        );
      default:
        return null;
    }
  }

  return (
    <Box>
      <ToastMessage
        open={toastData.open}
        message={toastData.message}
        severity={toastData.severity}
        handleClose={handleCloseToast}
      />
      <Modal open={isInviteDriverModalOpen} onClose={closeInviteDriverModal}>
        <Box>
          <InviteEditDriverModal
            closeInviteEditDriverModal={closeInviteDriverModal}
            isEditModeOn={isEditModeOn}
            selectedDriver={selectedDriver}
            openDeleteDriverModal={openDeleteDriverModal}
            handleOpenToast={handleOpenToast}
            transporterId={transporterId}
            fetchAllDriversDetails={fetchAllDriversDetails}
          />
        </Box>
      </Modal>
      <Modal open={isDeleteDriverModalOpen} onClose={closeDeleteDriverModal}>
        <Box>
          <DeleteDriverModal
            closeDeleteDriverModal={closeDeleteDriverModal}
            selectedDriver={selectedDriver}
            handleOpenToast={handleOpenToast}
            transporterId={transporterId}
            fetchAllDriversDetails={fetchAllDriversDetails}
          />
        </Box>
      </Modal>
      <Box
        sx={{
          height: "65px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "0 16px",
          borderBottom: "1px solid #ADADAD",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: "20px" }}>
          <Box sx={transporterTruckStatusCountContainer}>
            <Typography sx={headerText}>Total</Typography>
            <Typography sx={headerText}>
              {paginationData.totalItems || 0}
            </Typography>
          </Box>
          <Box sx={transporterTruckStatusCountContainer}>
            <Typography sx={headerText}>Available</Typography>
            <Typography sx={headerText}>
              {totalAvailableDrivers || 0}
            </Typography>
          </Box>
          <Box sx={transporterTruckStatusCountContainer}>
            <Typography sx={headerText}>Unavailable</Typography>
            <Typography sx={headerText}>
              {totalUnavailableDrivers || 0}
            </Typography>
          </Box>
          <Box sx={transporterTruckStatusCountContainer}>
            <Typography sx={headerText}>Invited</Typography>
            <Typography sx={headerText}>{totalInvitedDrivers || 0}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "167px",
            height: "40px",
            borderRadius: "3px",
            border: "1px solid #333",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            gap: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={openAddDriverModal}
        >
          <img
            src="/assets/icons/addBox.svg"
            alt="cross"
            style={{ width: "24px", height: "24px" }}
          />
          <Typography sx={headerText}>Add Driver</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          margin: "16px 8px",
          gap: "10px",
          height: "calc(100vh - 12rem)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "65%",
          }}
        >
          <SearchBox handleSearchBoxChange={handleSearchBoxChange} />

          <TableContainer
            component={Paper}
            sx={{ width: "100%", height: "100%" }}
          >
            <Table size="small" aria-label="Truck data">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Driver Name</StyledTableCell>
                  <StyledTableCell>Contact No.</StyledTableCell>
                  <StyledTableCell>Assigned Truck</StyledTableCell>
                  <TableCell>
                    <StatusBox>
                      Status
                      <img
                        src="/assets/icons/expand_less.svg"
                        alt="expand less transporter status"
                        style={{ cursor: "pointer" }}
                        onClick={handleFilterPopoverOpen}
                      />
                    </StatusBox>
                    <Popover
                      open={isFilterPopoverOpen}
                      anchorEl={filterPopoverAnchorEl}
                      onClose={handleFilterPopoverClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <PopOverBox>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filterValue.includes("AVAILABLE")}
                              onChange={handleFilterValueChange}
                              value="AVAILABLE"
                            />
                          }
                          label="Available"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filterValue.includes("UNAVAILABLE")}
                              onChange={handleFilterValueChange}
                              value="UNAVAILABLE"
                            />
                          }
                          label="Unavailable"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filterValue.includes("INVITED")}
                              onChange={handleFilterValueChange}
                              value="INVITED"
                            />
                          }
                          label="Invited"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filterValue.includes("IN_TRIP")}
                              onChange={handleFilterValueChange}
                              value="IN_TRIP"
                            />
                          }
                          label="In Trip"
                        />
                        {filterValue.length > 0 && (
                          <Button
                            onClick={() => {
                              setFilterValue([]);
                            }}
                          >
                            Clear All
                          </Button>
                        )}
                      </PopOverBox>
                    </Popover>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderViews()}</TableBody>
            </Table>
          </TableContainer>
          <div style={{ padding: "10px" }}>
            {driverData.length ? (
              <TablePagination
                rowsPerPageOptions={[15]}
                component="div"
                count={paginationData.totalItems}
                rowsPerPage={paginationData.pageSize}
                page={page}
                onPageChange={handleChangePage}
                labelDisplayedRows={({ count }) => (
                  <>
                    Page{" "}
                    <span style={{ color: "green", fontWeight: 700 }}>
                      {page + 1}
                    </span>{" "}
                    of {Math.ceil(count / paginationData.pageSize)}
                  </>
                )}
              />
            ) : null}
          </div>
        </Box>
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              border: "1px solid #ADADAD",
            }}
          >
            <Box
              sx={{
                height: "100%",
              }}
            >
              {driverData && driverData.length ? (
                <DriverCardDetails
                  openEditDriverModal={openEditDriverModal}
                  selectedDriver={selectedDriver}
                  updateLocalDriverStatus={updateLocalDriverStatus}
                  handleOpenToast={handleOpenToast}
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      maxWidth: "80%",
                      textAlign: "center",
                      fontSize: "18px",
                      fontWeight: 700,
                    }}
                  >
                    No driver added yet. Please add driver to see details.
                  </Typography>
                </Box>
              )}
            </Box>
            <TripDetails
              trips={tripData}
              isTripListFetching={isTripDataLoading}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const PopOverBox = styled(Box)({
  padding: "10px",
  display: "flex",
  flexDirection: "column",
});

const StatusBox = styled(Box)({
  fontSize: "14px",
  fontWeight: 700,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
});
