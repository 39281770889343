import React from "react";
import { Typography, Box, Stack } from "@mui/material";
const iconSize = {
  width: "180px",
  height: "180px",
};
const arrowStyle = {
  alignSelf: "center",
};
const text = {
  color: "#333",
  fontSize: "24px",
  fontWeight: 700,
};
const description = {
  color: "#767676",
  fontWeight: 400,
  fontSize: "18px",
};
const iconPaths = {
  bookingConfirmed:'/assets/landingPageIcons/HIW-booking-confirmed.svg',
  receiveOffer:'/assets/landingPageIcons/HIW-receive-offers.svg',
  selectOffer:'/assets/landingPageIcons/HIW-select-offers.svg',
  submitEnquiry:'/assets/landingPageIcons/HIW-submit-enquiry.svg',
  tracking:"/assets/landingPageIcons/HIW-tracking.svg"
}

function HowItWorks() {
  return (
    <Box sx={{ background: "#F8FAF8", paddingBlock: "3rem" }}>
      <Typography
        align="center"
        sx={{
          color: "#333",
          marginBottom: "2rem",
          fontSize: "36px",
          fontWeight: 700,
          textAlign: "center",
        }}
      >
        How It Works
      </Typography>
      <Stack
        direction="row"
        flexWrap="wrap"
        // alignItems="center"
        justifyContent="center"
        maxWidth="xl"
        sx={{ margin: "auto" }}
      >
        <Box
          height="100%"
          width="250px"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <img
            src={iconPaths.submitEnquiry}
            style={iconSize}
            alt="submit-enquiry"
          />
          <Typography sx={text}>Submit Enquiry</Typography>
          <Typography align="center" sx={description}>
            Tell us what you need to transport. Fill in the details of your
            goods and submit your enquiry.
          </Typography>
        </Box>
        <Box sx={arrowStyle}>
          <img src="/assets/icons/Union.svg" alt="Next step arrow" />
        </Box>

        <Box
          width="250px"
          height="100%"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={iconPaths.receiveOffer}
            style={iconSize}
            alt="recieve-offers"
          />
          <Typography sx={text}>Receive Offers</Typography>
          <Typography align="center" sx={description}>
          Our unique algorithm filters the best bids from a network of trusted transport partners.
          </Typography>
        </Box>
        <Box sx={arrowStyle}>
          <img src="/assets/icons/Union.svg" alt="Next step arrow" />
        </Box>

        <Box
          width="250px"
          height="100%"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={iconPaths.selectOffer}
            style={iconSize}
            alt="recieve-offers"
          />
          <Typography sx={text}>Select Offers</Typography>
          <Typography align="center" sx={description}>
          Review the bids and select the offer of your choice.
          </Typography>
        </Box>
        <Box sx={arrowStyle}>
          <img src="/assets/icons/Union.svg" alt="Next step arrow" />
        </Box>

        <Box
          width="250px"
          height="100%"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={iconPaths.bookingConfirmed}
            style={iconSize}
            alt="select offers"
          />
          <Typography sx={text}>Confirm Booking</Typography>
          <Typography align="center" sx={description}>
          Make a secure payment through our platform to confirm your booking.
          </Typography>
        </Box>
        <Box sx={arrowStyle}>
          <img src="/assets/icons/Union.svg" alt="Next step arrow" />
        </Box>

        <Box
          width="250px"
          height="100%"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={iconPaths.tracking}
            style={iconSize}
            alt="tracking"
          />
          <Typography align="center" sx={text}>
            Tracking
          </Typography>
          <Typography align="center" sx={description}>
          Track your vehicle till your goods are delivered safely.
          </Typography>
        </Box>
        
      </Stack>
    </Box>
  );
}
export default HowItWorks;