import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Grid,
  MenuItem,
  InputLabel,
  Select,
  Divider,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import { validateInteger } from "../../../../utils/validation";
import {
  createMaterial,
  editMaterial,
  getMaterialCategoryByIndustry,
} from "../../../../api/Admin/materialType";
import {
  INDUSTRY_ITEMS,
  SPECIAL_FACTORS_ITEMS,
  ACCESSORIES,
  LOADING_TYPE,
  NO_OF_PIECES,
  DIMENSION_UNITS,
  CATEGORY_ITEMS,
} from "../../../../constants/constant";

const AddEditMaterialModel = ({
  handleDeleteMaterialTypeButtonClick,
  addEditMaterialModalData,
  editMaterialData,
  handleCloseModal,
  fetchAllMaterial,
  handleOpenToast,
}) => {
  // state for material info
  const [materialInfo, setMaterialInfo] = useState({
    materialName: "",
    commonlyCalledAs: "",
    description: "",
    loadingRequirement: [],
    materialCategory: {
      id: "",
      name: "",
    },
    specialFactors: [],
    productCategory: "",
    industry: "",
    accessories: [],
    weight: {
      min: "",
      max: "",
      unit: "KG",
    },
    noOfPieces: "",
    dimension: {
      width: "",
      length: "",
      thickness: "",
      unit: "Feet",
    },
  });

  const [validationErrors, setValidationErrors] = useState({});

  // function for validate material info
  const validateMaterialInfo = (data) => {
    const errors = {};
    if (!data.materialName) {
      errors.materialName = "Material Name is required";
    }
    if (!data.productCategory) {
      errors.productCategory = "Category is required";
    }
    if (!data.industry) {
      errors.industry = "Industry is required";
    }
    if (!data.materialCategory) {
      errors.materialCategory = "Select Vehicle Preference Category";
    }
    if (!validateInteger(parseInt(data.weight.min))) {
      errors.weightMin = "Please enter valid Integer value";
    }
    if (!validateInteger(parseInt(data.weight.max))) {
      errors.weightMax = "Please enter valid Integer value";
    }
    if (!data.noOfPieces) {
      errors.noOfPieces = "No of Packed is required";
    }
    return errors;
  };

  // function for handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      name.startsWith("weight") ||
      name.startsWith("dimension") ||
      name.startsWith("materialCategory")
    ) {
      const [key, subkey] = name.split(".");
      setMaterialInfo((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          [subkey]: value,
        },
      }));
    } else {
      setMaterialInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const [materialCategoryList, setMaterialCategoryList] = useState([]);

  const getCategoryName = async (industry) => {
    try {
      const response = await getMaterialCategoryByIndustry(industry);
      if (response.status === 200) {
        setMaterialCategoryList(response?.data?.data);
      }
    } catch (error) {
      handleOpenToast(`Material category fetching failed.`, "error");
    }
  };

  useEffect(() => {
    if (materialInfo.industry) {
      getCategoryName(materialInfo.industry);
    }
  }, [materialInfo.industry]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateMaterialInfo(materialInfo);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    let materialData = { ...materialInfo };

    // Convert weight to kilograms if in tons and remove the unit
    if (materialData.weight.unit === "TONS") {
      materialData.weight.min = materialData.weight.min * 1000;
      materialData.weight.max = materialData.weight.max * 1000;
    }

    // Remove the unit property
    delete materialData.weight.unit;

    // handle adding and updating material
    if (editMaterialData && editMaterialData?.materialTypeId) {
      const response = await editMaterial(
        editMaterialData.materialTypeId,
        materialData
      );
      if (response.status === 200) {
        await fetchAllMaterial();
        handleOpenToast("Material updated successfully", "success");
      } else {
        handleOpenToast("Failed to update material", "error");
      }
    } else {
      const response = await createMaterial(materialData);
      if (response.status === 201) {
        await fetchAllMaterial();
        handleOpenToast("Material added successfully", "success");
      } else {
        handleOpenToast("Failed to add material", "error");
      }
    }
    handleCloseModal();
  };

  useEffect(() => {
    if (addEditMaterialModalData) {
      setMaterialInfo(addEditMaterialModalData);
    }
  }, [addEditMaterialModalData]);

  useEffect(() => {
    // handle state update when editMaterialData changes
    if (editMaterialData) {
      const newMaterialData = {
        materialName: editMaterialData.materialName || "",
        commonlyCalledAs: editMaterialData.commonlyCalledAs || "",
        description: editMaterialData.description || "",
        loadingRequirement: editMaterialData.loadingRequirement || [],
        materialCategory: {
          id: editMaterialData?.materialCategory?.id || "",
          name: editMaterialData?.materialCategory?.name || "",
        },
        specialFactors: editMaterialData.specialFactors || [],
        productCategory: editMaterialData.productCategory || "",
        industry: editMaterialData.industry || "",
        accessories: editMaterialData.accessories || [],
        weight: {
          min: editMaterialData?.weight?.min || "",
          max: editMaterialData?.weight?.max || "",
          unit: "KG",
        },
        noOfPieces: editMaterialData?.noOfPieces || "",
        dimension: {
          width: editMaterialData?.dimension?.width || "",
          length: editMaterialData?.dimension?.length || "",
          thickness: editMaterialData?.dimension?.thickness || "",
          unit: editMaterialData?.dimension?.unit || "Feet",
        },
      };

      setMaterialInfo((prevState) => ({
        ...prevState,
        ...newMaterialData,
      }));
    }
  }, [editMaterialData]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Box
        sx={{
          bgcolor: "white",
          p: 5,
          borderRadius: 4,
          width: "70%",
          height: "85%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              fontSize: "2rem",
              fontWeight: 700,
              marginBottom: "20px",
            }}
          >
            {editMaterialData && editMaterialData.materialTypeId
              ? "Edit Material Type"
              : "Add Material Type"}
          </Box>
          <img
            src="/assets/icons/cross-icon.svg"
            alt="cross"
            style={{
              cursor: "pointer",
            }}
            onClick={handleCloseModal}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "92%",
            overflowY: "scroll",
            padding: "20px",
          }}
        >
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputLabel
                  sx={{ color: "#333", fontSize: "1.125rem", fontWeight: 700 }}
                  htmlFor="materialName"
                >
                  Material Name <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <TextField
                  variant="outlined"
                  placeholder="Material Name"
                  name="materialName"
                  value={materialInfo.materialName}
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  error={!!validationErrors && !!validationErrors.materialName}
                  helperText={validationErrors && validationErrors.materialName}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  sx={{ color: "#333", fontSize: "1.125rem", fontWeight: 700 }}
                  htmlFor="commonlyCalledAs"
                >
                  Commonly Called As
                </InputLabel>
                <TextField
                  placeholder="Commonly Called As"
                  name="commonlyCalledAs"
                  value={materialInfo.commonlyCalledAs}
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={6}>
                <InputLabel
                  sx={{ color: "#333", fontSize: "1.125rem", fontWeight: 700 }}
                  htmlFor="industry"
                >
                  Industry <span style={{ color: "red" }}>*</span>
                </InputLabel>

                <TextField
                  name="industry"
                  select
                  label={materialInfo.industry ? "" : "Select Industry"}
                  value={materialInfo.industry}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: false }}
                  error={!!validationErrors && !!validationErrors.industry}
                  helperText={validationErrors && validationErrors.industry}
                  sx={{
                    "& .MuiOutlinedInput-input:-webkit-autofill": {
                      WebkitBoxShadow: "0 0 0 1000px white inset",
                    },
                    "& .MuiInputLabel-outlined.Mui-focused": {
                      color: "gray",
                    },
                    "& .MuiInputLabel-outlined": {
                      color: "gray",
                    },
                  }}
                >
                  {INDUSTRY_ITEMS.map((accessory, index) => (
                    <MenuItem key={index} value={accessory}>
                      {accessory}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  sx={{ color: "#333", fontSize: "1.125rem", fontWeight: 700 }}
                  htmlFor="materialCategory"
                >
                  Material Category
                  <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Autocomplete
                  // options={materialCategoryList || []}
                  options={
                    materialInfo.industry
                      ? materialCategoryList
                      : [
                          {
                            materialCategory: "Please select an industry first",
                          },
                        ]
                  }
                  getOptionLabel={(option) => option.materialCategory}
                  value={
                    materialCategoryList.find(
                      (category) =>
                        category.materialCategoryId ===
                        materialInfo.materialCategory.id
                    ) || null
                  }
                  name="materialCategory.id"
                  onChange={(e, value) =>
                    setMaterialInfo((prevState) => ({
                      ...prevState,
                      materialCategory: {
                        id: value?.materialCategoryId || "",
                        name: value?.materialCategory || "",
                      },
                    }))
                  }
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-input:-webkit-autofill": {
                      WebkitBoxShadow: "0 0 0 1000px white inset",
                    },
                    "& .MuiInputLabel-outlined.Mui-focused": {
                      color: "gray",
                    },
                    "& .MuiInputLabel-outlined": {
                      color: "gray",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={
                        materialInfo.materialCategory.id ||
                        params.inputProps.value
                          ? ""
                          : "Select Material Category"
                      }
                      variant="outlined"
                      disabled={!materialInfo.industry}
                      fullWidth
                      name="materialCategory.id"
                      InputLabelProps={{ shrink: true }}
                      value={
                        materialCategoryList.find(
                          (category) =>
                            category.materialCategoryId ===
                            materialInfo.materialCategory.id
                        ) || null
                      }
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          "&.MuiOutlinedInput-root .MuiAutocomplete-input": {
                            padding: "1rem",
                            paddingRight: "4rem",
                            color: "#333",
                          },
                        },
                      }}
                    />
                  )}
                />
                <span style={{ color: "red", fontSize: "0.8rem" }}>
                  {validationErrors && validationErrors.materialCategory}
                </span>
              </Grid>

              <Grid item xs={6}>
                <InputLabel
                  sx={{ color: "#333", fontSize: "1.125rem", fontWeight: 700 }}
                  htmlFor="productCategory"
                >
                  Product Category <span style={{ color: "red" }}>*</span>
                </InputLabel>

                <TextField
                  select
                  name="productCategory"
                  label={
                    materialInfo.productCategory
                      ? ""
                      : "Select Product Category"
                  }
                  value={materialInfo.productCategory}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: false }}
                  error={
                    !!validationErrors && !!validationErrors.productCategory
                  }
                  helperText={
                    validationErrors && validationErrors.productCategory
                  }
                  sx={{
                    "& .MuiOutlinedInput-input:-webkit-autofill": {
                      WebkitBoxShadow: "0 0 0 1000px white inset",
                    },
                    "& .MuiInputLabel-outlined.Mui-focused": {
                      color: "gray",
                    },
                    "& .MuiInputLabel-outlined": {
                      color: "gray",
                    },
                  }}
                >
                  {CATEGORY_ITEMS?.map((category, index) => (
                    <MenuItem key={index} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}></Grid>

              <Grid item xs={6}>
                <InputLabel
                  sx={{ color: "#333", fontSize: "1.125rem", fontWeight: 700 }}
                  htmlFor="loadingRequirement"
                >
                  Loading Requirement
                </InputLabel>
                <Select
                  name="loadingRequirement"
                  multiple
                  value={materialInfo.loadingRequirement}
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#ADADAD",
                    },
                    height: "57px",
                    maxHeight: "57px",
                  }}
                  renderValue={(selected) => (
                    <div style={{ display: "flex", overflowX: "auto" }}>
                      {selected.map((value, index) => (
                        <div
                          style={{
                            padding: "5px",
                            marginLeft: "8px",
                            borderRadius: "5px",
                            background: "#EAEAEA",
                            fontSize: "14px",
                          }}
                          key={index}
                        >
                          {value}
                        </div>
                      ))}
                    </div>
                  )}
                >
                  {LOADING_TYPE.map((accessory, index) => (
                    <MenuItem key={index} value={accessory}>
                      {accessory}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={6}>
                <InputLabel
                  sx={{ color: "#333", fontSize: "1.125rem", fontWeight: 700 }}
                  htmlFor="specialFactors"
                >
                  Special Factor
                </InputLabel>

                <Select
                  name="specialFactors"
                  multiple
                  label={
                    materialInfo.specialFactors ? "" : "Select Special Factor"
                  }
                  value={materialInfo.specialFactors}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  sx={{
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#ADADAD",
                    },
                    height: "57px",
                    maxHeight: "57px",
                  }}
                  renderValue={(selected) => (
                    <div style={{ display: "flex", overflowX: "auto" }}>
                      {selected?.map((value, index) => (
                        <div
                          style={{
                            padding: "5px",
                            marginLeft: "8px",
                            borderRadius: "5px",
                            background: "#EAEAEA",
                            fontSize: "14px",
                          }}
                          key={index}
                        >
                          {value}
                        </div>
                      ))}
                    </div>
                  )}
                >
                  {SPECIAL_FACTORS_ITEMS.map((accessory, index) => (
                    <MenuItem key={index} value={accessory}>
                      {accessory}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={6}>
                <InputLabel
                  sx={{ color: "#333", fontSize: "1.125rem", fontWeight: 700 }}
                  htmlFor="accessories"
                >
                  Accessories
                </InputLabel>

                <Select
                  name="accessories"
                  multiple
                  value={materialInfo.accessories}
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#ADADAD",
                    },
                    height: "57px",
                    maxHeight: "57px",
                  }}
                  renderValue={(selected) => (
                    <div style={{ display: "flex" }}>
                      {selected?.map((value, index) => (
                        <div
                          style={{
                            padding: "5px",
                            marginLeft: "8px",
                            borderRadius: "5px",
                            background: "#EAEAEA",
                            fontSize: "14px",
                          }}
                          key={index}
                        >
                          {value}
                        </div>
                      ))}
                    </div>
                  )}
                >
                  {ACCESSORIES.map((accessory, index) => (
                    <MenuItem key={index} value={accessory}>
                      {accessory}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  sx={{ color: "#333", fontSize: "1.125rem", fontWeight: 700 }}
                  htmlFor="noOfPieces"
                >
                  No of Pieces <span style={{ color: "red" }}>*</span>
                </InputLabel>

                <TextField
                  select
                  name="noOfPieces"
                  label={materialInfo.noOfPieces ? "" : "Select No of Package"}
                  value={materialInfo.noOfPieces}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: false }}
                  error={!!validationErrors && !!validationErrors.noOfPieces}
                  helperText={validationErrors && validationErrors.noOfPieces}
                  sx={{
                    "& .MuiOutlinedInput-input:-webkit-autofill": {
                      WebkitBoxShadow: "0 0 0 1000px white inset",
                    },
                    "& .MuiInputLabel-outlined.Mui-focused": {
                      color: "gray",
                    },
                    "& .MuiInputLabel-outlined": {
                      color: "gray",
                    },
                  }}
                >
                  {NO_OF_PIECES.map((fuel, index) => (
                    <MenuItem key={index} value={fuel}>
                      {fuel}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  sx={{
                    color: "#333",
                    fontSize: "1.125rem",
                    fontWeight: 700,
                  }}
                  htmlFor="weight.min"
                >
                  Minimum Weight <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <TextField
                  placeholder="Minimum Weight"
                  name="weight.min"
                  type="number"
                  value={materialInfo.weight.min}
                  onChange={handleChange}
                  fullWidth
                  sx={{ "& .MuiOutlinedInput-root": { paddingRight: "0px" } }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Select
                          value={materialInfo.weight.unit}
                          sx={{
                            width: "100px",
                            borderColor: "red",
                            "& .MuiOutlinedInput-notchedOutline , &.Mui-focused .MuiOutlinedInput-notchedOutline ,& .MuiOutlinedInput-root":
                              {
                                borderColor: "#EAEAEA !important",
                              },
                          }}
                          onChange={(e) =>
                            setMaterialInfo((prevState) => ({
                              ...prevState,
                              weight: {
                                ...prevState.weight,
                                unit: e.target.value,
                              },
                            }))
                          }
                        >
                          <MenuItem value="KG" defaultValue>
                            KG
                          </MenuItem>
                          <MenuItem value="TONS">TONS</MenuItem>
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ min: 0 }}
                  error={!!validationErrors && !!validationErrors.weightMin}
                  helperText={validationErrors && validationErrors.weightMin}
                />
              </Grid>

              <Grid item xs={6}>
                <InputLabel
                  sx={{
                    color: "#333",
                    fontSize: "1.125rem",
                    fontWeight: 700,
                  }}
                  htmlFor="weight.max"
                >
                  Maximum Weight <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <TextField
                  placeholder="Weight"
                  name="weight.max"
                  value={materialInfo.weight.max}
                  onChange={handleChange}
                  fullWidth
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  sx={{ "& .MuiOutlinedInput-root": { paddingRight: "0px" } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Select
                          value={materialInfo.weight.unit}
                          sx={{
                            width: "100px",
                            borderColor: "red",
                            "& .MuiOutlinedInput-notchedOutline , &.Mui-focused .MuiOutlinedInput-notchedOutline ,& .MuiOutlinedInput-root":
                              {
                                borderColor: "#EAEAEA !important",
                              },
                          }}
                          onChange={(e) =>
                            setMaterialInfo((prevState) => ({
                              ...prevState,
                              weight: {
                                ...prevState.weight,
                                unit: e.target.value,
                              },
                            }))
                          }
                        >
                          <MenuItem value="KG">KG</MenuItem>
                          <MenuItem value="TONS">TONS</MenuItem>
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ min: 0 }}
                  error={!!validationErrors && !!validationErrors.weightMax}
                  helperText={validationErrors && validationErrors.weightMax}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  sx={{ color: "#333", fontSize: "1.125rem", fontWeight: 700 }}
                  htmlFor="description"
                >
                  Description
                </InputLabel>
                <TextField
                  placeholder="Description"
                  name="description"
                  value={materialInfo.description}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  InputLabelProps={{ shrink: true }}
                  rows={4}
                />
              </Grid>
              <Box sx={{ width: "100%" }}>
                <Divider
                  light
                  style={{ marginTop: "30px", marginBottom: "10px" }}
                />
              </Box>
              <Box
                sx={{
                  fontSize: "1.3rem",
                  fontWeight: 600,
                  alignItems: "start",
                  display: "flex",
                  width: "100%",
                  marginLeft: "17px",
                }}
              >
                Material Dimension
              </Box>

              <Grid item xs={6}>
                <InputLabel
                  sx={{ color: "#333", fontSize: "1.125rem", fontWeight: 700 }}
                  htmlFor="dimension.width"
                >
                  Width
                  {materialInfo.dimension.unit === "Feet"
                    ? " (ft)"
                    : materialInfo.dimension.unit === "Meter"
                    ? " (m)"
                    : " (cm)"}
                </InputLabel>
                <TextField
                  placeholder={`Width${
                    materialInfo.dimension.unit === "Feet"
                      ? " (ft)"
                      : materialInfo.dimension.unit === "Meter"
                      ? " (m)"
                      : " (cm)"
                  }`}
                  InputLabelProps={{ shrink: true }}
                  name="dimension.width"
                  type="number"
                  value={materialInfo.dimension.width}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  sx={{ color: "#333", fontSize: "1.125rem", fontWeight: 700 }}
                  htmlFor="dimension.length"
                >
                  Length
                  {materialInfo.dimension.unit === "Feet"
                    ? " (ft)"
                    : materialInfo.dimension.unit === "Meter"
                    ? " (m)"
                    : " (cm)"}
                </InputLabel>
                <TextField
                  placeholder={`Length${
                    materialInfo.dimension.unit === "Feet"
                      ? " (ft)"
                      : materialInfo.dimension.unit === "Meter"
                      ? " (m)"
                      : " (cm)"
                  }`}
                  name="dimension.length"
                  type="number"
                  value={materialInfo.dimension.length}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  sx={{ color: "#333", fontSize: "1.125rem", fontWeight: 700 }}
                  htmlFor="dimension.thickness"
                >
                  Height/Thickness
                  {materialInfo.dimension.unit === "Feet"
                    ? " (ft)"
                    : materialInfo.dimension.unit === "Meter"
                    ? " (m)"
                    : " (cm)"}
                </InputLabel>
                <TextField
                  placeholder={`Height/Thickness${
                    materialInfo.dimension.unit === "Feet"
                      ? " (ft)"
                      : materialInfo.dimension.unit === "Meter"
                      ? " (m)"
                      : " (cm)"
                  }`}
                  name="dimension.thickness"
                  type="number"
                  value={materialInfo.dimension.thickness}
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  sx={{ color: "#333", fontSize: "1.125rem", fontWeight: 700 }}
                  htmlFor="dimension.unit"
                >
                  Unit
                </InputLabel>

                <TextField
                  name="dimension.unit"
                  select
                  label={materialInfo.dimension.unit ? "" : "Select Unit"}
                  value={materialInfo.dimension.unit}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                >
                  {DIMENSION_UNITS.map((accessory, index) => (
                    <MenuItem key={index} value={accessory}>
                      {accessory}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: `${
                  editMaterialData && editMaterialData.materialTypeId
                    ? "space-between"
                    : "flex-end"
                }`,
                width: "100%",
              }}
            >
              {editMaterialData && editMaterialData.materialTypeId ? (
                <span
                  onClick={handleDeleteMaterialTypeButtonClick}
                  style={{
                    color: "red",
                    marginTop: "10px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Delete
                </span>
              ) : null}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{
                  float: "right",
                  padding: "10px 30px",
                  fontSize: "1.2rem",
                  margin: "20px 0",
                }}
              >
                Save
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default AddEditMaterialModel;
