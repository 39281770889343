import { Box, Stack, Typography, Link, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded';

export const Footer = ({ handleSectionNavigation }) => {
  const number = useSelector((state) => state.supportNumbers?.landingPage);
  return (
    <Box sx={{ background: "#DDE7EF" }}>
      <Stack
        maxWidth="xl"
        direction="row"
        justifyContent={{ sm: "space-between", md: "space-around" }}
        sx={{ margin: "auto" }}
        py={5}
        px={{
          sm: 6,
          md: 6,
          lg: 12,
          xl: 16,
        }}
      >
        <Stack
          direction={"row"}
          divider={<Divider orientation="vertical" flexItem />}
          spacing={{ xs: 2, md: 12 }}
        >
          <Box>
            <Stack direction="row" spacing={2} mb={1.5}>
              <img
                src="/assets/icons/LZ-logo-face.svg"
                alt="logo face"
                style={{ marginBottom: "2px" }}
              />
              {/* <Hidden mdDown> */}
              <img
                src="/assets/icons/LZ-logo.svg"
                alt="logo"
                style={{ marginTop: "4px" }}
              />
            </Stack>
            <Stack direction="row">
              <PhoneRoundedIcon sx={{color:'#333'}}/>
              <Typography
                variant="p"
                sx={{ color: "#333333", fontSize: ".875em", fontWeight: "500" }}
                mr={1.5}
                pt="1px"
              >
                Get in touch
              </Typography>
              <Typography
                variant="p"
                ml="auto"
                color="#0C7F44"
                sx={{ fontWeight: "bold" }}
              >
                {number}
              </Typography>
            </Stack>
          </Box>
        </Stack>

        <Stack direction="row" spacing={{ sm: 3, lg: 5 }}>
          <Stack direction="column" spacing={2.5} alignItems="center">
            <Link
              onClick={() => handleSectionNavigation("home")}
              color="#414A50"
              fontWeight="600"
            >
              Home
            </Link>
            <Link
              onClick={() => handleSectionNavigation("aboutUs")}
              color="#414A50"
              fontWeight="600"
            >
              About Us
            </Link>
          </Stack>
          <Stack direction="column" spacing={2.5} alignItems="center">
            <Link
              onClick={() => handleSectionNavigation("whyChooseUs")}
              color="#414A50"
              fontWeight="600"
            >
              Why Choose Us
            </Link>
            <Link
              onClick={() => handleSectionNavigation("thingsWeDeliver")}
              color="#414A50"
              fontWeight="600"
            >
              Things We Deliver
            </Link>
          </Stack>
          {/* <Stack direction="column" spacing={2.5} alignItems="center">
            <Link
              onClick={() => handleSectionNavigation("customer")}
              color="#414A50"
              fontWeight="600"
            >
              Customer Login
            </Link>
            <Link
              onClick={() => handleSectionNavigation("transporter")}
              color="#414A50"
              fontWeight="600"
            >
              Partner Login
            </Link>
          </Stack> */}
          <Stack direction="column" spacing={2.5} alignItems="center">
          <Link
              href="/privacypolicy"
              color="#414A50"
              fontWeight="600"
            >
              Privacy Policy
            </Link>
            <Link
              href="/terms&conditions"
              color="#414A50"
              fontWeight="600"
            >
              Terms & Conditions
            </Link>
          </Stack>
          <Stack direction="column" spacing={2.5} alignItems="center">
          <Link
              href="/cancellations&refunds"
              color="#414A50"
              fontWeight="600"
            >
              Cancellation & Refunds
            </Link>
          
            
          </Stack>
        </Stack>
      </Stack>
      <Box sx={{ background: "#414A50", textAlign: "center" }}>
        <Typography color="#FFF" fontSize="1rem" fontWeight="700" p={1}>
          Our Service Locations : Bengaluru. Coming Soon To Rest Of India.
        </Typography>
      </Box>
    </Box>
  );
};
