import { client } from "../config";

export const getBillerDetailsTransporter = async () => {
  return await client.get("transporter/billing-address");
};
export const submitBillerDetailsTransporter = async (billingInformation) => {
  return await client.put("transporter/billing-address", {
    billingAddress: billingInformation,
  });
};
export const getPaymentDetailsTransporter = async () => {
  return await client.get("transporter/bank-details");
};

export const submitPaymentDetailsTransporter = async (details) => {
  return await client.put("transporter/bank-details", {
    bankAccountDetails: details,
  });
};
