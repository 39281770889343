import React from "react";
import { useTimer } from "react-timer-hook";
import { useDispatch } from "react-redux";

import { Box, Typography } from "@mui/material";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ScheduleIcon from "@mui/icons-material/Schedule";
import moment from "moment";

import { ENQUIRY_STATUS } from "../../../constants/constant";
import { setSelectedRow } from "../../../redux/slices/transporterSlice";

export default function DashboardEnquiryCard(props) {
  const { entityId, enquiry, getEnquiries } = props;
  const dispatch = useDispatch();

  const timerOpenStatus = [ENQUIRY_STATUS.UNQUOTED, ENQUIRY_STATUS.QUOTED];
  const timerAutoStartStatus = [ENQUIRY_STATUS.UNQUOTED, ENQUIRY_STATUS.QUOTED];

  const getRemainingTimeInSeconds = () => {
    const currentTime = moment.utc().valueOf();
    const expiryTime = moment(enquiry?.enquiryExpirationTimestamp).valueOf();

    const timeRemaining = Math.max(0, expiryTime - currentTime);
    const remainingSeconds = Math.floor(timeRemaining / 1000);
    const time = new Date();
    time.setSeconds(remainingSeconds);
    return time;
  };

  const { seconds, minutes, hours } = useTimer({
    autoStart: timerAutoStartStatus.includes(enquiry?.enquiryStatus),
    expiryTimestamp: getRemainingTimeInSeconds(),
    onExpire: () => getEnquiries(),
  });

  return (
    <Box
      width="235px"
      height="70px"
      backgroundColor="#fff"
      borderRadius="10px"
      border={`${
        entityId === enquiry.transporterEnquiryId
          ? "2px solid #5386E4"
          : "2px solid #ececec"
      }`}
      onClick={() => {
        props.onSelectEnquiry?.(enquiry.transporterEnquiryId);
        dispatch(setSelectedRow(enquiry));
      }}
      sx={{ cursor: "pointer" }}
    >
      <Box display="flex" gap="10px">
        {enquiry?.enquiryStatus === "YOU_QUOTED" ? (
          <BookmarkIcon
            sx={{
              fill: "#0C7F44",
              width: "20px",
              height: "20px",
              marginLeft: "2px",
              marginTop: "2px",
            }}
          />
        ) : (
          <Brightness1Icon
            sx={{
              fill:
                enquiry?.enquiryStatus === "UNQUOTED" ? "#7DC526" : "#F09116",
              width: "20px",
              height: "20px",
              marginLeft: "2px",
              marginTop: "2px",
            }}
          />
        )}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          gap="4px"
        >
          <Typography fontSize="14px" fontWeight="700">
            {enquiry.route}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        gap="3px"
      >
        <LocalShippingOutlinedIcon sx={{ width: "15px", height: "15px" }} />
        <Typography fontSize="12px" mr="10px">
          {enquiry?.distance} Km
        </Typography>
        <Typography fontSize="12px" mr="10px">
          |
        </Typography>

        <Typography fontSize="12px" ml="10px">
          {moment(enquiry?.pickupDate).format("DD MMM")}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" mx="2px">
        <Box
          width="190px"
          height="14px"
          bgcolor="#ececec"
          borderRadius="4px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="10px"
        >
          {(enquiry?.enquiryStatus === "UNQUOTED" ||
            enquiry?.enquiryStatus === "QUOTED") && (
            <>
              <Typography fontSize={12} fontWeight="700">
                Expires In
              </Typography>

              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="5px"
              >
                <ScheduleIcon sx={{ width: "15px", height: "15px" }} />
                <Typography fontSize="12px" fontWeight="700">
                  {timerOpenStatus.includes(enquiry?.enquiryStatus) ? (
                    <>{`${hours}h:${minutes}m:${seconds}s`}</>
                  ) : (
                    "-"
                  )}
                </Typography>
              </Box>
            </>
          )}

          {enquiry?.enquiryStatus === "YOU_QUOTED" && (
            <Typography fontSize={12} fontWeight="400">
              You Offered
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}
