import { Box, Button, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";

const TruckCarousel = ({ selectedVehicle }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (selectedVehicle?.vehicleImages) {
      const { frontUrl, leftUrl, rearUrl, rightUrl } =
        selectedVehicle.vehicleImages;
      const filteredImages = [frontUrl, leftUrl, rearUrl, rightUrl].filter(
        (url) => url !== ""
      );
      setImages(filteredImages);
    }
  }, [selectedVehicle]);

  const totalImages = images.length;

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalImages) % totalImages);
  };

  return (
    <Box
      width="350px"
      height="109px"
      p={0}
      // overflow="hidden"
      // display="flex"
      // alignItems="center"
      // justifyItems="center"
    >
      {images.length > 0 ? (
        <Stack
          height="100%"
          p={0}
          direction="row"
          alignItems="center"
          gap={3}
          justifyContent="center"
          m="auto"
        >
          <Box>
            <img
              src="/assets/icons/expand_less.svg"
              alt="expand less"
              onClick={handlePrev}
              style={{
                width: "30px",
                cursor: "pointer",
                transform: "rotate(90deg)",
                objectFit: "cover",
              }}
            />
          </Box>
          <Box height="100%" width="240px">
            <img
              src={images[currentIndex]}
              alt={`truckImage ${currentIndex + 1}`}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
          <Box>
            <img
              src="/assets/icons/expand_less.svg"
              alt="expand less"
              onClick={handleNext}
              style={{
                width: "30px",
                cursor: "pointer",
                transform: "rotate(-90deg)",
                objectFit: "cover",
              }}
            />
          </Box>
        </Stack>
      ) : (
        <Box top="30%" textAlign="center" color="red" pt={3}>
          No photos have been uploaded for this truck. Please click on the edit
          truck icon to add photos.
        </Box>
      )}
    </Box>
  );
};

export default TruckCarousel;
