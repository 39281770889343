import React, { useEffect, useState } from "react";

import { Box, Button, Skeleton, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import styled from "@emotion/styled";
import { LOADING_STATUS } from "../../constants/constant";

const StyledBox = styled(Box)(() => ({
  fontSize: "10px",
  fontWeight: "400",
  display: "-webkit-box",
  maxWidth: "200px",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

export default function TripCarousel(props) {
  const {
    trips,
    heading,
    emptyMessage,
    onSelectTrip,
    entityId,
    entityType,
    selectedTripType,
    selectedTripView,
    type,
    fetchStatus,
    retry,
  } = props;

  const [currentIndex, setCurrentIndex] = useState(0);
  const updatedTrips = trips.slice(0, 3);
  const currentTrip = updatedTrips[currentIndex];

  const isTripSelected = selectedTripType === type && entityType === "trip";

  const isDetailsSelected =
    isTripSelected &&
    entityId === currentTrip?.id &&
    selectedTripView === "details";

  const isMapSelected =
    isTripSelected &&
    entityId === currentTrip?.id &&
    selectedTripView === "map";

  const isSupportSelected =
    isTripSelected &&
    entityId === currentTrip?.id &&
    selectedTripView === "support";

  useEffect(() => {
    if (currentTrip?.id) {
      onSelectTrip(currentTrip?.id, "details");
    }
  }, [currentIndex]);

  return (
    <Box
      borderRadius="8px"
      width="320px"
      height="100%"
      padding="4px 14px"
      backgroundColor={`${isTripSelected ? "#E9F2F4" : "#fff"}`}
      border={`${isTripSelected ? "2px solid #5386E4" : "2px solid #ececec"}`}
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box fontWeight="700" fontSize="14px">
          {heading}
        </Box>
        {fetchStatus === LOADING_STATUS.LOADED && (
          <Box>
            {updatedTrips.length > 0 && (
              <Box
                fontWeight="700"
                fontSize="14px"
                mr="10px"
                display="flex"
                alignItems="center"
              >
                <KeyboardArrowLeftIcon
                  sx={{
                    cursor: currentIndex > 0 && "pointer",
                    fill: currentIndex > 0 ? "#333333" : "#D0D0D0",
                  }}
                  onClick={() =>
                    currentIndex > 0 && setCurrentIndex(currentIndex - 1)
                  }
                />
                <Box color="#0C7F44" fontWeight="700" fontSize="14px" mr="4px">
                  {currentIndex + 1}
                </Box>
                of
                <Box fontWeight="700" fontSize="14px" ml="4px">
                  {updatedTrips?.length}
                </Box>
                <ChevronRightIcon
                  sx={{
                    cursor:
                      currentIndex < updatedTrips?.length - 1 && "pointer",
                    fill:
                      currentIndex < updatedTrips?.length - 1
                        ? "#333333"
                        : "#D0D0D0",
                  }}
                  onClick={() =>
                    currentIndex < updatedTrips?.length - 1 &&
                    setCurrentIndex(currentIndex + 1)
                  }
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
      {fetchStatus === LOADING_STATUS.FAILED && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={4}
        >
          <Typography variant="body1" paragraph>
            Failed to load trips...
          </Typography>
          <Button variant="contained" color="primary" onClick={retry}>
            Retry
          </Button>
        </Box>
      )}

      {fetchStatus === LOADING_STATUS.LOADING && (
        <Box p={2}>
          <Box display="flex" gap={1}>
            <Skeleton variant="rounded" width={100} height={60} />
            <Skeleton variant="rounded" width={100} height={60} />
            <Skeleton variant="rounded" width={100} height={60} />
            <Skeleton variant="rounded" width={100} height={60} />
          </Box>
          <Box width={250} pt={1}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        </Box>
      )}

      {fetchStatus === LOADING_STATUS.LOADED && (
        <Box
          border="2px solid #ececec"
          borderRadius="8px"
          width="290px"
          height="calc(100% - 26px)"
          padding="4px 16px"
          backgroundColor="#fff"
          overflow="auto"
        >
          {updatedTrips?.length > 0 ? (
            <Box display="flex" gap="10px" flexDirection="column" height="100%">
              <Box display="flex" justifyContent="flex-start">
                <Box fontWeight="700" fontSize="14px">
                  {currentTrip.route}
                </Box>
              </Box>

              <Box display="flex" flexDirection="row" flex={1}>
                <img
                  src="/assets/icons/distanceArrowSmall.svg"
                  alt="DistanceArrow"
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <StyledBox>{currentTrip.pickUp}</StyledBox>
                  <StyledBox>{currentTrip.dropOff}</StyledBox>
                </Box>
              </Box>

              <Box display="flex" gap="15px">
                <Button
                  variant="outlined"
                  sx={{
                    width: "90px",
                    height: "20px",
                    backgroundColor: isDetailsSelected ? "#5386E4" : "#fff",
                    color: isDetailsSelected ? "#fff" : "#333333",
                    "&:hover": {
                      backgroundColor: isDetailsSelected ? "#5386E4" : "#fff",
                    },
                  }}
                  onClick={() => onSelectTrip(currentTrip.id, "details")}
                >
                  Details
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    width: "90px",
                    height: "20px",
                    backgroundColor: isMapSelected ? "#5386E4" : "#fff",
                    color: isMapSelected ? "#fff" : "#333333",
                    "&:hover": {
                      backgroundColor: isMapSelected ? "#5386E4" : "#fff",
                    },
                  }}
                  onClick={() => onSelectTrip(currentTrip.id, "map")}
                >
                  Map
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    width: "90px",
                    height: "20px",
                    backgroundColor: isSupportSelected ? "#5386E4" : "#fff",
                    color: isSupportSelected ? "#fff" : "#333333",
                    "&:hover": {
                      backgroundColor: isSupportSelected ? "#5386E4" : "#fff",
                    },
                  }}
                  onClick={() => onSelectTrip(currentTrip.id, "support")}
                >
                  Support
                </Button>
              </Box>
            </Box>
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box textAlign="center" p={4}>
                {emptyMessage}
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
