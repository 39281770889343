import { createTheme, ThemeProvider } from "@mui/material/styles";
import LandingPage from "./pages/LandingPage";
import { Routes, Route } from "react-router-dom";

import { admin_Routes, customer_Routes, transporter_Routes } from "./routes";

import TransporterLayout from "./layout/TransporterLayout";
import AdminLayout from "./layout/AdminLayout";
import AdminLoginPage from "./pages/Admin/AdminLoginPage";
import DriverAppPage from "./pages/DriverApp";
import { useEffect } from "react";
import { getSupportNumbers } from "./api/portalSupportNumbers";
import { useDispatch } from "react-redux";
import { setSupportnumbers } from "./redux/slices/supportNumbersSlice";
import CustomerLayout from "./layout/CustomerLayout";

import EnquiryLayout from "./layout/EnquiryLayout";
import WhatsAppEnquiryForm from "./pages/WhatsAppEnquiry";
import WhatsAppEnquiryEstimationPage from "./pages/WhatsAppEnquiryEstimatePage";
import Privacypolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/Terms&Conditions";
import CancellationAndRefund from "./pages/CancellationAndRefund";
import Rebook from "./pages/Customer/Rebook";
import TransporterNotificationPage from "./pages/Transporter/NotificationsPage";
import CustomerNotificationPage from "./pages/Customer/NotificationsPage";
import AdminNotificationPage from "./pages/Admin/NotificationPage";
import SendOfferForm from "./pages/SendOfferForm";
import WhatsappEnquiryRebook from "./pages/WhatsappEnquiryRebook";
import WhatsAppAcceptOffer from "./pages/Customer/WhatsAppAcceptOffer";

// todo - move these in configs

const theme = createTheme({
  typography: {
    fontFamily: "Helvetica",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: "#0C7F44",
          color: "white",
          fontWeight: "bold",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#0c7f44",
          },
          "&:disabled": {
            backgroundColor: "#ADADAD",
            color: "#FFF",
          },
        },
        outlinedPrimary: {
          borderColor: "#333333",
          color: "#333333",
          fontWeight: "bold",
          textTransform: "none",
          "&:hover": {
            borderColor: "#333333",
          },
          "&:disabled": {
            borderColor: "#ADADAD",
            color: "#ADADAD",
          },
        },
        outlinedSecondary: {
          borderColor: "#0c7f44",
          color: "#0c7f44",
          fontWeight: "bold",
          textTransform: "none",
          background: "#FFF",
          "&:hover": {
            borderColor: "#0c7f44",
          },
          "&:disabled": {
            borderColor: "#ADADAD",
            color: "#ADADAD",
          },
        },
        rejectButton: {
          background: "#FF3F3F",
          color: "white",
          "&:hover": {
            background: "#FF3F3F",
          },
        },
        supportButton: {
          "&:hover": { backgroundColor: "#ffffff" },
          backgroundColor: "#ffffff",
          color: "#333",
          border: "1px solid #333",
        },
        // Add more button variants and styles as needed
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
          borderRadius: 8,
          "& .MuiInputLabel-root": {
            color: "#6D6D6D", // Change this to the desired label color
          },
          "& .MuiOutlinedInput-root": {
            borderColor: "#C6C6C6", // Default border color
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#C6C6C6", // Default border color
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#333", // Border color on hover
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ADADAD", // Border color on focus
            },
            "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ADADAD", // Border color on focus
            },
            "&.Mui-disabled .MuiInputLabel-root": {
              color: "red", // Change this to the desired label color
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ADADAD",
          },
        },
      },
    },
  },
});
function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    getSupportNumbers()
      .then((response) => {
        dispatch(setSupportnumbers(response?.data?.data));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route
            path="/enquiry-form/:userId"
            element={<WhatsAppEnquiryForm />}
          />
          <Route
            path="/send-offer-form/:transporterId/:enquiryId"
            element={<SendOfferForm />}
          />
          <Route
            path="/whatsapp-accept-offer/:customerId/:enquiryId"
            element={<WhatsAppAcceptOffer />}
          />
          <Route
            path="/enquiry-form/:userId/success"
            element={<WhatsAppEnquiryEstimationPage />}
          />
          <Route path="/" element={<LandingPage />} />
          <Route path="/enquiry" element={<EnquiryLayout />} />
          <Route path="/rebook" element={<Rebook />} />
          <Route
            path="/chatbot/rebook-enquiry/:customerId/:enquiryId"
            element={<WhatsappEnquiryRebook />}
          />
          <Route exact path="/transporter" element={<TransporterLayout />}>
            <Route
              path="notifications"
              element={<TransporterNotificationPage />}
            />
            {transporter_Routes}
          </Route>
          <Route path="/customer" element={<CustomerLayout />}>
            <Route
              path="notifications"
              element={<CustomerNotificationPage />}
            />
            {customer_Routes}
          </Route>
          <Route path="/admin" element={<AdminLoginPage />} />
          <Route path="/admin/app" element={<AdminLayout />}>
            <Route path="notifications" element={<AdminNotificationPage />} />
            {admin_Routes}
          </Route>
          <Route path="/app" element={<DriverAppPage />} />
          <Route path="/privacypolicy" element={<Privacypolicy />} />
          <Route path="/terms&conditions" element={<TermsAndConditions />} />
          <Route
            path="/cancellations&refunds"
            element={<CancellationAndRefund />}
          />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
