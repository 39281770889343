import React, { Fragment } from "react";
import { Box } from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import EditIcon from "@mui/icons-material/Edit";
import styled from "@emotion/styled";

const BoxCard = styled(Box)(({ isIntracity }) => ({
  width: "200px",
  height: "80px",
  padding: "16px",
  borderRadius: "8px",
  boxShadow: "1px 4px 8px rgba(0, 0, 0, 0.25)",
  display: "flex",
  alignItems: isIntracity ? "" : "center",
  justifyContent: isIntracity && "center",
  flexDirection: isIntracity ? "column" : "row",
  gap: "8px",
}));

const CardContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "20px",
  flexWrap: "wrap",
  width: "100%",
}));

const IntraCityCard = styled(Box)(() => ({
  width: "53px",
  height: "22px",
  borderRadius: "8px",
  backgroundColor: "#0C7F44",
  color: "#fff",
  padding: "4px",
  fontSize: "12px",
  fontWeight: "700",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const CityName = styled(Box)(() => ({
  fontWeight: "700",
  fontSize: "18px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  textTransform: "none",
}));

export default function AddCity(props) {
  const { handleCreateCityDetails, cities } = props;

  return (
    <Fragment>
      <CardContainer>
        <BoxCard onClick={handleCreateCityDetails}>
          <AddCircleRoundedIcon sx={{ width: "28px", height: "28px" }} />
          <Box fontWeight="700" fontSize="18px">
            Add New City
          </Box>
        </BoxCard>
      </CardContainer>
      <Box
        display="flex"
        flexDirection="row"
        gap="30px"
        padding="20px"
        flexWrap="wrap"
        width="100%"
      >
        {cities?.map((item, index) => {
          return (
            <Box key={index}>
              <BoxCard isIntracity={item?.isIntraCity}>
                {item?.isIntraCity && <IntraCityCard>Intracity</IntraCityCard>}
                <Box display="flex" justifyContent="space-between" width="100%">
                  <CityName>{item.city}</CityName>
                  <EditIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => props.handleEditCityDetails(index)}
                  />
                </Box>
              </BoxCard>
            </Box>
          );
        })}
      </Box>
    </Fragment>
  );
}
