import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

import useToast from "../../../hooks/useToast";

import TripCarousel from "../../Common/TripCarousel";
import TripDetailedView from "./TripDetailedView";
import {
  getCustomerTripsByStatus,
  getrTripDetails,
} from "../../../api/Customer/trips";
import { LOADING_STATUS } from "../../../constants/constant";

const TripViews = {
  details: "details",
  map: "map",
  support: "support",
};

const TripTypes = {
  live: "live",
  upComing: "upComing",
};

export default function CustomerDashboardTrips(props) {
  const {
    customerId,
    entityType,
    setEntityType,
    selectedTripType,
    setSelectedTripType,
  } = props;

  const { handleOpenToast } = useToast();

  const [initialLiveTrips, setInitialLiveTrips] = useState([]);
  const [initialUpcomingTrips, setInitialUpcomingTrips] = useState([]);

  const [liveTrips, setLiveTrips] = useState([]);
  const [upComingTrips, setUpComingTrips] = useState([]);
  const [entityId, setEntityId] = useState(null);

  const [selectedTripView, setSelectedTripView] = useState(TripViews.details);

  const [isLiveTripDetailsFetched, setIsLiveTripDetailsFetched] = useState("");
  const [isUpcomingTripDetailsFetched, setIsUpcomingTripDetailsFetched] =
    useState("");

  const customerLiveTrips = async () => {
    try {
      setIsLiveTripDetailsFetched(LOADING_STATUS.LOADING);
      const tripStatus = ["TRUCK_IN_TRANSIT"];
      const response = await getCustomerTripsByStatus(
        customerId,
        0,
        tripStatus
      );

      if (response?.status === 200) {
        const trips = response.data.data.trips;
        setInitialLiveTrips(trips);
        setIsLiveTripDetailsFetched(LOADING_STATUS.LOADED);

        const tripsData = await Promise.all(
          trips.map(async (trip) => {
            const tripRes = await getrTripDetails(customerId, trip.id);
            const tripDetails = tripRes.data.data;
            return {
              id: trip.id,
              route: trip.route,
              pickUp: tripDetails?.enquiryDetails?.pickup?.location?.address,
              dropOff: tripDetails?.enquiryDetails?.dropoff?.location?.address,
              tripId: trip.tripId,
              tripDetails: tripDetails,
            };
          })
        );

        setLiveTrips(tripsData);
      } else {
        setLiveTrips([]);
        handleOpenToast("Error while getting live trips", "error");
      }
    } catch (error) {
      setIsLiveTripDetailsFetched(LOADING_STATUS.FAILED);
    }
  };

  const customerUpcomingTrips = async () => {
    try {
      setIsUpcomingTripDetailsFetched(LOADING_STATUS.LOADING);
      const tripStatus = [
        "BOOKING_ACCEPTED",
        "DRIVER_AT_PICKUP_LOCATION",
        "MATERIAL_LOADED",
      ];
      const response = await getCustomerTripsByStatus(
        customerId,
        0,
        tripStatus
      );

      if (response?.status === 200) {
        const trips = response.data.data.trips;
        setInitialUpcomingTrips(trips);
        setIsUpcomingTripDetailsFetched(LOADING_STATUS.LOADED);

        const tripsData = await Promise.all(
          trips.map(async (trip) => {
            const tripRes = await getrTripDetails(customerId, trip.id);
            const tripDetails = tripRes.data.data;

            return {
              id: trip.id,
              route: trip.route,
              pickUp: tripDetails?.enquiryDetails?.pickup?.location?.address,
              dropOff: tripDetails?.enquiryDetails?.dropoff?.location?.address,
              tripId: trip.tripId,
              tripDetails: tripDetails,
            };
          })
        );

        setUpComingTrips(tripsData);
      } else {
        setUpComingTrips([]);
        handleOpenToast("Error while getting upcoming trips", "error");
      }
    } catch (error) {
      setIsUpcomingTripDetailsFetched(LOADING_STATUS.FAILED);
    }
  };

  useEffect(() => {
    if (customerId) {
      customerLiveTrips();
      customerUpcomingTrips();
    }
  }, [customerId]);

  const onSelectLiveTrip = (id, view) => {
    setEntityId(id);
    setEntityType("trip");
    setSelectedTripType(TripTypes.live);
    setSelectedTripView(view);
  };
  const onSelectUpcomingTrip = (id, view) => {
    setEntityId(id);
    setEntityType("trip");
    setSelectedTripType(TripTypes.upComing);
    setSelectedTripView(view);
  };

  return (
    <Box display="flex" flexDirection="row" gap="10px" height="100%">
      <Box display="flex" flexDirection="column" gap="10px">
        <Box height="220px" overflow="auto">
          <TripCarousel
            trips={liveTrips}
            heading="Live Trips"
            emptyMessage="No live trips are available for display at the moment."
            onSelectTrip={onSelectLiveTrip}
            entityId={entityId}
            entityType={entityType}
            selectedTripType={selectedTripType}
            selectedTripView={selectedTripView}
            type={TripTypes.live}
            fetchStatus={isLiveTripDetailsFetched}
            retry={customerLiveTrips}
          />
        </Box>
        <Box height="220px" overflow="auto">
          <TripCarousel
            trips={upComingTrips}
            heading="Upcoming Trips"
            emptyMessage="No upcoming trips are available for display at the moment."
            onSelectTrip={onSelectUpcomingTrip}
            entityId={entityId}
            entityType={entityType}
            selectedTripType={selectedTripType}
            selectedTripView={selectedTripView}
            type={TripTypes.upComing}
            fetchStatus={isUpcomingTripDetailsFetched}
            retry={customerUpcomingTrips}
          />
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        gap="10px"
        width="calc(100% - 310px)"
        height="100%"
        overflow="auto"
      >
        <TripDetailedView
          entityId={entityId}
          customerId={customerId}
          entityType={entityType}
          selectedTripType={selectedTripType}
          selectedTripView={selectedTripView}
          trips={
            selectedTripType === "live"
              ? initialLiveTrips
              : initialUpcomingTrips
          }
          customerLiveTrips={customerLiveTrips}
          customerUpcomingTrips={customerUpcomingTrips}
        />
      </Box>
    </Box>
  );
}
