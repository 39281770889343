import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import styled from "@emotion/styled";

const TransactionTable = ({ transactionData, name }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650, color: "#DDEFDD" }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "#DDEFDD" }}>
            <StyledTableCell>Transaction Id</StyledTableCell>
            <StyledTableCell>From</StyledTableCell>
            <StyledTableCell>To</StyledTableCell>
            <StyledTableCell>Amount Paid</StyledTableCell>
            <StyledTableCell>Payment Mode</StyledTableCell>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell>Time</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactionData && transactionData.length !== 0 ? (
            transactionData?.map((transaction) => (
              <TableRow
                key={transaction.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  backgroundColor: "#DDEFDD",
                }}
              >
                <TableCell component="th" scope="row">
                  {transaction._id}
                </TableCell>
                <TableCell>
                  {transaction.senderId === "LE_PLATFORM"
                    ? "Admin"
                    : name
                    ? name
                    : transaction.senderId}
                </TableCell>
                <TableCell>
                  {transaction.receiverId === "LE_PLATFORM"
                    ? "Admin"
                    : name
                    ? name
                    : transaction.receiverId}
                </TableCell>
                <TableCell>{`₹ ${transaction?.amount}` || "-"}</TableCell>
                <TableCell>{transaction?.modeOfPayment}</TableCell>
                <TableCell>
                  {moment(transaction.createdAt).format("DD-MM-YYYY") || "-"}
                </TableCell>
                <TableCell>
                  {moment(transaction.createdAt).format("hh:mm A") || "-"}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                backgroundColor: "#DDEFDD",
              }}
            >
              <TableCell colSpan={7} align="center">
                No Transaction Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransactionTable;

const StyledTableCell = styled(TableCell)`
  && {
    font-weight: bold;
  }
`;
