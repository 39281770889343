import React from "react";
import { Typography, Box } from "@mui/material";

const AboutUsSection = () => {
  return (
    <Box
      id="aboutUs"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "auto",
        gap: "24px",
        paddingBlock: "3rem",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          color: "#333",
          textAlign: "center",
          fontFamily: "Helvetica",
          fontSize: "36px",
          fontWeight: 700,
        }}
      >
        Stress-Free Movement of Goods is now Possible!​
        <br />
        You are just a click away from it!
      </Typography>

      <Typography
        variant="body1"
        maxWidth="lg"
        sx={{
          color: "#4E4E4E",
          textAlign: "center",
          fontFamily: "Helvetica",
          fontSize: "24px",
          fontWeight: 400,
        }}
      >
        Most trusted in the logistics sector, LoadEazy delivers technologically
        advanced and out of the box transport services to its customers. We
        offer industry specific transport solutions across India. Our current
        focus being the Iron & Steel Industry.
      </Typography>
    </Box>
  );
};

export default AboutUsSection;
