import React from "react";
import { Box } from "@mui/system";
import { Button, Typography, styled, Avatar } from "@mui/material";
import { deleteSelectedDriver } from "../../../../../api/Transporter/driver";

const DeleteDriverModal = ({
  closeDeleteDriverModal,
  selectedDriver,
  handleOpenToast,
  transporterId,
  fetchAllDriversDetails,
}) => {
  //function handles the deletion of truck data.
  const handleDeleteDriverTypeData = async () => {
    const response = await deleteSelectedDriver(
      transporterId,
      selectedDriver.driverId
    );
    if (response.status === 200) {
      handleOpenToast("Driver data deleted successfully.", "success");
      await fetchAllDriversDetails();
    } else {
      handleOpenToast(
        "Failed to delete driver data. Please try again.",
        "error"
      );
    }
    closeDeleteDriverModal();
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "white",
        p: 2,
        borderRadius: 4,
        width: "600px",
        height: "400px",
      }}
    >
      <img
        src="/assets/icons/cross-icon.svg"
        alt="cross"
        style={{
          position: "absolute",
          right: "4%",
          top: "5%",
        }}
        onClick={closeDeleteDriverModal}
      />
      <Box
        sx={{
          position: "absolute",
          left: "4%",
          top: "5%",
          fontSize: "24px",
          fontWeight: "700",
        }}
      >
        Delete Driver
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "32px",
          height: "100%",
          marginTop: "32px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "500px",
            gap: "20px",
            borderRadius: "3px",
            border: "1.148px solid #C6C6C6",
            padding: "10px 20px",
          }}
        >
          <DriverAvatarBox>
            <StyledAvatar
              alt="User Avatar"
              src={selectedDriver?.profilePhoto}
            />
          </DriverAvatarBox>
          <Box sx={{ display: "flex", marginTop: "20px" }}>
            <Box sx={{ flex: "1", marginRight: "12px", minWidth: "140px" }}>
              <Typography sx={{ marginTop: "10px", marginBottom: "10px" }}>
                Driver name:
              </Typography>
              <Typography sx={{ marginTop: "10px", marginBottom: "10px" }}>
                Contact number:
              </Typography>
              <Typography sx={{ marginTop: "10px", marginBottom: "10px" }}>
                E-mail address:
              </Typography>
            </Box>
            <Box sx={{ flex: "1" }}>
              <Typography sx={{ marginTop: "10px", marginBottom: "10px" }}>
                {selectedDriver?.driverName}
              </Typography>
              <Typography sx={{ marginTop: "10px", marginBottom: "10px" }}>
                +91 {selectedDriver?.phone}
              </Typography>
              <Typography sx={{ marginTop: "10px", marginBottom: "10px" }}>
                {selectedDriver?.email.length > 20
                  ? selectedDriver?.email.slice(0, 20) + "..."
                  : selectedDriver?.email}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "6px" }}>
          <img
            src="/assets/icons/confirmation.svg"
            alt="logo face"
            style={{ marginBottom: "2px" }}
          />
          <Typography
            sx={{
              color: "#FF3F3F",
              fontSize: 14,
            }}
          >
            Are you sure you want to remove this driver?
          </Typography>
        </Box>

        <Box sx={{ display: "flex", gap: "20px" }}>
          <Button
            sx={{
              background: "#FF3F3F",
              color: "white",
              "&:hover": {
                background: "#FF3F3F",
              },
            }}
            onClick={handleDeleteDriverTypeData}
          >
            Confirm Remove
          </Button>
          <Button
            sx={{
              background: "#FFF",
              border: "1px solid #333",
              color: "#333",
            }}
            onClick={closeDeleteDriverModal}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteDriverModal;

const DriverAvatarBox = styled(Box)({
  width: "150px",
  height: "170px",
});

const StyledAvatar = styled(Avatar)({
  width: 120,
  height: 120,
  marginTop: "1rem",
});
