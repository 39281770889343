import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import CustomMultipleCircleProgressBar from "../../component/Common/CustomMultipleCircleProgressBar";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { PlacesAutocomplete } from "../../component/Common/PlacesAutoComplete";
import { createEnquiry } from "../../api/Customer/enquiry";
import { useMapsService } from "../../hooks/useMapsService";
import { LOADING_STATUS } from "../../constants/constant";
import useToast from "../../hooks/useToast";
import CustomerDashboardTrips from "../../component/Customer/CustomerDashboard/CustomerDashboardTrips";
const steps = [
  "Enquiry Posted",
  "Receive offers",
  "Accept offer",
  "Make Payment",
  "Booking Confirmed",
];

const EntityTypes = {
  search: "search",
  trip: "trip",
};

const TripTypes = {
  live: "live",
  upComing: "upComing",
};

const CustomerDashboard = () => {
  const { handleOpenToast, ToastMessage } = useToast();
  const customerId = useSelector((state) => state?.customer?.id);
  const [entityType, setEntityType] = useState(EntityTypes.search);
  const [selectedTripType, setSelectedTripType] = useState(TripTypes.live);

  return (
    <Box p={2}>
      <CheckFareAndBook
        handleOpenToast={handleOpenToast}
        entityType={entityType}
        setEntityType={setEntityType}
      />

      {/* this modal shows up only when customer enters while making enquiry as guest from landing page */}
      <CustomerProgressModal />
      <ToastMessage />
      <Box
        width="calc(100vw - 310px)"
        height="calc(100vh - 255px)"
        overflow="auto"
        pt={2}
      >
        <CustomerDashboardTrips
          customerId={customerId}
          entityType={entityType}
          setEntityType={setEntityType}
          selectedTripType={selectedTripType}
          setSelectedTripType={setSelectedTripType}
        />
      </Box>
    </Box>
  );
};
export default CustomerDashboard;

const CheckFareAndBook = ({ handleOpenToast, entityType, setEntityType }) => {
  const customerId = useSelector((state) => state.customer.id);
  const customerPhone = useSelector((state) => state.customer.phone);
  const navigate = useNavigate();
  const { isLoaded, calculateDistance, getLatLng } = useMapsService();

  const [btnStatus, setBtnStatus] = useState(LOADING_STATUS.INITIAL);
  const [dropOffLocation, setDropOffLocation] = useState({
    place: "",
    placeId: "",
  });
  const [pickUpLocation, setPickupLocation] = useState({
    place: "",
    placeId: "",
  });
  const [errors, setErrors] = useState({ pickup: "", dropoff: "" });
  const handleCreateEnquiry = async (
    pickupLocationDetails,
    dropoffLocationDetails,
    distance
  ) => {
    const pickup = {
      location: { ...pickupLocationDetails, address: pickUpLocation.place },
    };
    const dropoff = {
      location: { ...dropoffLocationDetails, address: dropOffLocation.place },
    };

    const enquiryBody = {
      pickup,
      dropoff,
      distance: parseInt(distance),
    };
    try {
      const response = await createEnquiry(enquiryBody);
      const enquiryId = response.data.data?.enquiryId;
      return enquiryId;
    } catch (err) {
      handleOpenToast("Something went wrong. Try again !", "error");
      return;
    }
  };

  const handleCheckFareAndBooktButtonClick = async () => {
    if (isLoaded) {
      // if no source and destination is selected
      if (!pickUpLocation.place && !dropOffLocation.place) {
        setErrors({
          pickup: "Please pick pickup location",
          dropoff: "Please pick pickup location",
        });
        return;
      }
      if (!pickUpLocation.place) {
        setErrors((p) => ({ ...p, pickup: "Please pick pickup location" }));
        return;
      }
      if (!dropOffLocation.place) {
        setErrors((p) => ({ ...p, dropoff: "Please pick dropoff location" }));
        return;
      }
      if (pickUpLocation.placeId === dropOffLocation.placeId) {
        handleOpenToast(
          "Pickup and Dropoff locations cannot be same !",
          "error"
        );
        return;
      }

      setBtnStatus(LOADING_STATUS.LOADING);
      try {
        const [pickupLocationDetails, dropoffLocationDetails, distance] =
          await Promise.all([
            getLatLng(pickUpLocation.placeId), // returns pickup locations lat, long, city and address
            getLatLng(dropOffLocation.placeId), // returns dropoff locations lat, long, city and address
            calculateDistance(pickUpLocation.place, dropOffLocation.place), // calcutes distance bt. origin and destn.
          ]);

        try {
          const enqId = await handleCreateEnquiry(
            pickupLocationDetails,
            dropoffLocationDetails,
            distance
          );
          setBtnStatus(LOADING_STATUS.LOADED);
          navigate("/enquiry", {
            state: {
              detail: {
                source: pickUpLocation.place,
                destn: dropOffLocation.place,
                distance: distance,
                enquiryId: enqId,
                isAuthenticated: true,
                phone: customerPhone,
                customerId: customerId,
              },
            },
          });
        } catch (error) {
          setBtnStatus(LOADING_STATUS.FAILED);
        }
      } catch (error) {
        if (error.message.split(" ")[2] === pickUpLocation.placeId) {
          setErrors((p) => ({
            ...p,
            pickup: "please pick more specific location",
          }));
        } else if (error.message.split(" ")[2] === dropOffLocation.placeId) {
          setErrors((p) => ({
            ...p,
            dropoff: "please pick more specific location",
          }));
        } else {
          console.error(error);
        }
        setBtnStatus(LOADING_STATUS.FAILED);
      }
    }
  };

  return (
    <Paper
      elevation={2}
      sx={{
        padding: "1.125rem",
        background: `${entityType === EntityTypes.search ? "#E9F2F4" : "#fff"}`,
        border: `3px solid ${
          entityType === EntityTypes.search ? "#5386E4" : "#fff"
        }`,
      }}
      onClick={() =>
        entityType !== EntityTypes.search && setEntityType(EntityTypes.search)
      }
    >
      <Typography gutterBottom fontWeight={700} color="#333">
        Book Your Truck
      </Typography>
      <Grid container gap={1}>
        <Grid item xs={3.85}>
          <PlacesAutocomplete
            error={Boolean(errors.pickup)}
            helperText={errors.pickup}
            resetError={() => setErrors((p) => ({ ...p, pickup: "" }))}
            placeholderText="Enter pick up location"
            onPlaceSelect={(place, placeId) =>
              setPickupLocation({ place, placeId })
            }
          />
        </Grid>

        <Grid item xs={3.85}>
          <PlacesAutocomplete
            error={Boolean(errors.dropoff)}
            helperText={errors.dropoff}
            resetError={() => setErrors((p) => ({ ...p, dropoff: "" }))}
            placeholderText="Enter drop off location"
            onPlaceSelect={(place, placeId) => {
              setDropOffLocation({ place, placeId });
            }}
          />
        </Grid>

        <Grid item xs={3.85}>
          <Button
            variant="contained"
            color="primary"
            disabled={btnStatus === LOADING_STATUS.LOADING}
            sx={{
              fontWeight: 700,
              fontSize: "1.25rem",
              paddingBlock: ".625rem",
              width: "100%",
            }}
            endIcon={
              btnStatus === LOADING_STATUS.LOADING && (
                <CircularProgress sx={{ color: "#fff" }} size={22} />
              )
            }
            onClick={handleCheckFareAndBooktButtonClick}
          >
            CHECK FARE & BOOK
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

// tood -> move it in common component once dashboard is ready
const CustomerProgressModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.state) {
      const { isEnquiryFlow, isServiceable } = location.state;
      if (
        typeof isEnquiryFlow !== "undefined" &&
        typeof isServiceable !== "undefined"
      ) {
        setIsOpen(isServiceable && isEnquiryFlow);
      }
    }
  }, [location.state]);

  return (
    <Dialog open={isOpen} maxWidth="625px">
      <Stack p="1rem .5rem">
        <Stack>
          <DialogTitle align="center">
            <Typography
              variant="h5"
              color="green"
              fontWeight={700}
              gutterBottom
            >
              Enquiry Received Successfully
            </Typography>

            <Typography variant="subtitle" color="#333">
              Your enquiry is posted.
            </Typography>
          </DialogTitle>
          <CloseIcon
            onClick={() => {
              setIsOpen(false);
              navigate("/customer/dashboard", { replace: true });
            }}
            sx={{
              position: "absolute",
              right: ".5rem",
              top: "1rem",
              fontSize: "26px",
              cursor: "pointer",
            }}
          />
        </Stack>

        <DialogContent
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: 2.5,
          }}
        >
          <CustomMultipleCircleProgressBar
            steps={steps}
            stakeholder="customer"
          />
          <Typography variant="subtitle" color="#333">
            Accept an offer to complete your booking.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ margin: "auto" }}
            variant="contained"
            color="primary"
            onClick={() => {
              navigate("/customer/enquiries", { replace: true });
            }}
          >
            Check Offers
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};
