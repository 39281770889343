import { adminApi } from "../config";

export const getAllTruckList = async (page, userInput) => {
  try {
    const response = adminApi.get(
      `admin/transporter-vehicles?page=${page}&search=${userInput}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getTruckDetails = async (id) => {
  try {
    const response = adminApi.get(`admin/transporter-vehicles/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getTruckTrips = async (id, page, pageSize, tripStatus, search) => {
  try {
    const queryParam = new URLSearchParams({
      page: page + 1,
      pageSize,
      tripStatus,
      search,
    });
    const response = adminApi.get(
      `admin/transporter-vehicle/trips/${id}?${queryParam}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateTruckStatus = async (vehicleId, status) => {
  try {
    const response = adminApi.patch(
      `admin/transporter-vehicle/${vehicleId}/status`,
      {
        status,
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
