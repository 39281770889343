import { adminApi } from "../config";

export const getAllDriverLocation = async (transporterId) => {
  try {
    const response = adminApi.get(
      `admin/transporter/${transporterId}/drivers/locations`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getDriverLocation = async (driverId) => {
  try {
    const response = adminApi.get(`admin/driver/${driverId}/location`);
    return response;
  } catch (error) {
    return error;
  }
};
