// common component for showing stats on pages

import { Typography } from "@mui/material";
import { Stack } from "@mui/system";

const statsStyle = {
  paddingInline: "1rem",
  paddingBlock: ".385rem",
  borderRadius: ".25rem",
  fontSize: "1rem",
  background: "#EAEAEA",
};
/**
 * takes array in form of  [ {name : "name", value : "23"} , ...]
 * @param {array} props
 * @returns <Typography>stat name & stat value </Typography>
 */
const GenerateStats = (props) => {
  const { data } = props;
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {data.map((stats) => (
        <Typography sx={statsStyle} key={stats.name}>
          {stats.name} &nbsp;<b>{stats.value}</b>
        </Typography>
      ))}
    </Stack>
  );
};

export default GenerateStats;
