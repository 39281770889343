import { useState } from "react";
import { Box, Typography } from "@mui/material";
import MaterialType from "../../../../component/Admin/Configurations/MaterialType";
import VehicleType from "../../../../component/Admin/Configurations/VehicleType";
import SupportedCity from "../../../../component/Admin/Configurations/SupportedCity";
import MaterialCategory from "../../../../component/Admin/Configurations/MaterialCategory";

const Inventory = () => {
  // State to keep track of expanded section.
  const [expandedSection, setExpandedSection] = useState("");

  // Array of sections with their titles and components.
  const inventorySections = [
    {
      title: "Material Category for Vehicle Preference",
      component: <MaterialCategory />,
    },
    { title: "Material Types", component: <MaterialType /> },
    { title: "Truck Models", component: <VehicleType /> },
    { title: "Supported Cities", component: <SupportedCity /> },
  ];

  // Function to toggle expanded section.
  const toggleExpandedSection = (title) => {
    setExpandedSection(expandedSection === title ? "" : title);
  };

  return (
    <div>
      {inventorySections.map((section, index) => (
        <Box key={index} sx={{ margin: "20px 30px" }}>
          <Box
            sx={{
              display: "flex",
              padding: "4px 16px",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "4px",
              background: "#EAEAEA",
              margin: "10px 0",
              cursor: "pointer",
              height: "48px",
            }}
            onClick={() => toggleExpandedSection(section.title)}
          >
            <Typography
              sx={{
                color: "#333",
                fontSize: "16px",
                fontWeight: 700,
              }}
            >
              {section.title}
            </Typography>

            <img
              src={
                expandedSection === section.title
                  ? "/assets/icons/expand_more.svg"
                  : "/assets/icons/expand_less.svg"
              }
              alt="accordian expand icon"
            />
          </Box>
          {expandedSection === section.title && (
            <Box sx={{ margin: "4px 12px" }}>{section.component}</Box>
          )}
        </Box>
      ))}
    </div>
  );
};

export default Inventory;
