import React, { useState } from "react";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import styled from "@emotion/styled";

import { LOADING_STATUS } from "../../../constants/constant";
import LoadingTable from "../../Common/LoadingTable";
import { EnquiryTableRow } from "./EnquiryTableRow";
import FilterCollapse from "./FilterCollapse";
import ErrorScreen from "../../Common/ErrorScreen";

function EnquiryTable(props) {
  const { setEnquiryStatus, enquiryStatus, enquires } = props;
  const [anchorForStatus, setAnchorForStatus] = useState(null);
  const [statusFilter, setStatusFilter] = useState([]);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#FFFFFF",
      color: "#333333",
      borderRight: "1px solid #c6c6c6",
      fontWeight: 700,
      paddingTop: "20px",
      borderLeft: "1px solid #c6c6c6",
      textAlign: "center",
    },
  }));

  function renderViews() {
    switch (props.isEnquiriesListFetched) {
      case LOADING_STATUS.LOADING:
        return <LoadingTable columns={8} />;
      case LOADING_STATUS.LOADED:
        if (enquires?.length) {
          return enquires.map((enquiry) => {
            return (
              <EnquiryTableRow
                enquiry={enquiry}
                handleOpenToast={props.handleOpenToast}
              />
            );
          });
        } else {
          return (
            <TableRow sx={{ background: "#EAEAEA" }}>
              <TableCell
                colSpan={8}
                align="center"
                sx={{ fontWeight: 600, color: "#333" }}
              >
                No Results Found.
              </TableCell>
            </TableRow>
          );
        }
      case LOADING_STATUS.FAILED:
        return (
          <TableRow>
            <TableCell colSpan={8} sx={{ borderBottom: "none" }}>
              <ErrorScreen onRetry={props.getTransporterEnquiries} />
            </TableCell>
          </TableRow>
        );
      default:
        return null;
    }
  }

  const statusFilters = [
    { label: "Quoted", value: "QUOTED" },
    { label: "Un Quoted", value: "UNQUOTED" },
    { label: "You Quoted", value: "YOU_QUOTED" },
    { label: "Quote Expired", value: "ENQUIRY_EXPIRED" },
    { label: "Bid Won", value: "BID_WON" },
    { label: "Bid Lost", value: "BID_LOST" },
    { label: "Admin Rejected", value: "ENQUIRY_REJECTED" },
    { label: "Enquiry Closed", value: "ENQUIRY_CLOSED" },
    { label: "Enquiry Ignored", value: "ENQUIRY_IGNORED" },
  ];

  const handleOpenStatusFilter = (event) => {
    setAnchorForStatus(event.target);
  };

  const handleCloseStatusFilter = () => {
    setAnchorForStatus(null);
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "100%",
        height: "calc(100% - 60px)",
        padding: "0px 18px 15px 30px",
        boxShadow: "none",
      }}
    >
      <Table
        stickyHeader
        size="small"
        aria-label="Enquiries data"
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableHead>
          <TableRow
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "1px",
              },
            }}
          >
            <StyledTableCell>Route</StyledTableCell>
            <StyledTableCell>Enquired On</StyledTableCell>
            <StyledTableCell>Material</StyledTableCell>
            <StyledTableCell>Weight</StyledTableCell>
            <StyledTableCell>Pickup Date</StyledTableCell>
            <StyledTableCell>Pickup Time</StyledTableCell>
            <TableCell
              style={{ cursor: "pointer" }}
              sx={{
                borderRight: "1px solid #c6c6c6",
                paddingTop: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  color: "#333333",
                  fontWeight: 700,
                }}
              >
                Status
                <img
                  src="/assets/icons/expand_less.svg"
                  alt="expand less transporter status"
                  style={{ cursor: "pointer" }}
                  onClick={handleOpenStatusFilter}
                />
              </Box>

              <FilterCollapse
                open={anchorForStatus}
                onClose={handleCloseStatusFilter}
                filters={statusFilters}
                setFilters={setStatusFilter}
                selectedFilters={statusFilter}
                setEnquiryStatus={setEnquiryStatus}
                enquiryStatus={enquiryStatus}
              />
            </TableCell>

            <StyledTableCell>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                Expires In
              </Stack>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderViews()}</TableBody>
      </Table>
    </TableContainer>
  );
}

export default EnquiryTable;
