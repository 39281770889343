import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  Box,
  Button,
  CircularProgress,
  TablePagination,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FavoriteTripCard from "../../../component/Customer/Favorites/FavoriteTripCard";
import { LOADING_STATUS } from "../../../constants/constant";
import { getFavorites } from "../../../api/Customer/trips";

export default function Favorites() {
  const navigate = useNavigate();
  const customerId = useSelector((state) => state.customer.id);

  const [favoriteTrips, setFavoriteTrips] = useState([]);
  const [isEnquiryFetched, setIsEnquiryFetched] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [page, setPage] = useState(0);

  const fetchCustomerFavorites = async () => {
    try {
      setIsEnquiryFetched(LOADING_STATUS.LOADING);
      const pageSize = 5;
      const response = await getFavorites(customerId, page, pageSize);
      if (response.status === 200) {
        const favoritesArray = response?.data.data.favoriteTrips;
        setFavoriteTrips(favoritesArray);
        setPaginationData(response?.data.data.pagination);
        setIsEnquiryFetched(LOADING_STATUS.LOADED);
      }
    } catch (error) {
      setIsEnquiryFetched(LOADING_STATUS.FAILED);
    }
  };

  useEffect(() => {
    if (customerId) {
      fetchCustomerFavorites();
    }
  }, [customerId, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
  };
  return (
    <Box width="calc(100vw - 280px)" height="calc(100vh - 96px)">
      <Box height="100%">
        <Box
          display="flex"
          alignItems="center"
          flexDirection="row"
          justifyContent="space-around"
          py={2}
          bgcolor="#EAEAEA"
          width="100%"
        >
          <ArrowBackIosIcon
            onClick={() => {
              navigate(-1);
            }}
            sx={{ cursor: "pointer", marginLeft: "10px" }}
            width="20%"
          />
          <Typography
            color="#333"
            fontWeight={700}
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontSize="24px"
            width="80%"
          >
            Favorite trips
          </Typography>
        </Box>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            p="4px"
            bgcolor="#DBEAF9"
            color="#6D6D6D"
            mt="4px"
            fontWeight="700"
            fontSize="14px"
            textAlign="center"
            borderRadius="5px"
          >
            (if you add the trips to favorite you can make bookings faster) If
            you are making anything favorite, you can repeatedly book that order
            and it will be fast process.
          </Typography>
        </Box>
        <>
          {isEnquiryFetched === LOADING_STATUS.LOADED && (
            <Box height="calc(100% - 101px)">
              <Box width="100%" height="calc(100% - 56px)" overflow="scroll">
                {favoriteTrips.length === 0 && page === 0 ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    fontWeight="700"
                    p={20}
                    fontSize="24px"
                  >
                    No Favorite Trips{" "}
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    gap="20px"
                    flexDirection="column"
                    alignItems="center"
                    my="20px"
                  >
                    {favoriteTrips.map((trip) => (
                      <FavoriteTripCard
                        trip={trip}
                        customerId={customerId}
                        fetchTrips={fetchCustomerFavorites}
                      />
                    ))}
                  </Box>
                )}
              </Box>
              <Box>
                {favoriteTrips?.length > 0 && (
                  <div
                    style={{
                      borderTop: "1px solid #adadad",
                    }}
                  >
                    <TablePagination
                      rowsPerPageOptions={[2]}
                      component="div"
                      count={paginationData.totalItems}
                      rowsPerPage={paginationData.pageSize}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelDisplayedRows={({ count }) => (
                        <div>
                          Page{" "}
                          <span style={{ color: "green", fontWeight: 700 }}>
                            {page + 1}
                          </span>{" "}
                          of {Math.ceil(count / paginationData.pageSize)}
                        </div>
                      )}
                    />
                  </div>
                )}
              </Box>
            </Box>
          )}

          {isEnquiryFetched === LOADING_STATUS.LOADING && (
            <Box textAlign="center" pt={16}>
              <CircularProgress sx={{ color: "green" }} size={36} />
            </Box>
          )}

          {isEnquiryFetched === LOADING_STATUS.FAILED && (
            <Box textAlign="center" pt={10}>
              <Typography fontSize={24} color="#333" gutterBottom>
                Oops! we ran into some problem
              </Typography>
              <Button variant="contained" onClick={fetchCustomerFavorites}>
                Retry
              </Button>
            </Box>
          )}
        </>
      </Box>
    </Box>
  );
}
