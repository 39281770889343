import React, { useEffect, useState } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import {
  CUSTOMER_ENQUIRY_STATUS,
  LOADING_STATUS,
} from "../../constants/constant";
import { useSelector } from "react-redux";
import { getCustomerEnquiryDetailsById } from "../../api/Customer/enquiry";
import ErrorScreen from "../Common/ErrorScreen";

export const RejectCollapse = ({ enquiry, status }) => {
  const [enquiryDetails, setEnquiryDetails] = useState({});
  const [enquiryDetailsFetched, setIsEnquiryDetailsFetched] = useState("");
  const customerId = useSelector((state) => state.customer.id);

  const getTitleFromStatus = (status) => {
    if (status === CUSTOMER_ENQUIRY_STATUS.ENQUIRY_EXPIRED)
      return "We are currently unable to serve this order, as this enquiry has expired.";
    else if (status === CUSTOMER_ENQUIRY_STATUS.ENQUIRY_CLOSED)
      return "This trip has been closed by you...";
    else if (status === CUSTOMER_ENQUIRY_STATUS.ENQUIRY_REJECTED)
      return "We are currently unable to serve this order...";
    else if (status === CUSTOMER_ENQUIRY_STATUS.OFFER_EXPIRED) {
      return "Offer expired. Please submit a new enquiry for updated quotes";
    } else if (status === CUSTOMER_ENQUIRY_STATUS.CONVERTED_TO_TRIP) {
      return "Enquiry is converted to trip, Please go to bookings page.";
    }
  };

  const fetchCustomerEnquiryDetails = async () => {
    try {
      setIsEnquiryDetailsFetched(LOADING_STATUS.LOADING);
      const response = await getCustomerEnquiryDetailsById(
        customerId,
        enquiry?.enquiryId
      );
      if (response.status === 200) {
        const details = response?.data.data.enquiry;
        setEnquiryDetails(details);
        setIsEnquiryDetailsFetched(LOADING_STATUS.LOADED);
      }
    } catch (error) {
      setIsEnquiryDetailsFetched(LOADING_STATUS.FAILED);
    }
  };

  useEffect(() => {
    fetchCustomerEnquiryDetails();
  }, []);
  if (enquiryDetailsFetched === LOADING_STATUS.LOADED) {
    return (
      <Box
        sx={{
          background:
            status === CUSTOMER_ENQUIRY_STATUS.CONVERTED_TO_TRIP
              ? "#DDEFDD"
              : "#F3E9E9",
          padding: "16px 24px",
        }}
      >
        <Box display="flex" gap={2}>
          <img
            src="/assets/icons/distanceArrowSmall.svg"
            alt="distanceArrowShort.svg"
            style={{ height: "58px" }}
          />
          <Box mb={2}>
            <Typography
              sx={{ fontSize: 14, fontWeight: 400, color: "#4F4848" }}
            >
              {enquiryDetails?.pickup?.location?.address}
            </Typography>
            <Typography
              mt={2}
              sx={{ fontSize: 14, fontWeight: 400, color: "#4F4848" }}
            >
              {enquiryDetails?.dropoff?.location?.address}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ py: 1 }}>
          <Typography sx={{ fontSize: 18, fontWeight: 700, color: "#333333" }}>
            {getTitleFromStatus(status)}
          </Typography>
        </Box>
      </Box>
    );
  } else if (enquiryDetailsFetched === LOADING_STATUS.FAILED) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <ErrorScreen onRetry={fetchCustomerEnquiryDetails} />;
      </Box>
    );
  } else if (enquiryDetailsFetched === LOADING_STATUS.LOADING) {
    return (
      <Box sx={{ width: "100%" }} p={3}>
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
      </Box>
    );
  }
};
