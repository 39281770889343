import React from "react";
import { TableBody, TableCell, Skeleton } from "@mui/material";
import { StyledTableRow } from "./StyledTableRow";

const LoadingTable = ({ columns = 4, rows = 10 }) => {
  const generateCells = () => {
    return Array.from({ length: columns }, (_, index) => (
      <TableCell key={index}>
        <Skeleton />
      </TableCell>
    ));
  };

  const generateRows = () => {
    return Array.from({ length: rows }, (_, index) => (
      <StyledTableRow key={index}>{generateCells()}</StyledTableRow>
    ));
  };

  return generateRows();
};

export default LoadingTable;
