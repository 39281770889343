import React from "react";
import { CUSTOMER_ENQUIRY_STATUS } from "../../constants/constant";
import { useTimer } from "react-timer-hook";
import { Box, Typography } from "@mui/material";
import { setEnquiryStatusTracking } from "../../redux/slices/customerSlice";
import { useDispatch } from "react-redux";

export default function FindingPartnerTimer({ enquiry }) {
  const dispatch = useDispatch();

  const getRemainingTimeInSeconds = () => {
    const currentTime = new Date().getTime();
    const expiryTime = new Date(
      enquiry?.timers?.enquiryExpirationTimestamp
    ).getTime();

    const timeRemaining = Math.max(0, expiryTime - currentTime);
    const remainingSeconds = Math.floor(timeRemaining / 1000);
    const time = new Date();
    time.setSeconds(remainingSeconds);
    return time;
  };

  const handleTimerExpired = async () => {
    await new Promise((resolve) => setTimeout(resolve, 10000));

    dispatch(
      setEnquiryStatusTracking(
        `${enquiry?.enquiryId}-finding-partner-timer-expired`
      )
    );
  };

  const expiryTime = new Date(
    enquiry?.timers?.enquiryExpirationTimestamp
  ).getTime();
  const autoStart = expiryTime >= Date.now();
  const { seconds, minutes, hours } = useTimer({
    autoStart: autoStart,
    expiryTimestamp: getRemainingTimeInSeconds(),
    onExpire: () => handleTimerExpired(),
  });

  const calculateBackgroundColor = () => {
    const totalElapsedTime = Number(
      enquiry?.timers?.enquiryExpirationTime * 60
    );
    let timePassed = Number(hours * 3600 + minutes * 60 + seconds);
    const progress = Number((timePassed / totalElapsedTime) * 100);
    // const percentage = (timeRemaining / 100) * 100;
    return `linear-gradient(90deg, #d3eab8 ${progress}%, #FFFFFF ${progress}%)`;
  };

  return (
    <Box
      sx={{
        backgroundImage: calculateBackgroundColor(),
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        display: "inline-block",
        paddingX: 1.5,
        paddingY: 0.5,
        borderRadius: "5px 0 0 5px",
      }}
    >
      <Typography sx={{ color: "#6D6D6D", fontWeight: 700 }}>
        Finding Partner
      </Typography>
    </Box>
  );
}
