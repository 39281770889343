import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { css } from '@emotion/react';

export const StyledDeliverySectionBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding-left: 8px;
      ${(props) =>
      props.sx &&
      css`
        ${props.sx}
      `}
`;
