import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Stack, Typography, Stepper, Box } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 7px)",
    right: "calc(50% + 7px)",
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#0C7F44",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#BFC4C0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const GradientLine = styled("div")(() => ({
  position: "absolute",
  top: 10,
  left: "50%",
  width: "100%",
  height: "2.4px",
  background: "linear-gradient(to right, #0C7F44 50%, transparent 50%)",
  zIndex: 2,
}));

const CustomStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#0C7F44",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#0C7F44",
  }),

  "& .step-icon": {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "currentColor",
    border: "3px solid",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "&-complete-circle": {
      borderColor: "green",
    },
    "&-upcoming-circle": {
      color: "#fff",
      border: "2px solid green",
      position: "relative",
    },
    "&-incomplete-circle": {
      color: "#fff",
      borderColor: "#BFC4C0",
    },
  },
}));

function CustomStepIconWrapper(stepNumber) {
  return function CustomStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <CustomStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <div className="step-icon step-icon-complete-circle" />
        ) : (
          <div className="step-icon step-icon-incomplete-circle" />
        )}
      </CustomStepIconRoot>
    );
  };
}

export default function CustomStepperProgressBar(props) {
  const { activeStep, steps, fromDashboard } = props;

  return (
    <Stack
      sx={{
        width: "100%",
        marginTop: "30px",
        marginBottom: "20px",
      }}
      spacing={4}
    >
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<CustomStepConnector />}
      >
        {steps?.map((item, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={CustomStepIconWrapper(activeStep)}>
              <Box fontWeight="700" fontSize={fromDashboard ? "10px" : "14px"}>
                {" "}
                {item.label}
              </Box>

              {item.description && (
                <Typography
                  color="#6D6D6D"
                  fontSize={fromDashboard ? "10px" : "12px"}
                >
                  {item.description}
                </Typography>
              )}
            </StepLabel>
            {activeStep !== 5 && activeStep === index + 1 && <GradientLine />}
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
