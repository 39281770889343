import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { TablePagination, Box, Stack } from "@mui/material";
import EnquiryTable from "./EnquiryTable";
import { getTransporterEnquiries } from "../../../api/Transporter/enquiries";
import { LOADING_STATUS } from "../../../constants/constant";
import useToast from "../../../hooks/useToast";
import GenerateStats from "../../Common/GenerateStats";

export default function TransporterEnquiries() {
  const { ToastMessage, handleOpenToast } = useToast();

  const [enquires, setEnquires] = useState([]);
  const [page, setPage] = useState(0);
  const [paginationData, setPaginationData] = useState({});
  const [enquiryStatus, setEnquiryStatus] = useState([]);
  const [isEnquiriesListFetched, setIsEnquiriesListFetched] = useState(
    LOADING_STATUS.INITIAL
  );
  const [statusCounts, setStatusCounts] = useState({});

  const transporterId = useSelector((state) => state.transporterId.id);
  const enquiryStatusTracking = useSelector(
    (state) => state.transporter.enquiryStatusTracking
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
  };

  const transporterEnquiries = async (page) => {
    try {
      setIsEnquiriesListFetched(LOADING_STATUS.LOADING);

      const response = await getTransporterEnquiries(
        transporterId,
        page,
        enquiryStatus
      );
      if (response.status === 200) {
        setEnquires(response?.data.data.enquiries);
        setPaginationData(response.data.data.pagination);
        setStatusCounts(response.data.data.statusCount[0]);
        setIsEnquiriesListFetched(LOADING_STATUS.LOADED);
      } else {
        setIsEnquiriesListFetched(LOADING_STATUS.FAILED);
      }
    } catch (error) {
      console.log("error while getting enquires", error);
    }
  };

  useEffect(() => {
    if (transporterId) {
      transporterEnquiries(page);
    }
  }, [transporterId, enquiryStatusTracking, page, enquiryStatus]);

  const statsArray = [
    {
      name: "Total",
      value: statusCounts?.Total || "0",
    },
    {
      name: "Quoted",
      value: statusCounts?.Quoted || "0",
    },
    {
      name: "Un Quoted",
      value: statusCounts?.UnQuoted || "0",
    },
    {
      name: "You Quoted",
      value: statusCounts?.YouQuoted || "0",
    },
    {
      name: "Bid Won",
      value: statusCounts?.BidWon || "0",
    },
    {
      name: "Enquiry Expired",
      value: statusCounts?.EnquiryExpired || "0",
    },
    {
      name: "Enquiry Closed",
      value: statusCounts?.EnquiryClosed || "0",
    },
  ];

  return (
    <>
      <ToastMessage />
      <Stack
        p="10px"
        borderBottom="1px solid #ADADAD"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <GenerateStats data={statsArray} />
      </Stack>
      <EnquiryTable
        enquires={enquires}
        setEnquiryStatus={setEnquiryStatus}
        enquiryStatus={enquiryStatus}
        isEnquiriesListFetched={isEnquiriesListFetched}
        getTransporterEnquiries={transporterEnquiries}
        handleOpenToast={handleOpenToast}
      />

      <div
        style={{
          borderTop: "1px solid #adadad",
        }}
      >
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={paginationData.totalItems}
          rowsPerPage={paginationData.pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({ count }) => (
            <div>
              Page{" "}
              <span style={{ color: "green", fontWeight: 700 }}>
                {page + 1}
              </span>{" "}
              of {Math.ceil(count / paginationData.pageSize)}
            </div>
          )}
        />
      </div>
    </>
  );
}
