import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  InputLabel,
  Stack,
  Typography,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import { useParams } from "react-router";
import styled from "@emotion/styled";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  getEnquiryDetailsById,
  getEstimate,
  getVehicalDropdownInfo,
} from "../api/Transporter/enquiries";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import useToast from "../hooks/useToast";
import { createBid } from "../api/Transporter/bid";

const labelStyle = { fontSize: ".835rem", color: "#333", fontWeight: 700 };

const SendOfferForm = () => {
  const { handleOpenToast, ToastMessage } = useToast();
  const { transporterId, enquiryId } = useParams();
  const [enquiryDetails, setEnquiryDetails] = useState({});
  const [estimationData, setEstimationData] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [trucks, setTrucks] = useState([]);
  const [error, setError] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [selectedTruck, setSelectedTruck] = useState(null);
  const [bidAmount, setBidAmount] = useState(0);

  const getTrucks = async () => {
    try {
      const response = await getVehicalDropdownInfo(transporterId);
      setTrucks(response.data.data);
    } catch (error) {
      handleOpenToast("Error while getting trucks.", "error");
    }
  };

  const getEnquiryDetails = async () => {
    setIsDataLoading(true);
    try {
      const response = await getEnquiryDetailsById(transporterId, enquiryId);
      setEnquiryDetails(response.data.data);
    } catch (error) {
      setError(true);
      handleOpenToast("Error while getting enquiry.", "error");
    }
    setIsDataLoading(false);
  };

  const getEstimation = async (vehicleCategory) => {
    try {
      const response = await getEstimate(
        transporterId,
        enquiryId,
        vehicleCategory
      );
      setEstimationData(response.data.data);
    } catch (error) {
      handleOpenToast("Error while getting estimation.", "error");
    }
  };

  const handleSendOffer = async () => {
    if (!selectedTruck) {
      handleOpenToast("Please select a truck", "error");
      return;
    }
    if (!bidAmount) {
      handleOpenToast("Please enter bid amount", "error");
      return;
    }

    const flag =
      enquiryDetails?.customerEnquiry?.customer?.preferredVehicle
        ?.vehicleCategoryId === selectedTruck.vehicleCategoryId
        ? "BEST_MATCH"
        : "BEST_OFFER";

    const bidData = {
      customerEnquiryId: enquiryDetails.customerEnquiry.customerEnquiryId,
      transporterEnquiryId: enquiryDetails.transporterEnquiryId,
      transporterVehicleId: selectedTruck.transporterVehicleId,
      vehicleTypeId: selectedTruck.vehicleTypeId,
      amountBided: +bidAmount,
      transporterId: transporterId,
      bidFlag: flag,
      distance: enquiryDetails.customerEnquiry.distance,
      vehicleCategory: selectedTruck.vehicleCategory,
      vehicleCategoryId: selectedTruck.vehicleCategoryId,
      vehicleNumber: selectedTruck.vehicleNumber,
      vehicleManufacturerName: selectedTruck.vehicleManufacturerName,
      vehicleModelName: selectedTruck.vehicleModelName,
    };
    try {
      const response = await createBid(bidData);
      if (response.status === 201) {
        handleOpenToast("Your bid is sent successfully!", "success");
      }
      setShowSuccess(true);
    } catch (error) {
      handleOpenToast("Error while sending your bid!", "error");
    }
  };

  useEffect(() => {
    getEnquiryDetails();
    getTrucks();
  }, [transporterId, enquiryId]);

  useEffect(() => {
    if (selectedTruck) {
      getEstimation(selectedTruck.vehicleCategory);
    } else {
      setEstimationData(null);
    }
  }, [selectedTruck]);

  if (isDataLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h5" color="error">
          Error while fetching data! Please try again again.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {showSuccess ? (
        <SuccessUI
          onContinue={() => setShowSuccess(false)}
          redirectUrl={process.env.REACT_APP_WHATSAPP_CHATBOT_URL}
        />
      ) : (
        <Box maxWidth="sm" m="auto" p={3}>
          <ToastMessage />

          <Box px={3}>
            <img
              src="/assets/icons/LZ-logo-face.svg"
              style={{ marginBottom: ".35rem", marginRight: ".25rem" }}
              alt="logoface"
            />
            <img src="/assets/icons/LZ-logo.svg" alt="logo" />
          </Box>
          <Box
            sx={{
              border: "1px solid #EAEAEA",
              borderRadius: "10px",
              padding: "10px",
              marginTop: "20px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <StyledBoxContainer
              gap="30px"
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <StyledText>
                {enquiryDetails?.customerEnquiry?.pickup?.location?.address}
              </StyledText>

              <StyledBoxContainer flexDirection="column" sx={{ width: "20%" }}>
                <Typography
                  sx={{
                    textAlign: "center",
                    margin: "0px",
                  }}
                >
                  {enquiryDetails?.customerEnquiry?.distance + " km"}
                </Typography>
                <img
                  src="/assets/icons/distanceArrow.svg"
                  alt="addImage"
                  style={{
                    height: "20px",
                  }}
                />
              </StyledBoxContainer>

              <StyledText>
                {enquiryDetails?.customerEnquiry?.dropoff?.location?.address}
              </StyledText>
            </StyledBoxContainer>
          </Box>

          <CargoDetails
            material={enquiryDetails.customerEnquiry.material.name}
            weight={enquiryDetails.customerEnquiry.weight}
            pickup={enquiryDetails.customerEnquiry.pickup}
            preferredVehicleModel={
              enquiryDetails.customerEnquiry.customer.preferredVehicle
                .vehicleModel
            }
            estimationData={estimationData}
          />

          <Stack direction="column" gap={1} mt={2}>
            <Box>
              <InputLabel sx={labelStyle}>Select vehicle</InputLabel>
              <Autocomplete
                disablePortal
                sx={{
                  "&.MuiOutlinedInput-root .MuiAutocomplete-input": {
                    padding: 0,
                  },
                }}
                options={trucks}
                getOptionLabel={(option) => option.vehicleNumber}
                onChange={(event, value) => setSelectedTruck(value)}
                value={selectedTruck}
                isOptionEqualToValue={(option, value) =>
                  option.vehicleNumber === value.vehicleNumber
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search Vehicles"
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        padding: "8px 12px",
                      },
                    }}
                  />
                )}
              />
            </Box>
            <Box>
              <InputLabel sx={labelStyle}></InputLabel>
              <TextField
                fullWidth
                type="number"
                variant="outlined"
                placeholder="Enter bid amount"
                width="100%"
                InputProps={{
                  style: {
                    height: "38px",
                    marginTop: "4px",
                    width: "100%",
                  },
                }}
                onChange={(e) => setBidAmount(e.target.value)}
              />
            </Box>
          </Stack>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ marginTop: "20px" }}
            onClick={handleSendOffer}
          >
            Send Offer
          </Button>
        </Box>
      )}
    </>
  );
};

export default SendOfferForm;

const CargoDetails = ({
  material,
  weight,
  pickup,
  preferredVehicleModel,
  estimationData,
}) => {
  const SubtitleText = (props) => (
    <Typography
      color="#ADADAD"
      fontWeight={700}
      variant="subtitle"
      gutterBottom
    >
      {props.children}
    </Typography>
  );
  return (
    <Stack
      direction="column"
      gap={1}
      mt={2}
      sx={{
        border: "1px solid #EAEAEA",
        borderRadius: "10px",
        padding: "10px",
      }}
    >
      <Stack>
        <SubtitleText>Material Type</SubtitleText>
        <Typography variant="subtitle2" fontWeight={700} color="#333">
          {material}
        </Typography>
      </Stack>
      <Stack>
        <SubtitleText>Weight</SubtitleText>
        <Typography variant="subtitle2" fontWeight={700} color="#333">
          {(weight / 1000).toFixed(3)} Tons
        </Typography>
      </Stack>
      <Stack>
        <SubtitleText>Pickup Date</SubtitleText>
        <Typography variant="subtitle2" fontWeight={700} color="#333">
          {moment(pickup.date).format("DD-MM-YYYY")}
        </Typography>
      </Stack>
      <Stack>
        <SubtitleText>Pickup Time</SubtitleText>
        <Typography variant="subtitle2" fontWeight={700} color="#333">
          {pickup.slot}
        </Typography>
      </Stack>
      <Stack>
        <SubtitleText>Estimated Amount</SubtitleText>
        <Typography variant="subtitle2" fontWeight={700} color="#333">
          {estimationData
            ? estimationData?.estimate?.estimationDetails?.minFare &&
              estimationData?.estimate?.estimationDetails?.maxFare
              ? `₹ ${estimationData?.estimate?.estimationDetails?.minFare} - ₹ ${estimationData?.estimate?.estimationDetails?.maxFare}`
              : "Estimation not found"
            : "Select Vehicle to get estimation"}
        </Typography>
      </Stack>
    </Stack>
  );
};

const StyledText = styled(Typography)(({ fontSize, width }) => ({
  color: "#4F4848",
  fontFamily: "Helvetica",
  fontSize: fontSize || "14px",
  fontStyle: "normal",
  fontWeight: "400",
  textTransform: "capitalize",
  width: width || "40%",
}));

const StyledBoxContainer = styled(Box)(
  ({ flexDirection, gap, paddingBottom, justifyContent }) => ({
    display: "flex",
    flexDirection: flexDirection || "row",
    gap: gap || "0px",
    paddingBottom: paddingBottom || "0px",
    justifyContent: justifyContent || "center",
  })
);

const SuccessUI = () => {
  const [count, setCount] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.location.replace(process.env.REACT_APP_WHATSAPP_CHATBOT_URL);
    }, 5000);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f4f6f8",
        padding: 3,
      }}
    >
      <Alert
        icon={<CheckCircleOutlineIcon fontSize="large" />}
        severity="success"
        sx={{
          mb: 2,
          backgroundColor: "#e8f5e9",
          color: "#2e7d32",
          "& .MuiAlert-icon": {
            color: "#2e7d32",
          },
        }}
      >
        <Box>
          <Typography variant="h6" component="div">
            Success!
          </Typography>
          <Typography variant="body1">
            Your bid has been successfully sent. Redirecting in {count}{" "}
            seconds...
          </Typography>
        </Box>
      </Alert>

      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          window.location.replace(process.env.REACT_APP_WHATSAPP_CHATBOT_URL)
        }
        sx={{ mt: 2 }}
      >
        Continue Immediately
      </Button>
    </Box>
  );
};
