import { useEffect, useState } from "react";
import {
  getMaterialTypes,
  getTimeSlots,
  getVehicleModels,
  getVehicleRecommendation,
  getVehicles,
} from "../../../api/Customer/enquiry";
import { CircularProgress, Typography } from "@mui/material";
import { Box, Button } from "@mui/material";
import { LOADING_STATUS } from "../../../constants/constant";
import RebookForm from "./RebookForm";
import moment from "moment";

const RebookScreen = ({ enquiryId, enquiryData, formData, setFormData }) => {
  const [vehicleList, setVehicleList] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [allTimeSlots, setAllTimeSlots] = useState([]);
  const [unit, setUnit] = useState("KG");
  const [isEnquiryDataReady, setIsEnquiryDataAvailable] = useState(
    LOADING_STATUS.INITIAL
  );

  const readableTruckCategories = (vehicle, idx) => {
    return {
      value: `${vehicle.category} wheelers (${vehicle.minCapacity / 1000}T - ${
        vehicle.maxCapacity / 1000
      }T)`,
      id: idx,
    };
  };
  const retriveDataForEnquiry = async () => {
    try {
      setIsEnquiryDataAvailable(LOADING_STATUS.LOADING);
      const [materialTypeResponse, timeSlotResponse] = await Promise.all([
        getMaterialTypes(),
        getTimeSlots(),
      ]);
      const materialTypeForDropdown = materialTypeResponse.data.data.map(
        (type) => ({
          id: type.materialTypeId,
          value: type?.materialName,
          category: type?.materialCategory?.name,
          categoryId: type?.materialCategory?.id,
        })
      );

      const timeSlotForDropdown = timeSlotResponse.data.data.map(
        (slot, idx) => ({
          id: idx,
          value: slot.timeSlot,
          startTime: slot.startTime,
        })
      );
      setAllTimeSlots(timeSlotForDropdown);
      setMaterialList(materialTypeForDropdown);

      setTimeSlots(timeSlotForDropdown);
      setIsEnquiryDataAvailable(LOADING_STATUS.LOADED);
    } catch (err) {
      setIsEnquiryDataAvailable(LOADING_STATUS.FAILED);
    }
  };

  useEffect(() => {
    if (formData.material && formData.weight) {
      const weightInput =
        unit === "KG" ? formData.weight : 1000 * formData.weight;
      getVehicleRecommendation(formData?.material?.categoryId, weightInput)
        .then((data) => {
          const res = data.data.data.map((vehicle) => {
            return {
              value: `${vehicle.numberOfWheels} wheelers (${
                vehicle.minCapacity / 1000
              }T - ${vehicle.maxCapacity / 1000}T)`,
              id: vehicle.vehicleCategoryId,
            };
          });
          if (res.length > 0) {
            setVehicleList(res);
          } else {
            setVehicleList([{ value: "No preference", id: "-1" }]);
          }

          if (res.length > 0) {
            setFormData((p) => ({
              ...p,
              vehicleCategory: res[0].value,
              vehicleCategoryId: res[0].id,
            }));
          } else {
            setFormData((p) => ({
              ...p,
              vehicleCategory: 1,
            }));
          }
        })
        .catch((err) => {
          setVehicleList([]);
        });
    }
  }, [formData.weight, formData.material, unit]);

  useEffect(() => {
    retriveDataForEnquiry();
  }, [formData.vehicleCategory]);

  useEffect(() => {
    if (formData.vehicleCategory === "No preference (any)") {
      setVehicleModels([]);
    } else if (
      formData.vehicleCategory !== "No preference (any)" &&
      formData.vehicleCategory &&
      formData?.vehicleCategory?.length > 0
    ) {
      getVehicleModels(formData?.vehicleCategory?.split(" ")[0])
        .then((data) => {
          const models = data.data.data.map((model) => ({
            value: model.modelName,
            id: model.vehicleTypeId,
          }));
          setVehicleModels(models);
        })
        .catch((err) => {
          setVehicleModels([]);
        });
    }
  }, [formData.vehicleCategory]);

  useEffect(() => {
    if (formData.date) {
      const selectedDate = moment(formData.date).startOf("day");
      const currentDate = moment().startOf("day");

      if (selectedDate.isSame(currentDate)) {
        const currentTime = moment();

        const newTimeSlots = allTimeSlots.filter((slot) => {
          const slotStartTime = moment(slot.startTime, "hh:mm A");
          return slotStartTime.isAfter(currentTime);
        });

        setTimeSlots(newTimeSlots);
      } else {
        setTimeSlots(allTimeSlots);
      }
    }
  }, [formData.date, allTimeSlots]);

  if (isEnquiryDataReady === LOADING_STATUS.FAILED) {
    return (
      <Box textAlign="center" pt={10}>
        <Typography fontSize={24} color="#333" gutterBottom>
          Oops! we ran into some problem
        </Typography>
        <Button variant="contained" onClick={retriveDataForEnquiry}>
          Retry
        </Button>
      </Box>
    );
  } else if (isEnquiryDataReady === LOADING_STATUS.LOADING) {
    return (
      <Box textAlign="center" pt={16}>
        <CircularProgress sx={{ color: "green" }} size={36} />
      </Box>
    );
  }

  return (
    <>
      <RebookForm
        enquiryId={enquiryId}
        formData={formData}
        setFormData={setFormData}
        vehicleList={vehicleList}
        vehicleModels={vehicleModels}
        materialList={materialList}
        timeSlots={timeSlots}
        unit={unit}
        setUnit={setUnit}
      />
    </>
  );
};
export default RebookScreen;
