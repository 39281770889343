import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
};
const transporterIdSlice = createSlice({
  name: "transporterId",
  initialState,
  reducers: {
    setTransporterId: (state, action) => {
      state.id = action.payload;
    },
  },
});

export const { setTransporterId } = transporterIdSlice.actions;
export default transporterIdSlice.reducer;
