import { client } from "../config";

export const createNewCity = async (cityData) => {
  return await client.post("transporter/operational-cities", cityData);
};

export const ftueNewCity = async (cityData) => {
  return await client.post("transporter/ftue/operational-cities", cityData);
};

export const getAllCities = async () => {
  return await client.get(`transporter/operational-cities`);
};

export const editCity = async (cityName, cityData) => {
  return await client.put(
    `transporter/operational-cities/${cityName}`,
    cityData
  );
};

export const deleteCity = async (cityName) => {
  return await client.delete(`transporter/operational-cities/${cityName}`);
};
