import { Box, Button, Stack, Typography } from "@mui/material";
import RejectDocumentModal from "./RejectDocumentModal";
import { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../api/config";
import Cookies from "js-cookie";
import KycDocumentImageViewer from "../../../component/Common/KycDocumentsImageViewer";

const PANDocuments = ({ panDocuments, kycId }) => {
  const [panStatus, setPanStatus] = useState(panDocuments?.approvalStatus);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  //   states for open reject document modal
  const handleOpen = () => setOpenRejectModal(true);
  const handleClose = () => setOpenRejectModal(false);

  const approvePANRequest = async () => {
    try {
      await axios
        .post(
          BASE_URL.concat("admin/kyc/approve"),
          {
            kycId,
            documentType: "pan",
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("admintoken")}`,
            },
          }
        )
        .then((data) => {
          setPanStatus(data.data.data.kyc.panDetails.approvalStatus);
          window.location.reload();
        });
    } catch (err) {}
  };

  const rejectPANRequest = async (reason) => {
    axios
      .post(
        BASE_URL.concat("admin/kyc/reject"),
        {
          kycId,
          documentType: "pan",
          resonOfRejection: reason,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("admintoken")}`,
          },
        }
      )
      .then((data) => {
        setPanStatus(data.data.data.kyc.panDetails.approvalStatus);
        window.location.reload();
      });
    handleClose();
  };

  if (!panDocuments)
    return (
      <Typography
        sx={{
          color: "#333",
          fontSize: "1rem",
          marginTop: "4rem",
          marginLeft: "1rem",
        }}
      >
        No documents uploaded yet
      </Typography>
    );
  return (
    <Box p={2} mt={5}>
      {panDocuments?.documentNumber && (
        <>
          <Typography sx={{ color: "#333", fontSize: "1rem" }}>
            PAN Details
          </Typography>
          <Typography sx={{ color: "#333", fontSize: "1rem", fontWeight: 700 }}>
            {panDocuments?.documentNumber}
          </Typography>
        </>
      )}
      <Stack direction="row" alignItems="center" spacing={2} mt={4}>
        <KycDocumentImageViewer imageUrl={panDocuments?.frontUrl} imageSide="pan"/>
      </Stack>
      <Stack
        width={600}
        direction="row"
        justifyContent="space-between"
        pr={0.5}
        mt={5}
      >
        {panStatus && panStatus === "APPROVED" ? (
          <>
            <span />
            <Button
              sx={{ color: "#0C7F44", textTransform: "none" }}
              endIcon={
                <img src="/assets/icons/green-tick.svg" alt="approved" />
              }
            >
              Approved
            </Button>
          </>
        ) : panStatus === "REJECTED" ? (
          <>
            <span />
            <Button
              sx={{ color: "#FF0707", textTransform: "none" }}
              endIcon={<img src="/assets/icons/red-cross.svg" alt="approved" />}
            >
              Rejected
            </Button>
          </>
        ) : (
          <>
            <Button variant="outlined" color="primary" onClick={handleOpen}>
              Reject
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={approvePANRequest}
            >
              Approve
            </Button>
          </>
        )}
      </Stack>
      <RejectDocumentModal
        documentType="PAN"
        openRejectModal={openRejectModal}
        handleOpen={handleOpen}
        handleClose={handleClose}
        handleRejectDocument={rejectPANRequest}
      />
    </Box>
  );
};

export default PANDocuments;

const FrontImageSection = ({ frontUrl = "" }) => {
  return (
    <Box>
      <Typography sx={{ color: "#333", fontSize: "1rem" }}>
        Front image
      </Typography>
      <Box
        sx={{ border: "1px solid #eaeaea", height: "180px", width: "290px" }}
      >
        <img src={frontUrl} alt="front" width="100%" height="100%" />
      </Box>
    </Box>
  );
};

