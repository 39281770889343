import { adminApi } from "../config";

// get all kyc requests for approval
export const getAllKycRequests = async (
  stakeholderFilter = "",
  statusFilter = "",
  page,
  inputValue
) =>
  await adminApi
    .get(
      `admin/kyc-approval-requests/?stakeholder=${stakeholderFilter==="ALL"?"":stakeholderFilter}&status=${statusFilter==="ALL"?"":statusFilter}&page=${page}&phoneNumber=${inputValue}`
    )
    .then((response) => response)
    .catch((err) => err);

export const getDocumentDetailsOfUser = async(kycId)=>{
  try{
    const response = await adminApi.get(`admin/kyc-details/${kycId}`)
    return response;
  }
  catch(error){
    return error;
  }
}
