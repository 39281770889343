import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button, Grid, InputLabel, MenuItem, TextField } from "@mui/material";
import { validateInteger } from "../../../../utils/validation";
import { createVehicle, editVehicle } from "../../../../api/Admin/vehicleType";

const AddEditVehicleModal = ({
  closeAddEditVehicleTypeModal,
  addEditVehicleModalData,
  handleDeleteVehicleTypeButtonClick,
  handleOpenToast,
  fetchAllVehicleType,
}) => {
  // Initial state for vehicle information and dropdown handling
  const [vehicleTypeInfo, setVehicleTypeInfo] = useState({
    modelName: "",
    commonlyKnownAs: "",
    manufacturerName: "",
    fuelType: "",
    noOfWheels: "",
    capacity: "",
  });

  // State to manage validation errors.
  const [validationErrors, setValidationErrors] = useState({});

  // Functions to validate vehicle fields.
  const validateVehicleInfo = (data) => {
    const errors = {};
    if (!validateInteger(parseInt(data.numberOfWheels))) {
      errors.noOfWheels = "Please enter a valid integer value.";
    }
    if (!validateInteger(parseInt(data.capacity))) {
      errors.capacity = "Please enter a valid integer value.";
    }
    if (!data.fuelType) {
      errors.fuelType = "Please select a fuel option.";
    }
    if (!data.modelName) {
      errors.modelName = "Please enter a model name.";
    }
    if (!data.manufacturerName) {
      errors.manufacturerName = "Please enter a manufacturer name.";
    }

    return errors;
  };

  // function to handle vehicle details change
  const handleVehicleDetailsChange = async () => {
    const body = {
      modelName: vehicleTypeInfo.modelName,
      manufacturerName: vehicleTypeInfo.manufacturerName,
      commonlyKnownAs: vehicleTypeInfo.commonlyKnownAs,
      numberOfWheels: vehicleTypeInfo.noOfWheels,
      capacity: vehicleTypeInfo.capacity,
      fuelType: vehicleTypeInfo.fuelType,
    };

    const errors = validateVehicleInfo(body);
    setValidationErrors(errors);
    if (Object.keys(errors).length !== 0) {
      return { errors };
    }

    // If addEditVehicleModalData is present, then edit the vehicle type.
    if (addEditVehicleModalData) {
      let response = await editVehicle(addEditVehicleModalData.vehicleTypeId, {
        modelName: body.modelName,
        manufacturerName: body.manufacturerName,
        commonlyKnownAs: body.commonlyKnownAs,
        capacity: body.capacity,
        fuelType: body.fuelType,
      });
      if (response.status === 200) {
        handleOpenToast("Vehicle edited successfully.");
        await fetchAllVehicleType();
      } else {
        handleOpenToast("Vehicle edition failed.");
      }
    } else {
      let response = await createVehicle(body);
      if (response.status === 201) {
        await fetchAllVehicleType();
        handleOpenToast("Vehicle created successfully.");
      } else {
        handleOpenToast("Vehicle creation failed.");
      }
    }
    closeAddEditVehicleTypeModal();
  };

  // useEffect to set vehicle type info in state if addEditVehicleModalData is present.
  useEffect(() => {
    if (addEditVehicleModalData) {
      const newVehicleInfo = {
        modelName: addEditVehicleModalData?.modelName || "",
        commonlyKnownAs: addEditVehicleModalData?.commonlyKnownAs || "",
        fuelType: addEditVehicleModalData?.fuelType || "",
        noOfWheels: addEditVehicleModalData?.numberOfWheels || "",
        capacity: addEditVehicleModalData?.capacity || "",
        manufacturerName: addEditVehicleModalData?.manufacturerName || "",
      };
      setVehicleTypeInfo((prevState) => ({
        ...prevState,
        ...newVehicleInfo,
      }));
    }
  }, [addEditVehicleModalData]);

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "white",
        p: 3,
        borderRadius: 4,
        width: "1000px",
        height: "550px",
      }}
    >
      <img
        src="/assets/icons/cross-icon.svg"
        alt="cross"
        style={{
          position: "absolute",
          right: "3%",
          top: "4%",
          cursor: "pointer",
        }}
        onClick={() => closeAddEditVehicleTypeModal()}
      />
      <Box sx={{ fontSize: "1.5rem", fontWeight: 700 }}>
        {addEditVehicleModalData ? "Edit Truck Type" : "Add Truck Type"}
      </Box>
      <div style={{ marginTop: "2rem" }}>
        <Box sx={{ width: "100%", gap: "1rem", padding: "1rem" }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              gap: "1rem",
            }}
          >
            <Box
              sx={{
                marginBottom: "2rem",
                width: "48%",
              }}
            >
              <InputLabel
                sx={{ color: "#333", fontSize: "1.125rem", fontWeight: 700 }}
              >
                Model Name <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                variant="outlined"
                placeholder="Ashok Leyland Dost Strong Pickup"
                fullWidth
                onChange={(event) => {
                  setVehicleTypeInfo((vehicleInfo) => ({
                    ...vehicleInfo,
                    ["modelName"]: event.target.value,
                  }));
                }}
                value={vehicleTypeInfo.modelName}
                error={!!validationErrors && !!validationErrors.modelName}
                helperText={validationErrors && validationErrors.modelName}
              />
            </Box>
            <Box sx={{ marginBottom: "2rem", width: "48%" }}>
              <InputLabel
                sx={{ color: "#333", fontSize: "1.125rem", fontWeight: 700 }}
              >
                Manufacturer Name <span style={{ color: "red" }}>*</span>
              </InputLabel>

              <TextField
                variant="outlined"
                placeholder="Ashok Leyland"
                fullWidth
                onChange={(event) => {
                  setVehicleTypeInfo((vehicleInfo) => ({
                    ...vehicleInfo,
                    ["manufacturerName"]: event.target.value,
                  }));
                }}
                value={vehicleTypeInfo.manufacturerName}
                error={
                  !!validationErrors && !!validationErrors.manufacturerName
                }
                helperText={
                  validationErrors && validationErrors.manufacturerName
                }
              />
            </Box>
          </Box>

          <Box display="flex" width="100%" gap="1rem">
            <Box sx={{ marginBottom: "2rem", width: "48%" }}>
              <InputLabel
                sx={{ color: "#333", fontSize: "1.125rem", fontWeight: 700 }}
              >
                Commonly known as
              </InputLabel>

              <TextField
                variant="outlined"
                placeholder="4w-1.35 MT"
                fullWidth
                onChange={(event) => {
                  setVehicleTypeInfo((vehicleInfo) => ({
                    ...vehicleInfo,
                    ["commonlyKnownAs"]: event.target.value,
                  }));
                }}
                value={vehicleTypeInfo.commonlyKnownAs}
              />
            </Box>
            <Box sx={{ marginBottom: "2rem", width: "48%" }}>
              <InputLabel
                sx={{ color: "#333", fontSize: "1.125rem", fontWeight: 700 }}
              >
                Number of Wheels <span style={{ color: "red" }}>*</span>
              </InputLabel>

              <TextField
                variant="outlined"
                placeholder="4"
                fullWidth
                onChange={(e) => {
                  setVehicleTypeInfo((vehicleInfo) => ({
                    ...vehicleInfo,
                    ["noOfWheels"]: e.target.value,
                  }));
                }}
                value={vehicleTypeInfo.noOfWheels}
                disabled={!!addEditVehicleModalData}
                error={!!validationErrors && !!validationErrors.noOfWheels}
                helperText={validationErrors && validationErrors.noOfWheels}
              />
            </Box>
          </Box>

          <Box display="flex" width="100%" gap="1rem">
            <Box sx={{ marginBottom: "2rem", width: "48%" }}>
              <Grid item xs={6}>
                <InputLabel
                  sx={{ color: "#333", fontSize: "1.125rem", fontWeight: 700 }}
                >
                  Fuel Type <span style={{ color: "red" }}>*</span>
                </InputLabel>

                <TextField
                  select
                  label={vehicleTypeInfo.fuelType ? "" : "Select Fuel Type"}
                  value={vehicleTypeInfo.fuelType}
                  onChange={(event) => {
                    setVehicleTypeInfo((vehicleInfo) => ({
                      ...vehicleInfo,
                      ["fuelType"]: event.target.value,
                    }));
                  }}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: false }}
                  sx={{
                    "& .MuiOutlinedInput-input:-webkit-autofill": {
                      WebkitBoxShadow: "0 0 0 1000px white inset",
                    },
                    "& .MuiInputLabel-outlined.Mui-focused": {
                      color: "gray",
                    },
                    "& .MuiInputLabel-outlined": {
                      color: "gray",
                    },
                  }}
                  error={!!validationErrors && !!validationErrors.fuelType}
                  helperText={validationErrors && validationErrors.fuelType}
                >
                  {["PETROL", "DIESEL", "ELECTRIC"].map((fuel, index) => (
                    <MenuItem key={index} value={fuel}>
                      {fuel}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Box>
            <Box sx={{ marginBottom: "2rem", width: "48%" }}>
              <InputLabel
                sx={{ color: "#333", fontSize: "1.125rem", fontWeight: 700 }}
              >
                Payload in kg <span style={{ color: "red" }}>*</span>
              </InputLabel>

              <TextField
                variant="outlined"
                placeholder="1350"
                fullWidth
                onChange={(e) => {
                  setVehicleTypeInfo((vehicleInfo) => ({
                    ...vehicleInfo,
                    ["capacity"]: e.target.value,
                  }));
                }}
                value={vehicleTypeInfo.capacity}
                error={!!validationErrors && !!validationErrors.capacity}
                helperText={validationErrors && validationErrors.capacity}
              />
            </Box>
          </Box>
        </Box>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleVehicleDetailsChange}
        sx={{ position: "absolute", right: "3%", bottom: "5%" }}
      >
        {addEditVehicleModalData ? "Save" : "Add"}
      </Button>
      {addEditVehicleModalData ? (
        <span
          onClick={handleDeleteVehicleTypeButtonClick}
          style={{
            position: "absolute",
            left: "3%",
            bottom: "5%",
            color: "red",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Delete
        </span>
      ) : null}
    </Box>
  );
};
export default AddEditVehicleModal;
