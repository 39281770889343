import { adminApi } from "../config";

export const getAllTransporters = async (page, inputValue, statusFilter) => {
  try {
    const response = await adminApi.get(
      `admin/transporters?page=${
        page + 1
      }&search=${inputValue}&kycStatus=${statusFilter}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getKycDetailById = async (kycId) => {
  try {
    const response = await adminApi.get(`admin/kyc-details/${kycId}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getDriversListOfTransporter = async (
  transporterId,
  page,
  pageSize,
  phone
) => {
  try {
    const response = await adminApi.get(
      `transporter/${transporterId}/drivers?page=${
        page + 1
      }&pageSize=${pageSize}&phone=${phone}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getTrucksListOfTransporter = async (
  transporterId,
  page,
  pageSize,
  vehicleNumber
) => {
  try {
    const response = await adminApi.get(
      `transporter/${transporterId}/transporter-vehicles?page=${
        page + 1
      }&pageSize=${pageSize}&vehicleNumber=${vehicleNumber}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getTransporterById = async (id) => {
  try {
    const response = await adminApi.get(`admin/transporters/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getTripsListOfTransporter = async (
  id,
  page,
  pageSize,
  tripStatus,
  search
) => {
  try {
    const queryParam = new URLSearchParams({
      page: page + 1,
      pageSize,
      tripStatus,
      search,
    });

    const response = await adminApi.get(
      `admin/transporter/trips/${id}?${queryParam}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getTransporterTransactions = async (
  id,
  page,
  pageSize,
  search,
  isPaymentDoneThroughLEPlatform
) => {
  try {
    const queryParam = new URLSearchParams({
      page: page + 1,
      pageSize,
      search,
      isPaymentDoneThroughLEPlatform,
    });

    const response = adminApi.get(
      `admin/transporter/transactions/${id}?${queryParam}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateTransporterStatus = async (transporterId, status) => {
  try {
    const response = await adminApi.patch(
      `admin/transporter/${transporterId}/status`,
      {
        status,
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
