import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY_ID,
  region: process.env.REACT_APP_AWS_REGION,
  signatureVersion: "v4",
});

const s3 = new AWS.S3();

export const uploadimage = async (path, file) => {
  const params = {
    Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    Key: `${path + "/" + file.name}`,
    Body: file,
    ACL: "public-read",
  };
  await s3.putObject(params).promise();

  const signedUrl = `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${path}/${file.name}`;
  return signedUrl;
};
