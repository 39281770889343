import React, { useEffect, useState } from "react";
import { Box, Button, Skeleton, Typography } from "@mui/material";

import { BookingsCollapse } from "../BookingsCollapse";
import SupportNumber from "../../Transporter/Dashboard/SupportNumber";
import { getrTripDetails } from "../../../api/Customer/trips";
import useToast from "../../../hooks/useToast";
import { LOADING_STATUS } from "../../../constants/constant";
import UpcomingMapTracking from "./UpcominMapTracking";
import InTransitMapTracking from "./InTransitMapTracking";

export default function TripDetailedView(props) {
  const {
    entityId,
    customerId,
    entityType,
    selectedTripType,
    selectedTripView,
    trips,
    customerLiveTrips,
    customerUpcomingTrips,
    liveTrips,
    upComingTrips,
  } = props;
  const [isTripDetailsForMapFetched, setIsTripDetailsForMapFetched] =
    useState(false);

  const selectedTrip = trips.find((trip) => trip.id === entityId);
  const { handleOpenToast } = useToast();

  const [tripDetails, setTripDetails] = useState([]);
  const [isTripDetailsFetched, setIsTripDetailsFetched] = useState("");

  const tripByCustomerId = async () => {
    try {
      setIsTripDetailsFetched(LOADING_STATUS.LOADING);
      setIsTripDetailsForMapFetched(false);
      const response = await getrTripDetails(customerId, entityId);
      if (response?.status === 200) {
        setTripDetails(response.data.data);
        setIsTripDetailsFetched(LOADING_STATUS.LOADED);
        if (selectedTripType === "upComing") {
          setIsTripDetailsForMapFetched(true);
        }
      }
    } catch (error) {
      handleOpenToast("Error while getting trip details", "error");
      setIsTripDetailsFetched(LOADING_STATUS.FAILED);
    }
  };

  useEffect(() => {
    if (entityType === "trip") {
      tripByCustomerId();
    }
  }, [customerId, entityId, entityType]);

  if (!selectedTrip || entityType === "search")
    return (
      <Box
        border="2px solid #5386E4"
        borderRadius="5px"
        height="100%"
        overflow="auto"
      >
        <iframe
          title="Google Map of India"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7579010.627389128!2d68.10969971093751!3d20.94626971035691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395d5ee6b0ae4b45%3A0x7fbcf8f71a4b3a1a!2sIndia!5e0!3m2!1sen!2sin!4v1649251739486!5m2!1sen!2sin"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </Box>
    );

  return (
    <Box
      borderRadius="5px"
      bgcolor="#E9F2F4"
      border="2px solid #5386E4"
      overflow="auto"
      height="100%"
    >
      {isTripDetailsFetched === LOADING_STATUS.LOADED && (
        <Box>
          {entityType === "trip" && selectedTripView === "details" && (
            <BookingsCollapse
              trip={selectedTrip}
              fromDashboard={true}
              entityId={entityId}
            />
          )}{" "}
        </Box>
      )}

      {isTripDetailsFetched === LOADING_STATUS.LOADING && (
        <Box
          padding="60px 60px"
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Box display="flex" gap={1}>
            <Skeleton variant="rounded" width={150} height={60} />
            <Skeleton variant="rounded" width={150} height={60} />
            <Skeleton variant="rounded" width={150} height={60} />
            <Skeleton variant="rounded" width={150} height={60} />
          </Box>
          <Box width={630} pt={1}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        </Box>
      )}

      {isTripDetailsFetched === LOADING_STATUS.FAILED && (
        <Box display="flex" alignItems="center" flexDirection="column" pt={20}>
          <Typography variant="body1" paragraph>
            Failed to loading the trip details....
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={tripByCustomerId}
          >
            Retry
          </Button>
        </Box>
      )}

      {entityType === "trip" &&
        selectedTripView === "map" &&
        selectedTripType === "live" && (
          <InTransitMapTracking trips={trips} entityId={entityId} />
        )}

      {isTripDetailsForMapFetched &&
        entityType === "trip" &&
        selectedTripView === "map" &&
        selectedTripType === "upComing" && (
          <UpcomingMapTracking
            trips={trips}
            entityId={entityId}
            tripDetails={tripDetails}
          />
        )}

      {entityType === "trip" && selectedTripView === "support" && (
        <SupportNumber
          tripDetails={tripDetails}
          trips={trips}
          entityId={entityId}
          customerSupportNumber={true}
        />
      )}
    </Box>
  );
}
