import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from "@mui/material";
import styled from "@emotion/styled";
import { patchToggleFavourite } from "../../../api/Customer/trips";
import useToast from "../../../hooks/useToast";
import CloseIcon from "@mui/icons-material/Close";

const StyledBox = styled(Box)(({ flexDirection, gap, marginTop }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: flexDirection || "row",
  gap: gap || "0px",
  marginTop: marginTop || "0px",
}));

const StyledText = styled(Typography)(() => ({
  color: "#4F4848",
  fontSize: "14px",
  fontWeight: "400",
  width: "400px",
}));

const RemoveTripModal = (props) => {
  const {
    isRemoveTripModalOpen,
    customerId,
    trip,
    closeRemoveTripModal,
    fetchTrips,
  } = props;

  const { handleOpenToast } = useToast();

  const confirmRemoveTrip = async () => {
    try {
      const response = await patchToggleFavourite(customerId, trip.tripId);
      if (response.status === 200) {
        closeRemoveTripModal(false);
        handleOpenToast("Removed trip successfully", "success");
        fetchTrips();
      }
    } catch (error) {
      handleOpenToast("Error while remove the favorite trip", "error");
    }
  };

  return (
    <Dialog open={isRemoveTripModalOpen} width="300px" height="300px">
      <DialogTitle
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mt={2}
      >
        <Typography color="#333" fontSize="24px" fontWeight="700">
          Remove Favorite Trip
        </Typography>
        <CloseIcon
          sx={{
            cursor: "pointer",
          }}
          onClick={closeRemoveTripModal}
        />
      </DialogTitle>

      <DialogContent>
        <StyledBox flexDirection="column" marginTop="20px" gap="25px">
          <StyledText textAlign="center">
            Do you want to remove favorite trip?
          </StyledText>

          <StyledBox gap="25px">
            <Button
              onClick={confirmRemoveTrip}
              variant="rejectButton"
              sx={{ padding: "7px 24px", fontWeight: 700 }}
            >
              Yes
            </Button>
            <Button
              sx={{
                fontWeight: 700,
                padding: "6px 24px",
              }}
              onClick={closeRemoveTripModal}
              variant="outlined"
            >
              No
            </Button>
          </StyledBox>
        </StyledBox>
      </DialogContent>
    </Dialog>
  );
};

export default RemoveTripModal;
