import React from "react";
import { Box, Typography } from "@mui/material";

import styled from "@emotion/styled";
import moment from "moment";

const StyledText = styled(Typography)(
  ({ fontSize, textTransform, fontWeight }) => ({
    color: "#4F4848",
    fontSize: fontSize || "12px",
    fontWeight: fontWeight || "400",
    textTransform: textTransform || "capitalize",
  })
);

const StyledSubHeading = styled(Typography)(({ color, textAlign }) => ({
  color: color || "#333333",
  textAlign: textAlign || "center",
  fontSize: "16px",
  fontWeight: "700",
}));

export default function AmountReceived(props) {
  const { tripDetails } = props;

  return (
    <Box display="flex" gap="20px">
      <StyledText width="140px" fontSize="14px">
        Amount Received
      </StyledText>
      <StyledSubHeading color="#0C7F44" textTransform="none" textAlign="left">
        {tripDetails?.transporterTransactions?.length
          ? tripDetails?.transporterTransactions.map((transaction) => {
              return (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  gap="20px"
                >
                  <StyledSubHeading
                    color="#0C7F44"
                    textTransform="none"
                    textAlign="left"
                    width="100px"
                  >
                    ₹ {""}
                    {transaction?.amount.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledSubHeading>
                  <StyledText textTransform="none">
                    received on{" "}
                    {moment(transaction?.transactedAt).format(
                      "DD-MM-YYYY, hh:mm A."
                    )}
                  </StyledText>
                </Box>
              );
            })
          : `₹ ${tripDetails?.transporterAmounts?.amountPaid.toLocaleString()}`}
      </StyledSubHeading>
    </Box>
  );
}
