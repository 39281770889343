import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import styled from "@emotion/styled";

const StyledInput = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "4px 12px",
  background: "#dde7ef",
  margin: "2px",
  gap: "8px",
  borderRadius: "3px",
  border: "1px solid #5386e4",
}));

const InterCityMultiSelect = (props) => {
  const removeItem = (index) => {
    const selectedClone = [...props.selectedItems];
    selectedClone.splice(index, 1);
    props.setItems?.(selectedClone);
  };

  return (
    <Box width="100%" fontWeight="700" fontSize="14px">
      <Autocomplete
        multiple
        disableCloseOnSelect
        value={props.selectedItems}
        onChange={(e, selectedItems) => {
          props.setItems?.(selectedItems);
        }}
        options={props?.options}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={props.placeholder}
            inputProps={{
              ...params.inputProps,
              style: {
                padding: "8px 12px",
              },
            }}
          />
        )}
        renderTags={() => <div />}
      />

      {props.selectedItems && (
        <Box
          sx={{
            ...(props.selectedItems.length
              ? {
                  border: "1px solid #c6c6c6",
                  borderTop: "none",
                  fontWeight: "700",
                  fontSize: "14px",
                  padding: "4px 12px",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexWrap: "wrap",
                }
              : {}),
          }}
        >
          {props.selectedItems?.map((item, index) => (
            <StyledInput key={index}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "4px",
                }}
              >
                {props.city}{" "}
                <SyncAltIcon sx={{ width: "15px", height: "15px" }} /> {item}
                <ClearIcon
                  onClick={() => removeItem(index)}
                  style={{
                    cursor: "pointer",
                    width: "14px",
                    height: "14px",
                    marginLeft: "5px",
                  }}
                />
              </Box>
            </StyledInput>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default InterCityMultiSelect;
