import { useLocation, useNavigate } from "react-router-dom";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  getEstimateRangeForEnquiry,
  postAnEnquiry,
  postEnquiryNoPreference,
} from "../api/Customer/enquiry";
import EastIcon from "@mui/icons-material/East";
import useToast from "../hooks/useToast";

const WhatsAppEnquiryEstimationPage = () => {
  const navigate = useNavigate();
  const { ToastMessage, handleOpenToast } = useToast({
    vertical: "top",
    horizontal: "center",
  }); 
  const location = useLocation();
  const { userId ,enqId , pickup, dropoff, distance,isRebook=false} = location.state?.details;
  const [estimateCode, setEstimateCode] = useState("");
  const [estimationData, setEstimationData] = useState(null);
  const [isEnquiryPosted, setIsEnquiryPosted] = useState(false);
  const [timerValue, setTimerValue] = useState(5);

  useEffect(() => {
    let timerId;
    if (isEnquiryPosted) {
      // Start countdown only if enquiry is posted
      timerId = setInterval(() => {
        setTimerValue((prevValue) => {
          if (prevValue === 0) {
            clearInterval(timerId); // Stop the countdown
            // Redirect or perform other actions here if needed when countdown reaches 0
            return prevValue; // Optionally reset or keep the last value
          }
          return prevValue - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timerId); // Cleanup on unmount or when `isEnquiryPosted` changes
  }, [isEnquiryPosted]);

  const fetchEnquiryEstimationDetails = async () => {
    try {
      const response = await getEstimateRangeForEnquiry(enqId);
      setEstimateCode(response.data.data.estimateCode);
      setEstimationData(response.data.data);
    } catch (err) {
      setEstimateCode(-1);
    }
  };

  const handlePostEnquiry = async () => {
    try {
      await postAnEnquiry(enqId);
      // handleOpenToast("Enquiry posted successfully !");
      setIsEnquiryPosted(true);
      setTimeout(() => {
        window.location.replace(process.env.REACT_APP_WHATSAPP_CHATBOT_URL);
      }, 5000);
    } catch (err) {
      handleOpenToast("Couldn't post enquiry", "error");
    }
  };
  const EstimationView = () => {
    switch (estimateCode) {
      case 1: // Any vehicle preferred
        return (
          <AnyVehiclePreferredView
            data={estimationData}
            enquiryStatus={estimationData.enquiryStatus}
            isEnquiryPosted={isEnquiryPosted}
          />
        );
      case 2: // cannot calculate
        return (
          <Typography
            color="#333"
            fontWeight={700}
            fontSize={16}
            textAlign="center"
            py={2}
          >
            {estimationData.enquiryStatus === "NO_SERVICE"
              ? "Currently we are not serving for the selected route"
              : "Estimation cannot be calculated"}
          </Typography>
        );
      case 3: // cannot calculate , large distance
        return (
          <>
            <Typography
              color="#333"
              fontWeight={700}
              fontSize={16}
              textAlign="center"
              py={2}
            >
              Distance too large, estimation cannot be calculated
            </Typography>
          </>
        );
      case 4: // Calculated
        return <EstimationCalculatedView data={estimationData} />;
      case -1: // if api fails
        return (
          <ErrorFetchingEstimate
            fetchEnquiryEstimationDetails={fetchEnquiryEstimationDetails}
          />
        );
      default:
        return null;
    }
  };
  useEffect(() => {
    fetchEnquiryEstimationDetails();
  }, []);
  return (
    <>
      <ToastMessage />
      <Box px={3} pt={3}>
        <img
          src="/assets/icons/LZ-logo-face.svg"
          style={{ marginBottom: ".35rem", marginRight: ".25rem" }}
          alt="logoface"
        />
        <img src="/assets/icons/LZ-logo.svg" alt="logo" />
      </Box>
      <Stack
        direction="column"
        textAlign="center"
        sx={{ background: "#EAEAEA" }}
        p={2}
        mt={2}
        borderRadius={0.5}
      >
        <Typography fontSize=".9rem">
          {pickup}
        </Typography>
        <Stack direction="column" alignItems="center">
          <EastIcon />
          <Typography fontWeight={700} fontSize=".9rem" color="#333">
            {distance} Km
          </Typography>
        </Stack>
        <Typography fontSize=".9rem">
          {dropoff}
        </Typography>
      </Stack>

      <Box maxWidth="sm" m="auto">
        <Box px={3}>
          <EstimationView />
        </Box>
        {/* cargo details will shown for each cases except when api fails */}
        {estimationData && estimateCode !== -1 && (
          <CargoDetails data={estimationData} />
        )}
        {estimationData && estimateCode === 3 && (
          <Typography
            px={3}
            fontSize={14}
            color="green"
            textAlign="center"
            mt={2}
          >
            {isEnquiryPosted
              ? "Your enquiry has been posted"
              : "You can still post your enquiry"}
          </Typography>
        )}
        <Stack p={3} gap={2}>
          {estimationData && estimationData.enquiryStatus === "NO_SERVICE" ? (
            <>
            {isEnquiryPosted && (
              <Typography
                color="#333"
                textAlign="center"
                pb={1}
                fontSize={14}
              >
                You will be redirected to whatsapp in{" "}
                <span style={{ color: "blue" }}>{timerValue}s</span>
              </Typography>
            )}
            <Button
              variant="contained"
              disabled={isEnquiryPosted}
              onClick={() =>
                // navigate(`/enquiry-form/${userId}`, {
                //   replace: true,
                // })
                postEnquiryNoPreference(enqId,userId).then(data=>{
                  setIsEnquiryPosted(true)
                  setTimeout(() => {
                    window.location.replace(process.env.REACT_APP_WHATSAPP_CHATBOT_URL);
                  }, 5000);
                }).catch(err=>{
                  handleOpenToast('Could not post enquiry !', 'error')
                })
              }
            >
              {!isEnquiryPosted ? "Post" : "Posted"}
            </Button></>
          ) : (
            <Stack>
              {isEnquiryPosted && (
                <Typography
                  color="#333"
                  textAlign="center"
                  pb={1}
                  fontSize={14}
                >
                  You will be redirected to whatsapp in{" "}
                  <span style={{ color: "blue" }}>{timerValue}s</span>
                </Typography>
              )}
              {estimateCode !== -1 && (
                <Button
                  variant="contained"
                  onClick={handlePostEnquiry}
                  disabled={isEnquiryPosted}
                >
                  {!isEnquiryPosted ? "Post" : "Posted"}
                </Button>
              )}
            </Stack>
          )}
          <Button
            sx={{ textTransform: "none" }}
            onClick={() =>
              !isRebook ?  navigate(`/enquiry-form/${userId}`, {
                replace: true,
              }) : navigate(`/chatbot/rebook-enquiry/${userId}/${enqId}`,{replace:true})
            }
          >
            Back
          </Button>
        </Stack>
      </Box>
    </>
  );
};
export default WhatsAppEnquiryEstimationPage;

const AnyVehiclePreferredView = ({ enquiryStatus, isEnquiryPosted }) => {
  return (
    <Box textAlign="center" mt={3}>
      <Typography fontSize={16} color="#333" fontWeight={600} gutterBottom>
        An estimate cannot be provided for this enquiry!
      </Typography>
      <Typography
        fontSize={14}
        color={isEnquiryPosted ? "green" : "#6d6d6d"}
        mb={2}
      >
        {enquiryStatus !== "NO_SERVICE"
          ? isEnquiryPosted
            ? "Your enquiry has been posted"
            : "You will receive best offers after posting this enquiry"
          : "Please post your enquiry, and we will get back to you shortly."}
      </Typography>
    </Box>
  );
};

const ErrorFetchingEstimate = ({ fetchEnquiryEstimationDetails }) => {
  return (
    <Stack p={3}>
      <Typography color="#333" variant="h5" textAlign="center" mt={10} mb={3}>
        Error, fetching estimatation information.
      </Typography>
      <Button
        color="primary"
        variant="contained"
        onClick={fetchEnquiryEstimationDetails}
      >
        Retry
      </Button>
    </Stack>
  );
};

const EstimationCalculatedView = ({ data }) => {
  const { estimationDetails, customer, weight } = data;
  return (
    <Box py={2} textAlign="center">
      <Typography fontSize={16} fontWeight={600} color="#333">
        {customer.preferredVehicle.vehicleModel}
        {`(${customer.preferredVehicle.vehicleCategory} Wheelers)`}
      </Typography>

      <Typography fontSize={20} fontWeight={600} color="#333">
        Estimated Fare Per Ton{" "}
      </Typography>
      <Typography fontSize={18} fontWeight={700} color="#0C7F44">
        &#8377;{parseInt(estimationDetails.minFare / (weight / 1000))} - &#8377;
        {parseInt(estimationDetails.maxFare / (weight / 1000))}{" "}
      </Typography>

      <Typography fontSize={16} color="#6D6D6D" fontWeight={700}>
        Total Estimated Fare &#8377;{parseInt(estimationDetails.minFare)} -
        &#8377;{parseInt(estimationDetails.maxFare)}
      </Typography>
    </Box>
  );
};

const CargoDetails = ({ data }) => {
  const SubtitleText = (props) => (
    <Typography color="#ADADAD" fontWeight={700} fontSize={14}>
      {props.children}
    </Typography>
  );
  const { material, pickup, weight } = data;

  return (
    <>
      <Divider />
      <Grid container pl={7} pr={3} mt={2} mb={2}>
        <Grid item xs={4}>
          <SubtitleText>Goods Type</SubtitleText>
        </Grid>
        <Grid item xs={8}>
          <Typography fontSize={14} fontWeight={700} color="#333">
            {material?.name}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <SubtitleText>Weight</SubtitleText>
        </Grid>
        <Grid item xs={8}>
          <Typography fontSize={14} fontWeight={700} color="#333">
            {weight}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <SubtitleText>Pickup Date</SubtitleText>
        </Grid>
        <Grid item xs={8}>
          <Typography fontSize={14} fontWeight={700} color="#333">
            {pickup.date.split("T")[0]}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <SubtitleText>Pickup Time</SubtitleText>
        </Grid>
        <Grid item xs={8}>
          {" "}
          <Typography fontSize={14} fontWeight={700} color="#333">
            {pickup.slot}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};