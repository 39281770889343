export const validateInteger = (input) => {
  const integerPattern = /^\d{1,5}$/; // Validates integers with up to 5 digits

  if (typeof input === "number") {
    const inputString = input.toString();
    if (integerPattern.test(inputString)) {
      return true;
    }
  }
  return false;
};

export const validateFloat = (input) => {
  const floatPattern = /^\d{1,5}(\.\d{1,5})?$/; // Validates floats with up to 5 digits before and after decimal point

  if (typeof input === "number") {
    const inputString = input.toString();
    if (floatPattern.test(inputString)) {
      return true;
    }
  }
  return false;
};
