import React from "react";
import { useSelector } from "react-redux";

import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import CloseIcon from "@mui/icons-material/Close";

export default function SupportNumberModal(props) {
  const { open, close, cancelText } = props;

  const supportNumber = useSelector(
    (state) => state.supportNumbers?.transporterPortal
  );

  return (
    <Dialog open={open} maxWidth="500px">
      <DialogTitle
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <CloseIcon
          sx={{
            width: "20px",
            height: "20px",
            cursor: "pointer",
            position: "absolute",
            right: "20px",
            top: "20px",
          }}
          onClick={() => close(false)}
        />
      </DialogTitle>

      <DialogContent>
        <Box mt="20px">
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            gap="30px"
            marginBottom="20px"
            textAlign="center"
            maxWidth="450px"
          >
            <PhoneInTalkIcon sx={{ width: "50px", height: "50px" }} />
            <Typography fontSize={18}>
              {!cancelText
                ? "For any queries related to this trip, please call our support team."
                : "To cancel this trip please call our support team."}
            </Typography>
            <Typography fontSize={20}>
              Support number
              <span style={{ fontWeight: 700, paddingLeft: "8px" }}>
                {supportNumber}
              </span>
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
