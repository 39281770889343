import { client } from "../config";

// get all truck data.
export const getAllTruckData = async (
  transporterId,
  currentPage,
  pageSize,
  inputValue,
  statusFilter
) => {
  try {
    const response = await client.get(
      `transporter/${transporterId}/transporter-vehicles?page=${currentPage}&pageSize=${pageSize}&vehicleNumber=${inputValue}&vehicleStatus=${statusFilter}`
    );
    return response;
  } catch (err) {
    return err;
  }
};
export const getVehicleDetailsById = async (transporterId, vehicleId) => {
  return await client.get(
    `transporter/${transporterId}/transporter-vehicles/${vehicleId}`
  );
};

// Function to add a new truck data.
export const addNewTruck = async (transporterId, newTruckData) => {
  try {
    const response = await client.post(
      `transporter/${transporterId}/transporter-vehicles`,
      newTruckData
    );
    return response;
  } catch (error) {
    return error;
  }
};

// Function to get vehicle categories.
export const getVehicleCategories = async () =>
  await client
    .get(`inventory/vehicle-categories`)
    .then((response) => response)
    .catch((err) => err);

// Function to get vehicle model based on vehicle categories.
export const getVehicleModels = async (vehicleCategory) =>
  await client
    .get(`inventory/vehicle-categories/${vehicleCategory}/models`)
    .then((response) => response)
    .catch((err) => err);

// Function to edit/update a vehicle
export const editTruckData = async (
  transporterId,
  vehicleId,
  updatedVehicleData
) => {
  try {
    const response = await client.put(
      `transporter/${transporterId}/transporter-vehicles/${vehicleId}`,
      updatedVehicleData
    );
    return response;
  } catch (error) {
    return error;
  }
};

// Function to delete a selected vehicle
export const deleteSelectedTruck = async (transporterId, vehicleId) => {
  try {
    const response = await client.delete(
      `transporter/${transporterId}/transporter-vehicles/${vehicleId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

// Function to get all drivers
export const updateTruckAvailabilityStatus = async (
  transporterId,
  vehicleId,
  isAvailable
) => {
  try {
    const response = await client.patch(
      `transporter/${transporterId}/transporter-vehicles/${vehicleId}/availability-status`,
      { isAvailable }
    );
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};

// Function to get all drivers in a dropdown
export const getLinkedDriversForDropdown = async (transporterId) => {
  try {
    const response = await client.get(
      `transporter/${transporterId}/drivers-dropdown`
    );
    return response;
  } catch (error) {
    return error;
  }
};

//  Function to assign driver to vehicle
export const assignDriverToVehicle = async (
  transporterId,
  vehicleId,
  newDriverId,
  currentDriverId,
  currentVehicleId
) => {
  try {
    const response = await client.patch(
      `transporter/${transporterId}/transporter-vehicles/${vehicleId}/assign-driver`,
      { newDriverId, currentDriverId, currentVehicleId }
    );
    return response;
  } catch (error) {
    return error;
  }
};

// function to get single driver details
export const getSingleDriverDetails = async (transporterId, driverId) => {
  try {
    const response = await client.get(
      `transporter/${transporterId}/drivers/${driverId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateDriverReAssignment = async (
  transporterId,
  tripId,
  driverId
) => {
  try {
    const response = await client.patch(
      `transporter/trip/${transporterId}/${tripId}/change-driver`,
      { driverId }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getUpcomingTripsOfVehicle = async (transporterId, vehicleId) => {
  try {
    const response = await client.get(
      `transporter/${transporterId}/transporter-vehicles/${vehicleId}/upcoming-trips`
    );
    return response;
  } catch (error) {
    return error;
  }
};
