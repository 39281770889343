import axios from "axios";
import { BASE_URL } from "../../../api/config";
import Cookies from "js-cookie";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { useState } from "react";
import RejectDocumentModal from "./RejectDocumentModal";
import { useLocation } from "react-router-dom";
import KycDocumentImageViewer from "../../../component/Common/KycDocumentsImageViewer";

const AadhaarDocuments = ({ aadhaarDocuments, kycId }) => {
  const location = useLocation();
  const [aadhaarStatus, setAadhaarStatus] = useState(
    aadhaarDocuments?.approvalStatus
  );

  const [openRejectModal, setOpenRejectModal] = useState(false);
  //   states for open reject document modal
  const handleOpen = () => setOpenRejectModal(true);
  const handleClose = () => setOpenRejectModal(false);

  const approveAadhaarRequest = async () => {
    try {
      await axios
        .post(
          BASE_URL.concat("admin/kyc/approve"),
          {
            kycId,
            documentType: "aadhar",
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("admintoken")}`,
            },
          }
        )
        .then((data) => {
          setAadhaarStatus(data.data.data.kyc.aadharDetails.approvalStatus);
          window.location.reload();
        });
    } catch (err) {}
  };

  const rejectAadhaarRequest = async (reason) => {
    axios
      .post(
        BASE_URL.concat("admin/kyc/reject"),
        {
          kycId,
          documentType: "aadhar",
          resonOfRejection: reason,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("admintoken")}`,
          },
        }
      )
      .then((data) => {
        setAadhaarStatus(data.data.data.kyc.aadharDetails.approvalStatus);
        window.location.reload();
      })
      .catch((err) => {});
  };

  if (!aadhaarDocuments)
    return (
      <Typography
        sx={{
          color: "#333",
          fontSize: "1rem",
          marginTop: "4rem",
          marginLeft: "1rem",
        }}
      >
        No documents uploaded by user yet
      </Typography>
    );
  return (
    <Box p={2} mt={5}>
      {aadhaarDocuments?.documentNumber && (
        <>
          <Typography sx={{ color: "#333", fontSize: "1rem" }}>
            {" Aadhaar Details"}
          </Typography>
          <Typography sx={{ color: "#333", fontSize: "1rem", fontWeight: 700 }}>
            {aadhaarDocuments?.documentNumber}
          </Typography>
        </>
      )}
      <Stack direction="row" alignItems="center" spacing={2} mt={4}>
        <KycDocumentImageViewer imageUrl={aadhaarDocuments?.frontUrl} imageSide="aadhaar_front"/>
        <KycDocumentImageViewer imageUrl={aadhaarDocuments?.backUrl} imageSide="aadhaar_back"/>
      </Stack>

      <Stack
        width={600}
        direction="row"
        justifyContent="space-between"
        pr={0.5}
        mt={5}
      >
        {aadhaarStatus && aadhaarStatus === "APPROVED" ? (
          <>
            <span />
            <Button
              sx={{ color: "#0C7F44", textTransform: "none" }}
              endIcon={
                <img src="/assets/icons/green-tick.svg" alt="approved" />
              }
            >
              Approved
            </Button>
          </>
        ) : aadhaarStatus === "REJECTED" ? (
          <>
            <span />
            <Button
              sx={{ color: "#FF0707", textTransform: "none" }}
              endIcon={<img src="/assets/icons/red-cross.svg" alt="approved" />}
            >
              Rejected
            </Button>
          </>
        ) : (
          <>
            <Button variant="outlined" color="primary" onClick={handleOpen}>
              Reject
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={approveAadhaarRequest}
            >
              Approve
            </Button>
          </>
        )}
      </Stack>
      <RejectDocumentModal
        documentType="Aadhaar"
        openRejectModal={openRejectModal}
        handleOpen={handleOpen}
        handleClose={handleClose}
        handleRejectDocument={rejectAadhaarRequest}
      />
    </Box>
  );
};

export default AadhaarDocuments;
