import { adminApi } from "../config";

export const getAllDriverList = async (page, search, status) => {
  try {
    const response = adminApi.get(
      `admin/drivers?page=${page + 1}&search=${search}&status=${status}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getDocumentDetailsForKycId = async (kycId) => {
  try {
    const response = adminApi.get(`admin/kyc-details/${kycId}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getDriverById = async (id) => {
  try {
    const response = adminApi.get(`admin/drivers/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getDriverTrips = async (
  id,
  page,
  pageSize,
  tripStatus,
  search
) => {
  try {
    const queryParam = new URLSearchParams({
      page: page + 1,
      pageSize,
      tripStatus,
      search,
    });
    const response = adminApi.get(`admin/driver/trips/${id}?${queryParam}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateDriverStatus = async (driverId, status) => {
  try {
    const response = adminApi.patch(`admin/driver/${driverId}/status`, {
      status,
    });
    return response;
  } catch (error) {
    return error;
  }
};
