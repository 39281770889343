import { client } from "../config";

export const getAllTransporterTrips = async (
  transporterId,
  page,
  tripStatus,
  paymentStatus = [],
  searchText = ""
) => {
  try {
    const response = await client.get(
      `transporter/trip/${transporterId}?page=${
        page + 1
      }&tripStatus=${tripStatus.toString()}&transporterPaymentStatus=${paymentStatus.toString()}&search=${searchText}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getTransporterTripById = async (transporterId, tripId) => {
  try {
    const response = await client.get(
      `transporter/trip/${transporterId}/${tripId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};
