/**
 * This function takes in validityTime i.e time for which OTP is valid and formats it in readable form.
 * @param {integer} validityTime
 * @returns min : seconds
 */
export const formatCounterTime = (validityTime) => {
  const minutes = Math.floor(validityTime / 60);
  const remainingSeconds = validityTime % 60;
  const formattedSeconds =
    remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
  return `${minutes}:${formattedSeconds}`;
};
