import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  transporterAppState: "",
};

export const transporterAppStateSlice = createSlice({
  name: "transporterAppState",
  initialState,
  reducers: {
    setTransporterAppState: (state, action) => {
      state.transporterAppState = action.payload;
    },
  },
});

export const { setTransporterAppState } = transporterAppStateSlice.actions;

export default transporterAppStateSlice.reducer;
