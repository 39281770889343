import { createSlice } from "@reduxjs/toolkit";

const transporterProfileSlice = createSlice({
  name: "transporterProfile",
  initialState: {},
  reducers: {
    setTransporterProfile: (state, action) => {
      return action.payload;
    },
  },
});

export const { setTransporterProfile } = transporterProfileSlice.actions;
export default transporterProfileSlice.reducer;
