import React from "react";
import { Box, Typography } from "@mui/material";
import { CUSTOMER_ENQUIRY_STATUS } from "../../constants/constant";
import FindingPartnerTimer from "./FindingPartnerTimer";

export const EnquiryStatus = ({ enquiry, status }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case CUSTOMER_ENQUIRY_STATUS.OFFER_RECEIVED:
        return "#FF3F3F";
      case CUSTOMER_ENQUIRY_STATUS.FINDING_PARTNER:
        return "#6D6D6D";
      default:
        return "#B2B2B2";
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case CUSTOMER_ENQUIRY_STATUS.OFFER_RECEIVED:
        return "Offer Received";
      case CUSTOMER_ENQUIRY_STATUS.FINDING_PARTNER:
        return "Finding Partner";
      case CUSTOMER_ENQUIRY_STATUS.CONVERTED_TO_TRIP:
        return "Converted To Trip";
      case CUSTOMER_ENQUIRY_STATUS.ENQUIRY_CLOSED:
        return "Enquiry Closed";
      case CUSTOMER_ENQUIRY_STATUS.ENQUIRY_EXPIRED:
        return "Enquiry Expired";
      case CUSTOMER_ENQUIRY_STATUS.ENQUIRY_REJECTED:
        return "Enquiry Rejected";
      case CUSTOMER_ENQUIRY_STATUS.OFFER_EXPIRED:
        return "Offer Expired";
      default:
        return "";
    }
  };

  return (
    <>
      {status === CUSTOMER_ENQUIRY_STATUS.FINDING_PARTNER ? (
        <FindingPartnerTimer enquiry={enquiry} />
      ) : (
        <Box
          sx={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            display: "inline-block",
            paddingX: 1.5,
            paddingY: 0.5,
            borderRadius: "5px 0 0 5px",
          }}
        >
          <Typography sx={{ color: getStatusColor(status), fontWeight: 700 }}>
            {getStatus(status)}
          </Typography>
        </Box>
      )}
    </>
  );
};
