import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getDriversListOfTransporter } from "../../api/Admin/transporterResources";
import { StyledTableRow } from "../Common/StyledTableRow";
import { StyledTableCell } from "../../pages/Admin/Approval/StyledTableCell";
import useToast from "../../hooks/useToast";

const TransportersDriverAccordion = ({
  panelExpanded,
  updateExpandedPanel,
  id,
}) => {
  const createTable = (driver) => {
    return (
      <StyledTableRow key={driver.name}>
        <StyledTableCell>{driver.driverName || "-"}</StyledTableCell>
        <StyledTableCell>{driver.phone || "-"} </StyledTableCell>
      </StyledTableRow>
    );
  };
  const [driversList, setDriversList] = useState([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [paginationData, setPaginationData] = useState({});
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(search);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setPageSize(parseInt(event.target.value, 10));
  };

  const { handleOpenToast } = useToast();

  const fetchDriverList = async () => {
    try {
      const response = await getDriversListOfTransporter(
        id,
        page,
        pageSize,
        search
      );
      if (response.status === 200) {
        setDriversList(response.data.data.drivers);
        setPaginationData(response.data.data.pagination);
      }
    } catch (error) {
      handleOpenToast("Error while getting drivers list", "error");
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  useEffect(() => {
    fetchDriverList();
  }, [id, page, pageSize, debouncedSearch]);

  return (
    <Accordion
      disableGutters
      TransitionProps={{ unmountOnExit: true }}
      sx={{ borderRadius: ".35rem" }}
      expanded={panelExpanded === "drivers"}
      onChange={updateExpandedPanel("drivers")}
    >
      <AccordionSummary
        expandIcon={
          <img src="/assets/icons/expand_less.svg" alt="expand_less" />
        }
        aria-controls="drivers-content"
        sx={{
          background: "#EAEAEA",
          paddingLeft: "2rem",
          fontWeight: 700,

          "&.Mui-expanded": {
            paddingTop: 0,
          },
          "&.MuiAccordionSummary-root": {
            height: "36px !important",
            minHeight: "36px !important",
          },
        }}
      >
        {"Drivers"}
      </AccordionSummary>

      <AccordionDetails
        sx={{
          background: "#FFF",
          paddingLeft: "2rem",
          paddingTop: "1rem",
        }}
      >
        <TextField
          variant="outlined"
          placeholder="Search Driver Number"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          InputProps={{
            sx: { height: "36.31px" },
            endAdornment: (
              <img src="/assets/icons/search-icon.svg" alt="search" />
            ),
          }}
        />
        <TableContainer>
          <Table size="small" sx={{ width: 500 }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>Driver Name</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Mobile Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {driversList?.length !== 0 ? (
                driversList.map((driver) => createTable(driver))
              ) : (
                <TableRow>
                  <TableCell colSpan={2}>
                    The transporter has not yet added any drivers.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
      {driversList?.length > 0 && (
        <Box
          sx={{
            borderTop: "1px solid #adadad",
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={paginationData.totalItems}
            rowsPerPage={paginationData.pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ count }) => (
              <div>
                Page{" "}
                <span style={{ color: "green", fontWeight: 700 }}>
                  {page + 1}
                </span>{" "}
                of {Math.ceil(count / paginationData.pageSize)}
              </div>
            )}
          />
        </Box>
      )}
    </Accordion>
  );
};
export default TransportersDriverAccordion;
