import { Box, Typography } from "@mui/material";

export default function CancellationAndRefund() {
  return (
    <Box p="2rem 5rem">
      <Typography textAlign="center" fontSize={24} mb={1}>
        Cancellation & Refund Policy
      </Typography>
      <Box>
        <Typography fontWeight={700} fontSize={20} mb={2}>
          CANCELLATION POLICY
        </Typography>
        <Typography sx={{ textDecoration: "underline" }} fontSize={18} mb={3}>
          Cancellation by customer or person booking the trip:
        </Typography>
        <Typography>
          To ensure a smooth operation of our goods and vehicle services, we
          have implemented a policy where if a vehicle is assigned and begins
          its journey towards a pick-up location before the scheduled reporting
          time, and the service is cancelled at that point, a cancellation fee
          is incurred. This measure is in place to discourage cancellations
          after the vehicle has been allocated, helping to maintain an
          uninterrupted flow of goods and vehicles.
        </Typography>
        <Typography mt={1}>
          The cancellation fee will vary depending on how last minute the
          cancellation is being done till the time of reporting of the vehicle.
          It will not exceed more than 10 percent of agreed freight.
        </Typography>
        <Typography
          sx={{ textDecoration: "underline" }}
          fontSize={18}
          mb={3}
          mt={3}
        >
          Cancellation by transporter:
        </Typography>
        <Typography mb={1}>
          The primary goal of this platform is to enhance the overall experience
          for senders, receivers, and movers of goods. In order to prevent
          delays in transporting goods for senders, we implement a cancellation
          fee for movers or transporters who cancel a trip. This fee is designed
          to promote service assurance and timely delivery for the senders of
          goods, emphasizing our commitment to reliable and efficient service.
        </Typography>
        <Typography>
          The cancellation fee will vary depending on the time left for the
          vehicle to report to pick-up location and the vehicle type. Last
          minute cancellations may invite higher cancellation fees not exceeding
          more than 15 percent of agreed freight.
        </Typography>

        {/* Refund Policy */}
        <Typography fontWeight={700} fontSize={20} mb={2} mt={2}>
          REFUND POLICY
        </Typography>
        <Typography component="div" pl={4}>
          <ul>
            <li>
              If for some reason we are unable to service your requirement then
              in that case it will be our endeavour to quickly refund any
              booking amount you may have paid us.
            </li>
            <li>
              he refund will be processed within 5 to 7 working days of an
              approved refund request to the same account from which we had
              received it.
            </li>
          </ul>
        </Typography>
        <Typography mt={3}>
          For any queries related to refund the amount of cancelled booking,
          please contact us on Email at{" "}
          <a href="mailto:loadeazy.tech@gmail.com" style={{ fontWeight:700 }}>loadeazy.tech@gmail.com</a>
        </Typography>
        <Typography mt={2}>
          Further, considering the same reasons, you agree and acknowledge that
          you shall not raise any dispute with payment repository/payment
          service providers for refunding the payment. In case if you raise any
          such disputes then you agree, acknowledge and allow us to instruct
          payment repository/payment service providers to credit payment in our
          account as per this clause.
        </Typography>
      </Box>
    </Box>
  );
}
