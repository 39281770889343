import {
  Avatar,
  Box,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import StateCityDropdown from "../../../component/Common/StatesAndCitiesDropdown";
import Cookies from "js-cookie";
import { uploadimage } from "../../../services/uploadImageToS3";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import { setTransporterName } from "../../../redux/slices/transporterNameSlice";
import { setTransporterProfile } from "../../../redux/slices/transporterProfileSlice";
import {
  getTransporterProfile,
  updateTransporterProfile,
  updateTransporterProfileOnFTUE,
} from "../../../api/Transporter/profile";
import { logoutTransporter } from "../../../api/logout";
import useToast from "../../../hooks/useToast";

const initialState = {
  name: "",
  phoneNumber: "",
  email: "",
  businessName: "",
  address: "",
  city: "",
  state: "",
  addressLine1: "",
  addressLine2: "",
};

const Profile = () => {
  const dispatch = useDispatch();
  const { toastData, handleOpenToast, handleCloseToast, ToastMessage } =
    useToast();

  const ftueStatus = localStorage.getItem("ftueStatus");
  const [formData, setFormData] = useState(initialState);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [errors, setErrors] = useState({});
  const [image, setImage] = useState(null);

  const transporterProfile = useSelector((state) => state.transporterProfile);

  const fetchTransporterProfile = async () => {
    let transporter = "";
    if (JSON.stringify(transporterProfile) !== "{}") {
      transporter = transporterProfile;
    } else {
      const response = await getTransporterProfile();
      transporter = response?.data?.transporter;
    }
    const name = transporter?.fullName;
    const email = transporter?.email;
    const businessName = transporter?.businessName;
    const businessAddress = transporter?.businessAddress;
    const addressLine1 = businessAddress?.addressLine1;
    const addressLine2 = businessAddress?.addressLine2;
    const city = businessAddress?.city;
    const state = businessAddress?.state;
    const phoneNumber = transporter?.phone;

    setFormData((prev) => ({
      ...prev,
      name,
      email,
      phoneNumber,
      businessName,
      addressLine1,
      addressLine2,
      city,
      state,
    }));
    setSelectedCity(city);
    setSelectedState(state);
    setImage(transporter?.profilePhoto);
  };
  useEffect(() => {
    if (ftueStatus !== "PENDING") {
      fetchTransporterProfile();
    } else {
      setFormData((prev) => ({
        ...prev,
        phoneNumber: transporterProfile?.phone,
      }));
    }
  }, []);

  const handleChange = (field) => (event) => {
    let fieldValue = event.target.value;
    if (field === "name" && fieldValue) {
      const ALPHA_REGEX = /^[a-zA-Z\s]+$/;
      fieldValue = ALPHA_REGEX.test(fieldValue) ? fieldValue : formData[field];
    }
    setFormData({ ...formData, [field]: fieldValue });
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const docImage = await uploadimage(
      `public/Transporter/Profile/ProfilePhoto`,
      file
    );
    setImage(docImage);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {};
      reader.readAsDataURL(file);
    }
  };

  const validateUserData = (userData) => {
    let newErrors = {};
    const { fullName, email, businessName, businessAddress } = userData;

    if (!validator.isLength(fullName, { min: 4 })) {
      newErrors.name = "Name must be at least 4 characters";
    }

    if (!validator.isLength(businessName, { min: 1 })) {
      newErrors.businessName = "This is a required field.";
    }

    if (!validator.isLength(businessAddress?.addressLine1 || "", { min: 1 })) {
      newErrors.businessLine1 = "This is a required field.";
    }

    if (!validator.isLength(businessAddress?.state || "", { min: 1 })) {
      newErrors.state = "Please select state";
    }

    if (!validator.isLength(businessAddress?.city || "", { min: 1 })) {
      newErrors.city = "Please select city";
    }

    setErrors(newErrors);

    // If there are no errors, the form is valid
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = async () => {
    // collecting user profile data to submit on server.
    const userProfileData = {
      fullName: formData.name,

      businessName: formData.businessName,
      businessAddress: {
        addressLine1: formData.addressLine1,
        addressLine2: formData.addressLine2,
        state: selectedState,
        city: selectedCity,
      },
    };

    // Only include email if it's provided
    if (formData.email) {
      userProfileData.email = formData.email;
    }
    if (validateUserData(userProfileData)) {
      try {
        await updateTransporterProfileOnFTUE(userProfileData).then(
          (response) => {
            dispatch(setTransporterName(response.data.transporter.fullName));
            dispatch(setTransporterProfile(response.data.transporter));
          }
        );

        localStorage.setItem("ftueStatus", "IN_PROGRESS");
        navigate("/transporter/accounts/cities&routes");
      } catch (err) {
        handleOpenToast("Something went wrong.", "error");
      }
    }
  };

  const handleSave = async () => {
    const userProfileData = {
      fullName: formData.name,
      businessName: formData.businessName,
      businessAddress: {
        addressLine1: formData.addressLine1,
        addressLine2: formData.addressLine2,
        state: selectedState,
        city: selectedCity,
      },
      profilePhoto: image,
    };
    // Only include email if it's provided
    if (formData.email) {
      userProfileData.email = formData.email;
    }
    if (validateUserData(userProfileData)) {
      try {
        await updateTransporterProfile(userProfileData).then((response) => {
          dispatch(setTransporterName(response.data.transporter.fullName));
          handleOpenToast("Profile Updated", "success");
          dispatch(setTransporterProfile(response.data.transporter));
        });
      } catch (err) {
        handleOpenToast("Something went wrong.", "error");
      }
    }
  };

  const handleLogoutButtonClick = async () => {
    await logoutTransporter()
      .then((response) => {
        const allCookies = Cookies.get();
        Object.keys(allCookies).forEach((cookieName) => {
          Cookies.remove(cookieName);
        });
        navigate("/");
      })
      .catch((err) => {
        handleOpenToast("could not log out!", "error");
      });
  };

  const navigate = useNavigate();

  return (
    <Stack
      direction="column"
      sx={{
        paddingLeft: { sm: "50px", xl: "100px" },
        paddingRight: { sm: "50px", lg: "108px" },
        paddingTop: "2rem",
        paddingBottom: "2rem",
      }}
    >
      <Stack
        direction="row"
        spacing={5}
        maxWidth="lg"
        sx={{ paddingRight: { lg: "80px" } }}
      >
        <UserAvatar image={image} handleImageChange={handleImageChange} />
        <form noValidate autoComplete="off" style={{ flexGrow: 1 }}>
          <Grid container spacing={1.5} pt={2} maxWidth="xl">
            <Grid item xs={12}>
              <InputLabel
                htmlFor="name-input"
                sx={{ color: "#333", fontSize: ".875rem" }}
              >
                Full Name <span style={{ color: "red" }}> *</span>
              </InputLabel>

              <TextField
                variant="outlined"
                value={formData.name}
                onChange={handleChange("name")}
                placeholder="Enter transporter's full name"
                fullWidth
                error={errors.name}
                helperText={errors.name}
                InputProps={{
                  style: { height: "52px" },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <InputLabel
                htmlFor="name-input"
                sx={{ color: "#333", fontSize: ".875rem" }}
              >
                Email Address
              </InputLabel>
              <TextField
                placeholder="Enter email address"
                value={formData.email}
                onChange={handleChange("email")}
                fullWidth
                error={errors.email}
                helperText={errors.email}
                InputProps={{
                  style: { height: "52px" },
                }}
              />
            </Grid>
            <Grid item xs={6} ml="auto">
              <InputLabel
                htmlFor="number-input"
                sx={{ color: "#333", fontSize: ".875rem" }}
              >
                Contact Number
              </InputLabel>
              <TextField
                placeholder="Phone Number"
                value={formData?.phoneNumber}
                disabled
                fullWidth
                InputProps={{
                  style: { height: "52px" },
                }}
              />
            </Grid>
            <Grid item xs={12} mb={5}>
              <InputLabel
                htmlFor="number-input"
                sx={{ color: "#333", fontSize: ".875rem" }}
              >
                Business Name<span style={{ color: "red" }}> *</span>
              </InputLabel>
              <TextField
                placeholder="Enter your business name"
                value={formData.businessName}
                onChange={handleChange("businessName")}
                error={errors.businessName}
                helperText={errors.businessName}
                fullWidth
                InputProps={{
                  style: { height: "52px" },
                }}
              />
            </Grid>

            <Typography ml={1.5}>Business Address</Typography>
            <Grid item xs={12}>
              <InputLabel
                htmlFor="number-input"
                sx={{ color: "#333", fontSize: ".875rem" }}
              >
                Address Line 1<span style={{ color: "red" }}> *</span>
              </InputLabel>
              <TextField
                placeholder="Enter your business address line 1"
                value={formData.addressLine1}
                onChange={handleChange("addressLine1")}
                error={errors.businessLine1}
                helperText={errors.businessLine1}
                fullWidth
                InputProps={{
                  style: { height: "52px" },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                htmlFor="number-input"
                sx={{ color: "#333", fontSize: ".875rem" }}
              >
                Address Line 2 <span> (optional)</span>
              </InputLabel>
              <TextField
                placeholder="Enter your business address line 2"
                value={formData.addressLine2}
                onChange={handleChange("addressLine2")}
                fullWidth
                error={errors.businessLine2}
                helperText={errors.businessLine2}
                InputProps={{
                  style: { height: "52px" },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <StateCityDropdown
                selectedState={selectedState}
                setSelectedState={setSelectedState}
                setSelectedCity={setSelectedCity}
                selectedCity={selectedCity}
                handleChange={handleChange}
                isCitySelected={errors.city}
                isStateSelected={errors.state}
              />
            </Grid>
          </Grid>
        </form>
      </Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "22px",
          marginTop: "2rem",
        }}
      >
        {ftueStatus === "DONE" ? (
          <Button
            sx={{
              color: "red",
              textDecoration: "underline",
              textTransform: "none",
            }}
            onClick={handleLogoutButtonClick}
          >
            Logout
          </Button>
        ) : (
          <p></p>
        )}
        {ftueStatus !== "DONE" ? (
          <Button variant="contained" color="primary" onClick={handleNext}>
            Next
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        )}
      </Box>
      <ToastMessage
        open={toastData.open}
        message={toastData.message}
        severity={toastData.severity}
        handleClose={handleCloseToast}
      />
    </Stack>
  );
};
export default Profile;

const UserAvatar = ({ image, handleImageChange }) => {
  const inputRef = useRef();
  const handleEditImageClick = () => {
    // Trigger the input file selection when "Edit Image" is clicked
    inputRef.current.click();
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleImageChange}
      />

      <Box onClick={handleEditImageClick}>
        <Avatar
          alt="User Avatar"
          src={image}
          sx={{ width: 120, height: 120, marginTop: "1rem" }}
        />
        <IconButton
          aria-label="edit image"
          sx={{ fontSize: ".75rem", color: "#333" }}
        >
          Edit Photo
        </IconButton>
      </Box>
    </Box>
  );
};
