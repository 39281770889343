import React from "react";
import { Box, Button } from "@mui/material";

const DetailBox = ({ detail }) => (
  <Box
    sx={{
      minHeight: "73px",
      minWidth: "100px",
      padding: "8px 16px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "4px",
      background: "#EAEAEA",
      fontSize: "14px",
      fontWeight: 700,
      color: "#333",
    }}
  >
    {detail}
  </Box>
);

const DeleteMaterialCategoryModal = ({
  closeDeleteModal,
  handleDeleteMaterialTypeData,
  materialData,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Box
        sx={{
          bgcolor: "white",
          p: 2,
          borderRadius: 4,
          width: "600px",
          height: "300px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Box sx={{ fontSize: "24px", fontWeight: "700" }}>
            Delete Material Type
          </Box>
          <img
            src="/assets/icons/cross-icon.svg"
            alt="cross"
            style={{ cursor: "pointer" }}
            onClick={closeDeleteModal}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Box sx={{ display: "flex", gap: "20px" }}>
            <DetailBox detail={materialData.industry} />
            <DetailBox detail={materialData.materialCategory} />
          </Box>

          <Box
            sx={{
              color: "#FF3F3F",
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            Are you sure you want to delete this material type?
          </Box>

          <Box sx={{ display: "flex", gap: "20px" }}>
            <Button
              sx={{
                background: "#FF3F3F",
                color: "white",
                "&:hover": {
                  background: "#FF3F3F",
                },
                textTransform: "none",
              }}
              onClick={handleDeleteMaterialTypeData}
            >
              Confirm Delete
            </Button>
            <Button
              sx={{
                background: "#FFF",
                border: "1px solid #333",
                color: "#333",
                textTransform: "none",
              }}
              onClick={closeDeleteModal}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteMaterialCategoryModal;
