import { adminApi } from "../config";

export const getAllMaterialCategories = async (
  currentPage = "1",
  pageSize = "10",
  industry = []
) => {
  try {
    const queryParam = new URLSearchParams({
      page: currentPage,
      limit: pageSize,
      industry,
    });

    const response = await adminApi.get(
      `inventory/material-category?${queryParam}`
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const createMaterialCategory = async (industry, category, vehicle) => {
  try {
    const response = await adminApi.post(`inventory/material-category`, {
      industry,
      category,
      vehicle,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getMaterialCategoryById = async (materialCategoryId) => {
  try {
    const response = await adminApi.get(
      `inventory/material-category/${materialCategoryId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateMaterialCategory = async (
  materialCategoryId,
  industry,
  category,
  vehicle
) => {
  try {
    const response = await adminApi.put(
      `inventory/material-category/${materialCategoryId}`,
      { industry, category, vehicle }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteMaterialCategory = async (materialCategoryId) => {
  try {
    const response = await adminApi.delete(
      `inventory/material-category/${materialCategoryId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};
