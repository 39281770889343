import React from "react";
import { Link } from "react-router-dom";

import { Box, Button, Skeleton, Typography } from "@mui/material";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import DashboardEnquiryCard from "./DashboardEnquiryCard";
import styled from "@emotion/styled";
import { LOADING_STATUS } from "../../../constants/constant";

const StyledBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "2px",
}));

export default function DashboardEnquiries(props) {
  const { enquiries, entityType, getEnquiries, fetchStatus } = props;

  return (
    <Box
      height="180px"
      width="calc(100% - 320px)"
      borderRadius="5px"
      bgcolor={
        entityType === "enquiry" && enquiries.length > 0 ? "#E9F2F4" : "#fff"
      }
      p={1}
      overflow="auto"
      border="2px solid #ececec"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
          <Box fontSize={18} fontWeight={700}>
            Enquiries
          </Box>
          <Box
            fontSize={12}
            fontWeight={700}
            color="#333"
            component={Link}
            to="/transporter/enquiries"
          >
            List View
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" gap="5px">
          <StyledBox>
            <Brightness1Icon
              sx={{ fill: "#7DC526", width: "10px", height: "10px" }}
            />
            <Typography fontSize={12} fontWeight={400}>
              UnQuoted
            </Typography>
          </StyledBox>

          <StyledBox>
            <Brightness1Icon
              sx={{ fill: "#F09116", width: "10px", height: "10px" }}
            />
            <Typography fontSize={12} fontWeight={400}>
              Quoted
            </Typography>
          </StyledBox>

          <StyledBox>
            <BookmarkIcon
              sx={{ fill: "#0C7F44", width: "10px", height: "10px" }}
            />
            <Typography fontSize={12} fontWeight={400}>
              You Quoted
            </Typography>
          </StyledBox>
        </Box>

        <Box
          fontSize={14}
          fontWeight={700}
          pr={4}
          color="#333"
          component={Link}
          to="/transporter/accounts/cities&routes"
        >
          Modify Routes
        </Box>
      </Box>
      {fetchStatus === LOADING_STATUS.FAILED && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={4}
        >
          <Typography variant="body1" paragraph>
            Failed to load trips...
          </Typography>
          <Button variant="contained" color="primary" onClick={getEnquiries}>
            Retry
          </Button>
        </Box>
      )}

      {fetchStatus === LOADING_STATUS.LOADING && (
        <Box p={2}>
          <Box display="flex" gap={1}>
            <Skeleton variant="rounded" width={100} height={30} />
            <Skeleton variant="rounded" width={100} height={30} />
            <Skeleton variant="rounded" width={100} height={30} />
            <Skeleton variant="rounded" width={100} height={30} />
          </Box>
          <Box width={425} pt={1}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        </Box>
      )}
      {fetchStatus === LOADING_STATUS.LOADED && (
        <Box>
          {enquiries?.length > 0 ? (
            <Box
              display="flex"
              flexDirection="row"
              gap="6px"
              flexWrap="wrap"
              width="100%"
            >
              {enquiries.map((enquiry, index) => {
                return (
                  <DashboardEnquiryCard
                    {...props}
                    enquiry={enquiry}
                    key={index}
                    getEnquiries={getEnquiries}
                  />
                );
              })}
            </Box>
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              pt="40px"
              fontWeight="700"
            >
              No enquiries are available for display at the moment.
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
