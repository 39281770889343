import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PlacesAutocomplete } from "../../component/Common/PlacesAutoComplete";
import { useEffect, useState } from "react";
import { useMapsService } from "../../hooks/useMapsService";
import { LOADING_STATUS } from "../../constants/constant";
import { createEnquiry } from "../../api/Customer/enquiry";
import useToast from "../../hooks/useToast";
export const CheckFareAndBook = () => {
  const {ToastMessage, handleOpenToast} = useToast();
  const [navbarHeight, setNavbarHeight] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      const navbarElement = document.getElementById("landing-page-navbar");
      if (navbarElement) {
        const height = navbarElement.getBoundingClientRect().height;
        setNavbarHeight(height);
      }
    };

    handleResize(); // Initial calculation
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const navigate = useNavigate();
  const { isLoaded, getLatLng, calculateDistance } = useMapsService();
  const [errField, setErrorField] = useState({
    pickup: false,
    dropoff: false,
  });

  const [pickUpLocation, setPickupLocation] = useState({
    place: "",
    placeId: "",
  });
  const [dropOffLocation, setDropOffLocation] = useState({
    place: "",
    placeId: "",
  });

  const [btnStatus, setBtnStatus] = useState(LOADING_STATUS.INITIAL);

  const handleCreateEnquiry = async (
    pickupLocationDetails,
    dropoffLocationDetails,
    distance
  ) => {
    const pickup = {
      location: {...pickupLocationDetails,address:pickUpLocation.place},
    };
    const dropoff = {
      location: {...dropoffLocationDetails,address:dropOffLocation.place},
    };

    const enquiryBody = {
      pickup,
      dropoff,
      distance: parseInt(distance),
    };
    try {
      const response = await createEnquiry(enquiryBody);
      const enquiryId = response.data.data.enquiryId;
      return enquiryId;
    } catch (err) {
      return;
    }
  };

  const handleCheckFareAndBooktButtonClick = async () => {
    if (isLoaded) {
      // if no source and destination is selected
      if (!pickUpLocation.place || !dropOffLocation.place) return;
      if (pickUpLocation.placeId === dropOffLocation.placeId) {
        handleOpenToast('Pickup and Dropoff location cannot be same.', 'error')
        return
      }
      setBtnStatus(LOADING_STATUS.LOADING);
      try {
        const [pickupLocationDetails, dropoffLocationDetails, distance] =
          await Promise.all([
            getLatLng(pickUpLocation.placeId), // returns pickup locations lat, long, city and address
            getLatLng(dropOffLocation.placeId), // returns dropoff locations lat, long, city and address
            calculateDistance(pickUpLocation.place, dropOffLocation.place), // calcutes distance bt. origin and destn.
          ]);

        try {
          const enqId = await handleCreateEnquiry(
            pickupLocationDetails,
            dropoffLocationDetails,
            distance
          );
          setBtnStatus(LOADING_STATUS.LOADED);
          navigate("/enquiry", {
            state: {
              detail: {
                source: pickUpLocation.place,
                destn: dropOffLocation.place,
                distance: distance,
                enquiryId: enqId,
              },
            },
          });
        } catch (error) {
          setBtnStatus(LOADING_STATUS.FAILED);
        }
      } catch (error) {
        if (error.message.split(" ")[2] === pickUpLocation.placeId) {
          setErrorField((p) => ({ ...p, pickup: true }));
        } else if (error.message.split(" ")[2] === dropOffLocation.placeId) {
          setErrorField((p) => ({ ...p, dropoff: true }));
        } else {
          console.error(error);
        }
        setBtnStatus(LOADING_STATUS.FAILED);
      }
    }
  };
  return (
   <><ToastMessage />
    <Box sx={{background:"#404750"}}>
      <Box
        style={{
          backgroundImage: `url('assets/images/bgImage.jpg')`,
          backgroundSize: "cover",
          backgroundPosition: 'center',
          height:`calc(100vh - ${navbarHeight}px - 40px)`,
         
        }}
      >
        <Box
          maxWidth="xl"
          sx={{ margin: "auto" }}
          px={{
            sm: 6,
            md: 8,
            lg: 12,
            xl: 16,
          }}
          pt={3}
          pb={2}
          display="flex"
          justifyContent="space-between"
        >
          <Stack direction="column">
            <Typography
              mt={4}
              color="#FFF"
              sx={{ fontSize: "3rem", fontWeight: "bold" }}
            >
              India's First Steel Logistics Solution
            </Typography>
            <Typography color="#FFF" fontSize={20}>
              For effortless movement of goods using simple technology.
            </Typography>
            <Box
              style={{ background: "#FFF", width: "22rem" }}
              mt={4}
              pt={3}
              borderRadius={1}
              p={2.5}
              pb={3}
            >
              <Typography mb={1} fontWeight="bold">
                Book Your Truck
              </Typography>
              <Stack direction="column" spacing={1.25}>
                <PlacesAutocomplete
                  error={errField.pickup}
                  helperText={
                    errField.pickup && "Please pick more specific location"
                  }
                  resetError={() =>
                    setErrorField((p) => ({ ...p, pickup: false }))
                  }
                  placeholderText="Enter pick up location"
                  onPlaceSelect={(place, placeId) =>
                    setPickupLocation({ place, placeId })
                  }
                />

                <PlacesAutocomplete
                  error={errField.dropoff}
                  helperText={
                    errField.dropoff && "Please pick more specific location"
                  }
                  resetError={() =>
                    setErrorField((p) => ({ ...p, dropoff: false }))
                  }
                  placeholderText="Enter drop off location"
                  onPlaceSelect={(place, placeId) => {
                    setDropOffLocation({ place, placeId });
                  }}
                />

                <Button
                  variant="contained"
                  color="primary"
                  disabled={btnStatus === LOADING_STATUS.LOADING}
                  sx={{
                    fontWeight: 700,
                    fontSize: "1.25rem",
                    paddingBlock: ".625rem",
                  }}
                  onClick={handleCheckFareAndBooktButtonClick}
                >
                  CHECK FARE & BOOK
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Box>
       
      </Box>
      
    </Box>
    <Box sx={{ background: "#414A50", textAlign: "center",marginTop:'auto' }}>
    <Typography color="#FFF" fontSize="1rem" fontWeight="700" p={1}>
      Our Service Locations : Bengaluru. Coming Soon To Rest Of India.
    </Typography>
  </Box></>
  );
};
