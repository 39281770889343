import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/system";
import {
  TextField,
  Box,
  CircularProgress,
  Stack,
  Button,
  MenuItem,
  Typography,
} from "@mui/material";
import { createSupportTicket } from "../../../api/Admin/support";
import useToast from "../../../hooks/useToast";
const CustomInputLabel = styled(InputLabel)({
  color: "#333", // Change this to the desired color
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "bold",
  lineHeight: "24px",
  // border:"1px solid red"
});
const CreateSupportTicketPage = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ background: "#EAEAEA" }}
        p=".5rem 1.5rem"
      >
        <img
          alt="back"
          src="/assets/icons/backArrow.svg"
          style={{ cursor: "pointer", width: "24px", height: "20px" }}
          onClick={() => navigate(-1)}
        />
        <Typography color="#333" fontWeight={700} fontSize="1.25rem">
          Create a Ticket
        </Typography>
        <Typography></Typography>
      </Stack>
      <Box>
        <SupportTicketForm />
      </Box>
    </Box>
  );
};

export default CreateSupportTicketPage;

const stakeholders = ["Driver", "Customer", "Transporter"];
const priorities = ["High", "Low", "Medium"];
const issues = [
  "Issue number 1",
  "Issue number 1.1",
  "Issue number 2",
  "Issue number 3",
  "Issue number 4",
];

const SupportTicketForm = () => {
  const initialFormState = {
    stakeholder: "",
    mobileNumber: "",
    natureOfIssue: "",
    stakeholderName: "",
    issueDescription: "",
    priority: "",
    status: "",
    // tripId: "", removing this field sorted 500 error ,
  };
  const { toastData, handleOpenToast, handleCloseToast, ToastMessage } =
    useToast();

  const [formValues, setFormValues] = useState(initialFormState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createSupportTicket(formValues);
      if (response.status === 201) {
        handleOpenToast(response.data.message, "success");
        setFormValues(initialFormState);
      } else {
        handleOpenToast("Something went wrong", "error");
      }
    } catch (error) {
      if (error.response) {
        handleOpenToast(error.response.statusText, "error");
      } else if (error.request) {
        handleOpenToast(error.request.data.error, "error");
      } else {
        handleOpenToast(error.message, "error");
      }
    }
    // console.log("Form submitted:", formValues);
  };

  return (
    <Box p="2rem 2.5rem" maxWidth="lg" m="auto">
      <ToastMessage
        open={toastData.open}
        message={toastData.message}
        severity={toastData.severity}
        handleClose={handleCloseToast}
      />
      <form onSubmit={handleSubmit}>
        <Stack direction="row" alignItems="center" gap={3} flexWrap="wrap">
          <Box flex={1}>
            <CustomInputLabel>Stakeholder Name *</CustomInputLabel>
            <TextField
              name="stakeholderName"
              autoComplete="off"
              value={formValues.stakeholderName}
              required
              onChange={handleChange}
              fullWidth
              placeholder="Enter Name"
              InputProps={{ sx: { height: "2.75rem" } }}
            />
          </Box>
          <Box flex={1}>
            <CustomInputLabel>Stakeholder *</CustomInputLabel>
            <TextField
              select
              required
              name="stakeholder"
              value={formValues.stakeholder}
              onChange={handleChange}
              fullWidth
              InputProps={{
                sx: {
                  height: "2.75rem",
                },
              }}
            >
              <MenuItem value="Select" disabled>
                Select Stakeholder
              </MenuItem>
              {stakeholders.map((stakeholder, i) => (
                <MenuItem key={stakeholder} value={stakeholder}>
                  {stakeholder}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          gap={3}
          mt={2}
          flexWrap="wrap"
        >
          <Box flex={1}>
            <CustomInputLabel>Mobile No *</CustomInputLabel>
            <TextField
              name="mobileNumber"
              autoComplete="off"
              value={formValues.mobileNumber}
              required
              onChange={handleChange}
              placeholder="Enter Mobile No."
              fullWidth
              InputProps={{ sx: { height: "2.75rem" } }}
            />
          </Box>
          <Box flex={1}>
            <CustomInputLabel>Trip Id</CustomInputLabel>
            <TextField
              placeholder="Enter Trip Id (optional)"
              name="tripId"
              value={formValues.tripId}
              fullWidth
              InputProps={{ sx: { height: "2.75rem" } }}
              onChange={handleChange}
            />
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" gap={3} mt={2} mb={2}>
          <Box flex={4}>
            <CustomInputLabel>Nature of Issue *</CustomInputLabel>
            <TextField
              select
              required
              placeholder="demo"
              value={formValues.natureOfIssue}
              onChange={handleChange}
              name="natureOfIssue"
              fullWidth
              InputProps={{
                sx: {
                  height: "2.75rem",
                },
              }}
            >
              <MenuItem value="" disabled>
                Choose Nature of Issue
              </MenuItem>
              {issues.map((stakeholder) => (
                <MenuItem key={stakeholder} value={stakeholder}>
                  {stakeholder}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box flex={1}>
            <CustomInputLabel>Priority *</CustomInputLabel>
            <TextField
              select
              required
              fullWidth
              value={formValues.priority}
              name="priority"
              onChange={handleChange}
              InputProps={{
                sx: {
                  height: "2.75rem",
                },
              }}
            >
              {priorities.map((stakeholder) => (
                <MenuItem key={stakeholder} value={stakeholder}>
                  {stakeholder}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Stack>
        <Box>
          <CustomInputLabel>Description</CustomInputLabel>
          <TextField
            placeholder="Enter Description"
            fullWidth
            name="issueDescription"
            value={formValues.issueDescription}
            label=""
            multiline
            rows={4}
            onChange={handleChange}
            required
          />
        </Box>

        <Stack direction="row" justifyContent="flex-end" mt={3}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ width: "140px" }}
          >
            Create
          </Button>
        </Stack>
      </form>
    </Box>
  );
};
