import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { css } from '@emotion/react';

export const StyledTextSecondary = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  color: #333333;
  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}
`;
