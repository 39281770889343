import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setNotificationPreference } from "../../redux/slices/customerSlice";
import { updateCustomerNotificationPreferences } from "../../api/Customer/notification";
import { customerPreferenceOrderTexts } from "../../constants/util";

const CustomerNotificationPreference = () => {
  const customerNotificationPreferences = useSelector(
    (state) => state.customer?.notificationPreferences
  );

  const dispatch = useDispatch();
  const [notificationDeatils, setNotificationDeatils] = useState([]);

  useEffect(() => {
    if (customerNotificationPreferences) {
      const transformedPreferences = customerPreferenceOrderTexts.map(
        (key) => ({
          text: getTextRepresentation(key),
          status: customerNotificationPreferences[key],
        })
      );
      setNotificationDeatils(transformedPreferences);
    }
  }, [customerNotificationPreferences]);

  const getTextRepresentation = (key) => {
    const textMappings = {
      enquiry: "Enquiry received notification",
      trip: "Trip notification",
      payment: "Payment received notification",
      kyc: "KYC verification notification",
    };
    return textMappings[key] || "";
  };

  const updateNotificationPreference = async (text, checked) => {
    const textMappings = {
      "KYC verification notification": "kyc",
      "Enquiry received notification": "enquiry",
      "Payment received notification": "payment",
      "Trip notification": "trip",
    };
    const updatedPreferences = {
      ...customerNotificationPreferences,
      [textMappings[text]]: checked,
    };
    try {
      const requestBody = { notifications: updatedPreferences };
      const response = await updateCustomerNotificationPreferences(requestBody);
      dispatch(
        setNotificationPreference(
          response?.data?.customer?.notificationPreferences
        )
      );
    } catch (error) {
      console.error("Error updating notification preferences:", error);
    }
  };

  return (
    <Box p={3}>
      <FormControl
        component="fieldset"
        variant="standard"
        sx={{ width: "90%" }}
      >
        <FormGroup sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {notificationDeatils.map((preference, index) => (
            <>
              <FormControlLabel
                labelPlacement="start"
                key={index}
                control={
                  <Switch
                    sx={{ marginLeft: "auto" }}
                    color="success"
                    checked={preference.status}
                    onChange={() =>
                      updateNotificationPreference(
                        preference.text,
                        !preference.status
                      )
                    }
                    name={preference.text}
                  />
                }
                label={preference.text}
              />
              <Divider />
            </>
          ))}
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default CustomerNotificationPreference;
