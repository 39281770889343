import { client } from "../config";

// Function to get all trips for a vehicle
export const getTruckTrips = async (transporterId, vehicleId) => {
  try {
    const response = await client.get(
      `transporter/trip/${transporterId}/transporter-vehicle/${vehicleId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

// Function to get all trips for a driver
export const getDriverTrips = async (transporterId, driverId) => {
  try {
    const response = await client.get(
      `transporter/trip/${transporterId}/driver/${driverId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};
