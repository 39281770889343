import React, { useState } from "react";
import { Box, Radio, Typography, Button } from "@mui/material";
import { State } from "country-state-city";
import { createOrder } from "../../api/Customer/payment";
import {
  createTrip,
  updateWhatsAppContactPersonDetails,
} from "../../api/Customer/enquiry";
import {
  CONFIRM_BOOKING_DIALOG_STEPS,
  PAYMENT_METHOD_CONSTANTS,
} from "../../constants/constant";

function WhatsAppAcceptOfferPayment({
  enquiryDetails,
  pickupAddress,
  dropoffAddress,
  handleOpenToast,
  setStep,
  contactPersonDetails,
}) {
  const [advancePaymentMethod, setAdvancePaymentMethod] = useState(
    PAYMENT_METHOD_CONSTANTS.FULL
  );

  const handleAdvancePaymentChange = (event) => {
    setAdvancePaymentMethod(event.target.value);
  };

  const { offers } = enquiryDetails;

  const getOfferedCost = () => {
    if (offers?.bestOffer?.totalCost) {
      return offers.bestOffer.totalCost;
    }
    if (offers?.bestMatch?.totalCost) {
      return offers.bestMatch.totalCost;
    }
    return 0;
  };

  const getBidId = () => {
    if (offers?.bestOffer?.bidId) {
      return offers.bestOffer.bidId;
    }
    if (offers?.bestMatch?.bidId) {
      return offers.bestMatch.bidId;
    }
    return null;
  };

  const getStateName = (isocode) => {
    const stateName = State.getStateByCodeAndCountry(isocode, "IN").name;
    return stateName ? stateName : "";
  };

  const getRazorPayAmount = () => {
    if (advancePaymentMethod === PAYMENT_METHOD_CONSTANTS.FULL) {
      return Math.ceil(getOfferedCost());
    } else {
      return Math.ceil(getOfferedCost() / 2);
    }
  };

  const handleCreateTrip = async (paymentId) => {
    if (
      !enquiryDetails ||
      !pickupAddress ||
      !dropoffAddress ||
      !getBidId() ||
      !paymentId ||
      !contactPersonDetails
    ) {
      handleOpenToast(`Error creating trip. Please apply for refund`, "error");
      return;
    }
    var bidId = getBidId();
    const paymentAmount = getRazorPayAmount();
    const pickupState = getStateName(pickupAddress?.state);
    const dropoffState = getStateName(dropoffAddress?.state);
    let tripData = {
      customerEnquiryId: enquiryDetails?.enquiryId,
      customerName: enquiryDetails?.customer?.customerName,
      pickup: {
        address: pickupAddress?.addressLine1 + pickupAddress?.addressLine2,
        city: pickupAddress?.city,
        state: pickupState,
      },
      dropoff: {
        address:
          dropoffAddress?.addressLine1 + "," + dropoffAddress?.addressLine2,
        city: dropoffAddress?.city,
        state: dropoffState,
      },
      bidId: bidId,
      amountReceived: paymentAmount,
      paymentStatus: "PAYMENT_SUCCESSFULL",
      paymentId: paymentId,
    };

    const response = await createTrip(
      enquiryDetails?.customer?.customerId,
      tripData
    );

    if (response.status === 201) {
      const customerId = enquiryDetails?.customer?.customerId;
      const tripId = response.data.data[0]._id;
      await updateWhatsAppContactPersonDetails(
        customerId,
        tripId,
        contactPersonDetails
      );
      handleOpenToast(
        `Trip created successfully redirecting to WhatsApp`,
        "success"
      );
      window.location.replace(process.env.REACT_APP_WHATSAPP_CHATBOT_URL);
    } else {
      handleOpenToast(`Error creating trip. Please apply for refund`, "error");
      setStep(CONFIRM_BOOKING_DIALOG_STEPS.CONTACT_PERSON_ADDRESS);
    }
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  async function displayRazorpay() {
    if (
      !enquiryDetails ||
      !pickupAddress ||
      !dropoffAddress ||
      !getBidId() ||
      !contactPersonDetails
    ) {
      handleOpenToast(`Please fill all details`, "error");
      return;
    } else {
      try {
        const res = await loadScript(
          "https://checkout.razorpay.com/v2/checkout.js"
        );

        if (!res) {
          alert(
            "Razorpay SDK failed to load. Please check your internet connection."
          );
          return;
        }

        const orderData = {
          totalCost: getRazorPayAmount(),
          enquiryId: enquiryDetails?.enquiryId,
        };

        const result = await createOrder(orderData);

        if (!result) {
          alert("Server error. Please try again later.");
          return;
        }

        const { amount, id: order_id, currency } = result.data;
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY_ID,
          amount: amount.toString(),
          currency: currency,
          name: "LoadEazy",
          description: "Test Transaction",
          image:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAcBAMAAABi/9neAAAAG1BMVEUAAAAOgEc5OTk6OjoMgEQzMzMMf0QzMzP////9dtj5AAAABnRSTlMAEhIj7u4z0rXDAAAAAWJLR0QIht6VegAAAEVJREFUGNNjYEAFoaEBLC4wwMBQXl7AlgYDpHMZBWEAzEW2CMhlgVuDopccLiPcGnwW0dpHpaFwAOIiARK5xsZGSnDAAAB8VWucFCeHkwAAAABJRU5ErkJggg==",
          order_id: order_id,
          handler: async function (response) {
            const data = {
              orderCreationId: order_id,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            };
            await handleCreateTrip(response.razorpay_payment_id);
          },
          prefill: {
            name: enquiryDetails?.customer?.customerName,
            email: enquiryDetails?.customer?.customerEmail,
            contact: enquiryDetails?.customer?.customerPhone,
          },
          notes: {
            address: "LoadEazy office address",
          },
          theme: {
            color: "#0C7F44",
          },
        };
        const rzp = new window.Razorpay(options);
        rzp.open();
      } catch (error) {
        alert("Failed to load Razorpay SDK. Are you online?");
      }
    }
  }

  return (
    <Box
      sx={{
        mt: 5,
        padding: 2,
        backgroundColor: "#f7f7f7",
        borderRadius: 2,
        boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Radio
            checked={advancePaymentMethod === PAYMENT_METHOD_CONSTANTS.FULL}
            onChange={handleAdvancePaymentChange}
            value={PAYMENT_METHOD_CONSTANTS.FULL}
            name="radio-button"
            sx={{
              color: "#0C7F44",
              "&.Mui-checked": {
                color: "#0C7F44",
              },
            }}
          />
          <Box sx={{ display: "flex", flexDirection: "column", ml: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333" }}>
              ₹{getOfferedCost()}
            </Typography>
            <Typography variant="caption" sx={{ color: "#666" }}>
              Full Advance
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Radio
            checked={advancePaymentMethod === PAYMENT_METHOD_CONSTANTS.HALF}
            onChange={handleAdvancePaymentChange}
            value={PAYMENT_METHOD_CONSTANTS.HALF}
            name="radio-button"
            sx={{
              color: "#0C7F44",
              "&.Mui-checked": {
                color: "#0C7F44",
              },
            }}
          />
          <Box sx={{ display: "flex", flexDirection: "column", ml: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333" }}>
              ₹{Math.ceil(getOfferedCost() / 2)}
            </Typography>
            <Typography variant="caption" sx={{ color: "#666" }}>
              50% Advance
            </Typography>
          </Box>
        </Box>

        <Button
          variant="contained"
          fullWidth
          onClick={displayRazorpay}
          disabled={!advancePaymentMethod}
          sx={{
            backgroundColor: "#0C7F44",
            "&:hover": {
              backgroundColor: "#0A6740",
              opacity: 0.9,
            },
            color: "white",
            borderRadius: 20,
            padding: "8px 0",
          }}
        >
          Confirm & Pay
        </Button>
      </Box>
    </Box>
  );
}

export default WhatsAppAcceptOfferPayment;
