import React, { useEffect, useRef, useState } from "react";
import { StyledDetailsSectionBox } from "./StyledDetailsSectionBox";
import { StyledDetailsSectionTextPrimary } from "./StyledDetailsSectionTextPrimary";
import { StyledDetailsSectionTextSecondary } from "./StyledDetailsSectionTextSecondary";
import { Box, Divider, LinearProgress, Typography } from "@mui/material";
import { Tag } from "./Tag";
import { truncateString } from "../../utils/textFormat";
import ConfirmBookingDialog from "./ConfirmBookingDialog";
import { useTimer } from "react-timer-hook";
import { LOADING_STATUS, OFFER_TYPE } from "../../constants/constant";
import { getCustomerEnquiryOffer } from "../../api/Customer/enquiry";
import { useSelector, useDispatch } from "react-redux";
import PaymentSuccessDialog from "./PaymentSuccessDialog";
import { setEnquiryStatusTracking } from "../../redux/slices/customerSlice";
import { Skeleton, Fade } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

export const OfferDetails = ({ enquiry, enquiryDetails, handleOpenToast }) => {
  const [isConfirmBookingDialogOpen, setIsConfirmBookingDialogOpen] =
    useState(false);
  const [isPaymentSuccessDialogOpen, setIsPaymentSuccessDialogOpen] =
    useState(false);
  const dispatch = useDispatch();

  const [offers, setOffers] = useState({});
  const [offerType, setOfferType] = useState("");
  const [tripId, setTripId] = useState("");

  const [paymentAmount, setPaymentAmount] = useState();
  const [isEnquiryOffersFetched, setIsEnquiryOffersFetched] = useState("");
  const customerId = useSelector((state) => state.customer.id);
  var offerRefreshTime = enquiry?.timers?.offerRefreshTime
    ? Number(enquiry?.timers?.offerRefreshTime) * 60 * 1000
    : 60000;

  const detailsBoxRef = useRef(null);
  const dynamicBoxRef = useRef(null);
  const detailsBoxBORef = useRef(null);
  const dynamicBoxBORef = useRef(null);

  const getRemainingTimeInSeconds = () => {
    const currentTime = new Date().getTime();
    const expiryTime = new Date(
      enquiry?.timers?.offerExpiryTimestamp
    ).getTime();

    const timeRemaining = Math.max(0, expiryTime - currentTime);
    const remainingSeconds = Math.floor(timeRemaining / 1000);
    const time = new Date();
    time.setSeconds(remainingSeconds);
    return time;
  };

  const handleTimerExpired = async () => {
    await new Promise((resolve) => setTimeout(resolve, 5000));

    dispatch(setEnquiryStatusTracking(`${enquiry?.enquiryId}-offer-expired`));
  };
  const expiryTimestamp = new Date(
    enquiry?.timers?.offerExpiryTimestamp
  ).getTime();
  const autoStart = expiryTimestamp >= Date.now();
  const { seconds, minutes, hours } = useTimer({
    autoStart: autoStart,
    expiryTimestamp: getRemainingTimeInSeconds(),
    onExpire: () => handleTimerExpired(),
  });

  const totalElapsedTime = Number(enquiry?.timers?.offerExpiryTime * 60);
  let timePassed = Number(hours * 3600 + minutes * 60 + seconds);
  const progress = Number((timePassed / totalElapsedTime) * 100);

  const handleOpenConfirmBookingDialog = (offerType) => {
    setOfferType(offerType);
    setIsConfirmBookingDialogOpen(true);
  };

  const handleCloseConfirmBookingDialog = () => {
    setOfferType("");
    setIsConfirmBookingDialogOpen(false);
  };

  const handleClosePaymentSuccessDialog = () => {
    setIsPaymentSuccessDialogOpen(false);

    new Promise((resolve) => setTimeout(resolve, 5000)).then(() => {
      dispatch(setEnquiryStatusTracking(`contact-person-details-save-cancel`));
    });
  };

  const fetchCustomerEnquiryOffers = async () => {
    try {
      setIsEnquiryOffersFetched(LOADING_STATUS.LOADING);
      const response = await getCustomerEnquiryOffer(
        customerId,
        enquiry?.enquiryId
      );
      if (response.status === 200) {
        const details = response?.data.data;
        setOffers(details);
        setIsEnquiryOffersFetched(LOADING_STATUS.LOADED);
      }
    } catch (error) {
      setIsEnquiryOffersFetched(LOADING_STATUS.FAILED);
    }
  };

  useEffect(() => {
    if (isEnquiryOffersFetched === LOADING_STATUS.LOADED) {
      if (Object.keys(offers?.bestMatch).length !== 0) {
        const detailsBoxWidth = detailsBoxRef.current.offsetWidth;
        dynamicBoxRef.current.style.width = `${detailsBoxWidth}px`;
      }
      if (Object.keys(offers?.bestOffer).length !== 0) {
        const detailsBoxBOWidth = detailsBoxBORef.current.offsetWidth;
        dynamicBoxBORef.current.style.width = `${detailsBoxBOWidth}px`;
      }
    }
  });

  const getAmountPerTon = (totalAmount, weightInKg) => {
    const weightInTon = Number(weightInKg) / 1000;
    return Number(Number(totalAmount) / weightInTon).toFixed(2);
  };

  useEffect(() => {
    // Only fetch offers and set interval if isPaymentSuccessDialogOpen is false
    if (!isPaymentSuccessDialogOpen) {
      fetchCustomerEnquiryOffers();

      const intervalId = setInterval(() => {
        fetchCustomerEnquiryOffers();
      }, offerRefreshTime);

      return () => clearInterval(intervalId);
    }
  }, [isPaymentSuccessDialogOpen, offerRefreshTime]);

  return (
    <>
      <Box
        display="flex"
        sx={{ padding: "12px 8px" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography sx={{ color: "#333333", fontSize: 14, fontWeight: 700 }}>
            Offer Received:
          </Typography>
          <Typography
            sx={{ color: "#0C7F44", fontSize: 14, fontWeight: 400, mt: 1 }}
          >
            Congratulations!! We have found a Match.
            <br /> Please accept the offer to confirm your booking.
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ color: "#333333", fontSize: 14, fontWeight: 700 }}>
            {`offer expires in: `}
            <span
              style={{ color: "#F28929" }}
            >{`${hours}h:${minutes}m:${seconds}s`}</span>
          </Typography>
          {/* Horizontal progress bar */}
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              height: 10,
              borderRadius: 5,
              marginTop: 1,
              backgroundColor: "white",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#F28929",
              },
            }}
          />
        </Box>
      </Box>

      {isEnquiryOffersFetched === LOADING_STATUS.LOADED ? (
        <Fade in={true} timeout={1500}>
          <Box>
            {/* best match section */}
            {Object.keys(offers?.bestMatch).length !== 0 && (
              <Box sx={{ padding: "0px 4px" }}>
                <Box display="flex" gap={1} alignItems="center">
                  <Typography
                    sx={{ color: "#0C7F44", fontSize: 14, fontWeight: 400 }}
                  >
                    *this is the best match so far{" "}
                  </Typography>

                  <img src="/assets/icons/info.svg" alt="distance arrow" />
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="start"
                  mt={1}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    gap={2}
                    alignItems="center"
                    ref={detailsBoxRef}
                  >
                    <StyledDetailsSectionBox sx={{ background: "#FFFFFF" }}>
                      <StyledDetailsSectionTextPrimary>
                        {`${offers?.bestMatch?.vehicleCategory} wheeler`}
                      </StyledDetailsSectionTextPrimary>
                      <StyledDetailsSectionTextSecondary sx={{ mt: 0.5 }}>
                        Vehicle Category
                      </StyledDetailsSectionTextSecondary>
                    </StyledDetailsSectionBox>
                    <StyledDetailsSectionBox sx={{ background: "#FFFFFF" }}>
                      <StyledDetailsSectionTextPrimary>
                        {truncateString(
                          offers?.bestMatch?.vehicleModelName,
                          25
                        )}
                      </StyledDetailsSectionTextPrimary>
                      <StyledDetailsSectionTextSecondary sx={{ mt: 0.5 }}>
                        Vehicle Name
                      </StyledDetailsSectionTextSecondary>
                    </StyledDetailsSectionBox>
                    <StyledDetailsSectionBox sx={{ background: "#FFFFFF" }}>
                      <StyledDetailsSectionTextPrimary>
                        {`₹${Number(offers?.bestMatch?.totalCost).toFixed(2)}`}
                      </StyledDetailsSectionTextPrimary>
                      <StyledDetailsSectionTextSecondary sx={{ mt: 0.5 }}>
                        Total Amount
                      </StyledDetailsSectionTextSecondary>
                    </StyledDetailsSectionBox>
                    <StyledDetailsSectionBox sx={{ background: "#FFFFFF" }}>
                      <StyledDetailsSectionTextPrimary>
                        ₹{" "}
                        {getAmountPerTon(
                          offers?.bestMatch?.totalCost,
                          enquiry?.weight
                        )}
                      </StyledDetailsSectionTextPrimary>
                      <StyledDetailsSectionTextSecondary sx={{ mt: 0.5 }}>
                        Amount / ton
                      </StyledDetailsSectionTextSecondary>
                    </StyledDetailsSectionBox>
                  </Box>

                  <Box>
                    <StyledDetailsSectionBox
                      onClick={() =>
                        handleOpenConfirmBookingDialog(OFFER_TYPE.BEST_MATCH)
                      }
                      sx={{
                        background: "#0C7F44",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#FFFFFF",
                          fontSize: 18,
                          fontWeight: 700,
                          lineHeight: "24px",
                        }}
                      >
                        Accept <br />
                        Offer
                      </Typography>
                    </StyledDetailsSectionBox>
                  </Box>
                </Box>
                <Box ref={dynamicBoxRef}>
                  {offers?.bestMatch?.accessories?.toolsAvailable &&
                  offers.bestMatch.accessories.toolsAvailable.length > 0 ? (
                    // If toolsAvailable is not empty and present
                    offers.bestMatch.accessories.toolsAvailable.map(
                      (acc, index) => (
                        <Tag
                          key={index}
                          title={acc}
                          containerStyle={{ mt: 1.5, ml: 0.5 }}
                        />
                      )
                    )
                  ) : (
                    <Typography
                      sx={{ color: "#ff3f3f", fontSize: 14, fontWeight: 400 }}
                    >
                      Tools not available{" "}
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
            {Object.keys(offers?.bestMatch).length !== 0 &&
              Object.keys(offers?.bestOffer).length !== 0 && (
                <Box
                  display="flex"
                  sx={{ width: "100%", my: 1.5 }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Divider sx={{ color: "#6D6D6D", width: "47%" }} />
                  <Typography
                    sx={{
                      color: "#0C7F44",
                      fontSize: 14,
                      fontWeight: 700,
                      p: 1,
                    }}
                  >
                    OR
                  </Typography>
                  <Divider sx={{ color: "#6D6D6D", width: "47%" }} />
                </Box>
              )}

            {/* best offer section */}
            {Object.keys(offers?.bestOffer).length !== 0 && (
              <Box sx={{ padding: "0px 4px", mb: 2 }}>
                <Box display="flex" gap={1} alignItems="center">
                  <Typography
                    sx={{ color: "#0C7F44", fontSize: 14, fontWeight: 400 }}
                  >
                    *this is the best offer so far{" "}
                  </Typography>

                  <Tooltip
                    title="This is Lowest cost offer available for you"
                    placement="top"
                    arrow
                  >
                    <img src="/assets/icons/info.svg" alt="distance arrow" />
                  </Tooltip>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="start"
                  mt={1}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    gap={2}
                    alignItems="center"
                    ref={detailsBoxBORef}
                  >
                    <StyledDetailsSectionBox sx={{ background: "#FFFFFF" }}>
                      <StyledDetailsSectionTextPrimary>
                        {`${offers?.bestOffer?.vehicleCategory} wheeler`}
                      </StyledDetailsSectionTextPrimary>
                      <StyledDetailsSectionTextSecondary sx={{ mt: 0.5 }}>
                        Vehicle Category
                      </StyledDetailsSectionTextSecondary>
                    </StyledDetailsSectionBox>
                    <StyledDetailsSectionBox sx={{ background: "#FFFFFF" }}>
                      <StyledDetailsSectionTextPrimary>
                        {truncateString(
                          offers?.bestOffer?.vehicleModelName,
                          25
                        )}
                      </StyledDetailsSectionTextPrimary>
                      <StyledDetailsSectionTextSecondary sx={{ mt: 0.5 }}>
                        Vehicle Name
                      </StyledDetailsSectionTextSecondary>
                    </StyledDetailsSectionBox>
                    <StyledDetailsSectionBox sx={{ background: "#FFFFFF" }}>
                      <StyledDetailsSectionTextPrimary>
                        {`₹${Number(offers?.bestOffer?.totalCost).toFixed(2)}`}
                      </StyledDetailsSectionTextPrimary>
                      <StyledDetailsSectionTextSecondary sx={{ mt: 0.5 }}>
                        Total Amount
                      </StyledDetailsSectionTextSecondary>
                    </StyledDetailsSectionBox>
                    <StyledDetailsSectionBox sx={{ background: "#FFFFFF" }}>
                      <StyledDetailsSectionTextPrimary>
                        ₹{" "}
                        {getAmountPerTon(
                          offers?.bestOffer?.totalCost,
                          enquiry?.weight
                        )}
                      </StyledDetailsSectionTextPrimary>
                      <StyledDetailsSectionTextSecondary sx={{ mt: 0.5 }}>
                        Amount/Tonne
                      </StyledDetailsSectionTextSecondary>
                    </StyledDetailsSectionBox>
                  </Box>

                  <Box>
                    <StyledDetailsSectionBox
                      onClick={() =>
                        handleOpenConfirmBookingDialog(OFFER_TYPE.BEST_OFFER)
                      }
                      sx={{ background: "#0C7F44", cursor: "pointer" }}
                    >
                      <Typography
                        sx={{
                          color: "#FFFFFF",
                          fontSize: 18,
                          fontWeight: 700,
                          lineHeight: "24px",
                        }}
                      >
                        Accept <br />
                        Offer
                      </Typography>
                    </StyledDetailsSectionBox>
                  </Box>
                </Box>

                <Box ref={dynamicBoxBORef}>
                  {offers?.bestOffer?.accessories?.toolsAvailable &&
                    offers?.bestOffer?.accessories?.toolsAvailable?.map(
                      (acc, index) => (
                        <Tag
                          key={index}
                          title={acc}
                          containerStyle={{ mt: 1.5, ml: 0.5 }}
                        />
                      )
                    )}
                  {offers?.bestOffer?.accessories?.other && (
                    <Tag
                      key={offers?.bestOffer?.accessories?.other}
                      title={offers?.bestOffer?.accessories?.other}
                      containerStyle={{ mt: 1.5, ml: 0.5 }}
                    />
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Fade>
      ) : isEnquiryOffersFetched === LOADING_STATUS.LOADING ? (
        <>
          <Box display="flex" gap={2}>
            <Skeleton variant="rounded" width={200} height={60} />
            <Skeleton variant="rounded" width={200} height={60} />
            <Skeleton variant="rounded" width={200} height={60} />
            <Skeleton variant="rounded" width={200} height={60} />
          </Box>
          <Box width={630} pt={2}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        </>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" p={3}>
          <Typography sx={{ color: "#ff3f3f" }}>
            Error while loading offers!
          </Typography>
        </Box>
      )}

      <ConfirmBookingDialog
        offerType={offerType}
        offers={offers}
        enquiry={enquiry}
        enquiryDetails={enquiryDetails}
        open={isConfirmBookingDialogOpen}
        handleClose={handleCloseConfirmBookingDialog}
        setIsPaymentSuccessDialogOpen={setIsPaymentSuccessDialogOpen}
        setPaymentAmount={setPaymentAmount}
        setTripId={setTripId}
        handleOpenToast={handleOpenToast}
      />
      <PaymentSuccessDialog
        isOpen={isPaymentSuccessDialogOpen}
        handleClose={handleClosePaymentSuccessDialog}
        paymentAmount={paymentAmount}
        tripId={tripId}
        handleOpenToast={handleOpenToast}
      />
    </>
  );
};
