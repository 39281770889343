import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import ErrorScreen from "../../../component/Common/ErrorScreen";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LOADING_STATUS } from "../../../constants/constant";
import useToast from "../../../hooks/useToast";
import {
  addComment,
  getTicketDetailsByTicketId,
  updateTicket,
} from "../../../api/Admin/support";
const EditSupportTicket = () => {
  const { toastData, handleOpenToast, handleCloseToast, ToastMessage } =
    useToast();
  const [ticketDetails, setTicketDetails] = useState(null);
  const [allComments, setAllComments] = useState(null);
  const [latestComment, setLatestComment] = useState("");
  const [ticketPriority, setTicketPriority] = useState("");
  const [ticketStatus, setTicketStatus] = useState("");

  const [isTicketDetailsFetched, setIsTicketDetailsFetched] = useState(
    LOADING_STATUS.INITIAL
  );
  const [isCommentAdded, setIsCommentAdded] = useState(false);

  const [isPriorityOrStatusChanged, setIsPriorityOrStatusChanged] =
    useState(true);
  const navigate = useNavigate();
  const { id } = useParams(); //ticket id

  const fetchSupportTicketDetails = async () => {
    setIsTicketDetailsFetched(LOADING_STATUS.LOADING);
    try {
      const response = await getTicketDetailsByTicketId(id);
      if (response.status === 200) {
        setTicketDetails(response.data.data);
        setAllComments(response.data.data.comments);
        setTicketPriority(response.data.data.priority);
        setTicketStatus(response.data.data.status);
        setIsTicketDetailsFetched(LOADING_STATUS.LOADED);
      } else {
        setIsTicketDetailsFetched(LOADING_STATUS.FAILED);
      }
    } catch (err) {
      setIsTicketDetailsFetched(LOADING_STATUS.FAILED);
    }
  };

  // handler for adding comment on ticket
  const handleAddComment = async () => {
    setIsCommentAdded(true);
    try {
      const response = await addComment(latestComment, id);
      if (response.status === 200) {
        setIsPriorityOrStatusChanged(true);
        setAllComments(response.data.data.comments);
        setLatestComment("");
        handleOpenToast(`${response.data.message}`, "success");
      } else {
        handleOpenToast("Something went wrong!", "error");
      }
    } catch (err) {
      handleOpenToast(err.response.data.message, "error");
    }
    setIsCommentAdded(false);
  };
  // handler for updating ticket status or priority
  const handleUpdateTicket = async () => {
    try {
      const response = await updateTicket(ticketPriority, ticketStatus, id);
      if (response.status === 200) {
        setIsPriorityOrStatusChanged(true);
        handleOpenToast(`${response.data.message}`, "success");
      } else {
        handleOpenToast("Something went wrong!", "error");
      }
    } catch (error) {
      if (error.response) {
        handleOpenToast(error.response.statusText, "error");
      } else if (error.request) {
        handleOpenToast(error.request.data.error, "error");
      } else {
        handleOpenToast(error.message, "error");
      }
    }
  };

  useEffect(() => {
    fetchSupportTicketDetails();
  }, []);

  function renderViews() {
    switch (isTicketDetailsFetched) {
      case LOADING_STATUS.LOADING:
        return (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            height={400}
          >
            <CircularProgress size={36} sx={{ color: "green" }} />
          </Stack>
        );
      case LOADING_STATUS.FAILED:
        return (
          <Box pt={10}>
            <ErrorScreen />
          </Box>
        );
      case LOADING_STATUS.LOADED:
        return (
          <Box p={3}>
            <Container>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={3}
              >
                <Typography>
                  Stakeholder:{" "}
                  <b>
                    {ticketDetails?.stakeholder} (
                    {ticketDetails?.stakeholderName})
                  </b>
                </Typography>
                <Box>
                  <TextField
                    select
                    value={ticketPriority}
                    onChange={(e) => {
                      setTicketPriority(e.target.value);
                      setIsPriorityOrStatusChanged(false);
                    }}
                    InputProps={{
                      sx: {
                        height: "36px",
                        width: "120px",
                        fontWeight: 600,
                        color: "#333",
                      },
                    }}
                  >
                    <MenuItem value="Low">Low</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="High">High</MenuItem>
                  </TextField>
                  <TextField
                    select
                    value={ticketStatus}
                    onChange={(e) => {
                      setTicketStatus(e.target.value);
                      setIsPriorityOrStatusChanged(false);
                    }}
                    InputProps={{
                      sx: {
                        height: "36px",
                        width: "120px",
                        fontWeight: 600,
                        color: "#333",
                        marginLeft: "10px",
                      },
                    }}
                  >
                    <MenuItem value="Open">Open</MenuItem>
                    <MenuItem value="Hold">Hold</MenuItem>
                    <MenuItem value="Closed">Closed</MenuItem>
                  </TextField>
                </Box>
              </Stack>

              <Typography>Issue Title:</Typography>
              <Typography mb={3} fontWeight={700}>
                {ticketDetails?.natureOfIssue}
              </Typography>

              <Typography>Issue Description:</Typography>
              <Typography mb={3} fontWeight={700}>
                {ticketDetails?.issueDescription}
              </Typography>

              <Typography>Comments:</Typography>
              <Stack direction="column" gap={1} mb={3}>
                {allComments && allComments.length ? (
                  allComments.map((comment, index) => (
                    <Comment key={index} comment={comment} />
                  ))
                ) : (
                  <Typography fontWeight={700} color="#ADADAD">
                    No comments added yet.
                  </Typography>
                )}
              </Stack>
              <TextField
                fullWidth
                autoComplete="off"
                placeholder="Add Comment"
                value={latestComment}
                required
                onChange={(e) => setLatestComment(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleAddComment}
                      startIcon={
                        isCommentAdded && (
                          <CircularProgress size={20} sx={{ color: "#FFF" }} />
                        )
                      }
                    >
                      Add
                    </Button>
                  ),
                }}
              />
              <Stack direction="row" justifyContent="flex-end" mt={3}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={isPriorityOrStatusChanged}
                  onClick={handleUpdateTicket}
                  sx={{ paddingInline: "2rem" }}
                >
                  Update
                </Button>
              </Stack>
            </Container>
          </Box>
        );
      default:
        return null;
    }
  }

  return (
    <Box>
      <ToastMessage
        open={toastData.open}
        message={toastData.message}
        severity={toastData.severity}
        handleClose={handleCloseToast}
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ background: "#EAEAEA" }}
        p=".5rem 1.5rem"
      >
        <img
          alt="back"
          src="/assets/icons/backArrow.svg"
          style={{ cursor: "pointer", width: "24px", height: "20px" }}
          onClick={() => navigate(-1)}
        />
        <Typography color="#333" fontWeight={700} fontSize="1.25rem">
          Edit Ticket
        </Typography>
        <Typography></Typography>
      </Stack>
      {renderViews()}
    </Box>
  );
};

export default EditSupportTicket;

const Comment = (props) => {
  console.log(props);
  return (
    <Box>
      <Typography fontWeight={700} color="#333">
        {props.comment.text}
      </Typography>
      <Typography fontSize="11px" color="#ADADAD">
        {props.comment.createdAt}
      </Typography>
    </Box>
  );
};
