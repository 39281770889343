import React from "react";
import { Typography, Box, CardContent, CardMedia, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
const text = {
  color: "#333",
  fontSize: "24px",
  fontWeight: 700,
};
const description = {
  color: "#767676",
  fontWeight: 400,
  fontSize: "18px",
};
const iconSize = {
  width: "180px",
  height: "180px",
};

const WhyChooseUs = () => {
  const whyChooseUs = [
    {
      title: "Efficient Coordination",
      description: "Seamlessly match your shipments with the right carriers.",
      image: "/assets/landingPageIcons/WCU-effecient-coordination.svg",
    },
    {
      title: "Real-Time Tracking",
      description: "Gain complete visibility into your good's journey.",
      image: "/assets/landingPageIcons/WCU-realtime-tracking.svg",
    },
    {
      title: "Cost Optimization",
      description: "Reduce expenses and improve your bottom line.",
      image: "/assets/landingPageIcons/WCU-cost-optimization.svg",
    },
    {
      title: "Seamless Experience",
      description: "Stay on top of regulations with our automated tools.",
      image: "/assets/landingPageIcons/WCU-seamless-experience.svg",
    },
    {
      title: "Secure Data",
      description: "Your Data is 100% secure with us and used only for fulfillment of services offered",
      image: "/assets/landingPageIcons/WCU-secure-data.svg",
    },
  ];
  return (
    <Box
      id="whyChooseUs"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#FFF",
        paddingBlock:'2.5rem'
      }}
    >
      <Typography
        sx={{
          color: "#333",
          marginBottom: "1.5rem",
          fontSize: "36px",
          fontWeight: 700,
          textAlign: "center",
        }}
      >
        Why Choose Us
      </Typography>
      <Typography maxWidth='lg' textAlign='center' fontSize={24} color="#333">
      The LoadEazy Team, with over 50 years of experience in the steel industry, deeply understands its complexities and challenges. Our solution is carefully designed to transform the transportation of steel products, placing a high priority on both convenience and safety.
      </Typography>
      <Typography maxWidth='lg' textAlign='center' fontSize={24} color="#333" mt={2} mb={2}>
      The primary objective of our platform is to create data driven decision support mechanism for an efficient logistics system, in line with the National Logistics Policy.
      </Typography>
      <Stack
        direction="row"
        justifyContent="center"
        maxWidth="xl"
        gap="56px"
        m="auto"
        flexWrap="wrap"
      >
        {whyChooseUs.map((e) => (
          <Box
            width="256px"
            alignItems="center"
            textAlign="center"
            key={e.title}
          >
            <img src={e.image} style={iconSize} alt={e.title} />
            <Typography sx={text}>{e.title}</Typography>
            <Typography sx={description}>{e.description}</Typography>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default WhyChooseUs;