import { adminApi } from "../config";

// get all admin enquiries
export const getAllAdminEnquiries = async (
  page,
  enquiryStatus,
  customerPhone
) => {
  try {
    const response = await adminApi.get(
      `admin/enquiry?page=${
        page + 1
      }&enquiryStatus=${enquiryStatus.toString()}&customerPhone=${customerPhone}`
    );

    return response;
  } catch (err) {
    return err;
  }
};

// get admin enquiry by id
export const getAdminEnquiryById = async (customerEnquiryId) => {
  try {
    const response = await adminApi.get(`admin/enquiry/${customerEnquiryId}`);

    return response;
  } catch (err) {
    return err;
  }
};

// reject admin enquiry by id
export const rejectAdminEnquiryById = async (customerEnquiryId) => {
  try {
    const response = await adminApi.patch(
      `admin/enquiry/${customerEnquiryId}/reject`
    );

    return response;
  } catch (err) {
    return err;
  }
};

// Edit admin enquiry by id
export const updateAdminEnquiryById = async (
  customerEnquiryId,
  updateEnquiryData
) => {
  try {
    const response = await adminApi.put(
      `admin/enquiry/${customerEnquiryId}`,
      updateEnquiryData
    );

    return response;
  } catch (err) {
    return err;
  }
};
