import { Box, Button, Drawer, Link, List, Toolbar } from "@mui/material";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import { useLocation, useNavigate } from "react-router-dom";
import { logoutAdmin } from "../../api/logout";
import useToast from "../../hooks/useToast";
import Cookies from "js-cookie";

const Sidebar = ({ routes }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { ToastMessage, handleOpenToast } = useToast();
  const handleAdminLogout = async () => {
    try {
      await logoutAdmin();
      Cookies.remove("admintoken");
      Cookies.remove("loggedInAdminId");
      navigate("/", { replace: true });
    } catch (err) {
      handleOpenToast("Error logging out. Please try again.", "error");
    }
  };
  const drawerWidth = 276;
  return (
    <Box>
      <ToastMessage />
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            borderRight: "1px solid #adadad",
            backgroundColor: "#FFF",
            "&::-webkit-scrollbar": {
              width: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#eaeaea",
              borderRadius: "6px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#fff",
              borderRadius: "10px",
            },
          },
        }}
      >
        <List disablePadding>
          <Toolbar sx={{ marginTop: "1.25rem", height: "6rem" }} />
          {routes.map((route, index) =>
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse item={route} key={index} />
              ) : (
                <SidebarItem item={route} key={index} />
              )
            ) : null
          )}
        </List>
        <List
          sx={{
            borderTop: "1px solid #adadad",
            textAlign: "center",
            padding: "1rem",
            marginTop: "auto",
            cursor: "pointer",
          }}
        >
          {location.pathname.includes("admin") ? (
            <Button
              sx={{ textTransform: "none", color: "#FF0707" }}
              onClick={handleAdminLogout}
            >
              Logout
            </Button>
          ) : (
            <Link
              target="_blank"
              href="/terms&conditions"
              color="#414A50"
              fontWeight="600"
            >
              Terms & Conditions
            </Link>
          )}
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
