import { ListItemButton, ListItemIcon } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const SidebarItem = ({ item, hasChild = false }) => {
  const location = useLocation();
  const isAdminPortal = location.pathname.includes("admin");
  const isCustomerPortal = location.pathname.includes("customer");
  const ftueStatus = localStorage.getItem("ftueStatus");

  const [enableTab, setEnableTab] = useState(false);
  useEffect(() => {
    if (ftueStatus) {
      if (ftueStatus !== "DONE" && !isAdminPortal && !isCustomerPortal) {
        setEnableTab(true);
      }
    }
  }, []);
  const { transporterAppState } = useSelector(
    (state) => state.transporterAppState
  );

  return item.sidebarProps && item.path ? (
    <ListItemButton
      component={Link}
      disabled={enableTab}
      to={`${item.path}`}
      sx={{
        "&: hover": {
          backgroundColor: "#f6f6f6",
        },
        textDecoration: "none",
        fontSize: isAdminPortal ? "1rem" : "18px",
        mb: hasChild ? "4px" : "8px",
        fontWeight: isAdminPortal
          ? transporterAppState === item.state
            ? "700"
            : "400"
          : "700",
        backgroundColor:
          transporterAppState === item.state ? "#DDE7EF" : "unset",
        borderRadius: ".25rem",
        color: transporterAppState === item.state ? "#333333" : "#6d6d6d",
        paddingY: hasChild ? "6px" : "12px",
        paddingLeft: "24px",
        paddingRight: hasChild ? "1px" : "24px",
        width: hasChild ? "68%" : "auto",
        ml: "auto",
        mr: "1rem",
      }}
    >
      {!hasChild && (
        <ListItemIcon
          sx={{
            filter:
              transporterAppState === item.state
                ? "invert(90%)"
                : "invert(50%)",
          }}
        >
          {item.sidebarProps.icon && item.sidebarProps.icon}
        </ListItemIcon>
      )}
      {item.sidebarProps.displayText}
    </ListItemButton>
  ) : null;
};

export default SidebarItem;
