import React from "react";
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import HeaderWithUnderline from "../Configurations/Enquiries/HeaderWithUnderline";
import NameDescription from "../Configurations/Enquiries/NameDescription";

const BoxContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  paddingTop: "16px",
  paddingLeft: "32px",
  paddingRight: "32px",
}));

const StyledBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export default function TransporterDetails({ transporterDetails }) {
  return (
    <Box sx={{ padding: "24px" }}>
      <HeaderWithUnderline header="Transporter Details" />

      <StyledBox>
        <BoxContainer marginLeft="0px" gap="40px">
          <NameDescription
            name="Transporter Name"
            description={transporterDetails?.fullName || "-"}
            color="#6D6D6D"
            marginLeft="0px"
          />
          <NameDescription
            name="Address"
            description={
              transporterDetails?.businessAddress?.addressLine1 || "-"
            }
            marginLeft="0px"
            color="#6D6D6D"
          />

          <NameDescription
            name="Email Address"
            description={transporterDetails?.email || "-"}
            marginLeft="0px"
            color="#6D6D6D"
            textTransform="none"
          />
          <NameDescription
            name="Transporter Company Name"
            description={transporterDetails?.businessName || "-"}
            marginLeft="0px"
            color="#6D6D6D"
          />
          <NameDescription
            name="Contact number"
            description={transporterDetails?.phone || "-"}
            marginLeft="0px"
            color="#0C7F44"
          />
        </BoxContainer>
      </StyledBox>
    </Box>
  );
}
