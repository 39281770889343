import { adminApi } from "../config";

const getEnquiryRelatedConfigs = async () => {
  try {
    const response = await adminApi.get(`inventory/time-related/enquiry`);
    return response;
  } catch (error) {
    return error;
  }
};

const updateEnquiryRelatedTime = async (
  timeData,
  enquiryValidityTimerBool,
  offerExpiryTimerBool,
  tripAllocationDeadlineBool
) => {
  try {
    let queryString = "";
    if (enquiryValidityTimerBool) {
      queryString = "enquiryValidityTimer=true";
    } else if (offerExpiryTimerBool) {
      queryString = "offerExpiryTimer=true";
    } else if (tripAllocationDeadlineBool) {
      queryString = "tripAllocationDeadline=true";
    }

    const response = await adminApi.put(
      `inventory/time-related/enquiry?${queryString}`,
      timeData
    );

    return response;
  } catch (error) {
    return error;
  }
};

const getCustomerRelatedTime = async () => {
  try {
    const response = await adminApi.get(`inventory/time-related/customer`);
    return response;
  } catch (error) {
    return error;
  }
};

const updateCustomerRelatedTime = async (
  timeData,
  showDriverInfoPreTripTimeBool,
  customerTripCancellationDeadlineBool,
  tripCancellationNotificationDeadlineBool,
  customerPaymentSettlementDeadlineBool,
  offersRefreshTimeBool
) => {
  try {
    let queryString = "";
    if (showDriverInfoPreTripTimeBool) {
      queryString = "showDriverInfoPreTripTime=true";
    } else if (customerTripCancellationDeadlineBool) {
      queryString = "customerTripCancellationDeadline=true";
    } else if (tripCancellationNotificationDeadlineBool) {
      queryString = "tripCancellationNotificationDeadline=true";
    } else if (customerPaymentSettlementDeadlineBool) {
      queryString = "customerPaymentSettlementDeadline=true";
    } else if (offersRefreshTimeBool) {
      queryString = "offersRefreshTime=true";
    }

    const response = await adminApi.put(
      `inventory/time-related/customer?${queryString}`,
      timeData
    );

    return response;
  } catch (error) {
    return error;
  }
};

const getTransporterRelatedTime = async () => {
  try {
    const response = await adminApi.get(`inventory/time-related/transporter`);
    return response;
  } catch (error) {
    return error;
  }
};

const updateTransporterRelatedTime = async (
  timeData,
  tripCancellationDeadlineTransporterBool,
  paymentSettlementDeadlineTransporterBool
) => {
  try {
    const response = await adminApi.put(
      `inventory/time-related/transporter?tripCancellationDeadlineTransporter=${tripCancellationDeadlineTransporterBool}&paymentSettlementDeadlineTransporter=${paymentSettlementDeadlineTransporterBool}`,
      timeData
    );
    return response;
  } catch (error) {
    return error;
  }
};

const getDriverRelatedTime = async () => {
  try {
    const response = await adminApi.get(`inventory/time-related/driver`);
    return response;
  } catch (error) {
    return error;
  }
};

const updateDriverRelatedTime = async (
  timeData,
  preTripReminderNotificationTimeDriverBool,
  goToPickupButtonActivationTimeDriverBool
) => {
  try {
    const response = await adminApi.put(
      `inventory/time-related/driver?preTripReminderNotificationTimeDriver=${preTripReminderNotificationTimeDriverBool}&goToPickupButtonActivationTimeDriver=${goToPickupButtonActivationTimeDriverBool}`,
      timeData
    );
    return response;
  } catch (error) {
    return error;
  }
};

const getEnquiryRelatedPickupTimeSlotById = async (id) => {
  try {
    const response = await adminApi.get(
      `inventory/time-related/enquiry/pickup/${id}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

const createEnquiryRelatedPickupTimeSlot = async (timeData) => {
  try {
    const response = await adminApi.post(
      `inventory/time-related/enquiry/pickup`,
      timeData
    );
    return response;
  } catch (error) {
    return error;
  }
};

const updateEnquiryRelatedPickupTimeSlot = async (id, timeData) => {
  try {
    const response = await adminApi.put(
      `inventory/time-related/enquiry/pickup/${id}`,
      timeData
    );
    return response;
  } catch (error) {
    return error;
  }
};

const deleteEnquiryRelatedPickupTimeSlot = async (id) => {
  try {
    const response = await adminApi.delete(
      `inventory/time-related/enquiry/pickup/${id}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export {
  getEnquiryRelatedConfigs,
  updateEnquiryRelatedTime,
  getCustomerRelatedTime,
  updateCustomerRelatedTime,
  getTransporterRelatedTime,
  updateTransporterRelatedTime,
  getDriverRelatedTime,
  updateDriverRelatedTime,
  getEnquiryRelatedPickupTimeSlotById,
  createEnquiryRelatedPickupTimeSlot,
  updateEnquiryRelatedPickupTimeSlot,
  deleteEnquiryRelatedPickupTimeSlot,
};
