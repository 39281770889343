import React, { useState, useEffect } from "react";
import { TextField, Button, Grid, CircularProgress, Box } from "@mui/material";
import {
  MapContainer,
  TileLayer,
  Marker,
  Tooltip,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import {
  getAllDriverLocation,
  getDriverLocation,
} from "../../../api/Admin/vehicleTracking";

const truckIcon = new L.Icon({
  iconUrl: "/assets/icons/truck.svg",
  iconSize: [15, 15],
  iconAnchor: [12, 25],
  popupAnchor: [0, -25],
});

const FitToBounds = () => {
  const map = useMap();

  useEffect(() => {
    map.fitBounds([
      [20.0, 60.0],
      [8.0, 98.0],
    ]);
  }, [map]);

  return null;
};

const VehicleTracker = () => {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [transporterId, setTransporterId] = useState("");
  const [driverId, setDriverId] = useState("");

  const handleSearch = async () => {
    setLoading(true);
    let newVehicles = [];

    if (transporterId) {
      const response = await getAllDriverLocation(transporterId);
      const validPositions = response.data.locations.filter(
        (vehicle) =>
          vehicle &&
          typeof vehicle.latitude === "number" &&
          typeof vehicle.longitude === "number" &&
          !isNaN(vehicle.latitude) &&
          !isNaN(vehicle.longitude)
      );

      const promises = validPositions.map(async (vehicle) => {
        const { latitude, longitude } = vehicle;
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
        );
        const address =
          response.data.results[0]?.formatted_address || "Unknown address";
        return { ...vehicle, address };
      });

      newVehicles = await Promise.all(promises);
    } else if (driverId) {
      const response = await getDriverLocation(driverId);
      const vehicle = response.data.locations;
      if (vehicle) {
        const { latitude, longitude } = vehicle;
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
        );
        const address =
          response.data.results[0]?.formatted_address || "Unknown address";
        newVehicles = [{ ...vehicle, address }];
      }
    }

    setVehicles(newVehicles);
    setLoading(false);
  };

  return (
    <Box p={2}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by transporter id"
            value={transporterId}
            onChange={(e) => setTransporterId(e.target.value)}
            InputProps={{
              endAdornment: (
                <img src="/assets/icons/search-icon.svg" alt="search" />
              ),
            }}
            sx={{ marginBottom: { xs: 1, sm: 0 } }}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by driver id"
            value={driverId}
            onChange={(e) => setDriverId(e.target.value)}
            InputProps={{
              endAdornment: (
                <img src="/assets/icons/search-icon.svg" alt="search" />
              ),
            }}
            sx={{ marginBottom: { xs: 1, sm: 0 } }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSearch}
            sx={{ height: "56px", fontSize: "16px" }}
          >
            Search
          </Button>
        </Grid>
      </Grid>

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="600px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box mt={2} height="600px" width="100%" overflow="auto">
          <Box height="1200px" width="100%">
            <MapContainer
              center={[20.5937, 78.9629]}
              zoom={5}
              style={{ height: "100%", width: "100%" }}
              scrollWheelZoom={true}
            >
              <FitToBounds />
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              {vehicles.map((vehicle) => (
                <Marker
                  key={vehicle.id}
                  position={[vehicle.latitude, vehicle.longitude]}
                  icon={truckIcon}
                >
                  <Tooltip
                    direction="top"
                    offset={[0, -25]}
                    opacity={1}
                    interactive
                  >
                    <div>
                      <strong>Driver Name: {vehicle.driverName}</strong>
                      <br />
                      Mobile Number: {vehicle.phone}
                      <br />
                      Address: {vehicle.address}
                    </div>
                  </Tooltip>
                </Marker>
              ))}
            </MapContainer>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default VehicleTracker;
