import { Box, TextField } from "@mui/material";
import { useRef } from "react";

export function OTPInput({ otp, setOtp,onChange,handleOTPVerification }) {
    const OTPInputFieldStyle = {
      textAlign: "center",
      fontSize: "24px",
      fontWeight: 700,
      padding: ".5rem",
      width: "44px",
      height: "50px",
    };
  
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  
    const handleOTPInputChange = (index, event) => {
      onChange();
      const { value } = event.target;
      if (isNaN(value) || value.length > 1) {
        return;
      }
  
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
  
      // Move to the next input field automatically
      if (value !== "" && index < inputRefs.length - 1) {
        inputRefs[index + 1].current.focus();
      }
    };
  
    const handleKeyDown = (index, event) => {
      if (event.key === "Backspace" && index > 0 && otp[index] === "") {
        inputRefs[index - 1].current.focus();
      }
      if(event.key === "Enter" && !otp.some(value=>value ==="")){
        handleOTPVerification();
      }
    };
  
    return (
      <Box display="flex" gap="10px" mt={2}>
        {otp.map((digit, index) => (
          <TextField
            autoFocus={index === 0}
            key={index}
            inputRef={inputRefs[index]}
            value={digit}
            onChange={(event) => handleOTPInputChange(index, event)}
            onKeyDown={(event) => handleKeyDown(index, event)}
            variant="outlined"
            inputProps={{
              maxLength: 1,
              style: {
                ...OTPInputFieldStyle,
              },
            }}
          />
        ))}
      </Box>
    );
  }