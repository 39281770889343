import React, { useEffect, useState } from "react";
import { Collapse, LinearProgress, TableCell, TableRow } from "@mui/material";
import { ENQUIRY_STATUS } from "../../../constants/constant";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import moment from "moment";

import {
  setEnquiryStatusTracking,
  setSelectedRow,
} from "../../../redux/slices/transporterSlice";
import EnquiryDetails from "./EnquiryDetails";
import StatusCell from "./StatusCell";
import { useTimer } from "react-timer-hook";
import { updateEnquiryDetailsById } from "../../../api/Transporter/enquiries";
import EnquiryExpiredDetails from "./EnquiryExpiredDetails";

export const EnquiryTableRow = ({ enquiry, handleOpenToast }) => {
  const selectedRow = useSelector((state) => state.transporter.selectedRow);
  const dispatch = useDispatch();
  const timerOpenStatus = [ENQUIRY_STATUS.UNQUOTED, ENQUIRY_STATUS.QUOTED];
  const timerAutoStartStatus = [
    ENQUIRY_STATUS.UNQUOTED,
    ENQUIRY_STATUS.QUOTED,
    ENQUIRY_STATUS.YOU_QUOTED,
  ];

  const greenCollapseView = [
    ENQUIRY_STATUS.UNQUOTED,
    ENQUIRY_STATUS.QUOTED,
    ENQUIRY_STATUS.YOU_QUOTED,
    ENQUIRY_STATUS.BID_WON,
  ];

  function getCollapseView(
    selectedRow,
    transporterId,
    transporterEnquiryId,
    customerEnquiryId,
    enquiryStatus
  ) {
    if (greenCollapseView.includes(enquiryStatus)) {
      return (
        <EnquiryDetails
          selectedRow={selectedRow}
          transporterId={transporterId}
          transporterEnquiryId={transporterEnquiryId}
          enquireId={customerEnquiryId}
          enquiryStatus={enquiryStatus}
          handleOpenToast={handleOpenToast}
        />
      );
    } else {
      return (
        <EnquiryExpiredDetails
          selectedRow={selectedRow}
          transporterId={transporterId}
          transporterEnquiryId={transporterEnquiryId}
          enquireId={customerEnquiryId}
          enquiryStatus={enquiryStatus}
        />
      );
    }
  }

  const handleRowClick = (isSelectedRow, enquiry) => {
    if (isSelectedRow) {
      dispatch(setSelectedRow(null));
    } else {
      dispatch(setSelectedRow(enquiry));
    }
  };

  const StyledTableRow = styled(TableRow)(({ isSelectedRow }) => ({
    textTransform: "capitalize",
    border: "2px solid #FFF",
    background: "",
    "&:hover": {
      background: "#E9F2F4",
      td: {
        color: "#333333",
      },
    },
    td: {
      fontWeight: 700,
      fontSize: "12px",
      paddingBottom: "18px",
      paddingTop: "18px",
      color: isSelectedRow ? "#333333" : "#6D6D6D",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const isSelectedRow =
    selectedRow?.customerEnquiryId === enquiry?.customerEnquiryId;

  const getRemainingTimeInSeconds = () => {
    const currentTime = moment.utc().valueOf();
    const expiryTime = moment(enquiry?.enquiryExpirationTimestamp).valueOf();

    const timeRemaining = Math.max(0, expiryTime - currentTime);
    const remainingSeconds = Math.floor(timeRemaining / 1000);
    const time = new Date();
    time.setSeconds(remainingSeconds);
    return time;
  };

  const handleTimerExpired = async () => {
    if (!timerAutoStartStatus.includes(enquiry?.enquiryStatus)) {
      return;
    }
    try {
      await new Promise((resolve) => setTimeout(resolve, 10000));

      const response = await updateEnquiryDetailsById(
        enquiry?.transporterId,
        enquiry?.transporterEnquiryId,
        { bidTimeExpired: true }
      );
      if (response.status === 200) {
        dispatch(
          setEnquiryStatusTracking(
            `${enquiry?.transporterEnquiryId}-timer-expired`
          )
        );
      }
    } catch (error) {
      console.log("error while updating enquiry", error);
    }
  };

  const expiryTime = new Date(enquiry?.enquiryExpirationTimestamp).getTime();
  const autoStart = expiryTime >= Date.now();
  const { seconds, minutes, hours } = useTimer({
    autoStart:
      autoStart && timerAutoStartStatus.includes(enquiry?.enquiryStatus),
    expiryTimestamp: getRemainingTimeInSeconds(),
    onExpire: () => handleTimerExpired(),
  });

  const totalElapsedTime = Number(enquiry?.enquiryExpirationTime * 60);
  let timePassed = Number(hours * 3600 + minutes * 60 + seconds);
  const progress = Number((timePassed / totalElapsedTime) * 100);

  return (
    <>
      <StyledTableRow
        key={enquiry?.customerEnquiryId}
        style={{
          cursor: "pointer",
          backgroundColor: isSelectedRow ? "#E9F2F4" : "",
          border: isSelectedRow && "1px solid #333333",
        }}
        onClick={() => handleRowClick(isSelectedRow, enquiry)}
        isSelectedRow={isSelectedRow}
      >
        <TableCell scope="row">{enquiry?.route}</TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          {moment(enquiry?.enquiredOn).format("DD-MM-YYYY")}
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>{enquiry?.material?.name}</TableCell>
        <TableCell>{`${enquiry?.weight / 1000} ${
          enquiry?.weight / 1000 <= 1 ? "Ton" : "Tons"
        }`}</TableCell>

        <TableCell sx={{ textAlign: "center" }}>
          {moment(enquiry?.pickupDate).format("DD-MM-YYYY")}
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          {enquiry?.pickupTime}
        </TableCell>

        <TableCell
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            borderBottom: "0px",
          }}
        >
          <StatusCell status={enquiry?.enquiryStatus} />
        </TableCell>
        <TableCell>
          {timerOpenStatus.includes(enquiry?.enquiryStatus) ? (
            <>
              {`${hours}h:${minutes}m:${seconds}s`}
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                  backgroundColor: "#EAEAEA",
                  borderRadius: "19px",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#F27D29",
                    borderRadius: "20px",
                  },
                }}
              />
            </>
          ) : (
            "-"
          )}
        </TableCell>
      </StyledTableRow>

      <TableCell
        colSpan={8}
        sx={{
          padding: "0px",
        }}
      >
        <Collapse
          in={isSelectedRow}
          sx={{ width: "calc(100vw - 324px)", overflow: "auto" }}
        >
          {isSelectedRow && (
            <>
              {getCollapseView(
                selectedRow,
                enquiry?.transporterId,
                enquiry?.transporterEnquiryId,
                enquiry?.customerEnquiryId,
                enquiry?.enquiryStatus
              )}
            </>
          )}
        </Collapse>
      </TableCell>
    </>
  );
};
