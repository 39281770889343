import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import KYCDocumentsImageUploader from "../../../component/Common/KYCDocumentImageUploader";
import { useState } from "react";
import { LOADING_STATUS } from "../../../constants/constant";
import axios from "axios";
import { BASE_URL } from "../../../api/config";
import Cookies from "js-cookie";
import KycUpdatesAlert from "../../../component/Common/KycUpdatesMessages";

const PAN = (props) => {
  const { expanded, handleChange, userPanDetails, setProfileStatus } = props;
  const [panStatus, setPanStatus] = useState(userPanDetails?.approvalStatus);
  const [reasonOfRejection, setReasonOfRejection] = useState(
    userPanDetails?.reasonOfRejection
  );
  const [panNumber, setPanNumber] = useState(userPanDetails?.documentNumber);
  const [panFrontImage, setPanFrontImage] = useState(userPanDetails?.frontUrl);
  const [panRearImage, setPanRearImage] = useState(userPanDetails?.backUrl);
  const [isPANSubmitted, setIsPANSubmitted] = useState(LOADING_STATUS.INITIAL);

  const [isPANValid, setIsPANValid] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [panImageSizeError, setPanImageSizeError] = useState('');
  const handleOnClose = () => {
    setShowSnackbar(false);
  };
  // Funtion handles pan number input field values on change
  const handlePanNumberInputChange = (event) => {
    if (event.target.value.length === 10) setIsPANValid(true);
    setPanNumber(event.target.value.toUpperCase());
  };

  const approvalStatusMarker = () => {
    if (panStatus === "REJECTED") {
      return <img src="/assets/icons/red-cross.svg" alt="rejected" />;
    }
    if (panStatus === "APPROVED") {
      return <img src="/assets/icons/green-tick.svg" alt="approved" />;
    }
  };
  const handlePANSubmit = async () => {
    if (!panNumber) {
      setIsPANValid(false);
      return;
    }
    if (panNumber?.length <= 9) {
      setIsPANValid(false);
      return;
    }
    if (!/^[A-Z]{5}[0-9]{4}[A-Z]$/.test(panNumber)) {
      setIsPANValid(false);
      return;
    }

    if (panNumber?.length > 10) {
      return;
    }
  
    if (!panFrontImage) {
      setSnackbarMessage({
        msg: "Please Select Front image",
        severity: "error",
      });
      setShowSnackbar(true);
      return;
    }
    // collecting user input for pan details
    const panDocs = {
      documentNumber: panNumber,
      frontUrl: panFrontImage,
    };
    try {
      setIsPANSubmitted(LOADING_STATUS.LOADING);
      const response = await axios.post(
        BASE_URL.concat("transporter/kyc/pan"),
        panDocs,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${Cookies.get("transportertoken")}`,
          },
        }
      );
      setIsPANSubmitted(LOADING_STATUS.LOADED);
      setReasonOfRejection(
        response.data.kycDetails.kycDetails.panDetails?.reasonOfRejection
      );
      setSnackbarMessage({
        msg: "Submitted Successfully.",
        severity: "success",
      });
      setPanStatus(
        response.data.kycDetails.kycDetails?.panDetails?.approvalStatus
      );
      setProfileStatus(response.data.kycDetails?.kycDetails?.profileStatus);
      setPanImageSizeError('')
    } catch (err) {
      setIsPANSubmitted(LOADING_STATUS.FAILED);
      setSnackbarMessage({ msg: "Something went wrong.", severity: "error" });
    }
    setShowSnackbar(true);
  };

  const getPanApprovalTextMessage = () => {
    switch (panStatus) {
      case "REJECTED":
        return (
          <Typography color="red" fontSize="14px" fontWeight="700">
            {" "}
            Rejection Reason : {reasonOfRejection}
          </Typography>
        );
      case "APPROVED":
        return (
          <Typography color="green" fontSize="14px" fontWeight="700">
            Your PAN Details are approved
          </Typography>
        );
      case "PENDING":
        return (
          <Typography color="#333" fontSize="14px" fontWeight="700">
            This document will be reviewed by LoadEazy Team
          </Typography>
        );
      default:
        return (
          <Typography color="#333" fontSize="14px" fontWeight="700">
            Please submit documents to complete KYC.
          </Typography>
        );
    }
  };
  return (
    <>
      <Accordion
        sx={{ boxShadow: "none", borderRadius: ".35rem" }}
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<img src="/assets/icons/expand_less.svg" />}
          aria-controls="panel2bh-content"
          id="panel1bh-header"
          sx={{
            background: "#EAEAEA",
            paddingLeft: "2rem",
            height: "3rem",
            borderRadius: ".25rem",
            "&.Mui-expanded": {
              minHeight: "3rem",
            },
          }}
        >
           <Typography sx={{ flexShrink: 0, fontWeight: 700 }}>
            PAN Details* &nbsp;
          </Typography>
          {approvalStatusMarker()}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            background: "#FFF",
            paddingLeft: "2rem",
            paddingTop: "2rem",
          }}
        >
          <Box maxWidth="sm">
            <InputLabel sx={{ color: "#333", fontSize: "14px" }}>
              PAN Number{" "}
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter Pan Number"
              variant="outlined"
              value={panNumber}
              onChange={handlePanNumberInputChange}
              error={!isPANValid || (!isPANValid && panNumber?.length !== 10)}
              helperText={
                !isPANValid || (!isPANValid && panNumber?.length !== 10)
                  ? "Invalid PAN Number*"
                  : ""
              }
              inputProps={{
                maxLength: 10,
                style: { color: "#333", fontWeight: 700 },
              }}
            />

            <Stack direction="row" justifyContent="space-between" mt={3}>
              <KYCDocumentsImageUploader
                documentType="pan"
                side="front"
                setDocumentImage={setPanFrontImage}
                displayImage={panFrontImage}
                imageSizeError={panImageSizeError}
                setImageSizeError={setPanImageSizeError}
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mt={2}
            >
              {getPanApprovalTextMessage()}

              {userPanDetails?.approvalStatus !== "APPROVED" && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePANSubmit}
                  disabled={isPANSubmitted === "loading"}
                >
                  Submit
                </Button>
              )}
            </Stack>
          </Box>
        </AccordionDetails>
      </Accordion>
      {showSnackbar && (
        <KycUpdatesAlert
          handleOnClose={handleOnClose}
          open={showSnackbar}
          message={snackbarMessage.msg}
          severity={snackbarMessage.severity}
        />
      )}
    </>
  );
};
export default PAN;
