import React from "react";

import { Box } from "@mui/material";
import { useTimer } from "react-timer-hook";

export default function AdminExpiryTimerCell(props) {
  const timerAutoStartStatus = ["UNQUOTED", "QUOTED"];

  const getRemainingTimeInSeconds = () => {
    const currentTime = new Date().getTime();
    const expiryTime = new Date(props?.enquiryExpirationTimestamp).getTime();

    const timeRemaining = Math.max(0, expiryTime - currentTime);
    const remainingSeconds = Math.floor(timeRemaining / 1000);
    const time = new Date();

    time.setSeconds(remainingSeconds);
    return time;
  };

  const { seconds, minutes } = useTimer({
    autoStart: timerAutoStartStatus.includes(props?.status),
    expiryTimestamp: getRemainingTimeInSeconds(),
  });

  return (
    <Box>
      {minutes === 0 && seconds === 0 ? (
        "-"
      ) : (
        <Box>
          {minutes}m {seconds}s
        </Box>
      )}
    </Box>
  );
}
