// Transporter Layout after user has logged in .
import { Box } from "@mui/system";
import Sidebar from "../component/Sidebar/Sidebar";
import { Outlet } from "react-router";
import { AppBar, CssBaseline, Stack, Toolbar, Typography } from "@mui/material";
import { adminRoutes } from "../routes/adminRoutes";
import { useEffect, useState } from "react";
import { messaging, onMessageListener } from "../firebase";
import { getToken } from "@firebase/messaging";
import NotificationPopup from "../component/NotificationPopup";
import { updateAdminFCMToken } from "../api/fcmToken";
import Cookies from "js-cookie";
import {Navigate,useNavigate,useLocation} from "react-router-dom"
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';

const AdminLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isTokenFound, setTokenFound] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isSnackbarOpen, setisSnackbarOpen] = useState(false);
  const adminFcmToken = sessionStorage.getItem("adminfcmToken");
  const adminToken = Cookies.get('admintoken');
  onMessageListener()
    .then((payload) => {
      // whenever new notification arrives ,store it in state.
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      // turn on Notification Popup
      setisSnackbarOpen(true);
    })
    .catch((err) => {
      console.log("failed: ", err);
    });

  const generateFCMToken = async () => {
    try {
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      });
      return token;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const updateFCMToken = async () => {
    const fcmtoken = await  generateFCMToken();
    if (fcmtoken) {
      // add fcm token to user document
      await updateAdminFCMToken(fcmtoken);
      sessionStorage.setItem("adminfcmToken", JSON.stringify(fcmtoken));
      setTokenFound(true);
    }
  };

  async function requestUserPermission() {
    if (adminFcmToken) {
      return;
    }
    if (Notification.permission === "denied") {
      alert(
        "Notifications are blocked. Please enable them in your browser settings."
      );
    } else if (Notification.permission === "default") {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        console.log("Notification permission granted.");
        // You can now send notifications
        updateFCMToken();
      }
    } else if (Notification.permission === "granted") {
      updateFCMToken();
    }
  }
  useEffect(() => {
    requestUserPermission();
  }, []);
  if(!adminToken || adminToken === undefined) return <Navigate to="/" replace />;
  return (
    <Box sx={{ height: "100vh" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          boxShadow: "none",
          background: "#fff",
          borderBottom: "1px solid #adadad",
        }}
      >
        <Toolbar sx={{ height: "6rem" }}>
          <Stack direction="row" alignItems="center" spacing="1.25rem">
            <img
              src="/assets/icons/LZ-logo-face.svg"
              style={{ marginBottom: ".35rem" }}
              alt="logoface"
            />
            <img src="/assets/icons/LZ-logo.svg" alt="logo" />
          </Stack>

          <Stack direction="row" alignItems="center" ml="auto" gap={4}>
          <NotificationsActiveOutlinedIcon
              sx={{
                color: "#333",
                background: location.pathname.includes('admin/app/notification') ? "#DDE7EF" : "#fff",
                cursor: "pointer",
                borderRadius:1,
                fontSize:26
              }}
              onClick={() => {
                navigate("notifications")
              }}
            />
            <Box>
              <Typography color="#0C7F44" fontWeight={700}>
                Admin
              </Typography>
            </Box>
          </Stack>
        </Toolbar>
      </AppBar>

      <Box sx={{ display: "flex", height: "100vh" }}>
        {!location.pathname.endsWith("admin/app/notifications") && <Sidebar routes={adminRoutes} />}
        <Box mt="6rem" sx={{ flexGrow: 1 }}>
          <Outlet />
        </Box>
      </Box>
      <NotificationPopup
        isOpen={isSnackbarOpen}
        header={notification.title}
        message={notification.body}
        setisSnackbarOpen={setisSnackbarOpen}
      />
    </Box>
  );
};
export default AdminLayout;