import styled from "@emotion/styled";
import { Box, Card, CardContent, IconButton, Typography } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { uploadimage } from "../../services/uploadImageToS3";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const ImageUploadCard = styled(Card)(({ isLargeImage }) => ({
  width: 290,
  height: 180,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  boxShadow: "none",
  border: `2px solid ${isLargeImage ? "#c6c6c6" : "#c6c6c6"}`,
}));

const ImageUploadContent = styled(CardContent)({
  width: "100%",
  height: "100%",
});

const Image = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const Input = styled("input")({
  display: "none",
});

const KYCDocumentsImageUploader = (props) => {
  const location = useLocation();
  const portal = location.pathname.includes("customer")
    ? "customer"
    : "transporterId";

  const userId = useSelector((state) => state[portal].id)
  const { setDocumentImage, displayImage, imageSizeError, setImageSizeError } =
    props;
  const [image, setImage] = useState(displayImage);
  const [fileSizeError, setFileSizeError] = useState(imageSizeError);
  const imageInputRef = useRef(null);

  const uploadOnS3 = async (file) => {
    return await uploadimage(`public/kyc/${portal}/${userId}`, file);
  };
  const processFile = async (file) => {
    if (file) {
      const docImage = await uploadOnS3(file);
      setDocumentImage(docImage);

      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2097152) {
        setImageSizeError(`Please choose an image with size smaller than 2MB.`);
        return;
      } else {
        setImageSizeError("");
        processFile(file);
      }
    }
  };

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      processFile(event.dataTransfer.files[0]);
    }
  }, []);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleEditDocumentImage = (event) => {
    imageInputRef.current.click();
  };

  return (
    <Box onDrop={handleDrop} onDragOver={handleDragOver}>
      <ImageUploadCard component="label" isLargeImage={false}>
        <Input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          ref={imageInputRef}
        />
        {image ? (
          <Image src={image} alt="Uploaded" />
        ) : (
          <ImageUploadContent>
            <Typography
              sx={{
                color: "#ADADAD",
                fontSize: "14px",
                textTransform: "capitalize",
              }}
            >
              {props.side} Side
            </Typography>

            <Box sx={{ textAlign: "center" }}>
              <IconButton component="span">
                <img
                  src="/assets/icons/upload_image_plus.svg"
                  alt="uploadimage"
                  style={{ width: "64px", height: "64px" }}
                />
              </IconButton>
            </Box>
            <Typography color="#AEAEAE" fontSize={12} textAlign="center">
              Please ensure that the selected file is in either 'JPG', 'JPEG',
              or 'PNG' format, with a maximum size of 2MB.
            </Typography>
          </ImageUploadContent>
        )}
      </ImageUploadCard>
      {imageSizeError && (
        <Typography pl={1} color="red" fontSize={12}>
          {imageSizeError}
        </Typography>
      )}
      {image && (
        <IconButton
          component="p"
          sx={{ fontSize: "12px", borderRadius: ".25rem", padding: "0 10px" }}
          onClick={handleEditDocumentImage}
        >
          Edit
        </IconButton>
      )}
    </Box>
  );
};
export default KYCDocumentsImageUploader;
