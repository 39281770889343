import React from "react";

const ToggleButton = ({ toggledState, onToggle }) => {
  return (
    <div
      onClick={() => onToggle(!toggledState)}
      style={{
        height: "19px",
        width: "37.3px",
        background: toggledState ? "#6CC329" : "#C6C6C6",
        borderRadius: "9.5px",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "2%",
          left: toggledState ? "" : "2%",
          right: toggledState ? "2%" : "",
          background: "white",
          width: "16px",
          height: "16px",
          borderRadius: "50%",
          margin: "1px",
        }}
      ></div>
    </div>
  );
};

export default ToggleButton;
