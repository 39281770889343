import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsRenderer,
  MarkerF,
} from "@react-google-maps/api";
import LoadScriptOnlyIfNeeded from "../../../utils/LoadscriptOnlyIfNeeded";
import { getTrackingDetailsById } from "../../../api/Transporter/mapTracking";

const containerStyle = {
  width: "100%",
  height: "800px",
};

export default function InTransitMapTracking(props) {
  const { trips, entityId } = props;
  const currentTrip = trips?.find((trip) => trip.id === entityId && trip);
  const inTransitTripId = currentTrip.tripId;

  const mapRef = useRef(null);
  const [tripData, setTripData] = useState({});
  const [currentLocation, setCurrentLocation] = useState(null);
  const [directionsToCurrent, setDirectionsToCurrent] = useState(null);
  const [directionsToDestination, setDirectionsToDestination] = useState(null);

  const getTrackingDetails = async () => {
    const res = await getTrackingDetailsById(inTransitTripId);
    if (res?.status === 200) {
      const tripDetails = res.data.data.vehicleLocation;
      const tripDetailsData = {
        tripId: tripDetails?.tripId,
        driverId: tripDetails?.driverId,
        currentLocation: {
          lat: tripDetails?.currentLocation?.latitude,
          lng: tripDetails?.currentLocation?.longitude,
        },
        distanceCovered: tripDetails?.distanceCovered,
        distanceLeft: tripDetails?.distanceLeft,
        startingPath: {
          name: tripDetails?.startingPath?.name,
          lat: tripDetails?.startingPath?.latitude,
          lng: tripDetails?.startingPath?.longitude,
        },
        destinationPath: {
          name: tripDetails?.destinationPath?.name,
          lat: tripDetails?.destinationPath?.latitude,
          lng: tripDetails?.destinationPath?.longitude,
        },
      };
      setTripData(tripDetailsData);
    }
  };

  useEffect(() => {
    getTrackingDetails();
  }, [inTransitTripId]);

  useEffect(() => {
    setCurrentLocation(tripData?.currentLocation);
  }, [tripData]);

  useEffect(() => {
    let intervalId;
    if (currentLocation) {
      intervalId = setInterval(async () => {
        const res = await getTrackingDetailsById(inTransitTripId);
        if (res.status === 200) {
          setCurrentLocation({
            lat: res.data.data.vehicleLocation.currentLocation.latitude,
            lng: res.data.data.vehicleLocation.currentLocation.longitude,
          });
        }
      }, 300000);
    }

    return () => intervalId && clearInterval(intervalId);
  }, [currentLocation]);

  const handleScriptLoad = () => {
    if (!tripData.startingPath || !tripData.destinationPath || !currentLocation)
      return;

    const directionsService = new window.google.maps.DirectionsService();

    // Calculate route from starting point to current location
    directionsService.route(
      {
        origin: tripData.startingPath,
        destination: currentLocation,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirectionsToCurrent(result);
        } else {
          console.error(`Error fetching directions to current: ${status}`);
        }
      }
    );

    // Calculate route from current location to destination
    directionsService.route(
      {
        origin: currentLocation,
        destination: tripData.destinationPath,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirectionsToDestination(result);
        } else {
          console.error(`Error fetching directions to destination: ${status}`);
        }
      }
    );
  };

  if (!currentLocation) return;

  return (
    <LoadScriptOnlyIfNeeded
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      onLoad={() => handleScriptLoad()}
    >
      <GoogleMap
        ref={mapRef}
        mapContainerStyle={containerStyle}
        center={currentLocation}
        zoom={6}
      >
        {directionsToCurrent && (
          <DirectionsRenderer
            directions={directionsToCurrent}
            options={{
              suppressMarkers: true,
              polylineOptions: { strokeColor: "gray" },
            }}
          />
        )}
        {directionsToDestination && (
          <DirectionsRenderer
            directions={directionsToDestination}
            options={{
              suppressMarkers: true,
              polylineOptions: { strokeColor: "green" },
            }}
          />
        )}

        <MarkerF
          position={tripData.startingPath}
          icon={{
            url: "/assets/icons/circle.svg",
            fillColor: "#000",
          }}
        />

        <MarkerF
          position={tripData.currentLocation}
          icon={{
            url: "/assets/icons/truck.svg",
            fillColor: "#000",
          }}
        />
        <MarkerF position={tripData.destinationPath} />
        {currentLocation && (
          <Marker position={currentLocation} label={"Current"} />
        )}
        <div
          style={{
            position: "absolute",
            top: 10,
            left: 200,
            backgroundColor: "white",
            padding: 10,
          }}
        >
          <div>Distance Covered: {tripData.distanceCovered}</div>
          <div>Distance Left: {tripData.distanceLeft}</div>
        </div>
      </GoogleMap>
    </LoadScriptOnlyIfNeeded>
  );
}
