import React from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";

const StyledHeading = styled(Box)(
  ({ fontWeight, width, fontSize, color, textTransform }) => ({
    fontSize: fontSize || "12px",
    fontWeight: fontWeight || 700,
    textTransform: textTransform || "capitalize",
    width: width || "",
    color: color || "",
  })
);

const BoxContainer = styled(Box)(({ marginLeft, padding }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  marginLeft: marginLeft || "32px",
  padding: padding || "0px",
}));

export default function NameDescription(props) {
  return (
    <BoxContainer marginLeft={props.marginLeft} padding={props.padding}>
      <StyledHeading>{props.name}</StyledHeading>
      <StyledHeading
        fontWeight="400"
        width={props.width}
        fontSize={props.fontSize}
        color={props.color}
        textTransform={props.textTransform}
      >
        {props.description}
      </StyledHeading>
    </BoxContainer>
  );
}
