import React, { useEffect, useState } from "react";
import CityDetailsForm from "../../../../component/Transporter/Accounts/CitiesAndRoutes/CityDetailsForm";
import TransporterRemoveCityModal from "../../../../component/Transporter/Accounts/CitiesAndRoutes/TransporterRemoveCityModal";
import { Box, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import useToast from "../../../../hooks/useToast";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import AddCity from "../../../../component/Transporter/Accounts/CitiesAndRoutes/AddCity";
import {
  createNewCity,
  deleteCity,
  editCity,
  ftueNewCity,
  getAllCities,
} from "../../../../api/Transporter/operationalCities";

const LoadingBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "80vh",
  width: "100%",
}));

export default function CityAndRoutes() {
  const { toastData, handleOpenToast, handleCloseToast, ToastMessage } =
    useToast();

  // Controls the visibility of a form
  const [showForm, setShowForm] = useState(false);
  // Stores a list of cities
  const [cities, setCities] = useState([]);
  // Tracks the index of the current city
  const [currentIndex, setCurrentIndex] = useState(null);
  // Manages the visibility of the delete city modal
  const [isDeleteCityModalOpen, setIsDeleteCityModalOpen] = useState(false);
  // Represents the state of fetching cities
  const [isCitiesDetailsFetched, setIsCitiesDetailsFetched] =
    useState("LOADING");

  const navigate = useNavigate();

  /**
   * Handles the initiation of editing city details for a specific index.
   * @param {number} index - The index of the city details to edit.
   * @returns {void}
   */
  const handleEditCityDetails = (index) => {
    setCurrentIndex(index);
    setShowForm(true);
  };

  /**
   * Handles the creation of new city details or initializes the creation form.
   * Sets the currentIndex to -1 (indicating a new city) and displays the creation form.
   */
  const handleCreateCityDetails = () => {
    setCurrentIndex(-1);
    setShowForm(true);
  };

  // Function to open the delete city confirmation modal.
  const openDeleteCityConfirmationModal = () => {
    setIsDeleteCityModalOpen(true);
  };

  /**
   * Closes the modal used for city removal.
   */
  const closeRemoveCityModal = () => {
    setIsDeleteCityModalOpen(false);
  };

  // Function to navigate to the Know Your Customer (KYC) page for transporter accounts
  const handleNextButtonClick = () => {
    navigate("/transporter/accounts/kyc");
  };

  // Function to fetch all city details.
  const fetchAllCityDetails = async () => {
    try {
      setIsCitiesDetailsFetched("LOADED");
      const ftueStatus = localStorage.getItem("ftueStatus");

      if (ftueStatus === "IN_PROGRESSS") {
        handleOpenToast("Please add a city", "warning");
        return;
      }

      const response = await getAllCities();

      if (response.status === 200) {
        const data = response?.data?.data?.operationalCities;
        setCities(data || []);
      }
    } catch (error) {
      handleOpenToast(`City fetching failed.`, "error");
      setIsCitiesDetailsFetched("FAILED");
    }
  };

  // Function to create a new city.
  const createCity = async (body) => {
    try {
      const cityExist = cities.some((city) => city.city === body.city);
      if (cityExist) {
        setShowForm(false);
        handleOpenToast(
          "City already exists. Please add another city.",
          "warning"
        );
        return;
      }

      const ftueStatus = localStorage.getItem("ftueStatus");

      if (ftueStatus === "IN_PROGRESS") {
        await ftueNewCity(body);
      } else {
        await createNewCity(body);
      }

      handleOpenToast(`Added a new city: ${body.city}.`, "success");
      fetchAllCityDetails();
      setShowForm(false);
    } catch (error) {
      handleOpenToast(`Failed to add a new city: ${body.city}.`, "error");
    }
  };

  // Function to edit city data.
  const editCityData = async (body) => {
    try {
      await editCity(body.city, body);
      fetchAllCityDetails();
      setShowForm(false);
      handleOpenToast(`City edited: ${body.city}.`, "success");
    } catch (error) {
      handleOpenToast(`Failed to edit a city: ${body.city}.`, "error");
    }
  };

  // Function to remove a city.
  const confirmRemoveCity = async () => {
    const cityName = cities[currentIndex]?.city;
    try {
      await deleteCity(cityName);

      closeRemoveCityModal();
      fetchAllCityDetails();
      setShowForm(false);
      handleOpenToast(`City deleted:: ${cityName}.`, "success");
    } catch (error) {
      handleOpenToast(`Failed to delete city: ${cityName}.`, "error");
    }
  };

  // Handle back button click on add/edit city page.
  const handleBackIconClick = () => {
    setShowForm(false);
    setCurrentIndex(-1);
  };

  useEffect(() => {
    fetchAllCityDetails();
  }, []);

  const ftueStatus = localStorage.getItem("ftueStatus");

  switch (isCitiesDetailsFetched) {
    case "LOADING":
      return (
        <LoadingBox>
          <CircularProgress />
        </LoadingBox>
      );
    case "LOADED":
      return (
        <Box sx={{ height: "calc(100vh - 96px)" }}>
          <ToastMessage />
          {!showForm ? (
            <Box height="100%" display="flex" flexDirection="column">
              <Box height="50%" overflow="auto">
                <AddCity
                  handleCreateCityDetails={handleCreateCityDetails}
                  cities={cities}
                  handleEditCityDetails={handleEditCityDetails}
                />
              </Box>

              <Box height="50%" textAlign="center" mt="20px">
                {ftueStatus === "IN_PROGRESS" && (
                  <Box>
                    {cities.length ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNextButtonClick}
                      >
                        Next
                      </Button>
                    ) : (
                      ""
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <CityDetailsForm
              selectedCity={currentIndex >= 0 ? cities[currentIndex] : null}
              createCity={createCity}
              editCityData={editCityData}
              openDeleteCityConfirmationModal={openDeleteCityConfirmationModal}
              handleBackIconClick={handleBackIconClick}
              cities={cities}
            />
          )}
          <TransporterRemoveCityModal
            confirmRemoveCity={confirmRemoveCity}
            closeRemoveCityModal={closeRemoveCityModal}
            cityName={cities[currentIndex]?.city}
            isDeleteCityModalOpen={isDeleteCityModalOpen}
          />
        </Box>
      );
    case "FAILED":
      return (
        <React.Fragment>
          <p>Couldn't Fetch Cities Details</p>
          <button onClick={() => fetchAllCityDetails()}>Retry</button>
        </React.Fragment>
      );
    default:
      return <h1>something went wrong!</h1>;
  }
}
