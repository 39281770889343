import { Box, Typography } from "@mui/material";
const colors = {
  main: "#333333",
};
const Privacypolicy = () => {
  return (
    <Box p={5} textAlign="center" maxWidth="lg" m="auto">
      <Typography
        component="h1"
        sx={{
          fontSize: "1rem",
          fontWeight: 700,
          color: colors.main,
        }}
      >
        LOADEAZY PRIVACY POLICY
      </Typography>
      <Box textAlign="left" mt={4}>
        <Typography component="p" color={colors.main}>
          This policy on privacy explains how LoadEazy uses and safeguards any
          information you provide to us as Customers, Transport Partners, and
          Driver Partners when using this website or mobile application. This
          Privacy Policy, along with any updated rules, is an electronic record
          between you and LoadEazy under by the Information Technology Act of
          2000.
        </Typography>
        <Typography component="p" color={colors.main} mt={3}>
          Juggernaut Ventures (the "Company", "we", "LoadEazy", or "us") values
          your privacy.
        </Typography>
        <Typography component="p" color={colors.main} mt={3}>
          This Privacy Policy ("Policy") describes the policies and procedures
          on the collection, use, disclosure and protection of your information
          when you use our website located at{" "}
          <a target="_blank" href="https://loadeazy.com">
            https://loadeazy.com
          </a>
          , or the LOADEAZY mobile application named LoadEazy Driver Partner,
          without limitation, to those including customers/service recipients
          and drivers.
        </Typography>
        <Typography component="p" color={colors.main} mt={3}>
          The terms "you" and "your" refer to the user of the LoadEazy Apps. The
          term "Services" refers to any services offered by LoadEazy/You whether
          on the LoadEazy Apps or otherwise. This Policy is a part of and
          incorporated within, and is to be read along with, the applicable
          Terms of Service and the Driver Partner Terms and Conditions based on
          the user of LoadEazy.
        </Typography>
        <Typography component="p" color={colors.main} mt={3}>
          We at LoadEazy are dedicated to protecting your privacy. You may be
          certain that any information we request from you on this website that
          allows us to identify you will only be used in compliance with our
          privacy statement.
        </Typography>
        <Typography component="p" color={colors.main} mt={3}>
          You agree and consent to the collection, transfer, use, storage,
          disclosure, and sharing of your information as specified and collected
          by us in compliance with this Policy by using the LoadEazy Apps and
          Services. Please do not use or access the LoadEazy Apps if you
          disagree with the Policy.
        </Typography>
        <Typography component="p" color={colors.main} mt={3}>
          LoadEazy has the right to modify this policy at any moment by
          modifying this page. This policy's current version will take effect on
          December 1, 2023. Users are encouraged to review this page
          periodically for any changes.
        </Typography>
      </Box>
      {/* WHAT WE COLLECT */}
      <Box textAlign="left" mt={3}>
        <Typography fontWeight={700}>WHAT WE COLLECT</Typography>
        <Box pl={2} mt={3}>
          <Typography>The following details could be gathered:</Typography>
          <Typography component="div" mt={1}>
            <ul
              style={{
                listStyle: "circle",
                color: colors.main,
                paddingLeft: "24px",
              }}
            >
              <li>Full Name</li>
              <li>Contact details, such as an email address.</li>
              <li>
                Demographic data, including address, phone number, postcode,
                preferences.
              </li>
              <li>
                When you use our services, we may gather and keep information
                about you, including but not limited to your phone number,
                address, email, billing details, invoices, and other details, in
                order to fulfil your requests and automatically fill out forms
                for future transactions.
              </li>
              <li>
                Additional data pertinent to surveys and/or offers for
                customers.
              </li>
              <li>Information on installing, uninstalling.</li>
              <li>
                When a Driver Partner signs up to use our services, we may
                gather information about your location, your profile picture,
                copies of your government-issued identification documents and
                other details (KYC), information about your vehicle, including
                insurance, a pollution certificate, bank account information,
                utility bills, registration certificates, driving licences, and
                other documents that attest to the vehicle's suitability for use
                with the LoadEazy Apps. We may also collect call and SMS details
                that we may share with our customers while providing the
                Services.
              </li>
              <li>
                We gather information from the Driver Partner's identification
                verification and background check. This could contain details
                about your driving past, criminal history, and employment
                eligibility (where allowed by law). On behalf of the Company, an
                approved vendor may gather this data.
              </li>
              <li>
                {" "}
                If you register as a Driver Partner, activate features that
                provide LoadEazy access to other apps on your device in an
                effort to stop client fraud. If the Driver Partner wants to be a
                part of the referral program, we will be able to access the
                phone's contact list if the Driver Partner gives us permission
                to share the chosen mobile number. We have no intention of
                disclosing these particular mobile numbers to outside parties.
              </li>
              <li>
                You can ask to check the information you submitted at any time,
                and LoadEazy will make sure that any sensitive personal data or
                personal information that is discovered to be incorrect or
                incomplete is updated or changed as soon as practical.
              </li>
              <li>
                LoadEazy will not keep sensitive personal information or data
                longer than is necessary to fulfil the uses for which it is
                permitted by law.
              </li>
            </ul>
          </Typography>
        </Box>
      </Box>
      {/* OUR ACTIONS AFTER GATHERING INFORMATION */}
      <Box textAlign="left" mt={3}>
        <Typography fontWeight={700}>
          OUR ACTIONS AFTER GATHERING INFORMATION
        </Typography>
        <Typography mt={3} color={colors.main}>
          In order to better understand your needs and serve you, we need this
          information, especially for the following reasons:
        </Typography>
        <Typography component="div" mt={3} color={colors.main}>
          <ul style={{ paddingLeft: "20px", color: colors.main }}>
            <li>
              Maintaining Internal Records
              <p style={{ marginTop: "10px" }}>
                We might make use of the data to enhance our offerings. We could
                make use of this data to:
              </p>
              <ul style={{ paddingLeft: "30px", marginTop: "10px" }}>
                <li>
                  Allow you to use Services on the LoadEazy Apps and to carry
                  out our obligations arising from any contracts between you and
                  us
                </li>
                <li>
                  Allow you to undertake or initiate any transactions on the
                  LoadEazy Apps
                </li>
                <li>
                  To inform your usage of the LoadEazy Apps and to manage your
                  account with LoadEazy Apps
                </li>
                <li>
                  To process the payments with respect to the transactions which
                  you may avail on the LoadEazy Apps and to perform ancillary
                  Services
                </li>
                <li>
                  To respond to your comments. reviews and questions, in order
                  to provide customer support and better Services
                </li>
                <li>
                  To communicate important notices and changes to the Services
                  provided on the LoadEazy Apps
                </li>
                <li>
                  To track the order status, processing, and delivery of
                  Services as per your applicability
                </li>
                <li>For any other purposes with your consent.</li>
              </ul>
              <p style={{ marginTop: "10px" }}>
                Using the email address and mobile number you have supplied, we
                may occasionally send you promotional emails, SMS, and voice
                calls about new goods, special deals, or other information we
                think you might find interesting.
              </p>
              <p style={{ marginTop: "10px" }}>
                We could occasionally use your information to get in touch with
                you for market research. We could get in touch with you by mail,
                fax, phone, SMS, or email. We might utilise the data to tailor
                the website to your preferences.
              </p>
              <p style={{ marginTop: "10px" }}>
                In line with the Telecom Commercial Communications Customer
                Preference Regulations, 2018 or as otherwise stated, you may
                expressly choose not to accept the aforementioned. If you would
                like to withdraw from the same, send us an email at &nbsp;
                <span style={{ color: "blue" }}>jvgroup22@gmail.com.</span>
              </p>
            </li>
            <li style={{ marginTop: "10px" }}>
              Security
              <p style={{ marginTop: "10px" }}>
                We are dedicated to protecting the security of your information.
                We have implemented appropriate physical, electronic, and
                managerial procedures in compliance with the Information
                Technology (Reasonable security practices and procedures and
                sensitive personal data or information) Rules, 2011 ("IT RSP
                Rules") to protect and secure the data we collect online. This
                is done to prevent unauthorised access or disclosure. We only
                keep the information we gather from you for as long as it is
                reasonably necessary for us to provide our services, or for as
                long as may be necessary to comply with Indian legal
                requirements. You agree to maintain the strict confidentiality
                of all information you get via the LoadEazy Apps, including your
                personal information.
              </p>
            </li>
            <li style={{ marginTop: "10px" }}>
              Discolsure
              <p style={{ marginTop: "10px" }}>
                We may disclose to third party services certain personally
                identifiable information listed below:
              </p>
              <ul style={{ marginTop: "10px", paddingLeft: "30px" }}>
                <li>
                  Information you provide us such as name, email, mobile phone
                  number, language.
                </li>
                <li>
                  Device information, location, and network carrier are among
                  the details we get when you use and access our service. If
                  users allow us to, we get exact or approximate location data
                  from their mobile devices in the foreground when they use the
                  LoadEazy Apps. This information is gathered by LoadEazy while
                  the app is open in the front of the user's screen and from the
                  moment a ride or delivery is requested until it is completed.
                  We utilise this information to improve pick-up locations,
                  activate safety features, stop and identify fraud, and
                  generally make your experience with our apps better. Even if
                  the client hasn't given us permission to gather location data
                  from their device, LoadEazy gathers the Driver Partner's
                  location data and connects it with the customer's account.
                  This makes it possible for us to provide services to our
                  clients, such as customer support and receipt creation.
                </li>
              </ul>
              <p style={{ marginTop: "10px" }}>
                This information is shared with third party service providers
                under contracts entered into with them so that we can:
              </p>
              <ul style={{ marginTop: "10px", paddingLeft: "30px" }}>
                <li>
                  Customise the LoadEazy Apps to your preferences for the best
                  possible experience.
                </li>
                <li>Administer behavioural analytics.</li>
                <li>
                  Information sharing for the purpose of supporting our services
                  with our vendors, consultants, marketing partners, research
                  firms, and other service providers or business partners, such
                  as companies that handle payments.
                </li>
                <li>
                  {" "}
                  In order to conduct academic research, we might disclose your
                  information to our academic partners.
                </li>
                <li>
                  If you are a Driver Partner with LOADEAZY, we may give other
                  users access to your name, contact information, and/or profile
                  picture (if applicable) in order to deliver the Services to
                  them.
                </li>
                <li>
                  When required by applicable laws, in good faith, or in
                  accordance with any orders or processes, we may disclose this
                  information to governmental agencies, law enforcement
                  agencies, or other businesses that are supporting us. We may
                  also use this information to detect and prevent actual or
                  potential instances of identity theft, fraud, abuse of
                  services, and other illegal acts.
                </li>
              </ul>
            </li>
          </ul>
        </Typography>
      </Box>
      {/* PROHIBITED ACTIVITEIS */}
      <Box textAlign="left" mt={3}>
        <Typography fontWeight={700}>PROHIBITED ACTIVITEIS</Typography>

        <Typography mt={3} color={colors.main}>
          The content Technology (Intermediary Guidelines and Digital Media
          Ethics Code) Rules, 2021, specifically Rule 3(1)(b), state that you
          are not allowed to host, display, upload, alter, publish, transmit,
          save, update, or share any content that:
        </Typography>
        <ul
          style={{
            paddingLeft: "30px",
            color: colors.main,
            listStyle: "circle",
          }}
        >
          <li>
            Is owned by someone else and over which the user has no legal claim
          </li>
          <li>
            Is libellous, racially or ethnically objectionable, obscene,
            pornographic, paedophilic, invasive of another person's privacy,
            including bodily privacy; defamatory, libellous, libellous, relating
            to or encouraging money laundering or gambling; or otherwise,
            inconsistent with or contrary to the laws in effect.
          </li>
          <li>Is detrimental to children</li>
          <li>
            Violates any proprietary rights such as patents, trademarks,
            copyrights, or others.
          </li>
          <li>Disobeys any current laws in effect</li>
          <li>
            Misleads or deceives the recipient about the message's origin or
            purposefully and knowingly conveys any information that is obviously
            false or misleading but that could be interpreted as fact.
          </li>
          <li>Impersonates another person</li>
          <li>
            Poses a threat to India's unity, integrity, defence, security, or
            sovereignty; friendly relations with other states; public order;
            incites the commission of any crime that is punishable by law;
            obstructs the investigation of any crime; or disparages another
            country.
          </li>
          <li>
            Is blatantly false and untrue, and is written or published in any
            form with the intent to mislead or harass a person, entity, or
            agency for financial gain, or to cause any harm to any person.
            contains software viruses or any other computer code, file, or
            program designed to interrupt, destroy, or limit the functionality
            of any computer resource.
          </li>
        </ul>
        <p style={{ color: colors.main, marginTop: "10px" }}>
          We reserve the right to immediately revoke users' access or usage
          rights to computer resources or the LoadEazy Apps, remove any
          non-compliant information, or both, in the event that they violate our
          policies, rules, or terms of service.
        </p>
      </Box>
      {/* DATA WITHDRAWAL OR NON-PROVISION */}
      <Box textAlign="left" mt={3}>
        <Typography fontWeight={700}>
          DATA WITHDRAWAL OR NON-PROVISION
        </Typography>
        <Typography color={colors.main} mt={3}>
          Please be aware that you have the choice not to give the requested
          data or information. You will always have the choice to revoke your
          prior consent to LoadEazy by getting in touch with us, whether you're
          using the services or not. The body corporate must receive written
          notice of this consent withdrawal.
        </Typography>
      </Box>
      {/* RETENTION OF INFORMATION */}
      <Box textAlign="left" mt={3}>
        <Typography fontWeight={700}>RETENTION OF INFORMATION</Typography>
        <Typography color={colors.main} mt={3}>
          Your provided information, save and except upon withdrawal or
          termination, shall be retained in locations outside the direct control
          of the Company (for instance, on servers or databases co-locates with
          hosting providers). Upon receiving a deletion request from you, we
          commit to deleting the information within a reasonable timeframe, and
          at the latest, within 90 days. However, specific portions of the
          information may be retained for periods stipulated by Applicable Law.
          Notwithstanding the above, the Company may keep data post-account
          deletion for reasons including, but not limited to:
        </Typography>
        <ul
          style={{
            paddingLeft: "30px",
            listStyle: "circle",
            color: colors.main,
            marginTop: "10px",
          }}
        >
          <li>
            Unresolved issues related to your account, claims, or disputes.
          </li>
          <li> Compliance with applicable laws.</li>
          <li>Retention in aggregated or anonymized form.</li>
          <li>
            Necessity for legitimate business interests such as fraud prevention
            and enhancing user safety and security.
          </li>
        </ul>
      </Box>
      {/* USAGE OF COOKIES */}
      <Box textAlign="left" mt={3}>
        <Typography fontWeight={700}>USAGE OF COOKIES</Typography>
        <Typography mt={3} color={colors.main}>
          A cookie is a little file that requests authorization to be stored on
          the hard drive of your computer. Once you accept, the file is added,
          and the cookie informs you when you visit a specific website or
          assists in the analysis of web traffic. Cookies enable personalised
          responses from web applications. By collecting and storing information
          about your preferences, the web application can adjust its
          functionality to your needs, preferences, and likes and dislikes.
        </Typography>
        <Typography mt={3} color={colors.main}>
          Cookies from traffic logs are used by us to determine which pages are
          being used. This aids in the analysis of website traffic data and
          helps us make improvements to our website to better meet the needs of
          users. This data is only used for statistical analysis; after that, it
          is deleted from the system.
        </Typography>
        <Typography mt={3} color={colors.main}>
          In general, cookies enable us to monitor which pages you find useful
          and which you do not, which helps us provide you with a better
          experience on our website. Other than the information you choose to
          share with us, a cookie in no way allows us access to your computer or
          any personal information about you.
        </Typography>
        <Typography mt={3} color={colors.main}>
          You have the option to accept or reject cookies. Although the majority
          of web browsers accept cookies by default, you can typically change
          your browser's settings to reject cookies if you'd rather. This might
          make it impossible for you to use the LoadEazy Apps and/or the website
          to their fullest.
        </Typography>
      </Box>
      {/* LINK TO EXTERNAL WEBSITES */}
      <Box textAlign="left" mt={3}>
        <Typography fontWeight={700}>LINK TO EXTERNAL WEBSITES</Typography>
        <Typography mt={3} color={colors.main}>
          There may be links on our website to worthwhile external websites. You
          should be aware, though, that once you have used these links to leave
          our website, we have no control over the other website—which might
          also have a banner advertisement on it. Except as stated in this
          privacy policy or when you submit your information, we never sell,
          share, rent, or trade the data we've collected about you. As a result,
          we cannot guarantee the security or privacy of any information you
          submit when visiting these websites, and these websites are not
          covered by this privacy statement. You should proceed with caution,
          review the privacy statement that applies to the specific website, and
          get in touch with a third party directly.
        </Typography>
        <Typography mt={3} color={colors.main}>
          Unless we obtain your consent or are compelled by law to do so, we
          will not divulge or share your personal information with outside
          parties. If you indicate that you would like this to happen, we may
          use your personal information to send you promotional materials about
          other companies that we believe you might find interesting.
        </Typography>
        <Typography mt={3} color={colors.main}>
          Under the Information Technology (Reasonable security practices and
          procedures and sensitive personal data or information) Rules, 2011,
          you have the right to request details of the personal information that
          we have about you. Please get in touch with us if you would like a
          copy of the data we have on you.
        </Typography>
        <Typography mt={3} color={colors.main}>
          Please contact us by mail or email as soon as you think that any
          information we are holding about you is inaccurate or lacking. We will
          promptly correct any information found to be incorrect.
        </Typography>
      </Box>
      {/* CONTACT US */}
      <Box textAlign="left" mt={3}>
        <Typography fontWeight={700}>CONTACT US</Typography>
        <Typography mt={3} color={colors.main}>
          If there are any questions regarding this privacy policy or if you
          wish to report a breach of the Privacy Policy, you may contact us
          using the information on the support page.
        </Typography>
      </Box>
      <Box mt={4} textAlign="left">
        <Typography fontWeight={700}>GRIEVANCE OFFICER</Typography>
        <Typography mt={3} color={colors.main}>
          In compliance with the Information Technology Act, 2000 (including its
          amendments) and its implementing regulations, the Grievance Officer's
          contact information is listed below. Should you have any questions or
          concerns about this privacy policy exclusively, you can contact them
          directly using the information below:
        </Typography>

        <Box mt={4}>
          <Typography color={colors.main}>Juggernaut Ventures</Typography>
          <Typography mt={2} color={colors.main}>
            283, 5th Main, 5th Cross,{" "}
          </Typography>
          <Typography mt={2} color={colors.main}>
            Koramangala 1st Block,
          </Typography>
          <Typography mt={2} color={colors.main}>
            Bengaluru, Karnataka 560034{" "}
          </Typography>
          <Typography mt={2} color={colors.main}>
            Email: <span style={{ color: "blue" }}>jvgroup22@gmail.com</span>{" "}
          </Typography>
          <Typography mt={2} color={colors.main}>
            Time: Monday to Friday (10 a.m. to 6 p.m.)
          </Typography>
          <Typography mt={2} color={colors.main}>
            For any queries or concerns regarding our Services, kindly email us
            at &nbsp; <span style={{ color: "blue" }}>jvgroup22@gmail.com</span>{" "}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Privacypolicy;