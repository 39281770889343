import { Skeleton, Stack } from "@mui/material";

const KYCLoadingScreen = () => {
  return (
    <Stack spacing={1.75} p={2}>
      <Skeleton variant="rounded" height={46} />
      <Skeleton variant="rounded" height={46} />
      <Skeleton variant="rounded" height={46} />
      <Skeleton variant="rounded" height={46} />
    </Stack>
  );
};
export default KYCLoadingScreen;
