import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box, Stack, TablePagination, TextField } from "@mui/material";

import GenerateStats from "../../../component/Common/GenerateStats";
import { LOADING_STATUS } from "../../../constants/constant";
import useToast from "../../../hooks/useToast";
import TripTable from "../../../component/Admin/Trips/TripTable";
import { getAllTrips } from "../../../api/Admin/trips";

const StyledBox = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 127px)",
  padding: "0px 40px 0px 24px",
}));

const Trips = () => {
  const [debouncedSearchText, setDebouncedSearchText] = useState("");

  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isTripsListFetched, setIsTripsListFetched] = useState(
    LOADING_STATUS.INITIAL
  );
  const [trips, setTrips] = useState([]);
  const [paginationData, setPaginationData] = useState({});

  const [tripStatus, setTripStatus] = useState([]);
  const [customerPaymentStatus, setCustomerPaymentStatus] = useState([]);
  const [transporterPaymentStatus, setTransporterPaymentStatus] = useState([]);
  const [tripDate, setTripDate] = useState([]);

  const [count, setCount] = useState({});

  const { handleOpenToast, ToastMessage } = useToast();

  const fetchTrips = async () => {
    try {
      const response = await getAllTrips(
        page,
        tripStatus,
        customerPaymentStatus,
        transporterPaymentStatus,
        tripDate,
        debouncedSearchText
      );

      if (response.status === 200) {
        setTrips(response.data.data.trips);
        setPaginationData(response.data.data.pagination);
        setIsTripsListFetched(LOADING_STATUS.LOADED);
        setCount(response.data.data.counts);
      } else {
        setIsTripsListFetched(LOADING_STATUS.FAILED);
      }
    } catch (error) {
      setIsTripsListFetched(LOADING_STATUS.FAILED);
      handleOpenToast("Error while getting admin trips", "error");
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 500);

    // Cleanup function to clear the timer on component unmount or re-render
    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);

  const statsArray = [
    {
      name: "Total",
      value: count?.total || 0,
    },
    {
      name: "Upcoming",
      value: count?.upcoming || 0,
    },
    {
      name: "In Transit",
      value: count?.inTransit || 0,
    },
    {
      name: "Completed",
      value: count?.completed || 0,
    },
    {
      name: "Cancelled",
      value: count?.cancelled || 0,
    },
  ];

  useEffect(() => {
    fetchTrips();
  }, [
    page,
    debouncedSearchText,
    tripStatus,
    customerPaymentStatus,
    transporterPaymentStatus,
    tripDate,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
  };

  return (
    <Box height={{ height: "100%" }}>
      <ToastMessage />
      <Stack
        p="1rem 1.625rem"
        borderBottom="1px solid #ADADAD"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <GenerateStats data={statsArray} />

        <TextField
          variant="outlined"
          placeholder="Search"
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          InputProps={{
            sx: { height: "36.31px" },
            endAdornment: (
              <img src="/assets/icons/search-icon.svg" alt="search" />
            ),
          }}
        />
      </Stack>

      <StyledBox>
        <TripTable
          isTripsListFetched={isTripsListFetched}
          trips={trips}
          fetchTrips={fetchTrips}
          setTripStatus={setTripStatus}
          setCustomerPaymentStatus={setCustomerPaymentStatus}
          setTransporterPaymentStatus={setTransporterPaymentStatus}
          tripStatus={tripStatus}
          customerPaymentStatus={customerPaymentStatus}
          transporterPaymentStatus={transporterPaymentStatus}
          handleOpenToast={handleOpenToast}
        />
      </StyledBox>

      {trips?.length > 1 && (
        <div
          style={{
            borderTop: "1px solid #adadad",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={paginationData.totalItems}
            rowsPerPage={paginationData.pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ count }) => (
              <div>
                Page{" "}
                <span style={{ color: "green", fontWeight: 700 }}>
                  {page + 1}
                </span>{" "}
                of {Math.ceil(count / paginationData.pageSize)}
              </div>
            )}
          />
        </div>
      )}
    </Box>
  );
};

export default Trips;
