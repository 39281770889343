import { Box, Stack, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import GenerateStats from "../../../component/Common/GenerateStats";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { StyledTableCell } from "../../Admin/Approval/StyledTableCell";
import { LOADING_STATUS } from "../../../constants/constant";
import { useNavigate } from "react-router-dom";
import FilterCollapse from "../../../component/Customer/FilterCollapse";
import { EnquiryTable } from "../../../component/Customer/EnquiryTable";
import { getCustomerEnquiries } from "../../../api/Customer/enquiry";
import { useSelector } from "react-redux";
import useToast from "../../../hooks/useToast";

export const Enquiries = () => {
  const { ToastMessage, handleOpenToast } = useToast();
  const customerId = useSelector((state) => state.customer.id);
  const enquiryStatusTracking = useSelector(
    (state) => state.customer.enquiryStatusTracking
  );

  const statusfilters = [
    "offer received",
    "finding partner",
    "converted to trip",
    "enquiry closed",
    "enquiry expired",
    "enquiry rejected",
    "offer expired",
  ];
  const navigate = useNavigate();
  const [enquiries, setEnquiries] = useState([]);
  const [isEnquiryFetched, setIsEnquiryFetched] = useState([]);
  const [page, setPage] = useState(0);
  const [paginationData, setPaginationData] = useState({});
  const [anchorForStatus, setAnchorForStatus] = useState(null);
  const [statusFilter, setStatusFilter] = useState([]);
  const [statusCounts, setStatusCounts] = useState({});

  const fetchCustomerEnquiries = async () => {
    try {
      setIsEnquiryFetched(LOADING_STATUS.LOADING);
      const response = await getCustomerEnquiries(customerId, page);
      if (response.status === 200) {
        const enquiriesArray = response?.data.data.enquiries;
        setEnquiries(enquiriesArray);
        setPaginationData(response?.data.data.pagination);
        setStatusCounts(response?.data.data.counts);
        setIsEnquiryFetched(LOADING_STATUS.LOADED);
      }
    } catch (error) {
      setIsEnquiryFetched(LOADING_STATUS.FAILED);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
  };
  const handleOpenStatusFilter = (event) => {
    setAnchorForStatus(event.currentTarget);
  };

  const handleCloseStatusFilter = () => {
    setAnchorForStatus(null);
  };

  const statsArray = [
    {
      name: "Total",
      value:
        (statusCounts?.FINDING_PARTNER || 0) +
        (statusCounts?.OFFER_RECEIVED || 0) +
        (statusCounts?.CONVERTED_TO_TRIP || 0) +
        (statusCounts?.ENQUIRY_EXPIRED || 0) +
        (statusCounts?.OFFER_EXPIRED || 0) +
        (statusCounts?.ENQUIRY_REJECTED || 0) +
        (statusCounts?.ENQUIRY_CLOSED || 0),
    },
    {
      name: "Finding Partner",
      value: statusCounts?.FINDING_PARTNER
        ? statusCounts?.FINDING_PARTNER
        : "0",
    },
    {
      name: "Converted To Trip",
      value: statusCounts?.CONVERTED_TO_TRIP
        ? statusCounts?.CONVERTED_TO_TRIP
        : "0",
    },
    {
      name: "Offer Expired",
      value: statusCounts?.OFFER_EXPIRED ? statusCounts?.OFFER_EXPIRED : "0",
    },
    {
      name: "Offer Received",
      value: statusCounts?.OFFER_RECEIVED ? statusCounts?.OFFER_RECEIVED : "0",
    },
    {
      name: "Others",
      value:
        (statusCounts?.ENQUIRY_EXPIRED || 0) +
        (statusCounts?.ENQUIRY_REJECTED || 0) +
        (statusCounts?.ENQUIRY_CLOSED || 0),
    },
  ];
  useEffect(() => {
    if (customerId) {
      fetchCustomerEnquiries();
    }
  }, [customerId, page, enquiryStatusTracking]);

  return (
    <Box>
      <Stack
        p=".75rem 1.625rem"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid #c6c6c6"
      >
        <GenerateStats data={statsArray} />
      </Stack>
      <Box p={3}>
        <TableContainer sx={{ marginBottom: 8 }}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ borderBottom: "0" }}>
                  Route
                </StyledTableCell>
                <StyledTableCell style={{ borderBottom: "0" }}>
                  Enquired On
                </StyledTableCell>
                <StyledTableCell style={{ borderBottom: "0" }}>
                  Material
                </StyledTableCell>
                <StyledTableCell style={{ borderBottom: "0" }}>
                  Weight
                </StyledTableCell>
                <StyledTableCell style={{ borderBottom: "0" }}>
                  Pickup Date
                </StyledTableCell>
                <StyledTableCell style={{ borderBottom: "0" }}>
                  Pickup Time
                </StyledTableCell>

                <StyledTableCell style={{ borderBottom: "0" }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    {"Status"}
                    <img
                      src="/assets/icons/expand_less.svg"
                      alt="expand less"
                      onClick={handleOpenStatusFilter}
                      style={{ cursor: "pointer" }}
                    />
                    <FilterCollapse
                      open={anchorForStatus}
                      onClose={handleCloseStatusFilter}
                      filters={statusfilters}
                      setFilters={setStatusFilter}
                      selectedFilters={statusFilter}
                    />
                  </Stack>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <EnquiryTable
              enquiries={enquiries}
              isEnquiryFetched={isEnquiryFetched}
              fetchCustomerEnquiries={fetchCustomerEnquiries}
              statusFilter={statusFilter}
              navigate={navigate}
              handleOpenToast={handleOpenToast}
            />
          </Table>
        </TableContainer>
        {isEnquiryFetched === LOADING_STATUS.LOADED ? (
          <div
            style={{
              borderTop: "1px solid #adadad",
              height: "60px",
              marginTop: "3px",
              position: "fixed",
              bottom: "0",
              left: "0",
              width: "100%",
              zIndex: "999",
              backgroundColor: "white",
            }}
          >
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={paginationData.totalItems}
              rowsPerPage={paginationData.pageSize}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={({ count }) => (
                <span>
                  Page{" "}
                  <span style={{ color: "green", fontWeight: 700 }}>
                    {page + 1}
                  </span>{" "}
                  of {Math.ceil(count / paginationData.pageSize)}
                </span>
              )}
            />
          </div>
        ) : (
          <></>
        )}
      </Box>
      <ToastMessage />
    </Box>
  );
};
