import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { TableCell, tableCellClasses } from '@mui/material';

export const StyledEnquiryTableCell = styled(TableCell)(({ theme, sx }) => ({
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: '#fff',
    color: '#6d6d6d',
    borderBottom: 0,
    fontWeight: 700,
    fontSize: 14,
    padding: 12,
    textOverflow: 'ellipsis',
    ...(sx &&
      css`
        ${sx}
      `),
  },
}));
