import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import GenerateStats from "../../../component/Common/GenerateStats";
import { StyledTableCell } from "../Approval/StyledTableCell";
import { useEffect, useState } from "react";
import { getAllDriverList } from "../../../api/Admin/driverResources";
import { useNavigate } from "react-router-dom";
import { StyledTableRow } from "../../../component/Common/StyledTableRow";
import { LOADING_STATUS } from "../../../constants/constant";
import LoadingTable from "../../../component/Common/LoadingTable";
import ErrorScreen from "../../../component/Common/ErrorScreen";
import { TableSearchInputField } from "../../../component/Common/TableSearchInput";
import shortenString from "../../../utils/shortenString";
import styled from "@emotion/styled";
import moment from "moment";

const DriverResources = () => {
  const [driversList, setDriversList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [paginationData, setPaginationData] = useState({});
  const [status, setStatus] = useState("");
  const [page, setPage] = useState(0);
  const [count, setCount] = useState({});
  const [isDriversListFetched, setIsDriversListFetched] = useState(
    LOADING_STATUS.INITIAL
  );
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
  };

  async function getDriversData() {
    setIsDriversListFetched(LOADING_STATUS.LOADING);

    const response = await getAllDriverList(page, inputValue, status);
    if (response.status === 200) {
      setDriversList(response.data.data.drivers);
      setPaginationData(response.data.data.pagination);
      setCount(response.data.data.counts);
      setIsDriversListFetched(LOADING_STATUS.LOADED);
    } else {
      setIsDriversListFetched(LOADING_STATUS.FAILED);
    }
  }

  const statsArray = [
    { name: "Total Drivers", value: count.total },
    { name: "Active", value: count.Available },
    {
      name: "Inactive",
      value: count.Unavailable,
    },
    {
      name: "In Transit",
      value: count.Intransit,
    },
    {
      name: "Invited",
      value: count.Invited,
    },
  ];

  const handleSearchInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleStatusFilterChange = (event) => {
    const { value } = event.target;
    setStatus((prev) => {
      if (prev.includes(value)) {
        return prev.filter((status) => status !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);
  const [filterPopoverAnchorEl, setFilterPopoverAnchorEl] = useState(null);
  const handleFilterPopoverClose = () => {
    setFilterPopoverAnchorEl(null);

    setIsFilterPopoverOpen(false);
  };

  const handleFilterPopoverOpen = (event) => {
    setFilterPopoverAnchorEl(event.currentTarget);
    setIsFilterPopoverOpen(true);
  };

  const tableHeaders = [
    "Name",
    "Mobile",
    "Joined On",
    "Status",
    "Transporter Name",
  ];

  useEffect(() => {
    getDriversData();
  }, [inputValue, page, status]);

  function renderViews() {
    switch (isDriversListFetched) {
      case LOADING_STATUS.LOADING:
        return <LoadingTable columns={5} />;

      case LOADING_STATUS.LOADED:
        if (driversList.length) return <DriverTable drivers={driversList} />;
        return (
          <TableRow sx={{ background: "#EAEAEA" }}>
            <TableCell
              colSpan={5}
              align="center"
              sx={{ fontWeight: 600, color: "#333" }}
            >
              No Results Found.
            </TableCell>
          </TableRow>
        );

      case LOADING_STATUS.FAILED:
        return (
          <TableRow>
            <TableCell
              colSpan={tableHeaders.length}
              sx={{ borderBottom: "none" }}
            >
              <ErrorScreen onRetry={getAllDriverList} />
            </TableCell>
          </TableRow>
        );
      default:
        return null;
    }
  }

  return (
    <Box>
      <Stack
        p=".75rem 1.625rem"
        borderBottom="1px solid #ADADAD"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <GenerateStats data={statsArray} />
        <TableSearchInputField
          value={inputValue}
          onChange={handleSearchInputChange}
          placeholder="Search"
        />
      </Stack>
      <Box p={3}>
        <TableContainer aria-label="Drivers table">
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Mobile</StyledTableCell>
                <StyledTableCell>Joined On</StyledTableCell>
                <StyledTableCell>
                  <StatusBox>
                    Status
                    <img
                      src="/assets/icons/expand_less.svg"
                      alt="expand less"
                      onClick={handleFilterPopoverOpen}
                      style={{ cursor: "pointer" }}
                    />
                  </StatusBox>
                  <Popover
                    open={isFilterPopoverOpen}
                    anchorEl={filterPopoverAnchorEl}
                    onClose={handleFilterPopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <PopOverBox>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={status.includes("INVITED")}
                            onChange={handleStatusFilterChange}
                            value="INVITED"
                          />
                        }
                        label="Invited"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={status.includes("AVAILABLE")}
                            onChange={handleStatusFilterChange}
                            value="AVAILABLE"
                          />
                        }
                        label="Available"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={status.includes("UNAVAILABLE")}
                            onChange={handleStatusFilterChange}
                            value="UNAVAILABLE"
                          />
                        }
                        label="Unavailable"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={status.includes("IN_TRIP")}
                            onChange={handleStatusFilterChange}
                            value="IN_TRIP"
                          />
                        }
                        label="In Trip"
                      />
                      {status.length > 0 && (
                        <Button onClick={() => setStatus("")}>Clear All</Button>
                      )}
                    </PopOverBox>
                  </Popover>
                </StyledTableCell>
                <StyledTableCell>Transporter Name</StyledTableCell>
              </TableRow>
            </TableHead>
            {/* based on loading status views will switch */}
            <TableBody>{renderViews()}</TableBody>
          </Table>
        </TableContainer>
        {isDriversListFetched === LOADING_STATUS.LOADED && (
          <div style={{ padding: "10px" }}>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={paginationData.totalItems}
              rowsPerPage={paginationData.pageSize}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={({ count }) => (
                <div>
                  Page{" "}
                  <span style={{ color: "green", fontWeight: 700 }}>
                    {page + 1}
                  </span>{" "}
                  of {Math.ceil(count / paginationData.pageSize)}
                </div>
              )}
            />
          </div>
        )}
      </Box>
    </Box>
  );
};

export default DriverResources;

const DriverTable = ({ drivers }) => {
  const navigate = useNavigate();

  const generateStatusCell = (status) => {
    let statusText = status[0] + status.slice(1).toLowerCase();
    if (status === "AVAILABLE") {
      return (
        <TableCell sx={{ color: "green", textAlign: "center" }}>
          {statusText}
        </TableCell>
      );
    } else if (status === "INVITED") {
      return (
        <TableCell sx={{ color: "orange", textAlign: "center" }}>
          {statusText}
        </TableCell>
      );
    } else
      return (
        <TableCell sx={{ color: "red", textAlign: "center" }}>
          {statusText}
        </TableCell>
      );
  };

  function createTransporterRowData(driver) {
    return {
      id: driver.driverId,
      name: driver?.driverName || "-",
      number: driver?.driverPhone || "-",
      transporterName: driver?.linkedTransporter || "-",
      joinedOn: driver?.joinedOn || "-",
      status: driver?.status || "-",
    };
  }

  const driverTableData = drivers.map((driver) =>
    createTransporterRowData(driver)
  );

  return driverTableData.map((row) => {
    return (
      <StyledTableRow
        key={row.id}
        onClick={() => navigate(`/admin/app/resources/drivers/${row.id}`)}
        style={{ textDecoration: "none", cursor: "pointer" }}
      >
        <TableCell scope="row" sx={{ color: "#6D6D6D", textAlign: "center" }}>
          {shortenString(row.name, 18)}
        </TableCell>
        <TableCell sx={{ color: "#6D6D6D", textAlign: "center" }}>
          {row.number}
        </TableCell>
        <TableCell sx={{ color: "#6D6D6D", textAlign: "center" }}>
          {moment(row.joinedOn).format("DD-MM-YYYY")}
        </TableCell>
        {generateStatusCell(row.status)}
        <TableCell sx={{ color: "#6D6D6D", textAlign: "center" }}>
          {shortenString(row.transporterName, 18)}
        </TableCell>
      </StyledTableRow>
    );
  });
};

const PopOverBox = styled(Box)({
  padding: "10px",
  display: "flex",
  flexDirection: "column",
});

const StatusBox = styled(Box)({
  fontSize: "14px",
  fontWeight: 700,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
});
