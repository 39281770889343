import { Button, Toolbar, Typography } from "@mui/material";

const ErrorScreen = ({ onRetry }) => {
    return (
      <div style={{ textAlign: "center", padding: "20px", height: "100%" }}>
        <Toolbar  />
        <Typography variant="h4" gutterBottom>
          Oops! Something went wrong.
        </Typography>
        <Typography variant="body1" paragraph>
          We're sorry, but an unexpected error occurred.
        </Typography>
        <Button variant="contained" color="primary" onClick={onRetry}>
          Retry
        </Button>
      </div>
    );
  };
  export default ErrorScreen