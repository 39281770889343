import React from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import HeaderWithUnderline from "../Configurations/Enquiries/HeaderWithUnderline";
import NameDescription from "../Configurations/Enquiries/NameDescription";

export default function CustomerDetails({ customerDetails }) {
  return (
    <Box sx={{ padding: "24px" }}>
      <HeaderWithUnderline header="Customer Details" />
      <StyledBox>
        <BoxContainer marginLeft="0px" gap="40px">
          <NameDescription
            name="Customer Name"
            description={customerDetails?.fullName || "-"}
            color="#6D6D6D"
            marginLeft="0px"
          />
          <NameDescription
            name="Address"
            description={customerDetails?.businessAddress?.addressLine1 || "-"}
            marginLeft="0px"
            color="#6D6D6D"
          />

          <NameDescription
            name="Email Address"
            description={customerDetails?.email || "-"}
            marginLeft="0px"
            color="#6D6D6D"
            textTransform="none"
          />
          <NameDescription
            name="Company Name"
            description={customerDetails?.businessName || "-"}
            marginLeft="0px"
            color="#6D6D6D"
          />
          <NameDescription
            name="Contact Number"
            description={customerDetails?.phone || "-"}
            marginLeft="0px"
            color="#0C7F44"
          />
        </BoxContainer>
      </StyledBox>
    </Box>
  );
}

const BoxContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  paddingTop: "16px",
  paddingLeft: "32px",
  paddingRight: "32px",
}));

const StyledBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
