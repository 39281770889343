import React from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import NameDescription from "./NameDescription";
import HeaderWithUnderline from "./HeaderWithUnderline";

const BoxContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  padding: "16px 0px",
}));

export default function CustomerDetails(props) {
  const { enquiry } = props;

  return (
    <Box>
      <HeaderWithUnderline header="Customer Details" />

      <BoxContainer>
        <NameDescription
          name="Customer Id"
          description={enquiry?.customer?.customerId || "-"}
        />
        <NameDescription
          name="Customer Name"
          description={enquiry?.customer?.customerName || "-"}
        />
        <NameDescription
          name="Contact number"
          description={enquiry?.customer?.customerPhone || "-"}
          color="#44C517"
        />
       
      </BoxContainer>
    </Box>
  );
}
