import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Box, Stack } from "@mui/system";
import { Avatar, Grid, Typography } from "@mui/material";
import {
  getTransporterById,
  getKycDetailById,
  updateTransporterStatus,
} from "../../../api/Admin/transporterResources";
import moment from "moment";
import TransporterTripsTable from "../../../component/AdminResources/TransporterTripsTable";
import TransportersTrucksAccordion from "../../../component/AdminResources/TransporterTrucksAccordion";
import TransportersDriverAccordion from "../../../component/AdminResources/TransporterDriversAccordion";
import TransporterCitiesAndRoutes from "../../../component/AdminResources/TransporterCitiesAndRoutes";
import TransporterTransaction from "../../../component/AdminResources/TransporterTransaction";
import useToast from "../../../hooks/useToast";
import styled from "@emotion/styled";
import ToggleButton from "../../../component/Common/ToggleButton";

const TransporterResourcesDetailedView = () => {
  const navigate = useNavigate();

  const [panelExpanded, setPanelExpanded] = useState(null);
  const updateExpandedPanel = (panel) => (event, isExpanded) => {
    setPanelExpanded(isExpanded ? panel : false);
  };
  const { handleOpenToast, ToastMessage } = useToast();

  const { id } = useParams();

  const [transporterDetails, setTransporterDetails] = useState({});

  const [transporterKycDetails, setTransporterKycDetails] = useState({});

  const getTransporterDetails = async () => {
    try {
      const response = await getTransporterById(id);
      if (response.status === 200) {
        setTransporterDetails(response.data.data);
      }
    } catch (error) {
      handleOpenToast("Error while getting transporter details", "error");
    }
  };

  const getTransporterKycDetail = async (id) => {
    try {
      const response = await getKycDetailById(id);
      if (response.status === 200) {
        setTransporterKycDetails(response.data.data);
      }
    } catch (error) {
      handleOpenToast("Error while getting transporter kyc details", "error");
    }
  };

  const [isTransporterActive, setIsTransporterActive] = useState(
    transporterDetails?.transporterStatus === "ACTIVE" ? true : false
  );

  const handleUpdateTransporterStatus = async () => {
    try {
      const newStatus = isTransporterActive ? "BLOCKED" : "ACTIVE";
      const response = await updateTransporterStatus(id, newStatus);
      if (response.status === 200) {
        handleOpenToast(
          `Transporter ${
            isTransporterActive ? "blocked" : "Activated"
          } successfully`,
          "success"
        );
        setIsTransporterActive(!isTransporterActive);
      }
    } catch (error) {
      handleOpenToast("Error while updating transporter status", "error");
    }
  };

  const onToggle = async () => {
    setIsTransporterActive(!isTransporterActive);
    handleUpdateTransporterStatus();
  };

  useEffect(() => {
    getTransporterDetails();
    setIsTransporterActive(
      transporterDetails?.transporterStatus === "ACTIVE" ? true : false
    );
  }, [id, transporterDetails?.transporterStatus]);

  useEffect(() => {
    if (transporterDetails.kycId) {
      getTransporterKycDetail(transporterDetails.kycId);
    }
  }, [transporterDetails.kycId]);

  const formattedBusinessAddress = (businessAddress) => {
    const { addressLine1, addressLine2, city, state } = businessAddress || {};
    return [addressLine1, addressLine2, city, state].filter(Boolean).join(", ");
  };

  return (
    <Box>
      <ToastMessage />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ background: "#EAEAEA" }}
        p=".5rem 1.5rem"
      >
        <img
          alt="back"
          src="/assets/icons/backArrow.svg"
          style={{ cursor: "pointer", width: "24px", height: "20px" }}
          onClick={() => navigate(-1)}
        />
        <Typography color="#333" fontWeight={700} fontSize="1.25rem">
          {transporterDetails?.fullName || ""}
        </Typography>
        <StatusAndToggle>
          <ToggleButton
            toggledState={isTransporterActive}
            onToggle={onToggle}
          />
          <StatusTypography
            sx={{ color: isTransporterActive ? "#00B87C" : "#FF0000" }}
          >
            {isTransporterActive ? "Active" : "Blocked"}
          </StatusTypography>
        </StatusAndToggle>
      </Stack>

      <Box p="1rem 2rem">
        {/* Transporter Details */}
        <Stack direction="row">
          <Typography sx={{ fontWeight: 700, color: "#333", fontSize: "1rem" }}>
            Transporter Details
          </Typography>
          <div style={{ borderBottom: "1px solid #6D6D6D", flexGrow: 1 }}></div>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={4} mt={2} pl={2}>
          {transporterDetails?.profilePhoto ? (
            <img
              src={transporterDetails.profilePhoto}
              alt="Profile"
              style={{ width: "112px", height: "112px", borderRadius: "50%" }}
            />
          ) : (
            <Avatar sx={{ width: "112px", height: "112px" }} />
          )}
          <Grid container spacing={2}>
            <Grid item sm={12} md={5}>
              <Stack direction="column" gap={0.75}>
                <Typography fontSize="1rem">
                  Name: <b>{transporterDetails?.fullName || "-"}</b>
                </Typography>

                <Typography>
                  Mobile No.: <b>{transporterDetails?.phone || "-"}</b>
                </Typography>

                <Typography>
                  Email: <b>{transporterDetails?.email || "-"}</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item sm={12} md={7} spacing={2}>
              <Stack direction="column" gap={0.75}>
                <Typography fontSize="1rem">
                  Joined on:{" "}
                  <b>
                    {moment(transporterDetails?.createdAt).format(
                      "DD-MM-YYYY"
                    ) || "-"}
                  </b>
                </Typography>
                <Typography fontSize="1rem">
                  Company Name: <b>{transporterDetails?.businessName || "-"}</b>
                </Typography>
                <Typography fontSize="1rem">
                  Address:{" "}
                  <b>
                    {formattedBusinessAddress(
                      transporterDetails?.businessAddress
                    ) || "-"}
                  </b>
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>

        {/* Documents Details */}
        <Stack direction="row" mt={3}>
          <Typography sx={{ fontWeight: 700, color: "#333", fontSize: "1rem" }}>
            Documents Details
          </Typography>
          <div style={{ borderBottom: "1px solid #6D6D6D", flexGrow: 1 }}></div>
        </Stack>
        <Stack direction="row" spacing={16} mt={2} pl={2}>
          <Grid item xs={6} sm={3}>
            <Typography>
              Aadhaar Number:{" "}
              <b>
                {transporterKycDetails?.aadharDetails?.documentNumber || "-"}
              </b>
            </Typography>
            <Typography sx={{ mt: 2 }}>
              PAN Number:{" "}
              <b>{transporterKycDetails?.panDetails?.documentNumber || "-"}</b>
            </Typography>
          </Grid>
          <Grid Grid item sm={12} md={7}>
            <Typography>
              GSTIN/UIN Number:{" "}
              <b>{transporterKycDetails?.gstDetails?.licenceNumber || "-"}</b>
            </Typography>

            <Typography sx={{ mt: 2 }}>
              Shop Act License Number:{" "}
              <b>
                {transporterKycDetails?.businessLicenceDetails?.licenceNumber ||
                  "-"}
              </b>
            </Typography>
          </Grid>
          <Grid item sm={12} md={7}>
            <Typography>
              Verification Status:{" "}
              <b
                style={{
                  color:
                    transporterKycDetails?.profileStatus === "APPROVED"
                      ? "green"
                      : "red",
                }}
              >
                {transporterKycDetails?.profileStatus === "APPROVED"
                  ? "Approved"
                  : "Pending"}
              </b>
            </Typography>
          </Grid>
        </Stack>
        {/* Accordions  */}

        <Stack direction="column" gap={2} mt={2}>
          <TransporterCitiesAndRoutes
            panelExpanded={panelExpanded}
            updateExpandedPanel={updateExpandedPanel}
            id={id}
            operationalCities={transporterDetails?.operationalCities}
          />
          <TransportersDriverAccordion
            panelExpanded={panelExpanded}
            updateExpandedPanel={updateExpandedPanel}
            id={id}
          />
          <TransportersTrucksAccordion
            panelExpanded={panelExpanded}
            updateExpandedPanel={updateExpandedPanel}
            id={id}
          />
          <TransporterTripsTable
            panelExpanded={panelExpanded}
            updateExpandedPanel={updateExpandedPanel}
            id={id}
          />
          <TransporterTransaction
            panelExpanded={panelExpanded}
            updateExpandedPanel={updateExpandedPanel}
            id={id}
            transporterName={transporterDetails?.fullName}
            amount={transporterDetails?.amount}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default TransporterResourcesDetailedView;

const StatusAndToggle = styled(Box)({
  display: "flex",
  gap: "10px",
});

const StatusTypography = styled(Typography)({
  fontSize: "14px",
  fontWeight: 700,
  width: "50px",
});
