import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { deleteSelectedTruck } from "../../../../api/Transporter/truck";
import styled from "@emotion/styled";

const DeleteTruckModal = ({
  closeDeleteTruckModal,
  selectedVehicle,
  handleOpenToast,
  fetchAllTrucksList,
  transporterId,
  isTransporterHasUpcomingTrips,
}) => {
  //function handles the deletion of truck data.
  const handleDeleteTruckTypeData = async () => {
    const response = await deleteSelectedTruck(
      transporterId,
      selectedVehicle?.transporterVehicleId
    );
    if (response.status === 200) {
      handleOpenToast("Truck data deleted successfully.", "success");
      await fetchAllTrucksList();
    } else {
      handleOpenToast("Truck data deleteion failed.", "error");
    }
    closeDeleteTruckModal();
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "white",
        p: 2,
        borderRadius: 4,
        width: "600px",
        height: "400px",
      }}
    >
      <Typography
        sx={{
          color: "#333",
          fontSize: "24px",
          fontWeight: 700,
        }}
      >
        Delete truck
      </Typography>

      {!isTransporterHasUpcomingTrips ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <img
            src="/assets/icons/truckImage.svg"
            alt="logo face"
            style={{
              width: "424px",
              height: "150px",
            }}
          />
          <Box sx={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <StyledBox>{selectedVehicle.vehicleModelName}</StyledBox>
            <StyledBox>Max load 12 ton</StyledBox>
            <StyledBox>{selectedVehicle.vehicleNumber}</StyledBox>
          </Box>
          <Box sx={{ display: "flex", gap: "6px" }}>
            <img
              src="/assets/icons/confirmation.svg"
              alt="logo face"
              style={{ marginBottom: "2px" }}
            />
            <Typography
              sx={{
                color: "#FF3F3F",
                fontSize: 14,
              }}
            >
              Are you sure you want to delete this truck?
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Button
              sx={{
                background: "#FF3F3F",
                color: "white",
                "&:hover": {
                  background: "#FF3F3F",
                },
                fontWeight: 700,
                textTransform: "none",
              }}
              onClick={handleDeleteTruckTypeData}
            >
              Confirm Delete
            </Button>
            <Button
              sx={{
                background: "#FFF",
                border: "1px solid #333",
                color: "#333",
                fontWeight: 700,
                textTransform: "none",
              }}
              onClick={closeDeleteTruckModal}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <img
            src="/assets/icons/truckImage.svg"
            alt="logo face"
            style={{
              width: "424px",
              height: "150px",
            }}
          />
          <Box sx={{ display: "flex", gap: "6px" }}>
            <img
              src="/assets/icons/confirmation.svg"
              alt="logo face"
              style={{ marginBottom: "2px" }}
            />
            <Typography
              sx={{
                color: "#FF3F3F",
                fontSize: 14,
              }}
            >
              You cannot delete this truck as it has upcoming trips.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Button
              sx={{
                background: "#FF3F3F",
                color: "white",
                "&:hover": {
                  background: "#FF3F3F",
                },
                fontWeight: 700,
                textTransform: "none",
              }}
              onClick={closeDeleteTruckModal}
            >
              OK
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DeleteTruckModal;

const StyledBox = styled(Box)(() => ({
  display: "flex",
  height: "46px",
  padding: "8px 16px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "4px",
  background: "#EAEAEA",
  fontWeight: 700,
}));
