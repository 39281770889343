import { useJsApiLoader } from "@react-google-maps/api";
import { useEffect, useState } from "react";

const places = ["places"];
export const useMapsService = (props) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: places,
  });
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [geocoderService, setGeocoderService] = useState(null);

  useEffect(() => {
    if (isLoaded && window.google) {
      setAutocompleteService(
        new window.google.maps.places.AutocompleteService()
      );
      setGeocoderService(new window.google.maps.Geocoder());
    }
  }, [isLoaded]);

  const calculateDistance = async (origin, destination) => {
    if (!window.google || !window.google.maps) {
      return null;
    }
    const directionService = new window.google.maps.DirectionsService();
    const distanceResponse = await directionService.route({
      origin,
      destination,
      travelMode: window.google.maps.TravelMode.DRIVING,
    });
    return parseFloat(
      distanceResponse.routes[0].legs[0].distance.text.replace(/[^\d.]/g, "")
    );
  };

  const predictedPlaces = async (input) => {
    return new Promise(async (resolve, reject) => {
      if (!autocompleteService) {
        reject(new Error("AutocompleteService not available"));
        return;
      }

      autocompleteService.getPlacePredictions(
        {
          input: props.city ? `${input} in ${props.city}` : input,
          componentRestrictions: {
            country: "IN",
          },
        },
        (predictions, status) => {
          if (status === "OK") {
            resolve(predictions);
          } else {
            reject(new Error("Error fetching predictions"));
          }
        }
      );
    });
  };

  const getLatLng = async (placeId) => {
    return new Promise((resolve, reject) => {
      if (!geocoderService) {
        reject(new Error("GeocoderService not available"));
        return;
      }

      geocoderService.geocode({ placeId: placeId }, (results, status) => {
        if (status === "OK" && results && results.length > 0) {
          const addressComponents = results[0].address_components;

          const cityComponent = addressComponents.find((component) =>
            component.types.includes("locality")
          );
          const admnComponent = addressComponents.find((component) =>
            component.types.includes("administrative_area_level_3")
          );

          const city = cityComponent
            ? cityComponent?.long_name + "," + admnComponent?.long_name ?? ""
            : null;
          if (!city) {
            reject(new Error(`no cityError ${placeId}`));
            return;
          }

          const addressWithoutCity = results[0].formatted_address
            .split(",")
            .filter((part) => part.trim() !== city && city.split(",")[0])
            .join(",")
            .trim();

          resolve({
            latitude: results[0]?.geometry?.location.lat(),
            longitude: results[0]?.geometry?.location.lng(),
            city: city,
            address: addressWithoutCity,
          });
        } else {
          console.error("Error fetching LatLng:", status);
          reject(new Error("Error fetching LatLng"));
        }
      });
    });
  };

  const getExactMatch = async (placeId) => {
    return new Promise((resolve, reject) => {
      if (!geocoderService) {
        reject(new Error("GeocoderService not available"));
        return;
      }

      geocoderService.geocode({ placeId: placeId }, (results, status) => {
        if (status === "OK" && results && results.length > 0) {
          const addressComponents = results[0].address_components;

          const exactMatch = addressComponents?.find((component) => {
            if (component?.types?.includes("sublocality_level_3")) {
              return component?.long_name;
            } else if (component?.types.includes("sublocality_level_2")) {
              return component?.long_name;
            } else if (component?.types.includes("sublocality_level_1")) {
              return component?.long_name;
            }
          });

          const cityComponent = addressComponents.find((component) =>
            component.types.includes("locality")
          );
          const admnComponent = addressComponents.find((component) =>
            component.types.includes("administrative_area_level_3")
          );

          const city = cityComponent
            ? cityComponent?.long_name + "," + admnComponent?.long_name ?? ""
            : null;
          if (!city) {
            reject(new Error(`no cityError ${placeId}`));
            return;
          }

          const addressWithoutCity = results[0].formatted_address
            .split(",")
            .filter((part) => part.trim() !== city && city.split(",")[0])
            .join(",")
            .trim();

          resolve({
            latitude: results[0]?.geometry?.location.lat(),
            longitude: results[0]?.geometry?.location.lng(),
            city: city,
            address: addressWithoutCity,
            exactMatch: exactMatch,
          });
        } else {
          console.error("Error fetching LatLng:", status);
          reject(new Error("Error fetching LatLng"));
        }
      });
    });
  };

  return {
    isLoaded,
    loadError,
    isLoading: !isLoaded && !loadError,
    calculateDistance,
    autocompleteService,
    predictedPlaces,
    getLatLng,
    getExactMatch,
  };
};
