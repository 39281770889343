import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import Timer from "./Timer";
import TimeSlot from "./TimeSlot";
import {
  getEnquiryRelatedConfigs,
  updateEnquiryRelatedTime,
} from "../../../../api/Admin/TimeRelated";
import useToast from "../../../../hooks/useToast";

const EnquiryRelated = () => {
  const [enquiryValidityTimer, setEnquiryValidityTimer] = useState({
    hr: 0,
    min: 0,
    sec: 0,
  });

  const [offerExpiryTimer, setOfferExpiryTimer] = useState({
    hr: 0,
    min: 0,
    sec: 0,
  });

  const [tripAllocationDeadline, setTripAllocationDeadline] = useState({
    d: 0,
    hr: 0,
    min: 0,
    sec: 0,
  });
  const { handleOpenToast, ToastMessage } = useToast();

  const [pickupTimeSlots, setPickupTimeSlots] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  // function to fetch enquiry related time configurations
  const fetchEnquiryRelatedConfigs = async () => {
    try {
      setIsLoading(true);
      const response = await getEnquiryRelatedConfigs();
      if (response.status === 200) {
        setPickupTimeSlots(response.data.data.pickupTimeSlots);
        setEnquiryValidityTimer(response.data.data.enquiryValidityTimer);
        setOfferExpiryTimer(response.data.data.offerExpiryTimer);
        setTripAllocationDeadline(response.data.data.tripAllocationDeadline);
      }
      setIsLoading(false);
    } catch (error) {
      handleOpenToast(
        "Error while fetching enquiry related configurations",
        "error"
      );
      setIsLoading(false);
    }
  };

  // function for update Enquiry Validity Timer
  const updateEnquiryValidityTimer = async (timeData) => {
    try {
      const response = await updateEnquiryRelatedTime(
        {
          enquiryValidityTimer: timeData,
        },
        true,
        false,
        false
      );
      if (response.status === 200) {
        fetchEnquiryRelatedConfigs();
        handleOpenToast(
          "Enquiry validity timer updated successfully",
          "success"
        );
      }
    } catch (error) {
      handleOpenToast("Error while updating enquiry validity timer", "error");
    }
  };

  // function for update Offer Expiry Timer
  const updateOfferExpiryTimer = async (timeData) => {
    try {
      const response = await updateEnquiryRelatedTime(
        {
          offerExpiryTimer: timeData,
        },
        false,
        true,
        false
      );

      if (response.status === 200) {
        fetchEnquiryRelatedConfigs();
        handleOpenToast("Offer expiry timer updated successfully", "success");
      }
    } catch (error) {
      handleOpenToast("Error while updating offer expiry timer", "error");
    }
  };

  const updateTripAllocationDeadline = async (timeData) => {
    try {
      const response = await updateEnquiryRelatedTime(
        {
          tripAllocationDeadline: timeData,
        },
        false,
        false,
        true
      );

      if (response.status === 200) {
        fetchEnquiryRelatedConfigs();
        handleOpenToast(
          "Trip allocation deadline updated successfully",
          "success"
        );
      }
    } catch (error) {
      handleOpenToast("Error while updating trip allocation deadline", "error");
    }
  };

  useEffect(() => {
    fetchEnquiryRelatedConfigs();
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "35vh",
          width: "100%",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <ToastMessage />
      <TimeSlot
        fetchEnquiryRelatedConfigs={fetchEnquiryRelatedConfigs}
        pickupTimeSlots={pickupTimeSlots}
      />
      <Timer
        title="Enquiry Validity Timer"
        hour={enquiryValidityTimer.hr}
        minute={enquiryValidityTimer.min}
        second={enquiryValidityTimer.sec}
        onSave={updateEnquiryValidityTimer}
      />
      <Timer
        title="Offer Expiry Timer"
        hour={offerExpiryTimer.hr}
        minute={offerExpiryTimer.min}
        second={offerExpiryTimer.sec}
        onSave={updateOfferExpiryTimer}
      />
      <Timer
        title="Trip allocation Timer"
        day={tripAllocationDeadline.d}
        hour={tripAllocationDeadline.hr}
        minute={tripAllocationDeadline.min}
        second={tripAllocationDeadline.sec}
        onSave={updateTripAllocationDeadline}
      />
    </Box>
  );
};

export default EnquiryRelated;
