import { customerApi, guestApi } from "../config";

export const createEnquiry = async (enquiryDetails) => {
  return await guestApi.post("/customer/enquiry", { ...enquiryDetails });
};

// step - 2 API
export const updateEnquiry = async (details, enquiryId) => {
  return await guestApi.put(`/customer/enquiry/${enquiryId}?step=2`, {
    ...details,
  });
};

// step - 3 API
export const updateCustomerDetails = async (customerId, enquiryId, details) => {
  return await customerApi.put(`/customer/enquiry/${enquiryId}?step=3`, {
    ...details,
    customerId,
  });
};

export const getEstimateRangeForEnquiry = async (enquiryId) => {
  return await customerApi.get(`/customer/enquiry/${enquiryId}/estimate`);
};

export const postAnEnquiry = async (enquiryId) => {
  return await customerApi.get(`/customer/enquiry/${enquiryId}/postEnquiry`);
};

export const getVehicles = async () => {
  return await guestApi.get("inventory/vehicle-categories");
};
export const getVehicleModels = async (wheels) => {
  return await guestApi.get(`inventory/vehicle-categories/${wheels}/models`);
};

export const getCustomerProfile = async () => {
  return await customerApi.get("customer/profile");
};

export const getCustomerEnquiries = async (customerId, page) => {
  try {
    const response = await customerApi.get(
      `customer/enquiry/${customerId}?page=${page + 1}`
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const getCustomerEnquiryDetailsById = async (customerId, enquiryId) => {
  try {
    const response = await customerApi.get(
      `customer/enquiry/${customerId}/${enquiryId}`
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const rebookEnquiry = async (enquiryData) => {
  return await customerApi.post(`customer/enquiry/rebook`, enquiryData);
};

export const getCustomerEnquiryOffer = async (customerId, enquiryId) => {
  try {
    const response = await customerApi.get(
      `customer/enquiry/${customerId}/${enquiryId}/offers`
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const createTrip = async (customerId, tripData) => {
  try {
    const response = await customerApi.post(
      `/customer/trip/${customerId}`,
      tripData
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateContactPersonDetails = async (
  customerId,
  tripId,
  contactDetails
) => {
  try {
    const response = await customerApi.patch(
      `/customer/trip/${customerId}/${tripId}`,
      contactDetails
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateWhatsAppContactPersonDetails = async (
  customerId,
  tripId,
  contactDetails
) => {
  try {
    const response = await customerApi.patch(
      `/customer/trip/whatsapp/${customerId}/${tripId}`,
      contactDetails
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const closeEnquiry = async (customerId, enquiryId) => {
  try {
    const response = await customerApi.patch(
      `customer/enquiry/${customerId}/${enquiryId}/close-enquiry`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const createEnquiryFromWhatsapp = async (enquiryData) => {
  return await customerApi.post("wati/createEnquiry", enquiryData);
};

export const getMaterialTypes = async () => {
  return await guestApi.get("inventory/material-type-dropdown");
};

export const getTimeSlots = async () => {
  return await guestApi.get("inventory/time-related/pickup-time-slot");
};

export const getVehicleRecommendation = async (categoryId, weight) => {
  return await guestApi.get(
    `inventory/${categoryId}/vehicle-category?weight=${weight}`
  );
};
export const postEnquiryNoPreference = async (enquiryId, customerId) => {
  return customerApi.post("wati/enquiry/summary", {
    customerId,
    enquiryId,
  });
};
