import React, { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";

function SearchBox({ handleSearchBoxChange }) {
  // State for storing the current search value
  const [searchValue, setSearchValue] = useState("");

  // State for storing the debounced search value
  const [debouncedValue, setDebouncedValue] = useState("");

  // Effect to debounce the search value before triggering the search
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(searchValue);
    }, 500);

    // Cleanup function to clear the timer on component unmount or re-render
    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);

  // Effect to trigger the search when the debounced value changes
  useEffect(() => {
    handleSearchBoxChange(debouncedValue);
  }, [debouncedValue, handleSearchBoxChange]);

  // Function to handle changes in the search input
  const handleChange = (value) => {
    setSearchValue(value);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      height={52}
      borderRadius={1}
      border="1.148px solid #ADADAD"
      background="#FFF"
      position="relative"
    >
      <img
        src="/assets/icons/search-icon.svg"
        alt="search"
        style={{
          position: "absolute",
          left: "1%",
        }}
      />
      <TextField
        placeholder="Search"
        variant="standard"
        style={{
          flex: 1,
          marginLeft: 32,
          border: "none",
        }}
        InputProps={{
          disableUnderline: true,
        }}
        value={searchValue}
        onChange={(event) => handleChange(event.target.value)}
      />
    </Box>
  );
}

export default SearchBox;
