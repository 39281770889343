import {
  Box,
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import {
  getBillerDetailsCustomer,
  getPaymentDetailsCustomer,
  submitBillerDetailsCustomer,
  submitPaymentDetailsCustomer,
} from "../../../api/Customer/billing";
import useToast from "../../../hooks/useToast";
import { bankList } from "../../../constants/util";
import axios from "axios";

const StyledInputLabel = (props) => {
  return (
    <InputLabel
      htmlFor={props.htmlFor}
      sx={{ fontSize: ".835rem", color: "#333", fontWeight: 700 }}
    >
      {props.children}
    </InputLabel>
  );
};

const initialBillerInformation = {
  billerName: "",
  address: "",
  email: "",
  gstNo: "",
};
const initialBankDetailsState = {
  bankName: "",
  accountHolderName: "",
  accountNumber: "",
  accountType: "",
  ifscCode: "",
};

const CustomerBilling = () => {
  return (
    <Box p={2}>
      <BillerAddressForm />
      <PaymentInformationForm />
    </Box>
  );
};
export default CustomerBilling;

const BillerAddressForm = () => {
  const { ToastMessage, handleOpenToast } = useToast();
  const [billingInformation, setBillingInformation] = useState(
    initialBillerInformation
  );
  const [errors, setErrors] = useState({});
  const [isBillerDetailsPresent, setIsBillerDetailsPresent] = useState(false);
  const handleBillingInformationDataChange = (event) => {
    const { name, value } = event.target;
    setBillingInformation((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) setErrors((prev) => ({ ...prev, [name]: "" }));
  };
  // validate inputs
  const validateInputs = (billerInformationBody) => {
    let validationErrors = {};
    if (!billerInformationBody.billerName.trim()) {
      validationErrors.billerName = "Biller name is required";
    }
    if (!billerInformationBody.address.trim()) {
      validationErrors.address = "Address is required";
    }
    if (!billerInformationBody.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(billerInformationBody.email)) {
      validationErrors.email = "Email is invalid";
    }
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };
  const handleBillingInformationSubmission = async (e) => {
    e.preventDefault();
    const isValidated = validateInputs(billingInformation);
    if (isValidated) {
      const billerInformationBody = {};
      if (billingInformation.billerName)
        billerInformationBody.billerName = billingInformation.billerName;
      if (billingInformation.address)
        billerInformationBody.address = billingInformation.address;
      if (billingInformation.email)
        billerInformationBody.email = billingInformation.email;
      if (billingInformation.gstNo)
        billerInformationBody.gstNo = billingInformation.gstNo;
      try {
        const response = await submitBillerDetailsCustomer(
          billerInformationBody
        );
        setIsBillerDetailsPresent(true);
        handleOpenToast("Updated successfully", "success");
      } catch (err) {
        handleOpenToast("Somrhing went wrong", "error");
      }
    } else return;
  };
  const fetchBillerAddress = async () => {
    const response = await getBillerDetailsCustomer();
    if (JSON.stringify(response.data.data) === "{}")
      setBillingInformation(initialBillerInformation);
    else {
      setIsBillerDetailsPresent(true);
      const { billerName, address, email, gstNo } = response.data.data;
      setBillingInformation({ billerName, address, email, gstNo: gstNo ?? "" });
    }
  };
  useEffect(() => {
    fetchBillerAddress();
  }, []);
  return (
    <Box>
      <ToastMessage />
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ background: "#EAEAEA", fontWeight: 700 }}
        p=".5rem 1rem"
        borderRadius={0.75}
      >
        {"Billing Address"}
        <EditIcon
          onClick={() => setIsBillerDetailsPresent(false)}
          style={{ cursor: "pointer" }}
        />
      </Stack>
      <Box component="form" onSubmit={handleBillingInformationSubmission}>
        <Grid container px={3} py={1} spacing={2}>
          <Grid item xs={6}>
            <Stack>
              <StyledInputLabel htmlFor="name">Biller Name</StyledInputLabel>
              <TextField
                id="name"
                disabled={isBillerDetailsPresent}
                placeholder="Enter biller name"
                fullWidth
                size="small"
                name="billerName"
                value={billingInformation.billerName}
                onChange={handleBillingInformationDataChange}
                error={!!errors.billerName}
                helperText={errors.billerName}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <StyledInputLabel>Address</StyledInputLabel>
              <TextField
                disabled={isBillerDetailsPresent}
                placeholder="Enter address"
                fullWidth
                size="small"
                name="address"
                value={billingInformation.address}
                onChange={handleBillingInformationDataChange}
                error={!!errors.address}
                helperText={errors.address}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <StyledInputLabel>Email Address</StyledInputLabel>
              <TextField
                disabled={isBillerDetailsPresent}
                placeholder="Enter email"
                fullWidth
                size="small"
                name="email"
                value={billingInformation.email}
                onChange={handleBillingInformationDataChange}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <StyledInputLabel>GST registration number</StyledInputLabel>
              <TextField
                disabled={isBillerDetailsPresent}
                fullWidth
                placeholder="Enter GST number"
                size="small"
                name="gstNo"
                value={billingInformation.gstNo}
                onChange={handleBillingInformationDataChange}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} textAlign="right">
            <Button
              disabled={isBillerDetailsPresent}
              variant="contained"
              color="primary"
              type="submit"
              sx={{ padding: ".25rem 1.5rem" }}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const PaymentInformationForm = () => {
  const { handleOpenToast, ToastMessage } = useToast();
  const [errors, setErrors] = useState({});
  const [confirmAccountNumber, setConfirmAccountNumber] = useState("");
  const [paymentInformation, setPaymentInforamtion] = useState(
    initialBankDetailsState
  );
  const [isBankDetailsPresent, setIsBankDetailsPresent] = useState(false);
  const [isIfscCodeSuccess, setIsIfscCodeSuccess] = useState(true);

  const handlePaymentInformationChange = (event) => {
    const { name, value } = event.target;
    if (name === "accountNumber") {
      if (isNaN(value)) return;
    }
    setPaymentInforamtion((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prevErr) => ({ ...prevErr, [name]: "" }));
    }
  };
  const handleConfirmAccountNoInputChange = (e) => {
    if (isNaN(e.target.value)) return;
    if (e.target.value === paymentInformation.accountNumber)
      setErrors((prev) => ({
        ...prev,
        confirmAccountNumber: "",
      }));
    setConfirmAccountNumber(e.target.value);
  };
  const validateInputValues = () => {
    const validationErrors = {};
    if (!paymentInformation.bankName) {
      validationErrors.bankName = "Please select a bank";
    }
    if (paymentInformation.accountHolderName.length < 4) {
      validationErrors.accountHolderName =
        "Account holder name should be atleast 4 characters";
    }
    if (!paymentInformation.accountHolderName) {
      validationErrors.accountHolderName = "Please enter account holder name";
    }
    if (!paymentInformation.accountNumber) {
      validationErrors.accountNumber = "Please enter account number";
    }
    if (!confirmAccountNumber) {
      validationErrors.confirmAccountNumber = "Please confirm account number";
    }
    if (confirmAccountNumber !== paymentInformation.accountNumber) {
      validationErrors.confirmAccountNumber = "Account number do not match";
    }
    if (!paymentInformation.accountType) {
      validationErrors.accountType = "Please select account type";
    }
    if (!paymentInformation.ifscCode) {
      validationErrors.ifscCode = "Please enter IFSC code";
    }
    if (!isIfscCodeSuccess) {
      validationErrors.ifscCode = "Please enter Valid IFSC code";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };
  const handleBankDetailsSubmission = async (event) => {
    event.preventDefault();
    if (validateInputValues()) {
      // submit bank details
      try {
        const response = await submitPaymentDetailsCustomer(paymentInformation);
        setIsBankDetailsPresent(true);
        handleOpenToast("Bank details saved successfully");
      } catch (err) {
        handleOpenToast("Error saving bank details", "error");
      }
    }
  };
  const fetchBankDetails = async () => {
    try {
      const response = await getPaymentDetailsCustomer();
      const {
        bankName,
        accountHolderName,
        accountNumber,
        accountType,
        ifscCode,
      } = response.data.data;
      setIsBankDetailsPresent(true);
      setPaymentInforamtion({
        bankName,
        accountHolderName,
        accountNumber,
        accountType,
        ifscCode,
      });
      setConfirmAccountNumber(accountNumber);
    } catch (err) {
      if (err.response.data.message === "Bank account details not found") {
        setPaymentInforamtion(initialBankDetailsState);
      } else {
        handleOpenToast("Unable to fetch profile details", "error");
      }
    }
  };
  useEffect(() => {
    fetchBankDetails();
  }, []);

  const handleCheckIfscCode = async () => {
    await axios
      .get(
        `https://bank-apis.justinclicks.com/API/V1/IFSC/${paymentInformation?.ifscCode?.toUpperCase()}`
      )
      .then((response) => {
        if (response?.status === 200) {
          setIsIfscCodeSuccess(true);
          setPaymentInforamtion({
            ...paymentInformation,
            bankName: response.data.BANK,
          });
        }
      })
      .catch((error) => {
        setIsIfscCodeSuccess(false);
        setPaymentInforamtion({
          ...paymentInformation,
          bankName: "",
        });
      });
  };
  return (
    <Box>
      <ToastMessage />
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ background: "#EAEAEA", fontWeight: 700 }}
        p=".5rem 1rem"
        borderRadius={0.75}
      >
        {"Payment Information"}
        <EditIcon
          onClick={() => setIsBankDetailsPresent(false)}
          style={{ cursor: "pointer" }}
        />
      </Stack>
      <Box component="form" onSubmit={handleBankDetailsSubmission}>
        <Grid container px={3} py={1} spacing={2}>
          <Grid item xs={6}>
            <Stack>
              <StyledInputLabel>Bank Name</StyledInputLabel>
              <Select
                displayEmpty
                disabled={isBankDetailsPresent}
                error={!!errors.bankName}
                size="small"
                name="bankName"
                value={paymentInformation.bankName}
                onChange={handlePaymentInformationChange}
                sx={{ color: paymentInformation.bankName ? "#333" : "#ADADAD" }}
              >
                <MenuItem value="" sx={{ display: "none" }}>
                  Select
                </MenuItem>
                {bankList.map((bank) => (
                  <MenuItem key={bank} value={bank}>
                    {bank}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ color: "#d32f2f", marginLeft: "14px" }}>
                {errors.bankName}
              </FormHelperText>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <StyledInputLabel>IFSC code</StyledInputLabel>
              <TextField
                autoComplete="off"
                placeholder="IFSC"
                disabled={isBankDetailsPresent}
                inputProps={{ maxLength: 11 }}
                fullWidth
                size="small"
                name="ifscCode"
                value={paymentInformation.ifscCode}
                onChange={handlePaymentInformationChange}
                error={!!errors.ifscCode}
                helperText={errors.ifscCode}
                onBlur={handleCheckIfscCode}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <StyledInputLabel>Bank account holder name</StyledInputLabel>
              <TextField
                autoComplete="off"
                placeholder="Account holder name"
                disabled={isBankDetailsPresent}
                fullWidth
                size="small"
                name="accountHolderName"
                value={paymentInformation.accountHolderName}
                onChange={handlePaymentInformationChange}
                error={!!errors.accountHolderName}
                helperText={errors.accountHolderName}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <StyledInputLabel>Account type</StyledInputLabel>
              <Select
                size="small"
                disabled={isBankDetailsPresent}
                displayEmpty
                name="accountType"
                value={paymentInformation.accountType}
                onChange={handlePaymentInformationChange}
                error={!!errors.accountType}
                sx={{
                  color: paymentInformation.accountType ? "#333" : "#ADADAD",
                }}
              >
                <MenuItem value="" disabled sx={{ display: "none" }}>
                  Select
                </MenuItem>
                <MenuItem value="Savings">Savings</MenuItem>
                <MenuItem value="Current">Current</MenuItem>
              </Select>
              <FormHelperText sx={{ color: "#d32f2f", marginLeft: "14px" }}>
                {errors.accountType}
              </FormHelperText>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <StyledInputLabel>Bank account number</StyledInputLabel>
              <TextField
                placeholder="Account number"
                autoComplete="off"
                disabled={isBankDetailsPresent}
                fullWidth
                size="small"
                name="accountNumber"
                value={paymentInformation.accountNumber}
                onChange={handlePaymentInformationChange}
                error={!!errors.accountNumber}
                helperText={errors.accountNumber}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <StyledInputLabel>Confirm bank account number</StyledInputLabel>
              <TextField
                autoComplete="off"
                placeholder="Confirm account number"
                disabled={isBankDetailsPresent}
                fullWidth
                value={confirmAccountNumber}
                onChange={handleConfirmAccountNoInputChange}
                size="small"
                name="confirmaccountNumber"
                error={!!errors.confirmAccountNumber}
                helperText={errors.confirmAccountNumber}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} textAlign="right">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isBankDetailsPresent}
            >
              Verify & Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
