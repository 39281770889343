import { Box, Typography } from "@mui/material";
import React from "react";
import { truncateString } from "../../utils/textFormat";

export const Tag = ({ title, containerStyle }) => {
  return (
    <Box
      display="inline-flex"
      gap={1}
      sx={{ borderRadius: 4, background: "#FFFFFF", p: 1, ...containerStyle }}
    >
      <Typography style={{ fontSize: 16, fontWeight: 400, color: "#6D6D6D" }}>
        {truncateString(title, 50)}
      </Typography>
      <img src="/assets/icons/green-tick.svg" alt="distance arrow" />
    </Box>
  );
};
