import { useState } from "react";
import { Box, Typography } from "@mui/material";
import EnquiryRelated from "../../../../component/Admin/Configurations/TimeRelated/EnquiryRelated";
import CustomerRelated from "../../../../component/Admin/Configurations/TimeRelated/CustomerRelated";
import TransporterRelated from "../../../../component/Admin/Configurations/TimeRelated/TransporterRelated";
import DriverRelated from "../../../../component/Admin/Configurations/TimeRelated/DriverRelated";

const TimeRelated = () => {
  const [expandedSection, setExpandedSection] = useState("");

  // Array of Time Related with their titles and components.
  const inventorySections = [
    { title: "Enquiry Related", component: <EnquiryRelated /> },
    { title: "Customer Related", component: <CustomerRelated /> },
    { title: "Transporter Related", component: <TransporterRelated /> },
    { title: "Driver Related", component: <DriverRelated /> },
  ];

  // Function to toggle expanded section.
  const toggleExpandedSection = (title) => {
    setExpandedSection(expandedSection === title ? "" : title);
  };

  return (
    <div>
      {inventorySections.map((section, index) => (
        <Box key={index} sx={{ margin: "20px 30px" }}>
          <Box
            sx={{
              display: "flex",
              padding: "4px 16px",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "4px",
              background: "#EAEAEA",
              margin: "10px 0",
              cursor: "pointer",
              height: "48px",
            }}
            onClick={() => toggleExpandedSection(section.title)}
          >
            <Typography
              sx={{
                color: "#333",
                fontSize: "16px",
                fontWeight: 700,
              }}
            >
              {section.title}
            </Typography>

            <img
              src={
                expandedSection === section.title
                  ? "/assets/icons/expand_more.svg"
                  : "/assets/icons/expand_less.svg"
              }
              alt="accordian expand icon"
            />
          </Box>
          {expandedSection === section.title && (
            <Box sx={{ margin: "4px 12px" }}>{section.component}</Box>
          )}
        </Box>
      ))}
    </div>
  );
};

export default TimeRelated;
