import { Outlet } from "react-router-dom";
import Profile from "../pages/Transporter/Profile/Profile";
import KYC from "../pages/Transporter/KYC/KYC";
import CitiesAndRoutes from "../pages/Transporter/Accounts/Cities&Routes/index";
import Notification from "../pages/Transporter/Accounts/Notifications";
import Drivers from "../pages/Transporter/Resources/Drivers";
import Truck from "../pages/Transporter/Resources/Truck";
import ComingSoon from "../component/ComingSoon";
import Enquiries from "../pages/Transporter/Enquiries";
import Dashboard from "../pages/Transporter/Dashboard";
import Trips from "../pages/Transporter/Trips";
import TransporterBilling from "../pages/Transporter/Accounts/Billing";
import VehicleInspectionRecords from "../pages/Transporter/Resources/VehicleInspectionRecords";
const iconStyle = { width: "24px", height: "24px" };

export const transporterRoutes = [
  {
    path: "dashboard",
    element: <Dashboard />,
    state: "dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: (
        <img
          src="/assets/sidebarIcons/dashboard.svg"
          style={iconStyle}
          alt="dashboard"
        />
      ),
    },
  },
  {
    path: "enquiries",
    element: <Enquiries />,
    state: "instalenquirieslation",
    sidebarProps: {
      displayText: "Enquiries",
      icon: (
        <img
          src="/assets/sidebarIcons/enquiry.svg"
          style={iconStyle}
          alt="enquiry"
        />
      ),
    },
  },
  {
    path: "trips",
    element: <Trips />,
    state: "trips",
    sidebarProps: {
      displayText: "Trips",
      icon: (
        <img
          src="/assets/sidebarIcons/trips.svg"
          style={iconStyle}
          alt="trips"
        />
      ),
    },
  },
  {
    path: "",
    element: (
      <>
        <Outlet />
      </>
    ),
    state: "resources",
    sidebarProps: {
      displayText: "Resources",
      icon: (
        <img
          src="/assets/sidebarIcons/resources.svg"
          style={iconStyle}
          alt="resources"
        />
      ),
    },
    child: [
      {
        path: "resources/trucks",
        element: <Truck />,
        state: "resources.trucks",
        sidebarProps: {
          displayText: "Vehicle List",
        },
      },
      {
        path: "resources/drivers",
        element: <Drivers />,
        state: "resources.drivers",
        sidebarProps: {
          displayText: "Driver List",
        },
      },
      {
        path: "resources/VehicleLocations",
        element: <VehicleInspectionRecords />,
        state: "resources.VehicleLocations",
        sidebarProps: {
          displayText: "Vehicle Locations",
        },
      },
    ],
  },
  {
    path: "",
    element: (
      <>
        <Outlet />
      </>
    ),
    state: "accounts",
    sidebarProps: {
      displayText: "Account",
      icon: (
        <img
          src="/assets/sidebarIcons/account.svg"
          style={iconStyle}
          alt="accounts"
        />
      ),
    },
    child: [
      {
        path: "accounts/profile",
        element: <Profile />,
        state: "accounts.profile",
        sidebarProps: {
          displayText: "Profile",
        },
      },
      {
        path: "accounts/cities&routes",
        element: <CitiesAndRoutes />,
        state: "accounts.cities&routes",
        sidebarProps: {
          displayText: "Cities & Routes",
        },
      },
      {
        path: "accounts/kyc",
        element: <KYC />,
        state: "accounts.kyc",
        sidebarProps: {
          displayText: "KYC",
        },
      },
      {
        path: "accounts/notifications",
        element: <Notification />,
        state: "accounts.notifications",
        sidebarProps: {
          displayText: "Notifications",
        },
      },

      {
        path: "accounts/billings",
        element: <TransporterBilling />,
        state: "accounts.billings",
        sidebarProps: {
          displayText: "Billing",
        },
      },
    ],
  },
];
