import { client } from "../config";

// get all driver data.
export const getAllDriverData = async (
  transporterId,
  currentPage,
  pageSize,
  status,
  phone = ""
) => {
  try {
    const response = await client.get(
      `transporter/${transporterId}/drivers?page=${currentPage}&pageSize=${pageSize}&status=${status}&phone=${phone}`
    );
    return response;
  } catch (err) {
    return err;
  }
};

// Function to add a new driver data.
export const addNewDriver = async (newDriverData) => {
  try {
    const response = await client.post(
      `/transporter/invite-driver`,
      newDriverData
    );
    return response;
  } catch (error) {
    return error;
  }
};

// Function to edit/update a driver.
export const editDriverData = async (
  transporterId,
  driverId,
  updatedDriverData
) => {
  try {
    const response = await client.put(
      `/transporter/${transporterId}/drivers/${driverId}`,
      updatedDriverData
    );
    return response;
  } catch (error) {
    return error;
  }
};

// Function to delete a selected driver.
export const deleteSelectedDriver = async (transporterId, driverId) => {
  try {
    const response = await client.delete(
      `transporter/${transporterId}/drivers/${driverId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

// Function to fetch a selected driver.
export const getSelectedDriver = async (transporterId, driverId) => {
  try {
    const response = await client.get(
      `transporter/${transporterId}/drivers/${driverId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

// Function to update driver access level
export const updateDriverAccessLevel = async (
  transporterId,
  driverId,
  isSuperDriver
) => {
  try {
    const response = await client.patch(
      `transporter/${transporterId}/drivers/${driverId}/access-level`,
      { isSuperDriver }
    );
    return response;
  } catch (error) {
    return error;
  }
};

// Function to update driver availability status
export const updateDriverAvailabilityStatus = async (
  transporterId,
  driverId,
  isAvailable
) => {
  try {
    const response = await client.patch(
      `transporter/${transporterId}/drivers/${driverId}/availability-status`,
      { isAvailable }
    );
    return response;
  } catch (error) {
    return error;
  }
};
