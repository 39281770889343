import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import { USER_ROLES } from "../../../constants/constant";
import moment from "moment";
import { cancelTrip } from "../../../api/Admin/trips";

const CancelTripModal = ({
  isModalOpen,
  handleCloseModal,
  pickupLocation,
  dropoffLocation,
  distance,
  tripId,
  vehicleCategory,
  handleOpenToast,
  getTripById,
  vehicleName,
}) => {
  const [requestedBy, setRequestedBy] = useState("");
  const [description, setDescription] = useState("");
  const [requestedOn, setRequestedOn] = useState("");
  const handleCancelTrip = async () => {
    if (!requestedBy || !requestedOn || !description) {
      handleOpenToast("Please fill all the fields", "error");
      return;
    }

    if (moment(requestedOn).isAfter(moment())) {
      handleOpenToast("Requested on cannot be a future date", "error");
      return;
    }

    try {
      const response = await cancelTrip(
        tripId,
        requestedBy.toUpperCase(),
        description,
        requestedOn,
        vehicleCategory
      );

      if (response.status === 200) {
        getTripById();
        handleCloseModal();
        handleOpenToast("Trip Cancelled Successfully", "success");
      }
    } catch (error) {
      handleOpenToast("Failed to cancel trip", "error");
    }
  };

  useEffect(() => {
    setRequestedOn(moment().format("YYYY-MM-DDTHH:mm"));
  }, []);

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleCloseModal}
      sx={{ width: "100%" }}
    >
      <DialogTitle sx={{ color: "#333" }}>
        <Typography fontWeight={700} fontSize={24}>
          Cancel Trip
        </Typography>
      </DialogTitle>
      <StyledCloseIcon fontSize="medium" onClick={handleCloseModal} />
      <DialogContent>
        <DialogBox sx={{ display: "flex", flexDirection: "column" }}>
          <StyledLocationBox>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <Box sx={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                <Box sx={{ maxWidth: "200px" }}>
                  <StyledLocationHeader>Pickup Location</StyledLocationHeader>
                  <StyledLocation>{pickupLocation}</StyledLocation>
                </Box>
                <Box sx={{ maxWidth: "200px" }}>
                  <StyledLocationHeader>Dropoff Location</StyledLocationHeader>
                  <StyledLocation>{dropoffLocation}</StyledLocation>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <Box>
                  <StyledLocationHeader>Vehicle</StyledLocationHeader>
                  <StyledLocation>{vehicleName}</StyledLocation>
                </Box>
                <Box>
                  <StyledLocationHeader>Distance</StyledLocationHeader>
                  <StyledLocation>{distance} km</StyledLocation>
                </Box>
                <Box>
                  <StyledLocationHeader>Vehicle Category</StyledLocationHeader>
                  <StyledLocation>{vehicleCategory} Wheeler</StyledLocation>
                </Box>
              </Box>
            </Box>
          </StyledLocationBox>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                width: "100%",
              }}
            >
              <Grid item xs={6} sx={{ width: "100%" }}>
                <InputLabel
                  sx={{
                    color: "#333",
                    fontSize: "1.125rem",
                    fontWeight: 700,
                  }}
                  htmlFor="noOfPieces"
                >
                  Requested by
                </InputLabel>
                <StyledTextField
                  select
                  name="requestedBy"
                  label={requestedBy ? "" : "Select Requested By"}
                  value={requestedBy}
                  onChange={(e) => setRequestedBy(e.target.value)}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: false }}
                >
                  {USER_ROLES.map((user, index) => (
                    <MenuItem key={index} value={user}>
                      {user}
                    </MenuItem>
                  ))}
                </StyledTextField>
              </Grid>
              <Grid item xs={6} sx={{ width: "100%" }}>
                <Box
                  sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  <InputLabel
                    sx={{
                      color: "#333",
                      fontSize: "1.125rem",
                      fontWeight: 700,
                    }}
                    htmlFor="noOfPieces"
                  >
                    Requested On
                  </InputLabel>
                  <Tooltip
                    title="Time at which the trip cancellation is requested"
                    placement="top"
                    arrow
                  >
                    <img src="/assets/icons/info.svg" alt="distance arrow" />
                  </Tooltip>
                </Box>
                <StyledTextField
                  type="datetime-local"
                  name="pickupTime"
                  value={requestedOn}
                  onChange={(e) => setRequestedOn(e.target.value)}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: false }}
                />
              </Grid>
            </Box>
            <Grid item xs={12}>
              <InputLabel
                sx={{ color: "#333", fontSize: "1.125rem", fontWeight: 700 }}
                htmlFor="description"
              >
                Reason for cancellation
              </InputLabel>
              <StyledTextField
                name="description"
                label={description ? "" : "Enter Reason"}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                InputLabelProps={{ shrink: false }}
              />
            </Grid>
          </Box>
        </DialogBox>
        <StyledCancelTripBox>
          <StyledCancelText>
            Are you sure you want to cancel this trip?
          </StyledCancelText>
          <StyledButton variant="contained" onClick={handleCancelTrip}>
            Cancel Trip
          </StyledButton>
        </StyledCancelTripBox>
      </DialogContent>
    </Dialog>
  );
};

export default CancelTripModal;

const DialogBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  mb: 2,
  gap: "20px",
  width: "500px",
}));

const StyledCloseIcon = styled(CloseIcon)(() => ({
  position: "absolute",
  right: "10px",
  top: "14px",
  cursor: "pointer",
}));

const StyledButton = styled(Button)(() => ({
  padding: "8px 16px",
  fontSize: "18px",
  margin: "14px 0",
  textTransform: "none",
  backgroundColor: "#FF3F3F",
}));

const StyledLocationHeader = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: "12px",
}));

const StyledLocation = styled(Typography)(() => ({
  fontSize: "12px",
  color: "#6D6D6D",
  fontWeight: 400,
}));

const StyledLocationBox = styled(Box)(() => ({
  bgcolor: "#EAEAEA",
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  width: "100%",
  justifyContent: "space-between",
  padding: "20px",
}));

const StyledCancelTripBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledCancelText = styled(Typography)(() => ({
  fontSize: "14px",
  fontWeight: 400,
  textAlign: "center",
  color: "#F10505",
  mt: 2,
}));

const StyledTextField = styled(TextField)(() => ({
  "& .MuiOutlinedInput-input:-webkit-autofill": {
    WebkitBoxShadow: "0 0 0 1000px white inset",
  },
  "& .MuiInputLabel-outlined.Mui-focused": {
    color: "gray",
  },
  "& .MuiInputLabel-outlined": {
    color: "gray",
  },
}));
