import React from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import HeaderWithUnderline from "../Configurations/Enquiries/HeaderWithUnderline";
import NameDescription from "../Configurations/Enquiries/NameDescription";

export default function VehicleDetails({ vehicleDetails, driver }) {
  return (
    <Box sx={{ padding: "24px" }}>
      <HeaderWithUnderline header="Vehicle Details" />

      <StyledBox>
        <BoxContainer marginLeft="0px" gap="40px">
          <NameDescription
            name="Truck Number"
            description={vehicleDetails?.vehicleNumber || "-"}
            color="#6D6D6D"
            marginLeft="0px"
          />
          <NameDescription
            name="Truck Type"
            description={`${vehicleDetails?.vehicleCategory} Wheeler` || "-"}
            marginLeft="0px"
            color="#6D6D6D"
          />

          <NameDescription
            name="Truck Model"
            description={vehicleDetails?.vehicleModelName || "-"}
            marginLeft="0px"
            color="#6D6D6D"
          />
          <NameDescription
            name="Truck Capacity"
            description={
              `${vehicleDetails?.maxCapacity / 1000} ${
                vehicleDetails?.maxCapacity / 1000 <= 1 ? "Ton" : "Tons"
              }` || "-"
            }
            marginLeft="0px"
            color="#6D6D6D"
          />
          <NameDescription
            name="Driver Name"
            description={driver?.driverName || "-"}
            marginLeft="0px"
            color="#6D6D6D"
          />
          <NameDescription
            name="Driver Contact Number"
            description={driver?.driverContact || "-"}
            marginLeft="0px"
            color="#0C7F44"
          />
        </BoxContainer>
      </StyledBox>
    </Box>
  );
}

const BoxContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  paddingTop: "16px",
  paddingLeft: "32px",
  paddingRight: "32px",
}));

const StyledBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
