import React, { useRef, useImperativeHandle } from "react";
import { Typography, Box, Grid, Divider, Chip, Avatar } from "@mui/material";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ContactInformation from "./ContactInformation";
import Declaration from "./Declaration";
import Consignment from "./Consignment";
import moment from "moment";

const Invoice = React.forwardRef(({ tripDetails, isPaymentReceipt }, ref) => {
  const pdfRef = useRef(null);
  const generatePDF = () => {
    if (!pdfRef.current) return;

    html2canvas(pdfRef.current, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
      pdf.link(58, 235, 65, 5, {
        url: "https://loadeazy.com/terms&conditions",
      });
      pdf.link(147, 255, 65, 5, {
        url: "mailto:juggernautventures23@gmail.com",
      });
      let filename = isPaymentReceipt
        ? `${tripDetails.tripId}_paymentReceipt.pdf`
        : `${tripDetails.tripId}_invoice.pdf`;
      pdf.save(filename);
    });
  };

  useImperativeHandle(ref, () => ({
    generatePDF,
  }));

  const FareItem = ({ label, amount, bold = false }) => (
    <Grid container my={2}>
      <Grid item xs={6}>
        <Typography
          variant="body1"
          component="span"
          sx={{ color: "#4a4a4a", fontWeight: bold ? "bold" : "normal" }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: "right" }}>
        <Typography
          variant="body1"
          component="span"
          sx={{ color: "#4a4a4a", fontWeight: bold ? "bold" : "normal" }}
        >
          ₹{amount}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Box
      ref={pdfRef}
      id="pdf-content"
      sx={{
        position: "absolute",
        top: -9999,
        left: -9999,
        width: "210mm",
        padding: 4,
        border: "1px solid #000",
        backgroundColor: "#fff",
        fontSize: "8px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "297mm",
      }}
    >
      <Box>
        <Typography
          variant="h6"
          component="h2"
          align="center"
          gutterBottom
          sx={{ fontWeight: "bold", fontSize: "24px" }}
        >
          {isPaymentReceipt ? "PAYMENT RECEIPT" : "INVOICE / CONSIGNMENT NOTE"}
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Box sx={{ m: 2 }}>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="/assets/icons/LZ-logo-paymnet-page.png"
                    alt="logo"
                    style={{
                      height: "200px",
                      display: "block",
                      margin: "auto",
                    }}
                  />
                </Box>
                <Consignment />
                <Box
                  sx={{
                    mt: 1,
                    p: 2,
                    borderRadius: 2,
                    backgroundColor: "#F0F0F0",
                  }}
                >
                  <Typography variant="body1" sx={{ color: "#4a4a4a", mb: 1 }}>
                    MATERIAL NAME
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#4a4a4a",
                      fontWeight: "bold",
                      fontSize: "10px",
                    }}
                  >
                    {tripDetails?.enquiryDetails?.material?.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 1,
                    p: 2,
                    borderRadius: 2,
                    backgroundColor: "#F0F0F0",
                  }}
                >
                  <Typography variant="body1" sx={{ color: "#4a4a4a", mb: 1 }}>
                    MATERIAL WEIGHT
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#4a4a4a",
                      fontWeight: "bold",
                      fontSize: "10px",
                    }}
                  >
                    {tripDetails?.enquiryDetails?.weight} KG
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ textAlign: "right", fontSize: "12px" }}>
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: "12px" }}
                    component="span"
                  >
                    {isPaymentReceipt ? "RECEIPT NO:" : "INVOICE NUMBER:"}
                  </Typography>
                  <Typography component="span" sx={{ fontSize: "12px" }}>
                    {`LEIV${tripDetails?.tripId.split("_")[1]}`}
                  </Typography>
                </Box>
                <Box sx={{ textAlign: "right" }}>
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: "12px" }}
                    component="span"
                  >
                    DATE:
                  </Typography>
                  <Typography component="span" sx={{ fontSize: "12px" }}>
                    {tripDetails?.customerPaymentStatus === "PAID"
                      ? moment(
                          tripDetails?.transactions?.customerTransactions[0]
                            ?.transactedAt
                        ).format("DD/MM/YYYY")
                      : moment(tripDetails?.tripCompletedTimeStamp).format(
                          "DD/MM/YYYY"
                        )}
                  </Typography>
                </Box>
                {isPaymentReceipt ? (
                  <Box
                    sx={{
                      mt: 2,
                      p: 2,
                      borderRadius: 2,
                      backgroundColor: "#F0F0F0",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h4"
                      component="h3"
                      sx={{
                        color: "#4a4a4a",
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                    >
                      PAYMENT STATUS
                    </Typography>
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "14px",
                        color: "#4a4a4a",
                        fontWeight: "bold",
                      }}
                    >
                      {tripDetails.customerPaymentStatus}
                    </Typography>
                  </Box>
                ) : null}

                <Box
                  sx={{
                    mt: 2,
                    p: 2,
                    borderRadius: 2,
                    backgroundColor: "#F0F0F0",
                  }}
                >
                  {!isPaymentReceipt ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Typography
                            variant="h4"
                            component="span"
                            sx={{
                              color: "#4a4a4a",
                              fontWeight: "bold",
                              fontSize: "14px",
                            }}
                          >
                            Total Amount
                          </Typography>
                          <Typography
                            variant="body1"
                            component="span"
                            sx={{
                              color: "#4a4a4a",
                              fontWeight: "bold",
                              ml: 1,
                              mt: 1,
                            }}
                          >
                            (Rounded)
                          </Typography>
                        </Box>

                        <Typography
                          variant="body1"
                          component="span"
                          sx={{
                            color: "#4a4a4a",
                            fontWeight: "bold",
                            ml: 1,
                            mt: 1,
                          }}
                        >
                          ₹{tripDetails?.customerAmounts?.totalAmount}
                        </Typography>
                      </Box>
                      <Divider sx={{ borderColor: "#4a4a4a", my: 1 }} />
                      <FareItem
                        label="Trip Fare"
                        amount={tripDetails?.customerAmounts?.totalAmount}
                      />
                      <FareItem label="Discount" amount="0.00" />
                      <Divider sx={{ borderColor: "#4a4a4a", my: 1 }} />
                      <FareItem
                        label="Sub Total"
                        amount={tripDetails?.customerAmounts?.totalAmount}
                        bold
                      />
                      <FareItem label="CGST" amount="0.00" />
                      <FareItem label="SGST" amount="0.00" />
                      <Divider sx={{ borderColor: "#4a4a4a", my: 1 }} />
                      <FareItem
                        label="Net Fare"
                        amount={tripDetails?.customerAmounts?.totalAmount}
                        bold
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 1,
                        }}
                      >
                        <Chip
                          label="Happy to complete your trip at promised fare."
                          variant="outlined"
                          sx={{
                            width: "100%",
                            textAlign: "center",
                            borderRadius: "25px",
                            fontWeight: "bold",
                            background: "#E0E0E0",
                            fontSize: "12px",
                          }}
                        />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h4"
                          component="h1"
                          sx={{
                            color: "#4a4a4a",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          Receipt
                        </Typography>
                        <Divider sx={{ borderColor: "#4a4a4a", my: 1 }} />
                        <FareItem
                          label="Advance Paymnet"
                          amount={tripDetails?.customerAmounts?.amountReceived}
                        />
                        <FareItem
                          label="Balance Receivable"
                          amount={
                            tripDetails?.customerAmounts?.balanceReceivable
                          }
                        />
                        <Divider sx={{ borderColor: "#4a4a4a", my: 1 }} />
                        <FareItem
                          label="Total Amount"
                          amount={tripDetails?.customerAmounts?.totalAmount}
                          bold
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 1,
                          }}
                        >
                          <Chip
                            label="Happy to complete your trip at promised fare."
                            variant="outlined"
                            sx={{
                              width: "100%",
                              textAlign: "center",
                              borderRadius: "25px",
                              fontWeight: "bold",
                              background: "#E0E0E0",
                              fontSize: "12px",
                            }}
                          />
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>

                <Box>
                  <Box
                    sx={{
                      borderRadius: 2,
                    }}
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            mt: 1,
                            p: 2,
                            borderRadius: 2,
                            backgroundColor: "#F0F0F0",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: "4px",
                              }}
                            >
                              <img
                                src="/assets/icons/vehicle.png"
                                width={20}
                                alt="comingsoon"
                              />
                              <Typography
                                variant="h4"
                                component="h3"
                                sx={{
                                  color: "#4a4a4a",
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                }}
                              >
                                Vehicle Number
                              </Typography>
                            </Box>{" "}
                            <Typography
                              variant="h4"
                              component="h3"
                              sx={{
                                color: "#4a4a4a",
                                fontWeight: "bold",
                                fontSize: "14px",
                                marginLeft: "24px",
                              }}
                            >
                              {tripDetails?.truck?.VehicleNumber ?? "-"}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: "4px",
                              }}
                            >
                              <img
                                src="/assets/icons/driver.png"
                                width={20}
                                alt="comingsoon"
                              />
                              <Typography
                                variant="h4"
                                component="h3"
                                sx={{
                                  color: "#4a4a4a",
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                }}
                              >
                                Driver Name
                              </Typography>
                            </Box>{" "}
                            <Typography
                              variant="h4"
                              component="h3"
                              sx={{
                                color: "#4a4a4a",
                                fontWeight: "bold",
                                fontSize: "14px",
                                marginLeft: "24px",
                              }}
                            >
                              {tripDetails?.driver?.driverName ?? "-"}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={5.5}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "4px",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "10px", fontWeight: "bold" }}
                          >
                            Pickup Person Details
                          </Typography>
                          <Typography variant="body1" sx={{ fontSize: "10px" }}>
                            Name:
                            {tripDetails?.pickupContactPerson?.name ?? "-"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "10px" }}>
                            Contact Number:
                            {tripDetails?.pickupContactPerson?.phoneNumber ??
                              "-"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "10px" }}>
                            Address:
                            {tripDetails.enquiryDetails.pickup.location
                              .address ?? "-"}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid
                        item
                        xs="auto"
                        sx={{ display: "flex", alignItems: "stretch" }}
                      >
                        <Box
                          sx={{
                            width: "1px",
                            backgroundColor: "#E0E0E0",
                            height: "85px",
                          }}
                        />
                      </Grid>

                      <Grid item xs={5.5}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "4px",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "10px", fontWeight: "bold" }}
                          >
                            Drop-off Person Details
                          </Typography>
                          <Typography variant="body1" sx={{ fontSize: "10px" }}>
                            Name:{" "}
                            {tripDetails?.dropOffContactPerson?.name ?? "-"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "10px" }}>
                            Contact Number:{" "}
                            {tripDetails?.dropOffContactPerson?.phoneNumber ??
                              "-"}
                          </Typography>
                          <Typography variant="body2" sx={{ fontSize: "10px" }}>
                            Address:{" "}
                            {tripDetails.enquiryDetails.dropoff.location
                              .address ?? "-"}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Declaration />
            </Grid>
          </Box>
        </Grid>
      </Box>
      <Box>
        <ContactInformation />
      </Box>
    </Box>
  );
});

export default Invoice;
