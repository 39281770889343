import { adminApi, client, customerApi } from "./config";

const lastUpdatedAt = new Date();
const updateCustomerFCMToken = (token) => {
  return customerApi.put("/customer/fcm-token", { token, lastUpdatedAt });
};

const updateTransporterFCMToken = (token) => {
  return client.put("/transporter/fcm-token", { token, lastUpdatedAt });
};

const updateAdminFCMToken = (token) => {
  return adminApi.put("admin/fcm-token", { token, lastUpdatedAt });
};
export {
  updateCustomerFCMToken,
  updateTransporterFCMToken,
  updateAdminFCMToken,
};