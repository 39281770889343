import { React, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import useToast from "../../../hooks/useToast";
import AddEditVehicleModal from "./VehicleType/AddEditVehicleModal";
import DeleteVehicleTypeModal from "./VehicleType/DeleteVehicleTypeModal";
import {
  deleteSelectedVehicle,
  getAllVehicle,
} from "../../../api/Admin/vehicleType";
import AddButton from "../../Common/AddButton";
import { StyledTableRow } from "../../Common/StyledTableRow";
import { StyledTableCell } from "../../../pages/Admin/Approval/StyledTableCell";

const VehicleType = () => {
  // Array to store vehicle type headers.
  const vehicleTypeHeaders = [
    "Mfr's Name",
    "Model Name",
    "Commonly known",
    "Wheels",
    "Payload (Kgs)",
    "Fuel Type",
  ];

  // Varibale to show loading state.
  const [loadingState, setLoadingState] = useState("LOADING");

  // Varibale to show toast messages.
  const { toastData, handleCloseToast, handleOpenToast, ToastMessage } =
    useToast();
  const [vehicleTypeTableData, setVehicleTypeTableData] = useState([]);

  //Variable to show whether Add/Edit vehicle modal is open or not.
  const [isAddEditVehicleModalOpen, setIsAddEditVehicleModalOpen] =
    useState(false);

  //Variable to store Edit vehicle data.
  const [addEditVehicleModalData, setAddEditVehicleModalData] = useState(false);

  //Variable to show whether Delete vehicle modal is open or not.
  const [isDeleteVehicleModalOpen, setIsDeletVehicleModalOpen] =
    useState(false);

  //Variable to show pagination data.
  const [paginationData, setPaginationData] = useState({});

  // Manages the current page number in pagination
  const [page, setPage] = useState(0);

  // Handles a change in page number in pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Open Add Vehicle Modal.
  const openAddVehicleTypeModal = () => {
    setIsAddEditVehicleModalOpen(true);
    setAddEditVehicleModalData(null);
  };

  // Open Edit Vehicle Modal.
  const handleEditVehicleModalData = (index) => {
    setIsAddEditVehicleModalOpen(true);
    setAddEditVehicleModalData(vehicleTypeTableData[index]);
  };

  // Closes the Add/Edit Vehicle Modal.
  const closeAddEditVehicleTypeModal = () => {
    setIsAddEditVehicleModalOpen(false);
  };

  // Open Vehicle modal on delete button click.
  const handleDeleteVehicleTypeButtonClick = () => {
    closeAddEditVehicleTypeModal();
    setIsDeletVehicleModalOpen(true);
  };

  // Closes the Delete Vehicle Modal.
  const closeDeleteVehicleModal = () => {
    setIsDeletVehicleModalOpen(false);
  };

  // Function to fetch all Vehicle details.
  const fetchAllVehicleType = async () => {
    try {
      const response = await getAllVehicle(page + 1, 10);
      if (response.status === 200) {
        setLoadingState("LOADED");
        setVehicleTypeTableData(response?.data?.data?.truckTypes);
        setPaginationData(response?.data?.data?.pagination);
      }
    } catch (error) {
      setLoadingState("FAILED");
      handleOpenToast(`Vehicle data fetching failed.`, "error");
    }
  };

  // Function to fetch all Vehicle details.
  const handleDeleteVehicleTypeData = async () => {
    try {
      const response = await deleteSelectedVehicle(
        addEditVehicleModalData.vehicleTypeId
      );
      if (response.status === 200) {
        handleOpenToast("Vehicle type deleted successfully.", "success");
        await fetchAllVehicleType();
      } else {
        handleOpenToast(`Vehicle deletion failed.`, "error");
      }
    } catch (error) {
      handleOpenToast(`Vehicle deletion failed.`, "error");
    }
    closeDeleteVehicleModal();
  };

  useEffect(() => {
    fetchAllVehicleType();
  }, [page]);

  switch (loadingState) {
    case "LOADING":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            width: "100%",
          }}
        >
          <CircularProgress />
        </div>
      );

    case "LOADED":
      return (
        <>
          <ToastMessage
            open={toastData.open}
            message={toastData.message}
            severity={toastData.severity}
            handleClose={handleCloseToast}
          />
          <Modal
            open={isAddEditVehicleModalOpen}
            onClose={closeAddEditVehicleTypeModal}
          >
            <Box>
              <AddEditVehicleModal
                closeAddEditVehicleTypeModal={closeAddEditVehicleTypeModal}
                addEditVehicleModalData={addEditVehicleModalData}
                handleDeleteVehicleTypeButtonClick={
                  handleDeleteVehicleTypeButtonClick
                }
                handleOpenToast={handleOpenToast}
                fetchAllVehicleType={fetchAllVehicleType}
              />
            </Box>
          </Modal>
          <Modal
            open={isDeleteVehicleModalOpen}
            onClose={closeDeleteVehicleModal}
          >
            <Box>
              <DeleteVehicleTypeModal
                closeDeleteVehicleModal={closeDeleteVehicleModal}
                handleDeleteVehicleTypeData={handleDeleteVehicleTypeData}
                addEditVehicleModalData={addEditVehicleModalData}
              />
            </Box>
          </Modal>
          <AddButton onClick={openAddVehicleTypeModal} name="Add Vehicle" />
          <TableContainer
            component={Paper}
            sx={{
              width: "100%",
              height: "100%",
              boxShadow: "none",
              border: "none",
              marginTop: "5px",
            }}
          >
            <Table size="small" aria-label="Truck data">
              <TableHead>
                <TableRow>
                  {vehicleTypeHeaders.map((header, index) => (
                    <StyledTableCell key={index}>{header}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!vehicleTypeTableData ? (
                  <div style={{ marginTop: "2rem" }}>No truck data found</div>
                ) : (
                  vehicleTypeTableData.map((vehicle, index) => (
                    <StyledTableRow
                      key={index}
                      style={{
                        textDecoration: "none",
                        marginTop: "1rem",
                        cursor: "pointer",
                      }}
                    >
                      <TableCell
                        scope="row"
                        sx={{ color: "#6D6D6D", textAlign: "center" }}
                        onClick={() => handleEditVehicleModalData(index)}
                      >
                        {vehicle.manufacturerName?.length > 20
                          ? vehicle.manufacturerName.substring(0, 0 + 21) +
                            "..."
                          : vehicle.manufacturerName?.length <= 20
                          ? vehicle.manufacturerName
                          : "-"}
                      </TableCell>
                      <TableCell
                        scope="row"
                        sx={{ color: "#6D6D6D", textAlign: "center" }}
                        onClick={() => handleEditVehicleModalData(index)}
                      >
                        {vehicle.modelName?.length > 20
                          ? vehicle.modelName.substring(0, 0 + 21) + "..."
                          : vehicle.modelName?.length <= 20
                          ? vehicle.modelName
                          : "-"}
                      </TableCell>
                      <TableCell
                        sx={{ color: "#6D6D6D", textAlign: "center" }}
                        onClick={() => handleEditVehicleModalData(index)}
                      >
                        {vehicle.commonlyKnownAs
                          ? vehicle.commonlyKnownAs
                          : "-"}
                      </TableCell>
                      <TableCell
                        sx={{ color: "#6D6D6D", textAlign: "center" }}
                        onClick={() => handleEditVehicleModalData(index)}
                      >
                        {vehicle.numberOfWheels ? vehicle.numberOfWheels : "-"}
                      </TableCell>
                      <TableCell
                        sx={{ color: "#6D6D6D", textAlign: "center" }}
                        onClick={() => handleEditVehicleModalData(index)}
                      >
                        {vehicle.capacity ? vehicle.capacity : "-"}
                      </TableCell>
                      <TableCell
                        sx={{ color: "#6D6D6D", textAlign: "center" }}
                        onClick={() => handleEditVehicleModalData(index)}
                      >
                        {vehicle.fuelType ? vehicle.fuelType : "-"}
                      </TableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={paginationData.totalItems}
            rowsPerPage={paginationData.pageSize}
            page={page}
            onPageChange={handleChangePage}
            labelDisplayedRows={({ count }) => (
              <>
                Page{" "}
                <span style={{ color: "green", fontWeight: 700 }}>
                  {page + 1}
                </span>{" "}
                of {Math.ceil(count / paginationData.pageSize)}
              </>
            )}
          />
        </>
      );

    case "FAILED":
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            justifyContent: "center",
            alignItems: "center",
            height: "20vh",
          }}
        >
          <p>Couldn't Fetch Vehicle Details</p>
          <button onClick={() => fetchAllVehicleType()}>Retry</button>
        </div>
      );
    default:
      return <h1>something went wrong!</h1>;
  }
};

export default VehicleType;
