import React from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";

import {
  ADMIN_QUOTE_STATUS,
  ADMIN_QUOTE_STATUS_COLOR,
} from "../../../../constants/constant";

const StatusText = styled(Typography)(({ color }) => ({
  color: color || "#ffffff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "12px",
  fontWeight: "700",
  padding: "10px",
}));

export default function AdminStatusCell({ status, preview }) {
  return (
    <StatusText
      color={
        ADMIN_QUOTE_STATUS_COLOR[status]
          ? ADMIN_QUOTE_STATUS_COLOR[status]
          : preview
          ? "#333"
          : "#ADADAD"
      }
    >
      {ADMIN_QUOTE_STATUS[status] ? ADMIN_QUOTE_STATUS[status] : "-"}
    </StatusText>
  );
}
