import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Box } from "@mui/material";

import EnquiryDetails from "../Enquiries/EnquiryDetails";
import TripDetails from "../Trips/TripDetails";
import { getTransporterTripById } from "../../../api/Transporter/transporterTrips";
import SupportNumber from "./SupportNumber";
import useToast from "../../../hooks/useToast";
import MapTracking from "./MapTracking";
import InTransitMapTracking from "./IntransitMapTracking";

export default function DashboardDetailedView(props) {
  const {
    entityId,
    transporterId,
    enquiryStatus,
    entityType,
    selectedTripType,
    selectedTripView,
    trips,
    getTripsByInTransitStatus,
  } = props;
  const { handleOpenToast } = useToast();

  const selectedRow = useSelector((state) => state.transporter.selectedRow);

  const [tripDetails, setTripDetails] = useState([]);

  const tripByTransporterId = async () => {
    try {
      const response = await getTransporterTripById(transporterId, entityId);
      if (response?.status === 200) {
        setTripDetails(response.data.data);
      }
    } catch (error) {
      handleOpenToast("Error while getting trip details", "error");
    }
  };

  useEffect(() => {
    if (
      (entityType === "trip" && selectedTripType === "inTransit") ||
      (entityType === "trip" && selectedTripType === "upComing")
    ) {
      tripByTransporterId();
    }
  }, [transporterId, entityId]);

  if (!entityId)
    return (
      <Box
        borderRadius="5px"
        bgcolor="#E9F2F4"
        border="2px solid #5386E4"
        height="100%"
        fontWeight="700"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        No enquiries are available for display at the moment.
      </Box>
    );

  return (
    <Box
      borderRadius="5px"
      bgcolor="#E9F2F4"
      border="2px solid #5386E4"
      overflow="auto"
      height="100%"
    >
      <Box height="100%">
        {entityType === "enquiry" && (
          <EnquiryDetails
            selectedRow={selectedRow}
            transporterId={transporterId}
            transporterEnquiryId={entityId}
            enquiryStatus={enquiryStatus}
            fromDashboard={true}
            entityId={entityId}
            handleOpenToast={handleOpenToast}
          />
        )}

        {entityType === "trip" && selectedTripView === "details" && (
          <TripDetails
            tripDetails={tripDetails}
            transporterId={transporterId}
            tripByTransporterId={getTripsByInTransitStatus}
            fromDashboard={true}
            trips={trips}
            entityId={entityId}
          />
        )}

        {entityType === "trip" &&
          selectedTripView === "map" &&
          selectedTripType === "upComing" && (
            <MapTracking trips={trips} entityId={entityId} />
          )}

        {entityType === "trip" &&
          selectedTripView === "map" &&
          selectedTripType === "inTransit" && (
            <InTransitMapTracking trips={trips} entityId={entityId} />
          )}

        {entityType === "trip" && selectedTripView === "support" && (
          <SupportNumber
            tripDetails={tripDetails}
            trips={trips}
            entityId={entityId}
          />
        )}
      </Box>
    </Box>
  );
}
