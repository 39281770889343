import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  name: "",
  email: "",
  phone: "",
  selectedRow: null,
  enquiryStatusTracking: "",
  bookingStatusTracking: "",
  notificationPreferences: {},
};
const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomerId: (state, action) => {
      state.id = action.payload;
    },
    setCustomerName: (state, action) => {
      state.name = action.payload;
    },
    setSelectedRow: (state, action) => {
      state.selectedRow = action.payload;
    },
    setEnquiryStatusTracking: (state, action) => {
      state.enquiryStatusTracking = action.payload;
    },
    setBookingStatusTracking: (state, action) => {
      state.bookingStatusTracking = action.payload;
    },
    setNotificationPreference: (state, action) => {
      state.notificationPreferences = action.payload;
    },
    setCustomerProfile: (state, action) => {
      return (state = {
        ...state,
        id: action.payload._id,
        name: action.payload.fullName,
        email: action.payload.email,
        phone: action.payload.phone,
        notificationPreferences: action.payload.notificationPreferences,
      });
    },
  },
});

export const {
  setCustomerId,
  setCustomerName,
  setSelectedRow,
  setEnquiryStatusTracking,
  setCustomerProfile,
  setBookingStatusTracking,
  setNotificationPreference,
} = customerSlice.actions;
export default customerSlice.reducer;
