import React from "react";
import { Box } from "@mui/system";
import { Button } from "@mui/material";

const DeleteVehicleTypeModal = ({
  closeDeleteVehicleModal,
  handleDeleteVehicleTypeData,
  addEditVehicleModalData,
}) => {
  const vehicleDetailParagraphContainer = {
    display: "flex",
    minHeight: "73px",
    minWidth: "100px",
    padding: "8px 16px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    background: "#EAEAEA",
    fontSize: "14px",
    fontWeight: 700,
    color: "#333",
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "white",
        p: 2,
        borderRadius: 4,
        width: "600px",
        height: "400px",
      }}
    >
      <img
        src="/assets/icons/cross-icon.svg"
        alt="cross"
        style={{
          position: "absolute",
          right: "4%",
          top: "5%",
        }}
        onClick={() => closeDeleteVehicleModal()}
      />
      <Box
        sx={{
          position: "absolute",
          left: "4%",
          top: "5%",
          fontSize: "24px",
          fontWeight: "700",
        }}
      >
        Delete Truck Type
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "40px",
          height: "100%",
          marginTop: "32px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <Box sx={vehicleDetailParagraphContainer}>
            {addEditVehicleModalData.modelName.length > 11
              ? addEditVehicleModalData.modelName.substring(0, 0 + 12) + "..."
              : addEditVehicleModalData.modelName.length <= 11
              ? addEditVehicleModalData.modelName
              : "-"}
          </Box>
          <Box sx={vehicleDetailParagraphContainer}>
            {addEditVehicleModalData.manufacturerName.length > 11
              ? addEditVehicleModalData.manufacturerName.substring(0, 0 + 12) +
                "..."
              : addEditVehicleModalData.manufacturerName.length <= 11
              ? addEditVehicleModalData.manufacturerName
              : "-"}
          </Box>
          <Box sx={vehicleDetailParagraphContainer}>
            {addEditVehicleModalData.numberOfWheels} Wheeler
          </Box>
        </Box>
        <Box
          sx={{
            color: "#FF3F3F",
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          Are you sure you want to delete this truck type?
        </Box>

        <Box sx={{ display: "flex", gap: "20px" }}>
          <Button
            sx={{
              background: "#FF3F3F",
              color: "white",
              "&:hover": {
                background: "#FF3F3F",
              },
              textTransform: "none",
            }}
            onClick={handleDeleteVehicleTypeData}
          >
            Confirm Delete
          </Button>
          <Button
            sx={{
              background: "#FFF",
              border: "1px solid #333",
              color: "#333",
              textTransform: "none",
            }}
            onClick={() => closeDeleteVehicleModal()}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteVehicleTypeModal;
