import { customerApi } from "../config";

export const getBillerDetailsCustomer = async () => {
  return await customerApi.get("customer/billing-address");
};
export const submitBillerDetailsCustomer = async (billingInformation) => {
  return await customerApi.put("customer/billing-address", {
    billingAddress: billingInformation,
  });
};
export const getPaymentDetailsCustomer = async () => {
  return await customerApi.get("customer/bank-details");
};

export const submitPaymentDetailsCustomer = async (details) => {
  return await customerApi.put("customer/bank-details", {
    bankAccountDetails: details,
  });
};
