import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const CallBackModal = ({ title, closeCallBackModal, number }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "white",
        p: 2,
        borderRadius: 4,
        width: "600px",
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src="/assets/icons/cross-icon.svg"
        alt="cross"
        style={{
          position: "absolute",
          right: "5%",
          top: "7%",
          cursor: "pointer",
        }}
        onClick={() => closeCallBackModal()}
      />
      <Box
        display="flex"
        flexDirection="column"
        gap="43px"
        sx={{ textAlign: "center", maxWidth: "457px" }}
      >
        <img src="/assets/icons/callBack.svg" alt="logo face" height="50px" />
        <Typography
          variant="h6"
          sx={{
            color: "#333",
            fontSize: "18px",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: "#333",
            textAlign: "center",
            fontSize: "24px",
          }}
        >
          Support number{" "}
          <span style={{ fontWeight: 700, paddingLeft: "8px" }}>{number}</span>
        </Typography>
      </Box>
    </Box>
  );
};

export default CallBackModal;
