import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  InputLabel,
  TextField,
  MenuItem,
  Button,
  InputAdornment,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import styled from "@emotion/styled";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import EditCalendarRoundedIcon from "@mui/icons-material/EditCalendarRounded";
import { PlacesAutocomplete } from "../../../Common/PlacesAutoComplete";
import { useMapsService } from "../../../../hooks/useMapsService";
import {
  getMaterialTypes,
  getTimeSlots,
  getVehicleModels,
  getVehicleRecommendation,
  getVehicles,
} from "../../../../api/Customer/enquiry";
import { updateAdminEnquiryById } from "../../../../api/Admin/adminEnquiries";
import useToast from "../../../../hooks/useToast";

export default function EditAdminEnquiryModal(props) {
  const {
    setIsEditEnquiryModalOpen,
    enquiry,
    refreshEnquiryDetails,
    isEditEnquiryModalOpen,
  } = props;

  const [editEnquiryForm, setEditEnquiryForm] = useState({
    pickup: enquiry?.pickup?.location?.address,
    dropoff: enquiry?.dropoff?.location?.address,
    material: {
      name: enquiry?.material?.name,
      categoryId: enquiry?.material?.categoryId,
      category: enquiry?.material?.category,
    },
    weight: enquiry?.weight || "",
    quantity: enquiry?.quantity || "",
    pickupDate: moment(enquiry?.pickup?.date).format("YYYY-MM-DD") || "",
    pickupTimeSlot: enquiry?.pickup?.slot || "",
    vehicleCategory:
      enquiry?.customer?.preferredVehicle?.vehicleCategory.toString() || "",
    vehicleCategoryId:
      enquiry?.customer?.preferredVehicle?.vehicleCategoryId || "",
  });

  const [editEnquiryFormErrors, setEditEnquiryFormErrors] = useState({
    pickup: false,
    dropoff: false,
    material: false,
    weight: false,
    pickupDate: false,
    pickupTimeSlot: false,
    vehicleCategory: false,
  });

  useEffect(() => {
    if (Object.keys(enquiry).length > 0) {
      setEditEnquiryForm({
        pickup: enquiry?.pickup?.location?.address,
        dropoff: enquiry?.dropoff?.location?.address,
        material: {
          name: enquiry?.material?.name,
          categoryId: enquiry?.material?.categoryId,
          category: enquiry?.material?.category,
        },
        weight: enquiry?.weight || "",
        quantity: enquiry?.quantity || "",
        pickupDate: moment(enquiry?.pickup?.date).format("YYYY-MM-DD") || "",
        pickupTimeSlot: enquiry?.pickup?.slot || "",
        vehicleCategory:
          enquiry?.customer?.preferredVehicle?.vehicleCategory.toString() || "",
        vehicleCategoryId:
          enquiry?.customer?.preferredVehicle?.vehicleCategoryId || "",
      });
    }
  }, [enquiry, isEditEnquiryModalOpen]);

  const hasErrors = Object.values(editEnquiryFormErrors).includes(true);

  const [vehicleList, setVehicleList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [timeSlotList, setTimeSlotList] = useState([]);
  const [unit, setUnit] = useState("KG");
  const [pickupPlaceId, setPickupPlaceId] = useState(null);
  const [dropOffPlaceId, setDropOffPlaceId] = useState(null);
  const { handleOpenToast } = useToast();

  const readableTruckCategories = (vehicle) => {
    return {
      value: `${vehicle.numberOfWheels} wheelers (${
        vehicle.minCapacity / 1000
      }T - ${vehicle.maxCapacity / 1000}T)`,
      id: vehicle.numberOfWheels.toString(),
      vehicleCategoryId: vehicle.vehicleCategoryId,
    };
  };

  const retrieveDataForEnquiry = async () => {
    try {
      const [materialTypeResponse, timeSlotResponse] = await Promise.all([
        getMaterialTypes(),

        getTimeSlots(),
      ]);

      const materialTypeForDropdown = materialTypeResponse.data.data.map(
        (type) => ({
          id: type.materialTypeId,
          value: type?.materialName,
          materialCategoryID: type?.materialCategory?.id,
        })
      );

      const timeSlotForDropdown = timeSlotResponse.data.data.map(
        (slot, idx) => ({
          id: idx,
          value: slot.timeSlot,
        })
      );
      setMaterialList(materialTypeForDropdown);
      setTimeSlotList(timeSlotForDropdown);
    } catch (err) {
      handleOpenToast("Couldn't get enquiry data. Please try again.", "error");
    }
  };

  let vehicleCategoriesForDropdown = [];
  const getVehiclesRecommendation = async (categoryId, weight) => {
    try {
      const response = await getVehicleRecommendation(categoryId, weight);
      if (response.status === 200) {
        vehicleCategoriesForDropdown = response.data.data.map((vehicle, idx) =>
          readableTruckCategories(vehicle, idx)
        );
        if (vehicleCategoriesForDropdown.length === 0) {
          vehicleCategoriesForDropdown = [
            {
              value: "No preference",
              id: 1,
            },
          ];
          setVehicleList(vehicleCategoriesForDropdown);
        } else {
          setVehicleList(vehicleCategoriesForDropdown);
        }

        if (vehicleCategoriesForDropdown.length > 0) {
          setEditEnquiryForm({
            ...editEnquiryForm,
            vehicleCategory: vehicleCategoriesForDropdown[0].id,
            vehicleCategoryId:
              vehicleCategoriesForDropdown[0].vehicleCategoryId,
          });
        } else {
          vehicleCategoriesForDropdown = [
            {
              value: "No preference",
              id: 1,
            },
          ];
          setEditEnquiryForm({
            ...editEnquiryForm,
            vehicleCategory: 1,
          });
        }
      }
    } catch (error) {
      handleOpenToast("Error while getting vehicle recommendation", "error");
    }
  };

  useEffect(() => {
    const categoryId = materialList.find(
      (material) => material.value === editEnquiryForm.material.name
    )?.materialCategoryID;

    const weight =
      unit === "KG" ? editEnquiryForm.weight : editEnquiryForm.weight * 1000;

    if (categoryId && weight) {
      getVehiclesRecommendation(categoryId, weight);
    }
  }, [editEnquiryForm.material.name, editEnquiryForm.weight, unit]);

  useEffect(() => {
    retrieveDataForEnquiry();
  }, []);

  const validateFormFields = () => {
    Object.keys(editEnquiryFormErrors).forEach((key) => {
      editEnquiryFormErrors[key] = false;

      if (!editEnquiryForm[key]) {
        editEnquiryFormErrors[key] = true;
      }
    });

    setEditEnquiryFormErrors({
      ...editEnquiryFormErrors,
    });

    return Object.values(editEnquiryFormErrors).includes(true);
  };

  const updateEnquiryDetails = async () => {
    try {
      const hasErrors = validateFormFields();
      if (hasErrors) return;

      const pickupLocation = pickupPlaceId
        ? await getLatLng(pickupPlaceId)
        : enquiry.pickup.location;

      const dropOffLocation = dropOffPlaceId
        ? await getLatLng(dropOffPlaceId)
        : enquiry.dropoff.location;

      const distance =
        pickupPlaceId || dropOffPlaceId
          ? await calculateDistance(
              editEnquiryForm.pickup,
              editEnquiryForm.dropoff
            )
          : enquiry.distance;

      const payload = {
        pickup: {
          location: {
            ...pickupLocation,
            loc: undefined,
          },
        },
        dropoff: {
          location: {
            ...dropOffLocation,
            loc: undefined,
          },
        },
        distance,
        material: editEnquiryForm.material,
        weight:
          unit === "KG"
            ? editEnquiryForm.weight
            : editEnquiryForm.weight * 1000,
        quantity: editEnquiryForm.quantity,
        vehicleCategory: +editEnquiryForm.vehicleCategory,
        pickupDate: editEnquiryForm.pickupDate,
        pickupSlot: editEnquiryForm.pickupTimeSlot,
        phoneNumber: enquiry.customer.customerPhone,
        name: enquiry.customer.customerName,
        customerId: enquiry.customer.customerId,
        vehicleCategoryId: editEnquiryForm.vehicleCategoryId,
      };

      const response = await updateAdminEnquiryById(
        enquiry?.customerEnquiryId,
        payload
      );
      if (response.status === 200) {
        refreshEnquiryDetails();
        setIsEditEnquiryModalOpen(false);
      }
    } catch (error) {
      handleOpenToast("Error while edit the enquiry", "error");
    }
  };

  const { getLatLng, calculateDistance } = useMapsService();

  return (
    <Dialog open={isEditEnquiryModalOpen} maxWidth="500px">
      <DialogTitle
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mt={2}
      >
        <StyledText>Edit Enquiry</StyledText>
        <img
          src="/assets/icons/cross-icon.svg"
          alt="cross"
          style={{
            cursor: "pointer",
          }}
          onClick={() => setIsEditEnquiryModalOpen(false)}
        />
      </DialogTitle>

      <DialogContent>
        <Box style={{ marginTop: "20px" }}>
          <RowItems>
            <Box>
              <StyledInputLabel>Enquiry ID</StyledInputLabel>
              <DisabledTextBox value={`#${enquiry?.customerEnquiryId}`} />
            </Box>
            <Box>
              <StyledInputLabel>Customer Name</StyledInputLabel>
              <DisabledTextBox value={enquiry?.customer?.customerName} />
            </Box>
          </RowItems>

          <RowItems marginTop="24px">
            <Box sx={{ width: "474px" }}>
              <StyledInputLabel paddingBottom="8px">
                Pickup Location <span style={{ color: "red" }}>*</span>
              </StyledInputLabel>

              <PlacesAutocomplete
                value={editEnquiryForm.pickup}
                error={editEnquiryFormErrors.pickup}
                resetError={() => {}}
                placeholderText="Enter pick up location"
                onPlaceSelect={(place, placeId) => {
                  setPickupPlaceId(placeId);
                  setEditEnquiryForm({
                    ...editEnquiryForm,
                    pickup: place,
                  });
                }}
              />
            </Box>
            <Box sx={{ width: "474px" }}>
              <StyledInputLabel paddingBottom="8px">
                Dropoff Location <span style={{ color: "red" }}>*</span>
              </StyledInputLabel>

              <PlacesAutocomplete
                value={editEnquiryForm.dropoff}
                error={editEnquiryFormErrors.dropoff}
                resetError={() => {}}
                placeholderText="Enter drop off location"
                onPlaceSelect={(place, placeId) => {
                  setDropOffPlaceId(placeId);
                  setEditEnquiryForm({
                    ...editEnquiryForm,
                    dropoff: place,
                  });
                }}
              />
            </Box>
          </RowItems>

          <RowItems marginTop="24px">
            <Box>
              <StyledInputLabel>
                Material Name <span style={{ color: "red" }}>*</span>
              </StyledInputLabel>
              <StyledTextField
                select
                error={editEnquiryFormErrors.material}
                value={editEnquiryForm?.material?.name}
                onChange={(e) =>
                  setEditEnquiryForm({
                    ...editEnquiryForm,
                    material: {
                      name: e.target.value,
                      categoryId: materialList.find(
                        (material) => material.value === e.target.value
                      )?.materialCategoryID,
                      category: materialList.find(
                        (material) => material.value === e.target.value
                      )?.value,
                    },
                  })
                }
                InputProps={{
                  style: {
                    ...StyledTextField.defaultProps.InputProps.style,
                    width: "474px",
                  },
                }}
              >
                {materialList.map((material, index) => (
                  <MenuItem key={index} value={material.value}>
                    {material.value}
                  </MenuItem>
                ))}
              </StyledTextField>
            </Box>

            <RowItem gap="10px">
              <Box>
                <StyledInputLabel>
                  Weight <span style={{ color: "red" }}>*</span>
                </StyledInputLabel>
                <StyledTextField
                  type="number"
                  error={editEnquiryFormErrors.weight}
                  value={editEnquiryForm?.weight}
                  onChange={(e) =>
                    setEditEnquiryForm({
                      ...editEnquiryForm,
                      weight: e.target.value,
                    })
                  }
                  InputProps={{
                    style: {
                      ...StyledTextField.defaultProps.InputProps.style,
                      width: "230px",
                      paddingRight: "0px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Select
                          value={unit}
                          onChange={(e) => setUnit(e.target.value)}
                          sx={{
                            height: "45px",
                            width: "100px",
                            borderColor: "red",
                            "& .MuiOutlinedInput-notchedOutline , &.Mui-focused .MuiOutlinedInput-notchedOutline ,& .MuiOutlinedInput-root":
                              {
                                borderColor: "#EAEAEA !important",
                              },
                          }}
                        >
                          <MenuItem value="KG">KG</MenuItem>
                          <MenuItem value="TONS">TONS</MenuItem>
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box>
                <StyledInputLabel>No of Pkgs</StyledInputLabel>
                <StyledTextField
                  type="number"
                  error={editEnquiryFormErrors.quantity}
                  value={editEnquiryForm?.quantity}
                  onChange={(e) =>
                    setEditEnquiryForm({
                      ...editEnquiryForm,
                      quantity: e.target.value,
                    })
                  }
                  InputProps={{
                    style: {
                      ...StyledTextField.defaultProps.InputProps.style,
                      width: "230px",
                    },
                  }}
                />
              </Box>
            </RowItem>
          </RowItems>

          <RowItems marginTop="24px">
            <Box>
              <StyledInputLabel paddingBottom="8px">
                Pick up Date <span style={{ color: "red" }}>*</span>
              </StyledInputLabel>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  error={editEnquiryFormErrors.pickupDate}
                  slots={{
                    openPickerIcon: EditCalendarRoundedIcon,
                  }}
                  value={moment(editEnquiryForm.pickupDate)}
                  minDate={moment(new Date())}
                  defaultValue={new Date()}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "2.75rem",
                      width: "474px",
                    },
                    input: {
                      fontWeight: "700",
                    },
                    ...(editEnquiryFormErrors.pickupDate
                      ? {
                          fieldset: {
                            borderColor: "#d32f2f !important",
                          },
                        }
                      : {}),
                  }}
                  name="date"
                  onChange={(newValue) => {
                    setEditEnquiryForm({
                      ...editEnquiryForm,
                      pickupDate: newValue.format("YYYY-MM-DD"),
                    });
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box>
              <StyledInputLabel>
                Time Slot <span style={{ color: "red" }}>*</span>
              </StyledInputLabel>
              <StyledTextField
                select
                error={editEnquiryFormErrors.pickupTimeSlot}
                value={editEnquiryForm.pickupTimeSlot}
                onChange={(e) =>
                  setEditEnquiryForm({
                    ...editEnquiryForm,
                    pickupTimeSlot: e.target.value,
                  })
                }
                InputProps={{
                  style: {
                    ...StyledTextField.defaultProps.InputProps.style,
                    width: "474px",
                  },
                }}
              >
                {timeSlotList.map((slot, index) => (
                  <MenuItem key={index} value={slot.value}>
                    {slot.value}
                  </MenuItem>
                ))}
              </StyledTextField>
            </Box>
          </RowItems>

          <RowItems marginTop="24px">
            <Box>
              <StyledInputLabel>
                Vehicle Category Preference{" "}
                <span style={{ color: "red" }}>*</span>
              </StyledInputLabel>
              <StyledTextField
                select
                error={editEnquiryFormErrors.vehicleCategory}
                value={
                  editEnquiryForm.vehicleCategory === "No preference"
                    ? "No preference"
                    : editEnquiryForm.vehicleCategory
                }
                onChange={(e) => {
                  setEditEnquiryForm({
                    ...editEnquiryForm,
                    vehicleCategory: e.target.value,
                    vehicleCategoryId: vehicleList.find(
                      (vehicle) => vehicle.id === e.target.value
                    )?.vehicleCategoryId,
                  });
                }}
                InputProps={{
                  style: {
                    ...StyledTextField.defaultProps.InputProps.style,
                    width: "475px",
                  },
                }}
              >
                {vehicleList.map((vehicle, index) => (
                  <MenuItem key={index} value={vehicle.id}>
                    {vehicle.value}
                  </MenuItem>
                ))}
              </StyledTextField>
            </Box>
          </RowItems>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: hasErrors ? "space-between" : "right",
          padding: "10px 20px",
          marginBottom: "20px",
        }}
      >
        {hasErrors && (
          <Box
            sx={{
              color: "#d32f2f",
              fontWeight: "700",
              textAlign: "left",
            }}
          >
            * Fill all required entry fields
          </Box>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={updateEnquiryDetails}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const StyledText = styled(Typography)(() => ({
  color: "#333",
  fontSize: "24px",
  fontWeight: "700",
  width: "500px",
  marginLeft: "10px",
}));

const RowItems = styled(Box)(({ marginTop, justifyContent, alignItems }) => ({
  display: "flex",
  alignItems: alignItems || "center",
  justifyContent: justifyContent || "center",
  width: "100%",
  gap: "8px",
  marginTop: marginTop || "",
}));

const RowItem = styled(Box)(({ width, justifyContent, gap }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: justifyContent || "center",
  width: width || "50%",
  gap: gap || "8px",
}));

const DisabledTextBox = styled(TextField)(({}) => ({}));

DisabledTextBox.defaultProps = {
  variant: "outlined",
  fullWidth: true,
  disabled: true,
  InputProps: {
    style: {
      height: "45px",
      width: "474px",
      marginTop: "8px",
      backgroundColor: "#F6F6F6",
      color: "#C6C6C6",
      fontWeight: "700",
    },
  },
};

const StyledInputLabel = styled(InputLabel)(({ paddingBottom }) => ({
  color: "#333",
  fontWeight: 700,
  fontSize: "16px",
  paddingBottom: paddingBottom || "",
}));

const StyledTextField = styled(TextField)(({ select, error }) => {
  return {
    ...(select
      ? {
          "& .MuiOutlinedInput-input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px white inset",
          },
          "& .MuiInputLabel-outlined.Mui-focused": {
            color: "gray",
          },
          "& .MuiInputLabel-outlined": {
            color: "gray",
          },
        }
      : {}),
    ...(error
      ? {
          fieldset: {
            borderColor: "#d32f2f !important",
          },
        }
      : {}),
  };
});

StyledTextField.defaultProps = {
  fullWidth: true,
  variant: "outlined",
  InputProps: {
    style: {
      height: "45px",
      width: "474px",
      marginTop: "8px",
      color: "#333",
      fontWeight: "700",
    },
  },
  InputLabelProps: { shrink: false },
};
