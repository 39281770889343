import moment from 'moment';

export function truncateString(inputString, limit) {
  if (inputString.length <= limit)
  {
    return inputString; // No truncation needed
  } else
  {
    return inputString.substring(0, limit) + '...';
  }
}


export function splitAndTruncateString(inputString, splitCount, limit) {
  if (inputString.length <= splitCount)
  {
    return inputString; // No splitting needed
  } else
  {
    const firstPart = inputString.substring(0, splitCount);
    const remainingPart = inputString.substring(splitCount, limit);


    if (inputString.trim().length <= limit)
    {
      const retstring = firstPart.trim() + "<br/>" + remainingPart.trim();;
      return retstring;

    } else
    {
      return firstPart.trim() + "<br/>" + remainingPart.trim() + "...";

    }
  }
}



export function formatDate(inputDate, format) {
  const date = moment(inputDate);
  return date.format(format);
}








