import { useEffect, useState } from "react";
import { getSupportTicketsList } from "../../../api/Admin/support";
import GenerateStats from "../../../component/Common/GenerateStats";
// import TableSearchInputField from "../../../component/Common/TableSearchInputField"
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import moment from "moment";
import { StyledTableCell } from "../Approval/StyledTableCell";
import { LOADING_STATUS } from "../../../constants/constant";
import LoadingTable from "../../../component/Common/LoadingTable";
import ErrorScreen from "../../../component/Common/ErrorScreen";
import { useLocation, useNavigate } from "react-router-dom";
import { StyledTableRow } from "../../../component/Common/StyledTableRow";
import Filters from "../../../component/Common/Filters";

const Support = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSupportTicketsFetched, setIsSupportTicketFetched] = useState(
    LOADING_STATUS.INITIAL
  );
  const [inputValue, setInputValue] = useState("");
  const [stats, setStats] = useState(null);
  const [supportTicketList, setSupportTicketList] = useState([]);
  const [paginationData, setPaginationData] = useState(null);
  const [page, setPage] = useState(0);

  const [anchorForStatus, setAnchorForStatus] = useState(null);
  const [statusFilter, setStatusFilter] = useState("");

  const [anchorForPriority, setAnchorForPriority] = useState(null);
  const [priorityFilter, setPriorityFilter] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
  };

  const generateStatsData = (statsData) => {
    const { total, low, high, medium } = statsData;
    const statsArray = [
      { name: "Total", value: total },
      { name: "High", value: high },
      { name: "Medium", value: medium },
      { name: "Low", value: low },
    ];
    setStats(statsArray);
  };

  const fetchSupportTicketsList = async () => {
    setIsSupportTicketFetched(LOADING_STATUS.LOADING);
    const response = await getSupportTicketsList(
      statusFilter,
      priorityFilter,
      inputValue,
      page
    );
    if (response.status === 200) {
      generateStatsData(response.data.data.counts);
      setSupportTicketList(response.data.data.tickets);
      setPaginationData(response.data.data.pagination);
      setIsSupportTicketFetched(LOADING_STATUS.LOADED);
    } else {
      setIsSupportTicketFetched(LOADING_STATUS.FAILED);
    }
  };

  // filter popup handlers for status type
  const handleOpenStatusFilter = (event) => {
    setAnchorForStatus(event.currentTarget);
  };
  const handleCloseStatusFilter = () => {
    setAnchorForStatus(null);
  };
  const updateTicketStatusFilter = (event) => {
    setStatusFilter(event.target.textContent);
    setPage(0);
    handleCloseStatusFilter();
  };

  // filter popup handlers for priority type
  const handleOpenPriorityFilter = (event) => {
    setAnchorForPriority(event.currentTarget);
  };
  const handleClosePriorityFilter = () => {
    setAnchorForPriority(null);
  };
  const updateTicketPriorityFilter = (event) => {
    setPriorityFilter(event.target.textContent);
    setPage(0);
    handleClosePriorityFilter();
  };
  useEffect(() => {
    fetchSupportTicketsList();
  }, [statusFilter, priorityFilter, inputValue, page]);

  function renderViews() {
    switch (isSupportTicketsFetched) {
      case LOADING_STATUS.LOADING:
        return <LoadingTable columns={8} />;
      case LOADING_STATUS.LOADED:
        if (supportTicketList.length)
          return <SupportTicketTable ticketsList={supportTicketList} />;
        return (
          <TableRow sx={{ background: "#EAEAEA" }}>
            <TableCell
              colSpan={8}
              align="center"
              sx={{ fontWeight: 600, color: "#333" }}
            >
              No Results Found.
            </TableCell>
          </TableRow>
        );
      case LOADING_STATUS.FAILED:
        return (
          <TableCell colSpan={8}>
            <ErrorScreen onRetry={fetchSupportTicketsList} />
          </TableCell>
        );
      default:
        return null;
    }
  }

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        p=".75rem 1.625rem"
        borderBottom="1px solid #ADADAD"
        alignItems="center"
      >
        <Stack direction="row">
          {stats && <GenerateStats data={stats} />}
          <Button
            variant="outlined"
            color="secondary"
            style={{ marginLeft: "1rem" }}
            onClick={() =>
              navigate(`${location.pathname.concat("/createTicket")}`)
            }
          >
            Create Ticket
          </Button>
        </Stack>
        {/* <TableSearchInputField /> */}
        <TextField
          variant="outlined"
          placeholder="Mobile No"
          value={inputValue}
          onChange={(e) => {
            if (isNaN(e.target.value)) return;
            setInputValue(e.target.value);
          }}
          InputProps={{
            sx: { height: "36.31px" },
            endAdornment: (
              <img src="/assets/icons/search-icon.svg" alt="search" />
            ),
          }}
        />
      </Stack>
      <Box p={3}>
        <TableContainer aria-label="customized table">
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Ticket No.</StyledTableCell>
                <StyledTableCell>Created On</StyledTableCell>
                <StyledTableCell>Stakeholder</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Mobile No.</StyledTableCell>
                <StyledTableCell>Nature of Issue</StyledTableCell>
                <StyledTableCell>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {"Priority"}
                    <img
                      src="/assets/icons/expand_less.svg"
                      alt="expand less"
                      onClick={handleOpenPriorityFilter}
                      style={{ cursor: "pointer" }}
                    />
                    <Filters
                      open={anchorForPriority}
                      onClose={handleClosePriorityFilter}
                      onClick={updateTicketPriorityFilter}
                      filters={["Low", "Medium", "High"]}
                    />
                  </Stack>
                </StyledTableCell>
                <StyledTableCell>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {"Status"}

                    <img
                      src="/assets/icons/expand_less.svg"
                      alt="expand less"
                      onClick={handleOpenStatusFilter}
                      style={{ cursor: "pointer" }}
                    />
                    <Filters
                      open={anchorForStatus}
                      onClose={handleCloseStatusFilter}
                      onClick={updateTicketStatusFilter}
                      filters={["Open", "Hold", "Closed"]}
                    />
                  </Stack>
                </StyledTableCell>
              </TableRow>
            </TableHead>

            {/* based on loading status views will switch */}
            <TableBody>{renderViews()}</TableBody>
          </Table>
        </TableContainer>
        {paginationData && (
          <div style={{ padding: "10px" }}>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={paginationData.totalItems}
              rowsPerPage={paginationData.pageSize}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={({ count }) => (
                <div>
                  Page{" "}
                  <span style={{ color: "green", fontWeight: 700 }}>
                    {page + 1}
                  </span>{" "}
                  of {Math.ceil(count / paginationData.pageSize)}
                </div>
              )}
            />
          </div>
        )}
      </Box>
    </Box>
  );
};

export default Support;

const SupportTicketTable = ({ ticketsList }) => {
  const navigate = useNavigate();
  const generatePriorityCell = (priority) => {
    let color = "";
    if (priority === "Low") {
      color = "#1CA82E";
    } else if (priority === "Medium") {
      color = "#EA8611";
    } else color = "#FF0707";
    return <TableCell sx={{ color: color }}>{priority}</TableCell>;
  };
  const generateStatusCell = (status) => {
    let color = "";
    if (status === "Closed") {
      color = "#1CA82E";
    } else if (status === "Open") {
      color = "#FF0707";
    } else color = "#EA8611";
    return <TableCell sx={{ color: color }}>{status}</TableCell>;
  };
  function createSupportTicketRowData(ticket) {
    return {
      id: ticket._id,
      ticketNo: ticket?.ticketNo ?? "",
      createdOn: ticket?.createdAt ?? "",
      stakeholder: ticket?.stakeholder ?? "",
      name: ticket?.stakeholderName ?? "",
      number: ticket?.mobileNumber ?? "",
      natureOfIssue: ticket?.natureOfIssue ?? "",
      priority: ticket?.priority ?? "",
      status: ticket?.status ?? "",
    };
  }

  const supportTicketsTableData = ticketsList.map((ticket) =>
    createSupportTicketRowData(ticket)
  );

  return supportTicketsTableData.map((row, index) => {
    return (
      <StyledTableRow
        key={row.id}
        onClick={() =>
          navigate(`editTicket/${row.id}`, {
            state: { transporterDetails: ticketsList[index] },
          })
        }
        style={{ textDecoration: "none", cursor: "pointer" }}
      >
        <TableCell scope="row" sx={{ color: "#6D6D6D" }}>
          {row.ticketNo}
        </TableCell>
        <TableCell sx={{ color: "#6D6D6D" }}>
          {moment(row.createdOn).format("DD-MM-YYYY hh:mm A")}
        </TableCell>
        <TableCell sx={{ color: "#6D6D6D" }}>{row.stakeholder}</TableCell>
        <TableCell sx={{ color: "#6d6d6d" }}>{row.name}</TableCell>
        <TableCell sx={{ color: "#6D6D6D" }}>{row.number}</TableCell>
        <TableCell sx={{ color: "#6D6D6D" }}>{row.natureOfIssue}</TableCell>
        {generatePriorityCell(row.priority)}
        {generateStatusCell(row.status)}

        {/* {generateStatus(row.status)} */}
      </StyledTableRow>
    );
  });
};
