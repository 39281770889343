import React, { useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import {
  addNewDriver,
  editDriverData,
} from "../../../../../api/Transporter/driver";

const styles = {
  addDriverGroup: {
    width: "80%",
    display: "flex",
    flexDirection: "column",
    mb: "20px",
  },
  addDriverinput: {
    width: "554px",
    height: "52px",
    color: "#333333",
    background: "transparent",
    outline: "none",
    borderRadius: 3,
  },
};

const InviteEditDriverModal = ({
  closeInviteEditDriverModal,
  isEditModeOn,
  selectedDriver,
  openDeleteDriverModal,
  handleOpenToast,
  transporterId,
  fetchAllDriversDetails,
}) => {
  const [currentDriverData, setCurrentDriverData] = useState({
    driverName: isEditModeOn ? selectedDriver?.driverName : "",
    driverNumber: isEditModeOn ? selectedDriver?.phone : "",
    driverEmail: isEditModeOn ? selectedDriver?.email : "",
  });

  const [errors, setErrors] = useState({
    nameError: "",
    numberError: "",
    emailError: "",
  });

  const handleChangedriverName = (e) => {
    let name = e.target.value;

    const ALPHA_REGEX = /^[a-zA-Z\s]+$/;
    if (name) {
      name = ALPHA_REGEX.test(name) ? name : currentDriverData.driverName;
    }

    setCurrentDriverData({
      ...currentDriverData,
      driverName: name,
    });
  };

  const handleChangeNumber = (e) => {
    setCurrentDriverData({
      ...currentDriverData,
      driverNumber: e.target.value.slice(0, 10),
    });
  };

  const handleChangeName = (e) => {
    setCurrentDriverData({
      ...currentDriverData,
      driverEmail: e.target.value,
    });
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const driverErrorValidation = () => {
    const { driverName, driverNumber, driverEmail } = currentDriverData;

    let hasErrors = false;
    const newErrors = { nameError: "", numberError: "", emailError: "" };

    if (!driverName.trim()) {
      newErrors.nameError = "Name is mandatory.";
      hasErrors = true;
    }

    if (!driverNumber.trim()) {
      newErrors.numberError = "Number is mandatory.";
      hasErrors = true;
    } else if (driverNumber.trim().length !== 10 || isNaN(driverNumber)) {
      newErrors.numberError = "Enter a valid 10-digit number.";
      hasErrors = true;
    }

    if (driverEmail.trim() && !isValidEmail(driverEmail)) {
      newErrors.emailError = "Enter a valid email address.";
      hasErrors = true;
    }

    if (hasErrors) {
      setErrors({ ...newErrors });
      return true;
    }
    return false;
  };

  const addNewDriverList = async () => {
    const errors = driverErrorValidation();
    if (errors) {
      return;
    }
    try {
      const response = await addNewDriver(currentDriverData);
      if (response.status === 200) {
        await fetchAllDriversDetails();
        closeInviteEditDriverModal();
        handleOpenToast("Driver added successfully.", "success");
      } else if (response.response.status === 409) {
        handleOpenToast(
          "Driver with the same phone number already invited.",
          "error"
        );
      } else {
        handleOpenToast("Driver addition failed.", "error");
      }
    } catch (err) {
      handleOpenToast("Cannot perform this action", "error");
    }
  };

  const editDriver = async () => {
    const errors = driverErrorValidation();
    if (errors) {
      return;
    }
    const editDriverBody = {
      driverName: currentDriverData.driverName,
      driverEmail: currentDriverData.driverEmail,
    };

    const response = await editDriverData(
      transporterId,
      selectedDriver.driverId,
      editDriverBody
    );
    if (response.status === 200) {
      handleOpenToast("Driver data updated successfully.", "success");
      await fetchAllDriversDetails();
    } else {
      handleOpenToast("Failed to update driver data.", "error");
    }
    closeInviteEditDriverModal();
  };

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          p: 2,
          width: "800px",
          height: "533px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <img
          src="/assets/icons/cross-icon.svg"
          alt="cross"
          style={{
            position: "absolute",
            right: "5%",
            top: "7%",
            cursor: "pointer",
          }}
          onClick={() => closeInviteEditDriverModal()}
        />
        <Box
          style={{
            position: "absolute",
            left: "5%",
            top: "6%",
            color: "#333",
            fontSize: 24,
            fontWeight: 700,
          }}
        >
          {isEditModeOn ? "Edit Driver" : "Invite Driver"}
        </Box>

        <Box sx={styles.addDriverGroup}>
          <label style={{ marginTop: "10px", marginBottom: "8px" }}>
            Driver's Full Name <b style={{ color: "red" }}>*</b>
          </label>
          <TextField
            placeholder="Enter the full name"
            sx={{
              ...styles.addDriverinput,
              "& .MuiInputBase-input": {
                fontWeight: "bold",
                padding: "14px",
                fontSize: "18px",
              },
            }}
            value={currentDriverData.driverName}
            onChange={handleChangedriverName}
            inputProps={{ maxLength: 35 }}
            error={!!errors.nameError}
            helperText={errors.nameError}
          />
        </Box>
        <Box sx={styles.addDriverGroup}>
          <label>
            Driver's Contact Number <b style={{ color: "red" }}>*</b>
          </label>
          <TextField
            maxLength={10}
            value={currentDriverData.driverNumber}
            placeholder="Enter the contact number"
            sx={{
              ...styles.addDriverinput,
              "& .MuiInputBase-input": {
                fontWeight: "bold",
                padding: "14px",
                fontSize: "18px",
              },
            }}
            onChange={handleChangeNumber}
            error={!!errors.numberError}
            helperText={errors.numberError}
            disabled={isEditModeOn}
          />
        </Box>
        <Box sx={styles.addDriverGroup}>
          <label>E-mail Address </label>
          <TextField
            type="email"
            inputProps={{ maxLength: 35 }}
            value={currentDriverData.driverEmail}
            placeholder="abc@domain.com"
            onChange={handleChangeName}
            error={!!errors.emailError}
            helperText={errors.emailError}
            sx={{
              ...styles.addDriverinput,
              "& .MuiInputBase-input": {
                fontWeight: "bold",
                padding: "14px",
                fontSize: "18px",
              },
            }}
          />
        </Box>
        {isEditModeOn ? (
          <>
            <Button
              sx={{
                position: "absolute",
                bottom: "5%",
                left: "5%",
                color: "#FF3F3F",
                fontSize: "18px",
                fontWeight: 700,
                textDecoration: "underline",
                textTransform: "none",
                "&:hover": { textDecoration: "underline" },
              }}
              onClick={openDeleteDriverModal}
            >
              Remove driver
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ position: "absolute", bottom: "5%", right: "5%" }}
              onClick={editDriver}
            >
              Save
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={addNewDriverList}
            sx={{ position: "absolute", bottom: "5%" }}
          >
            Send Invite
          </Button>
        )}
      </Box>
    </>
  );
};

export default InviteEditDriverModal;
