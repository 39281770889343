import {
  Box,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Tooltip,
  styled,
} from "@mui/material";

import ToggleButton from "../../../Common/ToggleButton";
import TruckCarousel from "./TruckCarousel";
import { useEffect, useState } from "react";
import {
  assignDriverToVehicle,
  getLinkedDriversForDropdown,
  getSingleDriverDetails,
  updateTruckAvailabilityStatus,
} from "../../../../api/Transporter/truck";

const TruckCardDetails = ({
  openEditTruckTypeModal,
  selectedVehicle,
  openDeleteTruckModal,
  transporterId,
  fetchAllTrucksList,
  vehicleStatus,
  handleOpenToast,
  handleTruckStatusChange,
}) => {
  const [isTruckActive, setIsTruckActive] = useState(false);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [driverToReassign, setDriverToReassign] = useState(null);

  const [driverDetails, setDriverDetails] = useState([]);

  const handleAssignDriver = async (driverId) => {
    // Check if the selected driver has already truck Number
    try {
      const response = await getSingleDriverDetails(transporterId, driverId);
      if (response.status === 200) {
        if (response.data.driver.truckNumber) {
          setDriverToReassign(driverId);
          setDriverDetails(response.data.driver);
          setOpenConfirmDialog(true);
        } else {
          // If no, assign the driver directly
          assignDriver(driverId);
        }
        return response.data.driver;
      }
    } catch (error) {
      handleOpenToast("Error while fetching driver details", "error");
    }
  };

  // Function to handle truck availability status
  const handleTruckAvailability = async (isAvailable) => {
    try {
      const response = await updateTruckAvailabilityStatus(
        transporterId,
        selectedVehicle.transporterVehicleId,
        isAvailable
      );
      if (response.status === 200) {
        handleOpenToast("Truck status updated successfully", "success");
        handleTruckStatusChange(
          selectedVehicle.transporterVehicleId,
          isAvailable
        );
        setIsTruckActive(isAvailable);
      }
    } catch (error) {
      handleOpenToast("Error while updating truck status", "error");
    }
  };

  const [driversList, setDriversList] = useState([]);

  const [selectedDriver, setSelectedDriver] = useState("");

  // Function to get drivers list for dropdown
  const getDriversForDropdown = async (transporterId) => {
    try {
      const response = await getLinkedDriversForDropdown(transporterId);
      if (response.status === 200) {
        setDriversList(response.data.data);
      }
    } catch (error) {
      handleOpenToast("Error while fetching drivers list", "error");
    }
  };

  const assignDriver = async (driverId) => {
    try {
      const response = await assignDriverToVehicle(
        transporterId,
        selectedVehicle.transporterVehicleId,
        driverId,
        selectedVehicle?.assignedTo || "",
        selectedVehicle?.transporterVehicleId || ""
      );

      if (response.status === 200) {
        fetchAllTrucksList();
        handleOpenToast("Driver assigned successfully", "success");
      }
    } catch (error) {
      handleOpenToast("Error while assigning driver", "error");
    }
  };

  const handleConfirmReassign = () => {
    if (driverToReassign) {
      assignDriver(driverToReassign);
      setOpenConfirmDialog(false);
    }
  };

  const onToggle = () => {
    setIsTruckActive(!isTruckActive);
    handleTruckAvailability(!isTruckActive);
  };

  const handleSelectDriver = async (event) => {
    const driverId = event.target.value;
    handleAssignDriver(driverId);
    setSelectedDriver(driverId);
  };

  useEffect(() => {
    setIsTruckActive(vehicleStatus === "AVAILABLE");
    getDriversForDropdown(transporterId);
    setSelectedDriver(selectedVehicle?.assignedTo || "");
  }, [selectedVehicle, transporterId, vehicleStatus]);

  return (
    <Box
      p={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        borderBottom: "1px solid #ADADAD",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
          {selectedVehicle?.vehicleNumber}
        </Typography>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Typography
            sx={{
              color: isTruckActive ? "#00B87C" : "#FF0000",
              fontSize: "14px",
              fontWeight: 700,
            }}
          >
            {isTruckActive ? "Available" : "Not Available"}
          </Typography>
          <ToggleButton toggledState={isTruckActive} onToggle={onToggle} />
        </Box>
      </Box>
      <Box
        sx={{
          border: "1px solid #ADADAD",
          borderRadius: 0.5,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "20px",
          p: 1,
        }}
        p={2}
      >
        <Box>
          {selectedVehicle && (
            <TruckCarousel selectedVehicle={selectedVehicle} />
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Tooltip title={selectedVehicle?.vehicleModelName || ""}>
          <VehicleNameBox>
            {selectedVehicle?.vehicleModelName.length > 20
              ? selectedVehicle?.vehicleModelName.substring(0, 20) + "..."
              : selectedVehicle?.vehicleModelName}
          </VehicleNameBox>
        </Tooltip>
        <VehicleNameBox>
          Max load {selectedVehicle?.maxCapacity / 1000} Ton
        </VehicleNameBox>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box onClick={openEditTruckTypeModal} sx={{ cursor: "pointer" }}>
            <img src="/assets/icons/editIcon.svg" alt="truck edit icon" />
          </Box>
          <Box onClick={openDeleteTruckModal} sx={{ cursor: "pointer" }}>
            <img src="/assets/icons/deleteIcon.svg" alt="delete" />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Box
          sx={{
            width: "42%",
          }}
        >
          <InputLabel
            htmlFor="vehicle-category"
            sx={{ color: "#333", fontSize: "14px" }}
          >
            Assign driver
          </InputLabel>

          <TextField
            variant="outlined"
            placeholder="Select"
            fullWidth
            InputProps={{
              style: { height: "32px" },
            }}
            select
            value={selectedDriver}
            onChange={handleSelectDriver}
          >
            {driversList &&
              driversList.length !== 0 &&
              driversList.map((driver) => (
                <MenuItem key={driver?._id} value={driver?._id}>
                  {driver.driverName}
                </MenuItem>
              ))}
          </TextField>
        </Box>
        <Box
          sx={{
            width: "45%",
          }}
        >
          <InputLabel
            htmlFor="vehicle-category"
            sx={{ color: "#333", fontSize: "14px" }}
          >
            Owner
          </InputLabel>

          <TextField
            variant="outlined"
            placeholder="Select"
            fullWidth
            value={selectedVehicle?.vehicleOwnerName}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              style: { height: "32px", width: "198px" },
              inputProps: {
                style: { background: "#F8FAF8", padding: "8px 12px" },
              },
              disabled: true,
            }}
          />
        </Box>
      </Box>
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle
          sx={{
            color: "#333",
            fontSize: "24px",
            fontWeight: 700,
          }}
        >
          Confirmation Required
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to unlink the driver from the currently
            assigned truck and reassign the driver to the newly selected truck?
          </DialogContentText>
          <DialogBox>
            <Box>
              <Typography sx={{ margin: "10px 0" }}>
                Current assigned vehicle:
                <strong> {driverDetails?.truckNumber}</strong>
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "#DDEFDD",
                padding: "2px 40px",
                borderRadius: "5px",
              }}
            >
              <Typography>
                New assigning vehicle:
                <strong> {selectedVehicle?.vehicleNumber} </strong>
              </Typography>
            </Box>
          </DialogBox>
        </DialogContent>
        <DialogActions
          sx={{
            gap: "10px",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <Button
            onClick={() => setOpenConfirmDialog(false)}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmReassign}
            variant="contained"
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default TruckCardDetails;

const VehicleNameBox = styled(Box)({
  width: "42%",
  minHeight: "50px",
  borderRadius: "3px",
  background: "#EAEAEA",
  display: "flex",
  color: "#333",
  fontSize: "14px",
  fontWeight: 700,
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
});

const DialogBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "20px 0",
});
