// reusable commmon component for creating filters on table columns

import { Button, Popover, Stack } from "@mui/material";

const popOverButtonsStyle = {
  color: "#333",
  textTransform: "none",
  fontSize: "12px",
};

// open -> your function that will enable popover
// onClick -> action to make upon clicking any of one filters
// filters -> An array that makes up filter needs to be displayed to user

const Filters = ({ open, onClose, filters = [], onClick ,selectedFilter=""}) => {
  return (
    <Popover
      open={Boolean(open)}
      anchorEl={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Stack direction="column">
        {filters.map((filter, i) => (
          <Button key={i} onClick={onClick} sx={{...popOverButtonsStyle , background:selectedFilter.toLowerCase() === filter.toLowerCase()?'#DDE7EF':''}}>
            {filter}
          </Button>
        ))}
      </Stack>
    </Popover>
  );
};
export default Filters;
