// reusable commmon component for creating filters on table columns

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Popover,
  Stack,
  Typography,
  checkboxClasses,
} from "@mui/material";

const popOverButtonsStyle = {
  color: "#333",
  textTransform: "capitalize",
  fontSize: "12px",
  textAlign: "left",
  pl: 0,
  pr: 2,
};

// open -> your function that will enable popover
// onClose => function to close dialog
// onClick -> action to make upon clicking any of one filters
// filters -> An array that makes up filter needs to be displayed to user

const FilterCollapse = ({
  open,
  onClose,
  filters = [],
  selectedFilters,
  setFilters,
}) => {
  // onclick handler for status filter
  const updateEnquiryStatusFilter = (filter) => {
    setFilters((prevSelectedFilters) => {
      if (prevSelectedFilters.includes(filter)) {
        // If filter is already selected, remove it
        return prevSelectedFilters.filter(
          (selectedFilter) => selectedFilter !== filter
        );
      } else {
        // If filter is not selected, add it
        return [...prevSelectedFilters, filter];
      }
    });
  };

  const clearEnquiryStatus = () => {
    setFilters([]);
  };
  return (
    <Popover
      open={Boolean(open)}
      anchorEl={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Stack direction="column">
        <Typography
          fontSize="14px"
          fontWeight="700"
          display="flex"
          justifyContent="end"
          pr="14px"
          sx={{ cursor: "pointer" }}
          onClick={() => clearEnquiryStatus()}
          color="#808080"
        >
          {" "}
          Clear All
        </Typography>
        {filters.map((filter, i) => (
          <Box display="flex" key={i}>
            <Checkbox
              checked={selectedFilters?.includes(filter.toLowerCase())}
              onChange={() => updateEnquiryStatusFilter(filter.toLowerCase())}
              sx={{
                [`&, &.${checkboxClasses.checked}`]: {
                  color: "#0C7F44",
                },
              }}
            />
            <Button key={i} sx={popOverButtonsStyle}>
              {filter.toLowerCase()}
            </Button>
          </Box>
        ))}
      </Stack>
    </Popover>
  );
};
export default FilterCollapse;
