import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import styled from "@emotion/styled";

const TransporterCitiesAndRoutes = ({
  panelExpanded,
  updateExpandedPanel,
  operationalCities,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState({});

  const handleClickOpen = (city) => {
    setSelectedCity(city);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderCityDetails = (city) => {
    const baseAreasText =
      city.baseAreas.length > 0
        ? city.baseAreas.map((area) => area.area).join(", ")
        : city.allBaseAreasSelected
        ? "All selected"
        : "None";

    const serviceAreasText =
      city.serviceAreas.length > 0
        ? city.serviceAreas.map((area) => area.area).join(", ")
        : city.allServiceAreasSelected
        ? "All selected"
        : "None";

    const interCityRoutesText = (
      <>
        {city?.interCityRoutes.length > 0
          ? city?.interCityRoutes.map((route, index) => (
              <span
                key={index}
                style={{
                  marginBottom: "0.5rem",
                  marginRight: "0.5rem",
                }}
              >
                {city.city}
                <SyncAltIcon
                  sx={{ width: "13px", height: "13px", mx: "0.1rem" }}
                />
                {route}
                {index !== city.interCityRoutes.length - 1 && ","}
              </span>
            ))
          : "None"}
      </>
    );

    return (
      <>
        <DialogContentText>
          <strong>Base Areas:</strong> {baseAreasText}
        </DialogContentText>
        <Divider sx={{ margin: "6px 0" }} />
        <DialogContentText>
          <strong>Service Areas:</strong> {serviceAreasText}
        </DialogContentText>
        <Divider sx={{ margin: "6px 0" }} />
        <DialogContentText>
          <strong>InterCity Routes:</strong> {interCityRoutesText}
        </DialogContentText>
      </>
    );
  };

  return (
    <Accordion
      disableGutters
      TransitionProps={{ unmountOnExit: true }}
      sx={{ borderRadius: ".35rem" }}
      expanded={panelExpanded === "Cities & Routes"}
      onChange={updateExpandedPanel("Cities & Routes")}
    >
      <AccordionSummary
        expandIcon={
          <img src="/assets/icons/expand_less.svg" alt="expand_less" />
        }
        aria-controls="panel1bh-content"
        sx={{
          background: "#EAEAEA",
          paddingLeft: "2rem",
          fontWeight: 700,
          "&.Mui-expanded": {
            paddingTop: 0,
          },
          "&.MuiAccordionSummary-root": {
            height: "36px !important",
            minHeight: "36px !important",
          },
        }}
      >
        {"Cities & Routes"}
      </AccordionSummary>

      <AccordionDetails
        sx={{
          display: "flex",
          flexDirection: "row",
          background: "#FFF",
          paddingLeft: "2rem",
          paddingTop: "1rem",
        }}
      >
        {operationalCities?.length > 0 &&
          operationalCities?.map((city, index) => (
            <Box
              key={index}
              sx={{
                border: "1px solid #EAEAEA",
                borderRadius: ".35rem",
                marginBottom: "1rem",
                marginRight: "1rem",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              <Button onClick={() => handleClickOpen(city)}>
                <Typography
                  sx={{
                    padding: "1rem",
                    fontWeight: 700,
                    fontSize: "1.2rem",
                    color: "#333",
                  }}
                >
                  {city.city}
                </Typography>
                {city.isIntraCity && (
                  <Typography
                    sx={{
                      padding: "0.5rem",
                      fontWeight: 700,
                      bgcolor: "green",
                      color: "#FFF",
                      fontSize: "0.7rem",
                      borderRadius: "0.35rem",
                    }}
                  >
                    Intra City
                  </Typography>
                )}
              </Button>
            </Box>
          ))}
        {operationalCities?.length === 0 && (
          <Typography>No operational cities</Typography>
        )}
      </AccordionDetails>

      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <DialogTitle
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={2}
        >
          <StyledText
            color="#333"
            fontSize="24px"
            fontWeight="700"
            width="500px"
          >
            {selectedCity.city}
          </StyledText>
          <img
            src="/assets/icons/cross-icon.svg"
            alt="cross"
            style={{
              cursor: "pointer",
            }}
            onClick={() => setOpen(false)}
          />
        </DialogTitle>

        <DialogContent>
          {selectedCity.city ? (
            renderCityDetails(selectedCity)
          ) : (
            <DialogContentText>No details available</DialogContentText>
          )}
        </DialogContent>
      </Dialog>
    </Accordion>
  );
};

export default TransporterCitiesAndRoutes;

const StyledText = styled(Typography)(
  ({ fontSize, color, fontWeight, width }) => ({
    color: color || "#4F4848",
    fontSize: fontSize || "14px",
    fontWeight: fontWeight || "400",
    width: width || "400px",
  })
);
