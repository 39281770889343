import React, { useEffect, useState } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import styled from "@emotion/styled";
import SimpleCard from "../../Common/SimpleCard";
import { getTransporterEnquiryDetailsById } from "../../../api/Transporter/enquiries";
import { getBidDetailsByBidId } from "../../../api/Transporter/bid";
import { ENQUIRY_STATUS_TO_TEXT_MAP } from "../../../constants/constant";

const StyledText = styled(Typography)(
  ({ fontSize, fontWeight, textTransform }) => ({
    color: "#4F4848",
    fontSize: fontSize || "14px",
    fontStyle: "normal",
    fontWeight: fontWeight || "400",
    textTransform: textTransform || "capitalize",
  })
);

const StyledBoxContainer = styled(Typography)(() => ({
  display: "flex",
  width: "100%",
  backgroundColor: "#F6EDED",
  border: "1px solid #333333",
  justifyContent: "space-between",
  gap: "30px",
  paddingBottom: "18px",
}));

const StyledBox = styled(Box)(
  ({
    marginLeft,
    marginTop,
    gap,
    flexDirection,
    paddingBottom,
    marginBottom,
  }) => ({
    display: "flex",
    flexDirection: flexDirection || "row",
    marginLeft: marginLeft || "0px",
    marginTop: marginTop || "0px",
    marginBottom: marginBottom || "0px",
    gap: gap || "0px",
    paddingBottom: paddingBottom || "0px",
  })
);

export default function EnquiryExpiredDetails(props) {
  const { transporterId, transporterEnquiryId, enquiryStatus } = props;

  const [transporterEnquiryDetails, setTransporterEnquiryDetails] = useState(
    {}
  );
  const [previousBidAmount, setPreviousBidAmount] = useState(0);

  const [loading, setIsLoading] = useState(false);
  const [isYouQuoted, setIsYouQuoted] = useState(false);

  const transporterEnquiryById = async () => {
    try {
      setIsLoading(true);
      const response = await getTransporterEnquiryDetailsById(
        transporterId,
        transporterEnquiryId
      );
      if (response.status === 200) {
        let responseData = response?.data.data.customerEnquiry;
        setTransporterEnquiryDetails(responseData);
        let bidId = responseData?.bidId;
        setIsYouQuoted(responseData?.isYouQuoted);
        if (responseData?.isYouQuoted && bidId) {
          const bidDetailsResponse = await getBidDetailsByBidId(bidId);
          if (bidDetailsResponse.status === 200) {
            setPreviousBidAmount(bidDetailsResponse?.data.data.amountBided);
          }
        }
      }
    } catch (error) {
      console.log("error while getting enquires", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    transporterEnquiryById();
  }, []);

  return (
    <>
      {loading ? (
        <Skeleton width="100%" height={180} animation="wave" />
      ) : (
        <StyledBoxContainer>
          <Box
            sx={{
              width: "calc(100% - 460px)",
            }}
          >
            <Box>
              <StyledBox
                marginLeft="40px"
                marginTop="42px"
                gap="30px"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <StyledText>
                  {transporterEnquiryDetails?.pickup?.location?.address}
                </StyledText>

                <StyledBox flexDirection="column" paddingBottom="10px">
                  <Typography
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    {transporterEnquiryDetails?.distance + " km"}
                  </Typography>
                  <img
                    src="/assets/icons/distanceArrow.svg"
                    alt="addImage"
                    style={{
                      height: "20px",
                      width: "165px",
                    }}
                  />
                </StyledBox>

                <StyledText>
                  {transporterEnquiryDetails?.dropoff?.location?.address}
                </StyledText>
              </StyledBox>
            </Box>
            <Box
              sx={{
                marginLeft: "40px",
                marginTop: "40px",
              }}
            >
              {isYouQuoted && (
                <StyledText
                  width="370px"
                  sx={{
                    marginBottom: "10px",
                    display: "inline",
                  }}
                >
                  {`Your quotation for this enquiry was ${previousBidAmount}`}
                </StyledText>
              )}
              <StyledText width="634px" fontWeight="700" textTransform="none">
                {ENQUIRY_STATUS_TO_TEXT_MAP[enquiryStatus]}
              </StyledText>
            </Box>
          </Box>

          <StyledBox
            flexDirection="column"
            sx={{
              marginRight: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "10px",
            }}
          >
            <StyledBox marginBottom="10px" gap="10px">
              <SimpleCard
                heading={transporterEnquiryDetails?.material?.name}
                subHeading={"Material"}
              />
              <SimpleCard
                heading={`${transporterEnquiryDetails?.weight / 1000} ${
                  transporterEnquiryDetails?.weight / 1000 <= 1 ? "Ton" : "Tons"
                }`}
                subHeading={"Weight"}
              />
            </StyledBox>
            <SimpleCard
              heading={
                transporterEnquiryDetails?.customer?.preferredVehicle
                  ?.vehicleCategory +
                " Wheeler"
              }
              subHeading={" Customers Vehicle Choice"}
              width={"390px"}
            />
          </StyledBox>
        </StyledBoxContainer>
      )}
    </>
  );
}
