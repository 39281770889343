import { adminApi } from "../config";
import axios from "axios";
// function to get all trips
export const getAllTrips = async (
  page,
  tripStatus,
  customerPaymentStatus,
  transporterPaymentStatus,
  tripDate,
  search,
  pageSize = 15
) => {
  try {
    const queryParams = new URLSearchParams({
      page: page + 1,
      tripStatus,
      customerPaymentStatus,
      transporterPaymentStatus,
      tripDate,
      search,
      pageSize,
    }).toString();

    const response = adminApi.get(`admin/trip?${queryParams}`);

    return response;
  } catch (error) {
    return error;
  }
};

// function to get the single details of a trip
export const getTripDetails = async (tripId) => {
  try {
    const response = adminApi.get(`admin/trip/${tripId}`);
    return response;
  } catch (error) {
    return error;
  }
};

// function to create a payment order
export const createOrder = async (tripId, amount) => {
  try {
    const response = adminApi.post(`payment/orders/${tripId}`, { amount });
    return response;
  } catch (error) {
    return error;
  }
};

// function to create transaction
export const createTransaction = async (transactionData) => {
  try {
    const response = adminApi.post(`transaction`, transactionData);
    return response;
  } catch (error) {
    return error;
  }
};

// function to get the list of transactions for a transporter
export const getTransporterTransactions = async (tripId) => {
  try {
    const response = await adminApi.get(
      `transaction?tripId=${tripId}&userType=transporter`
    );
    return response;
  } catch (error) {
    return error;
  }
};

// function to get the list of transactions for a customer
export const getCustomerTransactions = async (tripId) => {
  try {
    const response = await adminApi.get(
      `transaction?tripId=${tripId}&userType=customer`
    );
    return response;
  } catch (error) {
    return error;
  }
};

// function to send payment alert to the customer
export const sendPaymentAlert = async (data) => {
  try {
    const response = await adminApi.post(`admin/trip/send-payment-alert`, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const cancelTrip = async (
  tripId,
  cancelledBy,
  reason,
  cancelledAt,
  vehicleCategory
) => {
  try {
    const response = await adminApi.post("admin/trip/cancel", {
      tripId,
      cancelledBy,
      reason,
      cancelledAt,
      vehicleCategory,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const refundPayment = async (
  paymentId,
  newAmount,
  amount,
  customerId,
  penalty,
  newPenalty,
  tripId,
  customerEnquiryId
) => {
  try {
    const response = await adminApi.post(`payment/${paymentId}/refund`, {
      newAmount,
      amount,
      customerId,
      penalty,
      newPenalty,
      tripId,
      customerEnquiryId,
    });
    return response;
  } catch (error) {
    return error;
  }
};
