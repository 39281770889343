import { Chip, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { uploadimage } from "../../../services/uploadImageToS3";
import { Stack } from "@mui/system";

const StyledInputLabel = (props) => (
    <InputLabel
      htmlFor={props.htmlFor}
      sx={{ color: props?.disabled ? "#6d6d6d" : "#333", fontSize: ".935rem", fontWeight: 700 }}
    >
      {props.children}{" "}
      {props.isRequired && <span style={{ color: "red" }}>*</span>}
    </InputLabel>
  );

  const StyledTextField = ({
    name,
    placeholder,
    onChange,
    value,
    error,
    helperText,
    disabled = false,
  }) => {
    return (
      <TextField
        variant="outlined"
        placeholder={placeholder}
        fullWidth
        InputProps={{
          style: { height: "2.75rem" },
        }}
        onChange={onChange}
        value={value}
        error={error}
        helperText={helperText}
        disabled={disabled}
        name={name}
      />
    );
  };
  const CheckBoxLabel = ({ option }) => (
    <InputLabel
      htmlFor={option}
      sx={{
        color: "#333",
        fontSize: "1rem",
        fontWeight: 400,
        cursor: "pointer",
      }}
    >
      {option}
    </InputLabel>
  );
  const MultiSelectDropdown = ({
    options = ["OPEN_TOP", "SIDE_OPEN"],
    updateBodyCategory,
    name,
    value = [],
  }) => {
    const mapper = {
      OPEN_TOP: "Open Top",
      SIDE_OPEN: "Side Open",
    };
    const [selectedValues, setSelectedValues] = useState([]);
    useEffect(() => {
      setSelectedValues((p) => [...p, ...value]);
    }, [value.length]);
  
    const handleSelectChange = (event) => {
      setSelectedValues(event.target.value);
      updateBodyCategory(event.target.value);
    };
   
    return (
      <Select
        name={name}
        fullWidth
        sx={{
          minHeight: "2.75rem",
          height:"2.75rem",
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ADADAD",
          },
        }}
        multiple
        value={selectedValues}
        onChange={handleSelectChange}
        renderValue={(selected) => (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {selectedValues.length === 0 && <p>Select</p>}
            {selected.map((value) => (
              <Chip
                key={value}
                label={mapper[value]}
                sx={{ mx: 0.5, background: "#DDE7EF" }}
              />
            ))}
          </div>
        )}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {mapper[option]}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const TruckImageUploader = ({ side, updateImage, image = null }) => {
    const [currentImage, setCurrentImage] = useState(image);
  
    const handleImageChange = async (event) => {
      const file = event.target.files[0];
      const docImage = await uploadimage(`public/Transporter/Vehicle`, file);
      setCurrentImage(docImage);
      updateImage(docImage);
  
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {};
        reader.readAsDataURL(file);
      }
    };
    const imageRef = useRef(null);
    useEffect(() => {
      setCurrentImage(image);
    }, [image]);
    return (
      <Paper
        elevation={4}
        sx={{ width: "195px", height: "136px", cursor: "pointer" }}
        onClick={() => imageRef.current.click()}
      >
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={(event) => handleImageChange(event)}
          ref={imageRef}
        />
        {currentImage ? (
          <img
            src={currentImage}
            alt="pp"
            width="100%"
            height="100%"
            style={{ objectFit: "cover", borderRadius: 2.5 }}
          />
        ) : (
          <Stack
            width="100%"
            height="100%"
            direction="column"
            gap={1}
            alignItems="center"
            justifyContent="center"
          >
            <img
              src={`/assets/icons/truck${side}Side.svg`}
              alt={side}
              style={{
                width: side === "Left" || side === "Right" ? "130px" : "35px",
                height: "42px",
              }}
            />
            <img
              src="/assets/icons/uploadIcon.svg"
              alt="upload"
              style={{ width: "16px", height: "16px" }}
            />
            <Typography>{side} side</Typography>
          </Stack>
        )}
      </Paper>
    );
  };

  export {StyledInputLabel,StyledTextField,CheckBoxLabel,MultiSelectDropdown,TruckImageUploader}