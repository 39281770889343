import React from "react";
import { PlacesAutocomplete } from "../../../Common/PlacesAutoComplete";
import { useMapsService } from "../../../../hooks/useMapsService";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import ClearIcon from "@mui/icons-material/Clear";

const StyledInput = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "4px 12px",
  background: "#dde7ef",
  margin: "2px",
  gap: "8px",
  borderRadius: "3px",
  border: "1px solid #5386e4",
}));

const CityMultiSelectDropdown = (props) => {
  const { getExactMatch } = useMapsService();

  const removeItem = (index) => {
    const selectedClone = [...props.selectedItems];
    selectedClone.splice(index, 1);
    props.setItems?.(selectedClone);
  };

  return (
    <Box width="100%" fontWeight="700" fontSize="14px">
      <PlacesAutocomplete
        value=""
        resetError={() => {}}
        placeholderText={props.placeholder}
        onPlaceSelect={async (place, placeId) => {
          if (!place) return;

          const res = await getExactMatch(placeId);

          const selectedName = res?.exactMatch?.long_name;

          if (
            selectedName &&
            !props.selectedItems.find((i) => i.area === selectedName)
          ) {
            const selectedOb = {
              area: selectedName,
              latitude: res.latitude.toString(),
              longitude: res.longitude.toString(),
              city: props.city,
            };

            const tempArray = [...props.selectedItems, selectedOb];
            props.setItems(tempArray);
          }
        }}
        blurOnSelect
        clearOnBlur
        error={props.error}
        helperText={props.helperText}
        city={props.city}
      />

      {props.selectedItems && (
        <Box
          sx={{
            ...(props.selectedItems.length
              ? {
                  border: "1px solid #c6c6c6",
                  borderTop: "none",
                  fontWeight: "700",
                  fontSize: "14px",
                  padding: "4px 12px",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexWrap: "wrap",
                }
              : {}),
          }}
        >
          {props.selectedItems?.map((item, index) => (
            <StyledInput key={index}>
              <Box>{item.area}</Box>

              <ClearIcon
                onClick={() => removeItem(index)}
                cursor="pointer"
                sx={{
                  width: "16px",
                  height: "16px",
                }}
              />
            </StyledInput>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default CityMultiSelectDropdown;
