import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  getEstimateRangeForEnquiry,
  postAnEnquiry,
} from "../../api/Customer/enquiry";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const EnquiryEstimatePage = ({ enquiryId, setSwitchComp, vehicleCategory }) => {
  const navigate = useNavigate();
  const [vehicleOpted, setVehicleOpted] = useState("");
  const [estimateCode, setEstimateCode] = useState("");
  const [enquiryStatus, setEnquiryStatus] = useState("");
  const [estimationDetails, setEstimationDetails] = useState(null);
  const [tripData, setTripData] = useState(null);

  // holds entire response of an api
  const [estimationData, setEstimationData] = useState(null);

  const fetchEnquiryEstimationDetails = async () => {
    try {
      const response = await getEstimateRangeForEnquiry(enquiryId);
      setEstimateCode(response.data.data.estimateCode);
      setEstimationData(response.data.data);
    } catch (err) {
      setEstimateCode(-1);
    }
  };
  const handlePostEnquiry = async () => {
    try {
      await postAnEnquiry(enquiryId);
      navigate(
        "/customer/dashboard",
        {
          state: {
            isEnquiryFlow: true,
            isServiceable: true,
          },
        },
        { replace: true }
      );
    } catch (err) {}
  };
  useEffect(() => {
    fetchEnquiryEstimationDetails();
  }, []);

  const EstimationView = () => {
    switch (estimateCode) {
      case 1: // Any vehicle preferred
        return (
          <AnyVehiclePreferredView
            data={estimationData}
            enquiryStatus={estimationData.enquiryStatus}
          />
        );
      case 2: // cannot calculate
        return (
          <Typography color="#333" fontWeight={700} variant="h5">
            {estimationData.enquiryStatus === "NO_SERVICE"
              ? "Currently we are not serving for the selected route"
              : "Estimation cannot be calculated"}
          </Typography>
        );
      case 3: // cannot calculate , large distance
        return (
          <Typography color="#333" fontWeight={700} variant="h5">
            Distance too large, Estimation cannot be calculated
          </Typography>
        );
      case 4: // Calculated
        return (
          <EstimationCalculatedView
            data={estimationData}
            vehicleCategory={vehicleCategory}
          />
        );
      case -1: // if api fails
        return (
          <ErrorFetchingEstimate
            fetchEnquiryEstimationDetails={fetchEnquiryEstimationDetails}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Box>
      <Typography
        fontWeight={700}
        color="#333"
        sx={{
          background: "#DBEAF9",
          textAlign: "center",
          padding: ".625rem 0",
          borderRadius: ".25rem",
        }}
      >
        Your requirement is understood and you are one step away from posting
        enquiry
      </Typography>
      <Box
        mt={1}
        border="1px solid #C6C6C6"
        textAlign="center"
        borderRadius={1}
        p={3}
      >
        {EstimationView()}
        {estimationData && <CargoDetails data={estimationData} />}
      </Box>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        mt={2}
        gap={2}
      >
        {estimationData && estimationData.enquiryStatus === "NO_SERVICE" ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              // navigate(
              //   "/customer/dashboard",
              //   {
              //     state: {
              //       isEnquiryFlow: true,
              //       isServiceable: false,
              //     },
              //   },
              //   { replace: true }
              // )
              handlePostEnquiry()
            }
          >
            Post Enquiry
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handlePostEnquiry}
          >
            Post Enquiry
          </Button>
        )}
        <Button
          sx={{ textTransform: "none" }}
          onClick={() => setSwitchComp(true)}
        >
          Go Back
        </Button>
      </Stack>
    </Box>
  );
};
export default EnquiryEstimatePage;

const EstimationCalculatedView = ({ data, vehicleCategory }) => {
  const { estimationDetails, distance, customer, weight } = data;
  return (
    <Box>
      <Typography variant="body" fontWeight={700} color="#333">
        {vehicleCategory}
      </Typography>
      <Stack direction="row" justifyContent="center" mt={1} gap={2}>
        <Typography variant="h4" fontWeight={700} color="#333">
          Estimated Fare Per Ton{" "}
        </Typography>
        <Typography variant="h4" fontWeight={700} color="#0C7F44">
          &#8377;{parseInt(estimationDetails.minFare / (weight / 1000))} To
          &#8377;
          {parseInt(estimationDetails.maxFare / (weight / 1000))}{" "}
        </Typography>
      </Stack>
      <Typography mt={1}></Typography>
      <Typography fontSize={20} color="#6D6D6D" fontWeight={700} mt={1}>
        Total Estimated Fare &#8377;{parseInt(estimationDetails.minFare)} To
        &#8377;{parseInt(estimationDetails.maxFare)}
      </Typography>
      <Typography fontSize={16} mt={1} color="#0C7F44" fontWeight={700}>
        This price range is just an estimate. You might receive lower offers
        once a transporter match is found.
      </Typography>
    </Box>
  );
};

const AnyVehiclePreferredView = ({ enquiryStatus }) => {
  return (
    <Box>
      <Typography variant="h4" color="#333" fontWeight={600} gutterBottom>
        Any Vehicle Preferred
      </Typography>
      <Typography variant="h5" color="#6d6d6d">
        {enquiryStatus !== "NO_SERVICE"
          ? "You will receive the best offers after the enquiry is posted"
          : ""}
      </Typography>
    </Box>
  );
};

const ErrorFetchingEstimate = ({ fetchEnquiryEstimationDetails }) => {
  return (
    <Stack>
      <Typography color="#333" variant="h5">
        Error, fetching estimatation information.
      </Typography>
      <Button
        color="primary"
        variant="contained"
        onClick={fetchEnquiryEstimationDetails}
      >
        Retry
      </Button>
    </Stack>
  );
};

const CargoDetails = ({ data }) => {
  const SubtitleText = (props) => (
    <Typography
      color="#ADADAD"
      fontWeight={700}
      variant="subtitle"
      gutterBottom
    >
      {props.children}
    </Typography>
  );
  const { weight, material, pickup } = data;
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      gap={6}
      mt={5}
    >
      <Stack>
        <SubtitleText>Goods Type</SubtitleText>
        <Typography variant="subtitle" fontWeight={700} color="#333">
          {material?.name || "-"}
        </Typography>
      </Stack>
      <Stack>
        <SubtitleText>Weight</SubtitleText>
        <Typography variant="subtitle" fontWeight={700} color="#333">
          {(weight / 1000).toFixed(3)} Tons
        </Typography>
      </Stack>
      <Stack>
        <SubtitleText>Pickup Date</SubtitleText>
        <Typography variant="subtitle" fontWeight={700} color="#333">
          {moment(pickup.date).format("DD-MM-YYYY")}
        </Typography>
      </Stack>
      <Stack>
        <SubtitleText>Pickup Time</SubtitleText>
        <Typography variant="subtitle" fontWeight={700} color="#333">
          {pickup.slot}
        </Typography>
      </Stack>
    </Stack>
  );
};
