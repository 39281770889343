import React from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";

import TransporterEnquiries from "../../../component/Transporter/Enquiries";

export default function Enquiries() {
  const StyledBox = styled(Box)(() => ({
    width: "100%",
    height: "calc(100% - 60px)",
  }));
  return (
    <StyledBox>
      <TransporterEnquiries />
    </StyledBox>
  );
}
