import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getTrucksListOfTransporter } from "../../api/Admin/transporterResources";
import { StyledTableRow } from "../Common/StyledTableRow";
import { StyledTableCell } from "../../pages/Admin/Approval/StyledTableCell";
import useToast from "../../hooks/useToast";

const TransportersTrucksAccordion = ({
  panelExpanded,
  updateExpandedPanel,
  id,
}) => {
  const [truckList, setTruckList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const { handleOpenToast } = useToast();

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setPageSize(parseInt(event.target.value, 10));
  };

  const createTable = (truck) => {
    return (
      <StyledTableRow key={truck.name}>
        <StyledTableCell>{truck.vehicleNumber || "-"}</StyledTableCell>
        <StyledTableCell>
          {`${truck.vehicleCategory} Wheeler` || "-"}
        </StyledTableCell>
        <StyledTableCell>{truck?.vehicleModelName || "-"}</StyledTableCell>
        <StyledTableCell>{truck?.driverName || "-"} </StyledTableCell>
      </StyledTableRow>
    );
  };

  const fetchTruckList = async () => {
    try {
      const response = await getTrucksListOfTransporter(
        id,
        page,
        pageSize,
        search
      );
      if (response.status === 200) {
        setTruckList(response.data.data.vehicles);
        setPaginationData(response.data.data.pagination);
      }
    } catch (error) {
      handleOpenToast("Error while getting trucks list", "error");
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  useEffect(() => {
    fetchTruckList();
  }, [id, page, pageSize, debouncedSearch]);

  return (
    <Accordion
      disableGutters
      TransitionProps={{ unmountOnExit: true }}
      sx={{ borderRadius: ".35rem" }}
      expanded={panelExpanded === "trucks"}
      onChange={updateExpandedPanel("trucks")}
    >
      <AccordionSummary
        expandIcon={
          <img src="/assets/icons/expand_less.svg" alt="expand_less" />
        }
        aria-controls="panel1bh-content"
        sx={{
          background: "#EAEAEA",
          paddingLeft: "2rem",
          fontWeight: 700,

          "&.Mui-expanded": {
            paddingTop: 0,
          },
          "&.MuiAccordionSummary-root": {
            height: "36px !important",
            minHeight: "36px !important",
          },
        }}
      >
        {"Trucks"}
      </AccordionSummary>

      <AccordionDetails
        sx={{
          background: "#FFF",
          paddingLeft: "2rem",
          paddingTop: "1rem",
        }}
      >
        <TextField
          variant="outlined"
          placeholder="Search truck number"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          InputProps={{
            sx: { height: "36.31px" },
            endAdornment: (
              <img src="/assets/icons/search-icon.svg" alt="search" />
            ),
          }}
        />
        {
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 700 }}>Truck Number</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>
                    Vehicle Category
                  </TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Vehicle Model</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>
                    Assigned Driver
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {truckList?.length !== 0 ? (
                  truckList.map((truck) => createTable(truck))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      The transporter has not yet added any trucks.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </AccordionDetails>
      {truckList?.length > 0 && (
        <Box
          sx={{
            borderTop: "1px solid #adadad",
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={paginationData.totalItems}
            rowsPerPage={paginationData.pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ count }) => (
              <div>
                Page{" "}
                <span style={{ color: "green", fontWeight: 700 }}>
                  {page + 1}
                </span>{" "}
                of {Math.ceil(count / paginationData.pageSize)}
              </div>
            )}
          />
        </Box>
      )}
    </Accordion>
  );
};

export default TransportersTrucksAccordion;
