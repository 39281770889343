import { Box } from "@mui/material";
import LoginDialog from "../../component/Common/LoginDialog";

const AdminLoginPage = () => {
  return (
    <Box
      sx={{
        height:'100vh',
        width:"100vw",
        opacity:0.94,
        background:
          "linear-gradient(180deg, rgba(103, 124, 119, 0.69) 0%, rgba(64, 71, 80, 0.88) 73.45%)",
      }}
    >
        <LoginDialog isLoginModalOpen={true} stakeholder="admin" />
    </Box>
  );
};

export default AdminLoginPage;
