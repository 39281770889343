import { client } from "../config";

export const updateTransporterNotificationPreferences = async (
  transporterNotificationData
) => {
  return await client.put(
    "/transporter/profile/notificationStatus",
    transporterNotificationData
  );
};
