import React, { useState } from "react";
import { Typography, Box, Button, Modal } from "@mui/material";
import CallBackModal from "../../component/Common/CallBackModal";
import { useSelector } from "react-redux";

const ContactUsSection = ({ handleSectionNavigation }) => {
  const supportNumber = useSelector(
    (state) => state.supportNumbers?.customerPortal
  );
  // State to control the visibility of the callback modal
  const [isCallBackModalOpen, setIsCallBackModalOpen] = useState(false);
  const number = useSelector((state) => state.supportNumbers?.landingPage);
  // Function to handle the "Reach Us" button click event
  const handleReachUsButtonClick = () => {
    setIsCallBackModalOpen(true);
  };

  /**
   * Closes the callback modal when called.
   */
  const closeCallBackModal = () => {
    setIsCallBackModalOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "auto",
        gap: "24px",
        pt: "90px",
        pb: "90px",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          color: "#333",
          textAlign: "center",
          fontFamily: "Helvetica",
          fontSize: "36px",
          fontWeight: 700,
        }}
      >
        Are you ready to accelerate your business?
      </Typography>

      <Typography
        variant="body1"
        sx={{
          color: "#4E4E4E",
          textAlign: "center",
          fontFamily: "Helvetica",
          fontSize: "24px",
          fontWeight: 400,
          maxWidth: "962px",
        }}
      >
        Discover how we can boost your logistics
      </Typography>
      <Button
        variant="contained"
        onClick={() => handleSectionNavigation("home")}
        style={{
          backgroundColor: "#0C7F44",
          color: "#FFF",
          textAlign: "center",
          fontFamily: "Helvetica",
          fontSize: "24px",
          fontWeight: 700,
          width: "298px",
        }}
      >
        BOOK YOUR TRUCK
      </Button>
      <Modal open={isCallBackModalOpen} onClose={closeCallBackModal}>
        <CallBackModal
          closeCallBackModal={closeCallBackModal}
          number={number}
        />
      </Modal>

      <Button
        variant="contained"
        style={{
          backgroundColor: "#FFFFFF",
          color: "#000000",
          fontFamily: "Helvetica",
          fontSize: "24px",
          fontWeight: 700,
          border: "2px solid #333",
          width: "298px",
        }}
        onClick={handleReachUsButtonClick}
      >
        Reach Us
      </Button>
    </Box>
  );
};

export default ContactUsSection;
