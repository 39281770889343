import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const useToast = (props) => {
  const position = props ? props : {vertical:'bottom',horizontal:"left"}
  const [toastData, setToastData] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleOpenToast = (message, severity) => {
    setToastData({
      open: true,
      message,
      severity,
    });
  };

  const handleCloseToast = (event, reason) => {
    setToastData({
      ...toastData,
      open: false,
    });
  };

  const ToastMessage = ({
    open,
    message,
    severity,
    autoHideDuration = 3000,
  }) => {
    return (
      <Snackbar
        open={toastData.open}
        autoHideDuration={autoHideDuration}
        onClose={handleCloseToast}
        anchorOrigin={position}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseToast}
          severity={toastData.severity}
        >
          {toastData.message}
        </MuiAlert>
      </Snackbar>
    );
  };

  return {
    toastData,
    handleOpenToast,
    handleCloseToast,
    ToastMessage,
  };
};

export default useToast;
