import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import Timer from "./Timer";
import {
  getTransporterRelatedTime,
  updateTransporterRelatedTime,
} from "../../../../api/Admin/TimeRelated";
import useToast from "../../../../hooks/useToast";

const TransporterRelated = () => {
  const [
    transporterTripCancellationDeadline,
    setTransporterTripCancellationDeadline,
  ] = useState({
    d: 0,
    hr: 0,
    min: 0,
    sec: 0,
  });

  const [
    transporterPaymentSettlementDeadline,
    setTransporterPaymentSettlementDeadline,
  ] = useState({
    d: 0,
    hr: 0,
    min: 0,
    sec: 0,
  });

  const [isLoading, setIsLoading] = useState(false);

  const { handleOpenToast, ToastMessage } = useToast();

  // function to fetch transporter related time configurations
  const fetchTransporterRelatedConfigs = async () => {
    try {
      setIsLoading(true);
      const response = await getTransporterRelatedTime();
      if (response.status === 200) {
        setTransporterTripCancellationDeadline(
          response?.data?.data?.tripCancellationDeadlineTransporter
        );
        setTransporterPaymentSettlementDeadline(
          response?.data?.data?.paymentSettlementDeadlineTransporter
        );
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleOpenToast(
        "Error while fetching transporter related configurations",
        "error"
      );
    }
  };

  // function for update Transporter Trip Cancellation Deadline
  const updateTransporterTripCancellationDeadline = async (timeData) => {
    try {
      const response = await updateTransporterRelatedTime(
        {
          tripCancellationDeadlineTransporter: timeData,
        },
        true,
        false
      );
      if (response.status === 200) {
        fetchTransporterRelatedConfigs();
        handleOpenToast(
          "Transporter Trip Cancellation Deadline updated",
          "success"
        );
      }
    } catch (error) {
      handleOpenToast(
        "Error while updating Transporter Trip Cancellation Deadline",
        "error"
      );
    }
  };

  // function for update Transporter Payment Settlement Deadline
  const updateTransporterPaymentSettlementDeadline = async (timeData) => {
    try {
      const response = await updateTransporterRelatedTime(
        {
          paymentSettlementDeadlineTransporter: timeData,
        },
        false,
        true
      );
      if (response.status === 200) {
        fetchTransporterRelatedConfigs();
        handleOpenToast(
          "Transporter Payment Settlement Deadline updated",
          "success"
        );
      }
    } catch (error) {
      handleOpenToast(
        "Error while updating Transporter Payment Settlement Deadline",
        "error"
      );
    }
  };

  useEffect(() => {
    fetchTransporterRelatedConfigs();
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "35vh",
          width: "100%",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box>
      <ToastMessage />
      <Timer
        title="Transporter Trip Cancellation Deadline"
        day={transporterTripCancellationDeadline.d}
        hour={transporterTripCancellationDeadline.hr}
        minute={transporterTripCancellationDeadline.min}
        second={transporterTripCancellationDeadline.sec}
        onSave={updateTransporterTripCancellationDeadline}
      />
      <Timer
        title="Transporter Payment Settlement Deadline"
        day={transporterPaymentSettlementDeadline?.d}
        hour={transporterPaymentSettlementDeadline?.hr}
        minute={transporterPaymentSettlementDeadline?.min}
        second={transporterPaymentSettlementDeadline?.sec}
        onSave={updateTransporterPaymentSettlementDeadline}
      />
    </Box>
  );
};

export default TransporterRelated;
