import React from "react";
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";

const StyledBox = styled(Box)(({ width }) => ({
  backgroundColor: "#FFFFFF",
  padding: "15px",
  width: width || "190px",
  borderRadius: "5px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

export default function SimpleCard(props) {
  const { fromDashboard } = props;

  const StyledHeading = styled(Typography)(({ fontWeight, fromDashboard }) => ({
    color: "#6D6D6D",
    textAlign: "center",
    fontSize: fromDashboard ? "10px" : "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    textTransform: "capitalize",
  }));

  return (
    <StyledBox width={props.width}>
      <StyledHeading fromDashboard={fromDashboard} paddingBottom="2px">
        {props.heading}
      </StyledHeading>
      <StyledHeading fromDashboard={fromDashboard}>
        {props.subHeading}
      </StyledHeading>
    </StyledBox>
  );
}
