import { adminApi } from "../config";

export const getSupportTicketsList = async (
  statusFilter,
  priorityFilter,
  inputValue,
  page
) => {
  try {
    const response = await adminApi.get(
      `admin/support-tickets?page=${
        page + 1
      }&status=${statusFilter}&priority=${priorityFilter}&mobileNumber=${inputValue}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const createSupportTicket = async (ticketData) => {
  return await adminApi.post(`admin/support-tickets`, ticketData);
};

export const getTicketDetailsByTicketId = async (ticketId) => {
  return await adminApi.get(`admin/support-tickets/${ticketId}`);
};
export const addComment = async (latestComment, ticketId) => {
  return await adminApi.post(`admin/support-tickets/${ticketId}/comments`, {
    text: latestComment,
  });
};
export const updateTicket = async (ticketPriority, ticketStatus, ticketId) => {
  return await adminApi.put(`admin/support-tickets/${ticketId}`, {
    prirority: ticketPriority,
    status: ticketStatus,
  });
};
