import React, { useEffect, useState } from "react";
import { AppBar, Box, Stack, Toolbar, Typography } from "@mui/material";
import GetInTouch from "../../../component/Common/GetInTouch";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import RebookScreen from "./RebookScreen";
import { getCustomerEnquiryDetailsById } from "../../../api/Customer/enquiry";
import useToast from "../../../hooks/useToast";
import EastIcon from "@mui/icons-material/East";

const Rebook = () => {
  const getInTouchNumber = useSelector(
    (state) => state.supportNumbers.landingPage
  );
  const [enquiryDetails, setEnquiryDetails] = useState(null);

  const [formData, setFormData] = useState({});

  const navigate = useNavigate();
  const { handleOpenToast } = useToast();

  const getEnquiryDetails = async (customerId, enquiryId) => {
    try {
      const response = await getCustomerEnquiryDetailsById(
        customerId,
        enquiryId
      );
      if (response.status === 200) {
        const details = response?.data?.data.enquiry;
        setEnquiryDetails(details);
        setFormData({
          material: details?.material,
          weight: details?.weight,
          vehicleCategory: details?.customer?.preferredVehicle?.vehicleCategory,
          vehicleCategoryId:
            details?.customer?.preferredVehicle?.vehicleCategoryId,
          date: null,
          slot: "",
          quantity: details?.quantity,
          name: details?.customer?.customerName,
          pickupLocation: details?.pickup?.location,
          dropOffLocation: details?.dropoff?.location,
          distance: details?.distance,
          customerId: details?.customer?.customerId,
          updatedDistance: false,
        });
      }
    } catch (error) {
      handleOpenToast("Something went wrong", "error");
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      getEnquiryDetails(
        location.state.customerId,
        location.state.customerEnquiryId
      );
    }
  }, [location.state]);

  return (
    <Box>
      <AppBar
        position="relative"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          boxShadow: "none",
          background: "#fff",
        }}
      >
        <Toolbar sx={{ height: "6rem" }}>
          <Stack
            direction="row"
            m="auto"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            maxWidth="lg"
          >
            <Stack direction="row" alignItems="center" spacing="1.25rem">
              <img
                src="/assets/icons/LZ-logo-face.svg"
                style={{ marginBottom: ".35rem" }}
                alt="logoface"
              />
              <img src="/assets/icons/LZ-logo.svg" alt="logo" />
            </Stack>

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <GetInTouch />
              <Typography color="#0C7F44" fontWeight={700} align="right">
                {getInTouchNumber}
              </Typography>
            </Box>
          </Stack>
        </Toolbar>
      </AppBar>
      <Stack
        direction="row"
        alignItems="center"
        maxWidth="lg"
        m="auto"
        p={2}
        bgcolor="#f3f3f3"
      >
        <ArrowBackIosIcon
          onClick={() => {
            navigate(-1);
          }}
          sx={{ cursor: "pointer" }}
        />

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          gap={5}
        >
          <Typography
            color="#333"
            fontWeight={700}
            sx={{
              width: "40%",
            }}
          >
            {formData?.pickupLocation?.address}
          </Typography>
          <Stack direction="column" alignItems="center" maxWidth="20%">
            <EastIcon />
            {formData?.updatedDistance && (
              <Typography color="#333" fontWeight={700}>
                {formData?.distance} km
              </Typography>
            )}
          </Stack>
          <Typography
            color="#333"
            fontWeight={700}
            sx={{
              width: "40%",
            }}
          >
            {formData?.dropOffLocation?.address}
          </Typography>
        </Stack>
      </Stack>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
        maxWidth="lg"
        m="auto"
        pt={3}
      ></Box>
      <Box maxWidth="lg" m="auto" pt={3}>
        <RebookScreen
          enquiryId={"33"}
          enquiryDetails={enquiryDetails}
          setFormData={setFormData}
          formData={formData}
        />
      </Box>
    </Box>
  );
};

export default Rebook;
