import { adminApi } from "../config";

// Function to fetch all vehicles with pagination.
export const getAllVehicle = async (currentPage, pageSize = "10") => {
  try {
    const response = await adminApi.get(
      `inventory/vehicle-type/?page=${currentPage}&pageSize=${pageSize}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

// Function to create a new vehicle.
export const createVehicle = async (vehicleData) => {
  try {
    const response = await adminApi.post(`inventory/vehicle-type`, vehicleData);
    return response;
  } catch (error) {
    return error;
  }
};

// Function to edit/update a vehicle.
export const editVehicle = async (vehicleId, updatedVehicleData) => {
  try {
    const response = await adminApi.put(
      `inventory/vehicle-type/${vehicleId}`,
      updatedVehicleData
    );
    return response;
  } catch (error) {
    return error;
  }
};

// Function to delete a selected vehicle.
export const deleteSelectedVehicle = async (vehicleId) => {
  try {
    const response = await adminApi.delete(
      `inventory/vehicle-type/${vehicleId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};
