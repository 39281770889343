import React, { useEffect } from "react";
import AWS from "aws-sdk";
import { Container, Stack, Divider, Typography, Button } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY_ID,
  region: process.env.REACT_APP_AWS_REGION,
});

const s3 = new AWS.S3();
const bucketName = process.env.REACT_APP_S3_APK_INSTALLER_BUCKET;

const DriverAppPage = () => {
  useEffect(() => {
    downloadDriverApp();
  }, []);

  const downloadDriverApp = () => {
    try {
      const installerObjectKey = process.env.REACT_APP_S3_APK_INSTALLER_KEY;
      const urlPromise = s3.getSignedUrlPromise("getObject", {
        Bucket: bucketName,
        Key: installerObjectKey,
        Expires: 30,
      });
      urlPromise.then(
        function (url) {
          const fileName = process.env.REACT_APP_S3_APK_INSTALLER_KEY;
          const aTag = document.createElement("a");
          aTag.href = url;
          aTag.setAttribute("download", fileName);
          document.body.appendChild(aTag);
          aTag.click();
          aTag.remove();
        },
        function (err) {
          throw Error("download driver app failed!")
        }
      );
    } catch (error) {
      throw Error("download driver app failed!")
    }
  };

  return (
    <>
      <Container
        style={{
          height: "100vh",
          paddingTop: 40,
        }}
      >
        <Stack
          sx={{
            height:{
              xs:30,
              sm:30,
              md: 35,
              lg: 40,
              
            }
          }}
          style={{
            marginBottom: 25,
          }}
          direction="row"
          spacing={2}
        >
          <img
            src="/assets/icons/LZ-logo-face.svg"
            alt="logo face"
            style={{
              marginBottom: 5,
              marginTop: 5,
            }}
          />
          <img
            src="/assets/icons/LZ-logo.svg"
            alt="logo"
            style={{
              marginTop: 5,
            }}
          />
        </Stack>
        <Divider />
        <Stack
          sx={{
            padding: {

              md:"0px 200px",              
              lg:"0px 300px"
            }
          }}
          style={{
            margin: "50px 0px",
          }}
        >
          <Typography
            variant="h6"
            style={{
              textAlign: "center",
              margin: "50px 0px"
            }}
          >
            Your{" "}
            <span
              style={{
                fontSize: 21,
                fontWeight: "bold",
                color: "green",
              }}
            >
              Driver App{" "}
            </span>{" "}
            download will begin automatically....
          </Typography>

          <Typography variant="caption" style={{
            textAlign:"center"
          }}>
            if app auto-download is not started within <span style={{
              fontSize:"small",
              color: "orange"
            }}>10s</span>, Please click below button.
          </Typography>
          <Button variant="contained" 
              color="success"
              startIcon={<GetAppIcon />}
              onClick={downloadDriverApp}>
              Download Driver App
            </Button>
        </Stack>
      </Container>
    </>
  );
};

export default DriverAppPage;
