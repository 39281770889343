import React, { useState, useCallback, useRef, useEffect } from "react";
import { Avatar, Box, Button, IconButton } from "@mui/material";
import { uploadimage } from "../services/uploadImageToS3";

const useUserAvatar = (initialImage = "") => {
  const [image, setImage] = useState(initialImage);
  // path to image on S3 is stored in imageURI
  const [imageURI, setImageURI] = useState("");
  const inputRef = useRef("");
  useEffect(() => {
    setImage(initialImage);
  }, [initialImage]);

  const processFile = async(file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
      
      const imgUrl = await uploadimage(
        `public/Customer/Profile/ProfilePhoto`,
        file
      );
      setImageURI(imgUrl);
    }
  };

  const handleImageChange = (event) => {
    processFile(event.target.files[0]);
  };

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      processFile(event.dataTransfer.files[0]);
    }
  }, []);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const AvatarComponent = (props) => {
    return (
      <Box sx={{ textAlign: "center" }}>
        <input
          ref={inputRef}
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleImageChange}
        />

        <Box
          sx={{ cursor: "pointer" }}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onClick={() => inputRef.current.click()}
        >
          <Avatar
            alt="User Avatar"
            src={image}
            sx={{ width: 120, height: 120, }}
          />
          <IconButton
            aria-label="edit image"
            sx={{ fontSize: ".75rem", color: "#333" }}
          >
            Edit Photo
          </IconButton>
        </Box>
      </Box>
    );
  };
  return [AvatarComponent, imageURI];
};

export default useUserAvatar;
