import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  name: "",
  selectedRow: null,
  enquiryStatusTracking: "",
  transporterNotificationPreferences: {},
};
const transporterSlice = createSlice({
  name: "transporter",
  initialState,
  reducers: {
    setTransporterId: (state, action) => {
      state.id = action.payload;
    },
    setTransporterName: (state, action) => {
      state.name = action.payload;
    },
    setSelectedRow: (state, action) => {
      state.selectedRow = action.payload;
    },
    setEnquiryStatusTracking: (state, action) => {
      state.enquiryStatusTracking = action.payload;
    },
    setTransporterNotificationPreference: (state, action) => {
      state.transporterNotificationPreferences = action.payload;
    },
  },
});

export const {
  setTransporterId,
  setTransporterName,
  setSelectedRow,
  setEnquiryStatusTracking,
  setTransporterNotificationPreference,
} = transporterSlice.actions;
export default transporterSlice.reducer;
