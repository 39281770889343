import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import styled from "@emotion/styled";
import HeaderWithUnderline from "../Configurations/Enquiries/HeaderWithUnderline";
import NameDescription from "../Configurations/Enquiries/NameDescription";
import moment from "moment";
import RefundModal from "./RefundModal";
import { getCustomerTransactions } from "../../../api/Admin/trips";

export default function CancelDetails({
  tripData,
  handleOpenToast,
  getTripById,
}) {
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);

  const [refundAmount, setRefundAmount] = useState(0);
  const [paymentId, setPaymentId] = useState("");

  const { cancellation } = tripData || {};
  const handleRefundCloseModal = () => {
    setIsRefundModalOpen(false);
  };

  const getTransactionData = async () => {
    try {
      const response = await getCustomerTransactions(tripData?.tripId);
      setPaymentId(
        response?.data?.data?.find((transaction) => transaction.paymentId)
          ?.paymentId
      );
    } catch (error) {
      handleOpenToast("Error while getting transaction", "error");
    }
  };

  useEffect(() => {
    getTransactionData();
    setRefundAmount(
      Math.ceil(tripData?.customerAmounts?.amountReceived) -
        Math.ceil(cancellation?.penaltyCharge)
    );
  }, [tripData]);
  

  return (
    <Box sx={{ padding: "24px" }}>
      <HeaderWithUnderline header="Cancellation Details" />
      <RefundModal
        isModalOpen={isRefundModalOpen}
        handleCloseModal={handleRefundCloseModal}
        handleOpenToast={handleOpenToast}
        amountReceived={tripData?.customerAmounts?.amountReceived}
        penaltyCharge={cancellation?.penaltyCharge}
        paymentId={paymentId}
        customerId={tripData?.customerDetails?.customerId}
        tripId={tripData?.id}
        customerEnquiryId={tripData?.enquiryDetails?.enquiryId}
        getTripById={getTripById}
      />
      <StyledBox>
        <BoxContainer marginLeft="0px" gap="40px">
          <NameDescription
            name="Requested By"
            description={cancellation?.requestedBy?.toLowerCase() || "-"}
            color="#6D6D6D"
            marginLeft="0px"
          />
          <NameDescription
            name="Reason"
            description={cancellation?.reason || "-"}
            marginLeft="0px"
            color="#6D6D6D"
          />

          <NameDescription
            name="Cancelled At"
            description={
              moment(cancellation?.cancelledAt).format("DD-MM-YYYY hh:mm A") ||
              "-"
            }
            marginLeft="0px"
            color="#6D6D6D"
          />
          <NameDescription
            name="Penalty Charge"
            description={`₹ ${cancellation?.penaltyCharge || "-"}`}
            marginLeft="0px"
            color="#6D6D6D"
          />
          <NameDescription
            name="Refunded Amount"
            description={`₹ ${cancellation.refundAmount || "-"} `}
            marginLeft="0px"
            color="#6D6D6D"
          />
        </BoxContainer>

        {tripData?.customerAmounts?.amountReceived > 0 &&
          !cancellation?.refunded && (
            <StyledButton
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => setIsRefundModalOpen(true)}
            >
              Refund to Customer
            </StyledButton>
          )}
      </StyledBox>
    </Box>
  );
}

const BoxContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  paddingTop: "16px",
  paddingLeft: "32px",
  paddingRight: "32px",
}));

const StyledBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const StyledButton = styled(Button)(() => ({
  padding: "7px 20px",
  fontSize: "0.8rem",
  margin: "7px 0",
}));
