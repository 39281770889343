import React, { useEffect } from "react";
import { Alert, AlertTitle, Snackbar } from "@mui/material";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

const NotificationPopup = ({ isOpen, header, message, setisSnackbarOpen }) => {
  const [state, setState] = React.useState({
    open: isOpen,
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal, open } = state;
  useEffect(() => {
    if (isOpen === true) {
      setState({ ...state, open: true });
    }
  }, [isOpen]);

  const handleClose = () => {
    setState({ ...state, open: false });
    setisSnackbarOpen(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        key={vertical + horizontal}
        autoHideDuration={5000}
        sx={{maxWidth:'464px'}}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="info"
          variant="standard"
          icon={<NotificationsNoneIcon />}
        >
          <audio
            src="/assets/audio/notification_tune.mp3"
            autoPlay={true}
          ></audio>
          <AlertTitle>{header}</AlertTitle>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default NotificationPopup;