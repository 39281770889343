import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import GenerateStats from "../../../component/Common/GenerateStats";
import { StyledTableCell } from "../Approval/StyledTableCell";
import { StyledTableRow } from "../../../component/Common/StyledTableRow";
import LoadingTable from "../../../component/Common/LoadingTable";
import { LOADING_STATUS } from "../../../constants/constant";
import ErrorScreen from "../../../component/Common/ErrorScreen";
import { getAllTruckList } from "../../../api/Admin/trucksResources";
import shortenString from "../../../utils/shortenString";
import moment from "moment";

const TrucksResources = () => {
  const [trucksList, setTrucksList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [paginationData, setPaginationData] = useState({});
  const [isTransportersListFetched, setIsTransporterListFetched] = useState(
    LOADING_STATUS.INITIAL
  );
  const [count, setCount] = useState({});
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
  };

  const fetchData = async () => {
    setIsTransporterListFetched(LOADING_STATUS.LOADING);
    const response = await getAllTruckList(page + 1, inputValue);
    if (response.status === 200) {
      setTrucksList(response.data.data.transporterVehicles);
      setCount(response.data.data.counts);
      setIsTransporterListFetched(LOADING_STATUS.LOADED);
      setPaginationData(response.data.data.pagination);
    } else {
      setIsTransporterListFetched(LOADING_STATUS.FAILED);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, inputValue]);

  const statsArray = [
    { name: "Total Trucks", value: count?.total },
    { name: "In Transit", value: count?.Intransit },
    {
      name: "Available",
      value: count?.Available,
    },
    {
      name: "Unavailable",
      value: count?.Unavailable,
    },
  ];
  const tableHeaders = [
    "Vehicle No.",
    "Vehicle Category",
    "Vehicle Model",
    "Added On",
    "Status",
    "Transporter Name",
  ];

  const renderViews = () => {
    switch (isTransportersListFetched) {
      case "loading":
        return <LoadingTable columns={tableHeaders.length} />;

      case "loaded":
        if (trucksList.length)
          return <TransporterResourcesTable trucksList={trucksList} />;
        return (
          <TableRow sx={{ background: "#EAEAEA" }}>
            <TableCell
              colSpan={tableHeaders.length}
              align="center"
              sx={{ fontWeight: 600, color: "#333" }}
            >
              No Results Found.
            </TableCell>
          </TableRow>
        );

      case "failed":
        return (
          <TableRow>
            <TableCell
              colSpan={tableHeaders.length}
              sx={{ borderBottom: "none" }}
            >
              <ErrorScreen />
            </TableCell>
          </TableRow>
        );

      default:
        return null;
    }
  };

  return (
    <Box>
      <Stack
        p=".75rem 1.625rem"
        borderBottom="1px solid #ADADAD"
        direction="row"
        justifyContent="space-between"
      >
        <GenerateStats data={statsArray} />
        <TextField
          placeholder="Search"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          InputProps={{
            sx: { height: "36.31px" },
            endAdornment: (
              <img src="/assets/icons/search-icon.svg" alt="search-icon" />
            ),
          }}
          variant="outlined"
          size="small"
        />
      </Stack>
      <Box p={3}>
        <TableContainer aria-label="customized table">
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaders.map((header, index) => (
                  <StyledTableCell key={index}>{header}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            {/* based on loading status views will switch */}
            <TableBody>{renderViews()}</TableBody>
          </Table>
        </TableContainer>
        {isTransportersListFetched === "loaded" && (
          <div style={{ padding: "10px" }}>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={paginationData.totalItems}
              rowsPerPage={paginationData.pageSize}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={({ count }) => (
                <div>
                  Page{" "}
                  <span style={{ color: "green", fontWeight: 700 }}>
                    {page + 1}
                  </span>{" "}
                  of {Math.ceil(count / paginationData.pageSize)}
                </div>
              )}
            />
          </div>
        )}
      </Box>
    </Box>
  );
};
export default TrucksResources;

const TransporterResourcesTable = ({ trucksList }) => {
  const navigate = useNavigate();

  const generateStatus = (truckStatus) => {
    const text = truckStatus[0] + truckStatus.slice(1).toLowerCase();
    if (truckStatus === "AVAILABLE")
      return (
        <TableCell sx={{ color: "green", textAlign: "center" }}>
          {text}
        </TableCell>
      );
    if (truckStatus === "UNAVAILABLE")
      return (
        <TableCell sx={{ color: "red", textAlign: "center" }}>{text}</TableCell>
      );
    return (
      <TableCell sx={{ color: "orange", textAlign: "center" }}>
        {text}
      </TableCell>
    );
  };

  function createTransporterRowData(truck) {
    return {
      id: truck.transporterVehicleId,
      vehicleNumber: truck?.vehicleNumber || "-",
      vehicleCategory: truck?.vehicleCategory || "-",
      vehicleModelName: truck?.vehicleModelName || "-",
      joinedOn: truck?.addedOn || "-",
      totalTrip: "",
      status: truck?.vehicleStatus || "-",
      transporterName: truck?.transporterName || "-",
    };
  }

  const transporterTableData = trucksList.map((truck) =>
    createTransporterRowData(truck)
  );

  return transporterTableData.map((row, index) => {
    return (
      <StyledTableRow
        key={row.id}
        onClick={() => navigate(`/admin/app/resources/trucks/${row.id}`)}
        style={{ textDecoration: "none", cursor: "pointer" }}
      >
        <TableCell scope="row" sx={{ color: "#6D6D6D", textAlign: "center" }}>
          {row.vehicleNumber}
        </TableCell>
        <TableCell sx={{ color: "#6D6D6D", textAlign: "center" }}>
          {row.vehicleCategory + " Wheelers"}
        </TableCell>
        <TableCell sx={{ color: "#6D6D6D", textAlign: "center" }}>
          {shortenString(row.vehicleModelName, 20)}
        </TableCell>
        <TableCell sx={{ color: "#6d6d6d", textAlign: "center" }}>
          {moment(row.joinedOn).format("DD-MM-YYYY")}
        </TableCell>
        {generateStatus(row.status)}
        <TableCell sx={{ color: "#6D6D6D", textAlign: "center" }}>
          {shortenString(row.transporterName, 18)}
        </TableCell>
      </StyledTableRow>
    );
  });
};
