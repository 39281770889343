import React, { useState } from "react";
import AddButton from "../../../Common/AddButton";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import {
  createEnquiryRelatedPickupTimeSlot,
  deleteEnquiryRelatedPickupTimeSlot,
  updateEnquiryRelatedPickupTimeSlot,
} from "../../../../api/Admin/TimeRelated";
import useToast from "../../../../hooks/useToast";

function TimeSlot({ fetchEnquiryRelatedConfigs, pickupTimeSlots }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  // New state to keep track of editing slot
  const [editingSlotId, setEditingSlotId] = useState(null);
  const { handleOpenToast, ToastMessage } = useToast();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditingSlotId(null);
  };

  const handleOpenModal = () => {
    const currentTime = moment();
    const twoHoursLater = moment().add(2, "hours");

    setFromTime(currentTime.format("HH:00"));
    setToTime(twoHoursLater.format("HH:00"));

    setIsModalOpen(true);
  };

  // function to convert 12-hour format to 24-hour format
  const convertTo24HourFormat = (hour, meridiem) => {
    return moment(`${hour} ${meridiem}`, "hh A").format("HH:00");
  };

  const handleOpenModalForEdit = (slot) => {
    const from24hr = convertTo24HourFormat(slot.from, slot.fromMeridiem);
    const to24hr = convertTo24HourFormat(slot.to, slot.toMeridiem);

    setFromTime(from24hr);
    setToTime(to24hr);
    setEditingSlotId(slot._id);
    setIsModalOpen(true);
  };

  // function to convert 24-hour format to 12-hour format
  const convertTo12HourFormat = (time) => {
    const formattedTime = moment(time, "HH:mm");
    return {
      hour: formattedTime.format("hh"),
      meridiem: formattedTime.format("A"),
    };
  };

  const handleSave = async () => {
    const from = convertTo12HourFormat(fromTime);
    const to = convertTo12HourFormat(toTime);

    const timeData = {
      from: parseInt(from.hour, 10),
      to: parseInt(to.hour, 10),
      fromMeridiem: from.meridiem,
      toMeridiem: to.meridiem,
    };

    // If editingSlotId is present, then update the slot
    try {
      setIsSubmitting(true);
      let response;
      if (editingSlotId) {
        response = await updateEnquiryRelatedPickupTimeSlot(
          editingSlotId,
          timeData
        );
      } else {
        response = await createEnquiryRelatedPickupTimeSlot(timeData);
      }
      if (response.status === 200) {
        fetchEnquiryRelatedConfigs();
        handleCloseModal();
        handleOpenToast("Time slot saved successfully", "success");
      }
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      handleOpenToast("Error while saving time slot", "error");
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await deleteEnquiryRelatedPickupTimeSlot(id);
      if (response.status === 200) {
        fetchEnquiryRelatedConfigs();
        handleCloseModal();
        handleOpenToast("Time slot deleted successfully", "success");
      }
    } catch (error) {
      handleOpenToast("Error while deleting time slot", "error");
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <ToastMessage />
      <Dialog open={isModalOpen} onClose={handleCloseModal} Width="625px">
        <DialogTitle sx={{ color: "#333" }}>
          <Typography fontWeight={700} fontSize={24}>
            Pickup Time Slot
          </Typography>
          <Typography>Fill the pickup time slot:</Typography>
        </DialogTitle>
        <CloseIcon
          fontSize="medium"
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            right: "10px",
            top: "14px",
            cursor: "pointer",
          }}
        />
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
              gap: "20px",
              width: "500px",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <InputLabel
                sx={{
                  color: "#333",
                  fontSize: "1.125rem",
                  fontWeight: 700,
                }}
                htmlFor="weight.min"
              >
                Start Time
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                type="time"
                value={fromTime}
                width="100%"
                onChange={(e) => setFromTime(e.target.value + ":00")}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <InputLabel
                sx={{
                  color: "#333",
                  fontSize: "1.125rem",
                  fontWeight: 700,
                }}
                htmlFor="weight.min"
              >
                End Time
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                type="time"
                value={toTime}
                onChange={(e) => setToTime(e.target.value + ":00")}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: `${editingSlotId ? "space-between" : "flex-end"}`,
              width: "100%",
            }}
          >
            {editingSlotId && (
              <span
                onClick={() => handleDelete(editingSlotId)}
                style={{
                  color: "red",
                  textDecoration: "underline",
                  cursor: "pointer",
                  marginTop: "10%",
                }}
              >
                Delete
              </span>
            )}
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleSave}
              sx={{
                float: "right",
                padding: "10px 30px",
                fontSize: "1.2rem",
                margin: "20px 0",
              }}
              disabled={isSubmitting}
            >
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <AddButton onClick={handleOpenModal} name="Add Time Slot"></AddButton>
      <Box
        sx={{
          fontSize: "14px",
          fontWeight: 700,
          display: "flex",
          width: "80%",
          marginLeft: "20px",
          overflow: "scroll",
        }}
      >
        {pickupTimeSlots.map((slot, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "5px",
              background: "#EAEAEA",
              borderRadius: "5px",
              width: "full",
              margin: "4px",
              border: "1px solid #6D6D6D",
              cursor: "pointer",
            }}
            onClick={() => handleOpenModalForEdit(slot)}
          >
            <Box
              sx={{
                fontSize: "14px",
                color: "#6D6D6D",
              }}
            >
              {slot.from} {slot.fromMeridiem} - {slot.to} {slot.toMeridiem}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default TimeSlot;
