import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useMapsService } from "../../hooks/useMapsService";

export const PlacesAutocomplete = ({
  onPlaceSelect,
  placeholderText,
  error,
  helperText,
  resetError = ()=>{},
  value,
  ...props
}) => {
  const source = useRef("");
  const [placePredictions, setPlacePredictions] = useState([]);
  const { isLoaded, predictedPlaces } = useMapsService({ city: props.city });

  let timeoutId;
  const delayedSearch = (func, delay) => {
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const fetchPlaces = delayedSearch(async (input) => {
    source.current = input;
    resetError();
    if (!input || input === "") {
      setPlacePredictions([]);
      return;
    }

    try {
      const predictions = await predictedPlaces(input);
      setPlacePredictions(predictions);
    } catch (error) {
      console.error(error);
      setPlacePredictions([]);
    }
  }, 500);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  return (
    isLoaded && (
      <Autocomplete
        value={value}
        sx={{
          "&.MuiOutlinedInput-root .MuiAutocomplete-input": {
            padding: 0,
          },
        }}
        filterOptions={(x) => x}
        noOptionsText="No Locations"
        options={
          placePredictions ? placePredictions.map((e) => e.description) : []
        }
        getOptionLabel={(option) => option}
        onInputChange={(event, newInputValue) => {
          if (!newInputValue) {
            setPlacePredictions([]);
          }
          fetchPlaces(newInputValue);
        }}
        onChange={(event, newValue) => {
          if (newValue) {
            const p_id = placePredictions.filter(
              (e) => e.description === newValue
            );
            onPlaceSelect(newValue, p_id[0].place_id); // Pass the selected place to the parent component
          } else {
            onPlaceSelect("", null);
          }
        }}
        renderInput={(params) => (
          <TextField
            error={error}
            helperText={error && helperText}
            ref={params.InputProps.ref}
            value={source.current}
            {...params}
            InputProps={{
              ...params.InputProps,
              sx: {
                "&.MuiOutlinedInput-root .MuiAutocomplete-input": {
                  padding: "1rem",
                  paddingRight:"4rem",
                  fontWeight: 700,
                  color: "#333",
                },
              },
            }}
            // onChange={(e) => resetError()}
            placeholder={placeholderText ? placeholderText : "Location"}
          />
        )}
        blurOnSelect={props.blurOnSelect}
        clearOnBlur={props.clearOnBlur}
      />
    )
  );
};
