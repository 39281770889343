import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    InputLabel,
    Stack,
    TextField,
    Typography,
  } from "@mui/material";
  import axios from "axios";
  import { useState } from "react";
  import { BASE_URL } from "../../../api/config";
  import Cookies from "js-cookie";
  import { LOADING_STATUS } from "../../../constants/constant";
  import KycUpdatesAlert from "../../../component/Common/KycUpdatesMessages";
import { submitCustomerBusinessLicense } from "../../../api/Customer/kyc";
  
  const BusinessLicense = (props) => {
    const { expanded, handleChange, userBusinessLicenseNumber } = props;
    const [businessLicenseStatus, setBusinessLicenseStatus] = useState(userBusinessLicenseNumber?.approvalStatus)
    const [businessLicenseNumber, setBusinessLicenseNumber] = useState(
      userBusinessLicenseNumber?.licenceNumber
    );
    const [isGstSubmitted, setIsGstSubmitted] = useState(LOADING_STATUS.INITIAL);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(null);
    const approvalStatusMarker = () => {
      if (businessLicenseStatus === "REJECTED") {
        return <img src="/assets/icons/red-cross.svg" alt="rejected" />;
      }
      if (businessLicenseStatus === "APPROVED") {
        return <img src="/assets/icons/green-tick.svg" alt="approved" />;
      }
    };
    const handleOnClose = () => {
      setShowSnackbar(false);
    };
    const handleGSTNumberInputChange = (event) => {
      setBusinessLicenseNumber(event.target.value);
    };
    const handleSubmitGstNumber = async () => {
      if (!businessLicenseNumber) return;
  
      setIsGstSubmitted(LOADING_STATUS.LOADING);
      try {
        const response = await submitCustomerBusinessLicense(businessLicenseNumber)
        setIsGstSubmitted(LOADING_STATUS.LOADED);
        setBusinessLicenseStatus(response.data.kycDetails.businessLicenceDetails.approvalStatus)
        setSnackbarMessage({
          msg: "Submitted Successfully",
          severity: "success",
        });
        setShowSnackbar(true);
      } catch (err) {
        setIsGstSubmitted(LOADING_STATUS.FAILED);
        setSnackbarMessage({ msg: "Something went wrong", severity: "error" });
        setShowSnackbar(true);
      }
    };
    return (
      <>
        <Accordion
          sx={{ boxShadow: "none", borderRadius: ".35rem" }}
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<img src="/assets/icons/expand_less.svg" />}
            aria-controls="panel4bh-content"
            id="panel1bh-header"
            sx={{
              background: "#EAEAEA",
              paddingLeft: "2rem",
              height: "3rem",
              borderRadius: ".25rem",
              "&.Mui-expanded": {
                minHeight: "3rem",
              },
            }}
          >
            <Typography sx={{ width: "33%", flexShrink: 0, fontWeight: 700 }}>
              Business License Number &nbsp; {approvalStatusMarker()}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              background: "#FFF",
              width: "60%",
              paddingTop: "2rem",
              paddingLeft: "2rem",
            }}
          >
            <InputLabel sx={{ color: "#333", fontSize: "14px" }}>
              Shop Act License
            </InputLabel>
            <TextField
              placeholder="000000000000"
              required
              maxLength={12}
              value={businessLicenseNumber ? businessLicenseNumber : ""}
              fullWidth
              InputProps={{
                style: { height: "52px", fontWeight: 700 },
              }}
              onChange={handleGSTNumberInputChange}
            />
  
            <Stack direction="row" justifyContent="flex-end" mt={5}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  marginLeft: "auto",
                  paddingInline: "24px",
                  paddingBlock: "4px",
                }}
                onClick={handleSubmitGstNumber}
              >
                Submit
              </Button>
            </Stack>
          </AccordionDetails>
        </Accordion>
        {showSnackbar && (
          <KycUpdatesAlert
            handleOnClose={handleOnClose}
            open={showSnackbar}
            message={snackbarMessage.msg}
            severity={snackbarMessage.severity}
          />
        )}
      </>
    );
  };
  
  export default BusinessLicense;
  