import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import { Box, FormHelperText, Stack, TextField } from "@mui/material";
import { State, City } from "country-state-city";
import Autocomplete from "@mui/material/Autocomplete";

const StateCityDropdown = ({
  selectedState,
  setSelectedState,
  setSelectedCity,
  selectedCity,
  isCitySelected,
  isStateSelected,
}) => {
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [stateNames, setStateNames] = useState([]);

  useEffect(() => {
    const indiaStates = State.getStatesOfCountry("IN");
    const stateNamesRes = indiaStates.map((state) => state.name);
    setStatesList(indiaStates);
    setStateNames(stateNamesRes);
  }, []);

  useEffect(() => {
    const stateCodeObj = statesList.find((item) => item.name === selectedState);
    const listOfCities = City.getCitiesOfState("IN", stateCodeObj?.isoCode);
    setCitiesList(listOfCities.map((city) => city.name));
  }, [selectedState]);

  const handleStateChange = (event, value) => {
    setSelectedState(value);
    setSelectedCity("");
  };

  const handleCityChange = (event, value) => {
    setSelectedCity(value);
  };
  return (
    <Box sx={{ display: "flex", gap: "1rem" }}>
      <Stack sx={{ width: "50%" }}>
        <InputLabel
          htmlFor="state-select"
          sx={{ color: "#333", fontSize: "0.835rem" }}
        >
          State <span style={{ color: "red" }}>*</span>
        </InputLabel>
        <Autocomplete
          disableClearable
          value={selectedState}
          onChange={handleStateChange}
          options={stateNames}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select your State"
              inputProps={{
                ...params.inputProps,
                style: {
                  padding: "16.5px 14px",
                },
              }}
            />
          )}
          MenuProps={{
            PaperProps: { sx: { maxHeight: 300 } },
          }}
          renderTags={() => <div />}
          error={isStateSelected?.length}
        />
        <FormHelperText error={!selectedCity}>
          {isStateSelected && !selectedState && "Please select a state"}
        </FormHelperText>
      </Stack>

      <Stack sx={{ width: "50%" }}>
        <InputLabel
          htmlFor="city-select"
          sx={{ color: "#333", fontSize: "0.835rem" }}
        >
          City <span style={{ color: "red" }}>*</span>
        </InputLabel>
        <Autocomplete
          disableClearable
          value={selectedCity}
          onChange={handleCityChange}
          options={citiesList}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select your State"
              inputProps={{
                ...params.inputProps,
                style: {
                  padding: "16.5px 14px",
                },
              }}
            />
          )}
          MenuProps={{
            PaperProps: { sx: { maxHeight: 300 } },
          }}
          renderTags={() => <div />}
          error={isCitySelected?.length}
        />
        <FormHelperText error={!selectedCity}>
          {isCitySelected && !selectedCity && "Please select a city"}
        </FormHelperText>
      </Stack>
    </Box>
  );
};

export default StateCityDropdown;
