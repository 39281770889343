import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import RejectDocumentModal from "./RejectDocumentModal";
import axios from "axios";
import { BASE_URL } from "../../../api/config";
import Cookies from "js-cookie";

const OtherDocuments = ({ businessLicense, gstNumber, kycId }) => {
  const [otherDocumentStatus, setOtherDocumentStatus] = useState(
    businessLicense?.approvalStatus
  );

  const [openRejectModal, setOpenRejectModal] = useState(false);
  //   states for open reject document modal
  const handleOpen = () => setOpenRejectModal(true);
  const handleClose = () => setOpenRejectModal(false);

  const handleOtherDocumentsApproveRequest = async () => {
    try {
      await axios
        .post(
          BASE_URL.concat("admin/kyc/approve"),
          {
            kycId,
            documentType: "other",
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("admintoken")}`,
            },
          }
        )
        .then((data) => {
          setOtherDocumentStatus(data.data.data.approvalStatus);
          window.location.reload();
        });
    } catch (err) {}
  };
  const handleOtherDocumentsRejectRequest = async () => {
    try {
      await axios
        .post(
          BASE_URL.concat("admin/kyc/reject"),
          {
            kycId,
            documentType: "other",
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("admintoken")}`,
            },
          }
        )
        .then((data) => {
          setOtherDocumentStatus(data.data.data.approvalStatus);
          window.location.reload();
        });
    } catch (err) {}
  };

  if (!gstNumber && !businessLicense) {
    return (
      <Typography
        sx={{
          color: "#333",
          fontSize: "1rem",
          marginTop: "4rem",
          marginLeft: "1rem",
        }}
      >
        User has not provided these informations.
      </Typography>
    );
  }

  return (
    <Box mt={5} p={2}>
      <Typography> GSTIN/UIN Number:</Typography>
      {gstNumber ? (
        <Typography sx={{ fontWeight: 700, fontSize: "24px", color: "#333" }}>
          {gstNumber?.licenceNumber}
        </Typography>
      ) : (
        "--"
      )}

      <Typography mt={3}>Business License Number</Typography>
      {businessLicense ? (
        <Typography sx={{ fontWeight: 700, fontSize: "24px", color: "#333" }}>
          {businessLicense?.licenceNumber}
        </Typography>
      ) : (
        "--"
      )}
      {gstNumber && businessLicense && otherDocumentStatus === "APPROVED" ? (
        <>
          <span />
          <Button
            sx={{ color: "#0C7F44", textTransform: "none" }}
            endIcon={<img src="/assets/icons/green-tick.svg" alt="approved" />}
          >
            Approved
          </Button>
        </>
      ) : otherDocumentStatus === "REJECTED" ? (
        <>
          <span />
          <Button
            sx={{ color: "#FF0707", textTransform: "none" }}
            endIcon={<img src="/assets/icons/red-cross.svg" alt="approved" />}
          >
            Rejected
          </Button>
        </>
      ) : (
        <Stack
          width={300}
          direction="row"
          justifyContent="space-between"
          pr={0.5}
          mt={5}
        >
          <Button variant="outlined" color="primary" onClick={handleOpen}>
            Reject
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOtherDocumentsApproveRequest}
          >
            Approve
          </Button>
        </Stack>
      )}
      <RejectDocumentModal
        documentType="Others"
        openRejectModal={openRejectModal}
        handleOpen={handleOpen}
        handleClose={handleClose}
        handleRejectDocument={handleOtherDocumentsRejectRequest}
      />
    </Box>
  );
};
export default OtherDocuments;
