import React, { useState, useRef, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  MarkerF,
} from "@react-google-maps/api";
import LoadScriptOnlyIfNeeded from "../../../utils/LoadscriptOnlyIfNeeded";

const containerStyle = {
  width: "100%",
  height: "100%",
};

export default function UpcomingMapTracking(props) {
  const { tripDetails } = props;

  const tripData = {
    tripId: tripDetails?.tripId,
    startingPath: {
      name: tripDetails?.enquiryDetails?.pickup?.location?.address,
      lat:
        tripDetails?.enquiryDetails?.pickup?.location.latitude &&
        tripDetails?.enquiryDetails?.pickup?.location.latitude,
      lng:
        tripDetails?.enquiryDetails?.pickup?.location.longitude &&
        tripDetails?.enquiryDetails?.pickup?.location.longitude,
    },
    destinationPath: {
      name: tripDetails?.enquiryDetails?.dropoff?.location.address,
      lat:
        tripDetails?.enquiryDetails.dropoff?.location.latitude &&
        tripDetails?.enquiryDetails.dropoff?.location.latitude,
      lng:
        tripDetails?.enquiryDetails?.dropoff?.location.longitude &&
        tripDetails?.enquiryDetails?.dropoff?.location.longitude,
    },
    distance: tripDetails?.enquiryDetails?.distance,
  };

  const [directions, setDirections] = useState(null);
  const mapRef = useRef(null);

  const handleScriptLoad = () => {
    const directionsService = new window.google.maps.DirectionsService();
    const origin = new window.google.maps.LatLng(
      tripData.startingPath.lat,
      tripData.startingPath.lng
    );
    const destination = new window.google.maps.LatLng(
      tripData.destinationPath.lat,
      tripData.destinationPath.lng
    );

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.error(`Error fetching directions: ${status}`);
        }
      }
    );
  };

  return (
    <>
      <LoadScriptOnlyIfNeeded
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        onLoad={() => handleScriptLoad()}
      >
        <GoogleMap ref={mapRef} mapContainerStyle={containerStyle} zoom={6}>
          {directions && (
            <DirectionsRenderer
              directions={directions}
              options={{ suppressMarkers: true }}
            />
          )}
          <MarkerF
            position={{
              lat: tripData.startingPath.lat,
              lng: tripData.startingPath.lng,
            }}
            icon={{
              url: "/assets/icons/truck.svg",
              fillColor: "#000",
            }}
          >
            <div>Starting Path: {tripData.startingPath.name}</div>
          </MarkerF>
          <MarkerF
            position={{
              lat: tripData.destinationPath.lat,
              lng: tripData.destinationPath.lng,
            }}
          >
            <div>Destination Path: {tripData.destinationPath.name}</div>
          </MarkerF>
          <div
            style={{
              position: "absolute",
              top: 10,
              left: 200,
              backgroundColor: "white",
              padding: 10,
            }}
          >
            <div>Distance : {tripData?.distance} km</div>
          </div>
        </GoogleMap>
      </LoadScriptOnlyIfNeeded>
    </>
  );
}
