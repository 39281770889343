import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box, Stack, TablePagination, TextField } from "@mui/material";

import GenerateStats from "../../../component/Common/GenerateStats";
import AdminTable from "../../../component/Admin/Configurations/Enquiries/AdminTable";
import { getAllAdminEnquiries } from "../../../api/Admin/adminEnquiries";
import { LOADING_STATUS } from "../../../constants/constant";
import useToast from "../../../hooks/useToast";
import { TableSearchInputField } from "../../../component/Common/TableSearchInput";

const StyledBox = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 127px)",
  padding: "0px 24px 0px 24px",
}));

const Enquiries = () => {
  const [enquiries, setEnquiries] = useState([]);
  const [page, setPage] = useState(0);
  const [paginationData, setPaginationData] = useState({});
  const [enquiryStatus, setEnquiryStatus] = useState([]);
  const [statusCounts, setStatusCounts] = useState({});
  const [searchText, setSearchText] = useState("");
  const [inputValue, setInputValue] = useState("");

  const [isEnquiriesListFetched, setIsEnquiriesListFetched] = useState(
    LOADING_STATUS.INITIAL
  );
  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  const { handleOpenToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);

  const statsArray = [
    {
      name: "Total",
      value:
        (statusCounts?.QUOTED || 0) +
        (statusCounts?.ENQUIRY_EXPIRED || 0) +
        (statusCounts?.ENQUIRY_REJECTED || 0) +
        (statusCounts?.NO_SERVICE || 0) +
        (statusCounts?.OFFER_EXPIRED || 0),
    },
    {
      name: "Quoted",
      value: statusCounts?.QUOTED ? statusCounts?.QUOTED : "0",
    },
    {
      name: "Enquiry Expired",
      value: statusCounts?.ENQUIRY_EXPIRED
        ? statusCounts?.ENQUIRY_EXPIRED
        : "0",
    },
    {
      name: "Enquiry Rejected",
      value: statusCounts?.ENQUIRY_REJECTED
        ? statusCounts?.ENQUIRY_REJECTED
        : "0",
    },
    {
      name: "No Service",
      value: statusCounts?.NO_SERVICE ? statusCounts?.NO_SERVICE : "0",
    },
    {
      name: "Offer Expired",
      value: statusCounts?.OFFER_EXPIRED ? statusCounts?.OFFER_EXPIRED : "0",
    },
  ];

  const adminEnquiries = async () => {
    try {
      setIsEnquiriesListFetched(LOADING_STATUS.LOADING);
      const response = await getAllAdminEnquiries(
        page,
        enquiryStatus,
        debouncedSearchText
      );
      if (response?.status === 200) {
        setEnquiries(response.data.data.enquiries);
        setPaginationData(response.data.data.pagination);
        setStatusCounts(response.data.data.counts);
        setIsEnquiriesListFetched(LOADING_STATUS.LOADED);
      } else {
        setIsEnquiriesListFetched(LOADING_STATUS.FAILED);
      }
    } catch (error) {
      handleOpenToast("Error while getting admin enquiries", "error");
    }
  };

  useEffect(() => {
    adminEnquiries();
  }, [page, enquiryStatus, debouncedSearchText]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
  };

  const handleSearchInputChange = (event) => {
    if (isNaN(event.target.value)) return;
    setSearchText(event.target.value);
  };
  return (
    <Box height={{ height: "100%" }}>
      <Stack
        p="10px"
        borderBottom="1px solid #ADADAD"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <GenerateStats data={statsArray} />

        <TableSearchInputField
          placeholder="Mobile No."
          value={searchText}
          onChange={handleSearchInputChange}
        />
      </Stack>

      <StyledBox>
        <AdminTable
          isEnquiriesListFetched={isEnquiriesListFetched}
          enquiries={enquiries}
          enquiryStatus={enquiryStatus}
          setEnquiryStatus={setEnquiryStatus}
          getAdminEnquiries={adminEnquiries}
        />
      </StyledBox>

      {enquiries?.length > 1 && (
        <div
          style={{
            borderTop: "1px solid #adadad",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={paginationData.totalItems}
            rowsPerPage={paginationData.pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ count }) => (
              <div>
                Page{" "}
                <span style={{ color: "green", fontWeight: 700 }}>
                  {page + 1}
                </span>{" "}
                of {Math.ceil(count / paginationData.pageSize)}
              </div>
            )}
          />
        </div>
      )}
    </Box>
  );
};

export default Enquiries;
